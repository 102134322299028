export const DocumentAnalysisRegistrationCertificateDocumentTypesEnum = {
  // FR
  kbis: 'kbis',
  sirene_extract: 'sirene_extract',
  pappers_extract: 'pappers_extract',
  inscription_rne: 'inscription_rne',
  infonet_extract: 'infonet_extract',
  repertoire_metiers_extract: 'repertoire_metiers_extract',
  // DE, NL
  handelsregister: 'handelsregister',
  // IT
  visura_camerale: 'visura_camerale',
  // Fallback
  other: 'other',
} as const;
export type DocumentAnalysisRegistrationCertificateDocumentTypesEnum =
  (typeof DocumentAnalysisRegistrationCertificateDocumentTypesEnum)[keyof typeof DocumentAnalysisRegistrationCertificateDocumentTypesEnum];
