// in https://linear.app/dotfile/issue/E-2886/store-table-state-per-workspace-in-local-storage
// Run only once, when the script loads
const regex = /DOTFILE_TABLE\([a-zA-Z _]+\)/;
const regexTasks = /DOTFILE_TABLE\(Tasks:.+\)/;
const regexWebhookLogs =
  /DOTFILE_TABLE\(Webhook logs \(in the last 30 days\):.+\)/;

Object.keys(localStorage).forEach((key) => {
  // MIGRATION - 2023-07-05
  // Clean old local storage keys before the scoping by workspaceId
  if (key.match(regex)) {
    localStorage.removeItem(key);
  }

  // MIGRATION - 2023-12-21
  // Clean old checkUpdatedAt order for Tasks page
  if (key.match(regexTasks)) {
    const itemString = localStorage.getItem(key);
    if (itemString) {
      // Reset sorting to the default
      const item = JSON.parse(itemString);
      if (
        item.sort.find(
          (c: { id: string; desc: boolean }) => c.id === 'checkUpdatedAt',
        )
      ) {
        item.sort = [];
        localStorage.setItem(key, JSON.stringify(item));
      }
    }
  }

  // MIGRATION - 2024-04-22
  // Clean cache for WebhooksLogs with old label
  // This will also remove the reference to the column `createdAt` renamed to `latestLogCreatedAt`
  if (key.match(regexWebhookLogs)) {
    localStorage.removeItem(key);
  }
});

// @TODO - E-2888 - Local storage migration
