import { useTranslation } from 'react-i18next';

import {
  Alert,
  AlertTitle,
  Button,
} from '@dotfile/frontend/shared/design-system';

export const NewVersionCheckerAlert = ({
  noDotfileMention = false,
}: {
  noDotfileMention?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Alert status="info" variant="left-accent">
      <AlertTitle>
        {t('version_checker.title', {
          ns: 'components',
          defaultValue: 'New version is available',
          context: noDotfileMention ? undefined : 'dotfile',
        })}
      </AlertTitle>
      <Button
        variant="link"
        size="md"
        onClick={() => {
          window.location.reload();
        }}
      >
        {t('version_checker.refresh_action', {
          ns: 'components',
          defaultValue: 'Click to refresh',
        })}
      </Button>
    </Alert>
  );
};
