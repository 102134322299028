import { useTranslation } from 'react-i18next';

import { Text } from '@dotfile/frontend/shared/design-system';

import { AppLoader, FullContainer } from '../../../../shared';

export const CreateCaseScreen = () => {
  const { t } = useTranslation();

  return (
    <FullContainer centerContent>
      <AppLoader
        isInline
        // shift a bit to the top to have a more visually balanced page
        mt="-10"
      />
      <Text
        color="black"
        fontSize="larger"
        fontWeight="medium"
        textAlign="center"
      >
        {t('forms.create_case.title', {
          ns: 'client-portal',
          defaultValue:
            'Just a moment while we are preparing the verification we need from you',
        })}
      </Text>
      <Text textAlign="center">
        {t('forms.create_case.subtitle', {
          ns: 'client-portal',
          defaultValue:
            'Please do not close this tab during the process as it can take a few seconds.',
        })}
      </Text>
    </FullContainer>
  );
};
