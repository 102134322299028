import { useTranslation } from 'react-i18next';

import { RadioCard, Text } from '@dotfile/frontend/shared/design-system';

type IsItTheRightDocumentRadioCardProps = {
  value: 'yes' | 'no' | undefined;
  onChange: (value: 'yes' | 'no') => void;
};

/**
 * Similar to the component with the same name in id-verification/
 */
export const IsItTheRightDocumentRadioCard = ({
  value,
  onChange,
}: IsItTheRightDocumentRadioCardProps) => {
  const { t } = useTranslation();

  return (
    <RadioCard
      value={value}
      onChange={(newValue: string) => {
        if (newValue === 'yes' || newValue === 'no') {
          onChange(newValue);
        }
      }}
      options={[
        {
          value: 'yes',
          element: (
            <Text color="black">
              {t('checks.id_document.update_reference_data.yes', {
                ns: 'client-portal',
                defaultValue: 'Yes',
              })}
            </Text>
          ),
        },
        {
          value: 'no',
          element: (
            <Text color="black">
              {t('checks.id_document.update_reference_data.no', {
                ns: 'client-portal',
                defaultValue: 'No',
              })}
            </Text>
          ),
        },
      ]}
    />
  );
};
