// Use yup-extended to have countryCode2
import {
  CompanyModel,
  CompanyStatusEnum,
  CompanyTypeEnum,
} from '../../../../shared/models';
import {
  COMPANY_NAME_MAX_LENGTH,
  COMPANY_REGISTRATION_NUMBER_MAX_LENGTH,
} from '../../../../shared/properties';
import yup from '../../../../utils/yup-extended';
import { addressSchema } from '../../address';
import { bankingInformationSchema } from '../../banking-information';
import { caseRelationSchema } from '../../case-relation';
import {
  individualSchema,
  individualSchemaOptionalCustomProperty,
  MAX_INDIVIDUAL_PER_CASE,
} from '../../individual';
import { companyClassificationSchema } from './company-classification.schema';

export const companySchema = ({
  omitCaseId,
  percentageNoValueLabel = 'null',
}: {
  omitCaseId?: boolean;

  // This is not optimal and should be improved
  // @TODO - E-1343 - Yup umbrella issue
  /**
   * Allow to change the wording for no value for ownership percentage
   * - `'null'` for API
   * - `'empty'` for UI
   */
  percentageNoValueLabel?: 'null' | 'empty';
} = {}): yup.SchemaOf<
  Omit<
    CompanyModel,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'deletedAt'
    | 'lastActivityAt'
    | 'isRelevant'
    | 'case'
    | 'checks'
    | 'customPropertyValues'
    | 'workspace'
    | 'workspaceId'
    | 'documentOrders'
    | 'vendorData'
    | 'vendorIds'
    | 'caseId' // omitted because it can be optional in the validation schema
  >
> => {
  const caseId = omitCaseId ? yup.string() : yup.string().required().uuid();

  return yup
    .object({
      caseId,
      name: yup.string().required().max(COMPANY_NAME_MAX_LENGTH),
      commercialName: yup
        .string()
        .optionalString()
        .max(COMPANY_NAME_MAX_LENGTH),
      registrationNumber: yup
        .string()
        .optionalString()
        .max(COMPANY_REGISTRATION_NUMBER_MAX_LENGTH),
      registrationDate: yup
        .string()
        .optionalString()
        .absoluteDate()
        .default(null),
      status: yup
        .mixed()
        .oneOf(Object.values(CompanyStatusEnum))
        .optional()
        .default(CompanyStatusEnum.not_reported),
      type: yup
        .mixed()
        .oneOf(Object.values(CompanyTypeEnum))
        .optional()
        .default(CompanyTypeEnum.main),
      legalForm: yup.string().optionalString(),
      entityLegalForm: yup.string().optionalString().entityLegalForm(),
      country: yup.string().optionalString().countryCode2(),
      address: addressSchema(),
      bankingInformation: bankingInformationSchema(),
      shareCapital: yup.string().optionalString(),
      taxIdentificationNumber: yup.string().optionalString(),
      websiteUrl: yup.string().optionalString().url(),
      employerIdentificationNumber: yup.string().optionalString(),
      classifications: yup
        .array()
        .uniqueItems((p) => `${p.type}:${p.code}:${p.description}`)
        .transform((value, original) => {
          // Opt-out of transform by returning the original value, otherwise
          // this can let string like `"[]"` passes
          // @see https://linear.app/dotfile/issue/E-2126/data-consistency-for-company-classification
          // Cannot use `strict()` otherwise the whole companyClassificationSchema will be validated strictly
          return original;
        })
        .of(companyClassificationSchema())
        // @see https://github.com/jquense/yup/issues/2154
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .nullable() as any,
      individuals: yup
        .array(individualSchema({ omitCaseId: true }))
        .transform((value, original) => {
          // Opt-out of transform by returning the original value, otherwise
          // this can let string like `"[]"` passes
          // Cannot use `strict()` otherwise the whole individualSchema will be validated strictly
          return original;
        })
        .optional()
        .max(MAX_INDIVIDUAL_PER_CASE),
      relations: yup
        .array(
          caseRelationSchema({ percentageNoValueLabel })
            .pick([
              'roles',
              'position',
              'ownershipPercentage',
              'votingRightsPercentage',
            ])
            .concat(
              yup.object({
                sourceCompanyId: yup.string().uuid().optional(),
                targetCompanyId: yup.string().uuid().optional(),
                targetIndividualId: yup.string().uuid().optional(),
              }),
            ),
        )
        .default([])
        .when('type', {
          is: CompanyTypeEnum.main,
          then: (schema) => schema.max(0),
          otherwise: (schema) => schema.optional(),
        })
        .optional(),
    })
    .noUnknown()
    .defined();
};

// TODO - E-3828 - to remove and update schema
export const companySchemaWithOptionalCustomProperty = yup.object({
  customProperties: yup.object().optional(),
  individuals: yup
    .array(
      individualSchema({ omitCaseId: true }).concat(
        individualSchemaOptionalCustomProperty,
      ),
    )
    .transform((value, original) => {
      // Opt-out of transform by returning the original value, otherwise
      // this can let string like `"[]"` passes
      // Cannot use `strict()` otherwise the whole individualSchema will be validated strictly
      return original;
    })
    .optional()
    .max(MAX_INDIVIDUAL_PER_CASE),
});
