import { VendorEnum } from '../../../shared/models';
import { getDocumentPriceTier } from './get-document-price-tier';

export type AvailableDocumentDefinition = {
  country: string;
  label: string;
  vendor: VendorEnum;
  /** PriceTier string such as tier_XX */
  priceTier: string;
  /** original vendor price, in tokens */
  vendorPrice: number;
};

export const PappersDocumentType = {
  fr_extrait_pappers: 'fr_extrait_pappers',
  fr_avis_situation_insee: 'fr_avis_situation_insee',
  fr_extrait_inpi: 'fr_extrait_inpi',
  fr_status: 'fr_status',
} as const;
export type PappersDocumentType =
  (typeof PappersDocumentType)[keyof typeof PappersDocumentType];

const pappersPriceTier = getDocumentPriceTier(
  VendorEnum.pappers,
  // Not important for pappers
  1,
);

export const pappersAvailableDocumentsDefinitions: Partial<
  Record<PappersDocumentType, AvailableDocumentDefinition>
> = {
  // Pappers
  [PappersDocumentType.fr_avis_situation_insee]: {
    country: 'FR',
    label: 'Avis de situation INSEE',
    vendor: VendorEnum.pappers,
    priceTier: pappersPriceTier,
    vendorPrice: 3,
  },
  [PappersDocumentType.fr_extrait_inpi]: {
    country: 'FR',
    label: 'Extrait INPI',
    vendor: VendorEnum.pappers,
    priceTier: pappersPriceTier,
    vendorPrice: 3,
  },
  [PappersDocumentType.fr_extrait_pappers]: {
    country: 'FR',
    label: 'Extrait Pappers',
    vendor: VendorEnum.pappers,
    priceTier: pappersPriceTier,
    vendorPrice: 2,
  },
  [PappersDocumentType.fr_status]: {
    country: 'FR',
    label: 'Statuts',
    vendor: VendorEnum.pappers,
    priceTier: pappersPriceTier,
    vendorPrice: 3,
  },
};
