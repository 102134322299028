/**
 * Max length for CustomProperty / CustomPropertyOption `label` and `key`
 */
export const CUSTOM_PROPERTY_LABEL_KEY_MAX_LENGTH = 100;

export const MAX_CUSTOM_PROPERTY_PER_ENTITY_TARGET = 100;

/**
 * Maximum number of options per custom property that has options
 */
export const MAX_OPTION_PER_CUSTOM_PROPERTY = 50;
