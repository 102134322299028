import { isNotNullNotUndefined } from '@dotfile/shared/common';

import {
  ClientPortalBlockLayoutModel,
  ClientPortalBlockLayoutWidgetEnum,
  ClientPortalBlockTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BLOCK_KEY_MAX_LENGTH, BLOCK_LABEL_MAX_LENGTH } from '../../constants';
import {
  CLIENT_PORTAL_WIDGET_DYNAMIC_FIELD_REQUIREMENT_MAPPING,
  ClientPortalBlockLayoutDynamicFieldRequirement,
} from '../helpers';

type ClientPortalBlockLayoutSchemaOption = {
  /**
   * Async function to determine if a key is unique
   * @param key
   * @returns
   */
  isKeyUnique: (key: string) => Promise<boolean>;

  /**
   * Async function to determine if a position is unique
   * @param position
   * @returns
   */
  isPositionUnique: (position: number) => Promise<boolean>;
};

const computeDynamicRequirementPropertySchema = (
  field: ClientPortalBlockLayoutDynamicFieldRequirement,
  propertySchema: yup.StringSchema,
  widget: ClientPortalBlockLayoutWidgetEnum,
) => {
  const dynamicRequirementDefinitions =
    CLIENT_PORTAL_WIDGET_DYNAMIC_FIELD_REQUIREMENT_MAPPING[field];

  if (dynamicRequirementDefinitions.optional.includes(widget)) {
    return propertySchema.optional().nullable();
  }

  if (dynamicRequirementDefinitions.required.includes(widget)) {
    return propertySchema.required();
  }

  // Not available field
  return propertySchema
    .optional()
    .nullable()
    .test('not set', function (value?: string | null) {
      const { createError, path } = this;
      return (
        !isNotNullNotUndefined(value) ||
        createError({
          message: `${path} must be null for widget=${widget}`,
          path,
        })
      );
    });
};

export const clientPortalBlockLayoutSchema = ({
  isKeyUnique,
  isPositionUnique,
}: ClientPortalBlockLayoutSchemaOption): yup.SchemaOf<
  Pick<
    ClientPortalBlockLayoutModel,
    | 'type'
    | 'key'
    | 'label'
    | 'description'
    | 'position'
    | 'widget'
    | 'fileId'
    | 'stepId'
  >
> => {
  return yup
    .object()
    .shape({
      type: yup.mixed().oneOf([ClientPortalBlockTypeEnum.layout]).required(),
      widget: yup
        .mixed()
        .oneOf(Object.values(ClientPortalBlockLayoutWidgetEnum))
        .required(),
      key: yup
        .string()
        .max(BLOCK_KEY_MAX_LENGTH)
        .matches(
          /^[a-z][a-z_0-9]*$/,
          'key must start with a lower case letter and only contain lower case letters, digit and underscore',
        )
        .required()
        .isUnique(
          isKeyUnique,
          "Another block with the same key '${value}' already exists",
        ),
      stepId: yup.string().uuid().required(),
      position: yup
        .number()
        .isUnique(
          isPositionUnique,
          "Another block with the same position '${value}' already exists",
        ),
      label: yup
        .string()
        .max(BLOCK_LABEL_MAX_LENGTH)
        .when(
          'widget',
          (
            widget: ClientPortalBlockLayoutWidgetEnum,
            schema: yup.StringSchema,
          ) => {
            return computeDynamicRequirementPropertySchema(
              'label',
              schema,
              widget,
            );
          },
        ),
      description: yup
        .string()
        .when(
          'widget',
          (
            widget: ClientPortalBlockLayoutWidgetEnum,
            schema: yup.StringSchema,
          ) => {
            return computeDynamicRequirementPropertySchema(
              'description',
              schema,
              widget,
            );
          },
        ),
      fileId: yup
        .string()
        .uuid()
        .when(
          'widget',
          (
            widget: ClientPortalBlockLayoutWidgetEnum,
            schema: yup.StringSchema,
          ) => {
            return computeDynamicRequirementPropertySchema(
              'fileId',
              schema,
              widget,
            );
          },
        ),
    })
    .noUnknown()
    .defined();
};
