import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';

import { useCurrentEditor } from '@tiptap/react';

import { HStack } from '../../../layout/stack/stack';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '../../../overlay/popover';
import { Portal } from '../../../utils/portal';
import { Button } from '../../button/button';
import { Input } from '../../input/input';
import { urlHasProtocol } from './url-has-protocol.helper';

type RichTextLinkPopoverProps = PropsWithChildren;

export const RichTextLinkPopover = ({
  children,
}: RichTextLinkPopoverProps): ReactNode => {
  const { editor } = useCurrentEditor();

  const [linkInputValue, setLinkInputValue] = useState('');

  const setLink = useCallback(
    (linkValue: string) => {
      if (!editor) {
        return;
      }

      // Remove link on empty
      if (!linkValue) {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
      } else {
        const href = !urlHasProtocol(linkValue)
          ? `http://${linkValue}`
          : linkValue;

        // update link href
        const chain = editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href });

        const { selection } = editor.state;

        // Set link label to link url when no selected text and not already in a link
        const shouldPutLinkHrefAsText =
          selection.empty && !editor.isActive('link');
        if (shouldPutLinkHrefAsText) {
          chain.insertContent(linkValue);
        }

        // Move selection to end of current selection (plus link length if inserted as text)
        chain.setTextSelection(
          selection.$to.pos + (shouldPutLinkHrefAsText ? linkValue.length : 0),
        );
        if (!editor.isActive('link')) {
          // Unset mark if not inside it to avoid writing on the link text when typing
          chain.unsetMark('link', { extendEmptyMarkRange: false });
        }

        chain.run();
      }
    },
    [editor],
  );

  return (
    <Popover
      size="sm"
      closeOnBlur
      closeOnEsc
      onOpen={() => {
        // Initialize the input with the current link
        setLinkInputValue(editor?.getAttributes('link')['href'] ?? '');
      }}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setLink(linkInputValue);
            }
          }}
        >
          <PopoverArrow />
          <PopoverHeader>Add a link</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Input
              type="url"
              value={linkInputValue}
              onChange={(e) => setLinkInputValue(e.target.value)}
            />
          </PopoverBody>
          <PopoverFooter p="2">
            <HStack>
              <Button
                isDisabled={!linkInputValue}
                onClick={() => setLink(linkInputValue)}
              >
                Save
              </Button>
              <Button
                variant="ghost"
                isDisabled={!linkInputValue}
                onClick={() => setLink('')}
              >
                Remove link
              </Button>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
