import { AlertCircleIcon, CheckCircle2Icon, LoaderIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useIsMobile,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_Check } from '@dotfile/shared/data-access-client-portal';
import { CheckStatusEnum } from '@dotfile/shared/domain';

import {
  PendingStatuses,
  UnderReviewStatuses,
  useCaseChecks,
} from '../../shared';
import { ChecksEntityList } from './checks-entity-list';
import { ChecksTabItem } from './checks-tab-item';

export const ChecksTabList = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { data } = useCaseChecks();

  const { pending, underReview, completed } = useMemo(() => {
    const checks = [
      ...(data?.case?.companies.flatMap((company) => company.checks) ?? []),
      ...(data?.case?.individuals.flatMap((individual) => individual.checks) ??
        []),
    ];

    return checks.reduce(
      (acc, check) => {
        if (PendingStatuses.includes(check.status)) {
          return {
            ...acc,
            pending: [...acc.pending, check],
          };
        } else if (UnderReviewStatuses.includes(check.status)) {
          return {
            ...acc,
            underReview: [...acc.underReview, check],
          };
        } else if (check.status === CheckStatusEnum.approved) {
          return {
            ...acc,
            completed: [...acc.completed, check],
          };
        }

        return acc;
      },
      {
        pending: [],
        underReview: [],
        completed: [],
      } as Record<
        'pending' | 'underReview' | 'completed',
        ClientPortalChecks_Check[]
      >,
    );
  }, [data?.case]);

  return (
    <Tabs mt="2" isLazy>
      <TabList
        height="10"
        borderBottom="2px"
        overflowX={isMobile ? 'auto' : 'hidden'}
        overflowY="hidden"
        borderColor="gray.200"
      >
        {pending.length !== 0 && (
          <ChecksTabItem
            icon={AlertCircleIcon}
            label={t('checks.tabs.pending_action.title', {
              ns: 'client-portal',
              defaultValue: 'Pending actions',
            })}
            pending={pending.length}
          />
        )}
        {underReview.length !== 0 && (
          <ChecksTabItem
            icon={LoaderIcon}
            label={t('checks.tabs.under_review.title', {
              ns: 'client-portal',
              defaultValue: 'Under review',
            })}
          />
        )}
        {completed.length !== 0 && (
          <ChecksTabItem
            icon={CheckCircle2Icon}
            label={t('checks.tabs.completed.title', {
              ns: 'client-portal',
              defaultValue: 'Completed',
            })}
          />
        )}
      </TabList>

      <TabPanels mt={{ base: 2, md: 6 }}>
        {pending.length !== 0 && (
          <TabPanel px="0" pb="0">
            <ChecksEntityList statuses={PendingStatuses} />
          </TabPanel>
        )}
        {underReview.length !== 0 && (
          <TabPanel px="0" pb="0">
            <ChecksEntityList statuses={UnderReviewStatuses} />
          </TabPanel>
        )}
        {completed.length !== 0 && (
          <TabPanel px="0" pb="0">
            <ChecksEntityList statuses={[CheckStatusEnum.approved]} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};
