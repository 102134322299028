import { SystemStyleFunction } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

const baseStyle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 1,
    textTransform: 'none',
    fontSize: 'sm',
    borderRadius: 'full',
    fontWeight: 500,
    padding: '2px 8px',
    border: '1px solid',
    bg: c === 'default' ? 'white' : `${c}.100`,
    color: c === 'default' ? 'gray.700' : `${c}.700`,
    borderColor: c === 'default' ? 'gray.100' : `${c}.200`,
  };
};

const defaultProps = {
  colorScheme: 'default',
};

export const PillTheme: ComponentStyleConfig = {
  baseStyle,
  defaultProps,
};
