export const webhookEventDefinition = [
  { name: 'Case.Created' },
  { name: 'Case.Deleted' },
  { name: 'Case.ReviewConfirmed' },
  { name: 'Case.ReviewDue' },
  { name: 'Case.ReviewUpdated' },
  {
    name: 'Case.Updated',
    children: [
      {
        name: 'Case.TagsUpdated',
        aliasFor: ['Case.TagsAdded', 'Case.TagsRemoved'],
      },
      { name: 'Case.StatusUpdated' },
      { name: 'Case.FlagsUpdated' },
      { name: 'Case.ContactHasActionsUpdated' },
      { name: 'Case.ReviewerHasActionsUpdated' },
      { name: 'Case.RiskUpdated' },
      { name: 'Case.InfoUpdated' },
      { name: 'Case.TemplateUpdated' },
      { name: 'Case.MetadataUpdated' },
      { name: 'Case.AssigneeUpdated' },
    ],
  },
  { name: 'CaseReport.Generated' },
  { name: 'Check.Approved' },
  { name: 'Check.Deleted' },
  { name: 'Check.Expired' },
  { name: 'Check.Rejected' },
  { name: 'Check.ReviewNeeded' },
  { name: 'Check.Started' },
  { name: 'Company.Created' },
  { name: 'Company.Deleted' },
  {
    name: 'Company.Updated',
    children: [
      { name: 'Company.InfoUpdated' },
      { name: 'Company.MarkedAsRelevant' },
      { name: 'Company.MarkedAsNotRelevant' },
    ],
  },
  { name: 'DocumentOrder.Completed' },
  { name: 'DocumentOrder.Failed' },
  { name: 'Individual.Created' },
  { name: 'Individual.Deleted' },
  {
    name: 'Individual.Updated',
    children: [
      { name: 'Individual.InfoUpdated' },
      { name: 'Individual.MarkedAsRelevant' },
      { name: 'Individual.MarkedAsNotRelevant' },
    ],
  },
  { name: 'Note.Created' },
  { name: 'Note.Deleted' },
  { name: 'Note.Updated' },
  { name: 'NoteComment.Created' },
  { name: 'NoteComment.Deleted' },
  { name: 'NoteComment.Updated' },
] as const;
