import { uniqBy } from 'lodash';

import { ContextTypeEnum } from '../../../shared/context';
import { AppEventName } from '../../../shared/event';
import {
  ActivityContextModel,
  ActivityModel,
  ActivityTypeEnum,
} from '../../../shared/models';

/**
 * Merge activity context stored in columns (`caseId`, `companyId` and `individualId`)
 * and activity context stored in the jsonb column `context`
 */
export type AllActivityContext =
  // Context stored in jsonb `context` column
  ActivityContextModel &
    // Context stored in columns
    Pick<ActivityModel, 'caseId'> &
    Partial<Pick<ActivityModel, 'companyId' | 'individualId' | 'checkDataId'>>;

export type ActivityAllContextKeys = keyof AllActivityContext;

export type ActivityDefinition = {
  /**
   * The event that will trigger the activity from libs/shared/domain/src/lib/shared/event/app-event.ts
   */
  sourceEvent: AppEventName;

  /**
   * Context from libs/shared/domain/src/lib/shared/context/context.type.ts
   */
  contextType: ContextTypeEnum;

  /**
   * The activity that will be created on the `sourceEvent` in `contextType`.
   */
  activityType: ActivityTypeEnum;

  /**
   * Declare which context key will be set on this activity, including context
   * stored in columns (`caseId`, `companyId` and `individualId`)
   * @deprecated Not used currently
   */
  contextKeys: ActivityAllContextKeys[];

  /**
   * Component name in UpperCamelCase of icon, eg `'FolderClosed'` or `'Building2'`.
   * @see https://lucide.dev/icons/
   */
  icon: string;

  /**
   * Label of the activity, can use the token listed below surrounded with
   * curly braces (eg `{userFullName}`). Multiple tokens can be specified and the
   * first with a value will be used (eg to have either the `individualFullName` or
   * `companyName` -> `{individualFullName|companyName}`).
   */
  labelPattern: string;

  /**
   * Shorted label pattern to be shown on individual or company activity log
   * Optional, will default to `labelPattern`.
   */
  shortLabelPattern?: string;
};

export const activityDefinitions: Readonly<ActivityDefinition[]> = [
  // Case
  // Case.Created
  {
    sourceEvent: 'Case.Created',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__created__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} created via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.Created',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__created__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{userFullName} created {caseName}',
  },
  {
    sourceEvent: 'Case.Created',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__created__case,
    contextKeys: ['caseId', 'clientPortalId'],
    icon: 'FolderClosed',
    labelPattern: '{contactFullName} created {caseName} via {clientPortalName}',
  },
  // Case.InfoUpdated
  {
    sourceEvent: 'Case.InfoUpdated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated_info__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} updated via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.InfoUpdated',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__updated_info__case,
    contextKeys: ['caseId', 'clientPortalId'],
    icon: 'FolderClosed',
    labelPattern: '{contactFullName} updated {caseName} via {clientPortalName}',
  },
  {
    sourceEvent: 'Case.InfoUpdated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated_info__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{userFullName} updated {caseName}',
  },
  // Case.StatusUpdated
  {
    sourceEvent: 'Case.StatusUpdated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated_status__case,
    contextKeys: ['caseId', 'caseStatus'],
    icon: 'FolderClosed',
    labelPattern:
      '{caseName} status updated to {caseStatus} via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.StatusUpdated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated_status__case,
    contextKeys: ['caseId', 'caseStatus', 'caseReviewId'],
    icon: 'FolderClosed',
    labelPattern:
      '{userFullName} updated the status of {caseName} to {caseStatus}',
  },
  {
    sourceEvent: 'Case.StatusUpdated',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__updated_status__case,
    contextKeys: ['caseId', 'caseStatus', 'caseReviewId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} status automatically updated to {caseStatus}',
  },
  // Case.ReviewUpdated
  {
    sourceEvent: 'Case.ReviewUpdated',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__updated_review__case,
    contextKeys: ['caseId', 'caseReviewId'],
    icon: 'FolderSync',
    labelPattern:
      'Periodic review of {caseName} has been automatically updated',
  },
  // Case.ReviewConfirmed
  {
    sourceEvent: 'Case.ReviewConfirmed',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__confirmed_review__case,
    contextKeys: ['caseId', 'caseReviewId'],
    icon: 'FolderSync',
    labelPattern: '{userFullName} confirmed the periodic review of {caseName}',
  },
  {
    sourceEvent: 'Case.ReviewConfirmed',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__confirmed_review__case,
    contextKeys: ['caseId', 'caseReviewId'],
    icon: 'FolderSync',
    labelPattern:
      'Periodic review of {caseName} has been confirmed via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.ReviewConfirmed',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__confirmed_review__case,
    contextKeys: ['caseId', 'caseReviewId'],
    icon: 'FolderSync',
    labelPattern:
      'Periodic review of {caseName} has been automatically confirmed',
  },
  // Case.AssigneeUpdated
  {
    sourceEvent: 'Case.AssigneeUpdated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated_assignee__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} assignee updated via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.AssigneeUpdated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated_assignee__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{userFullName} updated {caseName} assignee',
  },
  // Case.TemplateUpdated
  {
    sourceEvent: 'Case.TemplateUpdated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated_template__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} template updated via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.TemplateUpdated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated_template__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{userFullName} updated {caseName} template',
  },
  {
    sourceEvent: 'Case.TemplateUpdated',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__updated_template__case,
    contextKeys: ['caseId', 'clientPortalId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} template automatically updated',
  },
  {
    sourceEvent: 'Case.TemplateUpdated',
    contextType: ContextTypeEnum.workflow,
    activityType: ActivityTypeEnum.workflow__updated_template__case,
    contextKeys: ['caseId', 'workflowExecutionId', 'clientPortalId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} template changed by {workflowName}',
  },
  // Case.MetadataUpdated
  {
    sourceEvent: 'Case.MetadataUpdated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated_metadata__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} metadata updated via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.MetadataUpdated',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__updated_metadata__case,
    contextKeys: ['caseId', 'clientPortalId'],
    icon: 'FolderClosed',
    labelPattern:
      '{contactFullName} updated {caseName} metadata via {clientPortalName}',
  },
  {
    sourceEvent: 'Case.MetadataUpdated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated_metadata__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{userFullName} updated {caseName} metadata',
  },
  // Case.RiskUpdated
  {
    sourceEvent: 'Case.RiskUpdated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated_risk__case,
    contextKeys: ['caseId', 'riskId'],
    icon: 'BarChart',
    labelPattern:
      'Risk automatically updated to {riskLevel} via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.RiskUpdated',
    contextType: ContextTypeEnum.workflow,
    activityType: ActivityTypeEnum.workflow__updated_risk__case,
    contextKeys: [
      'caseId',
      'workflowExecutionId',
      'riskEngineVersionId',
      'riskId',
    ],
    icon: 'BarChart',
    labelPattern: 'Risk automatically updated to {riskLevel} by {workflowName}',
  },
  {
    sourceEvent: 'Case.RiskUpdated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated_risk__case,
    contextKeys: ['caseId', 'riskId'],
    icon: 'BarChart',
    labelPattern: '{userFullName} has manually updated Risk to {riskLevel}',
  },
  // Case.TagsAdded
  {
    sourceEvent: 'Case.TagsAdded',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__added_tags__case,
    contextKeys: ['caseId'],
    icon: 'Tag',
    labelPattern: 'Tags added on {caseName} via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.TagsAdded',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__added_tags__case,
    contextKeys: ['caseId'],
    icon: 'Tag',
    labelPattern: '{userFullName} added tags on {caseName}',
  },
  {
    sourceEvent: 'Case.TagsAdded',
    contextType: ContextTypeEnum.workflow,
    activityType: ActivityTypeEnum.workflow__added_tags__case,
    contextKeys: ['caseId', 'workflowExecutionId', 'clientPortalId'],
    icon: 'Tag',
    labelPattern: 'Tags added on {caseName} by {workflowName}',
  },
  // Case.TagsRemoved
  {
    sourceEvent: 'Case.TagsRemoved',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__removed_tags__case,
    contextKeys: ['caseId'],
    icon: 'Tag',
    labelPattern: 'Tags removed on {caseName} via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.TagsRemoved',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__removed_tags__case,
    contextKeys: ['caseId'],
    icon: 'Tag',
    labelPattern: '{userFullName} removed tags on {caseName}',
  },
  // CaseRelation.Updated
  {
    sourceEvent: 'CaseRelation.Updated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated__case_relation,
    contextKeys: ['caseRelationId'],
    icon: 'Network',
    labelPattern:
      'Relation {caseRelationName} updated via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'CaseRelation.Updated',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__updated__case_relation,
    contextKeys: ['caseRelationId', 'clientPortalId'],
    icon: 'Network',
    labelPattern:
      '{contactFullName} updated {caseRelationName} via {clientPortalName}',
  },
  {
    sourceEvent: 'CaseRelation.Updated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated__case_relation,
    contextKeys: ['caseRelationId'],
    icon: 'Network',
    labelPattern: '{userFullName} updated {caseRelationName}',
  },
  // Case.Deleted
  {
    sourceEvent: 'Case.Deleted',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__deleted__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{caseName} deleted via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Case.Deleted',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__deleted__case,
    contextKeys: ['caseId'],
    icon: 'FolderClosed',
    labelPattern: '{userFullName} deleted {caseName}',
  },
  // Case.RunTemplate
  {
    sourceEvent: 'Case.RunTemplate',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__run__template,
    contextKeys: ['caseId', 'templateId'],
    icon: 'Layers',
    labelPattern: '{userFullName} has manually run template {templateName}',
  },
  {
    sourceEvent: 'Case.RunTemplate',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__run__template,
    contextKeys: ['caseId', 'templateId'],
    icon: 'Layers',
    labelPattern:
      'Template {templateName} has run manually via API (key: {apiKeyName})',
  },
  // @NOTE feels a bit redundant with entity created events and check started automatically via Template
  // Could reactivate later if we feel it misses
  // {
  //   sourceEvent: 'Case.RunTemplate',
  //   contextType: ContextTypeEnum.system,
  //   activityType: ActivityTypeEnum.system__run__template,
  //   contextKeys: ['caseId', 'templateId'],
  //   icon: 'Layers',
  //   labelPattern: 'Template {templateName} has run automatically',
  // },

  // On Communication.Received
  {
    sourceEvent: 'Communication.Received',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__received__communication,
    contextKeys: ['caseId', 'communicationMessageId'],
    icon: 'Mail',
    labelPattern: 'An email from {recipientInfo} has been received',
  },
  // On Communication.Sent
  {
    sourceEvent: 'Communication.Sent',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__sent__communication,
    contextKeys: ['caseId', 'communicationMessageId'],
    icon: 'Mail',
    labelPattern:
      'A reminder email has been automatically sent to {recipientInfo}',
  },
  {
    sourceEvent: 'Communication.Sent',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__sent__communication,
    contextKeys: ['caseId', 'communicationMessageId'],
    icon: 'Mail',
    labelPattern: '{userFullName} sent an email to {recipientInfo}',
  },

  // Company
  // Company.Created
  {
    sourceEvent: 'Company.Created',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__created__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'Building2',
    labelPattern: '{companyName} created via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Company.Created',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__created__company,
    contextKeys: ['caseId', 'companyId', 'clientPortalId'],
    icon: 'Building2',
    labelPattern:
      '{contactFullName} created {companyName}  via {clientPortalName}',
  },
  {
    sourceEvent: 'Company.Created',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__created__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'Building2',
    labelPattern: '{userFullName} created {companyName}',
  },
  // Company.Updated
  {
    sourceEvent: 'Company.InfoUpdated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'Building2',
    labelPattern: '{companyName} updated via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Company.InfoUpdated',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__updated__company,
    contextKeys: ['caseId', 'companyId', 'clientPortalId'],
    icon: 'Building2',
    labelPattern:
      '{contactFullName} updated {companyName} via {clientPortalName}',
  },
  {
    sourceEvent: 'Company.InfoUpdated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'Building2',
    labelPattern: '{userFullName} updated {companyName}',
  },
  // Company.MarkAsRelevant
  {
    sourceEvent: 'Company.MarkedAsRelevant',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__marked_as_relevant__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'Eye',
    labelPattern:
      '{companyName} marked as relevant via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Company.MarkedAsRelevant',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__marked_as_relevant__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'Eye',
    labelPattern: '{userFullName} marked {companyName} as relevant',
  },
  // Company.MarkAsNotRelevant
  {
    sourceEvent: 'Company.MarkedAsNotRelevant',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__marked_as_not_relevant__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'EyeOff',
    labelPattern:
      '{companyName} marked as not relevant via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Company.MarkedAsNotRelevant',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__marked_as_not_relevant__company,
    contextKeys: ['caseId', 'companyId', 'clientPortalId'],
    icon: 'EyeOff',
    labelPattern:
      '{contactFullName} marked {companyName} as not relevant via {clientPortalName}',
  },
  {
    sourceEvent: 'Company.MarkedAsNotRelevant',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__marked_as_not_relevant__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'EyeOff',
    labelPattern: '{userFullName} marked {companyName} as not relevant',
  },
  // Company.Deleted
  {
    sourceEvent: 'Company.Deleted',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__deleted__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'Building2',
    labelPattern: '{companyName} deleted via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Company.Deleted',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__deleted__company,
    contextKeys: ['caseId', 'companyId'],
    icon: 'Building2',
    labelPattern: '{userFullName} deleted {companyName}',
  },
  // CompanyData
  // CompanyData.Purchased
  {
    sourceEvent: 'CompanyData.Purchased',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__purchased__company_data,
    contextKeys: ['vendor'],
    icon: 'Building2',
    labelPattern:
      '{userFullName} purchase {vendorName} official data for {companyName}',
  },
  // Individual
  // Individual.Created
  {
    sourceEvent: 'Individual.Created',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__created__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'User',
    labelPattern: '{individualFullName} created via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Individual.Created',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__created__individual,
    contextKeys: ['caseId', 'individualId', 'clientPortalId'],
    icon: 'User',
    labelPattern:
      '{contactFullName} created {individualFullName}  via {clientPortalName}',
  },
  {
    sourceEvent: 'Individual.Created',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__created__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'User',
    labelPattern: '{userFullName} created {individualFullName}',
  },
  // Individual.InfoUpdated
  {
    sourceEvent: 'Individual.InfoUpdated',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__updated__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'User',
    labelPattern: '{individualFullName} updated via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Individual.InfoUpdated',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__updated__individual,
    contextKeys: ['caseId', 'individualId', 'clientPortalId'],
    icon: 'User',
    labelPattern:
      '{contactFullName} updated {individualFullName}  via {clientPortalName}',
  },
  {
    sourceEvent: 'Individual.InfoUpdated',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__updated__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'User',
    labelPattern: '{userFullName} updated {individualFullName}',
  },
  // Individual.MarkAsRelevant
  {
    sourceEvent: 'Individual.MarkedAsRelevant',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__marked_as_relevant__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'Eye',
    labelPattern:
      '{individualFullName} marked as relevant via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Individual.MarkedAsRelevant',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__marked_as_relevant__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'Eye',
    labelPattern: '{userFullName} marked {individualFullName} as relevant',
  },
  // Individual.MarkAsNotRelevant
  {
    sourceEvent: 'Individual.MarkedAsNotRelevant',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__marked_as_not_relevant__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'EyeOff',
    labelPattern:
      '{individualFullName} marked as not relevant via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Individual.MarkedAsNotRelevant',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__marked_as_not_relevant__individual,
    contextKeys: ['caseId', 'individualId', 'clientPortalId'],
    icon: 'EyeOff',
    labelPattern:
      '{contactFullName} marked {individualFullName} as not relevant via {clientPortalName}',
  },
  {
    sourceEvent: 'Individual.MarkedAsNotRelevant',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__marked_as_not_relevant__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'EyeOff',
    labelPattern: '{userFullName} marked {individualFullName} as not relevant',
  },
  // Individual.Deleted
  {
    sourceEvent: 'Individual.Deleted',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__deleted__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'User',
    labelPattern: '{individualFullName} deleted via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Individual.Deleted',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__deleted__individual,
    contextKeys: ['caseId', 'individualId'],
    icon: 'User',
    labelPattern: '{userFullName} deleted {individualFullName}',
  },
  // Individual.CollaborationEmailSent
  {
    sourceEvent: 'Individual.CollaborationEmailSentTo',
    contextType: ContextTypeEnum.client_portal,
    activityType:
      ActivityTypeEnum.contact__sent_collaboration_email__individual,
    contextKeys: ['caseId', 'individualId', 'clientPortalId'],
    icon: 'Mail',
    labelPattern:
      '{contactFullName} sent a collaboration invitation email to {individualFullName} via {clientPortalName}',
  },

  // Check
  // Check.Started
  {
    sourceEvent: 'Check.Started',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__started__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'Play',
    labelPattern:
      '{checkTitle} started via API for {companyName|individualFullName}',
    shortLabelPattern: '{checkTitle} started (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Check.Started',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__started__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
      'clientPortalId',
    ],
    icon: 'Play',
    labelPattern:
      '{contactFullName} started {checkTitle} for {companyName|individualFullName} via {clientPortalName}',
    shortLabelPattern:
      '{contactFullName} started {checkTitle} via {clientPortalName}',
  },
  {
    sourceEvent: 'Check.Started',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__started__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'Play',
    labelPattern:
      '{userFullName} started {checkTitle} for {companyName|individualFullName}',
    shortLabelPattern: '{userFullName} started {checkTitle}',
  },
  {
    sourceEvent: 'Check.Started',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__started__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'PlayCircle',
    labelPattern:
      '{checkTitle} started automatically for {companyName|individualFullName}',
    shortLabelPattern: '{checkTitle} started automatically',
  },
  {
    sourceEvent: 'Check.Started',
    contextType: ContextTypeEnum.template,
    activityType: ActivityTypeEnum.template__started__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'PlayCircle',
    labelPattern:
      '{checkTitle} started automatically for {companyName|individualFullName} via template {templateName}',
    shortLabelPattern:
      '{checkTitle} started automatically via template {templateName}',
  },
  {
    sourceEvent: 'Check.Started',
    contextType: ContextTypeEnum.workflow,
    activityType: ActivityTypeEnum.workflow__started__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
      'clientPortalId',
      'workflowExecutionId',
    ],
    icon: 'PlayCircle',
    labelPattern:
      '{checkTitle} check started automatically for {companyName|individualFullName} by {workflowName}',
    shortLabelPattern: '{checkTitle} started automatically by {workflowName}',
  },
  // Check.FlowStarted
  {
    sourceEvent: 'Check.FlowStarted',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.end_user__started_flow__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'Play',
    labelPattern:
      'End user has started the {checkTitle} flow for {companyName|individualFullName}',
    shortLabelPattern: 'End user has started the {checkTitle} flow',
  },
  // Check.FlowFinished
  {
    sourceEvent: 'Check.FlowFinished',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.end_user__finished_flow__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'Loader',
    labelPattern:
      'End user has finished the {checkTitle} flow for {companyName|individualFullName}',
    shortLabelPattern: 'End user has finished the {checkTitle} flow',
  },
  // Check.ReviewNeeded
  {
    sourceEvent: 'Check.ReviewNeeded',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__set_review_needed__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'AlertCircle',
    labelPattern:
      '{checkTitle} for {companyName|individualFullName} needs review',
    shortLabelPattern: '{checkTitle} needs review',
  },
  {
    sourceEvent: 'Check.ReviewNeeded',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__set_review_needed__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'AlertCircle',
    labelPattern:
      '{checkTitle} has been set in review for {companyName|individualFullName} via API (key: {apiKeyName})',
    shortLabelPattern:
      '{checkTitle} has been set in review via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Check.ReviewNeeded',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__set_review_needed__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
      'clientPortalId',
    ],
    icon: 'AlertCircle',
    labelPattern:
      '{contactFullName} set in review {checkTitle} for {companyName|individualFullName} via {clientPortalName}',
    shortLabelPattern:
      '{contactFullName} set in review {checkTitle} via {clientPortalName}',
  },
  // Check.Approved
  {
    sourceEvent: 'Check.Approved',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__approved__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'CheckCircle2',
    labelPattern:
      '{checkTitle} has been approved for {companyName|individualFullName} via API (key: {apiKeyName})',
    shortLabelPattern:
      '{checkTitle} has been approved via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Check.Approved',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__approved__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'CheckCircle2',
    labelPattern:
      '{checkTitle} has been automatically approved for {companyName|individualFullName}',
    shortLabelPattern: '{checkTitle} has been automatically approved',
  },
  {
    sourceEvent: 'Check.Approved',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__approved__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'CheckCircle2',
    labelPattern:
      '{userFullName} approved {checkTitle} for {companyName|individualFullName}',
    shortLabelPattern: '{userFullName} approved {checkTitle}',
  },
  // Check.Rejected
  {
    sourceEvent: 'Check.Rejected',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__rejected__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'XCircle',
    labelPattern:
      '{checkTitle} has been rejected for {companyName|individualFullName} via API (key: {apiKeyName})',
    shortLabelPattern:
      '{checkTitle} has been rejected via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Check.Rejected',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__rejected__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'XCircle',
    labelPattern:
      '{checkTitle} has been automatically rejected for {companyName|individualFullName}',
    shortLabelPattern: '{checkTitle} has been automatically rejected',
  },
  {
    sourceEvent: 'Check.Rejected',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__rejected__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'XCircle',
    labelPattern:
      '{userFullName} rejected {checkTitle} for {companyName|individualFullName}',
    shortLabelPattern: '{userFullName} rejected {checkTitle}',
  },
  // Check.MonitoringEnabled
  {
    sourceEvent: 'Check.MonitoringEnabled',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__enabled_monitoring__check,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'RadioTower',
    labelPattern:
      '{userFullName} enabled monitoring on {checkTitle} for {companyName|individualFullName}',
  },
  {
    sourceEvent: 'Check.MonitoringEnabled',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__enabled_monitoring__check,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'RadioTower',
    labelPattern:
      'Monitoring has been enabled on {checkTitle} for {companyName|individualFullName} via API (key: {apiKeyName})',
  },
  // Check.MonitoringDisabled
  {
    sourceEvent: 'Check.MonitoringDisabled',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__disabled_monitoring__check,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'RadioTower',
    labelPattern:
      '{userFullName} disabled monitoring on {checkTitle} for {companyName|individualFullName}',
  },
  {
    sourceEvent: 'Check.MonitoringDisabled',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__disabled_monitoring__check,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'RadioTower',
    labelPattern:
      'Monitoring has been disabled on {checkTitle} for {companyName|individualFullName} via API (key: {apiKeyName})',
  },
  {
    sourceEvent: 'Check.MonitoringDisabled',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__disabled_monitoring__check,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'RadioTower',
    labelPattern:
      'Monitoring has been disabled on {checkTitle} for {companyName|individualFullName}',
  },
  // Check.Expired
  {
    sourceEvent: 'Check.Expired',
    contextType: ContextTypeEnum.system,
    activityType: ActivityTypeEnum.system__expired__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'Timer',
    labelPattern:
      '{checkTitle} has expired for {companyName|individualFullName}',
    shortLabelPattern: '{checkTitle} has expired',
  },
  // Check.Deleted
  {
    sourceEvent: 'Check.Deleted',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__deleted__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'Trash',
    labelPattern:
      '{userFullName} deleted {checkTitle} for {companyName|individualFullName}',
  },
  {
    sourceEvent: 'Check.Deleted',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__deleted__check,
    contextKeys: [
      'caseId',
      'companyId',
      'individualId',
      'checkType',
      'checkDataId',
    ],
    icon: 'Trash',
    labelPattern:
      '{checkTitle} has been deleted on case {caseName} via API (key: {apiKeyName})',
  },

  // AmlHit
  // AmlHit.Ignored
  {
    sourceEvent: 'AmlHit.Ignored',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__ignored__aml_hit,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'ScanLine',
    labelPattern:
      '{userFullName} discarded {amlHitsCount} AML hits for {companyName|individualFullName}',
  },
  {
    sourceEvent: 'AmlHit.Ignored',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__ignored__aml_hit,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'ScanLine',
    labelPattern:
      '{amlHitsCount} AML hits discarded for {companyName|individualFullName} via API (key: {apiKeyName})',
  },
  // AmlHit.Confirmed
  {
    sourceEvent: 'AmlHit.Confirmed',
    contextType: ContextTypeEnum.user,
    activityType: ActivityTypeEnum.user__confirmed__aml_hit,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'ScanLine',
    labelPattern:
      '{userFullName} confirmed {amlHitsCount} AML hits for {companyName|individualFullName}',
  },
  {
    sourceEvent: 'AmlHit.Confirmed',
    contextType: ContextTypeEnum.workspace,
    activityType: ActivityTypeEnum.api__confirmed__aml_hit,
    contextKeys: ['checkType', 'checkDataId', 'companyId', 'individualId'],
    icon: 'ScanLine',
    labelPattern:
      '{amlHitsCount} AML hits confirmed for {companyName|individualFullName} via API (key: {apiKeyName})',
  },

  // ClientPortal

  // ClientPortal.FormsCompleted
  {
    sourceEvent: 'ClientPortal.FormsCompleted',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__completed_forms__client_portal,
    contextKeys: ['clientPortalId', 'individualId'],
    icon: 'PanelsTopLeft',
    labelPattern: '{contactFullName} completed all forms in {clientPortalName}',
  },
  // ClientPortal.ChecksCompleted
  {
    sourceEvent: 'ClientPortal.ChecksCompleted',
    contextType: ContextTypeEnum.client_portal,
    activityType: ActivityTypeEnum.contact__completed_checks__client_portal,
    contextKeys: ['clientPortalId', 'individualId'],
    icon: 'PanelsTopLeft',
    labelPattern:
      '{contactFullName} completed all checks in {clientPortalName}',
  },
];

// Check uniqueness of descriptions
if (
  uniqBy(activityDefinitions, (a) => `${a.sourceEvent}-${a.contextType}`)
    .length !== activityDefinitions.length
) {
  // If this error happen too often, maybe improve the error message to include the duplicate values
  throw new Error(
    'There is duplicate activity description on the same `sourceEvent`/`contextType`',
  );
}
if (
  uniqBy(activityDefinitions, (a) => a.activityType).length !==
  activityDefinitions.length
) {
  // If this error happen too often, maybe improve the error message to include the duplicate values
  throw new Error(
    'There is duplicate activity description on the same `activityType`',
  );
}
