import {
  CaseFlagEnum,
  CaseModel,
  RiskModeEnum,
  TagModel,
  TemplateModel,
  UserModel,
  ViewColumnMappingEntityEnum,
  ViewColumnMappingTypeEnum,
} from '../../../shared/models';
import { PropertyTypeEnum } from '../../../shared/properties';
import { caseDefaultPropertiesDefinition } from '../../../shared/properties';
import { progressDefinitions } from '../case';
import { ViewColumn } from './types';
import { ViewColumnBuilder } from './view-column-builder';

type CaseViewColumn = Omit<
  // @NOTE Also update the test snapshot when modifying the properties
  CaseModel,
  | 'flags'
  | 'workspace'
  | 'workspaceId'
  | 'assigneeId'
  | 'templateId'
  | 'metadata'
  | 'report'
  | 'clientPortal'
  | 'clientPortalId'
  | 'risks'
  | 'caseTags'
  | 'companies'
  | 'individuals'
  | 'customPropertyValues'
  | 'relations'
  | 'notes'
  | 'communicationMessages'
  | 'reviews'
  | 'reminder'
  | 'lastContactActionAt'
  | 'updatedAt'
  | 'deletedAt'
> & {
  nextReviewAt: Date;
  progress: CaseFlagEnum;
  currentRisk: RiskModeEnum;
  template: TemplateModel;
  tags: TagModel[];
  assignee: UserModel;
};

export const caseViewColumnDefinition: Record<
  keyof CaseViewColumn,
  ViewColumn
> = new ViewColumnBuilder<CaseViewColumn>(ViewColumnMappingEntityEnum.case)
  .addViewColumn('id', caseDefaultPropertiesDefinition.id, {
    canSort: false,
  })
  .addViewColumn('name', caseDefaultPropertiesDefinition.name)
  .addViewColumn('nextReviewAt', PropertyTypeEnum.date, {
    label: 'Next review',
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
  })
  .addViewColumn('progress', PropertyTypeEnum.choices, {
    mapping: {
      type: ViewColumnMappingTypeEnum.computed,
    },
    settings: {
      allowMultiple: false,
      options: progressDefinitions.map(({ progress, label }) => ({
        key: progress,
        label: label,
      })),
    },
  })
  .addViewColumn('currentRisk', caseDefaultPropertiesDefinition.currentRisk, {
    filter: {
      type: PropertyTypeEnum.choices,
      settings: {
        allowMultiple: false,
      },
    },
    canSort: true,
  })
  .addViewColumn('template', PropertyTypeEnum.unknown, {
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    filter: {
      type: PropertyTypeEnum.choices,
      settings: {
        allowMultiple: false,
      },
    },
  })
  .addViewColumn('tags', PropertyTypeEnum.choices, {
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    settings: {
      allowMultiple: true,
    },
  })
  .addViewColumn('assignee', PropertyTypeEnum.unknown, {
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    filter: {
      type: PropertyTypeEnum.choices,
      settings: {
        allowMultiple: false,
      },
    },
    canSort: true,
  })
  .addViewColumn('status', caseDefaultPropertiesDefinition.status)
  .addViewColumn(
    'lastActivityAt',
    caseDefaultPropertiesDefinition.lastActivityAt,
  )
  .addViewColumn('createdAt', caseDefaultPropertiesDefinition.createdAt)
  .addViewColumn(
    'contactHasActions',
    caseDefaultPropertiesDefinition.contactHasActions,
  )
  .addViewColumn(
    'reviewerHasActions',
    caseDefaultPropertiesDefinition.reviewerHasActions,
  )
  .addViewColumn('externalId', caseDefaultPropertiesDefinition.externalId)

  .getDefinition();

export const allCaseViewColumnDefinition = Object.values(
  caseViewColumnDefinition,
);
