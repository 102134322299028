import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationCompanies = (): JSX.Element => {
  const { stroke, skeleton } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="0 0 508 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M433 1C433 41.8691 400.317 75 360 75L360 1L433 1Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C441.186 75 507 140.814 507 222L360 222L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M63 75C63 34.1309 95.6832 1 136 1L136 75L63 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M286 75C286 156.186 218.843 222 136 222L136 75L286 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C360 34.1309 326.869 0.999999 286 0.999997L286 75L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
      </g>
      <g opacity="0.7" filter="url(#filter0_ddd_1624_81844)">
        <rect x="188" y="40" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 52C209.448 52 209 52.4477 209 53V69C209 69.5523 209.448 70 210 70H222C222.552 70 223 69.5523 223 69V53C223 52.4477 222.552 52 222 52H210ZM207 53C207 51.3432 208.343 50 210 50H222C223.657 50 225 51.3432 225 53V69C225 70.6569 223.657 72 222 72H210C208.343 72 207 70.6569 207 69V53Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212 67C212 66.4477 212.448 66 213 66H219C219.552 66 220 66.4477 220 67V71C220 71.5523 219.552 72 219 72C218.448 72 218 71.5523 218 71V68H214V71C214 71.5523 213.552 72 213 72C212.448 72 212 71.5523 212 71V67Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 55C211 54.4477 211.448 54 212 54H212.01C212.562 54 213.01 54.4477 213.01 55C213.01 55.5523 212.562 56 212.01 56H212C211.448 56 211 55.5523 211 55Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 55C219 54.4477 219.448 54 220 54H220.01C220.562 54 221.01 54.4477 221.01 55C221.01 55.5523 220.562 56 220.01 56H220C219.448 56 219 55.5523 219 55Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 55C215 54.4477 215.448 54 216 54H216.01C216.562 54 217.01 54.4477 217.01 55C217.01 55.5523 216.562 56 216.01 56H216C215.448 56 215 55.5523 215 55Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 59C215 58.4477 215.448 58 216 58H216.01C216.562 58 217.01 58.4477 217.01 59C217.01 59.5523 216.562 60 216.01 60H216C215.448 60 215 59.5523 215 59Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 63C215 62.4477 215.448 62 216 62H216.01C216.562 62 217.01 62.4477 217.01 63C217.01 63.5523 216.562 64 216.01 64H216C215.448 64 215 63.5523 215 63Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 59C219 58.4477 219.448 58 220 58H220.01C220.562 58 221.01 58.4477 221.01 59C221.01 59.5523 220.562 60 220.01 60H220C219.448 60 219 59.5523 219 59Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 63C219 62.4477 219.448 62 220 62H220.01C220.562 62 221.01 62.4477 221.01 63C221.01 63.5523 220.562 64 220.01 64H220C219.448 64 219 63.5523 219 63Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 59C211 58.4477 211.448 58 212 58H212.01C212.562 58 213.01 58.4477 213.01 59C213.01 59.5523 212.562 60 212.01 60H212C211.448 60 211 59.5523 211 59Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 63C211 62.4477 211.448 62 212 62H212.01C212.562 62 213.01 62.4477 213.01 63C213.01 63.5523 212.562 64 212.01 64H212C211.448 64 211 63.5523 211 63Z"
          fill={stroke}
        />
        <rect x="244" y="57" width="183" height="4" rx="2" fill={skeleton} />
      </g>
      <g opacity="0.7" filter="url(#filter1_ddd_1624_81844)">
        <rect x="188" y="90" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 102C209.448 102 209 102.448 209 103V119C209 119.552 209.448 120 210 120H222C222.552 120 223 119.552 223 119V103C223 102.448 222.552 102 222 102H210ZM207 103C207 101.343 208.343 100 210 100H222C223.657 100 225 101.343 225 103V119C225 120.657 223.657 122 222 122H210C208.343 122 207 120.657 207 119V103Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212 117C212 116.448 212.448 116 213 116H219C219.552 116 220 116.448 220 117V121C220 121.552 219.552 122 219 122C218.448 122 218 121.552 218 121V118H214V121C214 121.552 213.552 122 213 122C212.448 122 212 121.552 212 121V117Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 105C211 104.448 211.448 104 212 104H212.01C212.562 104 213.01 104.448 213.01 105C213.01 105.552 212.562 106 212.01 106H212C211.448 106 211 105.552 211 105Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 105C219 104.448 219.448 104 220 104H220.01C220.562 104 221.01 104.448 221.01 105C221.01 105.552 220.562 106 220.01 106H220C219.448 106 219 105.552 219 105Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 105C215 104.448 215.448 104 216 104H216.01C216.562 104 217.01 104.448 217.01 105C217.01 105.552 216.562 106 216.01 106H216C215.448 106 215 105.552 215 105Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 109C215 108.448 215.448 108 216 108H216.01C216.562 108 217.01 108.448 217.01 109C217.01 109.552 216.562 110 216.01 110H216C215.448 110 215 109.552 215 109Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 113C215 112.448 215.448 112 216 112H216.01C216.562 112 217.01 112.448 217.01 113C217.01 113.552 216.562 114 216.01 114H216C215.448 114 215 113.552 215 113Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 109C219 108.448 219.448 108 220 108H220.01C220.562 108 221.01 108.448 221.01 109C221.01 109.552 220.562 110 220.01 110H220C219.448 110 219 109.552 219 109Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 113C219 112.448 219.448 112 220 112H220.01C220.562 112 221.01 112.448 221.01 113C221.01 113.552 220.562 114 220.01 114H220C219.448 114 219 113.552 219 113Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 109C211 108.448 211.448 108 212 108H212.01C212.562 108 213.01 108.448 213.01 109C213.01 109.552 212.562 110 212.01 110H212C211.448 110 211 109.552 211 109Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 113C211 112.448 211.448 112 212 112H212.01C212.562 112 213.01 112.448 213.01 113C213.01 113.552 212.562 114 212.01 114H212C211.448 114 211 113.552 211 113Z"
          fill={stroke}
        />
        <rect x="244" y="107" width="126" height="4" rx="2" fill={skeleton} />
      </g>
      <g opacity="0.7" filter="url(#filter2_ddd_1624_81844)">
        <rect x="188" y="140" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 152C209.448 152 209 152.448 209 153V169C209 169.552 209.448 170 210 170H222C222.552 170 223 169.552 223 169V153C223 152.448 222.552 152 222 152H210ZM207 153C207 151.343 208.343 150 210 150H222C223.657 150 225 151.343 225 153V169C225 170.657 223.657 172 222 172H210C208.343 172 207 170.657 207 169V153Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212 167C212 166.448 212.448 166 213 166H219C219.552 166 220 166.448 220 167V171C220 171.552 219.552 172 219 172C218.448 172 218 171.552 218 171V168H214V171C214 171.552 213.552 172 213 172C212.448 172 212 171.552 212 171V167Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 155C211 154.448 211.448 154 212 154H212.01C212.562 154 213.01 154.448 213.01 155C213.01 155.552 212.562 156 212.01 156H212C211.448 156 211 155.552 211 155Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 155C219 154.448 219.448 154 220 154H220.01C220.562 154 221.01 154.448 221.01 155C221.01 155.552 220.562 156 220.01 156H220C219.448 156 219 155.552 219 155Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 155C215 154.448 215.448 154 216 154H216.01C216.562 154 217.01 154.448 217.01 155C217.01 155.552 216.562 156 216.01 156H216C215.448 156 215 155.552 215 155Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 159C215 158.448 215.448 158 216 158H216.01C216.562 158 217.01 158.448 217.01 159C217.01 159.552 216.562 160 216.01 160H216C215.448 160 215 159.552 215 159Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215 163C215 162.448 215.448 162 216 162H216.01C216.562 162 217.01 162.448 217.01 163C217.01 163.552 216.562 164 216.01 164H216C215.448 164 215 163.552 215 163Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 159C219 158.448 219.448 158 220 158H220.01C220.562 158 221.01 158.448 221.01 159C221.01 159.552 220.562 160 220.01 160H220C219.448 160 219 159.552 219 159Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 163C219 162.448 219.448 162 220 162H220.01C220.562 162 221.01 162.448 221.01 163C221.01 163.552 220.562 164 220.01 164H220C219.448 164 219 163.552 219 163Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 159C211 158.448 211.448 158 212 158H212.01C212.562 158 213.01 158.448 213.01 159C213.01 159.552 212.562 160 212.01 160H212C211.448 160 211 159.552 211 159Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211 163C211 162.448 211.448 162 212 162H212.01C212.562 162 213.01 162.448 213.01 163C213.01 163.552 212.562 164 212.01 164H212C211.448 164 211 163.552 211 163Z"
          fill={stroke}
        />
        <rect x="244" y="157" width="157" height="4" rx="2" fill={skeleton} />
      </g>
      <defs>
        <filter
          id="filter0_ddd_1624_81844"
          x="0.399979"
          y="-101.4"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_1624_81844"
          x="0.399979"
          y="-51.4"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_ddd_1624_81844"
          x="0.399979"
          y="-1.40002"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
