import { useCallback } from 'react';
import { apiObject } from 'rudder-sdk-js';

import { EventName } from '../events';
import { useAnalytics } from '../index';

/**
 * analytics.track() wrapper
 */
export function useAnalyticsTrack(): (
  event: EventName,
  properties: apiObject,
) => void {
  const analytics = useAnalytics();

  return useCallback(
    (event: string, properties: apiObject) =>
      analytics.track(event, properties),
    [analytics],
  );
}
