import { ForwardedRef, useRef, useState } from 'react';

import { createUseDebounce } from '@dotfile/frontend/shared/common';

import { Divider } from '../../data-display/divider/divider';
// Must specify the full path to divider to avoid circular imports
import { Spinner } from '../../feedback/spinner/spinner';
import { Box } from '../../layout/box/box';
import { Text } from '../../typography/text';
import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { Button } from '../button/button';
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '../input/input';
import {
  AutoCompleteFormatOptionFn,
  AutoCompleteOption,
} from './auto-complete-option';

export type AutoCompleteProps<Option> = InputProps & {
  /**
   * Indicate that option are loading with a spinner in the input text and hide previous options
   */
  isLoading?: boolean;
  /**
   * Force hide of options when there is an error
   */
  hasError?: boolean;
  /**
   * Called with the search term to refresh options
   */
  onLoadOptions: (search: string) => void;

  /**
   * Auto-focus the search input
   */
  autoFocus?: boolean;
  /**
   * Placeholder for the search input
   */
  placeholder?: string;
  /**
   * Optional default search terms, default to ""
   */
  defaultSearch?: string;
  /**
   * Minimum length of the search term to trigger `onLoadOptions`
   */
  minSearchLength: number;

  /**
   * Last loaded options
   */
  options?: Option[];
  /**
   * Function called with each option to format them for display in the list
   */
  formatOption: AutoCompleteFormatOptionFn<Option>;

  /**
   * Called with selected option
   */
  onAutoComplete: (option: Option) => Promise<void> | void;

  /**
   * Title before the option list, in light gray
   */
  title: string;

  /**
   * Displayed when the option array is empty
   */
  noResultsText: string | ((search: string) => string);

  /**
   * Optional action at the bottom of the option list
   */
  footerActionText?: string | ((search: string) => string);
  onFooterAction?: (option?: string) => void;

  /**
   * Optional brand color, default to false
   */
  hasBrandColor?: boolean;
};

const useDebounce = createUseDebounce<[string]>(500);

// Necessary for the generic
// eslint-disable-next-line react/function-component-definition
function AutoCompleteInner<Option>(
  {
    isLoading,
    hasError,
    onLoadOptions,
    minSearchLength,
    autoFocus,
    placeholder,
    defaultSearch = '',
    options,
    formatOption,
    onAutoComplete,
    title,
    noResultsText,
    footerActionText,
    onFooterAction,
    hasBrandColor = false,
    ...field
  }: AutoCompleteProps<Option>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const rootRef = useRef<HTMLDivElement>(null);
  const [lastSearch, setLastSearch] = useState(defaultSearch);
  const [displaySearchResult, setDisplaySearchResult] = useState(
    Boolean(autoFocus),
  );

  const debouncedLoadOptions = useDebounce((search) => {
    if (search.length >= minSearchLength) {
      setLastSearch(search);
      onLoadOptions(search);
    }
  });

  const handleAutoComplete = async (option: Option): Promise<void> => {
    await onAutoComplete(option);
    setDisplaySearchResult(false);
  };

  return (
    <Box
      onBlur={(ev) => {
        // Check if the user click outside input / autocomplete options to hide the search
        if (rootRef.current && !rootRef.current.contains(ev.relatedTarget)) {
          setDisplaySearchResult(false);
        }
      }}
    >
      <InputGroup>
        <Input
          onFocus={() => setDisplaySearchResult(true)}
          {...field}
          ref={ref}
          isInvalid={hasError}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onChange={(event) => {
            field.onChange?.(event);
            debouncedLoadOptions(event.target.value);
          }}
        />
        {isLoading && (
          <InputRightElement>
            <Spinner color="gray.200" size="md" />
          </InputRightElement>
        )}
      </InputGroup>
      {lastSearch.length >= minSearchLength &&
        !isLoading &&
        !hasError &&
        !!field.value &&
        displaySearchResult && (
          <Box
            ref={rootRef}
            tabIndex={0}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            boxShadow="md"
            padding="2"
            position="absolute"
            w="100%"
            zIndex="1"
            bg="white"
          >
            <Text
              mx="5"
              mt="3"
              mb="2"
              fontSize="11"
              color="gray.200"
              fontWeight="500"
              align="start"
            >
              {title}
            </Text>
            <Box
              mb="2"
              fontSize="15"
              // show at most five and half result
              maxHeight="250px"
              overflow="auto"
            >
              {options?.length ? (
                options.map((option) => {
                  return (
                    <AutoCompleteOption
                      key={formatOption(option).id}
                      option={option}
                      formatOption={formatOption}
                      onAutoComplete={handleAutoComplete}
                    />
                  );
                })
              ) : (
                <Text color="gray.500" lineHeight="30px" mx="5" align="start">
                  {typeof noResultsText === 'string'
                    ? noResultsText
                    : noResultsText(lastSearch)}
                </Text>
              )}
            </Box>
            {footerActionText && onFooterAction && (
              <>
                <Divider opacity="1" />
                <Box mx="5" mt="3" mb="2">
                  <Button
                    variant="link"
                    size="md"
                    onClick={() => onFooterAction(lastSearch)}
                    color={hasBrandColor ? 'gray.800' : 'blue.700'}
                    fontSize="15"
                  >
                    {typeof footerActionText === 'string'
                      ? footerActionText
                      : footerActionText(lastSearch)}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}
    </Box>
  );
}

export const AutoComplete = forwardRefWithGeneric(AutoCompleteInner);
