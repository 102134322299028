interface RetryFetchOptions {
  retryCount?: number;
  retryDelay?: number;
}

/**
 * Fetches a URL with automatic retries on failure. If a request fails (network error or 5xx response),
 * it will be retried up to the specified number of times with a delay between attempts.
 *
 * @param input
 * @param options
 * @returns
 */
export async function fetchWithRetry(
  request: Request,
  options: RetryFetchOptions = {},
): Promise<Response> {
  const { retryCount = 3, retryDelay = 1000 } = options;

  let lastError: Error | null = null;

  for (let attempt = 1; attempt <= retryCount; attempt++) {
    try {
      // Clone the request for each attempt since request body can only be read once
      const requestClone = request.clone();
      const response = await fetch(requestClone);

      // Check if response status is in the 5xx range
      if (response.status >= 500 && response.status < 600) {
        throw new Error(`Server error: ${response.status}`);
      }

      return response;
    } catch (error) {
      lastError = error as Error;

      // If this was the last attempt, throw the error
      if (attempt === retryCount) {
        throw new Error(
          `Failed after ${retryCount} attempts. Last error: ${lastError.message}`,
        );
      }

      // Wait before retrying
      await new Promise((resolve) => setTimeout(resolve, retryDelay));

      console.debug(
        `Attempt ${attempt} failed. Retrying... (${retryCount - attempt} attempts remaining)`,
      );
    }
  }

  // This should never be reached due to the error throwing above,
  // but TypeScript needs it for type safety
  throw lastError;
}
