import {
  Button as ChakraButton,
  ButtonGroup as CharkaButtonGroup,
  ButtonGroupProps as CharkaButtonGroupProps,
  ButtonProps as CharkaButtonProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/form/button
export type ButtonProps = CharkaButtonProps;
export const Button = ChakraButton;

export type ButtonGroupProps = CharkaButtonGroupProps;
export const ButtonGroup = CharkaButtonGroup;
