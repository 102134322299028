import {
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
} from '../../../../shared/models';
import { clientPortalStepDefinitionsRecord } from '../definitions';

export const getDefaultStepTypes = (
  clientPortalType: ClientPortalTypeEnum,
): Exclude<
  ClientPortalStepTypeEnum,
  typeof ClientPortalStepTypeEnum.custom
>[] => {
  return Object.values(ClientPortalStepTypeEnum)
    .filter(
      (
        t: ClientPortalStepTypeEnum,
      ): t is Exclude<
        ClientPortalStepTypeEnum,
        typeof ClientPortalStepTypeEnum.custom
      > => t !== ClientPortalStepTypeEnum.custom,
    )
    .filter(
      (
        t: Exclude<
          ClientPortalStepTypeEnum,
          typeof ClientPortalStepTypeEnum.custom
        >,
      ) => {
        const definition = clientPortalStepDefinitionsRecord[t];
        return definition.clientPortalType.includes(clientPortalType);
      },
    );
};

export const defaultStepTypesKYB = getDefaultStepTypes(
  ClientPortalTypeEnum.KYB,
);
export const defaultStepTypesKYC = getDefaultStepTypes(
  ClientPortalTypeEnum.KYC,
);
