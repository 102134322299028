import { createContext, useContext } from 'react';

import { AuthTokenService } from './token.service';
import { BaseAccessToken, BaseAuth } from './types';

export type BaseAuthContext<
  TToken extends BaseAccessToken,
  TAuth extends BaseAuth,
> = {
  auth: TAuth;
  sync: () => Promise<void>;
  logout: () => Promise<boolean>;
  getToken: () => AuthTokenService<TToken>;
};

export const AuthContext = createContext<BaseAuthContext<
  BaseAccessToken,
  BaseAuth
> | null>(null);

// useAuth (provided by AuthContext)
export const useAuth = <
  T extends BaseAuthContext<BaseAccessToken, BaseAuth> = BaseAuthContext<
    BaseAccessToken,
    BaseAuth
  >,
>(): T => {
  const context = useContext(AuthContext) as T | null;
  if (!context) {
    throw new Error(
      `value for AuthContext was not initialized. Make sure the AuthProvider is set up.`,
    );
  }
  return context;
};
