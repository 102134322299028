// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/avatar.ts
// for default ChakraUI theme
import { SystemStyleInterpolation } from '@chakra-ui/react';
import {
  ComponentSingleStyleConfig,
  ComponentStyleConfig,
} from '@chakra-ui/theme';

function getSize(size: string): SystemStyleInterpolation {
  return {
    container: {
      width: size,
      height: size,
      fontSize: `calc(var(--chakra-sizes-${size}) / 2.5)`,
    },
    label: { fontSize: `calc(var(--chakra-sizes-${size}) / 2.5)` },
    excessLabel: {
      width: size,
      height: size,
      fontSize: `calc(var(--chakra-sizes-${size}) / 2.5)`,
    },
  };
}

export const AvatarTheme: ComponentStyleConfig &
  Required<Pick<ComponentSingleStyleConfig, 'sizes'>> = {
  baseStyle: {
    // Avatar is a multi part component and this override the baseStyle for the 'container' part
    // see https://chakra-ui.com/docs/theming/component-style#styling-multipart-components
    container: {
      bg: 'gray.800',
      color: 'blue.100',
    },
  },
  sizes: {
    sm: getSize('4'),
    md: getSize('6'),
    lg: getSize('8'),
    xl: getSize('10'),
  },
};
