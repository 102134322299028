import { HashIcon } from 'lucide-react';

import {
  Country,
  Grid,
  GridItem,
  Icon,
  Text,
} from '@dotfile/frontend/shared/design-system';
import { CompanySearch } from '@dotfile/shared/data-access-client-portal';

export const CompanySearchCompanyOption = ({
  address,
  country,
  name,
  registrationNumber,
}: Pick<
  CompanySearch,
  'address' | 'country' | 'name' | 'registrationNumber'
>) => {
  return (
    <Grid
      templateColumns="24px 1fr"
      width="100%"
      height="100%"
      alignItems="center"
    >
      <GridItem>
        <Country code={country} flagOnly />
      </GridItem>
      <GridItem>
        <Text color="black">
          {name}
          {address.postalCode || address.city
            ? ` (${[address.postalCode, address.city]
                .filter(Boolean)
                .join(' ')})`
            : ''}
        </Text>
      </GridItem>
      <GridItem>
        <Icon color="gray.500" as={HashIcon} />
      </GridItem>
      <GridItem>
        <Text fontSize="sm">{registrationNumber}</Text>
      </GridItem>
    </Grid>
  );
};
