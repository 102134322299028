import { Trash } from 'lucide-react';
import { useCallback } from 'react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  HStack,
  Icon,
  IconButton,
  Input,
} from '@dotfile/frontend/shared/design-system';
import { FileTargetEnum } from '@dotfile/shared/domain';

import { GroupController } from '../../input';
import { UploadDropzone } from '../../upload-dropzone';
import { useUploadFile } from '../hooks';
import { FilesFormValues } from '../types';

export type FormDocumentItemProps = {
  index: number;
  remove: UseFieldArrayRemove;
  onUpload?: CallableFunction;
};

export const FormDocumentItem = (props: FormDocumentItemProps) => {
  const { index, remove, onUpload } = props;
  const { t } = useTranslation();

  const { control, setValue, clearErrors, setError, resetField } =
    useFormContext<FilesFormValues>();

  const { uploadFile, progress, fileName } = useUploadFile(
    FileTargetEnum.document_data_file,
  );

  const handleInputChange = useCallback(
    async (file: File): Promise<void> => {
      try {
        resetField(`files.${index}.uploadRef`);
        const uploadRef = await uploadFile(file);
        onUpload?.();
        setValue(`files.${index}.uploadRef`, uploadRef, {
          shouldDirty: true,
          shouldValidate: true,
        });
        clearErrors(`files.${index}.uploadRef`);
      } catch {
        setError(`files.${index}.uploadRef`, {
          type: 'documentFileId',
          message: t('file.error.invalid', {
            defaultValue: 'The imported file is not valid',
            ns: 'components',
          }),
        });
      }
    },
    [
      resetField,
      index,
      uploadFile,
      setValue,
      clearErrors,
      setError,
      onUpload,
      t,
    ],
  );

  return (
    <>
      <GroupController
        name={`files.${index}.uploadRef`}
        control={control}
        render={(field) => <Input type="hidden" {...field} />}
      />
      <HStack
        align="start"
        borderColor="gray.100"
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="md"
        p="3"
        mb="4"
      >
        <UploadDropzone
          type="file"
          fileName={fileName}
          onDrop={handleInputChange}
          uploadProgress={progress}
          isFocused={false}
          h="128"
        />
        <IconButton
          type="button"
          aria-label="delete"
          variant="ghost"
          size="sm"
          icon={<Icon as={Trash} />}
          onClick={() => {
            remove(index);
          }}
        />
      </HStack>
    </>
  );
};
