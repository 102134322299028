import { createContext, useContext } from 'react';

import { ApolloError } from '@apollo/client';

import { BaseAggregateQuery } from '../helpers';

export type AggregateContextValue = {
  isLoading: boolean;
  error?: ApolloError;
  data: BaseAggregateQuery;
  /**
   * `true` when the table was created without an `useAggregateQuery` and
   * this context stub the actual aggregate context
   */
  _isStubContext: boolean;
};

const AggregateContext = createContext<AggregateContextValue>({
  isLoading: false,
  data: {},
  _isStubContext: false,
});

export const useAggregateContext = () => useContext(AggregateContext);
export const AggregateContextProvider = AggregateContext.Provider;
