import {
  CompanyModel,
  ViewColumnMappingEntityEnum,
  ViewColumnMappingTypeEnum,
} from '../../../shared/models';
import {
  companyDefaultPropertiesDefinition,
  PropertyTypeEnum,
} from '../../../shared/properties';
import { AddressPropertyKey } from '../../../shared/properties';
import { getAddressViewColumnDefinition } from './address-view-column.definition';
import { ViewColumn } from './types';
import {
  NestedPropertyViewColumnPrefix,
  ViewColumnBuilder,
} from './view-column-builder';

type CompanyViewColumn = Omit<
  // @NOTE Also update the test snapshot when modifying the properties
  CompanyModel,
  | 'caseId'
  | 'case'
  | 'workspaceId'
  | 'workspace'
  | 'vendorIds'
  | 'checks'
  | 'documentOrders'
  | 'vendorData'
  | 'customPropertyValues'
  | 'relations'
  | 'bankingInformation'
  | 'updatedAt'
  | 'deletedAt'
> & {
  checks: unknown;
};

export const companyViewColumnDefinition: Record<
  | Exclude<keyof CompanyViewColumn, 'address'>
  | NestedPropertyViewColumnPrefix<'address', AddressPropertyKey>,
  ViewColumn
> = new ViewColumnBuilder<CompanyViewColumn>(
  ViewColumnMappingEntityEnum.company,
)
  .addViewColumn('id', companyDefaultPropertiesDefinition.id, {
    canSort: false,
  })
  .addViewColumn(
    'classifications',
    companyDefaultPropertiesDefinition.classifications,
    {
      canSort: false,
    },
  )
  .addViewColumn('isRelevant', companyDefaultPropertiesDefinition.isRelevant)
  .addViewColumn(
    'lastActivityAt',
    companyDefaultPropertiesDefinition.lastActivityAt,
  )
  .addViewColumn('name', companyDefaultPropertiesDefinition.name)
  .addViewColumn(
    'commercialName',
    companyDefaultPropertiesDefinition.commercialName,
  )
  .addViewColumn(
    'registrationNumber',
    companyDefaultPropertiesDefinition.registrationNumber,
  )
  .addViewColumn(
    'registrationDate',
    companyDefaultPropertiesDefinition.registrationDate,
  )
  .addViewColumn('createdAt', companyDefaultPropertiesDefinition.createdAt)
  .addViewColumn('type', companyDefaultPropertiesDefinition.type)
  .addViewColumn('status', companyDefaultPropertiesDefinition.status)
  .addViewColumn('country', companyDefaultPropertiesDefinition.country)
  .addViewColumn('legalForm', companyDefaultPropertiesDefinition.legalForm)
  .addViewColumn(
    'entityLegalForm',
    companyDefaultPropertiesDefinition.entityLegalForm,
  )
  .addViewColumn(
    'taxIdentificationNumber',
    companyDefaultPropertiesDefinition.taxIdentificationNumber,
  )
  .addViewColumn(
    'employerIdentificationNumber',
    companyDefaultPropertiesDefinition.employerIdentificationNumber,
  )
  .addViewColumn(
    'shareCapital',
    companyDefaultPropertiesDefinition.shareCapital,
  )
  .addViewColumn('websiteUrl', companyDefaultPropertiesDefinition.websiteUrl)
  .addViewColumn('checks', PropertyTypeEnum.unknown, {
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
  })
  .addNestedViewColumnProperties('address', getAddressViewColumnDefinition)
  .getDefinition();

export const allCompanyViewColumnDefinition = Object.values(
  companyViewColumnDefinition,
);
