import { CopyCheck, LucideIcon } from 'lucide-react';
import { AnySchema } from 'yup';

import { CustomPropertyTypeEnum } from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import { CustomPropertyFormatValueParam, CustomPropertyValue } from './type';

export class BooleanCustomPropertyDefinition extends BaseCustomPropertyDefinition {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.boolean;
  }

  get label(): string {
    return 'Boolean';
  }

  get icon(): LucideIcon {
    return CopyCheck;
  }

  // Settings
  // --------

  // no settings for now

  // Value
  // -----

  public valueSchema(): AnySchema {
    const schema = yup.boolean().nullable().strict();

    return schema;
  }

  public override formatValue({
    value,
  }: CustomPropertyFormatValueParam): string {
    return this.parseValue(value) ? 'Yes' : 'No';
  }

  public override parseValue(rawValue: string): CustomPropertyValue {
    return rawValue === 'true';
  }
}

export const booleanCustomPropertyDefinition =
  new BooleanCustomPropertyDefinition();
