import {
  CheckTypeEnum,
  EntityTypeEnum,
  TemplateRuleModel,
  TemplateRuleTargetEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import {
  documentSettingsSchema,
  getAvailableCompanyCheckDefinitions,
  getAvailableIndividualCheckDefinitions,
  idVerificationSettingsSchema,
} from '../../../checks';

const individualCheckDefinitions = getAvailableIndividualCheckDefinitions();
const companyCheckDefinitions = getAvailableCompanyCheckDefinitions();

export const templateRuleSchema = (): yup.SchemaOf<
  Omit<
    TemplateRuleModel,
    'id' | 'template' | 'createdAt' | 'updatedAt' | 'checkSubtype'
  >
> => {
  return yup
    .object({
      target: yup
        .mixed()
        .oneOf(Object.values(TemplateRuleTargetEnum))
        .required(),
      checkType: yup
        .mixed()
        .when('$entityType', {
          is: EntityTypeEnum.individual,
          then: (schema) => {
            return schema.oneOf(
              individualCheckDefinitions.map((definition) => definition.type),
            );
          },
        })
        .when('$entityType', {
          is: EntityTypeEnum.company,
          then: (schema) => {
            return schema.oneOf(
              companyCheckDefinitions.map((definition) => definition.type),
            );
          },
        })
        .required(),
      templateId: yup.string().uuid().required(),
      checkSettings: yup
        .mixed()
        .when('checkType', {
          is: CheckTypeEnum.document,
          then: documentSettingsSchema().nullable(),
        })
        .when('checkType', {
          is: CheckTypeEnum.id_verification,
          then: idVerificationSettingsSchema().nullable(),
        })
        /**
         * Cast as AnyObjectSchema
         * or yup typing will break the CheckDataSettingsModel inference
         * since MixedSchema is not assignable to inferred CheckDataSettingsModel
         */
        .default(undefined) as yup.AnyObjectSchema,
    })
    .noUnknown()
    .defined();
};
