const typenameKey = '__typename' as const;

type Primitive = string | number | boolean | undefined | null;

type StrictDeepOmitTypename<T> = T extends Primitive
  ? T
  : StrictDeepOmitHelper<T, Exclude<keyof T, typeof typenameKey>>;

type StrictDeepOmitArray<T extends unknown[]> = {
  [P in keyof T]: StrictDeepOmitTypename<T[P]>;
};

type StrictDeepOmitHelper<T, K extends keyof T> = {
  [P in K]: T[P] extends infer TP
    ? TP extends Primitive
      ? TP
      : TP extends unknown[]
        ? StrictDeepOmitArray<TP>
        : StrictDeepOmitTypename<TP>
    : never;
};

/**
 * Remove __typename from object
 * This is useful when reusing graphql data as input of another mutation
 * @param data
 * @returns
 */
export function removeTypenameFromData<T extends object | object[]>(
  data: T,
): StrictDeepOmitTypename<T> {
  if (typeof data !== 'object' || !data) return data;

  return Object.entries(data).reduce((acc, [key, value]) => {
    const isObject = typeof value === 'object';

    if (key === typenameKey) {
      return acc;
    }

    if (isObject && Array.isArray(value)) {
      return {
        ...acc,
        [key]: value.map((v) => removeTypenameFromData(v)),
      };
    }

    if (isObject) {
      return { ...acc, [key]: removeTypenameFromData(value) };
    }

    return { ...acc, [key]: value };
  }, {} as StrictDeepOmitTypename<T>);
}
