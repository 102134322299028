import { EntityTypeEnum } from '../../../shared/models';
import { checkDefinitionsRecord } from './get-check-definitions';
import type { AllCheckTypeDefinitions } from './get-check-definitions';

/**
 * @returns a list of check definition that are available on company
 */
export function getAvailableCompanyCheckDefinitions(): AllCheckTypeDefinitions[] {
  return Object.values(checkDefinitionsRecord).filter((cd) =>
    cd.availableTarget.includes(EntityTypeEnum.company),
  );
}
