import React, { ReactNode } from 'react';

// Recursive traversal, strictly extracts text, using React.Children utility
export const extractTextFromNode = (node: ReactNode): string => {
  let text = '';
  React.Children.forEach(node, (child) => {
    if (typeof child === 'string') {
      text += child;
    } else if (React.isValidElement(child)) {
      text += extractTextFromNode(child.props.children);
    }
  });
  return text;
};
