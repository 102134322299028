import { useCallback, useReducer } from 'react';
import { match } from 'ts-pattern';

type State = {
  isOpen: boolean;
  selectedIndex: number | null;
};

const initialState: State = {
  isOpen: false,
  selectedIndex: null,
};

function reducer(
  state: State,
  action:
    | { type: 'close' }
    | { type: 'add' }
    | { type: 'edit'; selectedIndex: number | null },
): State {
  return match(action)
    .with({ type: 'close' }, () => ({
      isOpen: false,
      selectedIndex: null,
    }))
    .with({ type: 'add' }, () => ({
      isOpen: true,
      selectedIndex: null,
    }))
    .with({ type: 'edit' }, ({ selectedIndex }) => ({
      isOpen: true,
      selectedIndex,
    }))
    .exhaustive();
}

export const useFormDrawerState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    ...state,
    handleAdd: useCallback(() => dispatch({ type: 'add' }), []),
    handleEdit: useCallback(
      (selectedIndex: number) => dispatch({ type: 'edit', selectedIndex }),
      [],
    ),
    handleClose: useCallback(() => dispatch({ type: 'close' }), []),
  };
};
