import {
  CustomPropertyEntityTypeEnum,
  CustomPropertyModel,
  CustomPropertyTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import {
  CUSTOM_PROPERTY_LABEL_KEY_MAX_LENGTH,
  MAX_OPTION_PER_CUSTOM_PROPERTY,
} from '../constants';
import { getCustomPropertyDefinition } from '../helpers';
import { customPropertyOptionSchema } from './custom-property-option.schema';

type CustomPropertySchemaOption = {
  /**
   * Async function to determine if a label is unique
   * @param label
   * @returns
   */
  isLabelUnique: (label: string) => Promise<boolean>;

  /**
   * Async function to determine if a label is unique
   * @param label
   * @returns
   */
  isKeyUnique: (label: string) => Promise<boolean>;

  /**
   * Optional functions to validate customPropertyOptions.
   *
   * If not set, customPropertyOptions won't be validated
   */
  customPropertyOptions?: {
    /**
     * Async function to determine if a label is unique
     * @param label
     * @returns
     */
    isLabelUnique: (label: string) => Promise<boolean>;
    /**
     * Async function to determine if a label is unique
     * @param label
     * @returns
     */
    isKeyUnique: (label: string) => Promise<boolean>;
  };
};

/**
 * Recursively build a yup.test on all possible custom property type to validate
 * their settings from the definition schema
 * @param types
 * @returns
 */
const buildValidateSettings = (
  [firstType, ...types]: CustomPropertyTypeEnum[] = Object.values(
    CustomPropertyTypeEnum,
  ),
): yup.AnySchema => {
  return yup.mixed().when('type', {
    is: (type: CustomPropertyTypeEnum) => type === firstType,
    then: () => getCustomPropertyDefinition(firstType).settingsSchema(),
    otherwise: (schema) =>
      types.length > 0 ? buildValidateSettings(types) : schema,
  });
};

/**
 * Recursively build a yup.test on all possible custom property type to validate
 * if they should have options and how many
 * @param types
 * @returns
 */
const buildValidateOptions = (
  rootSchema: yup.ArraySchema<yup.AnySchema>,
  [firstType, ...types]: CustomPropertyTypeEnum[] = Object.values(
    CustomPropertyTypeEnum,
  ),
): yup.AnySchema => {
  return rootSchema.when('type', {
    is: (type: CustomPropertyTypeEnum) => type === firstType,
    then: (schema) =>
      getCustomPropertyDefinition(firstType).hasOptions
        ? schema.min(1).max(MAX_OPTION_PER_CUSTOM_PROPERTY)
        : schema.min(0).max(0),
    otherwise: (schema) =>
      types.length > 0 ? buildValidateOptions(rootSchema, types) : schema,
  });
};

export const customPropertySchema = ({
  isLabelUnique,
  isKeyUnique,
  customPropertyOptions,
}: CustomPropertySchemaOption): yup.SchemaOf<
  Pick<
    CustomPropertyModel,
    | 'targetEntity'
    | 'type'
    | 'key'
    | 'label'
    | 'settings'
    | 'customPropertyOptions'
  >
> => {
  let schema = yup.object().shape({
    targetEntity: yup
      .string()
      .oneOf(Object.values(CustomPropertyEntityTypeEnum))
      .required(),
    type: yup.string().oneOf(Object.values(CustomPropertyTypeEnum)).required(),
    label: yup
      .string()
      .max(CUSTOM_PROPERTY_LABEL_KEY_MAX_LENGTH)
      .isUnique(
        isLabelUnique,
        "Another property with the same label '${value}' already exists",
      )
      .required(),
    key: yup
      .string()
      .max(CUSTOM_PROPERTY_LABEL_KEY_MAX_LENGTH)
      .isUnique(
        isKeyUnique,
        "Another property with the same key '${value}' already exists",
      )
      .matches(
        /^[a-z][a-z_0-9]*$/,
        'key must start with a lower case letter and only contain lower case letters, digit and underscore',
      )
      .required(),
    settings: buildValidateSettings(),
  });

  if (customPropertyOptions) {
    schema = schema.shape({
      customPropertyOptions: buildValidateOptions(
        yup
          .array(
            // Options uniqueness handled by the customPropertyOptionSchema schema
            customPropertyOptionSchema(customPropertyOptions),
          )
          .default([]),
      ),
    });
  }

  return schema.noUnknown().defined();
};
