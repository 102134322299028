export const bigram = nGram(2);
export const trigram = nGram(3);

/**
 * Inspiration from n-gram package
 * Recreated because it's ESM only
 * @see https://github.com/words/n-gram/blob/main/index.js
 **/
export function nGram(n: number): (value: string) => string[] {
  if (Number.isNaN(n) || n < 1 || n === Number.POSITIVE_INFINITY) {
    throw new Error(`\`${n}\` is not a valid argument for \`n-gram\``);
  }

  return (value: string) => {
    const nGrams: string[] = [];

    if (value === null || value === undefined) {
      return nGrams;
    }

    let index = value.length - n + 1;

    if (index < 1) {
      return nGrams;
    }

    while (index--) {
      nGrams[index] = value.slice(index, index + n);
    }

    return nGrams;
  };
}
