import { VendorEnum } from '../../../shared/models';

/**
 * Returns the tier for a given vendor and vendorPrice.
 *
 * @param vendor Will throw an error if vendor is invalid.
 * Currently only `pappers` and `kyckr` are accepted.
 *
 * @param vendorPrice Will throw an error if vendorPrice is invalid for vendor.
 * `pappers` accepts any price but `kyckr` only up to 12.
 *
 * @returns
 */
export const getDocumentPriceTier = (
  vendor: VendorEnum,
  vendorPrice: number,
): string => {
  switch (vendor) {
    case VendorEnum.pappers:
      return 'tier_0';

    case VendorEnum.kyckr:
      if (vendorPrice <= 1) return 'tier_0';
      if (vendorPrice <= 2) return 'tier_1';
      if (vendorPrice <= 3) return 'tier_2';
      if (vendorPrice <= 5) return 'tier_3';
      if (vendorPrice <= 8) return 'tier_4';
      if (vendorPrice <= 12) return 'tier_5';
      else
        throw new Error(
          `Vendor price ${vendorPrice} for ${vendor} is out of tiers`,
        );

    default:
      throw new Error(
        `Vendor ${vendor} not implemented for getDocumentPriceTier`,
      );
  }
};
