import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ElementOf } from 'ts-essentials';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  HStack,
  SelectMenu,
  Text,
} from '@dotfile/frontend/shared/design-system';
import { CaseRelationRoleEnum } from '@dotfile/shared/data-access-client-portal';

import { FormDatastoreIndividual, useFormDatastore } from '../shared';
import { IndividualItem } from './individual-item';
import { NoLegalRepresentativeIndividualsAlert } from './no-legal-representative-individuals-alert';
import { FormValues } from './use-business-contact-step-form';

export const DelegatorSelectController = () => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext<FormValues>();

  const delegatorIndex = watch('delegatorIndex');
  const signatoryIndex = watch('signatoryIndex');

  const individuals = useFormDatastore((state) => state.data.individuals);
  const legalRepresentativeOptions: {
    value: string;
    individual: Partial<FormDatastoreIndividual>;
  }[] = useMemo(
    () => [
      ...(
        individuals?.map((individual, index) => ({
          value: index.toString(),
          individual,
        })) || []
      ).filter(
        ({ individual }, index) =>
          // Cannot be the signatory
          index.toString() !== signatoryIndex &&
          // Must be legal representative
          individual.roles?.includes(CaseRelationRoleEnum.legal_representative),
      ),
    ],
    [individuals, signatoryIndex],
  );

  const isSignatoryLegalRepresentative = watch(
    'isSignatoryLegalRepresentative',
  );
  if (isSignatoryLegalRepresentative) {
    return (
      <Alert status="success">
        <HStack>
          <AlertIcon />
          <AlertDescription>
            {t(
              'forms.business_contact.legal_representative_alert.signatory_legal_representative',
              {
                ns: 'client-portal',
                defaultValue:
                  'The authorized signatory is a legal representative of the company.',
              },
            )}
          </AlertDescription>
        </HStack>
      </Alert>
    );
  }

  if (!delegatorIndex && legalRepresentativeOptions.length <= 0) {
    return <NoLegalRepresentativeIndividualsAlert />;
  }

  return (
    <GroupController
      isRequired
      name="delegatorIndex"
      label={t(`forms.business_contact.delegator.label`, {
        ns: 'client-portal',
        defaultValue: 'Grantor',
      })}
      helper={t(`forms.business_contact.delegator.helper`, {
        ns: 'client-portal',
        defaultValue:
          'Select the legal representative granting the signatory power to act on behalf of the company',
      })}
      control={control}
      render={(field) => (
        <SelectMenu
          {...field}
          onChange={({ value }: { value: string }) => {
            setValue('delegatorIndex', value, { shouldValidate: true });
          }}
          buttonProps={{
            w: '100%',
          }}
          variant="select"
          options={legalRepresentativeOptions}
          // @NOTE: option require to explicitly define typing, inferred will resolve to {value: Type} from SelectMenu
          renderOption={(
            option: ElementOf<typeof legalRepresentativeOptions>,
          ) => <IndividualItem individual={option.individual} />}
        >
          {field.value && individuals ? (
            <IndividualItem individual={individuals[Number(field.value)]} />
          ) : (
            <Text align="start">
              {t(`forms.business_contact.delegator.placeholder`, {
                ns: 'client-portal',
                defaultValue: 'Select a legal representative',
              })}
            </Text>
          )}
        </SelectMenu>
      )}
    />
  );
};
