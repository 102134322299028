/* eslint-disable no-restricted-imports */
// @NOTE special Client portal use-case here to override Design System theme
// thus we need some imports from Chakra under layer library
import { get } from 'lodash';
import { readableColor } from 'polished';

import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(['wrapper', 'option']);

export const RadioButtonTheme = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle((props) => {
    const { colorScheme: c, theme } = props;

    return {
      wrapper: {},

      option: {
        _readOnly: {
          cursor: 'not-allowed',
        },
        _hover: {
          borderColor: 'gray.300',
        },
        _checked: {
          bg: `${c}.500`,
          borderColor: `${c}.500`,
          color: readableColor(
            get(theme, 'colors.custom.500'),
            get(theme, 'colors.gray.800'),
            get(theme, 'white'),
            false,
          ),
        },
        _focusVisible: { borderColor: `${c}.500` },
      },
    };
  }),
});
