import { WorkflowActionTypeEnum } from '../../shared/models';

// This file is autogenerated by the script "yarn codegen:editor-typing" in
// tools/scripts/codegen/editor-typing.mjs

/**
 * Contains the Typescript typing as a string for the code editors
 *
 * This will be injected as an extra lib
 * @see https://microsoft.github.io/monaco-editor/docs.html#interfaces/languages.typescript.LanguageServiceDefaults.html#addExtraLib
 */
export const codeEditorTyping = (
  availableActions: Partial<typeof WorkflowActionTypeEnum>,
): string => {
  const globalKeys = ['input', ...Object.values(availableActions)]
    .map((a) => `'${a}'`)
    .join('|');

  return /* typescript */ `
declare module NodeJS {
  interface Global {
    $: Pick<WorkflowEditorGlobal, ${globalKeys}>
  }
}

/**
 * Global object to access \`input\` data and execute actions.
 */
declare const $: Pick<WorkflowEditorGlobal, ${globalKeys}>;

type WorkflowEditorReviewSettings = {
  /**
   * - \`true\` to automatically approve check on vendor or analysis approval.
   * - \`false\` always review manually.
   */
  automatic_approval?: boolean;
  /**
   * - \`true\` to automatically reject check on vendor or analysis rejection.
   * - \`false\` always review manually.
   */
  automatic_rejection?: boolean;
};

type WorkflowEditorCreateAmlCheckActionSettings = {
  /**
   * Level of flexibility of search
   *
   * (\`0.00\` will check for exact matches only; \`1.00\` will look for results with
   * even the slightest match in spelling and phonetics).
   */
  fuzziness?: number;
  /**
   * Types of lists to apply for AML screening.
   *
   * \`adverse_media\` may triggers many potential matches.
   */
  list_types?: (
    | 'sanction'
    | 'warning'
    | 'fitness_probity'
    | 'pep'
    | 'adverse_media'
  )[];
  /**
   * Restrict hits according to entity type, set to \`false\` for broader search.
   */
  enable_entity_filter?: boolean;
};

type WorkflowEditorCreateDocumentCheckActionSettingsDocumentAnalysisRegistrationCertificateParameters =
  {
    model: 'registration_certificate';
    max_age_in_days?: number | null;
    authorized_documents?:
      | (
          | 'kbis'
          | 'sirene_extract'
          | 'pappers_extract'
          | 'inscription_rne'
          | 'infonet_extract'
          | 'repertoire_metiers_extract'
          | 'handelsregister'
          | 'visura_camerale'
          | 'other'
        )[]
      | null;
  };

type WorkflowEditorCreateDocumentCheckActionSettingsDocumentAnalysisProofOfAddressParameters =
  {
    model: 'proof_of_address';
    max_age_in_days?: number | null;
    authorized_documents?:
      | ('energy_bill' | 'telco_bill' | 'official_document' | 'other')[]
      | null;
  };

type WorkflowEditorCreateDocumentCheckActionSettingsDocumentAnalysisIBANParameters =
  {
    model: 'iban';
    iban_matching?: boolean;
    bic_matching?: boolean;
  };

type WorkflowEditorCreateDocumentCheckActionSettingsDocumentAnalysis = {
  parameters:
    | WorkflowEditorCreateDocumentCheckActionSettingsDocumentAnalysisRegistrationCertificateParameters
    | WorkflowEditorCreateDocumentCheckActionSettingsDocumentAnalysisProofOfAddressParameters
    | WorkflowEditorCreateDocumentCheckActionSettingsDocumentAnalysisIBANParameters;
} & Required<WorkflowEditorReviewSettings>;

type WorkflowEditorCreateDocumentCheckActionSettings = {
  /**
   * Document type of the check, accept default or custom document type key
   */
  document_type_key:
    | (
        | 'articles_of_association'
        | 'registration_certificate'
        | 'ubo_registry_extract'
        | 'financial_statements'
        | 'proof_of_insurance'
        | 'id_document'
        | 'driving_license'
        | 'proof_of_address'
        | 'visa'
        | 'social_security_card'
        | 'birth_certificate'
        | 'vehicle_registration_certificate'
        | 'bank_details'
        | 'criminal_record_extract'
        | 'tax_notice'
      )
    | (string & {});
  document_analysis?: WorkflowEditorCreateDocumentCheckActionSettingsDocumentAnalysis | null;
};

type WorkflowEditorCreateIdDocumentCheckActionSettings = {
  /**
   * Vendor used for this check.
   */
  vendor?: 'veriff' | 'idnow';
} & WorkflowEditorReviewSettings;

type WorkflowEditorCreateIdVerificationCheckActionSettings = {
  /**
   * Vendor used for this check.
   *
   * Vendor \`ubble\` is deprecated, only for legacy configuration.
   */
  vendor?: 'veriff' | 'checkout' | 'onfido' | 'ubble';
  /**
   * Mode used for this check.
   *
   * Mode \`liveness\` is specific to vendor \`ubble\`.
   */
  mode?: 'liveness' | 'auto' | 'expert' | 'certified' | 'default';
  /**
   * Url to which the user will be redirected after completing the check.
   */
  redirect_url?: string;
} & WorkflowEditorReviewSettings;

type WorkflowEditorLookupParam<
  TType extends 'string' | 'boolean' | 'number' = 'string',
> = {
  /**
   * The key identifying the table in which to perform the lookup.
   */
  table_key: string;
  /**
   * The column used to match the lookup value.
   */
  lookup_column: string;
  /**
   * The value to match against the lookup column.
   *
   * When the value is of type \`number\` or \`boolean\`, it is compared against the each lookup column value casted using the same logic as \`output_type\`.
   */
  lookup_value: string | boolean | number;
  /**
   * The column whose value will be returned.
   */
  output_column: string;
  /**
   * The type of the returned value when found (defaults to \`'string'\`).
   *
   * - for \`string\`: the original string value is not transformed
   * - for \`boolean\`: transform the original string value from \`'0'\`, \`'1'\`, \`'true'\` and \`'FALSE'\` regardless of the case
   * - for \`number\`: transform the original string value using [parseFloat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseFloat) and accepts comma \`,\`  as decimal separator
   */
  output_type?: TType;
};

type WorkflowEditorAction = {
  /**
   * Add a tag to case.
   *
   * @param tag \`id\` or \`label\` of the tag to add.
   */
  add_tag: (tag: string) => void;
  /**
   * Create a new AML check on a company or individual.
   *
   * @link https://docs.dotfile.com/reference/aml-create-one
   */
  create_aml_check: (params: {
    company_id?: string;
    individual_id?: string;
    settings?: WorkflowEditorCreateAmlCheckActionSettings;
  }) => void;
  /**
   * Create a new Document check on a company or individual.
   *
   * @link https://docs.dotfile.com/reference/document-create-one
   */
  create_document_check: (params: {
    company_id?: string;
    individual_id?: string;
    /**
     * Must define either \`document_type\` or \`custom_document_type_id\`.
     */
    settings: WorkflowEditorCreateDocumentCheckActionSettings;
  }) => void;
  /**
   * Create a new ID Document check on an individual.
   *
   * @link https://docs.dotfile.com/reference/id-document-create-one
   */
  create_id_document_check: (params: {
    individual_id: string;
    settings?: WorkflowEditorCreateIdDocumentCheckActionSettings;
  }) => void;
  /**
   * Create a new ID Verification check on an individual.
   *
   * @link https://docs.dotfile.com/reference/id-verification-create-one
   */
  create_id_verification_check: (params: {
    individual_id: string;
    settings?: WorkflowEditorCreateIdVerificationCheckActionSettings;
  }) => void;
  /**
   * Log information to help you debug your workflow.
   *
   * @param message a single string, boolean or number.
   */
  log: (message: string | boolean | number | null | undefined) => void;
  /**
   * Set template on case.
   *
   * @param templateId \`id\` of the template.
   */
  set_template: (templateId: string) => void;
  /**
   * Looks up a value from a Table
   *
   * @returns Returns the value from the output column of the first matching table entry, converted to the specified \`output_type\`, or \`null\` if not found.
   */
  lookup: <TType extends 'string' | 'boolean' | 'number' = 'string'>(
    param: WorkflowEditorLookupParam<TType>,
  ) =>
    | null
    | (TType extends 'number'
        ? number
        : TType extends 'boolean'
          ? boolean
          : string);
  /**
   * Set the risk on the case and stop the execution of the code.
   *
   * @link https://docs.dotfile.com/reference/risk-create-one
   */
  set_risk: (param: {
    /**
     * Risk level
     */
    level:
      | 'not_defined'
      | 'low'
      | 'medium'
      | 'high'
      | 'critical'
      | 'prohibited';
    /**
     * Optional overall score of the risk
     *
     * Must be an \`integer\` between -2147483648 and 2147483647
     */
    score?: number | null;
    /**
     * Optional set of \`number\` score components
     */
    components?: null | Record<string, number>;
    /**
     * Optional set of \`boolean\` flags
     */
    flags?: null | Record<string, boolean>;
    /**
     * Optional comment on the risk
     */
    comment?: string | null;
  }) => never;
  /**
   * Stop the execution of the code.
   */
  exit: () => never;
};

type CustomPropertyValueType = string | boolean | string[] | number | null;

interface CustomPropertiesValuesRecordModel {
  [key: string]: CustomPropertyValueType;
}
type WorkflowEditorInputBankingInformation = {
  iban: string | null;
  bic: string | null;
};

type WorkflowEditorInputAddress = {
  street_address: string | null;
  street_address2: string | null;
  postal_code: string | null;
  city: string | null;
  state: string | null;
  region: string | null;
  /**
   * ISO 3166-1 alpha-2
   * @link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  country: string | null;
};

type WorkflowEditorInputCheck = {
  status:
    | 'in_progress'
    | 'need_review'
    | 'processing'
    | 'approved'
    | 'rejected'
    | 'expired';
  type: 'id_verification' | 'id_document' | 'aml' | 'document';
} & (
  | {
      type: 'aml';
      data: {
        true_positive_list_types: (
          | 'sanction'
          | 'warning'
          | 'fitness_probity'
          | 'pep'
          | 'adverse_media'
        )[];
      };
    }
  | {
      type: 'document';
      data: {
        settings: {
          document_type_key:
            | (
                | 'articles_of_association'
                | 'registration_certificate'
                | 'ubo_registry_extract'
                | 'financial_statements'
                | 'proof_of_insurance'
                | 'id_document'
                | 'driving_license'
                | 'proof_of_address'
                | 'visa'
                | 'social_security_card'
                | 'birth_certificate'
                | 'vehicle_registration_certificate'
                | 'bank_details'
                | 'criminal_record_extract'
                | 'tax_notice'
              )
            | (string & {});
        };
      };
    }
  | {
      type: 'id_document';
      data: {
        settings: {
          vendor: 'veriff' | 'idnow';
        };
        information: {
          document_type:
            | 'passport'
            | 'identity_card'
            | 'driving_license'
            | 'residence_permit'
            | 'visa'
            | 'other';
          document_number: string;
          first_name: string;
          last_name: string;
          nationality: string;
          birth_date: string;
          birth_country: string;
          expiration_date: string;
          issuing_date: string;
          issuing_country: string;
        };
      };
    }
  | {
      type: 'id_verification';
      data: {
        settings: {
          vendor: 'veriff' | 'checkout' | 'onfido' | 'ubble';
          mode: 'liveness' | 'auto' | 'expert' | 'certified' | 'default';
        };
        information: {
          document_type:
            | 'passport'
            | 'identity_card'
            | 'driving_license'
            | 'residence_permit'
            | 'visa'
            | 'other';
          document_number: string;
          first_name: string;
          last_name: string;
          nationality: string;
          birth_date: string;
          birth_country: string;
          expiration_date: string;
          issuing_date: string;
          issuing_country: string;
        };
      };
    }
);

type WorkflowEditorInputCustomProperties = {
  /**
   * Object with
   * - in key → the key of each custom properties of the workspace for this entity type
   * - in value → \`null\` or a value depending on the type of the custom property like in the API
   *
   * @link https://docs.dotfile.com/reference/cases-guide#custom-properties
   */
  custom_properties: Record<string, CustomPropertyValueType>;
};

type WorkflowEditorInputRisk = {
  level: 'not_defined' | 'low' | 'medium' | 'high' | 'critical' | 'prohibited';
  score: number | null;
  components: Record<string, number> | null;
  flags: Record<string, boolean> | null;
};

type WorkflowEditorInputCase = {
  id: string;
  name: string;
  external_id: string | null;
  template_id: string | null;
  risk: WorkflowEditorInputRisk | null;
  /**
   * The label of each tags
   */
  tags: string[];
} & WorkflowEditorInputCustomProperties;

type WorkflowEditorInputCompany = {
  id: string;
  name: string;
  type: 'main' | 'affiliated';
  is_relevant: boolean;
  commercial_name: string | null;
  registration_number: string;
  /**
   * Absolute date in format \`yyyy-MM-dd\`
   */
  registration_date: string | null;
  status: 'live' | 'closed' | 'not_reported';
  legal_form: string | null;
  /**
   * ISO 20275
   * @link https://www.gleif.org/en/about-lei/code-lists/iso-20275-entity-legal-forms-code-list
   */
  entity_legal_form: string | null;
  /**
   * ISO 3166-1 alpha-2
   * @link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  country: string;
  address: WorkflowEditorInputAddress;
  classifications:
    | {
        type: string;
        code: string;
        description?: string | null;
      }[]
    | null;
  banking_information: WorkflowEditorInputBankingInformation;
  share_capital: string | null;
  tax_identification_number: string | null;
  website_url: string | null;
  employer_identification_number: string | null;
  checks: WorkflowEditorInputCheck[];
} & WorkflowEditorInputCustomProperties;

type WorkflowEditorInputIndividual = {
  id: string;
  is_business_contact: boolean;
  is_beneficial_owner: boolean;
  is_delegator: boolean;
  is_signatory: boolean;
  is_relevant: boolean;
  roles: ('legal_representative' | 'shareholder')[];
  first_name: string;
  /**
   * May contain multiple middle names
   */
  middle_name: string | null;
  last_name: string;
  maiden_name: string | null;
  email: string | null;
  /**
   * Absolute date in format \`yyyy-MM-dd\`
   */
  birth_date: string | null;
  birth_place: string | null;
  /**
   * ISO 3166-1 alpha-2
   * @link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  birth_country: string | null;
  address: WorkflowEditorInputAddress;
  banking_information: WorkflowEditorInputBankingInformation;
  tax_identification_number: string | null;
  /**
   * E.164 phone number (eg \`+XXXXXXXXXXX\`)
   * @link https://en.wikipedia.org/wiki/E.164
   */
  phone_number: string | null;
  social_security_number: string | null;
  position: string | null;
  ownership_percentage: number | null;
  voting_rights_percentage: number | null;
  checks: WorkflowEditorInputCheck[];
} & WorkflowEditorInputCustomProperties;

type WorkflowEditorInput = {
  case: WorkflowEditorInputCase;
  companies: WorkflowEditorInputCompany[];
  individuals: WorkflowEditorInputIndividual[];
};

type WorkflowEditorGlobal = {
  /**
   * Contains the **Case** data on which the code will be executed.
   */
  input: WorkflowEditorInput;
} & WorkflowEditorAction;
`;
};
