export type FileTypeDefinition = {
  extensions: string[];
  description: string;
  mimeType: string;
  category: 'image' | 'office' | 'text' | 'archive';
};

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const allAcceptedFileTypes: FileTypeDefinition[] = [
  // .aac omitted because it's an audio format
  // .abw omitted because it's an obscure format (AbiWord document)
  {
    extensions: ['.arc'],
    description: 'Archive document (multiple files embedded)',
    mimeType: 'application/x-freearc',
    category: 'archive',
  },
  {
    extensions: ['.avif'],
    description: 'AVIF image',
    mimeType: 'image/avif',
    category: 'image',
  },
  // .avi omitted because it's a video format
  // .azw omitted it's an obscure format (Amazon Kindle eBook format)
  // .bin omitted because it's a binary/executable format
  {
    extensions: ['.bmp'],
    description: 'Windows OS/2 Bitmap Graphics',
    mimeType: 'image/bmp',
    category: 'image',
  },
  {
    extensions: ['.bz'],
    description: 'BZip archive',
    mimeType: 'application/x-bzip',
    category: 'archive',
  },
  {
    extensions: ['.bz2'],
    description: 'BZip2 archive',
    mimeType: 'application/x-bzip2',
    category: 'archive',
  },
  // .cda omitted because it's an audio format
  // .csh omitted because it's a binary/executable format
  // .css omitted because it's a developer format
  {
    extensions: ['.csv'],
    description: 'Comma-separated values (CSV)',
    mimeType: 'text/csv',
    category: 'text',
  },
  {
    extensions: ['.doc'],
    description: 'Microsoft Word',
    mimeType: 'application/msword',
    category: 'office',
  },
  {
    extensions: ['.docx'],
    description: 'Microsoft Word (OpenXML)',
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    category: 'office',
  },
  // .eot omitted because it's a font format
  // .epub omitted because it's an obscure format (Electronic publication (EPUB))
  {
    extensions: ['.gz'],
    description: 'GZip Compressed Archive',
    mimeType: 'application/gzip',
    category: 'archive',
  },
  {
    extensions: ['.gif'],
    description: 'Graphics Interchange Format (GIF)',
    mimeType: 'image/gif',
    category: 'image',
  },
  // .htm, .html omitted because it's a developer format
  // .ics omitted because it's an obscure format (iCalendar format)
  // .jar omitted because it's a developer format
  {
    extensions: ['.ico'],
    description: 'Icon format',
    mimeType: 'image/vnd.microsoft.icon',
    category: 'image',
  },
  {
    extensions: ['.ico'],
    description: 'Icon format',
    mimeType: 'image/x-icon',
    category: 'image',
  },
  {
    extensions: ['.jpeg', '.jpg'],
    description: 'JPEG images',
    mimeType: 'image/jpeg',
    category: 'image',
  },
  // .js omitted because it's a developer format
  {
    extensions: ['.json'],
    description: 'JSON format',
    mimeType: 'application/json',
    category: 'text',
  },
  {
    extensions: ['.jsonld'],
    description: 'JSON-LD format',
    mimeType: 'application/ld+json',
    category: 'text',
  },
  // .mid, .midi omitted
  // .mjs omitted because it's a developer format
  // .mp3 omitted because it's an audio format
  // .mp4 omitted because it's a video format
  // .mpeg omitted because it's a video format
  // .mpkg omitted because it's a binary/executable format
  {
    extensions: ['.odp'],
    description: 'OpenDocument presentation document',
    mimeType: 'application/vnd.oasis.opendocument.presentation',
    category: 'office',
  },
  {
    extensions: ['.ods'],
    description: 'OpenDocument spreadsheet document',
    mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
    category: 'office',
  },
  {
    extensions: ['.odt'],
    description: 'OpenDocument text document',
    mimeType: 'application/vnd.oasis.opendocument.text',
    category: 'office',
  },
  // .oga omitted because it's an audio format
  // .ogv omitted because it's a video format
  // .ogx omitted because it's a video format
  // .opus omitted because it's an audio format
  // .otf omitted  because it's a font format
  {
    extensions: ['.png'],
    description: 'Portable Network Graphics',
    mimeType: 'image/png',
    category: 'image',
  },
  {
    extensions: ['.pdf'],
    description: 'Adobe Portable Document Format (PDF)',
    mimeType: 'application/pdf',
    category: 'office',
  },
  // .php omitted because it's a developer format
  {
    extensions: ['.ppt'],
    description: 'Microsoft PowerPoint',
    mimeType: 'application/vnd.ms-powerpoint',
    category: 'office',
  },
  {
    extensions: ['.pptx'],
    description: 'Microsoft PowerPoint (OpenXML)',
    mimeType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    category: 'office',
  },
  {
    extensions: ['.rar'],
    description: 'RAR archive',
    mimeType: 'application/vnd.rar',
    category: 'archive',
  },
  {
    extensions: ['.rtf'],
    description: 'Rich Text Format (RTF)',
    mimeType: 'application/rtf',
    category: 'text',
  },
  // .sh omitted because it's a developer format
  {
    extensions: ['.svg'],
    description: 'Scalable Vector Graphics (SVG)',
    mimeType: 'image/svg+xml',
    category: 'image',
  },
  {
    extensions: ['.tar'],
    description: 'Tape Archive (TAR)',
    mimeType: 'application/x-tar',
    category: 'archive',
  },
  {
    extensions: ['.tif', '.tiff'],
    description: 'Tagged Image File Format (TIFF)',
    mimeType: 'image/tiff',
    category: 'image',
  },
  // .ts omitted because it's a video format (MPEG transport stream with mimeType `video/mp2t`)
  // .ttf omitted  because it's a font format
  {
    extensions: ['.txt'],
    description: 'Text, (generally ASCII or ISO 8859-n)',
    mimeType: 'text/plain',
    category: 'text',
  },
  {
    extensions: ['.vsd'],
    description: 'Microsoft Visio',
    mimeType: 'application/vnd.visio',
    category: 'office',
  },
  // .wav omitted because it's an audio format
  // .weba omitted because it's an audio format
  // .webm omitted because it's a video format
  {
    extensions: ['.webp'],
    description: 'WEBP image',
    mimeType: 'image/webp',
    category: 'image',
  },
  // .woff omitted  because it's a font format
  // .woff2 omitted  because it's a font format
  // .xhtml omitted because it's a developer format
  {
    extensions: ['.xls'],
    description: 'Microsoft Excel',
    mimeType: 'application/vnd.ms-excel',
    category: 'office',
  },
  {
    extensions: ['.xlsx'],
    description: 'Microsoft Excel (OpenXML)',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    category: 'office',
  },
  // .xml omitted because it's a developer format
  // .xul omitted because it's an obscure format (XML User Interface Language)
  {
    extensions: ['.zip'],
    description: 'ZIP archive',
    mimeType: 'application/zip',
    category: 'archive',
  },
  // .3gp omitted because it's an audio/video format
  // .3g2 omitted because it's an audio/video format
  {
    extensions: ['.7z'],
    description: '7-zip archive',
    mimeType: 'application/x-7z-compressed',
    category: 'archive',
  },
  // compressed files on windows have application/x-zip-compressed for the type https://github.com/mdn/content/issues/13409
  {
    extensions: ['.7z'],
    description: '7-zip archive on windows',
    mimeType: 'application/x-compressed',
    category: 'archive',
  },
  {
    extensions: ['.zip'],
    description: 'ZIP archive on windows',
    mimeType: 'application/x-zip-compressed',
    category: 'archive',
  },
];
