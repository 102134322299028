import { useEffect, useRef, useState } from 'react';

import { useAuth } from '@dotfile/frontend/shared/common';

import { ContactAuthContext } from '../../../../shared';
import { useFormDatastoreApi } from '../context';
import { useCaseForms } from './use-case-forms';

export const useCaseDataInit = () => {
  const {
    auth: { caseId },
  } = useAuth<ContactAuthContext>();
  const caseQuery = useCaseForms();

  /**
   * @NOTE:
   * Relying on caseQuery.loading only create a race condition when displaying form values
   * At some point caseQuery is no more loading, so the form render before the useEffect bellow is actually setting the state from the query.
   * We manually control the loading state to avoid that.
   */
  const [loading, setLoading] = useState(!!caseId);

  const storeApi = useFormDatastoreApi();

  const isInitialized = useRef(false);
  useEffect(() => {
    if (isInitialized.current) {
      // Avoid re-initializing the store if it was already done because
      // this clear the local storage and reset the progress current step
      return;
    } else if (!loading) {
      // Directly mark as initialized when there are no case to load
      // This will avoid initialization when there is a case that is set when a form is in progress
      // like when using "Save for later" without an existing case before
      isInitialized.current = true;
    } else if (caseQuery.data?.case) {
      // Mark as initialized from the case data
      isInitialized.current = true;

      const { setFromCaseQuery, reset } = storeApi.getState();

      // Reset store state (progress & data)
      // We will always start from the first step when resume form (even if you have local storage session)
      // If you refresh, it will reload the case and also start from the first step (helpful when something has changed form anywhere else: external API, console)
      reset();

      setFromCaseQuery(caseQuery.data.case);

      // Remove manual loading
      setLoading(false);
    }
  }, [loading, caseQuery.data?.case, storeApi]);

  return { loading };
};
