export const RichTextModeEnum = {
  display: 'display',
  edit: 'edit',
} as const;
export type RichTextModeEnum =
  (typeof RichTextModeEnum)[keyof typeof RichTextModeEnum];

export type RichTextVariant = 'large' | 'compact';

/**
 * Returns a list of suggestion from a `query`.
 *
 * Can returns a Promise but the UX is not optimal because there is no loading state
 * and you have to implement debounce
 */
export type RichTextGetSuggestionItemsFn = (
  query: string,
) => Promise<RichTextSuggestionItem[]> | RichTextSuggestionItem[];

export type RichTextSuggestionItem = { id: string; label: string };

export type RichTextHandle = {
  setContent: (content: string) => void;
  focus: () => void;
};
