import { Eye } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Heading,
  Icon,
  Link,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import {
  AppLoader,
  FullContainer,
  SupportButton,
  useCase,
  useLatestClientPortalVersion,
} from '../../shared';
import { useInterpolateRedirectUrl } from './hooks/use-interpolate-redirect-url';
import { useRedirectAfterDelay } from './hooks/use-redirect-after-delay';

export const EndPage = () => {
  const { t } = useTranslation();

  // @NOTE No need to handle loading or error, already loaded from parent components
  const { data } = useLatestClientPortalVersion();

  const supportEmail = data?.latestClientPortalVersion.setting.supportEmail;

  const caseQuery = useCase();
  const redirectUrl = useInterpolateRedirectUrl(
    data?.latestClientPortalVersion.setting.redirectUrl,
    caseQuery.data?.case,
  );
  const remainingTime = useRedirectAfterDelay(redirectUrl);

  return (
    <FullContainer centerContent>
      <VStack textAlign="center">
        <Box py={4}>
          {redirectUrl ? (
            <AppLoader isInline />
          ) : (
            <Icon w="48px" h="48px" color="custom.500" as={Eye} />
          )}
        </Box>

        <Heading mb={2} size="lg" textAlign="center">
          {t('end.title', {
            ns: 'client-portal',
            defaultValue: 'Our team is now reviewing your information',
          })}
        </Heading>

        <Text>
          {t('end.subtitle', {
            ns: 'client-portal',
            defaultValue: 'We will keep you informed as soon as possible.',
          })}
          <br />
          {redirectUrl &&
            t(`end.redirect`, {
              ns: 'client-portal',
              defaultValue:
                "You'll be redirected in {{remainingTime}} seconds, if this does not happen please follow this link:",
              remainingTime,
            })}
        </Text>
        <Box py={2}>
          {redirectUrl ? (
            <Link
              href={redirectUrl}
              size="xs"
              color="custom.500"
              fontSize="smaller"
            >
              {redirectUrl}
            </Link>
          ) : (
            <SupportButton supportEmail={supportEmail} />
          )}
        </Box>
      </VStack>
    </FullContainer>
  );
};
