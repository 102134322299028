import {
  ClientPortalAvailableDefaultLanguageEnum,
  DocumentAnalysisModelEnum,
} from '../../../../../../shared/models';
import { DocumentAnalysisPromptParametersModel } from '../../../../../../shared/models/checks/document/document-analysis/prompt/document-analysis-prompt-parameters.model';
import yup from '../../../../../../utils/yup-extended';
import { CUSTOM_DOCUMENT_TYPE_ANALYSIS_INSTRUCTION_MAX_LENGTH } from '../../../../../workspace';

export const documentAnalysisPromptParametersSchema =
  (): yup.SchemaOf<DocumentAnalysisPromptParametersModel> => {
    return yup
      .object({
        model: yup.mixed().is([DocumentAnalysisModelEnum.prompt]).required(),
        prompt: yup
          .string()
          .required()
          .max(CUSTOM_DOCUMENT_TYPE_ANALYSIS_INSTRUCTION_MAX_LENGTH),
        languages: yup
          .array(
            yup
              .mixed()
              .oneOf(Object.values(ClientPortalAvailableDefaultLanguageEnum)),
          )
          .nullable()
          .default(null),
      })
      .noUnknown()
      .required()
      .strict();
  };
