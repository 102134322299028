import {
  Button,
  forwardRef,
  HStack,
  Input,
  useNumberInput,
} from '@chakra-ui/react';

interface NumberSpinnerProps {
  step?: number;
  value?: number;
  min?: number;
  max?: number;
  precision?: number;
  keepWithinRange?: boolean;
  maxCharDisplay?: number;
  onChange?: (valueAsString: string, valueAsNumber: number) => void;
}

export const NumberSpinner = forwardRef<NumberSpinnerProps, typeof Input>(
  (props, ref): JSX.Element => {
    const {
      step = 1,
      value = 0,
      min = 0,
      max = 0,
      precision = 0,
      keepWithinRange = false,
      maxCharDisplay = 3,
      onChange,
    } = props;

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
      useNumberInput({
        step,
        defaultValue: value,
        min,
        max,
        precision,
        keepWithinRange,
        onChange,
      });

    const inc = getIncrementButtonProps();
    const dec = getDecrementButtonProps();
    const input = getInputProps();

    return (
      <HStack maxW="320px">
        <Button size="md" {...dec} variant="outline" colorScheme="gray">
          -
        </Button>
        <Input
          {...input}
          w={`${maxCharDisplay}ch`}
          textAlign="center"
          boxSizing="content-box"
          ref={ref}
        />
        <Button size="md" {...inc} variant="outline" colorScheme="gray">
          +
        </Button>
      </HStack>
    );
  },
);
