import { pick } from 'lodash';
import { FieldValues, get, UseFormReturn } from 'react-hook-form';

import { useCurrentStep } from '../context';
import {
  FIELDS_PREFIX,
  FieldsFormValues,
  formValuesToData,
  FormValuesToDataResult,
} from '../utils';

export const SUBMIT_BUTTON_DATA_ATTRIBUTE = {
  next: 'next',
  saveForLater: 'save-for-later',
};

export type UseHandleFieldsFormSubmit<
  TExtraValues extends FieldValues = Record<string, never>,
> = (param: {
  data: FormValuesToDataResult;
  formValues: FieldsFormValues<TExtraValues>;
  event: React.BaseSyntheticEvent | undefined;
}) => void;

type UseHandleFieldsFormParam<
  TExtraValues extends FieldValues = Record<string, never>,
> = {
  methods: UseFormReturn<FieldsFormValues<TExtraValues>>;
  handleDatastore: UseHandleFieldsFormSubmit<TExtraValues>;

  onSubmitStep: () => Promise<void>;
  onSaveForLater: () => void;
};

export const useHandleFieldsForm = <
  TExtraValues extends FieldValues = Record<string, never>,
>({
  methods,
  handleDatastore,
  onSubmitStep,
  onSaveForLater,
}: UseHandleFieldsFormParam<TExtraValues>) => {
  const step = useCurrentStep();
  const {
    handleSubmit: _handleSubmit,
    trigger,
    formState: { dirtyFields },
  } = methods;

  const handleFieldsForm = _handleSubmit(async (formValues, event) => {
    const isValid = await trigger();

    if (isValid) {
      // Only submit the value were the form field has been modified
      const dirtyFieldsValues = pick(
        formValues[FIELDS_PREFIX],
        Object.keys(dirtyFields[FIELDS_PREFIX] ?? {}),
      );
      const data = formValuesToData(dirtyFieldsValues, { step });

      handleDatastore({ data, event, formValues });

      // If Next button, also call onSubmitStep for navigation, otherwise Save for later
      if (
        // Submit on ENTER key
        get(event?.nativeEvent, 'submitter', null) === null ||
        // Submit on click on Next button
        get(
          event?.nativeEvent,
          'submitter',
          document.createElement('button'),
        ).getAttribute('data-submit') === SUBMIT_BUTTON_DATA_ATTRIBUTE.next
      ) {
        await onSubmitStep();
      } else {
        onSaveForLater();
      }
    }
  });

  return handleFieldsForm;
};
