export function getDaysBetweenTwoDates(
  start: Date | null,
  end: Date | null,
): number {
  if (start === null) return 0;

  const startDateInUTC = new Date(
    start.getUTCFullYear(),
    start.getUTCMonth(),
    start.getUTCDate(),
    start.getUTCHours(),
    start.getUTCMinutes(),
    start.getUTCSeconds(),
  );

  if (end === null) return 1;
  const endDateInUTC = new Date(
    end.getUTCFullYear(),
    end.getUTCMonth(),
    end.getUTCDate(),
    end.getUTCHours(),
    end.getUTCMinutes(),
    end.getUTCSeconds(),
  );

  return Math.floor(
    //@ts-expect-error - type error
    (Date.parse(endDateInUTC) - Date.parse(startDateInUTC)) / 1000 / 3600 / 24,
  );
}

export const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const generateYearsData = (nbrOfYears: number): number[] => {
  const date = new Date(),
    years = [],
    year = date.getFullYear();

  for (let i = year; i > year - nbrOfYears; i--) {
    years.push(i);
  }
  return years;
};
