import { match } from 'ts-pattern';

import {
  PropertyTypeEnum,
  QueryBuilderProperty,
} from '../../../shared/properties';
import {
  DEFAULT_QUERY_OPERATOR_LABELS,
  QueryBuilderOperator,
} from '../../../utils';
import { ViewColumnFilter } from './types';

type GetFilterBuilderOperator = {
  /**
   * Will default to the labels in `DEFAULT_QUERY_OPERATOR_LABELS`
   */
  label?: string;
  value: QueryBuilderOperator;
};

/**
 * Get the available operators for a given viewColumn type
 *
 * @param viewColumn
 *
 * @see https://react-querybuilder.js.org/docs/components/querybuilder#getoperators
 * @see https://react-querybuilder.js.org/docs/components/querybuilder#operators
 */
export const getViewFilterBuilderOperators = (
  viewColumn: Omit<QueryBuilderProperty, 'mapping'>,
): Required<GetFilterBuilderOperator>[] => {
  const textLikeOperators: GetFilterBuilderOperator[] = [
    { value: '=', label: 'Is' },
    { value: '!=', label: 'Is not' },
    { value: 'contains' },
    { value: 'doesNotContain' },
    { value: 'null' },
    { value: 'notNull' },
  ];

  const choicesSingleValue: GetFilterBuilderOperator[] = [
    { value: '=', label: 'Is' },
    { value: '!=', label: 'Is not' },
    { value: 'in' },
    { value: 'notIn' },
    { value: 'null' },
    { value: 'notNull' },
  ];
  const choicesArrayValues: GetFilterBuilderOperator[] = [
    { value: 'arrayContains' },
    { value: 'arrayContainsAll' },
    { value: 'arrayContainsExactly' },
    { value: 'doesNotArrayContain' },
    { value: 'doesNotArrayContainAll' },
    { value: 'null' },
    { value: 'notNull' },
  ];
  const dateOperators: GetFilterBuilderOperator[] = [
    { value: '=', label: 'On' },
    { value: '!=', label: 'Not on' },
    { value: '<', label: 'Before' },
    { value: '<=', label: 'On or before' },
    { value: '>', label: 'After' },
    { value: '>=', label: 'On or After' },
    { value: 'between' },
    { value: 'notBetween' },
    { value: 'null' },
    { value: 'notNull' },
  ];

  const operators = match(viewColumn)
    .when(
      isViewColumnFilter(PropertyTypeEnum.boolean),
      (): GetFilterBuilderOperator[] => [
        { value: '=', label: 'Is' },
        { value: '!=', label: 'Is not' },
        { value: 'null' },
        { value: 'notNull' },
      ],
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.choices),
      (viewColumn): GetFilterBuilderOperator[] =>
        viewColumn.settings?.allowMultiple
          ? choicesArrayValues
          : choicesSingleValue,
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.countries),
      (viewColumn): GetFilterBuilderOperator[] =>
        viewColumn.settings?.allowMultiple
          ? choicesArrayValues
          : choicesSingleValue,
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.entity_legal_form),
      (): GetFilterBuilderOperator[] => choicesSingleValue,
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.date),
      (): GetFilterBuilderOperator[] => dateOperators,
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.numeric),
      (): GetFilterBuilderOperator[] => [
        { value: '=' },
        { value: '!=' },
        { value: '<' },
        { value: '<=' },
        { value: '>' },
        { value: '>=' },
        { value: 'between' },
        { value: 'notBetween' },
        { value: 'null' },
        { value: 'notNull' },
      ],
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.email),
      (): GetFilterBuilderOperator[] => textLikeOperators,
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.phone_number),
      (): GetFilterBuilderOperator[] => textLikeOperators,
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.text),
      (): GetFilterBuilderOperator[] => textLikeOperators,
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.url),
      (): GetFilterBuilderOperator[] => textLikeOperators,
    )
    .when(
      isViewColumnFilter(PropertyTypeEnum.classifications),
      (): GetFilterBuilderOperator[] => choicesArrayValues,
    )
    .otherwise(
      // Other property type are not supported so returns no operators
      () => [],
    );

  const fullOperators: Required<GetFilterBuilderOperator>[] = operators.map(
    (o) => ({
      value: o.value,
      label: o.label ?? DEFAULT_QUERY_OPERATOR_LABELS[o.value],
    }),
  );

  return fullOperators;
};

export const isViewColumnFilter =
  <F extends PropertyTypeEnum>(type: F) =>
  (
    viewColumnFilter: ViewColumnFilter,
  ): viewColumnFilter is ViewColumnFilter<F> =>
    viewColumnFilter.type === type;
