import { deepFreeze } from '@dotfile/shared/common';

import {
  ClientPortalAvailableDefaultLanguageEnum,
  ClientPortalStepTypeEnum,
} from '../../../../shared/models';
import ClientPortalPropertiesTranslationDE from './properties/de.json';
import ClientPortalPropertiesTranslationEN from './properties/en.json';
import ClientPortalPropertiesTranslationES from './properties/es.json';
import ClientPortalPropertiesTranslationFR from './properties/fr.json';
import ClientPortalPropertiesTranslationIT from './properties/it.json';
import ClientPortalPropertiesTranslationNL from './properties/nl.json';
import ClientPortalPropertiesTranslationPL from './properties/pl.json';
import ClientPortalPropertiesTranslationPT from './properties/pt.json';
import ClientPortalStepsTranslationDE from './steps/de.json';
import ClientPortalStepsTranslationEN from './steps/en.json';
import ClientPortalStepsTranslationES from './steps/es.json';
import ClientPortalStepsTranslationFR from './steps/fr.json';
import ClientPortalStepsTranslationIT from './steps/it.json';
import ClientPortalStepsTranslationNL from './steps/nl.json';
import ClientPortalStepsTranslationPL from './steps/pl.json';
import ClientPortalStepsTranslationPT from './steps/pt.json';

export type ClientPortalStepTranslation = {
  title: string;
  description: string | null;
};

export const ClientPortalStepsTranslations: Record<
  ClientPortalAvailableDefaultLanguageEnum,
  Record<
    Exclude<ClientPortalStepTypeEnum, typeof ClientPortalStepTypeEnum.custom>,
    ClientPortalStepTranslation
  >
> = deepFreeze({
  en: ClientPortalStepsTranslationEN,
  fr: ClientPortalStepsTranslationFR,
  de: ClientPortalStepsTranslationDE,
  es: ClientPortalStepsTranslationES,
  it: ClientPortalStepsTranslationIT,
  nl: ClientPortalStepsTranslationNL,
  pl: ClientPortalStepsTranslationPL,
  pt: ClientPortalStepsTranslationPT,
});

export type ClientPortalPropertyTranslation = {
  label: string | null;
  options?: Record<string, string>;
};

export const ClientPortalPropertiesTranslations: Record<
  ClientPortalAvailableDefaultLanguageEnum,
  {
    company: Record<string, ClientPortalPropertyTranslation>;
    individual: Record<string, ClientPortalPropertyTranslation>;
  }
> = deepFreeze({
  en: ClientPortalPropertiesTranslationEN,
  fr: ClientPortalPropertiesTranslationFR,
  de: ClientPortalPropertiesTranslationDE,
  es: ClientPortalPropertiesTranslationES,
  it: ClientPortalPropertiesTranslationIT,
  nl: ClientPortalPropertiesTranslationNL,
  pl: ClientPortalPropertiesTranslationPL,
  pt: ClientPortalPropertiesTranslationPT,
});
