import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

// @NOTE: Similar to custom property phone_number validation but
// with isRequired

export const phoneNumberValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.phone_number
> = ({ isRequired, localizedValidation }) => {
  let schema: yup.AnySchema = yup
    .string()
    .optionalString()
    .phoneNumber(localizedValidation.phone_number.invalid);

  if (isRequired) {
    schema = schema.required(localizedValidation.required);
  }

  return (
    schema
      // @NOTE cannot do transform for optionalString in strict mode, no strong type check but all will fallback on the phoneNumber validation
      .strict(false)
  );
};
