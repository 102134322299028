import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import {
  ComponentStyleConfig,
  PartsStyleFunction,
  PartsStyleObject,
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleObject<typeof parts> = {
  container: {
    padding: 3,
    borderRadius: 'md',
    alignItems: 'baseline',
  },
  title: {
    fontWeight: 'medium',
    lineHeight: 6,
  },
  description: {},
  icon: {
    flexShrink: 0,
    marginEnd: 3,
    w: 5,
    h: 6,
    alignSelf: 'center',
  },
};

const variantDefault: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c } = props;
  const color = mode(`${c}.700`, `${c}.100`)(props);
  const backgroundColor = mode(`${c}.100`, `${c}.700`)(props);
  return {
    container: {
      width: '100%',
      borderWidth: '1px',
      color,
      borderColor: color,
      background: backgroundColor,
    },
    description: {
      fontSize: 'sm',
    },
    icon: { color: color, w: 4, h: 4 },
  };
};

const variants = {
  default: variantDefault,
};

const defaultProps = {
  variant: 'default',
  colorScheme: 'gray',
  sizes: 'sm',
};

// see https://github.com/chakra-ui/chakra-ui/blob/f14966a6de6d365daf54806a10776bd510168e9e/packages/theme/src/components/alert.ts
export const AlertTheme: ComponentStyleConfig = {
  parts: parts.keys,
  baseStyle,
  variants,
  defaultProps,
};
