import { validate as isUuid } from 'uuid';

export const extractMaybeClientPortalId = (pathname: string): string | null => {
  const clientPortalId = pathname?.split('/')?.[1]; // Pathname start with a slash so the client portal id maybe be in index 1
  if (clientPortalId && isUuid(clientPortalId)) {
    return clientPortalId;
  } else {
    return null;
  }
};
