import {
  createContext as createReactContext,
  useContext as useReactContext,
} from 'react';

export interface CreateContextOptions<T> {
  /**
   * If `true`, React will throw if context is `null` or `undefined`
   * In some cases, you might want to support nested context, so you can set it to `false`
   */
  strict?: boolean;
  hookName?: string;
  providerName?: string;
  /**
   * Error message to throw if the context is `undefined`
   *
   * When omitted, will be generated from `hookName`/`providerName`
   */
  errorMessage?: string;
  /**
   * The display name of the context
   */
  name?: string;
  defaultValue?: T;
}

export type CreateContextReturn<T> = [
  React.Provider<T>,
  () => T,
  React.Context<T>,
];

function getErrorMessage(hook: string, provider: string) {
  return `${hook} returned \`undefined\`. Seems you forgot to wrap component within ${provider}`;
}

/**
 * Creates a named context, provider, and hook.
 *
 * @param options create context options
 */
export function createContext<ContextType>(
  options: CreateContextOptions<ContextType> = {},
): CreateContextReturn<ContextType> {
  const {
    strict = true,
    errorMessage,
    name,
    hookName = 'useContext',
    providerName = 'Provider',
    defaultValue,
  } = options;

  const Context = createReactContext<ContextType | undefined>(defaultValue);

  Context.displayName = name;

  function useContext() {
    const context = useReactContext(Context);

    if (!context && strict) {
      const error = new Error(
        errorMessage ?? getErrorMessage(hookName, providerName),
      );
      error.name = 'ContextError';
      Error.captureStackTrace?.(error, useContext);
      throw error;
    }

    return context;
  }

  return [
    Context.Provider,
    useContext,
    Context,
  ] as CreateContextReturn<ContextType>;
}
