import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueOf } from 'ts-essentials';

import { CURRENCIES } from '@dotfile/shared/domain';

type UseCurrencyReturn = ValueOf<typeof CURRENCIES> | null;

/**
 * @param currencyCode 3 letter ISO4217 currency code
 * @returns The currency with the name in the current i18n language
 * @see https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes
 */
export const useTranslatedCurrency = (
  currencyCode: string | null | undefined,
): UseCurrencyReturn => {
  const { i18n } = useTranslation();

  const currency = useMemo(() => {
    if (!currencyCode) return null;

    const currency = CURRENCIES[currencyCode ?? ''];

    if (!currency) return null;

    const intl = new Intl.DisplayNames([i18n.language], { type: 'currency' });

    return {
      ...currency,
      name: intl.of(currencyCode) ?? currency.name,
    };
  }, [currencyCode, i18n.language]);

  return currency;
};
