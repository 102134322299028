import type { TFunction } from 'i18next';
import { t as defaultT } from 'i18next';

import { CheckDataType, CheckTypeEnum } from '../../../shared/models';
import { DocumentTypeDefinition, documentTypeDefinition } from '../document';
import { getCheckDefinition } from './get-check-definitions';

export const formatCheckTitle = <
  Type extends CheckTypeEnum,
  Data extends CheckDataType<Type>,
>(
  type: Type,
  data: Data,
  {
    t,
    ignorePrefixForDocument,
  }: { t?: TFunction; ignorePrefixForDocument?: boolean } = {},
): string => {
  const checkDefinition = getCheckDefinition(type);
  let title = checkDefinition.label;

  let documentTypeDef: DocumentTypeDefinition | null = null;

  if (extractCheckDataType(CheckTypeEnum.document, type, data)) {
    let suffix = '';
    if (data.settings.documentType) {
      documentTypeDef = documentTypeDefinition(data.settings.documentType);
      suffix = documentTypeDef.label(defaultT);
    }

    const customDocumentTypeLabel =
      data.settings.customDocumentType?.label ??
      // Still display the id if the label is not available
      data.settings.customDocumentTypeId ??
      data.settings.customDocumentType?.id;

    if (customDocumentTypeLabel) {
      documentTypeDef = {
        type: null,
        label: () => customDocumentTypeLabel,
      };
      suffix = customDocumentTypeLabel;
    }

    title = ignorePrefixForDocument ? suffix : `${title} - ${suffix}`;
  }

  if (t) {
    if (ignorePrefixForDocument && type === CheckTypeEnum.document) {
      return documentTypeDef ? documentTypeDef.label(t) : '';
    }

    const documentType = documentTypeDef ? documentTypeDef.label(t) : '';
    const translatedType = t(checkDefinition.labelKey, {
      defaultValue: checkDefinition.label,
      ns: 'domain',
    });

    return t(`check.${type}.title`, {
      defaultValue: title,
      documentType,
      type: translatedType,
      ns: 'domain',
    });
  } else {
    return title;
  }
};

function extractCheckDataType<C extends CheckTypeEnum>(
  expectedType: C,
  type: CheckTypeEnum,
  data: unknown,
): data is CheckDataType<C> {
  return type === expectedType;
}
