import { omit } from 'lodash';
import { AlertCircle, Info } from 'lucide-react';

import { Icon, IconProps } from '../../media-icon/icon/icon';
import { Tooltip } from '../../overlay/tooltip/tooltip';

export interface IndicatorProps {
  /**
   * Tooltip label. If falsy the indicator won't be displayed but `children` will always be
   */
  label?: string | null;
  /**
   * Optionally show the indicator on the top-right of his children
   */
  children?: React.ReactNode;

  iconProps?: IconProps;

  status: 'error' | 'info';
}

export const Indicator = ({
  label,
  status,
  children,
  iconProps = {},
}: IndicatorProps): JSX.Element => {
  if (!label) {
    // fragment is necessary here to make sure the return value is JSX.Element
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  const tooltipProps = {
    error: {
      icon: AlertCircle,
      bgColor: 'red.400',
    },
    info: {
      icon: Info,
      bgColor: 'blue.400',
    },
  };

  const statusProps = tooltipProps[status];

  return children ? (
    <div
      style={{
        position: 'relative',
        width: 'fit-content',
        display: 'flex',
      }}
    >
      {children}
      <Tooltip label={label}>
        <Icon
          as={statusProps.icon}
          bg={statusProps.bgColor}
          color="white"
          borderRadius="full"
          position="absolute"
          top={iconProps.top || '-1'}
          right={iconProps.right || '-1'}
          {...omit(iconProps, 'css')}
        />
      </Tooltip>
    </div>
  ) : (
    <Tooltip label={label}>
      <Icon
        as={statusProps.icon}
        color={statusProps.bgColor}
        ml="2"
        {...omit(iconProps, 'css')}
      />
    </Tooltip>
  );
};
