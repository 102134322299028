import { XIcon } from 'lucide-react';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';

import { logAndAddError } from '@dotfile/frontend/shared/common';
import {
  Badge,
  Button,
  Heading,
  Icon,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { useLatestClientPortalVersion } from '../hooks';
import { FullContainer, MainLayout } from '../layouts';
import { SupportButton } from './support-button';

type GenericErrorScreenProps = {
  error: unknown;

  /**
   * Manually report error to Datadog error tracking.
   *
   * Some error are not worth reporting because they are part of the nominal operation (like attempting to open an offline portal)
   * or the error might already been reported for instance via our ErrorBoundary or our GraphQL client.
   */
  reportError?: boolean;

  /**
   * Override the default title
   */
  title?: string;
  /**
   * Override the default sub-title
   */
  subtitle?: string;

  withMainLayout?: boolean;
};

/**
 * Handle error where the app has been bootstrapped (design-system theme and default i18n available)
 * Client portal may or may not have been loaded
 */
export const GenericErrorScreen = ({
  error,
  reportError,
  title,
  subtitle,
  withMainLayout,
}: GenericErrorScreenProps) => {
  const { data } = useLatestClientPortalVersion({
    // If the data is available, it must already be in the cache
    fetchPolicy: 'cache-only',
  });
  const supportEmail = data?.latestClientPortalVersion?.setting?.supportEmail;

  const { t } = useTranslation();

  const resolvedTitle =
    title ??
    t('errors.default.title', {
      ns: 'client-portal',
      defaultValue: 'An error occurred.',
    });

  const resolvedSubtitle =
    subtitle ??
    (supportEmail
      ? t('errors.default.subtitle', {
          ns: 'client-portal',
          supportEmail,
          defaultValue:
            'Try refreshing the page and if the problem persists click below to reach out to support ({{supportEmail) with the following error message:',
        })
      : t('errors.default.subtitle_no_support_email', {
          ns: 'client-portal',
          defaultValue:
            'Try refreshing the page and if the problem persists contact support with the following error message:',
        }));

  const message = error instanceof ApolloError ? error.message : `${error}`;

  // Report error if needed
  useEffect(() => {
    if (reportError) {
      logAndAddError(error);
    }
  }, [error, reportError]);

  // Avoid keeping the initial 'Loading ...' title
  useEffect(() => {
    if (
      window.document.title ===
      // From apps/client-portal/app/src/index.html
      'Loading ...'
    ) {
      window.document.title = resolvedTitle;
    }
  }, [resolvedTitle]);

  const Layout = withMainLayout ? MainLayout : Fragment;
  return (
    <Layout>
      <FullContainer centerContent>
        <Badge
          w="12"
          h="12"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p="0"
          colorScheme="red"
        >
          <Icon as={XIcon} />
        </Badge>
        <VStack textAlign="center">
          <Heading mb={2} size="lg" textAlign="center">
            {resolvedTitle}
          </Heading>

          <Text>{resolvedSubtitle}</Text>

          <Badge>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{message}</pre>
          </Badge>
        </VStack>

        <Button onClick={() => window.location.reload()}>
          {t('errors.default.refresh_page', {
            ns: 'client-portal',
            defaultValue: 'Refresh the page',
          })}
        </Button>

        <SupportButton supportEmail={supportEmail} />
      </FullContainer>
    </Layout>
  );
};
