import {
  StackProps,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { Header } from './header';

export const MainLayout = ({ children, ...props }: StackProps) => {
  const isSmallScreen = useIsSmallScreen();

  return (
    <VStack align="start" width="full" height="100vh" spacing="0">
      <Header />

      <VStack
        align="start"
        width="full"
        height="full"
        overflow="auto"
        paddingX={isSmallScreen ? '4' : '16'}
        paddingY="8"
        spacing="0"
        {...props}
      >
        {children}
      </VStack>
    </VStack>
  );
};
