import { PAGE_SIZES } from '@dotfile/shared/domain';

import { TableProps } from '../types';
import { computeCacheKeyPrefix } from './cache-key';
import {
  deserializeTableState,
  mergeSerializableTableState,
} from './serialization';
import { TBase } from './types';

/**
 * Get the table state from local storage for the first item with key starting with
 * the `label`
 *
 * @param label
 * @returns
 */
export const getTableLocalStorageStateForLabelPrefix = ({
  label,
  pageSizeOptions,
}: Pick<TableProps<TBase>, 'label' | 'pageSizeOptions'>) => {
  const labelPrefix = computeCacheKeyPrefix({ label });

  const localStorageState = findLocalStorageItemFromPrefix(labelPrefix);

  return mergeSerializableTableState({
    forceState: {},
    propsState: {},
    queryState: {},
    localStorageState: deserializeTableState(
      localStorageState,
      pageSizeOptions ?? PAGE_SIZES,
    ),
  });
};

const findLocalStorageItemFromPrefix = (prefix: string) => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith(prefix)) {
      return localStorage.getItem(key);
    }
  }

  return null;
};
