import { ClientPortalForms_BlockField } from '@dotfile/shared/data-access-client-portal';
import { generateKeyFromMapping } from '@dotfile/shared/domain';

export const FIELDS_PREFIX = '_fields' as const;

/**
 * Generate the html input name for a field inside a form powered by useFieldForm or useFieldFormExtra
 *
 * @param field
 * @returns
 */
export const generateFieldFormName = (
  field: Pick<ClientPortalForms_BlockField, 'mapping'>,
) => {
  return `${FIELDS_PREFIX}.${generateKeyFromMapping(field.mapping)}`;
};
