import { UploadIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonProps,
  Icon,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_Check } from '@dotfile/shared/data-access-client-portal';
import { CheckStatusEnum } from '@dotfile/shared/domain';

import { PendingStatuses } from '../constants';
import { useCanUpdateReferenceData } from '../hooks';

type UploadButtonProps = ButtonProps & {
  check: ClientPortalChecks_Check;
};

export const UploadButton = ({ check, ...props }: UploadButtonProps) => {
  const { t } = useTranslation();

  const canUpdateReferenceData = useCanUpdateReferenceData(check);

  if (!PendingStatuses.includes(check.status)) {
    return null;
  }

  return (
    <Button leftIcon={<Icon as={UploadIcon} />} {...props}>
      {check.status === CheckStatusEnum.rejected
        ? canUpdateReferenceData
          ? t('checks.resolve', {
              ns: 'client-portal',
              defaultValue: 'Resolve',
            })
          : t('checks.upload_new_document', {
              ns: 'client-portal',
              defaultValue: 'Upload new document',
            })
        : t('checks.upload', { ns: 'client-portal', defaultValue: 'Upload' })}
    </Button>
  );
};
