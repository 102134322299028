import { GroupBase, StylesConfig } from 'chakra-react-select';

import { Option } from '../select';

type SelectIsMulti = boolean;

/**
 * Work-around positioning of menu inside popover like for view table filters
 *
 * @warn This may break the Select, for instance is the following situation (but this is not an exhaustive list)
 * - inside an accordion (like for template forms)
 * - in a short modal (like language in create client portal, see context in https://linear.app/dotfile/issue/E-5103)
 * - having special option component like EntityLegalForm on the client portal, see https://linear.app/dotfile/issue/E-5102 )
 *
 * @see https://github.com/JedWatson/react-select/issues/4680#issuecomment-883375414
 *
 */
export const selectorInsidePopoverStylesWorkaround: StylesConfig<
  Option,
  SelectIsMulti,
  GroupBase<Option>
> = {
  menuPortal: () => ({
    position: 'relative',
    top: '0px',
  }),
};
