import { compareAsc, format, formatDistanceToNow } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS, fr } from 'date-fns/locale';

import { parseAbsoluteDate } from './absolute-date.util';
import { isNotNullNotUndefined } from './type-guards';

export const DATE_FORMATS = {
  /**
   * Format Date to `"October 8, 2021"`
   */
  date: 'MMMM d, y',

  /**
   * Format Date to `"Oct 8, 2021"`
   */
  date_short: 'MMM d, y',

  /**
   * Format Date to `"October 8, 2021 09:06"`
   */
  date_time: 'MMMM d, y HH:mm',

  /**
   * Format Date to `"Oct 8, 2021 09:06"`
   */
  date_time_short: 'MMM d, y HH:mm',

  /**
   * Format Date to `"Oct 8, 2021 09:06:59"`
   */
  date_time_short_second: 'MMM d, y HH:mm:ss',

  /**
   * Format Date to `"October 8, 2021 09:06:59"`
   */
  date_time_second: 'MMMM d, y HH:mm:ss',

  /**
   * Format Date to `2021-10-08`
   */
  date_iso: 'yyyy-MM-dd',
} as const;

/**
 * Format a date to `MMM d, y HH:mm` eg `Oct 8, 2021 09:06` or a custom format pattern
 *
 * @param dateOrString
 * @returns
 */
export const formatDateTime = (
  dateOrString: string | Date,
  fmt: string = DATE_FORMATS.date_time_short,
  timezone?: string,
): string => {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);
  return timezone ? formatInTimeZone(date, timezone, fmt) : format(date, fmt);
};

/**
 * Format a date to `MMMM d, y` eg `October 8, 2021`
 *
 * @param dateOrString
 * @returns
 */
export const formatDate = (
  dateOrString: string | Date,
  options?: { locale: string },
): string => {
  try {
    let date: Date;

    if (typeof dateOrString === 'string') {
      // When a string is passed, we need to parse it
      // to a date **in the current timezone**.
      // Simply creating a date object would set the date part to the previous day
      // in case of a negative offset timezone (America/Denver for instance)
      // eg (Europe/Paris): new Date("1984-02-18").toLocaleDateString() -> "18/02/1984"
      // eg (America/Denver): new Date("1984-02-18").toLocaleDateString() -> "2/17/1984"
      const [isoDate] = dateOrString.split('T');
      date = parseAbsoluteDate(isoDate);
    } else {
      date = dateOrString;
    }

    return format(date, DATE_FORMATS.date, {
      locale: options?.locale === 'fr' ? fr : enUS,
    });
  } catch {
    return `Malformed date: ${dateOrString}`;
  }
};

export const formatRelativeDate = (dateOrString: string | Date): string => {
  const date =
    dateOrString instanceof Date ? dateOrString : new Date(dateOrString);

  return formatDistanceToNow(date, {
    addSuffix: true,
  });
};

export const getLatestDate = (
  dates: (string | Date | null | undefined)[],
): Date | null => {
  const latestDates = dates
    .filter(isNotNullNotUndefined)
    .map((dateOrString) =>
      dateOrString instanceof Date ? dateOrString : new Date(dateOrString),
    )
    .sort(compareAsc);

  return latestDates.length > 0 ? latestDates[0] : null;
};
