import { useState } from 'react';
import { match } from 'ts-pattern';

import { CompanySearchInput } from '@dotfile/shared/data-access-client-portal';

import { StepProps } from '../shared';
import { CompanySearchStepSearchForm } from './search-form';
import { CompanySearchStepSelectForm } from './select-form';

export const CompanySearchStep = ({ step, onSubmitStep }: StepProps) => {
  const [companySearchInput, setCompanySearchInput] =
    useState<CompanySearchInput | null>(null);

  return match(companySearchInput)
    .with(null, () => (
      <CompanySearchStepSearchForm
        step={step}
        onSubmit={(input) => {
          setCompanySearchInput(input);
        }}
      />
    ))
    .otherwise(() => (
      <CompanySearchStepSelectForm
        step={step}
        companySearch={companySearchInput as CompanySearchInput}
        onPrevious={() => {
          setCompanySearchInput(null);
        }}
        onSubmit={onSubmitStep}
      />
    ));
};
