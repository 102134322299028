import { CSPHeadersProps } from './csp-headers';

/**
 * Generate the content of a meta tag for Permissions-Policy
 */
export const generatePermissionsPolicyContent = ({
  allowVeriff,
}: CSPHeadersProps): string => {
  const permissionsPolicies = {
    camera: [
      allowVeriff && 'self "https://*.veriff.com" "https://*.veriff.me"',
    ],
    microphone: [
      allowVeriff && 'self "https://*.veriff.com" "https://*.veriff.me"',
    ],
  };

  return `${Object.entries(permissionsPolicies)
    .map(
      ([policy, values]) => `${policy}=(${values.filter(Boolean).join(' ')})`,
    )
    .join(', ')}`;
};
