import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

// @NOTE: Similar to custom property email validation but
// with isRequired

export const emailValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.email
> = ({ isRequired, property, localizedValidation }) => {
  let schema = yup.string().nullable().email(localizedValidation.email.invalid);

  if (property.settings?.maxLength) {
    schema = schema.max(
      property.settings.maxLength,
      localizedValidation.max_length,
    );
  }

  // Add required depending on settings
  if (isRequired) {
    schema = schema
      .required(localizedValidation.required)
      .typeError(localizedValidation.required);
  }

  return schema.strict();
};
