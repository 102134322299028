import { Check, Clock } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@swan-io/chicane';

import { useAuth } from '@dotfile/frontend/shared/common';
import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { useRouter } from '../../../../routes';
import { FullContainer, MainLayout } from '../../../layouts';
import { ContactAuthContext } from '../type';

export const LinkExpiredScreen = ({ token }: { token: string }) => {
  const handleError = useHandleError();

  const { requestMagicLinkFromToken } = useAuth<ContactAuthContext>();

  const { t } = useTranslation();
  const router = useRouter();

  const [requestState, setRequestState] = useState<'idle' | 'pending' | 'done'>(
    'idle',
  );

  const doRequest = async () => {
    setRequestState('pending');

    try {
      await requestMagicLinkFromToken(token);
      setRequestState('done');
    } catch (error) {
      setRequestState('idle');

      handleError({
        error,
        title: t('errors.link_generation_failed', {
          ns: 'client-portal',
          defaultValue:
            'Error during the generation of the link, please try again',
        }),
      });
    }
  };

  return (
    <MainLayout>
      <FullContainer>
        <Container height="full" alignContent="center">
          <VStack alignItems="center" gap={6}>
            <Icon as={Clock} color="orange.500" boxSize={16} />
            <Heading as="h2" size="lg">
              {t('link_expired.title', {
                ns: 'client-portal',
                defaultValue: 'Link has expired',
              })}
            </Heading>

            <VStack gap={0}>
              <Text>
                {t('link_expired.description', {
                  ns: 'client-portal',
                  defaultValue:
                    'The link you are trying to access has expired.',
                })}
              </Text>
              <Text>
                {t('link_expired.resend_link', {
                  ns: 'client-portal',
                  defaultValue:
                    'Just resend a new link to your inbox to resume completion.',
                })}
              </Text>
            </VStack>

            {requestState === 'done' ? (
              <Flex alignItems="center" gap={2}>
                <Icon as={Check} />
                <Text color="green.700">
                  {t('link_expired.link_sent', {
                    ns: 'client-portal',
                    defaultValue:
                      'Link sent ! We just sent you an email with a link to resume your onboarding.',
                  })}
                </Text>
              </Flex>
            ) : (
              <Button
                isLoading={requestState === 'pending'}
                onClick={doRequest}
              >
                {t('link_expired.resend_new_link_button', {
                  ns: 'client-portal',
                  defaultValue: 'Resend new link',
                })}
              </Button>
            )}

            <Button as={Link} variant="ghost" to={router.Login()} replace>
              {t('common.back', { ns: 'client-portal', defaultValue: 'Back' })}
            </Button>
          </VStack>
        </Container>
      </FullContainer>
    </MainLayout>
  );
};
