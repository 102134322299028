import { IdDocumentVendorEnum } from '../../../shared/models';

export const idDocumentVendorDefinition = {
  [IdDocumentVendorEnum.veriff]: {
    label: 'Veriff',
  },
  [IdDocumentVendorEnum.idnow]: {
    label: 'IDnow',
  },
};
