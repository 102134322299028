import { useAuth } from '@dotfile/frontend/shared/common';
import { useCaseChecksQuery } from '@dotfile/shared/data-access-client-portal';

import { ContactAuthContext } from '../../../../shared';

export function useCaseChecks() {
  const {
    auth: { caseId },
  } = useAuth<ContactAuthContext>();

  return useCaseChecksQuery({
    variables: { id: caseId as string },
    skip: !caseId,
  });
}
