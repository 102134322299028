import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationTasks = (): JSX.Element => {
  const { stroke, skeleton, task1, task2 } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="0 0 508 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M433 1C433 41.8691 400.317 75 360 75L360 1L433 1Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C441.186 75 507 140.814 507 222L360 222L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M63 75C63 34.1309 95.6832 1 136 1L136 75L63 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M286 75C286 156.186 218.843 222 136 222L136 75L286 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C360 34.1309 326.869 0.999999 286 0.999997L286 75L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
      </g>
      <g opacity="0.7" filter="url(#filter0_ddd_1624_81844)">
        <rect x="188" y="40" width="256" height="46" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M226.707 52.2929C227.098 52.6834 227.098 53.3166 226.707 53.7071L216.707 63.7071C216.317 64.0976 215.684 64.0976 215.293 63.7071L212.293 60.7071C211.902 60.3166 211.902 59.6834 212.293 59.2929C212.684 58.9024 213.317 58.9024 213.707 59.2929L216 61.5858L225.293 52.2929C225.684 51.9024 226.317 51.9024 226.707 52.2929Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M209 53C208.735 53 208.48 53.1054 208.293 53.2929C208.105 53.4804 208 53.7348 208 54V68C208 68.2652 208.105 68.5196 208.293 68.7071C208.48 68.8946 208.735 69 209 69H223C223.265 69 223.52 68.8946 223.707 68.7071C223.895 68.5196 224 68.2652 224 68V61C224 60.4477 224.448 60 225 60C225.552 60 226 60.4477 226 61V68C226 68.7957 225.684 69.5587 225.121 70.1213C224.559 70.6839 223.796 71 223 71H209C208.204 71 207.441 70.6839 206.879 70.1213C206.316 69.5587 206 68.7957 206 68V54C206 53.2044 206.316 52.4413 206.879 51.8787C207.441 51.3161 208.204 51 209 51H220C220.552 51 221 51.4477 221 52C221 52.5523 220.552 53 220 53H209Z"
          fill={stroke}
        />
        <rect x="244" y="61" width="90" height="4" rx="2" fill={skeleton} />
        <rect x="350" y="57" width="74" height="12" rx="6" fill={task1} />
      </g>
      <g opacity="0.7" filter="url(#filter1_ddd_1624_81844)">
        <rect x="188" y="94" width="256" height="46" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M226.707 106.293C227.098 106.683 227.098 107.317 226.707 107.707L216.707 117.707C216.317 118.098 215.684 118.098 215.293 117.707L212.293 114.707C211.902 114.317 211.902 113.683 212.293 113.293C212.684 112.902 213.317 112.902 213.707 113.293L216 115.586L225.293 106.293C225.684 105.902 226.317 105.902 226.707 106.293Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M209 107C208.735 107 208.48 107.105 208.293 107.293C208.105 107.48 208 107.735 208 108V122C208 122.265 208.105 122.52 208.293 122.707C208.48 122.895 208.735 123 209 123H223C223.265 123 223.52 122.895 223.707 122.707C223.895 122.52 224 122.265 224 122V115C224 114.448 224.448 114 225 114C225.552 114 226 114.448 226 115V122C226 122.796 225.684 123.559 225.121 124.121C224.559 124.684 223.796 125 223 125H209C208.204 125 207.441 124.684 206.879 124.121C206.316 123.559 206 122.796 206 122V108C206 107.204 206.316 106.441 206.879 105.879C207.441 105.316 208.204 105 209 105H220C220.552 105 221 105.448 221 106C221 106.552 220.552 107 220 107H209Z"
          fill={stroke}
        />
        <rect x="244" y="115" width="90" height="4" rx="2" fill={skeleton} />
        <rect x="350" y="111" width="74" height="12" rx="6" fill={task2} />
      </g>
      <g opacity="0.7" filter="url(#filter2_ddd_1624_81844)">
        <rect x="188" y="148" width="256" height="46" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M226.707 160.293C227.098 160.683 227.098 161.317 226.707 161.707L216.707 171.707C216.317 172.098 215.684 172.098 215.293 171.707L212.293 168.707C211.902 168.317 211.902 167.683 212.293 167.293C212.684 166.902 213.317 166.902 213.707 167.293L216 169.586L225.293 160.293C225.684 159.902 226.317 159.902 226.707 160.293Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M209 161C208.735 161 208.48 161.105 208.293 161.293C208.105 161.48 208 161.735 208 162V176C208 176.265 208.105 176.52 208.293 176.707C208.48 176.895 208.735 177 209 177H223C223.265 177 223.52 176.895 223.707 176.707C223.895 176.52 224 176.265 224 176V169C224 168.448 224.448 168 225 168C225.552 168 226 168.448 226 169V176C226 176.796 225.684 177.559 225.121 178.121C224.559 178.684 223.796 179 223 179H209C208.204 179 207.441 178.684 206.879 178.121C206.316 177.559 206 176.796 206 176V162C206 161.204 206.316 160.441 206.879 159.879C207.441 159.316 208.204 159 209 159H220C220.552 159 221 159.448 221 160C221 160.552 220.552 161 220 161H209Z"
          fill={stroke}
        />
        <rect x="244" y="169" width="90" height="4" rx="2" fill={skeleton} />
        <rect x="350" y="165" width="74" height="12" rx="6" fill={task1} />
      </g>
      <defs>
        <filter
          id="filter0_ddd_1624_81844"
          x="0.399979"
          y="-101.4"
          width="631.2"
          height="421.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_1624_81844"
          x="0.399979"
          y="-47.4"
          width="631.2"
          height="421.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_ddd_1624_81844"
          x="0.399979"
          y="6.59998"
          width="631.2"
          height="421.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
