import { EmptyStateIllustrationAffiliated } from './affiliated';
import { EmptyStateIllustrationCases } from './cases';
import { EmptyStateIllustrationCheck } from './check';
import { EmptyStateIllustrationCompanies } from './companies';
import { EmptyStateIllustrationCompany } from './company';
import { EmptyStateIllustrationDefault } from './default';
import { EmptyStateIllustrationDocuments } from './documents';
import { EmptyStateIllustrationIndividual } from './individual';
import { EmptyStateIllustrationIndividualCompany } from './individual-company';
import { EmptyStateIllustrationIndividuals } from './individuals';
import { EmptyStateIllustrationMissingData } from './missing-data';
import { EmptyStateIllustrationProcessing } from './processing';
import { EmptyStateIllustrationTags } from './tag';
import { EmptyStateIllustrationTasks } from './tasks';
import { EmptyStateIllustrationTemplates } from './templates';

export type EmptyStateIllustration = keyof typeof illustrationMap;

// @TODO - E-3253 - Generic empty state illustration

export const illustrationMap = {
  individual: EmptyStateIllustrationIndividual,
  'individual-company': EmptyStateIllustrationIndividualCompany,
  company: EmptyStateIllustrationCompany,
  individuals: EmptyStateIllustrationIndividuals,
  companies: EmptyStateIllustrationCompanies,
  check: EmptyStateIllustrationCheck,
  cases: EmptyStateIllustrationCases,
  documents: EmptyStateIllustrationDocuments,
  tags: EmptyStateIllustrationTags,
  tasks: EmptyStateIllustrationTasks,
  templates: EmptyStateIllustrationTemplates,
  default: EmptyStateIllustrationDefault,
  'missing-data': EmptyStateIllustrationMissingData,
  processing: EmptyStateIllustrationProcessing,
  affiliated: EmptyStateIllustrationAffiliated,
} as const;

export { EmptyStateIllustrationDefault } from './default';
