import { webhookEventDefinition } from './webhook-event.definition';

type ExtractNames<T> = T extends {
  name: infer N;
  children: readonly (infer C)[];
}
  ? N | ExtractNames<C>
  : T extends { name: infer N }
    ? N
    : never;

export type WebhookEventName = ExtractNames<
  (typeof webhookEventDefinition)[number]
>;

export type WebhookSubEventItem = {
  name: WebhookEventName;
  aliasFor?: WebhookEventName[];
};

export type WebhookParentEventItem = {
  name: WebhookEventName;
  children: WebhookSubEventItem[];
};

export type WebhookEventItem = WebhookSubEventItem | WebhookParentEventItem;

export const isWebhookParentEventItem = (
  item: WebhookEventItem,
): item is WebhookParentEventItem =>
  (item as WebhookParentEventItem).children != null;

export const isSubEventItem = (
  item: WebhookEventItem,
): item is WebhookSubEventItem =>
  (item as WebhookParentEventItem).children == null;

export const getWebhookEvents = (): WebhookEventName[] => {
  return webhookEventDefinition
    .flatMap((event) => {
      if (isWebhookParentEventItem(event))
        return [event.name, ...event.children.map((event) => event.name)];
      return event.name;
    })
    .sort();
};

export const WEBHOOK_EVENTS = getWebhookEvents();

export const isWebhookEvent = (name: string): name is WebhookEventName =>
  (WEBHOOK_EVENTS as string[]).includes(name);

export const isWebhookSubEvent = (name: string): boolean => {
  return (Array.from(webhookEventDefinition) as WebhookEventItem[])
    .filter(isWebhookParentEventItem)
    .some((event) => event.children.some((child) => child.name === name));
};

export const getSubscribableEvents = (): WebhookEventName[] =>
  webhookEventDefinition
    .flatMap((event) => {
      if (isWebhookParentEventItem(event))
        return event.children.flatMap((event) => {
          if (event.aliasFor) return event.aliasFor;
          return event.name;
        });
      return event.name;
    })
    .sort();

// SubEvents are the events the dispatcher subscribes to.
export type WebhookSubEventName = ReturnType<
  typeof getSubscribableEvents
>[number];

export const isWebhookSubscribableEvent = (
  name: string,
): name is WebhookSubEventName =>
  getSubscribableEvents().includes(name as WebhookSubEventName);

export const isParentWebhookOf = (
  parent: WebhookParentEventItem,
  other: WebhookSubEventItem,
): boolean =>
  parent.name !== other.name &&
  parent.children.some((child) => other.name === child.name);

const sortEventItems = (a: WebhookEventItem, b: WebhookEventItem) =>
  a.name.localeCompare(b.name);

export const getWebhookEventItems = (): WebhookEventItem[] => {
  return webhookEventDefinition
    .map((event) => {
      if (isWebhookParentEventItem(event))
        return {
          name: event.name,
          children: event.children
            .map((event) => ({ name: event.name }))
            .sort(sortEventItems),
        };

      return { name: event.name };
    })
    .sort(sortEventItems);
};

export const getWebhookParentEvent = (
  parentName: WebhookEventName,
): WebhookParentEventItem | null => {
  return (
    getWebhookEventItems()
      .filter(isWebhookParentEventItem)
      .find((eventItem) => eventItem.name === parentName) ?? null
  );
};

export const WEBHOOK_PARENT_EVENTS = webhookEventDefinition.map(
  (event) => event.name,
);

const walkEventBranch = (
  searched: string,
  event: WebhookEventItem,
): WebhookEventName[] | null => {
  if (isWebhookParentEventItem(event)) {
    for (const child of event.children) {
      const forChild = walkEventBranch(searched, child);
      if (forChild) return [event.name, ...forChild];
    }
  } else if (isSubEventItem(event) && event.aliasFor) {
    for (const alias of event.aliasFor) {
      const forAlias = walkEventBranch(searched, { name: alias });
      if (forAlias) return [event.name];
    }
  } else if (event.name === searched) return [searched];

  return null;
};

export const findEventBranch = (event: string): WebhookEventName[] => {
  for (const item of webhookEventDefinition) {
    const forEvent = walkEventBranch(event, item);
    if (forEvent) return forEvent;
  }

  return [];
};
