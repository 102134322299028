import { useEffect } from 'react';

import { ClientPortalForms_Step } from '@dotfile/shared/data-access-client-portal';

import { useFormDatastore } from '../context';
import { useLatestClientPortalVersionForms } from './use-latest-client-portal-version-forms';
import { useStepProgress } from './use-step-progress';

/**
 * Compute initial progress from store and query
 */
export function useStepProgressInit() {
  const { data } = useLatestClientPortalVersionForms();
  const { setProgress, progress } = useFormDatastore((state) => state);
  const { current } = useStepProgress();

  useEffect(() => {
    if (data) {
      const { steps } = data.latestClientPortalVersion;
      const completedSteps = [
        ...new Set(
          [...(progress.completedStepKeys ?? [])]
            .map((id) => steps.find((step) => step.key === id))
            .filter(
              (
                t: ClientPortalForms_Step | undefined,
              ): t is ClientPortalForms_Step => !!t,
            ),
        ),
      ];

      // if state currentStep is not set, or not in steps, fallback to last completed or first step
      let currentStepKey = current;
      const lastCompletedStep = completedSteps.slice(-1)[0]?.key;
      if (
        lastCompletedStep &&
        (!progress.currentStepKey ||
          !steps.find((step) => progress.currentStepKey === step.key))
      ) {
        currentStepKey = lastCompletedStep;
      }

      if (
        // no current progress
        !current ||
        // state progress different from computed progress
        current !== progress.currentStepKey ||
        // no completed steps
        !progress.completedStepKeys ||
        // completed steps different from computed completed steps
        completedSteps.length !== progress.completedStepKeys?.length
      ) {
        setProgress({
          currentStepKey,
          completedStepKeys: completedSteps.map((step) => step.key),
        });
      }
    }
  }, [data, setProgress, progress, current]);
}
