import { MarkRequired } from 'ts-essentials';

import { AnalysisResultModel, CheckResultEnum } from '../../../shared/models';

/**
 * Compute check data result from analysisResults instead of the raw payload.
 * We're ignoring some IDnow "checks", which conflict with using the
 * globalStatus / lastAnalysisStatus of the report.
 * We still map it as vendor.verificationStatus.
 */
export const computeDataResultFromAnalysisResults = (
  analysisResults: MarkRequired<
    Partial<AnalysisResultModel>,
    'key' | 'result'
  >[],
  options: {
    /**
     * Ignore some key, this is useful to compute "vendor result" without
     * including data comparison in the result
     */
    excludeKeys?: string[];
  } = { excludeKeys: [] },
): CheckResultEnum | null => {
  if (!analysisResults.length) return null;

  return analysisResults.reduce<CheckResultEnum>((acc, analysisResults) => {
    // Don't run further if at least one of analysisResults is rejected
    if (acc === CheckResultEnum.rejected) {
      return acc;
    }

    // Ignore some key, this is useful to compute vendorResult without
    if (
      options.excludeKeys &&
      options.excludeKeys.includes(analysisResults.key)
    ) {
      return acc;
    }

    if (analysisResults.result === CheckResultEnum.rejected) {
      return CheckResultEnum.rejected;
    }

    if (analysisResults.result === CheckResultEnum.error) {
      return CheckResultEnum.error;
    }

    // At this point, we either in "error or "approved"
    return acc;
  }, CheckResultEnum.approved);
};
