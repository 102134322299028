import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

// @NOTE: Similar to custom property yes_no validation but
// with isRequired

export const booleanValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.boolean
> = ({ isRequired, localizedValidation }) => {
  let schema: yup.AnySchema = yup
    .boolean()
    .typeError(localizedValidation.required)
    .defined();

  if (isRequired) {
    schema = schema.required(localizedValidation.required);
  } else {
    schema = schema.nullable();
  }

  return schema.strict();
};
