import {
  COMPANY_COUNTRY_VENDOR_NONE,
  CompanyDataVendorEnum,
  WorkspaceSettingsCompanyDataVendor,
} from '../../../shared/models';
import { COUNTRY_COVERAGE } from './vendor';

export function getAvailableCompanyDataVendorForCountry(
  country: string,
  { includeNone = false }: { includeNone?: boolean } = {},
): WorkspaceSettingsCompanyDataVendor[] {
  const vendors: WorkspaceSettingsCompanyDataVendor[] = [];

  (Object.keys(COUNTRY_COVERAGE) as CompanyDataVendorEnum[]).forEach(
    (vendor: CompanyDataVendorEnum) => {
      if (
        COUNTRY_COVERAGE[vendor].find(
          ({ code }) => code === country.toUpperCase(),
        )
      ) {
        vendors.push(vendor);
      }
    },
  );

  if (includeNone) {
    vendors.push(COMPANY_COUNTRY_VENDOR_NONE);
  }

  return vendors;
}

export function getAvailableCompanyDataVendorForCountryWithoutNone(
  country: string,
): CompanyDataVendorEnum[] {
  return getAvailableCompanyDataVendorForCountry(country, {
    includeNone: false,
  }) as CompanyDataVendorEnum[];
}
