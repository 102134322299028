import { AtSign, Bold, Italic, Link, List } from 'lucide-react';
import { ReactNode } from 'react';

import { Divider } from '../../../data-display/divider/divider';
import { HStack, StackProps } from '../../../layout/stack/stack';
import { RichTextLinkPopover } from './rich-text-link-popover';
import { RichTextMenuButton } from './rich-text-menu-button';

type RichTextMenuProps = StackProps & {
  withMention?: boolean;
  withLink?: boolean;
};

const nonWhitespaceRegex = /(\S$)/;

export const RichTextMenu = ({
  withMention,
  withLink,
  ...stackProps
}: RichTextMenuProps): ReactNode => {
  return (
    <HStack {...stackProps}>
      <RichTextMenuButton
        aria-label="bold"
        command={(chain) => chain.toggleBold()}
        name="bold"
        icon={Bold}
      />

      <RichTextMenuButton
        aria-label="italic"
        command={(chain) => chain.toggleItalic()}
        name="italic"
        icon={Italic}
      />

      <Divider orientation="vertical" borderColor="gray.100" height="4" />

      <RichTextMenuButton
        aria-label="add bullet list"
        command={(chain) => chain.toggleBulletList()}
        name="bulletList"
        icon={List}
      />

      {withMention && (
        <>
          <Divider orientation="vertical" borderColor="gray.100" height="4" />

          <RichTextMenuButton
            aria-label="add mention"
            command={(chain, editor) => {
              const textBefore =
                editor.view.state.selection.$from.nodeBefore?.text;
              const shouldPrependSpace =
                textBefore && nonWhitespaceRegex.test(textBefore);

              return chain.insertContent(shouldPrependSpace ? ' @' : '@');
            }}
            icon={AtSign}
          />
        </>
      )}

      {withLink && (
        <>
          <Divider orientation="vertical" borderColor="gray.100" height="4" />

          <RichTextLinkPopover>
            <RichTextMenuButton aria-label="add link" name="link" icon={Link} />
          </RichTextLinkPopover>
        </>
      )}
    </HStack>
  );
};
