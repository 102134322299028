import { memo } from 'react';

import { type Plugin, type RenderViewer, Viewer } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';

import {
  useEnvironmentContext,
  useHttpClient,
} from '@dotfile/frontend/shared/common';
import { Skeleton } from '@dotfile/frontend/shared/design-system';

import { ViewerFile } from '../../type';
import { ThumbnailLayout, ThumbnailLayoutProps } from '../shared';

export interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement;
}

export const pageThumbnailPlugin = (
  props: PageThumbnailPluginProps,
): Plugin => {
  const { PageThumbnail } = props;

  return {
    renderViewer: (renderProps: RenderViewer) => {
      const { slot } = renderProps;

      slot.children = PageThumbnail;

      // Reset the sub slot
      if (slot.subSlot) {
        slot.subSlot.attrs = {};
        // eslint-disable-next-line react/jsx-no-useless-fragment
        slot.subSlot.children = <></>;
      }

      return slot;
    },
  };
};
export type PdfThumbnailProps = {
  file: ViewerFile;
} & ThumbnailLayoutProps;

export const PdfThumbnail = memo(
  ({ file, ...layoutProps }: PdfThumbnailProps) => {
    // File access
    const environment = useEnvironmentContext();
    const httpClient = useHttpClient();

    const media = {
      fileUrl: `${environment.baseAPI}/files/preview/${file.id}`,
      httpHeaders: httpClient.getHeaders(),
      withCredentials: true,
    };

    // @see https://react-pdf-viewer.dev/examples/display-the-thumbnail-of-a-given-page/
    const thumbnailPluginInstance = thumbnailPlugin();
    const { Cover } = thumbnailPluginInstance;
    const pageThumbnailPluginInstance = pageThumbnailPlugin({
      PageThumbnail: (
        <Cover
          getPageIndex={
            () => 0 // Always first page thumbnail
          }
        />
      ),
    });

    return (
      <ThumbnailLayout {...layoutProps}>
        <Viewer
          {...media}
          plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
          renderLoader={() => <Skeleton w="100%" h="100%" />}
        />
      </ThumbnailLayout>
    );
  },
);

PdfThumbnail.displayName = 'PdfThumbnail';
