const swatch = {
  // Override Chakra palette
  transparent: 'transparent',
  black: '#00111F',
  white: '#FFF',
  coal: {
    50: '#ECEBEB',
    100: '#E0E0E0',
    200: '#D7D7D7',
    300: '#777776',
    400: '#535252',
    500: '#1D1B1B',
  },
  green: {
    50: '#EDFDF9',
    100: '#CAFCF0',
    200: '#98F7E2',
    300: '#5CECD2',
    400: '#2AD7BC',
    500: '#0FA893',
    600: '#0B7A6D',
    700: '#0B655B',
    800: '#105650',
    900: '#0D3A36',
    950: '#03302E',
  },
  orange: {
    50: '#FEF9F5',
    100: '#FEF1E6',
    200: '#FBCEAD',
    300: '#F9AB78',
    400: '#F57E42',
    500: '#F25B1D',
    600: '#D74014',
    700: '#B32F14',
    800: '#852314',
    900: '#601B10',
    950: '#51110B',
  },
  gray: {
    25: '#FCFCFD',
    50: '#F6F6F9',
    100: '#ECEEF4',
    200: '#DFE2EC',
    300: '#BBC0D3',
    400: '#858bad',
    500: '#5C6084',
    600: '#474766',
    700: '#333347',
    800: '#22232F',
    900: '#09090B',
  },
  red: {
    50: '#FFF5F6',
    100: '#FFE4E8',
    200: '#FDCED8',
    300: '#FCA5B7',
    400: '#F97391',
    500: '#F02D5E',
    600: '#DE2059',
    700: '#BC144A',
    800: '#9D1445',
    900: '#861541',
    950: '#550723',
  },
  yellow: {
    50: '#FFFEF5',
    100: '#FEFCE6',
    200: '#FDF5AB',
    300: '#FAE356',
    400: '#F6CB09',
    500: '#D7A809',
    600: '#9A6709',
    700: '#865509',
    800: '#6D420D',
    900: '#563210',
    950: '#412107',
  },
  teal: {
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044',
  },
  blue: {
    25: '#FAFCFF',
    50: '#F5F8FF',
    75: '#F0F4FF',
    100: '#E6EDFE',
    200: '#bfd2fe',
    300: '#94b6fc',
    400: '#618ff9',
    500: '#3b67f5',
    600: '#2647ea',
    700: '#1e34d7',
    800: '#1f2cae',
    900: '#1f2b89',
    950: '#171c54',
  },
  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC6EA',
    500: '#00B5D8',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },
  purple: {
    50: '#F4F2FF',
    100: '#EAE8FF',
    200: '#D9D4FF',
    300: '#BCB1FF',
    400: '#9B58FF',
    500: '#7B54FE',
    600: '#6B31F6',
    700: '#5C1FE2',
    800: '#4C19BE',
    900: '#41179B',
    950: '#260C69',
  },
  pink: {
    50: '#FFF5F7',
    100: '#FED7E2',
    200: '#FBB6CE',
    300: '#F687B3',
    400: '#ED64A6',
    500: '#D53F8C',
    600: '#B83280',
    700: '#97266D',
    800: '#702459',
    900: '#521B41',
  },
};

const tokens = {
  primary: swatch.black,
  secondary: swatch.gray['400'],
  text: swatch.gray['500'],
};

export const colors = {
  ...swatch,
  ...tokens,
};
