import { humanCase } from '@dotfile/shared/common';

import {
  AmlListTypeEnum,
  CheckModel,
  CheckStatusEnum,
  CheckTypeEnum,
  DocumentTypeEnum,
  EntityTypeEnum,
  IdDocumentVendorEnum,
  IdentityDocumentTypeEnum,
  IdVerificationDataModeEnum,
  IdVerificationVendorEnum,
  ViewColumnMappingEntityEnum,
  ViewColumnMappingTypeEnum,
} from '../../../shared/models';
import { PropertyTypeEnum } from '../../../shared/properties';
import {
  flatIdVerificationModeDefinition,
  getCheckDefinition,
} from '../../checks';
import { vendorDefinition } from '../../integration';
import { ViewColumn } from './types';
import { ViewColumnBuilder } from './view-column-builder';

type CheckViewColumn = Omit<
  // @NOTE Also update the test snapshot when modifying the properties
  CheckModel,
  | 'workspace'
  | 'workspaceId'
  | 'individual'
  | 'individualId'
  | 'company'
  | 'companyId'
  | 'subtype'
  | 'amlData'
  | 'documentData'
  | 'idDocumentData'
  | 'idVerificationData'
  | 'updatedAt'
  | 'deletedAt'
> & {
  entityName: string;
  entityType: EntityTypeEnum;

  // AML
  amlTruePositiveListTypes: AmlListTypeEnum[];

  // Document
  documentDataSettingsDocumentType: DocumentTypeEnum | string;

  // IDD
  idDocumentVendor: IdDocumentVendorEnum;

  // IDD / IDV -> Identity
  identityDocumentType: IdentityDocumentTypeEnum;
  identityDocumentNumber: string;
  identityFirstName: string;
  identityLastName: string;
  identityNationality: string;
  identityBirthDate: string;
  identityBirthCountry: string;
  identityExpirationDate: string;
  identityIssuingDate: string;
  identityIssuingCountry: string;

  // IDV
  idVerificationVendor: IdVerificationVendorEnum;
  idVerificationMode: IdVerificationDataModeEnum;
};

export const checkViewColumnDefinition: Record<
  keyof CheckViewColumn,
  ViewColumn
> = new ViewColumnBuilder<CheckViewColumn>(ViewColumnMappingEntityEnum.check)
  .addViewColumn('id', PropertyTypeEnum.text, {
    canSort: false,
  })
  .addViewColumn('status', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CheckStatusEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })
  .addViewColumn('type', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CheckTypeEnum).map((key) => ({
        key,
        label: getCheckDefinition(key).label,
      })),
    },
  })
  .addViewColumn('entityName', PropertyTypeEnum.text, {
    mapping: {
      type: ViewColumnMappingTypeEnum.computed,
    },
  })
  .addViewColumn('entityType', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(EntityTypeEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.computed,
    },
  })
  .addViewColumn('lastActivityAt', PropertyTypeEnum.date, {
    label: 'Last activity',
  })
  .addViewColumn('createdAt', PropertyTypeEnum.date, {
    label: 'Created at',
  })
  .addViewColumn('isInternal', PropertyTypeEnum.boolean, {
    label: 'Internal',
  })

  // AML
  .addViewColumn('amlTruePositiveListTypes', PropertyTypeEnum.choices, {
    label: 'AML > True positive list types',
    settings: {
      allowMultiple: true,
      options: Object.values(AmlListTypeEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })

  .addViewColumn('documentDataSettingsDocumentType', PropertyTypeEnum.choices, {
    label: 'Document > Document type',
    settings: {
      allowMultiple: false,
      options: Object.values(DocumentTypeEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })

  // IDD
  .addViewColumn('idDocumentVendor', PropertyTypeEnum.choices, {
    label: 'ID Document > Vendor',
    settings: {
      allowMultiple: false,
      options: Object.values(IdDocumentVendorEnum).map((key) => ({
        key,
        label: vendorDefinition[key].name,
      })),
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })

  // IDV
  .addViewColumn('idVerificationVendor', PropertyTypeEnum.choices, {
    label: 'ID Verification > Vendor',
    settings: {
      allowMultiple: false,
      options: Object.values(IdVerificationVendorEnum).map((key) => ({
        key,
        label: vendorDefinition[key].name,
      })),
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('idVerificationMode', PropertyTypeEnum.choices, {
    label: 'ID Verification > Mode',
    settings: {
      allowMultiple: false,
      options: Object.values(IdVerificationDataModeEnum).map((key) => ({
        key,
        label: flatIdVerificationModeDefinition[key].label,
      })),
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })

  // IDD / IDV -> Identity
  .addViewColumn('identityDocumentType', PropertyTypeEnum.choices, {
    label: 'Identity > Document type',
    settings: {
      allowMultiple: false,
      options: Object.values(IdentityDocumentTypeEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityDocumentNumber', PropertyTypeEnum.text, {
    label: 'Identity > Document number',
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityFirstName', PropertyTypeEnum.text, {
    label: 'Identity > First name',
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityLastName', PropertyTypeEnum.text, {
    label: 'Identity > Last name',
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityNationality', PropertyTypeEnum.countries, {
    label: 'Identity > Nationality',
    settings: {
      allowMultiple: false,
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityBirthDate', PropertyTypeEnum.date, {
    label: 'Identity > Birth date',
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityBirthCountry', PropertyTypeEnum.countries, {
    label: 'Identity > Birth country',
    settings: {
      allowMultiple: false,
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityExpirationDate', PropertyTypeEnum.date, {
    label: 'Identity > Expiration date',
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityIssuingDate', PropertyTypeEnum.date, {
    label: 'Identity > Issuing date',
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })
  .addViewColumn('identityIssuingCountry', PropertyTypeEnum.countries, {
    label: 'Identity > Issuing country',
    settings: {
      allowMultiple: false,
    },
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
    canSort: false,
  })

  .getDefinition();

export const allCheckViewColumnDefinition = Object.values(
  checkViewColumnDefinition,
);
