import { useMemo } from 'react';

import { ClientPortalForms_Step } from '@dotfile/shared/data-access-client-portal';

import { useFormDatastore } from '../context';
import { useGetDisplayedSteps } from './use-displayed-steps';

export function useStepProgress() {
  const progress = useFormDatastore((state) => state.progress);
  const getDisplayedSteps = useGetDisplayedSteps();

  const stepProgress = useMemo(() => {
    // Get filtered steps that should not match logics
    const steps = getDisplayedSteps();

    const foundStep = steps.find((step) => {
      return step.key === progress.currentStepKey;
    });
    const lastCompletedStep = [
      ...(progress.completedStepKeys ?? []),
    ].reduce<ClientPortalForms_Step | null>(
      (acc, id) => acc || (steps.find((step) => step.key === id) ?? null),
      null,
    );
    const firstStep = steps[0];
    const currentStepId =
      foundStep?.key ?? lastCompletedStep?.key ?? firstStep?.key ?? null;

    return {
      current: currentStepId,
      getNext: () => {
        // Always get up to date filtered step for next
        const steps = getDisplayedSteps();
        const currentStepIndex = steps.findIndex(
          (step) => step.key === currentStepId,
        );
        return steps.length - 1 <= currentStepIndex
          ? null
          : steps[currentStepIndex + 1].key;
      },
      getPrevious: () => {
        // Always get up to date filtered step for previous
        const steps = getDisplayedSteps();
        const currentStepIndex = steps.findIndex(
          (step) => step.key === currentStepId,
        );
        return currentStepIndex === 0 ? null : steps[currentStepIndex - 1].key;
      },
    };
  }, [progress, getDisplayedSteps]);

  return stepProgress;
}
