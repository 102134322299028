import { AlertCircle, CheckCircle, Info } from 'lucide-react';
import React from 'react';

import {
  AlertStatus,
  chakra,
  Alert as ChakraAlert,
  AlertDescription as ChakraAlertDescription,
  AlertDescriptionProps as ChakraAlertDescriptionProps,
  AlertIconProps as ChakraAlertIconProps,
  AlertTitle as ChakraAlertTitle,
  AlertTitleProps as ChakraAlertTitleProps,
  HTMLChakraProps,
  SystemStyleObject,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';

import { Icon } from '../../media-icon/icon/icon';
import { forwardRef } from '../../utils/component-factory';
import { createContext } from '../../utils/create-context';

const STATUSES = {
  info: { icon: Info, colorScheme: 'blue' },
  warning: { icon: AlertCircle, colorScheme: 'orange' },
  success: { icon: CheckCircle, colorScheme: 'green' },
  error: { icon: AlertCircle, colorScheme: 'red' },
  loading: { icon: Info, colorScheme: 'gray' },
} satisfies Record<AlertStatus, { icon: unknown; colorScheme: string }>;

interface AlertContext {
  status: AlertStatus;
}

export const [AlertProvider, useAlertContext] = createContext<AlertContext>({
  name: 'AlertContext',
  hookName: 'useAlertContext',
  providerName: '<Alert />',
});

export const [AlertStylesProvider, useAlertStyles] = createContext<
  Record<string, SystemStyleObject>
>({
  name: `AlertStylesContext`,
  hookName: `useAlertStyles`,
  providerName: '<Alert />',
});

interface AlertOptions {
  status?: AlertStatus;
}
export interface AlertProps
  extends HTMLChakraProps<'div'>,
    AlertOptions,
    ThemingProps<'Alert'> {}

export const Alert = forwardRef((props: AlertProps, ref): JSX.Element => {
  const { children, status = 'info', ...rest } = props;
  const colorScheme = props.colorScheme ?? STATUSES[status].colorScheme;

  const styles = useMultiStyleConfig('Alert', { ...props, colorScheme });

  const alertStyles: SystemStyleObject = {
    ...styles['container'],
    // __css style always override style from rest/props so it must be merged here
    width: rest.width ?? '100%',
    display: rest.display ?? 'flex',
    alignItems: rest.alignItems ?? 'center',
    position: rest.position ?? 'relative',
    overflow: rest.overflow ?? 'hidden',
    fontSize: rest.fontSize ?? 'md',
  };

  return (
    <AlertProvider value={{ status }}>
      <AlertStylesProvider value={styles}>
        <chakra.div
          role="alert"
          {...rest}
          ref={ref}
          className={cx('chakra-alert', props.className)}
          __css={alertStyles}
        >
          {/* Must still wrap with ChakraAlert to have the chakra style context for other ChakraAlert components */}
          <ChakraAlert
            status={status}
            padding="0"
            backgroundColor="transparent"
            border="none"
          >
            {children}
          </ChakraAlert>
        </chakra.div>
      </AlertStylesProvider>
    </AlertProvider>
  );
});

export type AlertIconProps = ChakraAlertIconProps;
// based on Chakra AlertIcon with react-feather icon
// https://github.com/chakra-ui/chakra-ui/blob/f14966a6de6d365daf54806a10776bd510168e9e/packages/alert/src/alert.tsx#L120-L135
export const AlertIcon: React.FC<AlertIconProps> = (props: AlertIconProps) => {
  const { status } = useAlertContext();
  const { icon: asIcon } = STATUSES[status];
  const styles = useAlertStyles();

  return (
    <chakra.span
      display="inherit"
      {...props}
      className={cx('chakra-alert__icon', props.className)}
      __css={styles['icon']}
    >
      {asIcon && (
        <Icon w="100%" h="100%" as={asIcon} aria-label={`icon ${status}`} />
      )}
    </chakra.span>
  );
};

export type AlertTitleProps = ChakraAlertTitleProps;
export const AlertTitle = ChakraAlertTitle;

export type AlertDescriptionProps = ChakraAlertDescriptionProps;
export const AlertDescription = ChakraAlertDescription;
