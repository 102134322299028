import { Building } from 'lucide-react';

import { BaseEmptyStateIllustrationIcon } from './base-icon';
import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationCompany = (): JSX.Element => {
  const { stroke } = useIllustrationTokens();

  return (
    <BaseEmptyStateIllustrationIcon>
      <Building stroke={stroke} size="16" x="212" y="49" />
    </BaseEmptyStateIllustrationIcon>
  );
};
