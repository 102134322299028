export const CaseFlagEnum = {
  // enum order matters because
  // - the first flags that is found on the case will determine its "progress" value
  //   @see libs/backend/shared/database/src/lib/entities/view/case-list.view.ts#47
  // - flags array is set in this order
  //   @see libs/shared/domain/src/lib/modules/case-management/case/flags/compute-actions-and-flags.ts#39

  all_checks_approved: 'all_checks_approved',
  for_review: 'for_review',
  for_first_collect: 'for_first_collect',
  for_recollection: 'for_recollection',
  first_collect_completed: 'first_collect_completed',
} as const;
export type CaseFlagEnum = (typeof CaseFlagEnum)[keyof typeof CaseFlagEnum];
