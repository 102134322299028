import { CaseStatusEnum } from './case-status.enum';
import { subEnum } from './sub-enum.helper';

export const CaseReviewStatusEnum = subEnum(CaseStatusEnum, [
  CaseStatusEnum.approved,
  CaseStatusEnum.rejected,
  CaseStatusEnum.closed,
]);
export type CaseReviewStatusEnum =
  (typeof CaseReviewStatusEnum)[keyof typeof CaseReviewStatusEnum];
