import { AppLoader, FullContainer, GenericErrorScreen } from '../../../shared';
import { UbbleCheckoutSdkProvider, useCaseChecks } from '../shared';
import { ChecksHeader, ChecksTabList } from './components';
import { ChecksModal } from './components/checks-modal';

export const ChecksPage = () => {
  const { loading, error } = useCaseChecks();

  if (loading) {
    return <AppLoader />;
  }

  if (error) {
    return <GenericErrorScreen error={error} />;
  }

  return (
    <UbbleCheckoutSdkProvider>
      <FullContainer>
        <ChecksHeader />
        <ChecksTabList />
        <ChecksModal />
      </FullContainer>
    </UbbleCheckoutSdkProvider>
  );
};
