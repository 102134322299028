import { QueryHookOptions } from '@apollo/client';

import {
  LatestClientPortalVersionQuery,
  useLatestClientPortalVersionQuery,
} from '@dotfile/shared/data-access-client-portal';

/**
 * Query the basic information of a ClientPortalVersion necessary to initialize the app:
 * - clientPortal
 * - settings
 * - translations
 *
 * This query will be loaded on the initialization of the app, loading and error state will be handled
 * there so pages and sub components can assume that the data is already loaded successfully in the
 * Apollo cache
 *
 * @param param0
 * @returns
 */
export const useLatestClientPortalVersion = ({
  skip,
}: Pick<
  QueryHookOptions<LatestClientPortalVersionQuery>,
  'skip' | 'fetchPolicy'
> = {}) => {
  const { data, loading, error } = useLatestClientPortalVersionQuery({
    skip,
  });

  return { data, loading, error };
};
