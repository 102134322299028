import { FormProvider } from 'react-hook-form';

import { FullContainer } from '../../../shared';
import {
  Blocks,
  mainCompanySelector,
  StepFooter,
  StepHeader,
  StepProps,
  useFieldsForm,
  useFormDatastore,
  useHandleFieldsForm,
  useHandleSaveForLater,
} from '../shared';

export const CompanyEditStep = ({ step, onSubmitStep }: StepProps) => {
  const caseData = useFormDatastore((state) => state.data.case);
  const patchCaseData = useFormDatastore((state) => state.patchCaseData);

  const company = useFormDatastore(mainCompanySelector);
  const patchMainCompany = useFormDatastore(
    (state) => state.patchMainCompanyData,
  );

  const { methods, displayedBlocks } = useFieldsForm({
    step,
    data: {
      case: caseData,
      company,
    },
  });

  const [handleSaveForLater, saveForLaterState] = useHandleSaveForLater();

  const handleSubmit = useHandleFieldsForm({
    methods,
    handleDatastore: ({ data }) => {
      if (data.case) {
        patchCaseData(data.case);
      }
      if (data.company) {
        patchMainCompany(data.company);
      }
    },
    onSubmitStep,
    onSaveForLater: handleSaveForLater,
  });

  return (
    <FullContainer
      footer={
        <StepFooter
          saveForLaterState={saveForLaterState}
          isNextLoading={methods.formState.isSubmitting}
        />
      }
      as="form"
      noValidate
      onSubmit={handleSubmit}
    >
      <FormProvider {...methods}>
        <StepHeader />
        <Blocks blocks={displayedBlocks} />
      </FormProvider>
    </FullContainer>
  );
};
