import {
  DocumentAnalysisModelEnum,
  DocumentAnalysisRegistrationCertificateDocumentTypesEnum,
  DocumentAnalysisRegistrationCertificateParametersModel,
} from '../../../../../../shared/models';
import yup from '../../../../../../utils/yup-extended';

export const documentAnalysisRegistrationCertificateParametersSchema =
  (options?: {
    forceDefined?: boolean;
  }): yup.SchemaOf<DocumentAnalysisRegistrationCertificateParametersModel> => {
    const definedOrOptional = options?.forceDefined ? 'defined' : 'optional';

    return yup
      .object({
        model: yup
          .mixed()
          .is([DocumentAnalysisModelEnum.registration_certificate])
          .required(),
        maxAgeInDays: yup.number().min(0)[definedOrOptional]().nullable(),
        authorizedDocuments: yup
          .array()
          .of(
            yup
              .mixed()
              .oneOf(
                Object.values(
                  DocumentAnalysisRegistrationCertificateDocumentTypesEnum,
                ),
              ),
          )
          .min(1) // At least one item or null
          [definedOrOptional]()
          .nullable(),
      })
      .required()
      .strict()
      .noUnknown();
  };
