import { AnalysisResultCategoryEnum } from '../../../../shared/models';

export const OnfidoControlCategory: Record<string, AnalysisResultCategoryEnum> =
  {
    // Age Validation - not mapped
    document_report__age_validation__minimum_accepted_age:
      AnalysisResultCategoryEnum.other,

    // Compromised Document
    document_report__compromised_document__document_database:
      AnalysisResultCategoryEnum.fraud,
    document_report__compromised_document__repeat_attempts:
      AnalysisResultCategoryEnum.fraud,

    // Data Comparison - not mapped
    // 'document_report__data_comparison__date_of_birth': AnalysisResultCategoryEnum.na,
    // 'document_report__data_comparison__date_of_expiry': AnalysisResultCategoryEnum.na,
    // 'document_report__data_comparison__document_numbers': AnalysisResultCategoryEnum.na,
    // 'document_report__data_comparison__document_type': AnalysisResultCategoryEnum.na,
    // 'document_report__data_comparison__first_name': AnalysisResultCategoryEnum.na,
    // 'document_report__data_comparison__gender': AnalysisResultCategoryEnum.na,
    // 'document_report__data_comparison__issuing_country': AnalysisResultCategoryEnum.na,
    // 'document_report__data_comparison__last_name': AnalysisResultCategoryEnum.na,

    // Data Consistency
    document_report__data_consistency__date_of_birth:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__date_of_expiry:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__document_numbers:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__document_type:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__first_name:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__gender: AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__issuing_country:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__last_name:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__multiple_data_sources_present:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_consistency__nationality:
      AnalysisResultCategoryEnum.fraud,

    // Data Validation
    document_report__data_validation__date_of_birth:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_validation__document_expiration:
      AnalysisResultCategoryEnum.document_expiration,
    document_report__data_validation__document_numbers:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_validation__expiry_date:
      AnalysisResultCategoryEnum.fraud,
    document_report__data_validation__gender: AnalysisResultCategoryEnum.fraud,
    document_report__data_validation__mrz: AnalysisResultCategoryEnum.fraud,
    document_report__data_validation__barcode: AnalysisResultCategoryEnum.fraud,

    // Image Integrity
    document_report__image_integrity__colour_picture:
      AnalysisResultCategoryEnum.image_integrity,
    document_report__image_integrity__conclusive_document_quality:
      AnalysisResultCategoryEnum.image_integrity,
    document_report__image_integrity__image_quality:
      AnalysisResultCategoryEnum.image_integrity,
    document_report__image_integrity__supported_document:
      AnalysisResultCategoryEnum.document_acceptability,

    // Visual Authenticity
    document_report__visual_authenticity__digital_tampering:
      AnalysisResultCategoryEnum.fraud,
    document_report__visual_authenticity__face_detection:
      AnalysisResultCategoryEnum.fraud,
    document_report__visual_authenticity__fonts:
      AnalysisResultCategoryEnum.fraud,
    document_report__visual_authenticity__original_document_present:
      AnalysisResultCategoryEnum.fraud,
    document_report__visual_authenticity__other:
      AnalysisResultCategoryEnum.fraud,
    document_report__visual_authenticity__picture_face_integrity:
      AnalysisResultCategoryEnum.fraud,
    document_report__visual_authenticity__security_features:
      AnalysisResultCategoryEnum.fraud,
    document_report__visual_authenticity__template:
      AnalysisResultCategoryEnum.fraud,

    // Issuing Authority
    document_report__issuing_authority__nfc_active_authentication:
      AnalysisResultCategoryEnum.fraud,
    document_report__issuing_authority__nfc_passive_authentication:
      AnalysisResultCategoryEnum.fraud,

    // Facial Similarity
    facial_similarity_report__face_comparison__face_match:
      AnalysisResultCategoryEnum.fraud,
    facial_similarity_report__image_integrity__face_detected:
      AnalysisResultCategoryEnum.image_integrity,
    facial_similarity_report__image_integrity__source_integrity:
      AnalysisResultCategoryEnum.image_integrity,
    facial_similarity_report_authenticity__liveness_detected:
      AnalysisResultCategoryEnum.fraud,
    facial_similarity_report__visual_authenticity__spoofing_detection:
      AnalysisResultCategoryEnum.fraud,

    // Device Intelligence
    device_intelligence_report__device__device_integrity:
      AnalysisResultCategoryEnum.fraud,
    device_intelligence_report__device__device_reputation:
      AnalysisResultCategoryEnum.fraud,
    device_intelligence_report__device__application_authenticity:
      AnalysisResultCategoryEnum.fraud,
  };

export const OnfidoControlDescriptions: Record<string, string> = {
  // Age Validation
  document_report__age_validation__minimum_accepted_age:
    'Checks if the age from the document is greater than or equal to the minimum accepted age.',

  // Compromised Document
  document_report__compromised_document__document_database:
    'Asserts whether the document is publicly known as compromised.',
  document_report__compromised_document__repeat_attempts:
    'Checks if the document has been reused suspiciously.',

  // Data Comparison
  document_report__data_comparison__date_of_birth:
    "Compares the applicant's provided date of birth with the document's extracted date of birth.",
  document_report__data_comparison__date_of_expiry:
    "Compares the expiry date provided by the applicant with the document's extracted expiry date.",
  document_report__data_comparison__document_numbers:
    'Compares document numbers provided with those extracted from the document.',
  document_report__data_comparison__document_type:
    'Compares the document type provided with the extracted type.',
  document_report__data_comparison__first_name:
    "Matches the first name provided by the applicant with the document's extracted first name.",
  document_report__data_comparison__gender:
    "Matches the gender provided by the applicant with the document's extracted gender (note: often returns null).",
  document_report__data_comparison__issuing_country:
    'Checks if the issuing country provided matches the extracted country from the document.',
  document_report__data_comparison__last_name:
    "Matches the last name provided by the applicant with the document's extracted last name.",

  // Data Consistency
  document_report__data_consistency__date_of_birth:
    'Ensures the date of birth is consistent across multiple data sources within the document.',
  document_report__data_consistency__date_of_expiry:
    'Ensures the expiry date is consistent across document data sources.',
  document_report__data_consistency__document_numbers:
    'Validates that document numbers are consistent across available data sources.',
  document_report__data_consistency__document_type:
    'Ensures consistency of document type across data sources.',
  document_report__data_consistency__first_name:
    'Checks for consistency of the first name across document data sources.',
  document_report__data_consistency__gender:
    "Verifies gender consistency within the document's data.",
  document_report__data_consistency__issuing_country:
    "Ensures the issuing country is consistent across the document's data.",
  document_report__data_consistency__last_name:
    'Verifies last name consistency across data sources in the document.',
  document_report__data_consistency__multiple_data_sources_present:
    'Validates that multiple data sources are present and consistent within the document.',
  document_report__data_consistency__nationality:
    "Ensures the nationality is consistent across the document's data.",

  // Data Validation
  document_report__data_validation__date_of_birth:
    'Validates the date of birth format and correctness for the document type.',
  document_report__data_validation__document_expiration:
    'Checks if the document has expired based on extracted expiration date and format.',
  document_report__data_validation__document_numbers:
    'Ensures document numbers are formatted correctly for the document type.',
  document_report__data_validation__expiry_date:
    'Checks the expiry date format and correctness.',
  document_report__data_validation__gender:
    'Verifies the gender field is formatted correctly for the document.',
  document_report__data_validation__mrz:
    "Validates the machine-readable zone's format and data integrity.",
  document_report__data_validation__barcode:
    'Ensures the barcode data is correctly formatted and extracted.',

  // Image Integrity
  document_report__image_integrity__colour_picture:
    'Checks if the document image is in color (configurable to map to caution if black and white).',
  document_report__image_integrity__conclusive_document_quality:
    'Assesses if the document image quality is sufficient for fraud inspection.',
  document_report__image_integrity__image_quality:
    'Asserts that the image quality is suitable for processing.',
  document_report__image_integrity__supported_document:
    'Checks if the submitted document is a supported type.',

  // Visual Authenticity
  document_report__visual_authenticity__digital_tampering:
    'Detects indications of digital tampering in the document image.',
  document_report__visual_authenticity__face_detection:
    'Ensures a face is detected in the document photo.',
  document_report__visual_authenticity__fonts:
    'Verifies that the fonts used in the document match expected fonts for authenticity.',
  document_report__visual_authenticity__original_document_present:
    'Checks if the original document was present when the photo was taken (e.g., not a photo of a screen).',
  document_report__visual_authenticity__other:
    'Used for backward compatibility; shows clear if all other checks are clear, otherwise consider.',
  document_report__visual_authenticity__picture_face_integrity:
    'Verifies integrity of the face picture on the document for signs of tampering.',
  document_report__visual_authenticity__security_features:
    'Checks for the presence and correctness of expected security features on the document.',
  document_report__visual_authenticity__template:
    'Ensures the document matches the expected template for its type and issuing country.',

  // Issuing Authority
  document_report__issuing_authority__nfc_active_authentication:
    "Checks if the document's NFC chip is original or cloned.",
  document_report__issuing_authority__nfc_passive_authentication:
    'Verifies if the NFC chip data has been tampered with.',

  // Facial Similarity
  facial_similarity_report__face_comparison__face_match:
    'Check how similar the face on the document is to the face in the motion capture',
  facial_similarity_report__image_integrity__face_detected:
    'Ensures a face of good enough quality is found in both the document image and the motion capture.',
  facial_similarity_report__image_integrity__source_integrity:
    'Confirms the motion capture is trustworthy and not from a fake source (e.g., fake webcam).',
  facial_similarity_report__visual_authenticity__liveness_detected:
    'Verifies that the head movements were correctly executed to confirm the liveness of the subject.',
  facial_similarity_report__visual_authenticity__spoofing_detection:
    'Checks for indications of spoofing (like videos of screens or masks)',

  // Device Intelligence
  // device_intelligence_report__device__device_integrity: '',
  // device_intelligence_report__device__device_reputation: '',
  // device_intelligence_report__device__application_authenticity: '',
};
