import { TFunction } from 'i18next';
import { match, P } from 'ts-pattern';

import {
  ClientPortalBlockFieldMapping,
  PropertyTypeEnum,
} from '@dotfile/shared/data-access-client-portal';
import {
  clientPortalIndividualPropertiesDefinition,
  yup,
} from '@dotfile/shared/domain';

import { ClientPortalProperty, isPropertyType } from '../is-property-type';
import { LocalizedValidation } from './localize-validation';
import {
  bankingInformationValidationSchema,
  birthdateValidationSchema,
  booleanValidationSchema,
  choicesValidationSchema,
  classificationsValidationSchema,
  countriesValidationSchema,
  dateValidationSchema,
  emailValidationSchema,
  entityLegalFormValidationSchema,
  numericValidationSchema,
  phoneNumberValidationSchema,
  textValidationSchema,
  urlValidationSchema,
} from './property';
import { addressValidationSchema } from './property/address';

export type GeneratePropertyValidationSchemaParam = {
  isRequired: boolean;
  property: ClientPortalProperty<PropertyTypeEnum>;
  mapping: Pick<ClientPortalBlockFieldMapping, 'type'>;
  localizedValidation: LocalizedValidation;
  t: TFunction;
};

export const generatePropertyValidationSchema = (
  param: GeneratePropertyValidationSchemaParam,
): yup.AnySchema => {
  const schema = match(param.property)
    .with(P.when(isPropertyType(PropertyTypeEnum.address)), (property) =>
      addressValidationSchema({ ...param, property }),
    )

    .with(
      P.when(isPropertyType(PropertyTypeEnum.banking_information)),
      (property) => bankingInformationValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.boolean)), (property) =>
      booleanValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.choices)), (property) =>
      choicesValidationSchema({ ...param, property }),
    )

    .with(
      P.when(isPropertyType(PropertyTypeEnum.classifications)),
      (property) => classificationsValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.countries)), (property) =>
      countriesValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.date)), (property) => {
      return match(param.mapping)
        .with(
          clientPortalIndividualPropertiesDefinition.birthDate.mapping,
          () => birthdateValidationSchema({ ...param, property }),
        )
        .otherwise(() => dateValidationSchema({ ...param, property }));
    })

    .with(P.when(isPropertyType(PropertyTypeEnum.date)), (property) =>
      dateValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.email)), (property) =>
      emailValidationSchema({ ...param, property }),
    )

    .with(
      P.when(isPropertyType(PropertyTypeEnum.entity_legal_form)),
      (property) => entityLegalFormValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.numeric)), (property) =>
      numericValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.phone_number)), (property) =>
      phoneNumberValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.text)), (property) =>
      textValidationSchema({ ...param, property }),
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.url)), (property) =>
      urlValidationSchema({ ...param, property }),
    )

    .otherwise(() => {
      throw new Error(
        `Validation for property type ${param.property.type} not implemented`,
      );
    });

  return schema;
};
