import { Trans, TransProps } from 'react-i18next';

import { forwardRef } from '@chakra-ui/react';

import { Text, TextProps } from './text';

type I18nTextProps = TextProps &
  Pick<TransProps<string>, 'i18nKey' | 'defaults'>;

/**
 * I18nText is build thanks the `<Text/>` component from chakra and `<Trans/>` from react-i18n
 * It provides and ensures that all texts used in the application have the same styles.
 *
 * The main important props to know is `i18nKey`, then we can override all props as with `<Text />` thanks this the [forwardRef](https://chakra-ui.com/docs/components/recipes/as-prop#option-1-using-forwardref-from-chakra-uireact).
 *
 * Currently we have two available tags : `bold` and `medium`, but you can easily add some if you need to manage italic text for example.
 *
 * See this [link](https://react.i18next.com/latest/trans-component#alternative-usage-which-lists-the-components-v11.6.0) for more details about `<Trans/>`.
 *
 */

export const I18nText = forwardRef<I18nTextProps, typeof Text>(
  ({ i18nKey, defaults, ...props }, ref): JSX.Element => (
    <Text ref={ref} {...props}>
      <Trans
        i18nKey={i18nKey}
        defaults={defaults}
        components={{
          bold: <Text variant="inlineBold" />,
          medium: <Text variant="inlineMedium" />,
        }}
        ns="design-system"
      />
    </Text>
  ),
);
