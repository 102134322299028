import { CountryCoverage } from '../country-coverage.interface';

// Price for company profile
export interface KyckrCountryCoverage extends CountryCoverage {
  /**
   * Billing price tier for consumption
   */
  priceTier?:
    | 'tier_1' // A
    | 'tier_2' // B
    | 'tier_3' // C
    | 'tier_4' // D
    | 'tier_8'; // F
  // More tier could be added here
}

// @NOTE source from https://www.kyckr.com/legal/enhanced-profiles-documents-country-coverage
// Use a little script to convert jurisdiction names into country code (exclude no match)
export const KYCKR_COUNTRIES: KyckrCountryCoverage[] = [
  {
    code: 'AE', // 'United Arab Emirates'
    priceTier: 'tier_1',
  },
  {
    code: 'AF', // 'Afghanistan',
  },
  {
    code: 'AL', // 'Albania'
  },
  {
    code: 'AR', // 'Argentina'
  },
  {
    code: 'AM', // 'Armenia'
  },
  {
    code: 'AW', // 'Aruba'
  },
  {
    code: 'AU', // 'Australia'
    priceTier: 'tier_4',
  },
  {
    code: 'AT', // 'Austria',
    priceTier: 'tier_1',
  },
  {
    code: 'AZ', // 'Azerbaijan'
  },
  {
    code: 'BH', // 'Bahrain'
  },
  {
    code: 'BD', // 'Bangladesh'
  },
  {
    code: 'BB', // 'Barbados'
  },
  {
    code: 'BY', // 'Belarus'
  },
  {
    code: 'BE', // 'Belgium'
    priceTier: 'tier_1',
  },
  {
    code: 'BZ', // 'Belize'
  },
  {
    code: 'BM', // 'Bermuda'
  },
  {
    code: 'BA', // 'Bosnia and Herzegovina'
  },
  {
    code: 'BW', // 'Botswana'
  },
  {
    code: 'BR', // 'Brazil'
    priceTier: 'tier_1',
  },
  {
    code: 'BG', // 'Bulgaria'
  },
  {
    code: 'KH', // 'Cambodia'
  },
  {
    code: 'CM', // 'Cameroon'
  },
  {
    code: 'KY', // 'Cayman Islands'
  },
  {
    code: 'CL', // 'Chile'
  },
  {
    code: 'CN', // 'China'
    priceTier: 'tier_1',
  },
  {
    code: 'CO', // 'Colombia'
  },
  {
    code: 'CR', // 'Costa Rica'
  },
  {
    code: 'HR', // 'Croatia'
  },
  {
    code: 'CY', // 'Cyprus'
  },
  {
    code: 'CZ', // 'Czech Republic'
  },
  {
    code: 'DK', // 'Denmark'
    priceTier: 'tier_1',
  },
  {
    code: 'DO', // 'Dominican Republic'
  },
  {
    code: 'EC', // 'Ecuador'
  },
  {
    code: 'EG', // 'Egypt'
  },
  {
    code: 'EE', // 'Estonia'
    priceTier: 'tier_4',
  },
  {
    code: 'FI', // 'Finland'
    priceTier: 'tier_4',
  },
  {
    code: 'FR', // 'France'
    priceTier: 'tier_2',
  },
  {
    code: 'GE', // 'Georgia'
  },
  {
    code: 'DE', // 'Germany'
    // @TODO - E-3895 - Remove comment to allow DE for kyckr
    priceTier: 'tier_3',
  },
  {
    code: 'GB', // 'United Kingdom'
    priceTier: 'tier_1',
  },
  {
    code: 'GH', // 'Ghana'
  },
  {
    code: 'GG', // 'Guernsey'
    priceTier: 'tier_1',
  },
  {
    code: 'HN', // 'Honduras'
  },
  {
    code: 'HK', // 'Hong Kong'
  },
  {
    code: 'HU', // 'Hungary'
  },
  {
    code: 'IS', // 'Iceland'
  },
  {
    code: 'IN', // 'India'
    priceTier: 'tier_3',
  },
  {
    code: 'ID', // 'Indonesia'
  },
  {
    code: 'IE', // 'Ireland'
    priceTier: 'tier_1',
  },
  {
    code: 'IM', // 'Isle Of Man'
  },
  {
    code: 'IL', // 'Israel'
  },
  {
    code: 'IT', // 'Italy'
    priceTier: 'tier_3',
  },
  {
    code: 'JM', // 'Jamaica'
  },
  {
    code: 'JE', // 'Jersey'
    priceTier: 'tier_1',
  },
  {
    code: 'JO', // 'Jordan'
  },
  {
    code: 'KW', // 'Kuwait'
  },
  {
    code: 'LV', // 'Latvia'
    priceTier: 'tier_3',
  },
  {
    code: 'LS', // 'Lesotho'
  },
  {
    code: 'LI', // 'Liechtenstein'
  },
  {
    code: 'LT', // 'Lithuania'
    priceTier: 'tier_1',
  },
  {
    code: 'LU', // 'Luxembourg'
    priceTier: 'tier_8',
  },
  {
    code: 'MY', // 'Malaysia',
    priceTier: 'tier_3',
  },
  {
    code: 'MH', // 'Marshall Islands'
  },
  {
    code: 'MU', // 'Mauritius'
  },
  {
    code: 'MD', // 'Moldova'
  },
  {
    code: 'MC', // 'Monaco'
  },
  {
    code: 'ME', // 'Montenegro'
  },
  {
    code: 'MA', // 'Morocco'
  },
  {
    code: 'MM', // 'Myanmar'
  },
  {
    code: 'NP', // 'Nepal'
  },
  {
    code: 'NL', // 'Netherlands'
    priceTier: 'tier_2',
  },
  {
    code: 'NZ', // 'New Zealand'
    priceTier: 'tier_1',
  },
  {
    code: 'NI', // 'Nicaragua'
  },
  {
    code: 'PK', // 'Pakistan'
  },
  {
    code: 'PA', // 'Panama'
  },
  {
    code: 'PE', // 'Peru'
  },
  {
    code: 'PH', // 'Philippines'
  },
  {
    code: 'PL', // 'Poland'
  },
  {
    code: 'PT', // 'Portugal'
  },
  {
    code: 'PR', // 'Puerto Rico'
  },
  {
    code: 'QA', // 'Qatar'
  },
  {
    code: 'RO', // 'Romania'
  },
  {
    code: 'WS', // 'Samoa'
  },
  {
    code: 'RS', // 'Serbia'
  },
  {
    code: 'SG', // 'Singapore'
    priceTier: 'tier_2',
  },
  {
    code: 'SK', // 'Slovakia'
  },
  {
    code: 'SI', // 'Slovenia'
    priceTier: 'tier_4',
  },
  {
    code: 'SB', // 'Solomon Islands'
  },
  {
    code: 'ZA', // 'South Africa'
    priceTier: 'tier_2',
  },
  {
    code: 'ES', // 'Spain'
    priceTier: 'tier_1',
  },
  {
    code: 'LK', // 'Sri Lanka'
  },
  {
    code: 'SE', // 'Sweden'
    priceTier: 'tier_1',
  },
  {
    code: 'CH', // 'Switzerland'
  },
  {
    code: 'TW', // 'Taiwan'
    priceTier: 'tier_1',
  },
  {
    code: 'TH', // 'Thailand'
  },
  {
    code: 'TG', // 'Togo'
  },
  {
    code: 'TO', // 'Tonga'
  },
  {
    code: 'TT', // 'Trinidad And Tobago'
  },
  {
    code: 'TN', // 'Tunisia'
  },
  {
    code: 'TR', // 'Turkey'
  },
  {
    code: 'UG', // 'Uganda'
  },
  {
    code: 'UY', // 'Uruguay'
  },
  {
    code: 'VU', // 'Vanuatu'
  },
  {
    code: 'VE', // 'Venezuela'
  },
  // Unsupported must have US-{stateCode} for all jurisdictions
  // {
  //   code: 'US',
  //   // 'United States',
  // },
  // Unsupported must have CA-{stateCode} for all jurisdictions
  // {
  //   code: 'CA',
  //   // 'Canada',
  // },
];
