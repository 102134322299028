import { individualDefaultPropertiesDefinition } from '../../../shared/properties/individual-default-properties.definition';
import { createClientPortalPropertiesDefinition } from './helpers';

const CLIENT_PORTAL_INDIVIDUAL_PROPERTIES = [
  'isBeneficialOwner',
  'roles',
  'firstName',
  'middleName',
  'lastName',
  'maidenName',
  'email',
  'birthDate',
  'birthPlace',
  'birthCountry',
  'bankingInformation',
  'address',
  'address.city',
  'address.country',
  'address.postalCode',
  'address.region',
  'address.state',
  'address.streetAddress',
  'address.streetAddress2',
  'taxIdentificationNumber',
  'socialSecurityNumber',
  'phoneNumber',
  'position',
  'ownershipPercentage',
  'votingRightsPercentage',
] as const;

export const clientPortalIndividualPropertiesDefinition =
  createClientPortalPropertiesDefinition(
    individualDefaultPropertiesDefinition,
    CLIENT_PORTAL_INDIVIDUAL_PROPERTIES,
  );

export const allClientPortalIndividualPropertiesDefinition = Object.values(
  clientPortalIndividualPropertiesDefinition,
);
