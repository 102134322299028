import {
  DeepPartial,
  extendTheme as extendThemeChakra,
} from '@chakra-ui/react';
import { ChakraTheme } from '@chakra-ui/theme';

// DATA DISPLAY
import { BadgeTheme } from '../data-display/badge/badge.theme';
import { PillTheme } from '../data-display/pill';
import { TableTheme } from '../data-display/table';
import { AccordionTheme } from '../disclosure/accordion/accordion.theme';
// DISCLOSURE
import { TabsTheme } from '../disclosure/tabs/tabs.theme';
// FEEDBACK
import { AlertTheme } from '../feedback/alert/alert.theme';
// SKELETON
import { SkeletonTheme } from '../feedback/skeleton/skeleton.theme';
// COMPONENTS THEME
// FORM
import { ButtonTheme } from '../form/button/button.theme';
import { CheckboxTheme } from '../form/checkbox/checkbox.theme';
import { DropzoneTheme } from '../form/dropzone/dropzone.theme';
import { FormTheme } from '../form/form-control/form.theme';
// ICON
import { IconTheme } from '../form/icon/icon.theme';
import { InputTheme } from '../form/input/input.theme';
import { NumberInputTheme } from '../form/number-input/number-input.theme';
import { RadioButtonTheme } from '../form/radio-button/radio-button.theme';
import { RadioTheme } from '../form/radio/radio.theme';
import { sliderTheme } from '../form/slider/slider.theme';
import { TextareaTheme } from '../form/textarea/textarea.theme';
import { ToggleTheme } from '../form/toggle/toggle.theme';
// MEDIA & ICON
import { AvatarTheme } from '../media-icon/avatar/avatar.theme';
// NAVIGATION
import { BreadcrumbTheme } from '../navigation/breadcrumb/breadcrumb.theme';
import { LinkTheme } from '../navigation/link/link.theme';
import { DrawerTheme } from '../overlay/drawer/drawer.theme';
// OVERLAY
import { MenuTheme } from '../overlay/menu/menu.theme';
import { ModalTheme } from '../overlay/modal/modal.theme';
import { PopoverTheme } from '../overlay/popover/popover.theme';
import { TooltipTheme } from '../overlay/tooltip/tooltip.theme';
import { colors } from './colors';
import { textStyles } from './text-styles';

const extensions: DeepPartial<ChakraTheme> = {
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  },
  breakpoints: {
    // default breakpoint from chakra
    sm: '30em', // 480px
    md: '48em', // 768px
    lg: '62em', // 992px
    xl: '80em', // 1280px
    '2xl': '96em', // 1536px
    // custom
    '3xl': '125em', // 2000px
  },
  fontSizes: {
    // same as the Chakra default theme but shit by one
    // in order to have a md font size of 14px with a
    // base font/grid size of 16px
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '1.875rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
    '7xl': '3.75rem',
    '8xl': '4.5rem',
    '9xl': '6rem',
  },
  colors,
  radii: {
    base: '0.313rem', // 5px with a font-size of 16px
  },
  textStyles,
  layerStyles: {
    // Perhaps we will handle Shadow differently (with size name sx, sm, md, ...) for later
    shadowLight: {
      boxShadow: '0px 7.37098px 18.9859px rgba(13, 21, 26, 0.0298054)',
    },
    shadowMedium: {
      boxShadow:
        '0px 33px 134px rgba(13, 21, 26, 0.05), 0px 7.37098px 29.9307px rgba(13, 21, 26, 0.0298054), 0px 2.19453px 8.91114px rgba(13, 21, 26, 0.0201946)',
    },
  },
  components: {
    Heading: {
      variants: {
        h1: textStyles.h1,
        h2: textStyles.h2,
        h3: textStyles.h3,
        h4: textStyles.h4,
        h5: textStyles.h5,
        h6: textStyles.h6,
      },
    },
    Text: {
      defaultProps: {
        variant: 'main',
        fontSize: 'md',
      },
      variants: {
        main: textStyles.main,
        sub: textStyles.sub,
        tiny: textStyles.tiny,
        uppercase: textStyles.uppercase,
        inlineBold: textStyles.inlineBold,
        inlineMedium: textStyles.inlineMedium,
      },
    },
    Modal: ModalTheme,
    Alert: AlertTheme,
    Avatar: AvatarTheme,
    Badge: BadgeTheme,
    Button: ButtonTheme,
    Icon: IconTheme,
    Checkbox: CheckboxTheme,
    Radio: RadioTheme,
    Form: FormTheme,
    Menu: MenuTheme,
    Switch: ToggleTheme,
    Input: InputTheme,
    Textarea: TextareaTheme,
    Slider: sliderTheme,
    Tooltip: TooltipTheme,
    Tabs: TabsTheme,
    Dropzone: DropzoneTheme,
    Popover: PopoverTheme,
    Drawer: DrawerTheme,
    Accordion: AccordionTheme,
    Link: LinkTheme,
    Breadcrumb: BreadcrumbTheme,
    Table: TableTheme,
    Pill: PillTheme,
    Skeleton: SkeletonTheme,
    RadioButton: RadioButtonTheme,
    NumberInput: NumberInputTheme,
  },
  styles: {
    global: {
      // this allow to customize the positioning of the toasts (40px from the bottom right corner)
      '#chakra-toast-manager-bottom-right': {
        // toast have a hard-coded margin of 0.5 rem
        // see https://github.com/chakra-ui/chakra-ui/blob/f14966a6de6d365daf54806a10776bd510168e9e/packages/toast/src/toast.tsx#L123
        paddingRight: 'calc(var(--chakra-space-10) - 0.5rem)',
        paddingBottom: 'calc(var(--chakra-space-10) - 0.5rem)',
      },
    },
  },
};

export type Theme = ChakraTheme;
export const extendTheme = extendThemeChakra;
export const baseTheme = extendThemeChakra(extensions);

export default baseTheme;
