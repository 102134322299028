import { DeepPartial } from 'ts-essentials';

import {
  DocumentTypeEnum,
  WorkspaceSettingsDocumentModel,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import {
  documentAnalysisIBANParametersSchema,
  documentAnalysisProofOfAddressParametersSchema,
  documentAnalysisRegistrationCertificateParametersSchema,
} from '../../../checks/document/document-analysis';

export const workspaceDocumentSettingsDocumentAnalysisSchema = (): yup.SchemaOf<
  WorkspaceSettingsDocumentModel['documentAnalysis']
> =>
  yup
    .object()
    .required()
    .shape({
      [DocumentTypeEnum.proof_of_address]: yup
        .object()
        .optional()
        .shape({
          enabled: yup.boolean().defined(),
          automaticApproval: yup.boolean().defined(),
          automaticRejection: yup.boolean().defined(),
          parameters: documentAnalysisProofOfAddressParametersSchema({
            forceDefined: true,
          }).defined(),
        })
        .default(undefined),
      [DocumentTypeEnum.registration_certificate]: yup
        .object()
        .optional()
        .shape({
          enabled: yup.boolean().defined(),
          automaticApproval: yup.boolean().defined(),
          automaticRejection: yup.boolean().defined(),
          parameters: documentAnalysisRegistrationCertificateParametersSchema({
            forceDefined: true,
          }).defined(),
        })
        .default(undefined),
      [DocumentTypeEnum.bank_details]: yup
        .object()
        .optional()
        .shape({
          enabled: yup.boolean().defined(),
          automaticApproval: yup.boolean().defined(),
          automaticRejection: yup.boolean().defined(),
          parameters: documentAnalysisIBANParametersSchema({
            forceDefined: true,
          }).defined(),
        })
        .default(undefined),
    })
    .strict();

export const validateWorkspaceSettingsDocumentAnalysis = (
  documentAnalysis: DeepPartial<
    WorkspaceSettingsDocumentModel['documentAnalysis']
  > = {},
): void => {
  workspaceDocumentSettingsDocumentAnalysisSchema().validateSync(
    documentAnalysis,
    {
      abortEarly: false,
    },
  );
};
