import { useCallback } from 'react';

import { useLatestClientPortalVersion } from '../../../../shared';
import { useFormDatastoreApi } from '../context';
import {
  buildGlobalDataContext,
  LogicDataContext,
} from './data-context-builder';

export const useGlobalDataContext = (): (() => LogicDataContext['global']) => {
  const { data } = useLatestClientPortalVersion();
  const storeApi = useFormDatastoreApi();

  const getGlobalDataContext = useCallback(() => {
    if (data) {
      return buildGlobalDataContext({
        datastoreData:
          // Use the store api getState() to always have the latest store data
          storeApi.getState().data,
        clientPortalType: data?.latestClientPortalVersion.clientPortal.type,
      });
    } else {
      return {};
    }
  }, [data, storeApi]);

  return getGlobalDataContext;
};
