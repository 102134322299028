import {
  Skeleton as ChakraSkeleton,
  SkeletonCircle as ChakraSkeletonCircle,
  SkeletonProps as ChakraSkeletonProps,
  SkeletonText as ChakraSkeletonText,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/feedback/skeleton
export type SkeletonProps = ChakraSkeletonProps;
export const Skeleton = ChakraSkeleton;

export const SkeletonCircle = ChakraSkeletonCircle;
export const SkeletonText = ChakraSkeletonText;
