import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@dotfile/frontend/shared/common';

import { ContactAuthContext } from '..';
import { useRouter } from '../../routes';

type UseRedeemTokenParam = {
  magicToken: string | undefined;
};

type RedeemTokenState =
  | { status: 'loading' }
  | { status: 'error'; message: string }
  | { status: 'no-token' }
  | { status: 'expired-token' }
  | { status: 'redeemed' };

export const useRedeemToken = ({ magicToken }: UseRedeemTokenParam) => {
  const router = useRouter();
  const { redeemMagicToken } = useAuth<ContactAuthContext>();
  const [state, setState] = useState<RedeemTokenState>({ status: 'loading' });
  const { t } = useTranslation();

  useEffect(() => {
    if (!magicToken) setState({ status: 'no-token' });
  }, [magicToken]);

  // This ref deduplicate the useEffect call made twice by React in dev/StrictMode
  const isRedeeming = useRef(false);
  useEffect(() => {
    if (isRedeeming.current) {
      return;
    }
    isRedeeming.current = true;

    if (magicToken) {
      redeemMagicToken(magicToken)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(() => {
          // auth state will also be updated accordingly
          setState({ status: 'redeemed' });
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((error) => {
          if (
            error instanceof Error &&
            error.cause instanceof Response &&
            error.cause.status === 403
          ) {
            setState({ status: 'expired-token' });
            return;
          }

          setState({
            status: 'error',
            message: t('login.errors.other', {
              defaultValue:
                'Unable to login with this link, please request a new one below and if the problem persists contact support.',
              ns: 'client-portal',
            }),
          });
        });
    } else {
      setState({ status: 'no-token' });
    }
  }, [magicToken, redeemMagicToken, router, t]);

  return state;
};
