import { integrationsPlatformEventDefinition } from './integrations-platform-event.definition';

type GetArrayValues<T> = T extends ReadonlyArray<infer U> ? U : never;
export type IntegrationsPlatformEventName = {
  [K in keyof typeof integrationsPlatformEventDefinition]: (typeof integrationsPlatformEventDefinition)[K] extends true
    ? K
    : GetArrayValues<(typeof integrationsPlatformEventDefinition)[K]>;
}[keyof typeof integrationsPlatformEventDefinition];

export type IntegrationsPlatformSubEventName =
  keyof typeof integrationsPlatformEventDefinition;

const INTEGRATIONS_PLATFORM_EVENTS = Object.keys(
  integrationsPlatformEventDefinition,
) as IntegrationsPlatformEventName[];

export const isIntegrationsPlatformSubEvent = (
  name: string,
): name is IntegrationsPlatformSubEventName =>
  (INTEGRATIONS_PLATFORM_EVENTS as string[]).includes(name);
