import { match } from 'ts-pattern';

import {
  PropertyMappingEntityEnum,
  PropertyMappingTypeEnum,
} from '../../shared/models';
import {
  Property,
  PropertyMapping,
  PropertyTypeEnum,
} from '../../shared/properties/types';
import { companyDefaultPropertiesDefinition } from './company-default-properties.definition';
import { individualDefaultPropertiesDefinition } from './individual-default-properties.definition';

export const resolveDefaultPropertyFromMapping = ({
  entity,
  type,
  key,
}: PropertyMapping): {
  propertyName: string;
  property: Property<PropertyTypeEnum>;
} => {
  if (type !== PropertyMappingTypeEnum.default) {
    throw new Error(
      `Cannot resolve default property for mapping of type ${type}`,
    );
  }

  const propertyDefinition = match(entity)
    .with(
      PropertyMappingEntityEnum.company,
      () => companyDefaultPropertiesDefinition,
    )
    .with(
      PropertyMappingEntityEnum.individual,
      () => individualDefaultPropertiesDefinition,
    )
    .otherwise(() => {
      throw new Error(`No default property definition for entity ${entity}`);
    });

  const entry = Object.entries(propertyDefinition).find(
    ([, definition]) => definition.mapping.key === key,
  );
  if (!entry) {
    throw new Error(`No default property for entity ${entity} and key ${key}`);
  }

  return { propertyName: entry[0], property: entry[1] };
};
