import {
  CompanyClassificationModel,
  CompanyClassificationTypeEnum,
} from '../../models';

// @doc https://resources.companieshouse.gov.uk/sic/
// @doc https://github.com/companieshouse/api-enumerations/blob/master/constants.yml

const sicClassifications: CompanyClassificationModel[] = [
  {
    code: '1010',
    description: 'Mining & agglomeration of hard coal',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1020',
    description: 'Mining and agglomeration of lignite',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1030',
    description: 'Extraction and agglomeration of peat',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1110',
    description: 'Extraction petroleum & natural gas',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1120',
    description: 'Services to oil and gas extraction',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1200',
    description: 'Mining of uranium & thorium ores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1310',
    description: 'Mining of iron ores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1320',
    description: 'Mining non-ferrous metal ores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1411',
    description: 'Quarrying of stone for construction',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1412',
    description: 'Quarry of limestone, gypsum & chalk',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1413',
    description: 'Quarrying of slate',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1421',
    description: 'Operation of gravel and sand pits',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1422',
    description: 'Mining of clays and kaolin',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1430',
    description: 'Mine chemical & fertiliser minerals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1440',
    description: 'Production of salt',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1450',
    description: 'Other mining and quarrying',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1511',
    description: 'Production and preserving of meat',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1512',
    description: 'Production & preserve poultry meat',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1513',
    description: 'Production meat & poultry products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1520',
    description: 'Process & preserve fish & products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1531',
    description: 'Processing & preserve potatoes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1532',
    description: 'Manufacture of fruit & vegetable juice',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1533',
    description: 'Process etc. fruit, vegetables',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1541',
    description: 'Manufacture of crude oils and fats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1542',
    description: 'Manufacture of refined oils & fats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1543',
    description: 'Manufacture margarine & similar edible fats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1551',
    description: 'Operation dairies & cheese making',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1552',
    description: 'Manufacture of ice cream',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1561',
    description: 'Manufacture of grain mill products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1562',
    description: 'Manufacture of starches & starch products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1571',
    description: 'Manufacture of prepared farm animal feeds',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1572',
    description: 'Manufacture of prepared pet foods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1581',
    description: 'Manufacture of bread, fresh pastry & cakes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1582',
    description: 'Manufacture biscuits, preserved pastry etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1583',
    description: 'Manufacture of sugar',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1584',
    description: 'Manufacture cocoa, chocolate, confectionery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1585',
    description: 'Manufacture macaroni & similar farinaceous',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1586',
    description: 'Processing of tea and coffee',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1587',
    description: 'Manufacture of condiments & seasonings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1588',
    description: 'Manufacture of homogenised & dietetic food',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1589',
    description: 'Manufacture of other food products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1591',
    description: 'Manufacture distilled potable alcoholic drinks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1592',
    description: 'Ethyl alcohol fermented materials',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1593',
    description: 'Manufacture of wines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1594',
    description: 'Manufacture of cider & other fruit wines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1595',
    description: 'Manufacture other non-distilled fermented drinks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1596',
    description: 'Manufacture of beer',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1597',
    description: 'Manufacture of malt',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1598',
    description: 'Produce mineral water, soft drinks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1600',
    description: 'Manufacture of tobacco products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1711',
    description: 'Prepare & spin cotton-type fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1712',
    description: 'Prepare & spin woollen-type fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1713',
    description: 'Prepare & spin worsted-type fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1714',
    description: 'Preparation & spin flax-type fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1715',
    description: 'Throw & prepare silk, synthetic etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1716',
    description: 'Manufacture of sewing threads',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1717',
    description: 'Preparation & spin of other textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1721',
    description: 'Cotton-type weaving',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1722',
    description: 'Woollen-type weaving',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1723',
    description: 'Worsted-type weaving',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1724',
    description: 'Silk-type weaving',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1725',
    description: 'Other textile weaving',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1730',
    description: 'Finishing of textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1740',
    description: 'Manufacture made-up textiles, not apparel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1751',
    description: 'Manufacture of carpet and rugs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1752',
    description: 'Manufacture cordage, rope, twine & netting',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1753',
    description: 'Manufacture nonwovens & goods, not apparel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1754',
    description: 'Manufacture of other textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1760',
    description: 'Manufacture of knitted & crocheted fabrics',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1771',
    description: 'Manufacture of knitted & crocheted hosiery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1772',
    description: 'Manufacture knit & crocheted pullovers, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1810',
    description: 'Manufacture of leather clothes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1821',
    description: 'Manufacture of workwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1822',
    description: 'Manufacture of other outerwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1823',
    description: 'Manufacture of underwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1824',
    description: 'Manufacture other wearing apparel etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1830',
    description: 'Dress & dye fur; manufacture fur articles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1910',
    description: 'Tanning and dressing of leather',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1920',
    description: 'Manufacture of luggage & the like, saddlery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '1930',
    description: 'Manufacture of footwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2010',
    description: 'Sawmill, plane, impregnation wood',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2020',
    description: 'Manufacture of veneer sheets, plywood, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2030',
    description: 'Manufacture builders carpentry & joinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2040',
    description: 'Manufacture of wooden containers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2051',
    description: 'Manufacture of other products of wood',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2052',
    description: 'Manufacture of articles of cork, straw etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2111',
    description: 'Manufacture of pulp',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2112',
    description: 'Manufacture of paper & paperboard',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2121',
    description: 'Manufacture of cartons',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2122',
    description: 'Manufacture of household & toilet goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2123',
    description: 'Manufacture of paper stationery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2124',
    description: 'Manufacture of wallpaper',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2125',
    description: 'Manufacture of paper & paperboard goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2211',
    description: 'Publishing of books',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2212',
    description: 'Publishing of newspapers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2213',
    description: 'Publish journals & periodicals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2214',
    description: 'Publishing of sound recordings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2215',
    description: 'Other publishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2221',
    description: 'Printing of newspapers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2222',
    description: 'Printing not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2223',
    description: 'Bookbinding and finishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2224',
    description: 'Pre-press activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2225',
    description: 'Ancillary printing operations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2231',
    description: 'Reproduction of sound recording',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2232',
    description: 'Reproduction of video recording',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2233',
    description: 'Reproduction of computer media',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2310',
    description: 'Manufacture of coke oven products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2320',
    description: 'Manufacture of refined petroleum products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2330',
    description: 'Processing of nuclear fuel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2411',
    description: 'Manufacture of industrial gases',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2412',
    description: 'Manufacture of dyes and pigments',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2413',
    description: 'Manufacture other inorganic basic chemicals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2414',
    description: 'Manufacture other organic basic chemicals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2415',
    description: 'Manufacture fertilisers, nitrogen compounds',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2416',
    description: 'Manufacture of plastics in primary forms',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2417',
    description: 'Manufacture synthetic rubber primary forms',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2420',
    description: 'Manufacture of pesticides & agro-chemicals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2430',
    description: 'Manufacture of paints, print ink & mastics etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2441',
    description: 'Manufacture of basic pharmaceutical prods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2442',
    description: 'Manufacture of pharmaceutical preparations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2451',
    description: 'Manufacture soap & detergents, polishes etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2452',
    description: 'Manufacture perfumes & toilet preparations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2461',
    description: 'Manufacture of explosives',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2462',
    description: 'Manufacture of glues and gelatines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2463',
    description: 'Manufacture of essential oils',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2464',
    description: 'Manufacture photograph chemical material',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2465',
    description: 'Manufacture of prepared unrecorded media',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2466',
    description: 'Manufacture of other chemical products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2470',
    description: 'Manufacture of man-made fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2511',
    description: 'Manufacture of rubber tyres & tubes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2512',
    description: 'Retread & rebuild rubber tyres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2513',
    description: 'Manufacture of other rubber products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2521',
    description: 'Manufacture of plastic plates, sheets, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2522',
    description: 'Manufacture of plastic pack goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2523',
    description: 'Manufacture of builders ware of plastic',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2524',
    description: 'Manufacture of other plastic products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2611',
    description: 'Manufacture of flat glass',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2612',
    description: 'Shaping & process of flat glass',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2613',
    description: 'Manufacture of hollow glass',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2614',
    description: 'Manufacture of glass fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2615',
    description: 'Manufacture other glass inc. technical',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2621',
    description: 'Manufacture of ceramic household etc. goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2622',
    description: 'Manufacture of ceramic sanitary fixtures',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2623',
    description: 'Manufacture of ceramic insulators etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2624',
    description: 'Manufacture other technical ceramic goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2625',
    description: 'Manufacture of other ceramic products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2626',
    description: 'Manufacture of refractory ceramic products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2630',
    description: 'Manufacture of ceramic tiles & flags',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2640',
    description: 'Manufacture of bricks, etc. in baked clay',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2651',
    description: 'Manufacture of cement',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2652',
    description: 'Manufacture of lime',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2653',
    description: 'Manufacture of plaster',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2661',
    description: 'Manufacture concrete goods for construction',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2662',
    description: 'Manufacture plaster goods for construction',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2663',
    description: 'Manufacture of ready-mixed concrete',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2664',
    description: 'Manufacture of mortars',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2665',
    description: 'Manufacture of fibre cement',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2666',
    description: 'Manufacture other articles of concrete, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2670',
    description: 'Cutting, shaping & finish stone',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2681',
    description: 'Production of abrasive products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2682',
    description: 'Manufacture other non-metal mineral',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2710',
    description: 'Manufacture of basic iron & steel & of Ferro-alloys ',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2721',
    description: 'Manufacture of cast iron tubes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2722',
    description: 'Manufacture of steel tubes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2731',
    description: 'Cold drawing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2732',
    description: 'Cold rolling of narrow strips',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2733',
    description: 'Cold forming or folding',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2734',
    description: 'Wire drawing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2735',
    description: 'Other 1st process iron & steel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2741',
    description: 'Precious metals production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2742',
    description: 'Aluminium production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2743',
    description: 'Lead, zinc and tin production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2744',
    description: 'Copper production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2745',
    description: 'Other non-ferrous metal production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2751',
    description: 'Casting of iron',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2752',
    description: 'Casting of steel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2753',
    description: 'Casting of light metals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2754',
    description: 'Casting of other non-ferrous metals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2811',
    description: 'Manufacture metal structures & parts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2812',
    description: 'Manufacture builders carpentry of metal',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2821',
    description: 'Manufacture tanks, etc. & metal containers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2822',
    description: 'Manufacture central heating rads & boilers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2830',
    description: 'Manufacture steam generators, not boilers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2840',
    description: 'Forge press stamp & roll form metal',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2851',
    description: 'Treatment and coat metals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2852',
    description: 'General mechanical engineering',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2861',
    description: 'Manufacture of cutlery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2862',
    description: 'Manufacture of tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2863',
    description: 'Manufacture of locks and hinges',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2871',
    description: 'Manufacture steel drums, similar containers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2872',
    description: 'Manufacture of light metal packaging',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2873',
    description: 'Manufacture of wire products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2874',
    description: 'Manufacture fasteners, screw, chains etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2875',
    description: 'Manufacture other fabricated metal products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2911',
    description: 'Manufacture engines, not aircraft, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2912',
    description: 'Manufacture of pumps & compressors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2913',
    description: 'Manufacture of taps and valves',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2914',
    description: 'Manufacture bearings, gears, gear etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2921',
    description: 'Manufacture of furnaces & furnace burners',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2922',
    description: 'Manufacture of lift & handling equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2923',
    description: 'Manufacture non-domestic ventilation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2924',
    description: 'Manufacture of other general machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2931',
    description: 'Manufacture of agricultural tractors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2932',
    description: 'Manufacture other agric. & forestry machines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2940',
    description: 'Manufacture of machine tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2941',
    description: 'Manufacture of portable hand held power tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2942',
    description: 'Manufacture of metalworking tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2943',
    description: 'Manufacture of other machine tools not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2951',
    description: 'Manufacture of machinery for metallurgy',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2952',
    description: 'Manufacture machines for mining, quarry etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2953',
    description: 'Manufacture for food, beverage & tobacco',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2954',
    description: 'Manufacture for textile, apparel & leather',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2955',
    description: 'Manufacture machinery for paper & board',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2956',
    description: 'Manufacture other special purpose machine',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2960',
    description: 'Manufacture of weapons & ammunition',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2971',
    description: 'Manufacture of electric domestic appliances',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '2972',
    description: 'Manufacture non-electric domestic appliances',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3001',
    description: 'Manufacture of office machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3002',
    description: 'Manufacture computers & process equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3110',
    description: 'Manufacture electric motors, generators etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3120',
    description: 'Manufacture electricity distribution etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3130',
    description: 'Manufacture of insulated wire & cable',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3140',
    description: 'Manufacture of accumulators, batteries etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3150',
    description: 'Manufacture lighting equipment & lamps',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3161',
    description: 'Manufacture electric equipment, engines etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3162',
    description: 'Manufacture other electrical equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3210',
    description: 'Manufacture of electronic components',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3220',
    description: 'Manufacture TV transmitters, telephony etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3230',
    description: 'Manufacture TV & radio, sound or video etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3310',
    description: 'Manufacture medical, orthopaedic etc. equipment ',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3320',
    description: 'Manufacture instruments for measuring etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3330',
    description: 'Manufacture indust process control equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3340',
    description: 'Manufacture optical, photographic etc. equipment ',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3350',
    description: 'Manufacture of watches and clocks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3410',
    description: 'Manufacture of motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3420',
    description: 'Manufacture motor vehicle bodies etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3430',
    description: 'Manufacture motor vehicle & engine parts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3511',
    description: 'Building and repairing of ships',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3512',
    description: 'Build & repair pleasure & sport boats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3520',
    description: 'Manufacture of railway locomotives & stock',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3530',
    description: 'Manufacture of aircraft & spacecraft',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3541',
    description: 'Manufacture of motorcycles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3542',
    description: 'Manufacture of bicycles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3543',
    description: 'Manufacture of invalid carriages',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3550',
    description: 'Manufacture other transport equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3611',
    description: 'Manufacture of chairs and seats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3612',
    description: 'Manufacture other office & shop furniture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3613',
    description: 'Manufacture of other kitchen furniture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3614',
    description: 'Manufacture of other furniture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3615',
    description: 'Manufacture of mattresses',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3621',
    description: 'Striking of coins and medals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3622',
    description: 'Manufacture of jewellery & related',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3630',
    description: 'Manufacture of musical instruments',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3640',
    description: 'Manufacture of sports goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3650',
    description: 'Manufacture of games and toys',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3661',
    description: 'Manufacture of imitation jewellery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3662',
    description: 'Manufacture of brooms and brushes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3663',
    description: 'Other manufacturing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3710',
    description: 'Recycling of metal waste and scrap',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '3720',
    description: 'Recycling non-metal waste & scrap',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4010',
    description: 'Production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4011',
    description: 'Production of electricity',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4012',
    description: 'Transmission of electricity',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4013',
    description: 'Distribution & trade in electricity',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4020',
    description: 'Mfr of gas; mains distribution',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4021',
    description: 'Manufacture of gas',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4022',
    description: 'Distribution & trade of gaseous fuels through mains',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4030',
    description: 'Steam and hot water supply',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4100',
    description: 'Collection, purify etc. of water',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4511',
    description: 'Demolition buildings; earth moving',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4512',
    description: 'Test drilling and boring',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4521',
    description: 'General construction & civil engineer',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4522',
    description: 'Erection of roof covering & frames',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4523',
    description: 'Construction roads, airfields etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4524',
    description: 'Construction of water projects',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4525',
    description: 'Other special trades construction',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4531',
    description: 'Installation electrical wiring etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4532',
    description: 'Insulation work activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4533',
    description: 'Plumbing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4534',
    description: 'Other building installation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4541',
    description: 'Plastering',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4542',
    description: 'Joinery installation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4543',
    description: 'Floor and wall covering',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4544',
    description: 'Painting and glazing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4545',
    description: 'Other building completion',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '4550',
    description: 'Rent construction equipment with operator',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5010',
    description: 'Sale of motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5020',
    description: 'Maintenance & repair of motors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5030',
    description: 'Sale of motor vehicle parts etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5040',
    description: 'Sale, repair etc. motorcycles & parts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5050',
    description: 'Retail sale of automotive fuel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5111',
    description: 'Agents agric & textile materials',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5112',
    description: 'Agents in sale of fuels, ores, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5113',
    description: 'Agents in building materials',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5114',
    description: 'Agents in industrial equipment, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5115',
    description: 'Agents in household goods, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5116',
    description: 'Agents in textiles, footwear etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5117',
    description: 'Agents in food, drink & tobacco',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5118',
    description: 'Agents in particular products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5119',
    description: 'Agents in sale of variety of goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5121',
    description: 'Wholesale of grain, animal feeds',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5122',
    description: 'Wholesale of flowers and plants',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5123',
    description: 'Wholesale of live animals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5124',
    description: 'Wholesale hides, skins and leather',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5125',
    description: 'Wholesale of unmanufactured tobacco',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5131',
    description: 'Wholesale of fruit and vegetables',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5132',
    description: 'Wholesale of meat and meat products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5133',
    description: 'Wholesale dairy products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5134',
    description: 'Wholesale of alcohol and other drinks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5135',
    description: 'Wholesale of tobacco products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5136',
    description: 'Wholesale sugar, chocolate etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5137',
    description: 'Wholesale coffee, tea, cocoa etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5138',
    description: 'Wholesale other food inc fish, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5139',
    description: 'Non-specialised wholesale food, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5141',
    description: 'Wholesale of textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5142',
    description: 'Wholesale of clothing and footwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5143',
    description: 'Wholesale electric household goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5144',
    description: 'Wholesale of china, wallpaper etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5145',
    description: 'Wholesale of perfume and cosmetics',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5146',
    description: 'Wholesale of pharmaceutical goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5147',
    description: 'Wholesale of other household goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5151',
    description: 'Wholesale fuels & related products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5152',
    description: 'Wholesale of metals and metal ores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5153',
    description: 'Wholesale wood, construction etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5154',
    description: 'Wholesale hardware, plumbing etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5155',
    description: 'Wholesale of chemical products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5156',
    description: 'Wholesale other intermediate goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5157',
    description: 'Wholesale of waste and scrap',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5161',
    description: 'Wholesale of machine tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5162',
    description: 'Wholesale of construction machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5163',
    description: 'Wholesale textile industry machines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5164',
    description: 'Wholesale office machinery & equip',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5165',
    description: 'Wholesale machines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5166',
    description: 'Wholesale agric machines & tractors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5170',
    description: 'Other wholesale',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5181',
    description: 'Wholesale of machine tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5182',
    description: 'Wholesale of mining',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5183',
    description: 'Wholesale of machinery for the textile industry',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5184',
    description: 'Wholesale of computers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5185',
    description: 'Wholesale of other office machinery & equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5186',
    description: 'Wholesale of other electronic parts & equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5187',
    description: 'Wholesale of other machinery for use in industry',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5188',
    description:
      'Wholesale of agricultural machinery & accessories & implements',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5190',
    description: 'Other wholesale',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5211',
    description: 'Retail in non-specialised stores holding an alcohol licence',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5212',
    description: 'Other retail non-specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5221',
    description: 'Retail of fruit and vegetables',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5222',
    description: 'Retail of meat and meat products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5223',
    description: 'Retail of fish, crustaceans etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5224',
    description: 'Retail bread, cakes, confectionery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5225',
    description: 'Retail alcoholic & other beverages',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5226',
    description: 'Retail sale of tobacco products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5227',
    description: 'Other retail food etc. specialised',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5231',
    description: 'Dispensing chemists',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5232',
    description: 'Retail medical & orthopaedic goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5233',
    description: 'Retail cosmetic & toilet articles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5241',
    description: 'Retail sale of textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5242',
    description: 'Retail sale of clothing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5243',
    description: 'Retail of footwear & leather goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5244',
    description: 'Retail furniture household etc',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5245',
    description: 'Retail electric household, etc. goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5246',
    description: 'Retail hardware, paints & glass',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5247',
    description: 'Retail books, newspapers etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5248',
    description: 'Other retail specialist stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5250',
    description: 'Retail other secondhand goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5261',
    description: 'Retail sale via mail order houses',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5262',
    description: 'Retail sale via stalls and markets',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5263',
    description: 'Other non-store retail sale',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5271',
    description: 'Repair boots, shoes, leather goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5272',
    description: 'Repair electrical household goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5273',
    description: 'Repair of clocks & jewellery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5274',
    description: 'Repair not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5510',
    description: 'Hotels & motels with or without restaurant',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5511',
    description: 'Hotels & motels',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5512',
    description: 'Hotels & motels',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5521',
    description: 'Youth hostels and mountain refuges',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5522',
    description: 'Camp sites, including caravan sites',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5523',
    description: 'Other provision of lodgings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5530',
    description: 'Restaurants',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5540',
    description: 'Bars',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5551',
    description: 'Canteens',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '5552',
    description: 'Catering',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6010',
    description: 'Transport via railways',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6021',
    description: 'Other scheduled passenger land transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6022',
    description: 'Taxi operation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6023',
    description: 'Other passenger land transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6024',
    description: 'Freight transport by road',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6030',
    description: 'Transport via pipelines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6110',
    description: 'Sea and coastal water transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6120',
    description: 'Inland water transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6210',
    description: 'Scheduled air transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6220',
    description: 'Non-scheduled air transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6230',
    description: 'Space transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6311',
    description: 'Cargo handling',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6312',
    description: 'Storage & warehousing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6321',
    description: 'Other supporting land transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6322',
    description: 'Other supporting water transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6323',
    description: 'Other supporting air transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6330',
    description: 'Travel agencies etc; tourist',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6340',
    description: 'Other transport agencies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6411',
    description: 'National post activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6412',
    description: 'Courier other than national post',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6420',
    description: 'Telecommunications',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6511',
    description: 'Central banking',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6512',
    description: 'Other monetary intermediation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6521',
    description: 'Financial leasing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6522',
    description: 'Other credit granting',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6523',
    description: 'Other financial intermediation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6601',
    description: 'Life insurance/reinsurance',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6602',
    description: 'Pension funding',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6603',
    description: 'Non-life insurance/reinsurance',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6711',
    description: 'Administration of financial markets',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6712',
    description: 'Security broking & fund management',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6713',
    description: 'Auxiliary financial intermed',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '6720',
    description: 'Auxiliary insurance & pension fund',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7011',
    description: 'Development & sell real estate',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7012',
    description: 'Buying & sell own real estate',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7020',
    description: 'Letting of own property',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7031',
    description: 'Real estate agencies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7032',
    description: 'Manage real estate, fee or contract',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7110',
    description: 'Renting of automobiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7121',
    description: 'Rent other land transport equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7122',
    description: 'Rent water transport equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7123',
    description: 'Renting of air transport equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7131',
    description: 'Rent agricultural machinery & equip',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7132',
    description: 'Rent civil engineering machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7133',
    description: 'Rent office machinery inc computers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7134',
    description: 'Rent other machinery & equip',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7140',
    description: 'Rent personal & household goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7210',
    description: 'Hardware consultancy',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7220',
    description: 'Software consultancy and supply',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7221',
    description: 'Software publishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7222',
    description: 'Other software consultancy and supply',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7230',
    description: 'Data processing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7240',
    description: 'Data base activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7250',
    description: 'Maintenance office & computing mach',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7260',
    description: 'Other computer related activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7310',
    description: 'R & d on nat sciences & engineering',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7320',
    description: 'R & d on soc sciences & humanities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7411',
    description: 'Legal activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7412',
    description: 'Accounting, auditing; tax consult',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7413',
    description: 'Market research, opinion polling',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7414',
    description: 'Business & management consultancy',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7415',
    description: 'Holding companies including Head Offices',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7420',
    description: 'Architectural, technical consult',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7430',
    description: 'Technical testing and analysis',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7440',
    description: 'Advertising',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7450',
    description: 'Labour recruitment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7460',
    description: 'Investigation & security',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7470',
    description: 'Other cleaning activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7481',
    description: 'Portrait photographic activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7482',
    description: 'Packaging activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7483',
    description: 'Secretarial & translation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7484',
    description: 'Other business activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7485',
    description: 'Secretarial & translation services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7486',
    description: 'Call centre activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7487',
    description: 'Other business activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7499',
    description: 'Non-trading company',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7511',
    description: 'General (overall) public service',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7512',
    description: 'Regulation health, education, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7513',
    description: 'Regulation more efficient business',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7514',
    description: 'Support services for government',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7521',
    description: 'Foreign affairs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7522',
    description: 'Defence activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7523',
    description: 'Justice and judicial activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7524',
    description: 'Public security, law & order',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7525',
    description: 'Fire service activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '7530',
    description: 'Compulsory social security',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8010',
    description: 'Primary education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8021',
    description: 'General secondary education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8022',
    description: 'Technical & vocational secondary',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8030',
    description: 'Higher education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8041',
    description: 'Driving school activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8042',
    description: 'Adult and other education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8511',
    description: 'Hospital activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8512',
    description: 'Medical practice activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8513',
    description: 'Dental practice activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8514',
    description: 'Other human health activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8520',
    description: 'Veterinary activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8531',
    description: 'Social work with accommodation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '8532',
    description: 'Social work without accommodation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9000',
    description: 'Refuse disposal',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9001',
    description: 'Collection and treatment of sewage',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9002',
    description: 'Collection and treatment of other waste',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9003',
    description: 'Sanitation remediation and similar activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9111',
    description: 'Business & employers organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9112',
    description: 'Professional organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9120',
    description: 'Trade unions',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9131',
    description: 'Religious organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9132',
    description: 'Political organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9133',
    description: 'Other membership organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9211',
    description: 'Motion picture and video production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9212',
    description: 'Motion picture & video distribution',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9213',
    description: 'Motion picture projection',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9220',
    description: 'Radio and television activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9231',
    description: 'Artistic & literary creation etc',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9232',
    description: 'Operation of arts facilities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9233',
    description: 'Fair and amusement park activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9234',
    description: 'Other entertainment activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9240',
    description: 'News agency activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9251',
    description: 'Library and archives activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9252',
    description: 'Museum & preservation of history',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9253',
    description: 'Botanical, zoos & nature reserves',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9261',
    description: 'Operate sports arenas & stadiums',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9262',
    description: 'Other sporting activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9271',
    description: 'Gambling and betting activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9272',
    description: 'Other recreational activities nec',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9301',
    description: 'Wash & dry clean textile & fur',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9302',
    description: 'Hairdressing & other beauty treatment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9303',
    description: 'Funeral and related activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9304',
    description: 'Physical well-being activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9305',
    description: 'Other service activities n.e.c.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9500',
    description: 'Private household with employees',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9600',
    description:
      'Undifferentiated goods producing activities of private households for own use',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9700',
    description:
      'Undifferentiated services producing activities of private households for own use',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9800',
    description: 'Residents property management',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9900',
    description: 'Extra-territorial organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '9999',
    description: 'Dormant company',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10110',
    description: 'Processing and preserving of meat',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10120',
    description: 'Processing and preserving of poultry meat',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10130',
    description: 'Production of meat and poultry meat products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10200',
    description: 'Processing and preserving of fish, crustaceans and molluscs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10310',
    description: 'Processing and preserving of potatoes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10320',
    description: 'Manufacture of fruit and vegetable juice',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10390',
    description: 'Other processing and preserving of fruit and vegetables',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10410',
    description: 'Manufacture of oils and fats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10420',
    description: 'Manufacture of margarine and similar edible fats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10511',
    description: 'Liquid milk and cream production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10512',
    description: 'Butter and cheese production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10519',
    description: 'Manufacture of other milk products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10520',
    description: 'Manufacture of ice cream',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10611',
    description: 'Grain milling',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10612',
    description: 'Manufacture of breakfast cereals and cereals-based food',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10620',
    description: 'Manufacture of starches and starch products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10710',
    description:
      'Manufacture of bread; manufacture of fresh pastry goods and cakes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10720',
    description:
      'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10730',
    description:
      'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10810',
    description: 'Manufacture of sugar',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10821',
    description: 'Manufacture of cocoa and chocolate confectionery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10822',
    description: 'Manufacture of sugar confectionery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10831',
    description: 'Tea processing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10832',
    description: 'Production of coffee and coffee substitutes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10840',
    description: 'Manufacture of condiments and seasonings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10850',
    description: 'Manufacture of prepared meals and dishes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10860',
    description:
      'Manufacture of homogenised food preparations and dietetic food',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10890',
    description: 'Manufacture of other food products not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10910',
    description: 'Manufacture of prepared feeds for farm animals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '10920',
    description: 'Manufacture of prepared pet foods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '11010',
    description: 'Distilling, rectifying and blending of spirits',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '11020',
    description: 'Manufacture of wine from grape',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '11030',
    description: 'Manufacture of cider and other fruit wines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '11040',
    description: 'Manufacture of other non-distilled fermented beverages',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '11050',
    description: 'Manufacture of beer',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '11060',
    description: 'Manufacture of malt',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '11070',
    description:
      'Manufacture of soft drinks; production of mineral waters and other bottled waters',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '12000',
    description: 'Manufacture of tobacco products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13100',
    description: 'Preparation and spinning of textile fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13200',
    description: 'Weaving of textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13300',
    description: 'Finishing of textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13910',
    description: 'Manufacture of knitted and crocheted fabrics',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13921',
    description: 'Manufacture of soft furnishings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13922',
    description: 'manufacture of canvas goods, sacks, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13923',
    description: 'manufacture of household textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13931',
    description: 'Manufacture of woven or tufted carpets and rugs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13939',
    description: 'Manufacture of other carpets and rugs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13940',
    description: 'Manufacture of cordage, rope, twine and netting',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13950',
    description:
      'Manufacture of non-wovens and articles made from non-wovens, except apparel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13960',
    description: 'Manufacture of other technical and industrial textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '13990',
    description: 'Manufacture of other textiles not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14110',
    description: 'Manufacture of leather clothes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14120',
    description: 'Manufacture of workwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14131',
    description: "Manufacture of other men's outerwear",
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14132',
    description: "Manufacture of other women's outerwear",
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14141',
    description: "Manufacture of men's underwear",
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14142',
    description: "Manufacture of women's underwear",
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14190',
    description:
      'Manufacture of other wearing apparel and accessories not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14200',
    description: 'Manufacture of articles of fur',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14310',
    description: 'Manufacture of knitted and crocheted hosiery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '14390',
    description: 'Manufacture of other knitted and crocheted apparel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '15110',
    description: 'Tanning and dressing of leather; dressing and dyeing of fur',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '15120',
    description:
      'Manufacture of luggage, handbags and the like, saddlery and harness',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '15200',
    description: 'Manufacture of footwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '16100',
    description: 'Sawmilling and planing of wood',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '16210',
    description: 'Manufacture of veneer sheets and wood-based panels',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '16220',
    description: 'Manufacture of assembled parquet floors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '16230',
    description: "Manufacture of other builders' carpentry and joinery",
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '16240',
    description: 'Manufacture of wooden containers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '16290',
    description:
      'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '17110',
    description: 'Manufacture of pulp',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '17120',
    description: 'Manufacture of paper and paperboard',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '17211',
    description:
      'Manufacture of corrugated paper and paperboard, sacks and bags',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '17219',
    description: 'Manufacture of other paper and paperboard containers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '17220',
    description:
      'Manufacture of household and sanitary goods and of toilet requisites',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '17230',
    description: 'Manufacture of paper stationery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '17240',
    description: 'Manufacture of wallpaper',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '17290',
    description:
      'Manufacture of other articles of paper and paperboard not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '18110',
    description: 'Printing of newspapers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '18121',
    description: 'Manufacture of printed labels',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '18129',
    description: 'Printing not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '18130',
    description: 'Pre-press and pre-media services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '18140',
    description: 'Binding and related services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '18201',
    description: 'Reproduction of sound recording',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '18202',
    description: 'Reproduction of video recording',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '18203',
    description: 'Reproduction of computer media',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '19100',
    description: 'Manufacture of coke oven products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '19201',
    description: 'Mineral oil refining',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '19209',
    description:
      'Other treatment of petroleum products (excluding petrochemicals manufacture)',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20110',
    description: 'Manufacture of industrial gases',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20120',
    description: 'Manufacture of dyes and pigments',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20130',
    description: 'Manufacture of other inorganic basic chemicals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20140',
    description: 'Manufacture of other organic basic chemicals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20150',
    description: 'Manufacture of fertilisers and nitrogen compounds',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20160',
    description: 'Manufacture of plastics in primary forms',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20170',
    description: 'Manufacture of synthetic rubber in primary forms',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20200',
    description: 'Manufacture of pesticides and other agrochemical products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20301',
    description:
      'Manufacture of paints, varnishes and similar coatings, mastics and sealants',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20302',
    description: 'Manufacture of printing ink',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20411',
    description: 'Manufacture of soap and detergents',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20412',
    description: 'Manufacture of cleaning and polishing preparations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20420',
    description: 'Manufacture of perfumes and toilet preparations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20510',
    description: 'Manufacture of explosives',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20520',
    description: 'Manufacture of glues',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20530',
    description: 'Manufacture of essential oils',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20590',
    description:
      'Manufacture of other chemical products not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '20600',
    description: 'Manufacture of man-made fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '21100',
    description: 'Manufacture of basic pharmaceutical products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '21200',
    description: 'Manufacture of pharmaceutical preparations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '22110',
    description:
      'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '22190',
    description: 'Manufacture of other rubber products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '22210',
    description: 'Manufacture of plastic plates, sheets, tubes and profiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '22220',
    description: 'Manufacture of plastic packing goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '22230',
    description: 'Manufacture of builders  ware of plastic',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '22290',
    description: 'Manufacture of other plastic products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23110',
    description: 'Manufacture of flat glass',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23120',
    description: 'Shaping and processing of flat glass',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23130',
    description: 'Manufacture of hollow glass',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23140',
    description: 'Manufacture of glass fibres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23190',
    description:
      'Manufacture and processing of other glass, including technical glassware',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23200',
    description: 'Manufacture of refractory products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23310',
    description: 'Manufacture of ceramic tiles and flags',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23320',
    description:
      'Manufacture of bricks, tiles and construction products, in baked clay',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23410',
    description: 'Manufacture of ceramic household and ornamental articles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23420',
    description: 'Manufacture of ceramic sanitary fixtures',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23430',
    description: 'Manufacture of ceramic insulators and insulating fittings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23440',
    description: 'Manufacture of other technical ceramic products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23490',
    description:
      'Manufacture of other ceramic products not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23510',
    description: 'Manufacture of cement',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23520',
    description: 'Manufacture of lime and plaster',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23610',
    description: 'Manufacture of concrete products for construction purposes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23620',
    description: 'Manufacture of plaster products for construction purposes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23630',
    description: 'Manufacture of ready-mixed concrete',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23640',
    description: 'Manufacture of mortars',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23650',
    description: 'Manufacture of fibre cement',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23690',
    description:
      'Manufacture of other articles of concrete, plaster and cement',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23700',
    description: 'Cutting, shaping and finishing of stone',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23910',
    description: 'Production of abrasive products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '23990',
    description:
      'Manufacture of other non-metallic mineral products not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24100',
    description: 'Manufacture of basic iron and steel and of ferro-alloys',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24200',
    description:
      'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24310',
    description: 'Cold drawing of bars',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24320',
    description: 'Cold rolling of narrow strip',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24330',
    description: 'Cold forming or folding',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24340',
    description: 'Cold drawing of wire',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24410',
    description: 'Precious metals production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24420',
    description: 'Aluminium production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24430',
    description: 'Lead, zinc and tin production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24440',
    description: 'Copper production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24450',
    description: 'Other non-ferrous metal production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24460',
    description: 'Processing of nuclear fuel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24510',
    description: 'Casting of iron',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24520',
    description: 'Casting of steel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24530',
    description: 'Casting of light metals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '24540',
    description: 'Casting of other non-ferrous metals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25110',
    description: 'Manufacture of metal structures and parts of structures',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25120',
    description: 'Manufacture of doors and windows of metal',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25210',
    description: 'Manufacture of central heating radiators and boilers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25290',
    description:
      'Manufacture of other tanks, reservoirs and containers of metal',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25300',
    description:
      'Manufacture of steam generators, except central heating hot water boilers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25400',
    description: 'Manufacture of weapons and ammunition',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25500',
    description:
      'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25610',
    description: 'Treatment and coating of metals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25620',
    description: 'Machining',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25710',
    description: 'Manufacture of cutlery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25720',
    description: 'Manufacture of locks and hinges',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25730',
    description: 'Manufacture of tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25910',
    description: 'Manufacture of steel drums and similar containers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25920',
    description: 'Manufacture of light metal packaging',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25930',
    description: 'Manufacture of wire products, chain and springs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25940',
    description: 'Manufacture of fasteners and screw machine products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '25990',
    description:
      'Manufacture of other fabricated metal products not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26110',
    description: 'Manufacture of electronic components',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26120',
    description: 'Manufacture of loaded electronic boards',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26200',
    description: 'Manufacture of computers and peripheral equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26301',
    description:
      'Manufacture of telegraph and telephone apparatus and equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26309',
    description:
      'Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26400',
    description: 'Manufacture of consumer electronics',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26511',
    description:
      'Manufacture of electronic measuring, testing etc. equipment, not for industrial process control',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26512',
    description:
      'Manufacture of electronic industrial process control equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26513',
    description:
      'Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26514',
    description:
      'Manufacture of non-electronic industrial process control equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26520',
    description: 'Manufacture of watches and clocks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26600',
    description:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26701',
    description: 'Manufacture of optical precision instruments',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26702',
    description: 'Manufacture of photographic and cinematographic equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '26800',
    description: 'Manufacture of magnetic and optical media',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27110',
    description: 'Manufacture of electric motors, generators and transformers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27120',
    description:
      'Manufacture of electricity distribution and control apparatus',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27200',
    description: 'Manufacture of batteries and accumulators',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27310',
    description: 'Manufacture of fibre optic cables',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27320',
    description:
      'Manufacture of other electronic and electric wires and cables',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27330',
    description: 'Manufacture of wiring devices',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27400',
    description: 'Manufacture of electric lighting equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27510',
    description: 'Manufacture of electric domestic appliances',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27520',
    description: 'Manufacture of non-electric domestic appliances',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '27900',
    description: 'Manufacture of other electrical equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28110',
    description:
      'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28120',
    description: 'Manufacture of fluid power equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28131',
    description: 'Manufacture of pumps',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28132',
    description: 'Manufacture of compressors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28140',
    description: 'Manufacture of taps and valves',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28150',
    description: 'Manufacture of bearings, gears, gearing and driving elements',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28210',
    description: 'Manufacture of ovens, furnaces and furnace burners',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28220',
    description: 'Manufacture of lifting and handling equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28230',
    description:
      'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28240',
    description: 'Manufacture of power-driven hand tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28250',
    description:
      'Manufacture of non-domestic cooling and ventilation equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28290',
    description:
      'Manufacture of other general-purpose machinery not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28301',
    description: 'Manufacture of agricultural tractors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28302',
    description:
      'Manufacture of agricultural and forestry machinery other than tractors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28410',
    description: 'Manufacture of metal forming machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28490',
    description: 'Manufacture of other machine tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28910',
    description: 'Manufacture of machinery for metallurgy',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28921',
    description: 'Manufacture of machinery for mining',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28922',
    description: 'Manufacture of earthmoving equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28923',
    description:
      'Manufacture of equipment for concrete crushing and screening and roadworks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28930',
    description:
      'Manufacture of machinery for food, beverage and tobacco processing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28940',
    description:
      'Manufacture of machinery for textile, apparel and leather production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28950',
    description: 'Manufacture of machinery for paper and paperboard production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28960',
    description: 'Manufacture of plastics and rubber machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '28990',
    description:
      'Manufacture of other special-purpose machinery not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '29100',
    description: 'Manufacture of motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '29201',
    description:
      'Manufacture of bodies (coachwork) for motor vehicles (except caravans)',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '29202',
    description: 'Manufacture of trailers and semi-trailers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '29203',
    description: 'Manufacture of caravans',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '29310',
    description:
      'Manufacture of electrical and electronic equipment for motor vehicles and their engines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '29320',
    description:
      'Manufacture of other parts and accessories for motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '30110',
    description: 'Building of ships and floating structures',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '30120',
    description: 'Building of pleasure and sporting boats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '30200',
    description: 'Manufacture of railway locomotives and rolling stock',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '30300',
    description: 'Manufacture of air and spacecraft and related machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '30400',
    description: 'Manufacture of military fighting vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '30910',
    description: 'Manufacture of motorcycles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '30920',
    description: 'Manufacture of bicycles and invalid carriages',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '30990',
    description:
      'Manufacture of other transport equipment not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '31010',
    description: 'Manufacture of office and shop furniture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '31020',
    description: 'Manufacture of kitchen furniture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '31030',
    description: 'Manufacture of mattresses',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '31090',
    description: 'Manufacture of other furniture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32110',
    description: 'Striking of coins',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32120',
    description: 'Manufacture of jewellery and related articles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32130',
    description: 'Manufacture of imitation jewellery and related articles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32200',
    description: 'Manufacture of musical instruments',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32300',
    description: 'Manufacture of sports goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32401',
    description: 'Manufacture of professional and arcade games and toys',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32409',
    description:
      'Manufacture of other games and toys, not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32500',
    description: 'Manufacture of medical and dental instruments and supplies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32910',
    description: 'Manufacture of brooms and brushes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '32990',
    description: 'Other manufacturing not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33110',
    description: 'Repair of fabricated metal products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33120',
    description: 'Repair of machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33130',
    description: 'Repair of electronic and optical equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33140',
    description: 'Repair of electrical equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33150',
    description: 'Repair and maintenance of ships and boats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33160',
    description: 'Repair and maintenance of aircraft and spacecraft',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33170',
    description:
      'Repair and maintenance of other transport equipment not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33190',
    description: 'Repair of other equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '33200',
    description: 'Installation of industrial machinery and equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '35110',
    description: 'Production of electricity',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '35120',
    description: 'Transmission of electricity',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '35130',
    description: 'Distribution of electricity',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '35140',
    description: 'Trade of electricity',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '35210',
    description: 'Manufacture of gas',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '35220',
    description: 'Distribution of gaseous fuels through mains',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '35230',
    description: 'Trade of gas through mains',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '35300',
    description: 'Steam and air conditioning supply',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '36000',
    description: 'Water collection, treatment and supply',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '37000',
    description: 'Sewerage',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '38110',
    description: 'Collection of non-hazardous waste',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '38120',
    description: 'Collection of hazardous waste',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '38210',
    description: 'Treatment and disposal of non-hazardous waste',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '38220',
    description: 'Treatment and disposal of hazardous waste',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '38310',
    description: 'Dismantling of wrecks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '38320',
    description: 'Recovery of sorted materials',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '39000',
    description: 'Remediation activities and other waste management services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '41100',
    description: 'Development of building projects',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '41201',
    description: 'Construction of commercial buildings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '41202',
    description: 'Construction of domestic buildings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '42110',
    description: 'Construction of roads and motorways',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '42120',
    description: 'Construction of railways and underground railways',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '42130',
    description: 'Construction of bridges and tunnels',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '42210',
    description: 'Construction of utility projects for fluids',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '42220',
    description:
      'Construction of utility projects for electricity and telecommunications',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '42910',
    description: 'Construction of water projects',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '42990',
    description:
      'Construction of other civil engineering projects not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43110',
    description: 'Demolition',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43120',
    description: 'Site preparation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43130',
    description: 'Test drilling and boring',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43210',
    description: 'Electrical installation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43220',
    description: 'Plumbing, heat and air-conditioning installation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43290',
    description: 'Other construction installation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43310',
    description: 'Plastering',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43320',
    description: 'Joinery installation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43330',
    description: 'Floor and wall covering',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43341',
    description: 'Painting',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43342',
    description: 'Glazing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43390',
    description: 'Other building completion and finishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43910',
    description: 'Roofing activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43991',
    description: 'Scaffold erection',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '43999',
    description:
      'Other specialised construction activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '45111',
    description: 'Sale of new cars and light motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '45112',
    description: 'Sale of used cars and light motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '45190',
    description: 'Sale of other motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '45200',
    description: 'Maintenance and repair of motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '45310',
    description: 'Wholesale trade of motor vehicle parts and accessories',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '45320',
    description: 'Retail trade of motor vehicle parts and accessories',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '45400',
    description:
      'Sale, maintenance and repair of motorcycles and related parts and accessories',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46110',
    description:
      'Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46120',
    description:
      'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46130',
    description: 'Agents involved in the sale of timber and building materials',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46140',
    description:
      'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46150',
    description:
      'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46160',
    description:
      'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46170',
    description: 'Agents involved in the sale of food, beverages and tobacco',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46180',
    description: 'Agents specialised in the sale of other particular products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46190',
    description: 'Agents involved in the sale of a variety of goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46210',
    description:
      'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46220',
    description: 'Wholesale of flowers and plants',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46230',
    description: 'Wholesale of live animals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46240',
    description: 'Wholesale of hides, skins and leather',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46310',
    description: 'Wholesale of fruit and vegetables',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46320',
    description: 'Wholesale of meat and meat products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46330',
    description: 'Wholesale of dairy products, eggs and edible oils and fats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46341',
    description:
      'Wholesale of fruit and vegetable juices, mineral water and soft drinks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46342',
    description:
      'Wholesale of wine, beer, spirits and other alcoholic beverages',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46350',
    description: 'Wholesale of tobacco products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46360',
    description: 'Wholesale of sugar and chocolate and sugar confectionery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46370',
    description: 'Wholesale of coffee, tea, cocoa and spices',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46380',
    description:
      'Wholesale of other food, including fish, crustaceans and molluscs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46390',
    description: 'Non-specialised wholesale of food, beverages and tobacco',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46410',
    description: 'Wholesale of textiles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46420',
    description: 'Wholesale of clothing and footwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46431',
    description:
      'Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46439',
    description:
      "Wholesale of radio, television goods & electrical household appliances (other than records, tapes, CD's & video tapes and the equipment used for playing them)",
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46440',
    description: 'Wholesale of china and glassware and cleaning materials',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46450',
    description: 'Wholesale of perfume and cosmetics',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46460',
    description: 'Wholesale of pharmaceutical goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46470',
    description: 'Wholesale of furniture, carpets and lighting equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46480',
    description: 'Wholesale of watches and jewellery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46491',
    description: 'Wholesale of musical instruments',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46499',
    description:
      'Wholesale of household goods (other than musical instruments) not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46510',
    description:
      'Wholesale of computers, computer peripheral equipment and software',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46520',
    description:
      'Wholesale of electronic and telecommunications equipment and parts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46610',
    description: 'Wholesale of agricultural machinery, equipment and supplies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46620',
    description: 'Wholesale of machine tools',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46630',
    description:
      'Wholesale of mining, construction and civil engineering machinery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46640',
    description:
      'Wholesale of machinery for the textile industry and of sewing and knitting machines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46650',
    description: 'Wholesale of office furniture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46660',
    description: 'Wholesale of other office machinery and equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46690',
    description: 'Wholesale of other machinery and equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46711',
    description: 'Wholesale of petroleum and petroleum products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46719',
    description: 'Wholesale of other fuels and related products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46720',
    description: 'Wholesale of metals and metal ores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46730',
    description:
      'Wholesale of wood, construction materials and sanitary equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46740',
    description:
      'Wholesale of hardware, plumbing and heating equipment and supplies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46750',
    description: 'Wholesale of chemical products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46760',
    description: 'Wholesale of other intermediate products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46770',
    description: 'Wholesale of waste and scrap',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '46900',
    description: 'Non-specialised wholesale trade',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47110',
    description:
      'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47190',
    description: 'Other retail sale in non-specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47210',
    description: 'Retail sale of fruit and vegetables in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47220',
    description: 'Retail sale of meat and meat products in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47230',
    description:
      'Retail sale of fish, crustaceans and molluscs in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47240',
    description:
      'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47250',
    description: 'Retail sale of beverages in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47260',
    description: 'Retail sale of tobacco products in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47290',
    description: 'Other retail sale of food in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47300',
    description: 'Retail sale of automotive fuel in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47410',
    description:
      'Retail sale of computers, peripheral units and software in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47421',
    description: 'Retail sale of mobile telephones',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47429',
    description:
      'Retail sale of telecommunications equipment other than mobile telephones',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47430',
    description:
      'Retail sale of audio and video equipment in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47510',
    description: 'Retail sale of textiles in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47520',
    description:
      'Retail sale of hardware, paints and glass in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47530',
    description:
      'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47540',
    description:
      'Retail sale of electrical household appliances in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47591',
    description: 'Retail sale of musical instruments and scores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47599',
    description:
      'Retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47610',
    description: 'Retail sale of books in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47620',
    description:
      'Retail sale of newspapers and stationery in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47630',
    description:
      'Retail sale of music and video recordings in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47640',
    description:
      'Retail sale of sports goods, fishing gear, camping goods, boats and bicycles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47650',
    description: 'Retail sale of games and toys in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47710',
    description: 'Retail sale of clothing in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47721',
    description: 'Retail sale of footwear in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47722',
    description: 'Retail sale of leather goods in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47730',
    description: 'Dispensing chemist in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47741',
    description: 'Retail sale of hearing aids',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47749',
    description:
      'Retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47750',
    description:
      'Retail sale of cosmetic and toilet articles in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47760',
    description:
      'Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47770',
    description: 'Retail sale of watches and jewellery in specialised stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47781',
    description: 'Retail sale in commercial art galleries',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47782',
    description: 'Retail sale by opticians',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47789',
    description:
      'Other retail sale of new goods in specialised stores (not commercial art galleries and opticians)',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47791',
    description: 'Retail sale of antiques including antique books in stores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47799',
    description:
      'Retail sale of other second-hand goods in stores (not incl. antiques)',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47810',
    description:
      'Retail sale via stalls and markets of food, beverages and tobacco products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47820',
    description:
      'Retail sale via stalls and markets of textiles, clothing and footwear',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47890',
    description: 'Retail sale via stalls and markets of other goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47910',
    description: 'Retail sale via mail order houses or via Internet',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '47990',
    description: 'Other retail sale not in stores, stalls or markets',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49100',
    description: 'Passenger rail transport, interurban',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49200',
    description: 'Freight rail transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49311',
    description:
      'Urban and suburban passenger railway transportation by underground, metro and similar systems',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49319',
    description:
      'Other urban, suburban or metropolitan passenger land transport (not underground, metro or similar)',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49320',
    description: 'Taxi operation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49390',
    description: 'Other passenger land transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49410',
    description: 'Freight transport by road',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49420',
    description: 'Removal services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '49500',
    description: 'Transport via pipeline',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '50100',
    description: 'Sea and coastal passenger water transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '50200',
    description: 'Sea and coastal freight water transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '50300',
    description: 'Inland passenger water transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '50400',
    description: 'Inland freight water transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '51101',
    description: 'Scheduled passenger air transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '51102',
    description: 'Non-scheduled passenger air transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '51210',
    description: 'Freight air transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '51220',
    description: 'Space transport',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52101',
    description:
      'Operation of warehousing and storage facilities for water transport activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52102',
    description:
      'Operation of warehousing and storage facilities for air transport activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52103',
    description:
      'Operation of warehousing and storage facilities for land transport activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52211',
    description: 'Operation of rail freight terminals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52212',
    description: 'Operation of rail passenger facilities at railway stations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52213',
    description:
      'Operation of bus and coach passenger facilities at bus and coach stations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52219',
    description:
      'Other service activities incidental to land transportation, not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52220',
    description: 'Service activities incidental to water transportation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52230',
    description: 'Service activities incidental to air transportation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52241',
    description: 'Cargo handling for water transport activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52242',
    description: 'Cargo handling for air transport activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52243',
    description: 'Cargo handling for land transport activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '52290',
    description: 'Other transportation support activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '53100',
    description: 'Postal activities under universal service obligation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '53201',
    description: 'Licensed carriers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '53202',
    description: 'Unlicensed carrier',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '55100',
    description: 'Hotels and similar accommodation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '55201',
    description: 'Holiday centres and villages',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '55202',
    description: 'Youth hostels',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '55209',
    description: 'Other holiday and other collective accommodation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '55300',
    description:
      'Recreational vehicle parks, trailer parks and camping grounds',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '55900',
    description: 'Other accommodation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '56101',
    description: 'Licensed restaurants',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '56102',
    description: 'Unlicensed restaurants and cafes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '56103',
    description: 'Take-away food shops and mobile food stands',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '56210',
    description: 'Event catering activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '56290',
    description: 'Other food services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '56301',
    description: 'Licensed clubs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '56302',
    description: 'Public houses and bars',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '58110',
    description: 'Book publishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '58120',
    description: 'Publishing of directories and mailing lists',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '58130',
    description: 'Publishing of newspapers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '58141',
    description: 'Publishing of learned journals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '58142',
    description:
      'Publishing of  consumer and business journals and periodicals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '58190',
    description: 'Other publishing activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '58210',
    description: 'Publishing of computer games',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '58290',
    description: 'Other software publishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59111',
    description: 'Motion picture production activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59112',
    description: 'Video production activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59113',
    description: 'Television programme production activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59120',
    description:
      'Motion picture, video and television programme post-production activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59131',
    description: 'Motion picture distribution activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59132',
    description: 'Video distribution activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59133',
    description: 'Television programme distribution activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59140',
    description: 'Motion picture projection activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '59200',
    description: 'Sound recording and music publishing activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '60100',
    description: 'Radio broadcasting',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '60200',
    description: 'Television programming and broadcasting activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '61100',
    description: 'Wired telecommunications activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '61200',
    description: 'Wireless telecommunications activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '61300',
    description: 'Satellite telecommunications activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '61900',
    description: 'Other telecommunications activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '62011',
    description:
      'Ready-made interactive leisure and entertainment software development',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '62012',
    description: 'Business and domestic software development',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '62020',
    description: 'Information technology consultancy activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '62030',
    description: 'Computer facilities management activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '62090',
    description: 'Other information technology service activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '63110',
    description: 'Data processing, hosting and related activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '63120',
    description: 'Web portals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '63910',
    description: 'News agency activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '63990',
    description:
      'Other information service activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64110',
    description: 'Central banking',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64191',
    description: 'Banks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64192',
    description: 'Building societies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64201',
    description: 'Activities of agricultural holding companies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64202',
    description: 'Activities of production holding companies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64203',
    description: 'Activities of construction holding companies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64204',
    description: 'Activities of distribution holding companies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64205',
    description: 'Activities of financial services holding companies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64209',
    description:
      'Activities of other holding companies not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64301',
    description: 'Activities of investment trusts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64302',
    description: 'Activities of unit trusts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64303',
    description: 'Activities of venture and development capital companies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64304',
    description: 'Activities of open-ended investment companies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64305',
    description: 'Activities of property unit trusts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64306',
    description: 'Activities of real estate investment trusts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64910',
    description: 'Financial leasing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64921',
    description:
      'Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64922',
    description: 'Activities of mortgage finance companies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64929',
    description: 'Other credit granting not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64991',
    description: 'Security dealing on own account',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64992',
    description: 'Factoring',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '64999',
    description: 'Financial intermediation not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '65110',
    description: 'Life insurance',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '65120',
    description: 'Non-life insurance',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '65201',
    description: 'Life reinsurance',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '65202',
    description: 'Non-life reinsurance',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '65300',
    description: 'Pension funding',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '66110',
    description: 'Administration of financial markets',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '66120',
    description: 'Security and commodity contracts dealing activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '66190',
    description:
      'Activities auxiliary to financial intermediation not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '66210',
    description: 'Risk and damage evaluation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '66220',
    description: 'Activities of insurance agents and brokers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '66290',
    description: 'Other activities auxiliary to insurance and pension funding',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '66300',
    description: 'Fund management activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '68100',
    description: 'Buying and selling of own real estate',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '68201',
    description: 'Renting and operating of Housing Association real estate',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '68202',
    description: 'Letting and operating of conference and exhibition centres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '68209',
    description: 'Other letting and operating of own or leased real estate',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '68310',
    description: 'Real estate agencies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '68320',
    description: 'Management of real estate on a fee or contract basis',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '69101',
    description: 'Barristers at law',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '69102',
    description: 'Solicitors',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '69109',
    description:
      'Activities of patent and copyright agents; other legal activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '69201',
    description: 'Accounting and auditing activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '69202',
    description: 'Bookkeeping activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '69203',
    description: 'Tax consultancy',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '70100',
    description: 'Activities of head offices',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '70210',
    description: 'Public relations and communications activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '70221',
    description: 'Financial management',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '70229',
    description:
      'Management consultancy activities other than financial management',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '71111',
    description: 'Architectural activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '71112',
    description: 'Urban planning and landscape architectural activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '71121',
    description:
      'Engineering design activities for industrial process and production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '71122',
    description:
      'Engineering related scientific and technical consulting activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '71129',
    description: 'Other engineering activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '71200',
    description: 'Technical testing and analysis',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '72110',
    description: 'Research and experimental development on biotechnology',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '72190',
    description:
      'Other research and experimental development on natural sciences and engineering',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '72200',
    description:
      'Research and experimental development on social sciences and humanities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '73110',
    description: 'Advertising agencies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '73120',
    description: 'Media representation services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '73200',
    description: 'Market research and public opinion polling',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74100',
    description: 'Specialised design activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74201',
    description: 'Portrait photographic activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74202',
    description: 'Other specialist photography',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74203',
    description: 'Film processing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74209',
    description: 'Photographic activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74300',
    description: 'Translation and interpretation activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74901',
    description: 'Environmental consulting activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74902',
    description: 'Quantity surveying activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74909',
    description:
      'Other professional, scientific and technical activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '74990',
    description: 'Non-trading company',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '75000',
    description: 'Veterinary activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77110',
    description: 'Renting and leasing of cars and light motor vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77120',
    description: 'Renting and leasing of trucks and other heavy vehicles',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77210',
    description: 'Renting and leasing of recreational and sports goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77220',
    description: 'Renting of video tapes and disks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77291',
    description: 'Renting and leasing of media entertainment equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77299',
    description: 'Renting and leasing of other personal and household goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77310',
    description: 'Renting and leasing of agricultural machinery and equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77320',
    description:
      'Renting and leasing of construction and civil engineering machinery and equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77330',
    description:
      'Renting and leasing of office machinery and equipment (including computers)',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77341',
    description: 'Renting and leasing of passenger water transport equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77342',
    description: 'Renting and leasing of freight water transport equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77351',
    description: 'Renting and leasing of air passenger transport equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77352',
    description: 'Renting and leasing of freight air transport equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77390',
    description:
      'Renting and leasing of other machinery, equipment and tangible goods not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '77400',
    description:
      'Leasing of intellectual property and similar products, except copyright works',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '78101',
    description:
      'Motion picture, television and other theatrical casting activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '78109',
    description: 'Other activities of employment placement agencies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '78200',
    description: 'Temporary employment agency activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '78300',
    description:
      'Human resources provision and management of human resources functions',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '79110',
    description: 'Travel agency activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '79120',
    description: 'Tour operator activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '79901',
    description: 'Activities of tourist guides',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '79909',
    description:
      'Other reservation service activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '80100',
    description: 'Private security activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '80200',
    description: 'Security systems service activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '80300',
    description: 'Investigation activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81100',
    description: 'Combined facilities support activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81210',
    description: 'General cleaning of buildings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81221',
    description: 'Window cleaning services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81222',
    description: 'Specialised cleaning services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81223',
    description: 'Furnace and chimney cleaning services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81229',
    description: 'Other building and industrial cleaning activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81291',
    description: 'Disinfecting and exterminating services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81299',
    description: 'Other cleaning services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '81300',
    description: 'Landscape service activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82110',
    description: 'Combined office administrative service activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82190',
    description:
      'Photocopying, document preparation and other specialised office support activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82200',
    description: 'Activities of call centres',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82301',
    description: 'Activities of exhibition and fair organisers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82302',
    description: 'Activities of conference organisers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82911',
    description: 'Activities of collection agencies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82912',
    description: 'Activities of credit bureaus',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82920',
    description: 'Packaging activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '82990',
    description:
      'Other business support service activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84110',
    description: 'General public administration activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84120',
    description:
      'Regulation of health care, education, cultural and other social services, not incl. social security',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84130',
    description:
      'Regulation of and contribution to more efficient operation of businesses',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84210',
    description: 'Foreign affairs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84220',
    description: 'Defence activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84230',
    description: 'Justice and judicial activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84240',
    description: 'Public order and safety activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84250',
    description: 'Fire service activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '84300',
    description: 'Compulsory social security activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85100',
    description: 'Pre-primary education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85200',
    description: 'Primary education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85310',
    description: 'General secondary education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85320',
    description: 'Technical and vocational secondary education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85410',
    description: 'Post-secondary non-tertiary education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85421',
    description: 'First-degree level higher education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85422',
    description: 'Post-graduate level higher education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85510',
    description: 'Sports and recreation education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85520',
    description: 'Cultural education',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85530',
    description: 'Driving school activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85590',
    description: 'Other education not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '85600',
    description: 'Educational support services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '86101',
    description: 'Hospital activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '86102',
    description: 'Medical nursing home activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '86210',
    description: 'General medical practice activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '86220',
    description: 'Specialists medical practice activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '86230',
    description: 'Dental practice activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '86900',
    description: 'Other human health activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '87100',
    description: 'Residential nursing care facilities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '87200',
    description:
      'Residential care activities for learning difficulties, mental health and substance abuse',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '87300',
    description: 'Residential care activities for the elderly and disabled',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '87900',
    description: 'Other residential care activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '88100',
    description:
      'Social work activities without accommodation for the elderly and disabled',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '88910',
    description: 'Child day-care activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '88990',
    description:
      'Other social work activities without accommodation not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '90010',
    description: 'Performing arts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '90020',
    description: 'Support activities to performing arts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '90030',
    description: 'Artistic creation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '90040',
    description: 'Operation of arts facilities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '91011',
    description: 'Library activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '91012',
    description: 'Archives activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '91020',
    description: 'Museums activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '91030',
    description:
      'Operation of historical sites and buildings and similar visitor attractions',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '91040',
    description:
      'Botanical and zoological gardens and nature reserves activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '92000',
    description: 'Gambling and betting activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '93110',
    description: 'Operation of sports facilities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '93120',
    description: 'Activities of sport clubs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '93130',
    description: 'Fitness facilities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '93191',
    description: 'Activities of racehorse owners',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '93199',
    description: 'Other sports activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '93210',
    description: 'Activities of amusement parks and theme parks',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '93290',
    description:
      'Other amusement and recreation activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '94110',
    description:
      'Activities of business and employers membership organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '94120',
    description: 'Activities of professional membership organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '94200',
    description: 'Activities of trade unions',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '94910',
    description: 'Activities of religious organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '94920',
    description: 'Activities of political organisations',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '94990',
    description:
      'Activities of other membership organisations not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '95110',
    description: 'Repair of computers and peripheral equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '95120',
    description: 'Repair of communication equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '95210',
    description: 'Repair of consumer electronics',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '95220',
    description: 'Repair of household appliances and home and garden equipment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '95230',
    description: 'Repair of footwear and leather goods',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '95240',
    description: 'Repair of furniture and home furnishings',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '95250',
    description: 'Repair of watches, clocks and jewellery',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '95290',
    description:
      'Repair of personal and household goods not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '96010',
    description: 'Washing and (dry-)cleaning of textile and fur products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '96020',
    description: 'Hairdressing and other beauty treatment',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '96030',
    description: 'Funeral and related activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '96040',
    description: 'Physical well-being activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '96090',
    description: 'Other service activities not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '97000',
    description: 'Activities of households as employers of domestic personnel',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '98000',
    description: 'Residents property management',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '98100',
    description:
      'Undifferentiated goods-producing activities of private households for own use',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '98200',
    description:
      'Undifferentiated service-producing activities of private households for own use',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '99000',
    description: 'Activities of extraterritorial organisations and bodies',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '99999',
    description: 'Dormant Company',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0111',
    description: 'Grow cereals & other crops',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0112',
    description: 'Grow vegetables & nursery products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0113',
    description: 'Grow fruit, nuts, beverage & spice crops',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0121',
    description: 'Farming of cattle, dairy farming',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0122',
    description: 'Farm sheep, goats, horses, etc.',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0123',
    description: 'Farming of swine',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0124',
    description: 'Farming of poultry',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0125',
    description: 'Other farming of animals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0130',
    description: 'Crops combined with animals, mixed farms',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0141',
    description: 'Agricultural service activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0142',
    description: 'Animal husbandry services, not vets',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0150',
    description: 'Hunting and game rearing inc. services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0201',
    description: 'Forestry & logging',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0202',
    description: 'Forestry & logging related services',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0501',
    description: 'Fishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '0502',
    description: 'Operation of fish hatcheries & farms',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01110',
    description:
      'Growing of cereals (except rice), leguminous crops and oil seeds',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01120',
    description: 'Growing of rice',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01130',
    description: 'Growing of vegetables and melons, roots and tubers',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01140',
    description: 'Growing of sugar cane',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01150',
    description: 'Growing of tobacco',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01160',
    description: 'Growing of fibre crops',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01190',
    description: 'Growing of other non-perennial crops',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01210',
    description: 'Growing of grapes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01220',
    description: 'Growing of tropical and subtropical fruits',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01230',
    description: 'Growing of citrus fruits',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01240',
    description: 'Growing of pome fruits and stone fruits',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01250',
    description: 'Growing of other tree and bush fruits and nuts',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01260',
    description: 'Growing of oleaginous fruits',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01270',
    description: 'Growing of beverage crops',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01280',
    description: 'Growing of spices, aromatic, drug and pharmaceutical crops',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01290',
    description: 'Growing of other perennial crops',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01300',
    description: 'Plant propagation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01410',
    description: 'Raising of dairy cattle',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01420',
    description: 'Raising of other cattle and buffaloes',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01430',
    description: 'Raising of horses and other equines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01440',
    description: 'Raising of camels and camelids',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01450',
    description: 'Raising of sheep and  goats',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01460',
    description: 'Raising of swine/pigs',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01470',
    description: 'Raising of poultry',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01490',
    description: 'Raising of other animals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01500',
    description: 'Mixed farming',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01610',
    description: 'Support activities for crop production',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01621',
    description: 'Farm animal boarding and care',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01629',
    description:
      'Support activities for animal production (other than farm animal boarding and care) not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01630',
    description: 'Post-harvest crop activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01640',
    description: 'Seed processing for propagation',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '01700',
    description: 'Hunting, trapping and related service activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '02100',
    description: 'Silviculture and other forestry activities',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '02200',
    description: 'Logging',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '02300',
    description: 'Gathering of wild growing non-wood products',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '02400',
    description: 'Support services to forestry',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '03110',
    description: 'Marine fishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '03120',
    description: 'Freshwater fishing',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '03210',
    description: 'Marine aquaculture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '03220',
    description: 'Freshwater aquaculture',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '05101',
    description: 'Deep coal mines',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '05102',
    description: 'Open cast coal working',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '05200',
    description: 'Mining of lignite',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '06100',
    description: 'Extraction of crude petroleum',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '06200',
    description: 'Extraction of natural gas',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '07100',
    description: 'Mining of iron ores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '07210',
    description: 'Mining of uranium and thorium ores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '07290',
    description: 'Mining of other non-ferrous metal ores',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '08110',
    description:
      'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '08120',
    description:
      'Operation of gravel and sand pits; mining of clays and kaolin',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '08910',
    description: 'Mining of chemical and fertiliser minerals',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '08920',
    description: 'Extraction of peat',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '08930',
    description: 'Extraction of salt',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '08990',
    description: 'Other mining and quarrying not elsewhere classified',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '09100',
    description: 'Support activities for petroleum and natural gas extraction',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
  {
    code: '09900',
    description: 'Support activities for other mining and quarrying',
    type: CompanyClassificationTypeEnum.SIC_UK,
  },
];

export default sicClassifications;
