import { Building2 } from 'lucide-react';

import { BaseEmptyStateIllustrationIcon } from './base-icon';
import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationAffiliated = (): JSX.Element => {
  const { stroke } = useIllustrationTokens();

  return (
    <BaseEmptyStateIllustrationIcon>
      <Building2 stroke={stroke} size="16" x="212" y="49" />
    </BaseEmptyStateIllustrationIcon>
  );
};
