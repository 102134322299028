import { uniqBy } from 'lodash';
import { DeepPartial } from 'ts-essentials';

import { ColumnFilter, ColumnSort, TableState } from '@tanstack/react-table';

const dateFormat =
  // regex for strict, full date time, including milliseconds
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

function jsonDateReviver(key: unknown, value: unknown) {
  // Must parse date string to Date instance
  if (typeof value === 'string' && dateFormat.test(value)) {
    // @TODO - E-821 - Better encoding and decoding for sort/filters param in UrlSearchParam
    return new Date(value);
  }

  return value;
}

function serializeArray(value?: unknown[]): string {
  if (!value?.length) return '[]';
  // @TODO - E-821 - Better encoding for sort/filters param in UrlSearchParam
  return JSON.stringify(value);
}

function deserializeArrayOrObject<T>(value?: string): T[] | undefined {
  if (value) {
    try {
      // @TODO - E-821 - Better encoding for sort/filters param in UrlSearchParam
      return JSON.parse(value, jsonDateReviver);
    } catch (e) {
      return undefined;
    }
  } else {
    return undefined;
  }
}

function serializeNumber(number: number): string {
  return number.toFixed(0);
}

function deserializeNumber(param: string | undefined): number | undefined {
  const parsed = param ? parseInt(param, 10) : Number.NaN;

  return !isNaN(parsed) ? parsed : undefined;
}

export type SerializedTableState<
  Target extends 'query-params' | 'local-storage',
> = Target extends 'query-params'
  ? {
      // There can be more from global filters
      filters?: string;
      sort?: string;
      search?: string;
      page?: string;
      pageSize?: string;
    }
  : {
      // There can be more from global filters
      filters?: TableState['columnFilters'] | Record<string, unknown>;
      sort?: TableState['sorting'];
      search?: TableState['globalFilter'];
      page?: number;
      pageSize?: number;
    };

export type SerializableTableState = Pick<
  TableState,
  'globalFilter' | 'columnFilters' | 'sorting' | 'pagination'
>;

export type PartialTableState = Partial<
  Pick<SerializableTableState, 'globalFilter' | 'columnFilters' | 'sorting'>
> &
  DeepPartial<Pick<SerializableTableState, 'pagination'>>;

type SerializeTableStateResult<
  Target extends 'query-params' | 'local-storage',
> = Target extends 'query-params'
  ? SerializedTableState<'query-params'>
  : string;

export const serializeTableState = <
  Target extends 'query-params' | 'local-storage',
>(
  state: SerializableTableState,
  target: Target,
): SerializeTableStateResult<Target> => {
  if (target === 'query-params') {
    const { search, filters } = state.globalFilter ?? {};

    const serialized: SerializedTableState<'query-params'> = {
      search: search ?? '',
      filters: filters
        ? JSON.stringify(filters)
        : serializeArray(state.columnFilters),
      sort: serializeArray(state.sorting),
      page: serializeNumber(state.pagination.pageIndex + 1),
      pageSize: serializeNumber(state.pagination.pageSize),
    };
    return serialized as SerializeTableStateResult<Target>;
  } else {
    return JSON.stringify({
      search: state.globalFilter.search ?? '',
      filters: state.globalFilter.filters ?? state.columnFilters,
      sort: state.sorting,
      page: state.pagination.pageIndex + 1,
      pageSize: state.pagination.pageSize,
    }) as SerializeTableStateResult<Target>;
  }
};

export type SerializablePartialTableState = Partial<
  SerializedTableState<'local-storage'>
>;
/**
 * This is useful to generate link to a page with a table and some specific state
 *
 * @param state
 * @returns
 */
export const serializePartialTableState = (
  state: SerializablePartialTableState,
): Partial<SerializedTableState<'query-params'>> => {
  const serialized: Partial<SerializedTableState<'query-params'>> = {};
  if (state.filters) {
    if (Array.isArray(state.filters) && state.filters?.length) {
      serialized.filters = serializeArray(state.filters);
    } else if (
      typeof state.filters === 'object' &&
      Object.keys(state.filters).length
    ) {
      serialized.filters = JSON.stringify(state.filters);
    }
  }
  if (state.sort?.length) {
    serialized.sort = serializeArray(state.sort);
  }
  if (typeof state.search === 'string') {
    serialized.search = state.search;
  }
  if (typeof state.page === 'number') {
    serialized.page = serializeNumber(state.page);
  }
  if (typeof state.pageSize === 'number') {
    serialized.pageSize = serializeNumber(state.pageSize);
  }

  return serialized;
};

export const defaultState: SerializableTableState = {
  columnFilters: [],
  pagination: {
    /**
     * This is called `pageIndex` to follow @tanstack/react-table interface
     * but this is not an index, it's the actual page number (first page is 1)
     */
    pageIndex: 0,
    pageSize: 50,
  },
  globalFilter: { search: '' },
  sorting: [],
};

export const deserializeTableState = (
  serializedState: SerializedTableState<'query-params'> | string | null,
  pageSizes: number[],
): PartialTableState => {
  if (!serializedState) {
    return {};
  }

  // From localStorage JSON.stringify-ed string
  if (typeof serializedState === 'string') {
    try {
      const parsed = JSON.parse(
        serializedState,
        jsonDateReviver,
      ) as SerializedTableState<'local-storage'>;

      if (typeof parsed !== 'object' || !parsed) return defaultState;

      const { filters, sort, search, page, pageSize } = parsed;
      const state: PartialTableState = {
        columnFilters: undefined,
        sorting: sort,
        globalFilter: {},
        pagination: {
          pageIndex:
            typeof page === 'number' && page >= 1 ? page - 1 : undefined,
          pageSize:
            typeof pageSize === 'number' && pageSizes.includes(pageSize)
              ? pageSize
              : undefined,
        },
      };

      if (Array.isArray(filters)) {
        state.columnFilters = filters;
      } else if (filters && typeof filters === 'object') {
        state.globalFilter['filters'] = filters;
      }

      if (typeof search === 'string') {
        state.globalFilter['search'] = search;
      }

      return state;
    } catch {
      return defaultState;
    }
  }

  // From URL query params

  // Make sure pageIndex is positive
  const { filters, sort, search, page, pageSize } = serializedState;

  const deserializedPage = deserializeNumber(page);

  // Make sure pageSize is in the accepted values
  const deserializedPageSize = deserializeNumber(pageSize);

  const state: PartialTableState = {
    columnFilters: undefined,
    sorting: deserializeArrayOrObject<ColumnSort>(sort),

    globalFilter: {},
    pagination: {
      pageIndex:
        typeof deserializedPage === 'number' && deserializedPage >= 1
          ? deserializedPage - 1
          : undefined,
      pageSize:
        typeof deserializedPageSize === 'number' &&
        pageSizes.includes(deserializedPageSize)
          ? deserializedPageSize
          : undefined,
    },
  };

  const deserializedFilters = deserializeArrayOrObject<ColumnFilter>(filters);
  if (Array.isArray(deserializedFilters)) {
    state.columnFilters = deserializedFilters;
  } else if (deserializedFilters && typeof deserializedFilters === 'object') {
    state.globalFilter['filters'] = deserializedFilters;
  }

  if (typeof search === 'string') {
    state.globalFilter['search'] = search;
  }

  return state;
};

export const mergeSerializableTableState = ({
  forceState,
  queryState,
  localStorageState,
  propsState,
}: {
  forceState: PartialTableState;
  queryState: PartialTableState;
  localStorageState: PartialTableState;
  propsState: PartialTableState;
}): SerializableTableState => {
  const result: SerializableTableState = {
    sorting: uniqBy(
      [
        // concat force and other state sort
        ...(forceState?.sorting ?? []),
        ...firstNonEmptyArray(
          queryState.sorting,
          localStorageState.sorting,
          propsState.sorting,
          defaultState.sorting,
        ),
      ],
      'id',
    ),
    columnFilters: uniqBy(
      [
        // concat force and other state filters
        // with force filter first
        ...(forceState.columnFilters ?? []),
        // We actually want the first array. queryState should override localStorageState if set
        ...firstArray(
          queryState.columnFilters,
          localStorageState.columnFilters,
          propsState.columnFilters,
          defaultState.columnFilters,
        ),
      ],
      'id',
    ),

    globalFilter: {
      ...defaultState.globalFilter,
      ...propsState.globalFilter,
      ...localStorageState.globalFilter,
      ...queryState.globalFilter,
      ...forceState.globalFilter,
    },
    pagination: {
      pageIndex:
        forceState.pagination?.pageIndex ??
        queryState.pagination?.pageIndex ??
        localStorageState.pagination?.pageIndex ??
        propsState.pagination?.pageIndex ??
        defaultState.pagination.pageIndex,
      pageSize:
        forceState.pagination?.pageSize ??
        queryState.pagination?.pageSize ??
        localStorageState.pagination?.pageSize ??
        propsState.pagination?.pageSize ??
        defaultState.pagination.pageSize,
    },
  };

  return result;
};

const firstNonEmptyArray = <T>(
  ...arrays: Array<T[] | undefined>
): Exclude<T, undefined>[] => {
  const first = arrays.find((array) => !!array && array?.length > 0);

  return (first ?? []) as Exclude<T, undefined>[];
};

const firstArray = <T>(
  ...arrays: Array<T[] | undefined>
): Exclude<T, undefined>[] => {
  const first = arrays.find((array) => !!array);

  return (first ?? []) as Exclude<T, undefined>[];
};
