import { AlertCircleIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  HStack,
  Icon,
  Skeleton,
  Text,
  useIsMobile,
} from '@dotfile/frontend/shared/design-system';

import { usePendingActions } from '../hooks';

export const PendingActionBadge = ({ entityId }: { entityId?: string }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { count, loading } = usePendingActions(entityId);

  if (count === 0) return null;

  return (
    <Skeleton isLoaded={!loading}>
      <Badge colorScheme="orange">
        <HStack>
          <Icon as={AlertCircleIcon} />
          <Text color="orange.700">
            {isMobile
              ? count
              : t(`checks.header.pending_action`, {
                  ns: 'client-portal',
                  defaultValue: '{{count}} pending actions',
                  count,
                })}
          </Text>
        </HStack>
      </Badge>
    </Skeleton>
  );
};
