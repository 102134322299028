import {
  CompanyClassificationModel,
  CompanyClassificationTypeEnum,
} from '../../models';

const nafClassifications: CompanyClassificationModel[] = [
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'A',
    description: 'AGRICULTURE, SYLVICULTURE ET PÊCHE',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01',
    description: 'Culture et production animale, chasse et services annexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.1',
    description: 'Cultures non permanentes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.11',
    description:
      "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.11Z',
    description:
      "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.12',
    description: 'Culture du riz',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.12Z',
    description: 'Culture du riz',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.13',
    description: 'Culture de légumes, de melons, de racines et de tubercules',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.13Z',
    description: 'Culture de légumes, de melons, de racines et de tubercules',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.14',
    description: 'Culture de la canne à sucre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.14Z',
    description: 'Culture de la canne à sucre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.15',
    description: 'Culture du tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.15Z',
    description: 'Culture du tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.16',
    description: 'Culture de plantes à fibres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.16Z',
    description: 'Culture de plantes à fibres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.19',
    description: 'Autres cultures non permanentes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.19Z',
    description: 'Autres cultures non permanentes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.2',
    description: 'Cultures permanentes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.21',
    description: 'Culture de la vigne',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.21Z',
    description: 'Culture de la vigne',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.22',
    description: 'Culture de fruits tropicaux et subtropicaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.22Z',
    description: 'Culture de fruits tropicaux et subtropicaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.23',
    description: "Culture d'agrumes",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.23Z',
    description: "Culture d'agrumes",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.24',
    description: 'Culture de fruits à pépins et à noyau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.24Z',
    description: 'Culture de fruits à pépins et à noyau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.25',
    description:
      "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.25Z',
    description:
      "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.26',
    description: 'Culture de fruits oléagineux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.26Z',
    description: 'Culture de fruits oléagineux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.27',
    description: 'Culture de plantes à boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.27Z',
    description: 'Culture de plantes à boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.28',
    description:
      'Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.28Z',
    description:
      'Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.29',
    description: 'Autres cultures permanentes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.29Z',
    description: 'Autres cultures permanentes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.3',
    description: 'Reproduction de plantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.30',
    description: 'Reproduction de plantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.30Z',
    description: 'Reproduction de plantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.4',
    description: 'Production animale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.41',
    description: 'Élevage de vaches laitières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.41Z',
    description: 'Élevage de vaches laitières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.42',
    description: "Élevage d'autres bovins et de buffles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.42Z',
    description: "Élevage d'autres bovins et de buffles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.43',
    description: "Élevage de chevaux et d'autres équidés",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.43Z',
    description: "Élevage de chevaux et d'autres équidés",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.44',
    description: "Élevage de chameaux et d'autres camélidés",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.44Z',
    description: "Élevage de chameaux et d'autres camélidés",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.45',
    description: "Élevage d'ovins et de caprins",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.45Z',
    description: "Élevage d'ovins et de caprins",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.46',
    description: 'Élevage de porcins',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.46Z',
    description: 'Élevage de porcins',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.47',
    description: 'Élevage de volailles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.47Z',
    description: 'Élevage de volailles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.49',
    description: "Élevage d'autres animaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.49Z',
    description: "Élevage d'autres animaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.5',
    description: 'Culture et élevage associés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.50',
    description: 'Culture et élevage associés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.50Z',
    description: 'Culture et élevage associés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.6',
    description:
      "Activités de soutien à l'agriculture et traitement primaire des récoltes",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.61',
    description: 'Activités de soutien aux cultures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.61Z',
    description: 'Activités de soutien aux cultures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.62',
    description: 'Activités de soutien à la production animale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.62Z',
    description: 'Activités de soutien à la production animale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.63',
    description: 'Traitement primaire des récoltes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.63Z',
    description: 'Traitement primaire des récoltes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.64',
    description: 'Traitement des semences',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.64Z',
    description: 'Traitement des semences',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.7',
    description: 'Chasse, piégeage et services annexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.70',
    description: 'Chasse, piégeage et services annexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '01.70Z',
    description: 'Chasse, piégeage et services annexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02',
    description: 'Sylviculture et exploitation forestière',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.1',
    description: 'Sylviculture et autres activités forestières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.10',
    description: 'Sylviculture et autres activités forestières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.10Z',
    description: 'Sylviculture et autres activités forestières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.2',
    description: 'Exploitation forestière',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.20',
    description: 'Exploitation forestière',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.20Z',
    description: 'Exploitation forestière',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.3',
    description:
      "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.30',
    description:
      "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.30Z',
    description:
      "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.4',
    description: "Services de soutien à l'exploitation forestière",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.40',
    description: "Services de soutien à l'exploitation forestière",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '02.40Z',
    description: "Services de soutien à l'exploitation forestière",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03',
    description: 'Pêche et aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.1',
    description: 'Pêche',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.11',
    description: 'Pêche en mer',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.11Z',
    description: 'Pêche en mer',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.12',
    description: 'Pêche en eau douce',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.12Z',
    description: 'Pêche en eau douce',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.2',
    description: 'Aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.21',
    description: 'Aquaculture en mer',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.21Z',
    description: 'Aquaculture en mer',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.22',
    description: 'Aquaculture en eau douce',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '03.22Z',
    description: 'Aquaculture en eau douce',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'B',
    description: 'INDUSTRIES EXTRACTIVES',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '05',
    description: 'Extraction de houille et de lignite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '05.1',
    description: 'Extraction de houille',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '05.10',
    description: 'Extraction de houille',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '05.10Z',
    description: 'Extraction de houille',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '05.2',
    description: 'Extraction de lignite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '05.20',
    description: 'Extraction de lignite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '05.20Z',
    description: 'Extraction de lignite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '06',
    description: "Extraction d'hydrocarbures",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '06.1',
    description: 'Extraction de pétrole brut',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '06.10',
    description: 'Extraction de pétrole brut',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '06.10Z',
    description: 'Extraction de pétrole brut',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '06.2',
    description: 'Extraction de gaz naturel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '06.20',
    description: 'Extraction de gaz naturel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '06.20Z',
    description: 'Extraction de gaz naturel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07',
    description: 'Extraction de minerais métalliques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07.1',
    description: 'Extraction de minerais de fer',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07.10',
    description: 'Extraction de minerais de fer',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07.10Z',
    description: 'Extraction de minerais de fer',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07.2',
    description: 'Extraction de minerais de métaux non ferreux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07.21',
    description: "Extraction de minerais d'uranium et de thorium",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07.21Z',
    description: "Extraction de minerais d'uranium et de thorium",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07.29',
    description: "Extraction d'autres minerais de métaux non ferreux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '07.29Z',
    description: "Extraction d'autres minerais de métaux non ferreux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08',
    description: 'Autres industries extractives',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.1',
    description: "Extraction de pierres, de sables et d'argiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.11',
    description:
      "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.11Z',
    description:
      "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.12',
    description:
      'Exploitation de gravières et sablières, extraction d’argiles et de kaolin',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.12Z',
    description:
      'Exploitation de gravières et sablières, extraction d’argiles et de kaolin',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.9',
    description: 'Activités extractives n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.91',
    description: "Extraction des minéraux chimiques et d'engrais minéraux ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.91Z',
    description: "Extraction des minéraux chimiques et d'engrais minéraux ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.92',
    description: 'Extraction de tourbe',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.92Z',
    description: 'Extraction de tourbe',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.93',
    description: 'Production de sel ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.93Z',
    description: 'Production de sel ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.99',
    description: 'Autres activités extractives n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '08.99Z',
    description: 'Autres activités extractives n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '09',
    description: 'Services de soutien aux industries extractives',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '09.1',
    description: "Activités de soutien à l'extraction d'hydrocarbures",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '09.10',
    description: "Activités de soutien à l'extraction d'hydrocarbures",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '09.10Z',
    description: "Activités de soutien à l'extraction d'hydrocarbures",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '09.9',
    description: 'Activités de soutien aux autres industries extractives',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '09.90',
    description: 'Activités de soutien aux autres industries extractives ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '09.90Z',
    description: 'Activités de soutien aux autres industries extractives ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'C',
    description: 'INDUSTRIE MANUFACTURIÈRE',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10',
    description: 'Industries alimentaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.1',
    description:
      'Transformation et conservation de la viande et préparation de produits à base de viande',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.11',
    description: 'Transformation et conservation de la viande de boucherie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.11Z',
    description: 'Transformation et conservation de la viande de boucherie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.12',
    description: 'Transformation et conservation de la viande de volaille',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.12Z',
    description: 'Transformation et conservation de la viande de volaille',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.13',
    description: 'Préparation de produits à base de viande',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.13A',
    description: 'Préparation industrielle de produits à base de viande',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.13B',
    description: 'Charcuterie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.2',
    description:
      'Transformation et conservation de poisson, de crustacés et de mollusques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.20',
    description:
      'Transformation et conservation de poisson, de crustacés et de mollusques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.20Z',
    description:
      'Transformation et conservation de poisson, de crustacés et de mollusques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.3',
    description: 'Transformation et conservation de fruits et légumes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.31',
    description: 'Transformation et conservation de pommes de terre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.31Z',
    description: 'Transformation et conservation de pommes de terre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.32',
    description: 'Préparation de jus de fruits et légumes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.32Z',
    description: 'Préparation de jus de fruits et légumes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.39',
    description: 'Autre transformation et conservation de fruits et légumes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.39A',
    description: 'Autre transformation et conservation de légumes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.39B',
    description: 'Transformation et conservation de fruits',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.4',
    description: 'Fabrication d’huiles et graisses végétales et animales',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.41',
    description: "Fabrication d'huiles et graisses",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.41A',
    description: "Fabrication d'huiles et graisses brutes",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.41B',
    description: "Fabrication d'huiles et graisses raffinées",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.42',
    description: 'Fabrication de margarine et graisses comestibles similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.42Z',
    description: 'Fabrication de margarine et graisses comestibles similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.5',
    description: 'Fabrication de produits laitiers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.51',
    description: 'Exploitation de laiteries et fabrication de fromage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.51A',
    description: 'Fabrication de lait liquide et de produits frais',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.51B',
    description: 'Fabrication de beurre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.51C',
    description: 'Fabrication de fromage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.51D',
    description: "Fabrication d'autres produits laitiers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.52',
    description: 'Fabrication de glaces et sorbets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.52Z',
    description: 'Fabrication de glaces et sorbets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.6',
    description: 'Travail des grains ; fabrication de produits amylacés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.61',
    description: 'Travail des grains',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.61A',
    description: 'Meunerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.61B',
    description: 'Autres activités du travail des grains',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.62',
    description: 'Fabrication de produits amylacés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.62Z',
    description: 'Fabrication de produits amylacés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.7',
    description:
      'Fabrication de produits de boulangerie-pâtisserie et de pâtes alimentaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.71',
    description: 'Fabrication de pain et de pâtisserie fraîche',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.71A',
    description: 'Fabrication industrielle de pain et de pâtisserie fraîche',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.71B',
    description: 'Cuisson de produits de boulangerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.71C',
    description: 'Boulangerie et boulangerie-pâtisserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.71D',
    description: 'Pâtisserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.72',
    description:
      'Fabrication de biscuits, biscottes et pâtisseries de conservation',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.72Z',
    description:
      'Fabrication de biscuits, biscottes et pâtisseries de conservation',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.73',
    description: 'Fabrication de pâtes alimentaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.73Z',
    description: 'Fabrication de pâtes alimentaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.8',
    description: "Fabrication d'autres produits alimentaires",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.81',
    description: 'Fabrication de sucre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.81Z',
    description: 'Fabrication de sucre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.82',
    description: 'Fabrication de cacao, chocolat et de produits de confiserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.82Z',
    description: 'Fabrication de cacao, chocolat et de produits de confiserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.83',
    description: 'Transformation du thé et du café',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.83Z',
    description: 'Transformation du thé et du café',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.84',
    description: 'Fabrication de condiments et assaisonnements',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.84Z',
    description: 'Fabrication de condiments et assaisonnements',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.85',
    description: 'Fabrication de plats préparés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.85Z',
    description: 'Fabrication de plats préparés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.86',
    description: "Fabrication d'aliments homogénéisés et diététiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.86Z',
    description: "Fabrication d'aliments homogénéisés et diététiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.89',
    description: "Fabrication d'autres produits alimentaires n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.89Z',
    description: "Fabrication d'autres produits alimentaires n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.9',
    description: "Fabrication d'aliments pour animaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.91',
    description: "Fabrication d'aliments pour animaux de ferme",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.91Z',
    description: "Fabrication d'aliments pour animaux de ferme",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.92',
    description: "Fabrication d'aliments pour animaux de compagnie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '10.92Z',
    description: "Fabrication d'aliments pour animaux de compagnie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11',
    description: 'Fabrication de boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.0',
    description: 'Fabrication de boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.01',
    description: 'Production de boissons alcooliques distillées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.01Z',
    description: 'Production de boissons alcooliques distillées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.02',
    description: 'Production de vin (de raisin)',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.02A',
    description: 'Fabrication de vins effervescents',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.02B',
    description: 'Vinification',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.03',
    description: 'Fabrication de cidre et de vins de fruits  ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.03Z',
    description: 'Fabrication de cidre et de vins de fruits ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.04',
    description: "Production d'autres boissons fermentées non distillées",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.04Z',
    description: "Production d'autres boissons fermentées non distillées",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.05',
    description: 'Fabrication de bière',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.05Z',
    description: 'Fabrication de bière',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.06',
    description: 'Fabrication de malt',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.06Z',
    description: 'Fabrication de malt',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.07',
    description:
      'Industrie des eaux minérales et autres eaux embouteillées et des boissons rafraîchissantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.07A',
    description: 'Industrie des eaux de table',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '11.07B',
    description: 'Production de boissons rafraîchissantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '12',
    description: 'Fabrication de produits à base de tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '12.0',
    description: 'Fabrication de produits à base de tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '12.00',
    description: 'Fabrication de produits à base de tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '12.00Z',
    description: 'Fabrication de produits à base de tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13',
    description: 'Fabrication de textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.1',
    description: 'Préparation de fibres textiles et filature',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.10',
    description: 'Préparation de fibres textiles et filature',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.10Z',
    description: 'Préparation de fibres textiles et filature',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.2',
    description: 'Tissage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.20',
    description: 'Tissage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.20Z',
    description: 'Tissage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.3',
    description: 'Ennoblissement textile',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.30',
    description: 'Ennoblissement textile',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.30Z',
    description: 'Ennoblissement textile',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.9',
    description: "Fabrication d'autres textiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.91',
    description: "Fabrication d'étoffes à mailles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.91Z',
    description: "Fabrication d'étoffes à mailles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.92',
    description: "Fabrication d'articles textiles, sauf habillement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.92Z',
    description: "Fabrication d'articles textiles, sauf habillement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.93',
    description: 'Fabrication de tapis et moquettes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.93Z',
    description: 'Fabrication de tapis et moquettes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.94',
    description: 'Fabrication de ficelles, cordes et filets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.94Z',
    description: 'Fabrication de ficelles, cordes et filets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.95',
    description: 'Fabrication de non-tissés, sauf habillement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.95Z',
    description: 'Fabrication de non-tissés, sauf habillement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.96',
    description: "Fabrication d'autres textiles techniques et industriels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.96Z',
    description: "Fabrication d'autres textiles techniques et industriels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.99',
    description: "Fabrication d'autres textiles n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '13.99Z',
    description: "Fabrication d'autres textiles n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14',
    description: "Industrie de l'habillement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.1',
    description: "Fabrication de vêtements, autres qu'en fourrure",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.11',
    description: 'Fabrication de vêtements en cuir',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.11Z',
    description: 'Fabrication de vêtements en cuir',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.12',
    description: 'Fabrication de vêtements de travail',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.12Z',
    description: 'Fabrication de vêtements de travail',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.13',
    description: 'Fabrication de vêtements de dessus',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.13Z',
    description: 'Fabrication de vêtements de dessus',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.14',
    description: 'Fabrication de vêtements de dessous',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.14Z',
    description: 'Fabrication de vêtements de dessous',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.19',
    description: "Fabrication d'autres vêtements et accessoires",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.19Z',
    description: "Fabrication d'autres vêtements et accessoires",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.2',
    description: "Fabrication d'articles en fourrure",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.20',
    description: "Fabrication d'articles en fourrure",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.20Z',
    description: "Fabrication d'articles en fourrure",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.3',
    description: "Fabrication d'articles à mailles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.31',
    description: "Fabrication d'articles chaussants à mailles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.31Z',
    description: "Fabrication d'articles chaussants à mailles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.39',
    description: "Fabrication d'autres articles à mailles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '14.39Z',
    description: "Fabrication d'autres articles à mailles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15',
    description: 'Industrie du cuir et de la chaussure',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15.1',
    description:
      "Apprêt et tannage des cuirs ; préparation et teinture des fourrures ; fabrication d'articles de voyage, de maroquinerie et de sellerie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15.11',
    description:
      'Apprêt et tannage des cuirs ; préparation et teinture des fourrures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15.11Z',
    description:
      'Apprêt et tannage des cuirs ; préparation et teinture des fourrures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15.12',
    description:
      "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15.12Z',
    description:
      "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15.2',
    description: 'Fabrication de chaussures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15.20',
    description: 'Fabrication de chaussures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '15.20Z',
    description: 'Fabrication de chaussures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16',
    description:
      "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles ; fabrication d’articles en vannerie et sparterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.1',
    description: 'Sciage et rabotage du bois',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.10',
    description: 'Sciage et rabotage du bois',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.10A',
    description: 'Sciage et rabotage du bois, hors imprégnation',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.10B',
    description: 'Imprégnation du bois',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.2',
    description: "Fabrication d'articles en bois, liège, vannerie et sparterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.21',
    description: 'Fabrication de placage et de panneaux de bois',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.21Z',
    description: 'Fabrication de placage et de panneaux de bois',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.22',
    description: 'Fabrication de parquets assemblés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.22Z',
    description: 'Fabrication de parquets assemblés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.23',
    description: "Fabrication de charpentes et d'autres menuiseries",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.23Z',
    description: "Fabrication de charpentes et d'autres menuiseries",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.24',
    description: "Fabrication d'emballages en bois",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.24Z',
    description: "Fabrication d'emballages en bois",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.29',
    description:
      "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '16.29Z',
    description:
      "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17',
    description: 'Industrie du papier et du carton',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.1',
    description: 'Fabrication de pâte à papier, de papier et de carton',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.11',
    description: 'Fabrication de pâte à papier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.11Z',
    description: 'Fabrication de pâte à papier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.12',
    description: 'Fabrication de papier et de carton',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.12Z',
    description: 'Fabrication de papier et de carton',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.2',
    description: "Fabrication d'articles en papier ou en carton",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.21',
    description:
      "Fabrication de papier et carton ondulés et d'emballages en papier ou en carton",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.21A',
    description: 'Fabrication de carton ondulé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.21B',
    description: 'Fabrication de cartonnages ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.21C',
    description: "Fabrication d'emballages en papier",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.22',
    description:
      "Fabrication d'articles en papier à usage sanitaire ou domestique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.22Z',
    description:
      "Fabrication d'articles en papier à usage sanitaire ou domestique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.23',
    description: "Fabrication d'articles de papeterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.23Z',
    description: "Fabrication d'articles de papeterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.24',
    description: 'Fabrication de papiers peints',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.24Z',
    description: 'Fabrication de papiers peints',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.29',
    description: "Fabrication d'autres articles en papier ou en carton",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '17.29Z',
    description: "Fabrication d'autres articles en papier ou en carton",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18',
    description: "Imprimerie et reproduction d'enregistrements",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.1',
    description: 'Imprimerie et services annexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.11',
    description: 'Imprimerie de journaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.11Z',
    description: 'Imprimerie de journaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.12',
    description: 'Autre imprimerie (labeur)',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.12Z',
    description: 'Autre imprimerie (labeur)',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.13',
    description: 'Activités de pré-presse ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.13Z',
    description: 'Activités de pré-presse ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.14',
    description: 'Reliure et activités connexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.14Z',
    description: 'Reliure et activités connexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.2',
    description: "Reproduction d'enregistrements",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.20',
    description: "Reproduction d'enregistrements",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '18.20Z',
    description: "Reproduction d'enregistrements",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '19',
    description: 'Cokéfaction et raffinage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '19.1',
    description: 'Cokéfaction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '19.10',
    description: 'Cokéfaction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '19.10Z',
    description: 'Cokéfaction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '19.2',
    description: 'Raffinage du pétrole',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '19.20',
    description: 'Raffinage du pétrole',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '19.20Z',
    description: 'Raffinage du pétrole',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20',
    description: 'Industrie chimique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.1',
    description:
      "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.11',
    description: 'Fabrication de gaz industriels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.11Z',
    description: 'Fabrication de gaz industriels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.12',
    description: 'Fabrication de colorants et de pigments',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.12Z',
    description: 'Fabrication de colorants et de pigments',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.13',
    description: "Fabrication d'autres produits chimiques inorganiques de base",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.13A',
    description: 'Enrichissement et  retraitement de matières nucléaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.13B',
    description:
      "Fabrication d'autres produits chimiques inorganiques de base n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.14',
    description: "Fabrication d'autres produits chimiques organiques de base",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.14Z',
    description: "Fabrication d'autres produits chimiques organiques de base",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.15',
    description: "Fabrication de produits azotés et d'engrais",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.15Z',
    description: "Fabrication de produits azotés et d'engrais",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.16',
    description: 'Fabrication de matières plastiques de base',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.16Z',
    description: 'Fabrication de matières plastiques de base',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.17',
    description: 'Fabrication de caoutchouc synthétique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.17Z',
    description: 'Fabrication de caoutchouc synthétique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.2',
    description:
      'Fabrication de pesticides et d’autres produits agrochimiques ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.20',
    description: 'Fabrication de pesticides et d’autres produits agrochimiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.20Z',
    description: 'Fabrication de pesticides et d’autres produits agrochimiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.3',
    description: 'Fabrication de peintures, vernis, encres et mastics',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.30',
    description: 'Fabrication de peintures, vernis, encres et mastics',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.30Z',
    description: 'Fabrication de peintures, vernis, encres et mastics',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.4',
    description: "Fabrication de savons, de produits d'entretien et de parfums",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.41',
    description: "Fabrication de savons, détergents et produits d'entretien",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.41Z',
    description: "Fabrication de savons, détergents et produits d'entretien",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.42',
    description: 'Fabrication de parfums et de produits pour la toilette',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.42Z',
    description: 'Fabrication de parfums et de produits pour la toilette',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.5',
    description: "Fabrication d'autres produits chimiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.51',
    description: 'Fabrication de produits explosifs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.51Z',
    description: 'Fabrication de produits explosifs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.52',
    description: 'Fabrication de colles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.52Z',
    description: 'Fabrication de colles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.53',
    description: "Fabrication d'huiles essentielles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.53Z',
    description: "Fabrication d'huiles essentielles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.59',
    description: "Fabrication d'autres produits chimiques n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.59Z',
    description: "Fabrication d'autres produits chimiques n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.6',
    description: 'Fabrication de fibres artificielles ou synthétiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.60',
    description: 'Fabrication de fibres artificielles ou synthétiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '20.60Z',
    description: 'Fabrication de fibres artificielles ou synthétiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '21',
    description: 'Industrie pharmaceutique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '21.1',
    description: 'Fabrication de produits pharmaceutiques de base',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '21.10',
    description: 'Fabrication de produits pharmaceutiques de base',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '21.10Z',
    description: 'Fabrication de produits pharmaceutiques de base',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '21.2',
    description: 'Fabrication de préparations pharmaceutiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '21.20',
    description: 'Fabrication de préparations pharmaceutiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '21.20Z',
    description: 'Fabrication de préparations pharmaceutiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22',
    description: 'Fabrication de produits en caoutchouc et en plastique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.1',
    description: 'Fabrication de produits en caoutchouc',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.11',
    description: 'Fabrication et rechapage de pneumatiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.11Z',
    description: 'Fabrication et rechapage de pneumatiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.19',
    description: "Fabrication d'autres articles en caoutchouc",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.19Z',
    description: "Fabrication d'autres articles en caoutchouc",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.2',
    description: 'Fabrication  de produits en plastique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.21',
    description:
      'Fabrication de plaques, feuilles, tubes et profilés en matières plastiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.21Z',
    description:
      'Fabrication de plaques, feuilles, tubes et profilés en matières plastiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.22',
    description: "Fabrication d'emballages en matières plastiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.22Z',
    description: "Fabrication d'emballages en matières plastiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.23',
    description:
      "Fabrication d'éléments en matières plastiques pour la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.23Z',
    description:
      "Fabrication d'éléments en matières plastiques pour la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.29',
    description: "Fabrication d'autres articles en matières plastiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.29A',
    description:
      'Fabrication de pièces techniques à base de matières plastiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '22.29B',
    description:
      'Fabrication de produits de consommation courante en matières plastiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23',
    description: "Fabrication d'autres produits minéraux non métalliques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.1',
    description: "Fabrication de verre et d'articles en verre",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.11',
    description: 'Fabrication de verre plat',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.11Z',
    description: 'Fabrication de verre plat',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.12',
    description: 'Façonnage et transformation du verre plat',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.12Z',
    description: 'Façonnage et transformation du verre plat',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.13',
    description: 'Fabrication de verre creux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.13Z',
    description: 'Fabrication de verre creux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.14',
    description: 'Fabrication de fibres de verre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.14Z',
    description: 'Fabrication de fibres de verre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.19',
    description:
      "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.19Z',
    description:
      "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.2',
    description: 'Fabrication de produits réfractaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.20',
    description: 'Fabrication de produits réfractaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.20Z',
    description: 'Fabrication de produits réfractaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.3',
    description: 'Fabrication de matériaux de construction en terre cuite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.31',
    description: 'Fabrication de carreaux en céramique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.31Z',
    description: 'Fabrication de carreaux en céramique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.32',
    description:
      'Fabrication de briques, tuiles et produits de construction, en terre cuite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.32Z',
    description:
      'Fabrication de briques, tuiles et produits de construction, en terre cuite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.4',
    description: "Fabrication d'autres produits en céramique et en porcelaine ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.41',
    description:
      "Fabrication d'articles céramiques à usage domestique ou ornemental",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.41Z',
    description:
      "Fabrication d'articles céramiques à usage domestique ou ornemental",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.42',
    description: "Fabrication d'appareils sanitaires en céramique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.42Z',
    description: "Fabrication d'appareils sanitaires en céramique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.43',
    description: "Fabrication d'isolateurs et pièces isolantes en céramique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.43Z',
    description: "Fabrication d'isolateurs et pièces isolantes en céramique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.44',
    description: "Fabrication d'autres produits céramiques à usage technique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.44Z',
    description: "Fabrication d'autres produits céramiques à usage technique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.49',
    description: "Fabrication d'autres produits céramiques ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.49Z',
    description: "Fabrication d'autres produits céramiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.5',
    description: 'Fabrication de ciment, chaux et plâtre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.51',
    description: 'Fabrication de ciment',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.51Z',
    description: 'Fabrication de ciment',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.52',
    description: 'Fabrication de chaux et plâtre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.52Z',
    description: 'Fabrication de chaux et plâtre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.6',
    description: "Fabrication d'ouvrages en béton, en ciment ou en plâtre",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.61',
    description: "Fabrication d'éléments en béton pour la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.61Z',
    description: "Fabrication d'éléments en béton pour la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.62',
    description: "Fabrication d'éléments en plâtre pour la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.62Z',
    description: "Fabrication d'éléments en plâtre pour la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.63',
    description: "Fabrication de béton prêt à l'emploi",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.63Z',
    description: "Fabrication de béton prêt à l'emploi",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.64',
    description: 'Fabrication de mortiers et bétons secs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.64Z',
    description: 'Fabrication de mortiers et bétons secs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.65',
    description: "Fabrication d'ouvrages en fibre-ciment",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.65Z',
    description: "Fabrication d'ouvrages en fibre-ciment",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.69',
    description:
      "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.69Z',
    description:
      "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.7',
    description: 'Taille, façonnage et finissage de pierres ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.70',
    description: 'Taille, façonnage et finissage de pierres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.70Z',
    description: 'Taille, façonnage et finissage de pierres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.9',
    description:
      'Fabrication de produits abrasifs et de produits minéraux non métalliques n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.91',
    description: 'Fabrication de produits abrasifs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.91Z',
    description: 'Fabrication de produits abrasifs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.99',
    description:
      "Fabrication d'autres produits minéraux non métalliques n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '23.99Z',
    description:
      "Fabrication d'autres produits minéraux non métalliques n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24',
    description: 'Métallurgie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.1',
    description: 'Sidérurgie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.10',
    description: 'Sidérurgie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.10Z',
    description: 'Sidérurgie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.2',
    description:
      'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.20',
    description:
      'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.20Z',
    description:
      'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.3',
    description:
      "Fabrication d'autres produits de première transformation de l'acier",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.31',
    description: 'Étirage à froid de barres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.31Z',
    description: 'Étirage à froid de barres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.32',
    description: 'Laminage à froid de feuillards',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.32Z',
    description: 'Laminage à froid de feuillards',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.33',
    description: 'Profilage à froid par formage ou pliage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.33Z',
    description: 'Profilage à froid par formage ou pliage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.34',
    description: 'Tréfilage à froid',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.34Z',
    description: 'Tréfilage à froid',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.4',
    description: "Production de métaux précieux et d'autres métaux non ferreux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.41',
    description: 'Production de métaux précieux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.41Z',
    description: 'Production de métaux précieux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.42',
    description: "Métallurgie de l'aluminium",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.42Z',
    description: "Métallurgie de l'aluminium",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.43',
    description: "Métallurgie du plomb, du zinc ou de l'étain",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.43Z',
    description: "Métallurgie du plomb, du zinc ou de l'étain",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.44',
    description: 'Métallurgie du cuivre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.44Z',
    description: 'Métallurgie du cuivre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.45',
    description: 'Métallurgie des autres métaux non ferreux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.45Z',
    description: 'Métallurgie des autres métaux non ferreux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.46',
    description: 'Élaboration et transformation de matières nucléaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.46Z',
    description: 'Élaboration et transformation de matières nucléaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.5',
    description: 'Fonderie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.51',
    description: 'Fonderie de fonte',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.51Z',
    description: 'Fonderie de fonte',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.52',
    description: "Fonderie d'acier",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.52Z',
    description: "Fonderie d'acier",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.53',
    description: 'Fonderie de métaux légers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.53Z',
    description: 'Fonderie de métaux légers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.54',
    description: "Fonderie d'autres métaux non ferreux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '24.54Z',
    description: "Fonderie d'autres métaux non ferreux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25',
    description:
      'Fabrication de produits métalliques, à l’exception des machines et des équipements',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.1',
    description: "Fabrication d'éléments en métal pour la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.11',
    description:
      'Fabrication de structures métalliques et de parties de structures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.11Z',
    description:
      'Fabrication de structures métalliques et de parties de structures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.12',
    description: 'Fabrication de portes et fenêtres en métal',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.12Z',
    description: 'Fabrication de portes et fenêtres en métal',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.2',
    description:
      'Fabrication de réservoirs, citernes et conteneurs métalliques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.21',
    description:
      'Fabrication de radiateurs et de chaudières pour le chauffage central',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.21Z',
    description:
      'Fabrication de radiateurs et de chaudières pour le chauffage central',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.29',
    description:
      "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.29Z',
    description:
      "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.3',
    description:
      "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.30',
    description:
      "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.30Z',
    description:
      "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.4',
    description: "Fabrication d'armes et de munitions",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.40',
    description: "Fabrication d'armes et de munitions",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.40Z',
    description: "Fabrication d'armes et de munitions",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.5',
    description: 'Forge, emboutissage, estampage ; métallurgie des poudres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.50',
    description: 'Forge, emboutissage, estampage ; métallurgie des poudres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.50A',
    description: 'Forge, estampage, matriçage ; métallurgie des poudres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.50B',
    description: 'Découpage, emboutissage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.6',
    description: 'Traitement et revêtement des métaux ; usinage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.61',
    description: 'Traitement et revêtement des métaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.61Z',
    description: 'Traitement et revêtement des métaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.62',
    description: 'Usinage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.62A',
    description: 'Décolletage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.62B',
    description: 'Mécanique industrielle',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.7',
    description: "Fabrication de coutellerie, d'outillage et de quincaillerie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.71',
    description: 'Fabrication de coutellerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.71Z',
    description: 'Fabrication de coutellerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.72',
    description: 'Fabrication de serrures et de ferrures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.72Z',
    description: 'Fabrication de serrures et de ferrures',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.73',
    description: "Fabrication d'outillage",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.73A',
    description: 'Fabrication de moules et modèles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.73B',
    description: "Fabrication d'autres outillages",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.9',
    description: "Fabrication d'autres ouvrages en métaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.91',
    description: 'Fabrication de fûts et emballages métalliques similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.91Z',
    description: 'Fabrication de fûts et emballages métalliques similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.92',
    description: "Fabrication d'emballages métalliques légers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.92Z',
    description: "Fabrication d'emballages métalliques légers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.93',
    description:
      "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.93Z',
    description:
      "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.94',
    description: 'Fabrication de vis et de boulons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.94Z',
    description: 'Fabrication de vis et de boulons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.99',
    description: "Fabrication d'autres produits métalliques n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.99A',
    description: "Fabrication d'articles métalliques ménagers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '25.99B',
    description: "Fabrication d'autres articles métalliques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26',
    description:
      'Fabrication de produits informatiques, électroniques et optiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.1',
    description: 'Fabrication de composants et cartes électroniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.11',
    description: 'Fabrication de composants électroniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.11Z',
    description: 'Fabrication de composants électroniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.12',
    description: 'Fabrication de cartes électroniques assemblées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.12Z',
    description: 'Fabrication de cartes électroniques assemblées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.2',
    description: "Fabrication d'ordinateurs et d'équipements périphériques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.20',
    description: "Fabrication d'ordinateurs et d'équipements périphériques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.20Z',
    description: "Fabrication d'ordinateurs et d'équipements périphériques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.3',
    description: "Fabrication d'équipements de communication",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.30',
    description: "Fabrication d'équipements de communication ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.30Z',
    description: "Fabrication d'équipements de communication ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.4',
    description: 'Fabrication de produits électroniques grand public',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.40',
    description: 'Fabrication de produits électroniques grand public',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.40Z',
    description: 'Fabrication de produits électroniques grand public',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.5',
    description:
      "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation ; horlogerie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.51',
    description:
      "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.51A',
    description: "Fabrication d'équipements d'aide à la navigation",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.51B',
    description: "Fabrication d'instrumentation scientifique et technique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.52',
    description: 'Horlogerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.52Z',
    description: 'Horlogerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.6',
    description:
      "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.60',
    description:
      "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.60Z',
    description:
      "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.7',
    description: 'Fabrication de matériels optique et photographique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.70',
    description: 'Fabrication de matériels optique et photographique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.70Z',
    description: 'Fabrication de matériels optique et photographique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.8',
    description: 'Fabrication de supports magnétiques et optiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.80',
    description: 'Fabrication de supports magnétiques et optiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '26.80Z',
    description: 'Fabrication de supports magnétiques et optiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27',
    description: "Fabrication d'équipements électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.1',
    description:
      'Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.11',
    description:
      'Fabrication de moteurs, génératrices et transformateurs électriques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.11Z',
    description:
      'Fabrication de moteurs, génératrices et transformateurs électriques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.12',
    description:
      'Fabrication de matériel de distribution et de commande électrique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.12Z',
    description:
      'Fabrication de matériel de distribution et de commande électrique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.2',
    description: "Fabrication de piles et d'accumulateurs électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.20',
    description: "Fabrication de piles et d'accumulateurs électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.20Z',
    description: "Fabrication de piles et d'accumulateurs électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.3',
    description:
      "Fabrication de fils et câbles et de matériel d'installation électrique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.31',
    description: 'Fabrication de câbles de fibres optiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.31Z',
    description: 'Fabrication de câbles de fibres optiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.32',
    description:
      "Fabrication d'autres fils et câbles électroniques ou électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.32Z',
    description:
      "Fabrication d'autres fils et câbles électroniques ou électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.33',
    description: "Fabrication de matériel d'installation électrique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.33Z',
    description: "Fabrication de matériel d'installation électrique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.4',
    description: "Fabrication d'appareils d'éclairage électrique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.40',
    description: "Fabrication d'appareils d'éclairage électrique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.40Z',
    description: "Fabrication d'appareils d'éclairage électrique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.5',
    description: "Fabrication d'appareils ménagers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.51',
    description: "Fabrication d'appareils électroménagers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.51Z',
    description: "Fabrication d'appareils électroménagers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.52',
    description: "Fabrication d'appareils ménagers non électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.52Z',
    description: "Fabrication d'appareils ménagers non électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.9',
    description: "Fabrication d'autres matériels électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.90',
    description: "Fabrication d'autres matériels électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '27.90Z',
    description: "Fabrication d'autres matériels électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28',
    description: 'Fabrication de machines et équipements n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.1',
    description: "Fabrication de machines d'usage général",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.11',
    description:
      "Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.11Z',
    description:
      "Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.12',
    description: "Fabrication d'équipements hydrauliques et pneumatiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.12Z',
    description: "Fabrication d'équipements hydrauliques et pneumatiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.13',
    description: "Fabrication d'autres pompes et compresseurs",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.13Z',
    description: "Fabrication d'autres pompes et compresseurs",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.14',
    description: "Fabrication d'autres articles de robinetterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.14Z',
    description: "Fabrication d'autres articles de robinetterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.15',
    description:
      "Fabrication d'engrenages et d'organes mécaniques de transmission",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.15Z',
    description:
      "Fabrication d'engrenages et d'organes mécaniques de transmission",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.2',
    description: "Fabrication d'autres machines d'usage général",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.21',
    description: 'Fabrication de fours et brûleurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.21Z',
    description: 'Fabrication de fours et brûleurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.22',
    description: 'Fabrication de matériel de levage et de manutention',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.22Z',
    description: 'Fabrication de matériel de levage et de manutention',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.23',
    description:
      "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.23Z',
    description:
      "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.24',
    description: "Fabrication d'outillage portatif à moteur incorporé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.24Z',
    description: "Fabrication d'outillage portatif à moteur incorporé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.25',
    description:
      "Fabrication d'équipements aérauliques et frigorifiques industriels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.25Z',
    description:
      "Fabrication d'équipements aérauliques et frigorifiques industriels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.29',
    description: "Fabrication de machines diverses d'usage général",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.29A',
    description:
      "Fabrication d'équipements d'emballage, de conditionnement et de pesage ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.29B',
    description: "Fabrication d'autres machines d'usage général",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.3',
    description: 'Fabrication de machines agricoles et forestières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.30',
    description: 'Fabrication de machines agricoles et forestières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.30Z',
    description: 'Fabrication de machines agricoles et forestières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.4',
    description:
      'Fabrication de machines de formage des métaux et de machines-outils',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.41',
    description: 'Fabrication de machines de formage des métaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.41Z',
    description: 'Fabrication de machines-outils pour le travail des métaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.49',
    description: "Fabrication d'autres machines-outils ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.49Z',
    description: "Fabrication d'autres machines-outils ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.9',
    description: "Fabrication d'autres machines d'usage spécifique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.91',
    description: 'Fabrication de machines pour la métallurgie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.91Z',
    description: 'Fabrication de machines pour la métallurgie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.92',
    description: "Fabrication de machines pour l'extraction ou la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.92Z',
    description: "Fabrication de machines pour l'extraction ou la construction",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.93',
    description: "Fabrication de machines pour l'industrie agro-alimentaire",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.93Z',
    description: "Fabrication de machines pour l'industrie agro-alimentaire",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.94',
    description: 'Fabrication de machines pour les industries textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.94Z',
    description: 'Fabrication de machines pour les industries textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.95',
    description:
      'Fabrication de machines pour les industries du papier et du carton',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.95Z',
    description:
      'Fabrication de machines pour les industries du papier et du carton',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.96',
    description:
      'Fabrication de machines pour le travail du caoutchouc ou des plastiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.96Z',
    description:
      'Fabrication de machines pour le travail du caoutchouc ou des plastiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.99',
    description: "Fabrication d'autres machines d'usage spécifique n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.99A',
    description: "Fabrication de machines d'imprimerie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '28.99B',
    description: "Fabrication d'autres machines spécialisées",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29',
    description: 'Industrie automobile',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.1',
    description: 'Construction de véhicules automobiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.10',
    description: 'Construction de véhicules automobiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.10Z',
    description: 'Construction de véhicules automobiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.2',
    description: 'Fabrication de carrosseries et remorques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.20',
    description: 'Fabrication de carrosseries et remorques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.20Z',
    description: 'Fabrication de carrosseries et remorques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.3',
    description: "Fabrication d'équipements automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.31',
    description:
      "Fabrication d'équipements électriques et électroniques automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.31Z',
    description:
      "Fabrication d'équipements électriques et électroniques automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.32',
    description: "Fabrication d'autres équipements automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '29.32Z',
    description: "Fabrication d'autres équipements automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30',
    description: "Fabrication d'autres matériels de transport",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.1',
    description: 'Construction navale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.11',
    description: 'Construction de navires et de structures flottantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.11Z',
    description: 'Construction de navires et de structures flottantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.12',
    description: 'Construction de bateaux de plaisance',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.12Z',
    description: 'Construction de bateaux de plaisance',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.2',
    description:
      "Construction de locomotives et d'autre matériel ferroviaire roulant",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.20',
    description:
      "Construction de locomotives et d'autre matériel ferroviaire roulant ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.20Z',
    description:
      "Construction de locomotives et d'autre matériel ferroviaire roulant ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.3',
    description: 'Construction aéronautique et spatiale ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.30',
    description: 'Construction aéronautique et spatiale ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.30Z',
    description: 'Construction aéronautique et spatiale ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.4',
    description: 'Construction de véhicules militaires de combat',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.40',
    description: 'Construction de véhicules militaires de combat ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.40Z',
    description: 'Construction de véhicules militaires de combat ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.9',
    description: 'Fabrication de matériels de transport n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.91',
    description: 'Fabrication de motocycles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.91Z',
    description: 'Fabrication de motocycles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.92',
    description: 'Fabrication de bicyclettes et de véhicules pour invalides',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.92Z',
    description: 'Fabrication de bicyclettes et de véhicules pour invalides',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.99',
    description: 'Fabrication d’autres équipements de transport n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '30.99Z',
    description: 'Fabrication d’autres équipements de transport n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31',
    description: 'Fabrication de meubles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.0',
    description: 'Fabrication de meubles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.01',
    description: 'Fabrication de meubles de bureau et de magasin',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.01Z',
    description: 'Fabrication de meubles de bureau et de magasin',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.02',
    description: 'Fabrication de meubles de cuisine ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.02Z',
    description: 'Fabrication de meubles de cuisine ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.03',
    description: 'Fabrication de matelas',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.03Z',
    description: 'Fabrication de matelas',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.09',
    description: "Fabrication d'autres meubles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.09A',
    description: "Fabrication de sièges d'ameublement d'intérieur",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '31.09B',
    description:
      'Fabrication d’autres meubles et industries connexes de l’ameublement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32',
    description: 'Autres industries manufacturières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.1',
    description:
      'Fabrication d’articles de joaillerie, bijouterie et articles similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.11',
    description: 'Frappe de monnaie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.11Z',
    description: 'Frappe de monnaie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.12',
    description: 'Fabrication d’articles de joaillerie et bijouterie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.12Z',
    description: 'Fabrication d’articles de joaillerie et bijouterie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.13',
    description:
      'Fabrication d’articles de bijouterie fantaisie et articles similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.13Z',
    description:
      'Fabrication d’articles de bijouterie fantaisie et articles similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.2',
    description: "Fabrication d'instruments de musique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.20',
    description: "Fabrication d'instruments de musique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.20Z',
    description: "Fabrication d'instruments de musique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.3',
    description: "Fabrication d'articles de sport",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.30',
    description: "Fabrication d'articles de sport",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.30Z',
    description: "Fabrication d'articles de sport",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.4',
    description: 'Fabrication de jeux et jouets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.40',
    description: 'Fabrication de jeux et jouets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.40Z',
    description: 'Fabrication de jeux et jouets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.5',
    description:
      "Fabrication d'instruments et de fournitures à usage médical et dentaire",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.50',
    description:
      "Fabrication d'instruments et de fournitures à usage médical et dentaire ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.50A',
    description: 'Fabrication de matériel médico-chirurgical et dentaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.50B',
    description: 'Fabrication de lunettes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.9',
    description: 'Activités manufacturières n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.91',
    description: 'Fabrication d’articles de brosserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.91Z',
    description: 'Fabrication d’articles de brosserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.99',
    description: 'Autres activités manufacturières n.c.a. ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '32.99Z',
    description: 'Autres activités manufacturières n.c.a. ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33',
    description: "Réparation et installation de machines et d'équipements ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.1',
    description:
      "Réparation d'ouvrages en métaux, de machines et d'équipements",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.11',
    description: "Réparation d'ouvrages en métaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.11Z',
    description: "Réparation d'ouvrages en métaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.12',
    description: 'Réparation de machines et équipements mécaniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.12Z',
    description: 'Réparation de machines et équipements mécaniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.13',
    description: 'Réparation de matériels électroniques et optiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.13Z',
    description: 'Réparation de matériels électroniques et optiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.14',
    description: "Réparation d'équipements électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.14Z',
    description: "Réparation d'équipements électriques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.15',
    description: 'Réparation et maintenance navale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.15Z',
    description: 'Réparation et maintenance navale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.16',
    description: "Réparation et maintenance d'aéronefs et d'engins spatiaux ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.16Z',
    description: "Réparation et maintenance d'aéronefs et d'engins spatiaux ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.17',
    description: "Réparation et maintenance d'autres équipements de transport",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.17Z',
    description: "Réparation et maintenance d'autres équipements de transport",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.19',
    description: "Réparation d'autres équipements",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.19Z',
    description: "Réparation d'autres équipements",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.2',
    description: "Installation de machines et d'équipements industriels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.20',
    description: "Installation de machines et d'équipements industriels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.20A',
    description:
      'Installation de structures métalliques, chaudronnées et de tuyauterie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.20B',
    description: 'Installation de machines et équipements mécaniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.20C',
    description:
      "Conception d'ensemble et assemblage sur site industriel d'équipements de contrôle des processus industriels ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '33.20D',
    description:
      "Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'D',
    description:
      "PRODUCTION ET DISTRIBUTION D'ÉLECTRICITÉ, DE GAZ, DE VAPEUR ET D'AIR CONDITIONNÉ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35',
    description:
      "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.1',
    description: "Production, transport et distribution d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.11',
    description: "Production d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.11Z',
    description: "Production d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.12',
    description: "Transport d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.12Z',
    description: "Transport d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.13',
    description: "Distribution d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.13Z',
    description: "Distribution d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.14',
    description: "Commerce d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.14Z',
    description: "Commerce d'électricité",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.2',
    description: 'Production et distribution de combustibles gazeux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.21',
    description: 'Production de combustibles gazeux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.21Z',
    description: 'Production de combustibles gazeux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.22',
    description: 'Distribution de combustibles gazeux par conduites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.22Z',
    description: 'Distribution de combustibles gazeux par conduites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.23',
    description: 'Commerce de combustibles gazeux par conduites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.23Z',
    description: 'Commerce de combustibles gazeux par conduites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.3',
    description: "Production et distribution de vapeur et d'air conditionné",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.30',
    description: "Production et distribution de vapeur et d'air conditionné ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '35.30Z',
    description: "Production et distribution de vapeur et d'air conditionné ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'E',
    description:
      "PRODUCTION ET DISTRIBUTION D'EAU ; ASSAINISSEMENT, GESTION DES DÉCHETS ET DÉPOLLUTION",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '36',
    description: "Captage, traitement et distribution d'eau ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '36.0',
    description: "Captage, traitement et distribution d'eau",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '36.00',
    description: "Captage, traitement et distribution d'eau",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '36.00Z',
    description: "Captage, traitement et distribution d'eau",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '37',
    description: 'Collecte et traitement des eaux usées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '37.0',
    description: 'Collecte et traitement des eaux usées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '37.00',
    description: 'Collecte et traitement des eaux usées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '37.00Z',
    description: 'Collecte et traitement des eaux usées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38',
    description:
      'Collecte, traitement et élimination des déchets ; récupération',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.1',
    description: 'Collecte des déchets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.11',
    description: 'Collecte des déchets non dangereux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.11Z',
    description: 'Collecte des déchets non dangereux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.12',
    description: 'Collecte des déchets dangereux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.12Z',
    description: 'Collecte des déchets dangereux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.2',
    description: 'Traitement et élimination des déchets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.21',
    description: 'Traitement et élimination des déchets non dangereux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.21Z',
    description: 'Traitement et élimination des déchets non dangereux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.22',
    description: 'Traitement et élimination des déchets dangereux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.22Z',
    description: 'Traitement et élimination des déchets dangereux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.3',
    description: 'Récupération',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.31',
    description: "Démantèlement d'épaves",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.31Z',
    description: "Démantèlement d'épaves",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.32',
    description: 'Récupération de déchets triés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '38.32Z',
    description: 'Récupération de déchets triés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '39',
    description: 'Dépollution et autres services de gestion des déchets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '39.0',
    description: 'Dépollution et autres services de gestion des déchets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '39.00',
    description: 'Dépollution et autres services de gestion des déchets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '39.00Z',
    description: 'Dépollution et autres services de gestion des déchets',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'F',
    description: 'CONSTRUCTION',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41',
    description: 'Construction de bâtiments ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.1',
    description: 'Promotion immobilière',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.10',
    description: 'Promotion immobilière',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.10A',
    description: 'Promotion immobilière de logements',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.10B',
    description: 'Promotion immobilière de bureaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.10C',
    description: "Promotion immobilière d'autres bâtiments",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.10D',
    description: 'Supports juridiques de programmes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.2',
    description: 'Construction de bâtiments résidentiels et non résidentiels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.20',
    description: 'Construction de bâtiments résidentiels et non résidentiels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.20A',
    description: 'Construction de maisons individuelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '41.20B',
    description: "Construction d'autres bâtiments",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42',
    description: 'Génie civil',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.1',
    description: 'Construction de routes et de voies ferrées ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.11',
    description: 'Construction de routes et autoroutes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.11Z',
    description: 'Construction de routes et autoroutes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.12',
    description: 'Construction de voies ferrées de surface et souterraines',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.12Z',
    description: 'Construction de voies ferrées de surface et souterraines',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.13',
    description: 'Construction de ponts et tunnels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.13A',
    description: "Construction d'ouvrages d'art",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.13B',
    description: 'Construction et entretien de tunnels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.2',
    description: 'Construction de réseaux et de lignes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.21',
    description: 'Construction de réseaux pour fluides',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.21Z',
    description: 'Construction de réseaux pour fluides',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.22',
    description: 'Construction de réseaux électriques et de télécommunications',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.22Z',
    description: 'Construction de réseaux électriques et de télécommunications',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.9',
    description: "Construction d'autres ouvrages de génie civil ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.91',
    description: "Construction d'ouvrages maritimes et fluviaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.91Z',
    description: "Construction d'ouvrages maritimes et fluviaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.99',
    description: "Construction d'autres ouvrages de génie civil n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '42.99Z',
    description: "Construction d'autres ouvrages de génie civil n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43',
    description: 'Travaux de construction spécialisés ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.1',
    description: 'Démolition et préparation des sites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.11',
    description: 'Travaux de démolition',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.11Z',
    description: 'Travaux de démolition',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.12',
    description: 'Travaux de préparation des sites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.12A',
    description: 'Travaux de terrassement courants et travaux préparatoires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.12B',
    description: 'Travaux de terrassement spécialisés ou de grande masse',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.13',
    description: 'Forages et sondages',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.13Z',
    description: 'Forages et sondages',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.2',
    description:
      "Travaux d'installation électrique, plomberie et autres travaux d'installation",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.21',
    description: 'Installation électrique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.21A',
    description: "Travaux d'installation électrique dans tous locaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.21B',
    description: "Travaux d'installation électrique sur la voie publique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.22',
    description:
      "Travaux de plomberie et installation de chauffage et de conditionnement d'air",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.22A',
    description: "Travaux d'installation d'eau et de gaz en tous locaux",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.22B',
    description:
      "Travaux d'installation d'équipements thermiques et de climatisation",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.29',
    description: "Autres travaux d'installation",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.29A',
    description: "Travaux d'isolation",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.29B',
    description: "Autres travaux d'installation n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.3',
    description: 'Travaux de finition',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.31',
    description: 'Travaux de plâtrerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.31Z',
    description: 'Travaux de plâtrerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.32',
    description: 'Travaux de menuiserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.32A',
    description: 'Travaux de menuiserie bois et PVC',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.32B',
    description: 'Travaux de menuiserie métallique et serrurerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.32C',
    description: 'Agencement de lieux de vente',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.33',
    description: 'Travaux de revêtement des sols et des murs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.33Z',
    description: 'Travaux de revêtement des sols et des murs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.34',
    description: 'Travaux de peinture et vitrerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.34Z',
    description: 'Travaux de peinture et vitrerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.39',
    description: 'Autres travaux de finition',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.39Z',
    description: 'Autres travaux de finition',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.9',
    description: 'Autres travaux de construction spécialisés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.91',
    description: 'Travaux de couverture',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.91A',
    description: 'Travaux de charpente',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.91B',
    description: 'Travaux de couverture par éléments',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.99',
    description: 'Autres travaux de construction spécialisés n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.99A',
    description: "Travaux d'étanchéification",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.99B',
    description: 'Travaux de montage de structures métalliques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.99C',
    description: 'Travaux de maçonnerie générale et gros œuvre de bâtiment',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.99D',
    description: 'Autres travaux spécialisés de construction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '43.99E',
    description: 'Location avec opérateur de matériel de construction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'G',
    description: "COMMERCE ; RÉPARATION D'AUTOMOBILES ET DE MOTOCYCLES",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45',
    description: "Commerce et réparation d'automobiles et de motocycles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.1',
    description: 'Commerce de véhicules automobiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.11',
    description: 'Commerce de voitures et de véhicules automobiles légers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.11Z',
    description: 'Commerce de voitures et de véhicules automobiles légers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.19',
    description: "Commerce d'autres véhicules automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.19Z',
    description: "Commerce d'autres véhicules automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.2',
    description: 'Entretien et réparation de véhicules automobiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.20',
    description: 'Entretien et réparation de véhicules automobiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.20A',
    description: 'Entretien et réparation de véhicules automobiles légers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.20B',
    description: "Entretien et réparation d'autres véhicules automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.3',
    description: "Commerce d'équipements automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.31',
    description: "Commerce de gros d'équipements automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.31Z',
    description: "Commerce de gros d'équipements automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.32',
    description: "Commerce de détail d'équipements automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.32Z',
    description: "Commerce de détail d'équipements automobiles",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.4',
    description: 'Commerce et réparation de motocycles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.40',
    description: 'Commerce et réparation de motocycles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '45.40Z',
    description: 'Commerce et réparation de motocycles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46',
    description:
      'Commerce de gros, à l’exception des automobiles et des motocycles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.1',
    description: 'Intermédiaires du commerce de gros',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.11',
    description:
      'Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.11Z',
    description:
      'Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.12',
    description:
      'Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.12A',
    description: "Centrales d'achat de carburant",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.12B',
    description:
      'Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.13',
    description:
      'Intermédiaires du commerce en bois et matériaux de construction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.13Z',
    description:
      'Intermédiaires du commerce en bois et matériaux de construction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.14',
    description:
      'Intermédiaires du commerce en machines, équipements industriels, navires et avions',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.14Z',
    description:
      'Intermédiaires du commerce en machines, équipements industriels, navires et avions',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.15',
    description:
      'Intermédiaires du commerce en meubles, articles de ménage et quincaillerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.15Z',
    description:
      'Intermédiaires du commerce en meubles, articles de ménage et quincaillerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.16',
    description:
      'Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.16Z',
    description:
      'Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.17',
    description: 'Intermédiaires du commerce en denrées, boissons et tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.17A',
    description: "Centrales d'achat alimentaires",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.17B',
    description:
      'Autres intermédiaires du commerce en denrées, boissons et tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.18',
    description:
      "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.18Z',
    description:
      "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.19',
    description: 'Intermédiaires du commerce en produits divers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.19A',
    description: "Centrales d'achat non alimentaires",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.19B',
    description: 'Autres intermédiaires du commerce en produits divers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.2',
    description:
      "Commerce de gros de produits agricoles bruts et d'animaux vivants",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.21',
    description:
      "Commerce de gros de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.21Z',
    description:
      "Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.22',
    description: 'Commerce de gros de fleurs et plantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.22Z',
    description:
      'Commerce de gros (commerce interentreprises) de fleurs et plantes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.23',
    description: "Commerce de gros d'animaux vivants",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.23Z',
    description:
      "Commerce de gros (commerce interentreprises) d'animaux vivants",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.24',
    description: 'Commerce de gros de cuirs et peaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.24Z',
    description:
      'Commerce de gros (commerce interentreprises) de cuirs et peaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.3',
    description:
      'Commerce de gros de produits alimentaires, de boissons et de tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.31',
    description: 'Commerce de gros de fruits et légumes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.31Z',
    description:
      'Commerce de gros (commerce interentreprises) de fruits et légumes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.32',
    description: 'Commerce de gros de viandes et de produits à base de viande',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.32A',
    description:
      'Commerce de gros (commerce interentreprises) de viandes de boucherie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.32B',
    description:
      'Commerce de gros (commerce interentreprises) de produits à base de viande',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.32C',
    description:
      'Commerce de gros (commerce interentreprises) de volailles et gibier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.33',
    description:
      'Commerce de gros de produits laitiers, œufs, huiles et matières grasses comestibles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.33Z',
    description:
      'Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.34',
    description: 'Commerce de gros de boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.34Z',
    description: 'Commerce de gros (commerce interentreprises) de boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.35',
    description: 'Commerce de gros de produits à base de tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.35Z',
    description:
      'Commerce de gros (commerce interentreprises) de produits à base de tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.36',
    description: 'Commerce de gros de sucre, chocolat et confiserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.36Z',
    description:
      'Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.37',
    description: 'Commerce de gros de café, thé, cacao et épices',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.37Z',
    description:
      'Commerce de gros (commerce interentreprises) de café, thé, cacao et épices',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.38',
    description:
      "Commerce de gros d'autres produits alimentaires, y compris poissons, crustacés et mollusques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.38A',
    description:
      'Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.38B',
    description:
      'Commerce de gros (commerce interentreprises) alimentaire spécialisé divers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.39',
    description:
      'Commerce de gros non spécialisé de denrées, boissons et tabac',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.39A',
    description:
      'Commerce de gros (commerce interentreprises) de produits surgelés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.39B',
    description:
      'Commerce de gros (commerce interentreprises) alimentaire non spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.4',
    description: 'Commerce de gros de biens domestiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.41',
    description: 'Commerce de gros de textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.41Z',
    description: 'Commerce de gros (commerce interentreprises) de textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.42',
    description: "Commerce de gros d'habillement et de chaussures",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.42Z',
    description:
      "Commerce de gros (commerce interentreprises) d'habillement et de chaussures",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.43',
    description: "Commerce de gros d'appareils électroménagers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.43Z',
    description:
      "Commerce de gros (commerce interentreprises) d'appareils électroménagers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.44',
    description:
      "Commerce de gros de vaisselle, verrerie et produits d'entretien",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.44Z',
    description:
      "Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.45',
    description: 'Commerce de gros de parfumerie et de produits de beauté',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.45Z',
    description:
      'Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.46',
    description: 'Commerce de gros de produits pharmaceutiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.46Z',
    description:
      'Commerce de gros (commerce interentreprises) de produits pharmaceutiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.47',
    description:
      "Commerce de gros de meubles, de tapis et d'appareils d'éclairage ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.47Z',
    description:
      "Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'éclairage ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.48',
    description: "Commerce de gros d'articles d'horlogerie et de bijouterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.48Z',
    description:
      "Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.49',
    description: "Commerce de gros d'autres biens domestiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.49Z',
    description:
      "Commerce de gros (commerce interentreprises) d'autres biens domestiques ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.5',
    description:
      "Commerce de gros d'équipements de l'information et de la communication ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.51',
    description:
      "Commerce de gros d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.51Z',
    description:
      "Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.52',
    description:
      "Commerce de gros de composants et d'équipements électroniques et de télécommunication",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.52Z',
    description:
      "Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.6',
    description: "Commerce de gros d'autres équipements industriels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.61',
    description: 'Commerce de gros de matériel agricole',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.61Z',
    description:
      'Commerce de gros (commerce interentreprises) de matériel agricole',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.62',
    description: 'Commerce de gros de machines-outils',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.62Z',
    description:
      'Commerce de gros (commerce interentreprises) de machines-outils',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.63',
    description:
      "Commerce de gros de machines pour l'extraction, la construction et le génie civil ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.63Z',
    description:
      "Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.64',
    description:
      "Commerce de gros de machines pour l'industrie textile et l'habillement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.64Z',
    description:
      "Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.65',
    description: 'Commerce de gros de mobilier de bureau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.65Z',
    description:
      'Commerce de gros (commerce interentreprises) de mobilier de bureau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.66',
    description: "Commerce de gros d'autres machines et équipements de bureau ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.66Z',
    description:
      "Commerce de gros (commerce interentreprises) d'autres machines et équipements de bureau ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.69',
    description: "Commerce de gros d'autres machines et équipements",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.69A',
    description:
      'Commerce de gros (commerce interentreprises) de matériel électrique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.69B',
    description:
      'Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.69C',
    description:
      'Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.7',
    description: 'Autres commerces de gros spécialisés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.71',
    description: 'Commerce de gros de combustibles et de produits annexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.71Z',
    description:
      'Commerce de gros (commerce interentreprises) de combustibles et de produits annexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.72',
    description: 'Commerce de gros de minerais et métaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.72Z',
    description:
      'Commerce de gros (commerce interentreprises) de minerais et métaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.73',
    description:
      "Commerce de gros de bois, de matériaux de construction et d'appareils sanitaires ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.73A',
    description:
      'Commerce de gros (commerce interentreprises) de bois et de matériaux de construction ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.73B',
    description:
      "Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.74',
    description:
      'Commerce de gros de quincaillerie et fournitures pour plomberie et chauffage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.74A',
    description:
      'Commerce de gros (commerce interentreprises) de quincaillerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.74B',
    description:
      'Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.75',
    description: 'Commerce de gros de produits chimiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.75Z',
    description:
      'Commerce de gros (commerce interentreprises) de produits chimiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.76',
    description: "Commerce de gros d'autres produits intermédiaires",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.76Z',
    description:
      "Commerce de gros (commerce interentreprises) d'autres produits intermédiaires",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.77',
    description: 'Commerce de gros de déchets et débris',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.77Z',
    description:
      'Commerce de gros (commerce interentreprises) de déchets et débris',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.9',
    description: 'Commerce de gros non spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.90',
    description: 'Commerce de gros non spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '46.90Z',
    description: 'Commerce de gros (commerce interentreprises) non spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47',
    description:
      'Commerce de détail, à l’exception des automobiles et des motocycles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.1',
    description: 'Commerce de détail en magasin non spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.11',
    description:
      'Commerce de détail en magasin non spécialisé à prédominance alimentaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.11A',
    description: 'Commerce de détail de produits surgelés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.11B',
    description: "Commerce d'alimentation générale",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.11C',
    description: 'Supérettes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.11D',
    description: 'Supermarchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.11E',
    description: 'Magasins multi-commerces',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.11F',
    description: 'Hypermarchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.19',
    description: 'Autre commerce de détail en magasin non spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.19A',
    description: 'Grands magasins',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.19B',
    description: 'Autres commerces de détail en magasin non spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.2',
    description: 'Commerce de détail alimentaire en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.21',
    description:
      'Commerce de détail de fruits et légumes en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.21Z',
    description:
      'Commerce de détail de fruits et légumes en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.22',
    description:
      'Commerce de détail de viandes et de produits à base de viande en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.22Z',
    description:
      'Commerce de détail de viandes et de produits à base de viande en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.23',
    description:
      'Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.23Z',
    description:
      'Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.24',
    description:
      'Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.24Z',
    description:
      'Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.25',
    description: 'Commerce de détail de boissons en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.25Z',
    description: 'Commerce de détail de boissons en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.26',
    description:
      'Commerce de détail de produits à base de tabac en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.26Z',
    description:
      'Commerce de détail de produits à base de tabac en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.29',
    description:
      'Autres commerces de détail alimentaires en magasin spécialisé ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.29Z',
    description:
      'Autres commerces de détail alimentaires en magasin spécialisé ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.3',
    description: 'Commerce de détail de carburants en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.30',
    description: 'Commerce de détail de carburants en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.30Z',
    description: 'Commerce de détail de carburants en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.4',
    description:
      "Commerce de détail d'équipements de l'information et de la communication en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.41',
    description:
      "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.41Z',
    description:
      "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.42',
    description:
      'Commerce de détail de matériels de télécommunication en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.42Z',
    description:
      'Commerce de détail de matériels de télécommunication en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.43',
    description:
      'Commerce de détail de matériels audio/vidéo en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.43Z',
    description:
      'Commerce de détail de matériels audio et vidéo en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.5',
    description:
      "Commerce de détail d'autres équipements du foyer en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.51',
    description: 'Commerce de détail de textiles en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.51Z',
    description: 'Commerce de détail de textiles en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.52',
    description:
      'Commerce de détail de quincaillerie, peintures et verres en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.52A',
    description:
      'Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.52B',
    description:
      'Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m2et plus)',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.53',
    description:
      'Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.53Z',
    description:
      'Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.54',
    description:
      "Commerce de détail d'appareils électroménagers en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.54Z',
    description:
      "Commerce de détail d'appareils électroménagers en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.59',
    description:
      "Commerce de détail de meubles, appareils d'éclairage et autres articles de ménage en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.59A',
    description: 'Commerce de détail de meubles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.59B',
    description: "Commerce de détail d'autres équipements du foyer",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.61',
    description: 'Commerce de détail de livres en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.61Z',
    description: 'Commerce de détail de livres en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.62',
    description:
      'Commerce de détail de journaux et papeterie en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.62Z',
    description:
      'Commerce de détail de journaux et papeterie en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.63',
    description:
      "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.63Z',
    description:
      "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.64',
    description: "Commerce de détail d'articles de sport en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.64Z',
    description: "Commerce de détail d'articles de sport en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.65',
    description: 'Commerce de détail de jeux et jouets en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.65Z',
    description: 'Commerce de détail de jeux et jouets en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.7',
    description: 'Autres commerces de détail en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.71',
    description: "Commerce de détail d'habillement en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.71Z',
    description: "Commerce de détail d'habillement en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.72',
    description:
      "Commerce de détail de chaussures et d'articles en cuir en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.72A',
    description: 'Commerce de détail de la chaussure',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.72B',
    description: "Commerce de détail de maroquinerie et d'articles de voyage",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.73',
    description:
      'Commerce de détail de produits pharmaceutiques en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.73Z',
    description:
      'Commerce de détail de produits pharmaceutiques en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.74',
    description:
      "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.74Z',
    description:
      "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.75',
    description:
      'Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.75Z',
    description:
      'Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.76',
    description:
      'Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.76Z',
    description:
      'Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.77',
    description:
      "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.77Z',
    description:
      "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.78',
    description:
      'Autre commerce de détail de biens neufs en magasin spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.78A',
    description: "Commerces de détail d'optique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.78B',
    description: 'Commerces de détail de charbons et combustibles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.78C',
    description: 'Autres commerces de détail spécialisés divers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.79',
    description: "Commerce de détail de biens d'occasion en magasin",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.79Z',
    description: "Commerce de détail de biens d'occasion en magasin",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.8',
    description: 'Commerce de détail sur éventaires et marchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.81',
    description: 'Commerce de détail alimentaire sur éventaires et marchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.81Z',
    description: 'Commerce de détail alimentaire sur éventaires et marchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.82',
    description:
      "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.82Z',
    description:
      "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.89',
    description: 'Autres commerces de détail sur éventaires et marchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.89Z',
    description: 'Autres commerces de détail sur éventaires et marchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.9',
    description: 'Commerce de détail hors magasin, éventaires ou marchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.91',
    description: 'Vente à distance',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.91A',
    description: 'Vente à distance sur catalogue général',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.91B',
    description: 'Vente à distance sur catalogue spécialisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.99',
    description:
      'Autres commerces de détail hors magasin, éventaires ou marchés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.99A',
    description: 'Vente à domicile',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '47.99B',
    description:
      'Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'H',
    description: 'TRANSPORTS ET ENTREPOSAGE',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49',
    description: 'Transports terrestres et transport par conduites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.1',
    description: 'Transport ferroviaire interurbain de voyageurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.10',
    description: 'Transport ferroviaire interurbain de voyageurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.10Z',
    description: 'Transport ferroviaire interurbain de voyageurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.2',
    description: 'Transports ferroviaires de fret',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.20',
    description: 'Transports ferroviaires de fret ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.20Z',
    description: 'Transports ferroviaires de fret ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.3',
    description: 'Autres transports terrestres de voyageurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.31',
    description: 'Transports urbains et suburbains de voyageurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.31Z',
    description: 'Transports urbains et suburbains de voyageurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.32',
    description: 'Transports de voyageurs par taxis',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.32Z',
    description: 'Transports de voyageurs par taxis',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.39',
    description: 'Autres transports terrestres de voyageurs n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.39A',
    description: 'Transports routiers réguliers de voyageurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.39B',
    description: 'Autres transports routiers de voyageurs ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.39C',
    description: 'Téléphériques et remontées mécaniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.4',
    description: 'Transports routiers de fret et services de déménagement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.41',
    description: 'Transports routiers de fret',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.41A',
    description: 'Transports routiers de fret interurbains',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.41B',
    description: 'Transports routiers de fret de proximité',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.41C',
    description: 'Location de camions avec chauffeur',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.42',
    description: 'Services de déménagement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.42Z',
    description: 'Services de déménagement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.5',
    description: 'Transports par conduites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.50',
    description: 'Transports par conduites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '49.50Z',
    description: 'Transports par conduites',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50',
    description: 'Transports par eau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.1',
    description: 'Transports maritimes et côtiers de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.10',
    description: 'Transports maritimes et côtiers de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.10Z',
    description: 'Transports maritimes et côtiers de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.2',
    description: 'Transports maritimes et côtiers de fret',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.20',
    description: 'Transports maritimes et côtiers de fret',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.20Z',
    description: 'Transports maritimes et côtiers de fret',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.3',
    description: 'Transports fluviaux de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.30',
    description: 'Transports fluviaux de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.30Z',
    description: 'Transports fluviaux de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.4',
    description: 'Transports fluviaux de fret',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.40',
    description: 'Transports fluviaux de fret ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '50.40Z',
    description: 'Transports fluviaux de fret ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51',
    description: 'Transports aériens',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51.1',
    description: 'Transports aériens de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51.10',
    description: 'Transports aériens de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51.10Z',
    description: 'Transports aériens de passagers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51.2',
    description: 'Transports aériens de fret et transports spatiaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51.21',
    description: 'Transports aériens de fret',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51.21Z',
    description: 'Transports aériens de fret',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51.22',
    description: 'Transports spatiaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '51.22Z',
    description: 'Transports spatiaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52',
    description: 'Entreposage et services auxiliaires des transports',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.1',
    description: 'Entreposage et stockage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.10',
    description: 'Entreposage et stockage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.10A',
    description: 'Entreposage et stockage frigorifique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.10B',
    description: 'Entreposage et stockage non frigorifique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.2',
    description: 'Services auxiliaires des transports',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.21',
    description: 'Services auxiliaires des transports terrestres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.21Z',
    description: 'Services auxiliaires des transports terrestres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.22',
    description: 'Services auxiliaires des transports par eau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.22Z',
    description: 'Services auxiliaires des transports par eau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.23',
    description: 'Services auxiliaires des transports aériens',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.23Z',
    description: 'Services auxiliaires des transports aériens',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.24',
    description: 'Manutention',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.24A',
    description: 'Manutention portuaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.24B',
    description: 'Manutention non portuaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.29',
    description: 'Autres services auxiliaires des transports ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.29A',
    description: 'Messagerie, fret express',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '52.29B',
    description: 'Affrètement et organisation des transports ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '53',
    description: 'Activités de poste et de courrier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '53.1',
    description:
      "Activités de poste dans le cadre d'une obligation de service universel",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '53.10',
    description:
      "Activités de poste dans le cadre d'une obligation de service universel ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '53.10Z',
    description:
      "Activités de poste dans le cadre d'une obligation de service universel ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '53.2',
    description: 'Autres activités de poste et de courrier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '53.20',
    description: 'Autres activités de poste et de courrier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '53.20Z',
    description: 'Autres activités de poste et de courrier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'I',
    description: 'HÉBERGEMENT ET RESTAURATION',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55',
    description: 'Hébergement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.1',
    description: 'Hôtels et hébergement similaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.10',
    description: 'Hôtels et hébergement similaire ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.10Z',
    description: 'Hôtels et hébergement similaire ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.2',
    description:
      'Hébergement touristique et autre hébergement de courte durée ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.20',
    description:
      'Hébergement touristique et autre hébergement de courte durée ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.20Z',
    description:
      'Hébergement touristique et autre hébergement de courte durée ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.3',
    description:
      'Terrains de camping et parcs pour caravanes ou véhicules de loisirs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.30',
    description:
      'Terrains de camping et parcs pour caravanes ou véhicules de loisirs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.30Z',
    description:
      'Terrains de camping et parcs pour caravanes ou véhicules de loisirs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.9',
    description: 'Autres hébergements ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.90',
    description: 'Autres hébergements ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '55.90Z',
    description: 'Autres hébergements ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56',
    description: 'Restauration',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.1',
    description: 'Restaurants et services de restauration mobile',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.10',
    description: 'Restaurants et services de restauration mobile',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.10A',
    description: 'Restauration traditionnelle',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.10B',
    description: 'Cafétérias et autres libres-services',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.10C',
    description: 'Restauration de type rapide',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.2',
    description: 'Traiteurs et autres services de restauration',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.21',
    description: 'Services des traiteurs ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.21Z',
    description: 'Services des traiteurs ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.29',
    description: 'Autres services de restauration ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.29A',
    description: 'Restauration collective sous contrat',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.29B',
    description: 'Autres services de restauration n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.3',
    description: 'Débits de boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.30',
    description: 'Débits de boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '56.30Z',
    description: 'Débits de boissons',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'J',
    description: 'INFORMATION ET COMMUNICATION',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58',
    description: 'Édition',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.1',
    description:
      "Édition de livres et périodiques et autres activités d'édition ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.11',
    description: 'Édition de livres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.11Z',
    description: 'Édition de livres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.12',
    description: "Édition de répertoires et de fichiers d'adresses",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.12Z',
    description: "Édition de répertoires et de fichiers d'adresses",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.13',
    description: 'Édition de journaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.13Z',
    description: 'Édition de journaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.14',
    description: 'Édition de revues et périodiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.14Z',
    description: 'Édition de revues et périodiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.19',
    description: "Autres activités d'édition",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.19Z',
    description: "Autres activités d'édition",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.2',
    description: 'Édition de logiciels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.21',
    description: 'Édition de jeux électroniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.21Z',
    description: 'Édition de jeux électroniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.29',
    description: "Édition d'autres logiciels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.29A',
    description: 'Édition de logiciels système et de réseau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.29B',
    description: 'Edition de logiciels outils de développement et de langages',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '58.29C',
    description: 'Edition de logiciels applicatifs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59',
    description:
      'Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.1',
    description: 'Activités cinématographiques, vidéo et de télévision',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.11',
    description:
      'Production de films cinématographiques, de vidéo et de programmes de télévision ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.11A',
    description: 'Production de films et de programmes pour la télévision ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.11B',
    description: 'Production de films institutionnels et publicitaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.11C',
    description: 'Production de films pour le cinéma',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.12',
    description:
      'Post-production de films cinématographiques, de vidéo et de programmes de télévision',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.12Z',
    description:
      'Post-production de films cinématographiques, de vidéo et de programmes de télévision',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.13',
    description:
      'Distribution de films cinématographiques, de vidéo et de programmes de télévision ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.13A',
    description: 'Distribution de films cinématographiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.13B',
    description: 'Edition et distribution vidéo',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.14',
    description: 'Projection de films cinématographiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.14Z',
    description: 'Projection de films cinématographiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.2',
    description: 'Enregistrement sonore et édition musicale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.20',
    description: 'Enregistrement sonore et édition musicale ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '59.20Z',
    description: 'Enregistrement sonore et édition musicale ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '60',
    description: 'Programmation et diffusion',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '60.1',
    description: 'Édition et diffusion de programmes radio',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '60.10',
    description: 'Édition et diffusion de programmes radio',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '60.10Z',
    description: 'Édition et diffusion de programmes radio',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '60.2',
    description: 'Programmation de télévision et télédiffusion',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '60.20',
    description: 'Programmation de télévision et télédiffusion',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '60.20A',
    description: 'Edition de chaînes généralistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '60.20B',
    description: 'Edition de chaînes thématiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61',
    description: 'Télécommunications',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.1',
    description: 'Télécommunications filaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.10',
    description: 'Télécommunications filaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.10Z',
    description: 'Télécommunications filaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.2',
    description: 'Télécommunications sans fil ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.20',
    description: 'Télécommunications sans fil ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.20Z',
    description: 'Télécommunications sans fil ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.3',
    description: 'Télécommunications par satellite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.30',
    description: 'Télécommunications par satellite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.30Z',
    description: 'Télécommunications par satellite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.9',
    description: 'Autres activités de télécommunication',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.90',
    description: 'Autres activités de télécommunication ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '61.90Z',
    description: 'Autres activités de télécommunication ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62',
    description: 'Programmation, conseil et autres activités informatiques ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.0',
    description: 'Programmation, conseil et autres activités informatiques ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.01',
    description: 'Programmation informatique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.01Z',
    description: 'Programmation informatique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.02',
    description: 'Conseil informatique ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.02A',
    description: 'Conseil en systèmes et logiciels informatiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.02B',
    description:
      'Tierce maintenance de systèmes et d’applications informatiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.03',
    description: "Gestion d'installations informatiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.03Z',
    description: "Gestion d'installations informatiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.09',
    description: 'Autres activités informatiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '62.09Z',
    description: 'Autres activités informatiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63',
    description: "Services d'information",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.1',
    description:
      'Traitement de données, hébergement et activités connexes ; portails Internet',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.11',
    description: 'Traitement de données, hébergement et activités connexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.11Z',
    description: 'Traitement de données, hébergement et activités connexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.12',
    description: 'Portails Internet',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.12Z',
    description: 'Portails Internet',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.9',
    description: "Autres services d'information",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.91',
    description: 'Activités des agences de presse',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.91Z',
    description: 'Activités des agences de presse',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.99',
    description: "Autres services d'information n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '63.99Z',
    description: "Autres services d'information n.c.a.",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'K',
    description: "ACTIVITÉS FINANCIÈRES ET D'ASSURANCE",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64',
    description:
      'Activités des services financiers, hors assurance et caisses de retraite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.1',
    description: 'Intermédiation monétaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.11',
    description: 'Activités de banque centrale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.11Z',
    description: 'Activités de banque centrale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.19',
    description: 'Autres intermédiations monétaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.19Z',
    description: 'Autres intermédiations monétaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.2',
    description: 'Activités des sociétés holding',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.20',
    description: 'Activités des sociétés holding',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.20Z',
    description: 'Activités des sociétés holding',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.3',
    description: 'Fonds de placement et entités financières similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.30',
    description: 'Fonds de placement et entités financières similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.30Z',
    description: 'Fonds de placement et entités financières similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.9',
    description:
      'Autres activités des services financiers, hors assurance et caisses de retraite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.91',
    description: 'Crédit-bail ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.91Z',
    description: 'Crédit-bail ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.92',
    description: 'Autre distribution de crédit',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.92Z',
    description: 'Autre distribution de crédit',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.99',
    description:
      'Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '64.99Z',
    description:
      'Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65',
    description: 'Assurance',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.1',
    description: 'Assurance',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.11',
    description: 'Assurance vie ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.11Z',
    description: 'Assurance vie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.12',
    description: 'Autres assurances',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.12Z',
    description: 'Autres assurances',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.2',
    description: 'Réassurance',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.20',
    description: 'Réassurance',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.20Z',
    description: 'Réassurance',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.3',
    description: 'Caisses de retraite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.30',
    description: 'Caisses de retraite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '65.30Z',
    description: 'Caisses de retraite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66',
    description: "Activités auxiliaires de services financiers et d'assurance ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.1',
    description:
      'Activités auxiliaires de services financiers, hors assurance et caisses de retraite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.11',
    description: 'Administration de marchés financiers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.11Z',
    description: 'Administration de marchés financiers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.12',
    description: 'Courtage de valeurs mobilières et de marchandises',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.12Z',
    description: 'Courtage de valeurs mobilières et de marchandises',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.19',
    description:
      'Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.19A',
    description: 'Supports juridiques de gestion de patrimoine mobilier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.19B',
    description:
      'Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.2',
    description: "Activités auxiliaires d'assurance et de caisses de retraite",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.21',
    description: 'Évaluation des risques et dommages',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.21Z',
    description: 'Évaluation des risques et dommages',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.22',
    description: "Activités des agents et courtiers d'assurances",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.22Z',
    description: "Activités des agents et courtiers d'assurances",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.29',
    description:
      "Autres activités auxiliaires d'assurance et de caisses de retraite",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.29Z',
    description:
      "Autres activités auxiliaires d'assurance et de caisses de retraite",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.3',
    description: 'Gestion de fonds',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.30',
    description: 'Gestion de fonds',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '66.30Z',
    description: 'Gestion de fonds',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'L',
    description: 'ACTIVITÉS IMMOBILIÈRES',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68',
    description: 'Activités immobilières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.1',
    description: 'Activités des marchands de biens immobiliers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.10',
    description: 'Activités des marchands de biens immobiliers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.10Z',
    description: 'Activités des marchands de biens immobiliers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.2',
    description:
      'Location et exploitation de biens immobiliers propres ou loués',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.20',
    description:
      'Location et exploitation de biens immobiliers propres ou loués ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.20A',
    description: 'Location de logements',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.20B',
    description: "Location de terrains et d'autres biens immobiliers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.3',
    description: 'Activités immobilières pour compte de tiers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.31',
    description: 'Agences immobilières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.31Z',
    description: 'Agences immobilières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.32',
    description: 'Administration de biens immobiliers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.32A',
    description: "Administration d'immeubles et autres biens immobiliers",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '68.32B',
    description: 'Supports juridiques de gestion de patrimoine immobilier',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'M',
    description: 'ACTIVITÉS SPÉCIALISÉES, SCIENTIFIQUES ET TECHNIQUES',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '69',
    description: 'Activités juridiques et comptables',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '69.1',
    description: 'Activités juridiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '69.10',
    description: 'Activités juridiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '69.10Z',
    description: 'Activités juridiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '69.2',
    description: 'Activités comptables',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '69.20',
    description: 'Activités comptables',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '69.20Z',
    description: 'Activités comptables',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70',
    description: 'Activités des sièges sociaux ; conseil de gestion',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70.1',
    description: 'Activités des sièges sociaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70.10',
    description: 'Activités des sièges sociaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70.10Z',
    description: 'Activités des sièges sociaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70.2',
    description: 'Conseil de gestion',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70.21',
    description: 'Conseil en relations publiques et communication',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70.21Z',
    description: 'Conseil en relations publiques et communication',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70.22',
    description: 'Conseil pour les affaires et autres conseils de gestion',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '70.22Z',
    description: 'Conseil pour les affaires et autres conseils de gestion',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71',
    description:
      "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.1',
    description: "Activités d'architecture et d'ingénierie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.11',
    description: "Activités d'architecture ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.11Z',
    description: "Activités d'architecture ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.12',
    description: "Activités d'ingénierie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.12A',
    description: 'Activité des géomètres',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.12B',
    description: 'Ingénierie, études techniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.2',
    description: 'Activités de contrôle et analyses techniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.20',
    description: 'Activités de contrôle et analyses techniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.20A',
    description: 'Contrôle technique automobile',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '71.20B',
    description: 'Analyses, essais et inspections techniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72',
    description: 'Recherche-développement scientifique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72.1',
    description: 'Recherche-développement en sciences physiques et naturelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72.11',
    description: 'Recherche-développement en biotechnologie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72.11Z',
    description: 'Recherche-développement en biotechnologie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72.19',
    description:
      'Recherche-développement en autres sciences physiques et naturelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72.19Z',
    description:
      'Recherche-développement en autres sciences physiques et naturelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72.2',
    description: 'Recherche-développement en sciences humaines et sociales',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72.20',
    description: 'Recherche-développement en sciences humaines et sociales',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '72.20Z',
    description: 'Recherche-développement en sciences humaines et sociales',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73',
    description: 'Publicité et études de marché',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73.1',
    description: 'Publicité',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73.11',
    description: 'Activités des agences de publicité',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73.11Z',
    description: 'Activités des agences de publicité',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73.12',
    description: 'Régie publicitaire de médias',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73.12Z',
    description: 'Régie publicitaire de médias',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73.2',
    description: 'Études de marché et sondages',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73.20',
    description: 'Études de marché et sondages',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '73.20Z',
    description: 'Études de marché et sondages',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74',
    description: 'Autres activités spécialisées, scientifiques et techniques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.1',
    description: 'Activités spécialisées de design',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.10',
    description: 'Activités spécialisées de design',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.10Z',
    description: 'Activités spécialisées de design',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.2',
    description: 'Activités photographiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.20',
    description: 'Activités photographiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.20Z',
    description: 'Activités photographiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.3',
    description: 'Traduction et interprétation',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.30',
    description: 'Traduction et interprétation',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.30Z',
    description: 'Traduction et interprétation',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.9',
    description:
      'Autres activités spécialisées, scientifiques et techniques n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.90',
    description:
      'Autres activités spécialisées, scientifiques et techniques n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.90A',
    description: 'Activité des économistes de la construction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '74.90B',
    description: 'Activités spécialisées, scientifiques et techniques diverses',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '75',
    description: 'Activités vétérinaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '75.0',
    description: 'Activités vétérinaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '75.00',
    description: 'Activités vétérinaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '75.00Z',
    description: 'Activités vétérinaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'N',
    description: 'ACTIVITÉS DE SERVICES ADMINISTRATIFS ET DE SOUTIEN',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77',
    description: 'Activités de location et location-bail',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.1',
    description: 'Location et location-bail de véhicules automobiles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.11',
    description:
      'Location et location-bail de voitures et de véhicules automobiles légers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.11A',
    description:
      'Location de courte durée de voitures et de véhicules automobiles légers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.11B',
    description:
      'Location de longue durée de voitures et de véhicules automobiles légers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.12',
    description: 'Location et location-bail de camions',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.12Z',
    description: 'Location et location-bail de camions',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.2',
    description: 'Location et location-bail de biens personnels et domestiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.21',
    description: "Location et location-bail d'articles de loisirs et de sport ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.21Z',
    description: "Location et location-bail d'articles de loisirs et de sport ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.22',
    description: 'Location de vidéocassettes et disques vidéo',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.22Z',
    description: 'Location de vidéocassettes et disques vidéo',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.29',
    description:
      "Location et location-bail d'autres biens personnels et domestiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.29Z',
    description:
      "Location et location-bail d'autres biens personnels et domestiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.3',
    description:
      "Location et location-bail d'autres machines, équipements et biens",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.31',
    description:
      'Location et location-bail de machines et équipements agricoles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.31Z',
    description:
      'Location et location-bail de machines et équipements agricoles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.32',
    description:
      'Location et location-bail de machines et équipements pour la construction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.32Z',
    description:
      'Location et location-bail de machines et équipements pour la construction',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.33',
    description:
      'Location et location-bail de machines de bureau et de matériel informatique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.33Z',
    description:
      'Location et location-bail de machines de bureau et de matériel informatique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.34',
    description: 'Location et location-bail de matériels de transport par eau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.34Z',
    description: 'Location et location-bail de matériels de transport par eau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.35',
    description: 'Location et location-bail de matériels de transport aérien',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.35Z',
    description: 'Location et location-bail de matériels de transport aérien',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.39',
    description:
      "Location et location-bail d'autres machines, équipements et biens matériels n.c.a. ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.39Z',
    description:
      "Location et location-bail d'autres machines, équipements et biens matériels n.c.a. ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.4',
    description:
      "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.40',
    description:
      "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '77.40Z',
    description:
      "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78',
    description: "Activités liées à l'emploi",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.1',
    description: "Activités des agences de placement de main-d'œuvre",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.10',
    description: "Activités des agences de placement de main-d'œuvre ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.10Z',
    description: "Activités des agences de placement de main-d'œuvre ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.2',
    description: 'Activités des agences de travail temporaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.20',
    description: 'Activités des agences de travail temporaire ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.20Z',
    description: 'Activités des agences de travail temporaire ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.3',
    description: 'Autre mise à disposition de ressources humaines',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.30',
    description: 'Autre mise à disposition de ressources humaines',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '78.30Z',
    description: 'Autre mise à disposition de ressources humaines',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79',
    description:
      'Activités des agences de voyage, voyagistes, services de réservation et activités connexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79.1',
    description: 'Activités des agences de voyage et voyagistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79.11',
    description: 'Activités des agences de voyage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79.11Z',
    description: 'Activités des agences de voyage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79.12',
    description: 'Activités des voyagistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79.12Z',
    description: 'Activités des voyagistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79.9',
    description: 'Autres services de réservation et activités connexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79.90',
    description: 'Autres services de réservation et activités connexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '79.90Z',
    description: 'Autres services de réservation et activités connexes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80',
    description: 'Enquêtes et sécurité',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.1',
    description: 'Activités de sécurité privée',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.10',
    description: 'Activités de sécurité privée ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.10Z',
    description: 'Activités de sécurité privée ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.2',
    description: 'Activités liées aux systèmes de sécurité',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.20',
    description: 'Activités liées aux systèmes de sécurité ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.20Z',
    description: 'Activités liées aux systèmes de sécurité ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.3',
    description: "Activités d'enquête",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.30',
    description: "Activités d'enquête",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '80.30Z',
    description: "Activités d'enquête",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81',
    description: 'Services relatifs aux bâtiments et aménagement paysager',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.1',
    description: 'Activités combinées de soutien lié aux bâtiments',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.10',
    description: 'Activités combinées de soutien lié aux bâtiments ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.10Z',
    description: 'Activités combinées de soutien lié aux bâtiments ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.2',
    description: 'Activités de nettoyage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.21',
    description: 'Nettoyage courant des bâtiments',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.21Z',
    description: 'Nettoyage courant des bâtiments',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.22',
    description:
      'Autres activités de nettoyage des bâtiments et nettoyage industriel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.22Z',
    description:
      'Autres activités de nettoyage des bâtiments et nettoyage industriel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.29',
    description: 'Autres activités de nettoyage',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.29A',
    description: 'Désinfection, désinsectisation, dératisation',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.29B',
    description: 'Autres activités de nettoyage n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.3',
    description: "Services d'aménagement paysager",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.30',
    description: "Services d'aménagement paysager ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '81.30Z',
    description: "Services d'aménagement paysager ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82',
    description:
      'Activités administratives et autres activités de soutien aux entreprises',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.1',
    description: 'Activités administratives ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.11',
    description: 'Services administratifs combinés de bureau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.11Z',
    description: 'Services administratifs combinés de bureau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.19',
    description:
      'Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.19Z',
    description:
      'Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.2',
    description: "Activités de centres d'appels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.20',
    description: "Activités de centres d'appels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.20Z',
    description: "Activités de centres d'appels",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.3',
    description: 'Organisation de salons professionnels et congrès',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.30',
    description: 'Organisation de salons professionnels et congrès',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.30Z',
    description: 'Organisation de foires, salons professionnels et congrès',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.9',
    description: 'Activités de soutien aux entreprises n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.91',
    description:
      "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.91Z',
    description:
      "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.92',
    description: 'Activités de conditionnement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.92Z',
    description: 'Activités de conditionnement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.99',
    description: 'Autres activités de soutien aux entreprises n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '82.99Z',
    description: 'Autres activités de soutien aux entreprises n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'O',
    description: 'ADMINISTRATION PUBLIQUE ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84',
    description:
      'Administration publique et défense ; sécurité sociale obligatoire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.1',
    description: 'Administration générale, économique et sociale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.11',
    description: 'Administration publique générale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.11Z',
    description: 'Administration publique générale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.12',
    description:
      'Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.12Z',
    description:
      'Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.13',
    description: 'Administration publique (tutelle) des activités économiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.13Z',
    description: 'Administration publique (tutelle) des activités économiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.2',
    description: 'Services de prérogative publique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.21',
    description: 'Affaires étrangères',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.21Z',
    description: 'Affaires étrangères',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.22',
    description: 'Défense',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.22Z',
    description: 'Défense',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.23',
    description: 'Justice',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.23Z',
    description: 'Justice',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.24',
    description: 'Activités d’ordre public et de sécurité',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.24Z',
    description: 'Activités d’ordre public et de sécurité',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.25',
    description: 'Services du feu et de secours',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.25Z',
    description: 'Services du feu et de secours',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.3',
    description: 'Sécurité sociale obligatoire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.30',
    description: 'Sécurité sociale obligatoire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.30A',
    description: 'Activités générales de sécurité sociale',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.30B',
    description: 'Gestion des retraites complémentaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '84.30C',
    description: 'Distribution sociale de revenus',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'P',
    description: 'ENSEIGNEMENT',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85',
    description: 'Enseignement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.1',
    description: 'Enseignement pré-primaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.10',
    description: 'Enseignement pré-primaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.10Z',
    description: 'Enseignement pré-primaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.2',
    description: 'Enseignement primaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.20',
    description: 'Enseignement primaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.20Z',
    description: 'Enseignement primaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.3',
    description: 'Enseignement secondaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.31',
    description: 'Enseignement secondaire général',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.31Z',
    description: 'Enseignement secondaire général',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.32',
    description: 'Enseignement secondaire technique ou professionnel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.32Z',
    description: 'Enseignement secondaire technique ou professionnel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.4',
    description: 'Enseignement supérieur et post-secondaire non supérieur',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.41',
    description: 'Enseignement post-secondaire non supérieur',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.41Z',
    description: 'Enseignement post-secondaire non supérieur',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.42',
    description: 'Enseignement supérieur',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.42Z',
    description: 'Enseignement supérieur',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.5',
    description: "Autres activités d'enseignement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.51',
    description:
      "Enseignement de disciplines sportives et d'activités de loisirs",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.51Z',
    description:
      "Enseignement de disciplines sportives et d'activités de loisirs",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.52',
    description: 'Enseignement culturel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.52Z',
    description: 'Enseignement culturel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.53',
    description: 'Enseignement de la conduite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.53Z',
    description: 'Enseignement de la conduite',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.59',
    description: 'Enseignements divers',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.59A',
    description: "Formation continue d'adultes",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.59B',
    description: 'Autres enseignements',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.6',
    description: "Activités de soutien à l'enseignement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.60',
    description: "Activités de soutien à l'enseignement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '85.60Z',
    description: "Activités de soutien à l'enseignement",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'Q',
    description: 'SANTÉ HUMAINE ET ACTION SOCIALE',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86',
    description: 'Activités pour la santé humaine',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.1',
    description: 'Activités hospitalières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.10',
    description: 'Activités hospitalières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.10Z',
    description: 'Activités hospitalières',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.2',
    description: 'Activité des médecins et des dentistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.21',
    description: 'Activité des médecins généralistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.21Z',
    description: 'Activité des médecins généralistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.22',
    description: 'Activité des médecins spécialistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.22A',
    description: 'Activités de radiodiagnostic et de radiothérapie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.22B',
    description: 'Activités chirurgicales',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.22C',
    description: 'Autres activités des médecins spécialistes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.23',
    description: 'Pratique dentaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.23Z',
    description: 'Pratique dentaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.9',
    description: 'Autres activités pour la santé humaine',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.90',
    description: 'Autres activités pour la santé humaine',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.90A',
    description: 'Ambulances',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.90B',
    description: "Laboratoires d'analyses médicales",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.90C',
    description: "Centres de collecte et banques d'organes",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.90D',
    description: 'Activités des infirmiers et des sages-femmes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.90E',
    description:
      'Activités des professionnels de la rééducation, de l’appareillage et des pédicures-podologues',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '86.90F',
    description: 'Activités de santé humaine non classées ailleurs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87',
    description: 'Hébergement médico-social et social',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.1',
    description: 'Hébergement médicalisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.10',
    description: 'Hébergement médicalisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.10A',
    description: 'Hébergement médicalisé pour personnes âgées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.10B',
    description: 'Hébergement médicalisé pour enfants handicapés ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.10C',
    description:
      'Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.2',
    description:
      'Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.20',
    description:
      'Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.20A',
    description:
      'Hébergement social pour handicapés mentaux et malades mentaux ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.20B',
    description: 'Hébergement social pour toxicomanes',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.3',
    description:
      'Hébergement social pour personnes âgées ou handicapées physiques ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.30',
    description:
      'Hébergement social pour personnes âgées ou handicapées physiques ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.30A',
    description: 'Hébergement social pour personnes âgées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.30B',
    description: 'Hébergement social pour handicapés  physiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.9',
    description: 'Autres activités d’hébergement social ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.90',
    description: 'Autres activités d’hébergement social ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.90A',
    description: 'Hébergement social pour enfants en difficultés ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '87.90B',
    description:
      'Hébergement social pour adultes et familles en difficultés et autre hébergement social ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88',
    description: 'Action sociale sans hébergement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.1',
    description:
      'Action sociale sans hébergement pour personnes âgées et pour personnes handicapées ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.10',
    description:
      'Action sociale sans hébergement pour personnes âgées et pour personnes handicapées ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.10A',
    description: 'Aide à domicile  ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.10B',
    description:
      'Accueil ou accompagnement sans hébergement d’adultes handicapés ou de  personnes âgées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.10C',
    description: 'Aide par le travail ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.9',
    description: 'Autre action sociale sans hébergement',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.91',
    description: 'Action sociale sans hébergement pour jeunes enfants',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.91A',
    description: 'Accueil de jeunes enfants',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.91B',
    description:
      'Accueil ou accompagnement sans hébergement d’enfants handicapés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.99',
    description: 'Autre action sociale sans hébergement n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.99A',
    description:
      'Autre accueil ou accompagnement sans hébergement d’enfants et d’adolescents',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '88.99B',
    description: 'Action sociale sans hébergement n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'R',
    description: 'ARTS, SPECTACLES ET ACTIVITÉS RÉCRÉATIVES',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90',
    description: 'Activités créatives, artistiques et de spectacle ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.0',
    description: 'Activités créatives, artistiques et de spectacle ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.01',
    description: 'Arts du spectacle vivant',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.01Z',
    description: 'Arts du spectacle vivant',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.02',
    description: 'Activités de soutien au spectacle vivant',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.02Z',
    description: 'Activités de soutien au spectacle vivant',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.03',
    description: 'Création artistique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.03A',
    description: 'Création artistique relevant des arts plastiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.03B',
    description: 'Autre création artistique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.04',
    description: 'Gestion de salles de spectacles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '90.04Z',
    description: 'Gestion de salles de spectacles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91',
    description:
      'Bibliothèques, archives, musées et autres activités culturelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.0',
    description:
      'Bibliothèques, archives, musées et autres activités culturelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.01',
    description: 'Gestion des bibliothèques et des archives',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.01Z',
    description: 'Gestion des bibliothèques et des archives',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.02',
    description: 'Gestion des musées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.02Z',
    description: 'Gestion des musées',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.03',
    description:
      'Gestion des sites et monuments historiques et des attractions touristiques similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.03Z',
    description:
      'Gestion des sites et monuments historiques et des attractions touristiques similaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.04',
    description:
      'Gestion des jardins botaniques et zoologiques et des réserves naturelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '91.04Z',
    description:
      'Gestion des jardins botaniques et zoologiques et des réserves naturelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '92',
    description: "Organisation de jeux de hasard et d'argent",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '92.0',
    description: "Organisation de jeux de hasard et d'argent",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '92.00',
    description: "Organisation de jeux de hasard et d'argent",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '92.00Z',
    description: "Organisation de jeux de hasard et d'argent",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93',
    description: 'Activités sportives, récréatives et de loisirs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.1',
    description: 'Activités liées au sport',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.11',
    description: "Gestion d'installations sportives",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.11Z',
    description: "Gestion d'installations sportives",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.12',
    description: 'Activités de clubs de sports',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.12Z',
    description: 'Activités de clubs de sports',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.13',
    description: 'Activités des centres de culture physique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.13Z',
    description: 'Activités des centres de culture physique',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.19',
    description: 'Autres activités liées au sport',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.19Z',
    description: 'Autres activités liées au sport',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.2',
    description: 'Activités récréatives et de loisirs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.21',
    description: "Activités des parcs d'attractions et parcs à thèmes",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.21Z',
    description: "Activités des parcs d'attractions et parcs à thèmes",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.29',
    description: 'Autres activités récréatives et de loisirs ',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '93.29Z',
    description: 'Autres activités récréatives et de loisirs',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'S',
    description: 'AUTRES ACTIVITÉS DE SERVICES',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94',
    description: 'Activités des organisations associatives',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.1',
    description:
      'Activités des organisations économiques, patronales et professionnelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.11',
    description: 'Activités des organisations patronales et consulaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.11Z',
    description: 'Activités des organisations patronales et consulaires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.12',
    description: 'Activités des organisations professionnelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.12Z',
    description: 'Activités des organisations professionnelles',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.2',
    description: 'Activités des syndicats de salariés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.20',
    description: 'Activités des syndicats de salariés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.20Z',
    description: 'Activités des syndicats de salariés',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.9',
    description: 'Activités des autres organisations associatives',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.91',
    description: 'Activités des organisations religieuses',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.91Z',
    description: 'Activités des organisations religieuses',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.92',
    description: 'Activités des organisations politiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.92Z',
    description: 'Activités des organisations politiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.99',
    description: 'Activités des organisations associatives n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '94.99Z',
    description: 'Autres organisations fonctionnant par adhésion volontaire',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95',
    description:
      "Réparation d'ordinateurs et de biens personnels et domestiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.1',
    description: "Réparation d'ordinateurs et d'équipements de communication ",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.11',
    description: "Réparation d'ordinateurs et d'équipements périphériques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.11Z',
    description: "Réparation d'ordinateurs et d'équipements périphériques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.12',
    description: "Réparation d'équipements de communication",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.12Z',
    description: "Réparation d'équipements de communication",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.2',
    description: 'Réparation de biens personnels et domestiques',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.21',
    description: 'Réparation de produits électroniques grand public',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.21Z',
    description: 'Réparation de produits électroniques grand public',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.22',
    description:
      "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.22Z',
    description:
      "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.23',
    description: "Réparation de chaussures et d'articles en cuir",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.23Z',
    description: "Réparation de chaussures et d'articles en cuir",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.24',
    description: "Réparation de meubles et d'équipements du foyer",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.24Z',
    description: "Réparation de meubles et d'équipements du foyer",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.25',
    description: "Réparation d'articles d'horlogerie et de bijouterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.25Z',
    description: "Réparation d'articles d'horlogerie et de bijouterie",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.29',
    description: "Réparation d'autres biens personnels et domestiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '95.29Z',
    description: "Réparation d'autres biens personnels et domestiques",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96',
    description: 'Autres services personnels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.0',
    description: 'Autres services personnels',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.01',
    description: 'Blanchisserie-teinturerie',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.01A',
    description: 'Blanchisserie-teinturerie de gros',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.01B',
    description: 'Blanchisserie-teinturerie de détail',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.02',
    description: 'Coiffure et soins de beauté',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.02A',
    description: 'Coiffure',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.02B',
    description: 'Soins de beauté',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.03',
    description: 'Services funéraires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.03Z',
    description: 'Services funéraires',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.04',
    description: 'Entretien corporel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.04Z',
    description: 'Entretien corporel',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.09',
    description: 'Autres services personnels n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '96.09Z',
    description: 'Autres services personnels n.c.a.',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'T',
    description:
      "ACTIVITÉS DES MÉNAGES EN TANT QU'EMPLOYEURS ; ACTIVITÉS INDIFFÉRENCIÉES DES MÉNAGES EN TANT QUE PRODUCTEURS DE BIENS ET SERVICES POUR USAGE PROPRE",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '97',
    description:
      "Activités des ménages en tant qu'employeurs de personnel domestique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '97.0',
    description:
      "Activités des ménages en tant qu'employeurs de personnel domestique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '97.00',
    description:
      "Activités des ménages en tant qu'employeurs de personnel domestique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '97.00Z',
    description:
      "Activités des ménages en tant qu'employeurs de personnel domestique",
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '98',
    description:
      'Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '98.1',
    description:
      'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '98.10',
    description:
      'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '98.10Z',
    description:
      'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '98.2',
    description:
      'Activités indifférenciées des ménages en tant que producteurs de services pour usage propre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '98.20',
    description:
      'Activités indifférenciées des ménages en tant que producteurs de services pour usage propre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '98.20Z',
    description:
      'Activités indifférenciées des ménages en tant que producteurs de services pour usage propre',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: 'U',
    description: 'ACTIVITÉS EXTRA-TERRITORIALES',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '99',
    description: 'Activités des organisations et organismes extraterritoriaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '99.0',
    description: 'Activités des organisations et organismes extraterritoriaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '99.00',
    description: 'Activités des organisations et organismes extraterritoriaux',
  },
  {
    type: CompanyClassificationTypeEnum.NAF,
    code: '99.00Z',
    description: 'Activités des organisations et organismes extraterritoriaux',
  },
];

export default nafClassifications;
