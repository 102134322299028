import {
  parseAbsoluteDate,
  stringifyAbsoluteDate,
} from '@dotfile/shared/common';
import { PropertyTypeEnum } from '@dotfile/shared/domain';

import { AbsoluteDatePicker, DateRangePicker } from '../date-picker';
import { CustomValueEditorProps } from './type';

export const ValueEditorDate = ({
  operator,
  value,
  handleOnChange,
}: CustomValueEditorProps<typeof PropertyTypeEnum.date>): JSX.Element => {
  const selectsRange = operator === 'between' || operator === 'notBetween';

  if (selectsRange) {
    let arrayValue = value;
    if (typeof arrayValue === 'string') {
      arrayValue = arrayValue.split(',');
    }
    return (
      <DateRangePicker
        selectsRange
        isClearable
        width="100%"
        startDate={arrayValue?.[0] ? parseAbsoluteDate(arrayValue[0]) : null}
        endDate={arrayValue?.[1] ? parseAbsoluteDate(arrayValue[1]) : null}
        onChange={(newValues) => {
          const newStartDate = newValues[0];
          const newEndDate = newValues[1];

          if (newStartDate instanceof Date && newEndDate instanceof Date) {
            handleOnChange([
              stringifyAbsoluteDate(newStartDate),
              stringifyAbsoluteDate(newEndDate),
            ]);
          } else {
            handleOnChange(null);
          }
        }}
      />
    );
  }

  return (
    <AbsoluteDatePicker
      width="100%"
      value={value}
      onChange={handleOnChange}
      selectsRange={selectsRange}
    />
  );
};
