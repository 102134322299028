import { ReactNode, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  EntityLegalFormSelect,
  GroupControlProps,
  Tooltip,
} from '@dotfile/frontend/shared/design-system';
import { generateEntityLegalFormOptions } from '@dotfile/shared/domain';

import { GroupController } from '../../../input';

type EntityLegalFormFieldProps = {
  name: string;
  label?: ReactNode;
  country: string | null;
  subdivision?: string | null;
} & GroupControlProps;

export const EntityLegalFormField = ({
  country,
  subdivision,
  ...rest
}: EntityLegalFormFieldProps) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();

  const entityLegalForm = watch(rest.name);

  const { availableEntityLegalForms } = useMemo(() => {
    return generateEntityLegalFormOptions(country, subdivision);
  }, [country, subdivision]);

  // Reset field if country has changed and selected value is not in available entityLegalForm
  useEffect(() => {
    if (
      country &&
      entityLegalForm &&
      !availableEntityLegalForms.find(({ code }) => code === entityLegalForm)
    ) {
      setValue(rest.name, '', { shouldValidate: true, shouldDirty: true });
    }
  }, [
    rest.name,
    availableEntityLegalForms,
    entityLegalForm,
    country,
    setValue,
  ]);

  return (
    <GroupController
      {...rest}
      control={control}
      isDisabled={!country}
      render={(field) => (
        // @NOTE: This tooltip should stay inside the group controller, or it will break the grid used in client-portal
        <Tooltip
          isDisabled={!!country}
          label={t('entity_legal_form.country_required', {
            ns: 'components',
            defaultValue: 'A country must be selected',
          })}
        >
          <Box overflow="hidden">
            <EntityLegalFormSelect
              {...field}
              isMulti={false}
              country={country}
              subdivision={subdivision}
              onChange={(value) => {
                setValue(rest.name, value?.value ?? '', {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                });
              }}
            />
          </Box>
        </Tooltip>
      )}
    />
  );
};
