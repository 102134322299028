import {
  AbsoluteCenter as AbsoluteChakraCenter,
  AbsoluteCenterProps as AbsoluteChakraCenterProps,
  Center as ChakraCenter,
  CenterProps as ChakraCenterProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/layout/center
export type CenterProps = ChakraCenterProps;
export const Center = ChakraCenter;

// @doc https://v2.chakra-ui.com/docs/components/center/usage#absolutecenter
export type AbsoluteCenterProps = AbsoluteChakraCenterProps;
export const AbsoluteCenter = AbsoluteChakraCenter;
