import { cloneDeep, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  DefaultValues,
  FieldValues,
  Path,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { usePrevious } from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalBlockTypeEnum,
  ClientPortalForms_Block,
  ClientPortalForms_BlockField,
  ClientPortalForms_Step,
} from '@dotfile/shared/data-access-client-portal';
import {
  clientPortalIndividualPropertiesDefinition,
  matchFieldMapping,
  yup,
} from '@dotfile/shared/domain';

import { useBlocksLogic } from '../logics';
import {
  FIELDS_PREFIX,
  FieldsFormValues,
  FieldsValues,
  generateBlockValidationSchema,
  generateDefaultValuesFromData,
  GenerateDefaultValuesFromDataParam,
} from '../utils';

/**
 * Retrigger validation on all invalid fields when local changes to update the error messages
 */
const useTriggerValidationOnLanguageChange = <TValues extends FieldValues>(
  methods: UseFormReturn<TValues>,
) => {
  const {
    trigger,
    formState: { errors },
  } = methods;

  const { i18n } = useTranslation();
  const previousLanguage = usePrevious(i18n.language);

  useEffect(() => {
    if (previousLanguage !== i18n.language) {
      // @NOTE For nested field like address and banking information, it will trigger the validation on
      // the whole field instead of only the invalid part of the fields
      const invalidFieldNames = Object.keys(errors) as Path<TValues>[];
      trigger(invalidFieldNames);
    }
  }, [previousLanguage, i18n.language, trigger, errors]);
};

/**
 * Reset default value when the form becomes visible
 */
const useResetWhenBecomeVisible = <TValues extends FieldValues>(
  methods: UseFormReturn<TValues>,
  defaultValues: DefaultValues<TValues>,
  isHidden: boolean,
) => {
  const { reset } = methods;

  const previousIsHidden = usePrevious(isHidden);

  useEffect(() => {
    if (previousIsHidden && !isHidden) {
      reset(defaultValues);
    }
  }, [previousIsHidden, isHidden, reset, defaultValues]);
};

type UseFieldsFormExtraParam<TExtraValues extends FieldValues> = {
  /**
   * Current step that contains the field that will powered this form
   */
  step: ClientPortalForms_Step;
  /**
   * Data from the store to compute the default value for the fields
   */
  data: GenerateDefaultValuesFromDataParam['data'];

  /**
   * Optional additional default values for the part of the form not powered by step fields.
   * `'_fields'` name is reserved for all the fields from
   */
  extraDefaultValues?: TExtraValues;
  /**
   * Optional additional schema for the part of the form not powered by step fields
   */
  extraSchema?: yup.AnyObjectSchema;

  /**
   * Optional boolean to indicate that the form is hidden (for instance in a closed Drawer).
   * The form will be reset to newly computed default value when it become visible.
   */
  isHidden?: boolean;
};

/**
 * Create a react-hook-form with validation and default value based on the fields of a
 * step and the data from the store.
 *
 * Can accepts extra "input" with their default value in `extraDefaultValues` and their
 * validation schema in `extraSchema`. Be aware that the values for the fields of a step
 * are nested under the name `_fields` so this name cannot be used for extra data.
 */
export function useFieldsForm<
  TExtraValues extends FieldValues = Record<string, never>,
>({
  step,
  data,
  extraDefaultValues,
  extraSchema,
  isHidden = false,
}: UseFieldsFormExtraParam<TExtraValues>): {
  methods: UseFormReturn<FieldsFormValues<TExtraValues>>;
  displayedBlocks: ClientPortalForms_Block[];
} {
  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    const allFields = step.blocks.filter(
      (b): b is ClientPortalForms_BlockField =>
        b.type === ClientPortalBlockTypeEnum.field,
    );

    const defaultFieldValues: FieldsValues = generateDefaultValuesFromData({
      fields: allFields,
      data,
      errorContext: { step },
    });

    const defaultValues = {
      _extra: { ...extraDefaultValues },
      [FIELDS_PREFIX]: defaultFieldValues,
    };
    return defaultValues;
  }, [data, extraDefaultValues, step]);

  const computeBlockLogics = useBlocksLogic(step.blocks);

  const [logicEnhancedBlocks, setLogicEnhancedBlocks] = useState(() =>
    computeBlockLogics(defaultValues[FIELDS_PREFIX]),
  );

  const resolver = useMemo(() => {
    const schema = (extraSchema ?? yup.object()).concat(
      yup.object({
        [FIELDS_PREFIX]: generateBlockValidationSchema({
          step,
          displayedFields: logicEnhancedBlocks.displayedFields,
          hiddenFields: logicEnhancedBlocks.hiddenFields,
          t,
        }),
      }),
    );
    return yupResolver(schema);
  }, [
    extraSchema,
    logicEnhancedBlocks.displayedFields,
    logicEnhancedBlocks.hiddenFields,
    step,
    t,
  ]);

  const methods = useForm<FieldsFormValues<TExtraValues>>({
    defaultValues,

    mode: 'all',
    criteriaMode: 'all',
    resolver,
  });

  useTriggerValidationOnLanguageChange(methods);

  useResetWhenBecomeVisible(methods, defaultValues, isHidden);

  // Recompute block logic when form value changes
  const formValues = methods.watch(FIELDS_PREFIX);
  const previousFormValues = usePrevious(cloneDeep(formValues));
  const { setValue } = methods;
  useEffect(() => {
    if (!isEqual(previousFormValues, formValues)) {
      const logicEnhancedBlocks = computeBlockLogics(formValues);
      setLogicEnhancedBlocks(logicEnhancedBlocks);

      for (const hiddenField of logicEnhancedBlocks.hiddenFields) {
        // Remove the value in the form to not impact logic with hidden field value
        // Since the dirty-ness of the field is not set, the value will be persisted
        // only if is was set dirty by an user interaction
        setValue(`${FIELDS_PREFIX}.${hiddenField.key}`, null);
      }
    }
  }, [computeBlockLogics, formValues, setValue, previousFormValues]);

  const displayedBlocks = useMemo(() => {
    let displayedBlocks = logicEnhancedBlocks.displayedBlocks;

    const isExistingBusinessContact =
      // When an individual already exists (has an id) and is a business contact,
      // the email cannot be edited via the client portal
      data.individual?.isBusinessContact && data.individual?.id;
    if (isExistingBusinessContact) {
      const matchIndividualDefaultEmail = matchFieldMapping(
        clientPortalIndividualPropertiesDefinition.email,
      );
      // Currently, isBusinessContact cannot be added as a field on the client portal
      // (it is not even defined in the individual properties)
      // @TODO - E-4396 - Client portal logic: Make more properties available
      // Even if not available as field step in builder, would make sense to also filter-out the isBUsinessContact
      // field to more sure it is not displayed on the forms
      displayedBlocks = displayedBlocks.filter(
        (block) =>
          !('mapping' in block) ||
          !matchIndividualDefaultEmail({
            mapping: block.mapping,
          }),
      );

      // @WARN Hiding the field like that could interfere with the logic, eventually a proper disabling feature could be
    }
    return displayedBlocks;
  }, [
    data.individual?.id,
    data.individual?.isBusinessContact,
    logicEnhancedBlocks.displayedBlocks,
  ]);

  return {
    methods,
    displayedBlocks,
  };
}
