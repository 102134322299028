import {
  Input as ChakraInput,
  InputAddonProps as ChakraInputAddonProps,
  InputElementProps as ChakraInputElementProps,
  InputGroup as ChakraInputGroup,
  InputGroupProps as ChakraInputGroupProps,
  InputLeftAddon as ChakraInputLeftAddon,
  InputLeftElement as ChakraInputLeftElement,
  InputProps as ChakraInputProps,
  InputRightAddon as ChakraInputRightAddon,
  InputRightElement as ChakraInputRightElement,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/form/input
export type InputProps = ChakraInputProps;
export const Input = ChakraInput;

export type InputGroupProps = ChakraInputGroupProps;
export const InputGroup = ChakraInputGroup;

export type InputElementProps = ChakraInputElementProps;
export const InputLeftElement = ChakraInputLeftElement;
export const InputRightElement = ChakraInputRightElement;

export type InputAddonProps = ChakraInputAddonProps;
export const InputLeftAddon = ChakraInputLeftAddon;
export const InputRightAddon = ChakraInputRightAddon;
