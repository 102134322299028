import {
  chakraComponents,
  Select as ChakraSelect,
  CreatableSelect,
  GroupBase,
  OptionBase,
  OptionProps,
  Props,
  SelectInstance,
  SingleValueProps,
} from 'chakra-react-select';
import { MutableRefObject } from 'react';

import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { useGenericSelectStyles } from './use-generic-select-styles';

export type Option = {
  label: string;
  value: string;
} & OptionBase;

export const GenericOption = chakraComponents.Option;
export type GenericOptionProps<O, M extends boolean> = OptionProps<O, M>;
export const GenericSingleValue = chakraComponents.SingleValue;
export type GenericSingleValueProps<O, M extends boolean> = SingleValueProps<
  O,
  M
>;

export const GenericSelect = forwardRefWithGeneric(
  <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    {
      chakraStyles: propsChakraStyles,
      styles: propsStyles,
      isCreatable = false,
      ...props
    }: Props<Option, IsMulti, Group> & { isCreatable?: boolean },
    ref:
      | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
      | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
      | null,
  ) => {
    const { chakraStyles, styles } = useGenericSelectStyles(
      propsChakraStyles,
      propsStyles,
    );

    if (isCreatable) {
      return (
        <CreatableSelect<Option, IsMulti, Group>
          ref={ref}
          menuPlacement="auto"
          menuPosition="fixed"
          chakraStyles={chakraStyles}
          styles={styles}
          selectedOptionStyle="check"
          isSearchable={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={!props.isMulti}
          formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
          {...props}
        />
      );
    }

    return (
      <ChakraSelect<Option, IsMulti, Group>
        ref={ref}
        menuPlacement="auto"
        menuPosition="fixed"
        onKeyDown={(event) => {
          // ChakraSelect will contain an input with aria-expanded attributes when menu is open
          const isMenuOpen =
            event.currentTarget.querySelector('input')?.ariaExpanded === 'true';
          // Don't propagate enter key event to form if menu is open
          if (event.key === 'Enter' && isMenuOpen) event.stopPropagation();

          props?.onKeyDown?.(event);
        }}
        chakraStyles={chakraStyles}
        styles={styles}
        selectedOptionStyle="check"
        isSearchable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={!props.isMulti}
        {...props}
      />
    );
  },
);
