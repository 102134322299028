export const RICH_TEXT_PADDING = 12; // equal to --chakra-space-3
export const RICH_TEXT_FONT_SIZE = 14;
export const RICH_TEXT_LINE_HEIGHT = 21;

/**
 * Match all the `<p></p>` at the end of the text, for instance to remove them
 */
export const RICH_TEXT_TRAILING_EMPTY_PARAGRAPH_REGEX = /(<p>\s*<\/p>)+$/;

/**
 * Match when the text contains only `<p></p>`, for instance to not save it
 */
export const RICH_TEXT_ONLY_EMPTY_PARAGRAPH_REGEX = /^(<p>\s*<\/p>)+$/;
