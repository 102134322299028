import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ClientPortalBlockField,
  ClientPortalForms_Step,
} from '@dotfile/shared/data-access-client-portal';
import { ClientPortalBlockTypeEnum } from '@dotfile/shared/domain';

import { useBlocksLogic } from '../logics';
import {
  FieldsValues,
  generateBlockValidationSchema,
  generateDefaultValuesFromData,
  GenerateDefaultValuesFromDataParam,
} from '../utils';

export const useIsFieldsFormValid = (step: ClientPortalForms_Step) => {
  const { t } = useTranslation();

  const computeBlockLogics = useBlocksLogic(step.blocks);

  const { generateSchemaForValues, generateDefaultValues } = useMemo(() => {
    const allFields = step.blocks.filter(
      (b): b is ClientPortalBlockField =>
        b.type === ClientPortalBlockTypeEnum.field,
    );

    const generateSchemaForValues = (fieldValues: FieldsValues) => {
      const logicEnhancedBlocks = computeBlockLogics(fieldValues);
      return generateBlockValidationSchema({
        step,
        displayedFields: logicEnhancedBlocks.displayedFields,
        hiddenFields: logicEnhancedBlocks.hiddenFields,
        t,
      });
    };

    const generateDefaultValues = (
      data: GenerateDefaultValuesFromDataParam['data'],
    ) =>
      generateDefaultValuesFromData({
        fields: allFields,
        data,
        errorContext: { step },
      });

    return {
      generateSchemaForValues,
      generateDefaultValues,
    };
  }, [step, t, computeBlockLogics]);

  const isFieldsFormValid = useCallback(
    (data: GenerateDefaultValuesFromDataParam['data']) => {
      const formValues = generateDefaultValues(data);
      return generateSchemaForValues(formValues).isValidSync(formValues);
    },
    [generateDefaultValues, generateSchemaForValues],
  );

  return isFieldsFormValid;
};
