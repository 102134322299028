import {
  IdDocumentDataSettingsModel,
  IdDocumentVendorEnum,
} from '../../../shared/models';

export const defaultIdDocumentSettings: IdDocumentDataSettingsModel = {
  vendor: IdDocumentVendorEnum.veriff,
  automaticApproval: true,
  automaticRejection: true,
};
