import { CheckTypeEnum } from '../../../shared/models';
import { amlCheckDefinition } from '../aml';
import { documentCheckDefinition } from '../document/document-check-definition';
import { idDocumentCheckDefinition } from '../id-document/id-document-check-definition';
import { idVerificationCheckDefinition } from '../id-verification/id-verification-check-definition';

/**
 * Record of all check definitions indexed by CheckTypeEnum
 * @NOTE Add new CheckType only here and everything should be magic
 */
export const checkDefinitionsRecord = {
  // Order of check matter here because the check will be displayed in
  // this order through the apps, for instance on the template form.
  [CheckTypeEnum.aml]: amlCheckDefinition,
  [CheckTypeEnum.id_verification]: idVerificationCheckDefinition,
  [CheckTypeEnum.id_document]: idDocumentCheckDefinition,
  [CheckTypeEnum.document]: documentCheckDefinition,
  // @TODO - E-2271 - Base interface for check data
  // Instead of unknown in Record<CheckTypeEnum, unknown>, use the base check data type
} as const satisfies Record<CheckTypeEnum, unknown>;

/**
 * All Check definitions type
 */
export type CheckTypeDefinitionMapping = typeof checkDefinitionsRecord;
export type AllCheckTypeDefinitions =
  (typeof checkDefinitionsRecord)[keyof CheckTypeDefinitionMapping];

/**
 * Get the check definition for CheckTypeEnum
 * @param type
 * @returns
 */
export function getCheckDefinition<T extends CheckTypeEnum>(
  type: T,
): CheckTypeDefinitionMapping[T] {
  return checkDefinitionsRecord[type];
}
