import { useCallback } from 'react';

import {
  ClientPortalForms_Step,
  ClientPortalLogicTypeEnum,
} from '@dotfile/shared/data-access-client-portal';
import { jsonLogic } from '@dotfile/shared/domain';

import { useLatestClientPortalVersionForms } from '../hooks';
import { useGlobalDataContext } from './use-global-data-context';

export function useStepsDisplayLogic(): () => ClientPortalForms_Step[] {
  const { data: dataForm } = useLatestClientPortalVersionForms();
  // @NOTE only need to global context since it is computed before the step is displayed (so no local context)
  const globalDataContext = useGlobalDataContext();

  const getFilteredStepsByLogics = useCallback(() => {
    if (dataForm) {
      return dataForm.latestClientPortalVersion.steps.filter((step) => {
        const displayLogic = step.logics.find(
          (logic) => logic.type === ClientPortalLogicTypeEnum.display,
        )?.jsonLogic;
        if (displayLogic) {
          const global = globalDataContext();
          return jsonLogic.apply(displayLogic, {
            local: {},
            global,
          });
        } else {
          // No logic, the step is always displayed and returned
          return true;
        }
      });
    } else {
      return [];
    }
  }, [dataForm, globalDataContext]);

  return getFilteredStepsByLogics;
}
