import React, {
  ReactElement,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Button, ButtonProps } from '../../form/button/button';
import { BoxProps } from '../../layout/box/box';
import { HStack } from '../../layout/stack/stack';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '../modal/modal';

export type FormModalProps = ModalProps &
  BoxProps & {
    isOpen: boolean;
    onClose?: () => void;
    onSubmit: (e?: SyntheticEvent) => Promise<void> | void;
    header: ReactElement | string;
    children?: React.ReactNode;
    cancelText?: string;
    submitText?: string;
    submitIsDisabled?: boolean;
    submitIsLoading?: boolean;
    submitColorScheme?: ButtonProps['colorScheme'];
    submitWithEnter?: boolean;
    hasClose?: boolean;
    hasCloseButton?: boolean;
    textAlign?: BoxProps['textAlign'];
    bodyProps?: ModalContentProps & { ref?: RefObject<HTMLDivElement> };
  };

export const FormModal: React.FC<FormModalProps> = (props) => {
  const ref = useRef<HTMLElement | null>(null);
  const {
    isOpen,
    onClose,
    onSubmit,
    header,
    cancelText,
    submitText,
    submitIsDisabled,
    submitIsLoading,
    submitColorScheme,
    submitWithEnter = true,
    hasClose = true,
    hasCloseButton = true,
    size = 'lg',
    textAlign = 'center',
    bodyProps = {},
    children,
    ...rest
  } = props;

  const handleSubmit = useCallback(
    (e?: SyntheticEvent) => {
      if (submitIsDisabled || submitIsLoading) return;
      onSubmit(e);
    },
    [onSubmit, submitIsDisabled, submitIsLoading],
  );

  useEffect(() => {
    const container = ref.current;
    if (!container) return;
    const keyDownHandler = (event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement | HTMLTextAreaElement;
      if (
        event.key === 'Enter' &&
        submitWithEnter &&
        // @NOTE don't submit form if Enter on Textarea to not block break line
        !['TEXTAREA'].includes(target.tagName)
      ) {
        event.preventDefault();
        handleSubmit(undefined);
      }
    };
    container.addEventListener('keydown', keyDownHandler);
    return () => {
      container.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleSubmit, submitWithEnter]);

  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!submitIsLoading}
      closeOnOverlayClick={!submitIsLoading}
      {...rest}
    >
      <ModalOverlay />
      <ModalContent ref={ref}>
        <ModalHeader>{header}</ModalHeader>
        {hasCloseButton && <ModalCloseButton />}
        <ModalBody textAlign={textAlign} {...bodyProps}>
          {children}
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            {hasClose && (
              <Button
                size="md"
                id="modal-cancel"
                onClick={onClose}
                variant="ghost"
              >
                {cancelText || 'Cancel'}
              </Button>
            )}
            <Button
              id="modal-create"
              data-testid="submit"
              size="md"
              colorScheme={submitColorScheme}
              isDisabled={submitIsDisabled}
              isLoading={submitIsLoading}
              onClick={handleSubmit}
              autoFocus
            >
              {submitText || 'Submit'}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
