import { ClientPortalFieldMappingTypeEnum } from '@dotfile/shared/data-access-client-portal';
import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

// @NOTE: Similar to custom property countries validation but
// with isRequired

export const countriesValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.countries
> = ({ property, mapping, isRequired, localizedValidation }) => {
  const allowMultiple = property.settings?.allowMultiple ?? false;

  let schema;
  if (mapping.type === ClientPortalFieldMappingTypeEnum.custom) {
    schema = yup.array().uniqueItems().of(yup.string().countryCode2());

    if (!allowMultiple) {
      // Custom single choice: only one element but still an array
      schema = schema.max(1);
    }
  }

  // mapping type default
  else if (allowMultiple) {
    schema = yup.array().uniqueItems().of(yup.string().countryCode2());
  } else {
    schema = yup.string().countryCode2();
  }

  // Add required depending on settings
  if (isRequired) {
    schema = schema
      .required(localizedValidation.required)
      .typeError(localizedValidation.required);
  } else {
    schema = schema.nullable();
  }

  return schema.strict();
};
