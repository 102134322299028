import { ClientPortalStepTypeEnum } from '../../../../shared/models';
import {
  clientPortalCompanyPropertiesDefinition,
  clientPortalIndividualPropertiesDefinition,
  ClientPortalProperty,
} from '../../client-portal-properties';

export const defaultStepPropertiesDefinition: {
  [P in ClientPortalStepTypeEnum]: {
    properties: ClientPortalProperty[];
  };
} = {
  company_search: {
    properties: [],
  },
  company_edit: {
    properties: [
      clientPortalCompanyPropertiesDefinition.name,
      {
        ...clientPortalCompanyPropertiesDefinition.registrationNumber,
        // Set has required by default
        alwaysRequired: true,
      },
      {
        ...clientPortalCompanyPropertiesDefinition.country,
        // Set has required by default
        alwaysRequired: true,
      },
      clientPortalCompanyPropertiesDefinition.address,
    ],
  },
  affiliated_companies_edit: {
    properties: [
      clientPortalCompanyPropertiesDefinition.name,
      clientPortalCompanyPropertiesDefinition.registrationNumber,
      clientPortalCompanyPropertiesDefinition.country,
    ],
  },
  business_contact: {
    // @TODO - E-4124 - Client portal: configure blocks for Business contact step
    properties: [
      clientPortalIndividualPropertiesDefinition.firstName,
      clientPortalIndividualPropertiesDefinition.lastName,
      {
        ...clientPortalIndividualPropertiesDefinition.email,
        // Business contact required an email
        alwaysRequired: true,
      },
    ],
  },
  individuals_edit: {
    properties: [
      clientPortalIndividualPropertiesDefinition.firstName,
      clientPortalIndividualPropertiesDefinition.lastName,
      clientPortalIndividualPropertiesDefinition.email,
      clientPortalIndividualPropertiesDefinition.roles,
    ],
  },
  individual_edit: {
    properties: [
      clientPortalIndividualPropertiesDefinition.firstName,
      clientPortalIndividualPropertiesDefinition.lastName,
      {
        ...clientPortalIndividualPropertiesDefinition.email,
        // KYC: email is always required because only one individual which is the business contact
        alwaysRequired: true,
      },
    ],
  },
  custom: { properties: [] },
};
