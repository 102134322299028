import {
  IndividualModel,
  ViewColumnMappingEntityEnum,
  ViewColumnMappingTypeEnum,
} from '../../../shared/models';
import {
  AddressPropertyKey,
  individualDefaultPropertiesDefinition,
  PropertyTypeEnum,
} from '../../../shared/properties';
import { getAddressViewColumnDefinition } from './address-view-column.definition';
import { ViewColumn } from './types';
import {
  NestedPropertyViewColumnPrefix,
  ViewColumnBuilder,
} from './view-column-builder';

type IndividualViewColumn = Omit<
  // @NOTE Also update the test snapshot when modifying the properties
  IndividualModel,
  | 'caseId'
  | 'case'
  | 'workspaceId'
  | 'workspace'
  | 'checks'
  | 'customPropertyValues'
  | 'bankingInformation'
  | 'relations'
  | 'updatedAt'
  | 'deletedAt'
> & {
  name: string;
  checks: unknown;
};

export const individualViewColumnDefinition: Record<
  | Exclude<keyof IndividualViewColumn, 'address'>
  | NestedPropertyViewColumnPrefix<'address', AddressPropertyKey>,
  ViewColumn
> = new ViewColumnBuilder<IndividualViewColumn>(
  ViewColumnMappingEntityEnum.individual,
)
  .addViewColumn('id', individualDefaultPropertiesDefinition.id, {
    canSort: false,
  })
  .addViewColumn(
    'lastActivityAt',
    individualDefaultPropertiesDefinition.lastActivityAt,
  )
  .addViewColumn('createdAt', individualDefaultPropertiesDefinition.createdAt)
  .addViewColumn(
    'isBusinessContact',
    individualDefaultPropertiesDefinition.isBusinessContact,
  )
  .addViewColumn(
    'isBeneficialOwner',
    individualDefaultPropertiesDefinition.isBeneficialOwner,
  )
  .addViewColumn(
    'isDelegator',
    individualDefaultPropertiesDefinition.isDelegator,
  )
  .addViewColumn(
    'isSignatory',
    individualDefaultPropertiesDefinition.isSignatory,
  )
  .addViewColumn('isRelevant', individualDefaultPropertiesDefinition.isRelevant)
  .addViewColumn('roles', individualDefaultPropertiesDefinition.roles)

  .addViewColumn('firstName', individualDefaultPropertiesDefinition.firstName)
  .addViewColumn('middleName', individualDefaultPropertiesDefinition.middleName)
  .addViewColumn('lastName', individualDefaultPropertiesDefinition.lastName)
  .addViewColumn('maidenName', individualDefaultPropertiesDefinition.maidenName)
  .addViewColumn('name', PropertyTypeEnum.text, {
    mapping: {
      type: ViewColumnMappingTypeEnum.computed,
    },
  })

  .addViewColumn('email', individualDefaultPropertiesDefinition.email)
  .addViewColumn('birthDate', individualDefaultPropertiesDefinition.birthDate)
  .addViewColumn('birthPlace', individualDefaultPropertiesDefinition.birthPlace)
  .addViewColumn(
    'birthCountry',
    individualDefaultPropertiesDefinition.birthCountry,
  )

  .addViewColumn(
    'taxIdentificationNumber',
    individualDefaultPropertiesDefinition.taxIdentificationNumber,
  )
  .addViewColumn(
    'socialSecurityNumber',
    individualDefaultPropertiesDefinition.socialSecurityNumber,
  )
  .addViewColumn(
    'phoneNumber',
    individualDefaultPropertiesDefinition.phoneNumber,
  )

  .addViewColumn('position', individualDefaultPropertiesDefinition.position)
  .addViewColumn(
    'ownershipPercentage',
    individualDefaultPropertiesDefinition.ownershipPercentage,
  )
  .addViewColumn(
    'votingRightsPercentage',
    individualDefaultPropertiesDefinition.votingRightsPercentage,
  )
  .addViewColumn('checks', PropertyTypeEnum.unknown, {
    mapping: {
      type: ViewColumnMappingTypeEnum.relation,
    },
  })
  .addNestedViewColumnProperties('address', getAddressViewColumnDefinition)

  .getDefinition();

export const allIndividualViewColumnDefinition = Object.values(
  individualViewColumnDefinition,
);
