import { GroupBase, SelectInstance } from 'chakra-react-select';
import { sortBy } from 'lodash';
import { Ref, useMemo } from 'react';

import { COUNTRIES } from '@dotfile/shared/domain';

import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { GenericSelect, Option, SelectProps } from '../select';

export type CountrySelectProps<IsMulti extends boolean = false> = Omit<
  SelectProps<IsMulti>,
  'defaultValue' | 'value'
> & { defaultValue?: string | string[]; value?: string | string[] };

const defaultOptions = sortBy(
  COUNTRIES.map(({ name, emoji, code }) => ({
    label: `${emoji} ${name}`,
    value: code,
    name,
  })),
  // Sort by country name instead of code the name is visible in the label but the code is hidden
  'name',
);

export const CountrySelect = forwardRefWithGeneric(
  function CountrySelectWithRef<IsMulti extends boolean = false>(
    { ...props }: CountrySelectProps<IsMulti>,
    ref: Ref<SelectInstance<Option, IsMulti>>,
  ): JSX.Element {
    const {
      isSearchable = true,
      placeholder,
      options,
      defaultValue,
      value,
      ...rest
    } = props;

    const resolvedOptions =
      options && options.length ? options : defaultOptions;

    const resolvedDefaultValue = useMemo(() => {
      if (typeof defaultValue === 'string') {
        return resolvedOptions.find(
          (o): o is Option => 'value' in o && o.value === defaultValue,
        );
      } else if (Array.isArray(defaultValue)) {
        return resolvedOptions.filter(
          (o): o is Option => 'value' in o && defaultValue.includes(o.value),
        );
      } else {
        return defaultValue;
      }
    }, [defaultValue, resolvedOptions]);

    const resolvedValue = useMemo(() => {
      if (typeof value === 'string') {
        return resolvedOptions.find(
          (o): o is Option => 'value' in o && o.value === value,
        );
      } else if (Array.isArray(value)) {
        return resolvedOptions.filter(
          (o): o is Option => 'value' in o && value.includes(o.value),
        );
      } else {
        return value;
      }
    }, [value, resolvedOptions]);

    return (
      <GenericSelect<Option, IsMulti, GroupBase<Option>>
        ref={ref}
        placeholder={placeholder ? placeholder : 'Select country'}
        isSearchable={isSearchable}
        options={resolvedOptions}
        defaultValue={resolvedDefaultValue}
        value={resolvedValue}
        isClearable
        {...rest}
      />
    );
  },
);
