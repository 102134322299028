import { ChevronDownIcon } from 'lucide-react';
import { createRef, ForwardedRef, forwardRef } from 'react';

import {
  Badge,
  BadgeProps,
  Box,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { Portal } from '../../utils/portal';

export type BadgePopoverProps = BadgeProps & {
  popover?: Omit<PopoverProps, 'initialFocusRef'> & {
    closeButton?: boolean;
    header?: PopoverProps['children'];
    body: PopoverProps['children'];
    footer?: PopoverProps['children'];
  };
  tooltip?: string;
  isLoading?: boolean;
  isDisable?: boolean;
};
export const BadgePopover = forwardRef(
  (
    {
      popover: { header, body, footer, ...popover } = {
        closeOnBlur: true,
        placement: 'bottom-start',
        header: null,
        footer: null,
        body: null,
      },
      tooltip,
      variant = 'subtle',
      isLoading,
      isDisable,
      children,
      colorScheme,
      ...badgeProps
    }: BadgePopoverProps,
    ref: ForwardedRef<{ focus(): void }>,
  ) => {
    const initRef = createRef<{ focus(): void }>();

    let badge = (
      <Badge
        ref={ref}
        cursor={isDisable ? 'not-allowed' : isLoading ? 'wait' : 'pointer'}
        borderRadius="4px"
        height="6"
        p="1"
        variant={variant}
        colorScheme={colorScheme}
        transition="background-color 0.1s linear"
        _hover={{
          backgroundColor: isDisable ? undefined : `${colorScheme}.200`,
        }}
        {...badgeProps}
      >
        <HStack spacing="1">
          <Text
            as="div"
            color="inherit"
            pl="3px" // add left padding to counterbalance the size of chevron down icon
          >
            {children}
          </Text>
          {isDisable ? null : isLoading ? (
            <Spinner size="xs" />
          ) : (
            <Icon as={ChevronDownIcon} />
          )}
        </HStack>
      </Badge>
    );

    if (tooltip) {
      badge = <Tooltip label={tooltip}>{badge}</Tooltip>;
    }

    if (isDisable) {
      return badge;
    }

    return (
      <Popover {...popover} initialFocusRef={initRef}>
        {(renderProps) => (
          <>
            <PopoverTrigger>
              <Box>{badge}</Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="max-content">
                {header && (
                  <PopoverHeader
                    padding={0}
                    borderBottomWidth={!body ? '0' : undefined}
                  >
                    {typeof header === 'function'
                      ? header(renderProps)
                      : header}
                  </PopoverHeader>
                )}
                {body && (
                  <PopoverBody padding={0}>
                    {typeof body === 'function' ? body(renderProps) : body}
                  </PopoverBody>
                )}
                {footer && (
                  <PopoverFooter
                    padding={0}
                    borderTopWidth={!header && !body ? '0' : undefined}
                  >
                    {typeof footer === 'function'
                      ? footer(renderProps)
                      : footer}
                  </PopoverFooter>
                )}
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    );
  },
);

BadgePopover.displayName = 'BadgePopover';
