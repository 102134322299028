import { PlusIcon } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAsyncFn } from '@dotfile/frontend/shared/common';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Icon,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { FullContainer } from '../../../shared';
import {
  StepFooter,
  StepHeader,
  StepProps,
  useFormDatastore,
  useFormDrawerState,
  useHandleSaveForLater,
  useIsFieldsFormValid,
  useRemoveDialogState,
} from '../shared';
import { IndividualFormDrawer } from './individual-form-drawer';
import { IndividualItem } from './individual-item';
import { RemoveIndividualDialog } from './remove-individual-dialog';

export const IndividualsEditStep = ({ step, onSubmitStep }: StepProps) => {
  const { t } = useTranslation();

  const individuals = useFormDatastore((state) => state.data.individuals);
  const drawerState = useFormDrawerState();
  const removeDialogState = useRemoveDialogState();

  const [submitAttempted, setSubmitAttempted] = useState(false);
  const noIndividualAlertRef = useRef<HTMLDivElement>(null);
  const firstInvalidIndividualRef = useRef<HTMLDivElement>(null);

  const isFieldsFormValidForIndividual = useIsFieldsFormValid(step);
  const invalidIndexes = useMemo(() => {
    const invalidIndexes: number[] = [];
    individuals?.forEach((individual, index) => {
      const isValid = isFieldsFormValidForIndividual({
        case: undefined,
        individual, // Only individual fields can be added on this step
      });

      if (!isValid) {
        invalidIndexes.push(index);
      }
    });
    return invalidIndexes;
  }, [individuals, isFieldsFormValidForIndividual]);

  const validateSubmit = useCallback(() => {
    setSubmitAttempted(true);

    if (!individuals?.length) {
      setTimeout(() => {
        // Make sure to scroll after alert has been rendered
        noIndividualAlertRef.current?.scrollIntoView?.({
          behavior: 'smooth',
          block: 'start',
        });
      }, 50);
      return false;
    } else if (invalidIndexes.length > 0) {
      firstInvalidIndividualRef.current?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'start',
      });
      return false;
    } else {
      return true;
    }
  }, [individuals?.length, invalidIndexes.length]);

  const [{ loading: isNextLoading }, handleNext] = useAsyncFn(
    useCallback(async () => {
      if (validateSubmit()) {
        await onSubmitStep();
      }
    }, [validateSubmit, onSubmitStep]),
  );

  const [handleSaveForLater, saveForLaterState] = useHandleSaveForLater();
  const handleSave = useCallback(() => {
    if (validateSubmit()) {
      handleSaveForLater();
    }
  }, [validateSubmit, handleSaveForLater]);

  return (
    <>
      <FullContainer
        footer={
          <StepFooter
            onNext={handleNext}
            isNextLoading={isNextLoading}
            saveForLaterState={saveForLaterState}
            onSaveForLater={handleSave}
          />
        }
      >
        <StepHeader />

        {submitAttempted && !individuals?.length && (
          <Alert status="error" ref={noIndividualAlertRef}>
            <HStack>
              <AlertIcon />
              <VStack gap={0} align="stretch" width="full">
                <AlertTitle>
                  {t('forms.missing_information', {
                    ns: 'client-portal',
                    defaultValue: 'Missing information',
                  })}
                </AlertTitle>
                <AlertDescription>
                  {t('forms.individuals_edit.no_individuals', {
                    ns: 'client-portal',
                    defaultValue:
                      'You need to add at least one individual to continue.',
                  })}
                </AlertDescription>
              </VStack>
            </HStack>
          </Alert>
        )}

        {individuals?.map((individual, index) => {
          const indexInInvalidIndexes = invalidIndexes.indexOf(index);
          const hasMissingInformation = indexInInvalidIndexes !== -1;
          return (
            <IndividualItem
              key={index}
              index={index}
              individual={individual}
              onEdit={() => drawerState.handleEdit(index)}
              onRemove={() => {
                removeDialogState.handleOpen({
                  index,
                  label: [individual.firstName, individual.lastName]
                    .filter(Boolean)
                    .join(' '),
                });
              }}
              hasMissingInformation={hasMissingInformation}
              borderColor={
                submitAttempted && hasMissingInformation
                  ? 'orange.700'
                  : undefined
              }
              ref={
                indexInInvalidIndexes === 0
                  ? firstInvalidIndividualRef
                  : undefined
              }
            />
          );
        })}

        <IndividualFormDrawer
          isOpen={drawerState.isOpen}
          onClose={drawerState.handleClose}
          step={step}
          selectedIndividualIndex={drawerState.selectedIndex}
        />

        <Button
          alignSelf="start"
          leftIcon={<Icon as={PlusIcon} />}
          variant="outline"
          colorScheme="coal"
          onClick={drawerState.handleAdd}
        >
          {t('forms.add_individual', {
            ns: 'client-portal',
            defaultValue: 'Add individual',
          })}
        </Button>
      </FullContainer>

      <RemoveIndividualDialog
        isOpen={removeDialogState.isOpen}
        onClose={removeDialogState.handleClose}
        individual={removeDialogState.item}
      />
    </>
  );
};
