import { lowerCase, upperFirst } from 'lodash';
import { DeepPartial } from 'ts-essentials';

import { removeTypenameFromData } from '@dotfile/frontend/shared/common';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Heading,
  HStack,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import {
  CheckResultEnum,
  CheckStatusEnum,
  IdDocumentDataDetailedResultsModel,
  IdVerificationDataDetailedResultsModel,
} from '@dotfile/shared/domain';

import { DetailedResultsKey } from './types';

const detailedResultsTitle: Record<DetailedResultsKey, string> = {
  document: 'Document checks',
  face: 'Face checks',
};

/**
 * @TODO - E-5020 - Remove legacy detailed results and migrations
 * @deprecated
 */
export const DetailedResults = ({
  detailedResults,
  status,
}: {
  detailedResults: DeepPartial<
    IdDocumentDataDetailedResultsModel | IdVerificationDataDetailedResultsModel
  >;
  status: CheckStatusEnum;
}) => {
  const isInProgress = (
    [
      CheckStatusEnum.in_progress,
      CheckStatusEnum.processing,
      // @TODO - E-2270 - Add @total-typescript/ts-reset
      // `as` should not be necessary anymore
    ] as CheckStatusEnum[]
  ).includes(status);

  return (
    <Accordion width="full" variant="unstyled" allowToggle>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionPanel px="0" pb="2" as={VStack} align="start" spacing="4">
              {Object.entries(removeTypenameFromData(detailedResults)).map(
                ([key, resultRecord]) => {
                  const title = detailedResultsTitle[key as DetailedResultsKey];

                  const hasRejected = Object.values(resultRecord).some(
                    (result) =>
                      result &&
                      (
                        [
                          CheckResultEnum.error,
                          CheckResultEnum.rejected,
                        ] as CheckResultEnum[]
                      ).includes(result),
                  );

                  return (
                    <VStack key={key} align="start">
                      <Heading size="sm" fontWeight="semibold">
                        {title}
                      </Heading>
                      {Object.entries(resultRecord)
                        .filter(([_key, value]) =>
                          hasRejected
                            ? // Display only error/rejected when detailedResults has rejected element
                              (
                                [
                                  CheckResultEnum.error,
                                  CheckResultEnum.rejected,
                                  // @TODO - E-2270 - Add @total-typescript/ts-reset
                                  // `as` should not be necessary anymore
                                ] as CheckResultEnum[]
                              ).includes(value as CheckResultEnum)
                            : // Do not display unknown result
                              value,
                        )
                        .map(([key, value]) => {
                          return (
                            <HStack key={key}>
                              <Box w="20">
                                <Badge
                                  colorScheme={
                                    value === CheckResultEnum.approved
                                      ? 'green'
                                      : (
                                            [
                                              CheckResultEnum.error,
                                              CheckResultEnum.rejected,
                                              // @TODO - E-2270 - Add @total-typescript/ts-reset
                                              // `as` should not be necessary anymore
                                            ] as CheckResultEnum[]
                                          ).includes(value as CheckResultEnum)
                                        ? 'red'
                                        : 'gray'
                                  }
                                >
                                  {upperFirst(value ?? 'no data')}
                                </Badge>
                              </Box>
                              <Text>{upperFirst(lowerCase(key))}</Text>
                            </HStack>
                          );
                        })}
                    </VStack>
                  );
                },
              )}
            </AccordionPanel>
            {isInProgress ? null : (
              <AccordionButton px="0">
                <AccordionIcon />
                {/* @TODO - E-3452 - Design: SeeMore component */}
                {isExpanded ? 'See less' : 'See more'}
              </AccordionButton>
            )}
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
