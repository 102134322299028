import { WorkflowActionTypeEnum } from '../../../shared/models';
import { subEnum } from '../../../shared/models/enum/sub-enum.helper';

export const ClientPortalWorkflowCodeActionEnum = subEnum(
  WorkflowActionTypeEnum,
  [
    'add_tag',
    'create_aml_check',
    'create_document_check',
    'create_id_document_check',
    'create_id_verification_check',
    'exit',
    'log',
    'lookup',
    'set_template',
  ],
);
