import { FC, useMemo } from 'react';

import {
  createGraphqlClient,
  GraphQLClientOptions,
  logAndAddError,
} from '@dotfile/frontend/shared/common';
import { possibleTypes } from '@dotfile/shared/data-access-client-portal';

import { environment } from '../../../environments/environment';
import { onRefreshAuthFailed } from './auth';

const cacheConfig: GraphQLClientOptions['cacheConfig'] = {
  typePolicies: {
    Query: {
      fields: {},
    },
  },
  possibleTypes: possibleTypes,
};

export const GraphQLProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Handle custom domain for baseAPI for GraphQL
  // @NOTE we need /graphql to be on the same origin to let first-party cookies pass (refresh token) for SaveForLater & formCompleted mutation that set the cookie
  const isDefaultDomain = environment.baseApp === window.location.origin;
  let baseAPI = environment.baseAPI;
  if (!isDefaultDomain) {
    baseAPI = window.location.origin;
  }

  const GraphQLProvider = useMemo(
    () =>
      createGraphqlClient({
        environment: { ...environment, baseAPI },
        cacheConfig,
        httpOptions: {
          // Always include cross-origin credentials since the API will respond with the
          // authentication cookie for the createCaseAndAuthenticate mutation
          // @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#including_credentials
          credentials: 'include',
        },
        onRefreshAuthFailed,
        onGraphQLError: (apolloClient, graphQLError) => {
          if (/No vendor supported for country/.test(graphQLError.message)) {
            // @TODO - E-4696 - Client portal: company search get countries with vendor
            // This should not be necessary since this error should not be throw anymore
            // so we will want to be alerted if it happen

            // @TODO - E-69 - better handle of error
            // Currently, the extensions.code for this error is INTERNAL_SERVER_ERROR so we need to ignore it explicitly
            // from its error message but ideally it should be BAD_REQUEST and we could not log all BAD_REQUEST error codes
            return;
          }

          const error = new Error(
            `GraphQLError operation: ${graphQLError.message}`,
          );
          error.cause = graphQLError;
          logAndAddError(error);
        },
      }),
    [baseAPI],
  );

  return <GraphQLProvider>{children}</GraphQLProvider>;
};
