import { useCallback } from 'react';

import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  CaseChecksQueryDocument,
  SendCollaborationEmailInput,
  useSendCollaborationEmailMutation,
} from '@dotfile/shared/data-access-client-portal';

export const useSendCollaborationEmail = () => {
  const [runMutation, result] = useSendCollaborationEmailMutation();
  const handleError = useHandleError();

  const sendCollaborationEmail = useCallback(
    async (input: SendCollaborationEmailInput) => {
      try {
        const { data } = await runMutation({
          variables: {
            input,
          },
          refetchQueries: [CaseChecksQueryDocument],
          awaitRefetchQueries: true,
        });

        return data?.sendCollaborationEmail.sent;
      } catch (error) {
        handleError({
          error: error,
          title: (error as Error).message,
        });
      }

      return false;
    },
    [runMutation, handleError],
  );

  return [sendCollaborationEmail, result] as const;
};
