import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

// @NOTE: Similar to custom property date validation but
// with isRequired

export const dateValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.date
> = ({ isRequired, localizedValidation }) => {
  let schema = yup
    .string()
    .nullable()
    .absoluteDate(localizedValidation.date.invalid);

  // Add required depending on settings
  if (isRequired) {
    schema = schema.required(localizedValidation.required);
  }

  return schema.strict();
};
