import { Flag, LucideIcon } from 'lucide-react';
import { AnySchema, SchemaOf } from 'yup';

import { COUNTRIES } from '../../../../shared/country';
import {
  CustomPropertyCountriesSettings,
  CustomPropertyTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import {
  CustomPropertyFormatValueParam,
  CustomPropertyValueSchemaParam,
} from './type';

export class CountriesCustomPropertyDefinition extends BaseCustomPropertyDefinition<CustomPropertyCountriesSettings> {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.countries;
  }

  get label(): string {
    return 'Countries';
  }

  get icon(): LucideIcon {
    return Flag;
  }

  // Settings
  // --------

  override get defaultSettings(): CustomPropertyCountriesSettings {
    return { allowMultiple: false };
  }

  public override settingsSchema(): SchemaOf<CustomPropertyCountriesSettings> {
    const schema = yup.object().shape({
      allowMultiple: yup.boolean().required(),
    });
    return schema;
  }

  // Value
  // -----

  public valueSchema(
    customProperty: CustomPropertyValueSchemaParam,
  ): AnySchema {
    const { allowMultiple } = this.settingsSchema().validateSync(
      customProperty.settings,
    );

    let baseSchema = yup.array().uniqueItems();

    if (!allowMultiple) {
      // Single country: only one element but still an array
      baseSchema = baseSchema.max(1);
    }

    return baseSchema.of(yup.string().countryCode2()).nullable().strict();
  }

  override get multiValues(): boolean {
    return true;
  }

  public override formatValue({
    value,
  }: CustomPropertyFormatValueParam): string {
    return COUNTRIES.find(({ code }) => code === value)?.name ?? value;
  }
}

export const countriesCustomPropertyDefinition =
  new CountriesCustomPropertyDefinition();
