import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonProps,
  Text,
} from '@dotfile/frontend/shared/design-system';

const RetryButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  const [seconds, setSeconds] = useState(10);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer); // Clean up the timer on unmount
    } else {
      setIsButtonEnabled(true);
      return;
    }
  }, [seconds]);

  return isButtonEnabled ? (
    <Button {...props} size="sm" color="black">
      {t('login.retry', {
        ns: 'client-portal',
        defaultValue: 'Retry',
      })}
    </Button>
  ) : (
    <Text fontSize="sm">
      {t('login.retry_delay', {
        ns: 'client-portal',
        defaultValue: 'Retry in {{ count }} seconds.',
        count: seconds,
      })}
    </Text>
  );
};

export default RetryButton;
