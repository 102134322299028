import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@dotfile/frontend/shared/common';
import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  CompanyDataInput,
  IndividualDataInput,
  useSaveForLaterMutation,
  useSaveForLaterPublicMutation,
} from '@dotfile/shared/data-access-client-portal';

import { ContactAuthContext } from '../../../../../../shared';
import { useFormDatastoreApi } from '../../../context';

export const useSaveForLater = () => {
  const handleError = useHandleError();
  const { t } = useTranslation();

  const [saveForLater, saveForLaterResult] = useSaveForLaterMutation();
  const [saveForLaterPublic, saveForLaterPublicResult] =
    useSaveForLaterPublicMutation();

  const storeApi = useFormDatastoreApi();
  const { auth, setAuthToken } = useAuth<ContactAuthContext>();

  const run = useCallback(async (): Promise<boolean> => {
    try {
      const {
        data: storeData, // Must use getState to get up-to-date data, otherwise the closure contains stale value
        setFromCaseQuery,
      } = storeApi.getState();

      const runMutation = auth.isAuthenticated
        ? saveForLater
        : saveForLaterPublic;

      // Update case
      const { data } = await runMutation({
        variables: {
          input: {
            ...storeData.case,
            // Store contains data typed as fully partial but by construction, the client-portal
            // should collect all required fields / attributes
            companies: (storeData.companies ?? []) as CompanyDataInput[],
            individuals: (storeData.individuals ?? []) as IndividualDataInput[],
          },
        },
      });
      if (!data) {
        // data could be missing in result in some case
        // https://www.apollographql.com/docs/react/data/mutations/#mutationhookoptions-interface-ignoreresults
        throw new Error(`No data from save for later`);
      }

      const {
        auth: { accessToken, contactId, caseId, expiresAt },
        case: caseQuery,
      } = 'saveForLater' in data ? data.saveForLater : data.saveForLaterPublic;

      // Set authenticated
      setAuthToken({
        accessToken,
        contactId,
        caseId,
        expiresAt,
      });

      // Sync case data with Store
      setFromCaseQuery(caseQuery);

      return true;
    } catch (error) {
      handleError({
        error,
        title: t('forms.save_for_later.error', {
          ns: 'client-portal',
          defaultValue:
            'Unable to save your data, please retry later or contact support if the problem persist',
        }),
        duration: null,
      });

      return false;
    }
  }, [
    t,
    auth.isAuthenticated,
    saveForLater,
    saveForLaterPublic,
    setAuthToken,
    storeApi,
    handleError,
  ]);

  return {
    run,
    // Should always call only one of the 2 mutations
    data: saveForLaterResult.data || saveForLaterPublicResult.data,
    error: saveForLaterResult.error || saveForLaterPublicResult.error,
    loading: saveForLaterResult.loading || saveForLaterPublicResult.loading,
  };
};
