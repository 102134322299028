import { Plus } from 'lucide-react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ElementOf } from 'ts-essentials';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Avatar,
  forwardRef,
  HStack,
  Icon,
  SelectMenu,
  StackProps,
  Text,
} from '@dotfile/frontend/shared/design-system';
import { fullNameHelper } from '@dotfile/shared/domain';

import { FormDatastoreIndividual, useFormDatastore } from '../../context';
import { SaveForLaterAddBusinessContactFormValues } from './type';

export const NO_INDIVIDUAL_INDEX = -1;
export const NEW_INDIVIDUAL_INDEX = -2;

export type IndividualItemProps = {
  individual: Partial<FormDatastoreIndividual> | null | undefined;
} & StackProps;

export const IndividualItem = forwardRef(
  ({ individual, ...props }: IndividualItemProps, ref) => {
    const { t } = useTranslation();

    return (
      <HStack py="1" {...props} ref={ref}>
        {individual ? (
          <>
            <Avatar name={fullNameHelper(individual)} />
            <Text color="black" maxWidth="200px" noOfLines={1}>
              {fullNameHelper(individual)}
            </Text>
          </>
        ) : (
          <>
            <Icon as={Plus} />
            <Text color="black" maxWidth="200px" noOfLines={1}>
              {t(`forms.save_for_later.add_business_contact.select.add`, {
                ns: 'client-portal',
                defaultValue: 'Add new individual',
              })}
            </Text>
          </>
        )}
      </HStack>
    );
  },
);

export const IndividualSelectGroupController = () => {
  const { t } = useTranslation();
  const { setValue, control } =
    useFormContext<SaveForLaterAddBusinessContactFormValues>();

  const individuals = useFormDatastore((state) => state.data.individuals);
  const individualOptions: {
    value: string;
    individual: Partial<FormDatastoreIndividual> | null;
  }[] = useMemo(
    () => [
      ...(individuals
        ?.filter((i) => !i.isDelegator)
        .map((individual, index) => ({
          value: index.toString(),
          individual,
        })) || []),
      {
        value: NEW_INDIVIDUAL_INDEX.toString(),
        individual: null,
      },
    ],
    [individuals],
  );

  const onSelectBusinessContact = ({ value }: { value: string }) => {
    const index = isNaN(Number(value)) ? NO_INDIVIDUAL_INDEX : Number(value);

    setValue('_individualIndex', index, {
      shouldDirty: true,
      shouldValidate: true,
    });

    const individual = individuals?.[index];
    setValue('firstName', individual?.firstName ?? '', {
      shouldValidate: true,
    });
    setValue('lastName', individual?.lastName ?? '', {
      shouldValidate: true,
    });
    setValue('email', individual?.email ?? '', {
      shouldValidate: true,
    });

    setValue('_showEmail', individual?.email ? false : true);
  };

  return (
    <GroupController
      isRequired
      name="_individualIndex"
      label={t(`forms.save_for_later.add_business_contact.select.label`, {
        ns: 'client-portal',
        defaultValue: 'Individual',
      })}
      control={control}
      render={(field) => (
        <SelectMenu
          {...field}
          buttonProps={{ width: 'full' }}
          value={(field.value ?? NO_INDIVIDUAL_INDEX).toString()}
          variant="select"
          onChange={onSelectBusinessContact}
          options={individualOptions}
          // @NOTE: option require to explicitly define typing, inferred will resolve to {value: Type} from SelectMenu
          renderOption={(option: ElementOf<typeof individualOptions>) => (
            <IndividualItem individual={option.individual} />
          )}
        >
          {field.value !== NO_INDIVIDUAL_INDEX && individuals ? (
            <IndividualItem individual={individuals[Number(field.value)]} />
          ) : (
            <Text align="start">
              {t(
                `forms.save_for_later.add_business_contact.select.placeholder`,
                {
                  ns: 'client-portal',
                  defaultValue: 'Select the business contact',
                },
              )}
            </Text>
          )}
        </SelectMenu>
      )}
    />
  );
};
