import { ReactNode, SyntheticEvent, useRef } from 'react';

import { useAsyncFn } from '@dotfile/frontend/shared/common';

import { Button, ButtonProps } from '../../form/button/button';
import { HStack, VStack } from '../../layout/stack/stack';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
} from '../alert-dialog/alert-dialog';

export type ConfirmDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (e: SyntheticEvent) => Promise<void>;
  header: ReactNode;
  cancelText: string;
  confirmText: string;
  children: ReactNode;
  confirmColorScheme?: ButtonProps['colorScheme'];
  isConfirmDisabled?: boolean;
  noCloseCross?: boolean;
} & Omit<AlertDialogProps, 'leastDestructiveRef'>;

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  header,
  cancelText,
  confirmText,
  confirmColorScheme,
  children,
  isConfirmDisabled,
  noCloseCross = false,
  ...rest
}: ConfirmDialogProps) => {
  const cancelRef = useRef(null);

  const [{ loading: isDeleting }, doConfirm] = useAsyncFn(onConfirm);

  return (
    <AlertDialog
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      size="lg"
      {...rest}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{header}</AlertDialogHeader>
          {!noCloseCross && <AlertDialogCloseButton />}
          <AlertDialogBody>
            <VStack spacing={6} alignItems="start">
              {children}
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack spacing={4}>
              <Button
                size="md"
                ref={cancelRef}
                onClick={onClose}
                variant="ghost"
              >
                {cancelText}
              </Button>
              <Button
                size="md"
                colorScheme={confirmColorScheme}
                onClick={doConfirm}
                isLoading={isDeleting}
                isDisabled={isConfirmDisabled}
              >
                {confirmText}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
