import { snakeCase } from 'lodash';
import { DeepPartial, DeepReadonly, StrictDeepPick } from 'ts-essentials';
import { match } from 'ts-pattern';

import { deepFreeze, humanCase } from '@dotfile/shared/common';

import {
  ViewColumnMappingEntityEnum,
  ViewColumnMappingTypeEnum,
} from '../../../shared/models';
import { Property, PropertyTypeEnum } from '../../../shared/properties';
import { NESTED_PROPERTY_SEPARATOR } from '../../../shared/properties/default-properties-builder';
import { generateViewColumnKeyFromMapping } from './mapping-key';
import { ViewColumn } from './types';

type BuildColumnInput<TType extends PropertyTypeEnum = PropertyTypeEnum> =
  DeepPartial<
    StrictDeepPick<
      ViewColumn<TType, TType>,
      {
        label: true;
        mapping: { key: true; type: true };
        filter: {
          type: true;
          settings: true;
        };
      }
    >
  > &
    Pick<Property<TType>, 'settings'> & {
      /**
       * Optional override `canSort` from type
       */ canSort?: boolean;
    };

type PreBuildViewColumn<
  T extends PropertyTypeEnum = PropertyTypeEnum,
  F extends PropertyTypeEnum = PropertyTypeEnum,
> = Omit<ViewColumn<T, F>, 'canFilter' | 'canSort'>;

/**
 * Prefix each element of a union type with a given prefix separated by a dot
 */
export type NestedPropertyViewColumnPrefix<
  Prefix extends string,
  K,
> = K extends string ? `${Prefix}${typeof NESTED_PROPERTY_SEPARATOR}${K}` : K;

/**
 * Build the view columns definition for an entity
 *
 * It has a strong typing:
 * - the name of the properties depends on the model as first parameter
 * - the settings type will depends on the property type
 * - the definition will have a type with all the name of the property added (can `satisfy` a specific interface or subset of the model )
 */
export class ViewColumnBuilder<TModel, TPropertyNames extends string = never> {
  private readonly columns: Record<string, ViewColumn> = {};

  constructor(private readonly entity: ViewColumnMappingEntityEnum) {}

  /**
   * Add a property on the definition
   * @param name Property name on the Typescript model
   * @param type Type
   * @param property.label Override label (default is human-case from the property name)
   * @param property.mapping.key Override mapping key (default is snake-case from the property name)
   * @param property.alwaysRequired Optionally mark this property as always required
   * @param property.settings Optional settings depending on the `type`
   *
   * @returns
   */
  public addViewColumn<
    const TName extends Exclude<keyof TModel & string, TPropertyNames>,
    TType extends PropertyTypeEnum,
  >(
    name: TName,
    typeOrProperty: TType | Property<TType>,
    column?: BuildColumnInput,
  ): ViewColumnBuilder<TModel, TPropertyNames | TName> {
    const isProperty =
      typeof typeOrProperty === 'object' && 'type' in typeOrProperty;
    const type: PropertyTypeEnum = isProperty
      ? typeOrProperty.type
      : typeOrProperty;

    const key =
      column?.mapping?.key ??
      (isProperty ? typeOrProperty.mapping.key : undefined) ??
      snakeCase(name);

    const mappingType =
      column?.mapping?.type ??
      (isProperty ? typeOrProperty.mapping.type : undefined) ??
      ViewColumnMappingTypeEnum.default;

    const label =
      column?.label ??
      (isProperty ? typeOrProperty.label : undefined) ??
      humanCase(key);

    const mapping = {
      entity: this.entity,
      type: mappingType,
      key,
    };
    const preColumn: PreBuildViewColumn = {
      key: generateViewColumnKeyFromMapping(mapping),
      type,
      mapping,
      label,
    };

    if (column?.settings) {
      preColumn.settings = column.settings;
    }

    if (isProperty && typeOrProperty.settings) {
      preColumn.settings = typeOrProperty.settings;
    }

    if (column?.filter) {
      preColumn.filter = {
        type: column.filter.type ?? type,
        settings: column.filter
          .settings as Property<PropertyTypeEnum>['settings'],
      };
    }

    this.columns[name] = {
      ...preColumn,

      canSort: column?.canSort ?? canViewColumnBeSortedNatively(preColumn),
      canFilter: canViewColumnBeFilteredNatively({
        type: column?.filter?.type ?? type,
      }),
    };

    return this as ViewColumnBuilder<TModel, TPropertyNames | TName>;
  }

  public addNestedViewColumnProperties<
    const TName extends keyof TModel & string,
  >(
    name: TName,
    viewColumnDefinition: (
      entity: ViewColumnMappingEntityEnum,
    ) => Record<keyof TModel[TName], ViewColumn>,
  ): ViewColumnBuilder<
    TModel,
    | TPropertyNames
    | NestedPropertyViewColumnPrefix<TName, keyof TModel[TName] & string>
  > {
    Object.entries(viewColumnDefinition(this.entity)).forEach(
      ([key, value]) => {
        const nestedKey =
          `${name}${NESTED_PROPERTY_SEPARATOR}${key}` as Exclude<
            keyof TModel & string,
            TPropertyNames
          >;

        this.addViewColumn(nestedKey, (value as ViewColumn).type, {
          ...(value as ViewColumn),
          mapping: { key: nestedKey },
          label: `${humanCase(name)} > ${(value as ViewColumn).label}`,
        });
      },
    );

    return this as ViewColumnBuilder<
      TModel,
      | TPropertyNames
      | NestedPropertyViewColumnPrefix<TName, keyof TModel[TName] & string>
    >;
  }

  public getDefinition(): DeepReadonly<Record<TPropertyNames, ViewColumn>> {
    // deep freeze definition to prevent accidental mutation and allow to safely cast
    // Object.keys(definition) as Array<keyof typeof definition>
    return deepFreeze<Record<TPropertyNames, ViewColumn>>(this.columns);
  }
}

/**
 * Native sorting support: all columns except when type=unknown or type=choices/countries with allowMultiple=true
 *
 * Some column can override this with `canSort` in the property definition but then special logic for that needs to be
 * implemented on the backend or frontend
 *
 * @param viewColumn
 * @returns
 */
export const canViewColumnBeSortedNatively = (
  viewColumn: Pick<PreBuildViewColumn, 'type' | 'settings'>,
): boolean => {
  return match(viewColumn)
    .when(
      isViewColumn(PropertyTypeEnum.choices),
      (viewColumn) => !viewColumn.settings?.allowMultiple,
    )
    .when(
      isViewColumn(PropertyTypeEnum.countries),
      (viewColumn) => !viewColumn.settings?.allowMultiple,
    )
    .when(isViewColumn(PropertyTypeEnum.unknown), () => false)
    .otherwise(() => true);
};
function isViewColumn<T extends PropertyTypeEnum, F extends PropertyTypeEnum>(
  type: T,
) {
  return (
    viewColumn: Pick<PreBuildViewColumn, 'type' | 'settings'>,
  ): viewColumn is Pick<PreBuildViewColumn<T, F>, 'type' | 'settings'> =>
    viewColumn.type === type;
}

/**
 * Native filtering support: all columns except when type=unknown
 *
 * Some column can override this with `filter` in the property definition but then special logic for that needs to be
 * implemented on the backend or frontend
 *
 * @param viewColumn
 * @returns
 */
export const canViewColumnBeFilteredNatively = (
  viewColumn: Pick<PreBuildViewColumn, 'type'>,
): boolean => {
  return match(viewColumn.type)
    .with(PropertyTypeEnum.unknown, () => false)
    .otherwise(() => true);
};
