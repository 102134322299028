import { CheckIcon } from 'lucide-react';
import { AutoSizer, List } from 'react-virtualized';

import {
  Box,
  HStack,
  Icon,
  Text,
  Tooltip,
} from '@dotfile/frontend/shared/design-system';

// @TODO - E-4125 - Virtualized list (classifications) - Keyboard navigation
export const VirtualizedMenuList = <
  Option extends { label: string; value: string } = {
    label: string;
    value: string;
  },
>({
  onChange,
  options,
  selected,
}: {
  onChange: (value: Option) => void;
  options: Option[];
  selected?: string;
}) => {
  const selectedIndex = options.findIndex(
    (option) => option.value === selected,
  );

  return (
    <Box
      borderRadius="base"
      border="1px solid"
      borderColor="gray.100"
      bgColor="white"
    >
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            height={400}
            width={width}
            rowHeight={40}
            rowCount={options.length}
            scrollToIndex={Math.max(selectedIndex, 0)}
            rowRenderer={({ index, key, style }) => (
              <Box
                key={key}
                px="4"
                display="flex"
                alignItems="center"
                cursor="pointer"
                style={style}
                _hover={{ bgColor: 'gray.100' }}
                onClick={() => onChange(options[index])}
                textOverflow="ellipsis"
                maxWidth="100%"
                whiteSpace="nowrap"
              >
                <Tooltip
                  label={
                    options[index].label.length >= 50
                      ? options[index].label
                      : ''
                  }
                >
                  <HStack>
                    {selectedIndex === index ? (
                      <Icon as={CheckIcon} />
                    ) : (
                      <Box minW="4" />
                    )}
                    <Text color="gray.600">{options[index].label}</Text>
                  </HStack>
                </Tooltip>
              </Box>
            )}
          />
        )}
      </AutoSizer>
    </Box>
  );
};
