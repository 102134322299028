import { CopyIcon, EllipsisVertical, SendIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@dotfile/frontend/shared/common';
import {
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
  useIsMobile,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { nameFormat } from '@dotfile/shared/common';
import { ClientPortalChecks_Individual } from '@dotfile/shared/data-access-client-portal';
import { CaseRelationRoleEnum } from '@dotfile/shared/domain';

import {
  BeneficialOwnerPill,
  ContactAuthContext,
  LegalRepresentativePill,
  ShareholderPill,
} from '../../../../shared';
import { useShareClientPortalLink } from '../hooks';

export const BusinessContactItem = ({
  businessContact,
  onEmailUser,
}: {
  onEmailUser: () => void;
  businessContact: Pick<
    ClientPortalChecks_Individual,
    'id' | 'firstName' | 'lastName' | 'roles' | 'email' | 'isBeneficialOwner'
  >;
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [shareClientPortalLink, { loading }] = useShareClientPortalLink();
  const menuDisclosure = useDisclosure();
  const {
    auth: { contactId },
  } = useAuth<ContactAuthContext>();
  const isCurrentContact = businessContact.id === contactId;

  return (
    <HStack width="full" justifyContent="space-between" px="4">
      <VStack align="start" spacing="1">
        <HStack flexWrap={isMobile ? 'wrap' : 'nowrap'}>
          <Text color="black">
            {nameFormat(businessContact.firstName, businessContact.lastName)}
          </Text>
          {businessContact.isBeneficialOwner && <BeneficialOwnerPill />}
          {businessContact.roles.includes(
            CaseRelationRoleEnum.legal_representative,
          ) && <LegalRepresentativePill />}
          {businessContact.roles.includes(CaseRelationRoleEnum.shareholder) && (
            <ShareholderPill />
          )}
        </HStack>
        <Text fontSize="sm">{businessContact.email}</Text>
      </VStack>
      {!isCurrentContact && (
        <Menu
          strategy="fixed"
          closeOnSelect={false}
          onClose={menuDisclosure.onClose}
          isOpen={menuDisclosure.isOpen}
          placement="bottom-end"
          isLazy
        >
          <MenuButton
            as={IconButton}
            variant="ghost"
            onClick={menuDisclosure.onOpen}
            icon={<Icon as={EllipsisVertical} />}
          />
          <MenuList>
            <MenuItem
              isDisabled={loading}
              onClick={async () => {
                await shareClientPortalLink(businessContact);
                menuDisclosure.onClose();
              }}
            >
              <HStack>
                {loading ? <Spinner size="sm" /> : <Icon as={CopyIcon} />}
                <Text color="black">
                  {t('collaboration.copy_link', {
                    defaultValue: 'Copy link',
                    ns: 'client-portal',
                  })}
                </Text>
              </HStack>
            </MenuItem>
            <MenuItem onClick={onEmailUser}>
              <HStack>
                <Icon as={SendIcon} />
                <Text color="black">
                  {t('collaboration.send_link_by_email', {
                    defaultValue: 'Send link by email',
                    ns: 'client-portal',
                  })}
                </Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};
