import { DeepNonNullable } from 'ts-essentials';

import {
  RiskLevelEnum,
  WorkspaceSettingsReviewPeriodInMonthsModel,
} from '../../../shared/models';

export const DEFAULT_IS_COMMENT_REQUIRED_FOR_CASE_APPROVAL_REJECTION_PERIODIC_REVIEW =
  false;

export const DEFAULT_REVIEW_PERIOD_IN_MONTHS: DeepNonNullable<WorkspaceSettingsReviewPeriodInMonthsModel> =
  {
    [RiskLevelEnum.not_defined]: 0,
    [RiskLevelEnum.low]: 0,
    [RiskLevelEnum.medium]: 0,
    [RiskLevelEnum.high]: 0,
    [RiskLevelEnum.critical]: 0,
    [RiskLevelEnum.prohibited]: 0,
  };

export const MAX_REVIEW_PERIOD_IN_MONTHS = 120; // 10 years
