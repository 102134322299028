import { deepFreeze } from '@dotfile/shared/common';

import { ClientPortalCollaborationEnum } from '../../../shared/models';

export const clientPortalCollaborationDefinition = deepFreeze({
  [ClientPortalCollaborationEnum.none]: {
    label: 'Do not allow inviting others to collaborate',
  },
  [ClientPortalCollaborationEnum.existing_only]: {
    label: 'Allow inviting existing individual of the case to collaborate',
  },
  [ClientPortalCollaborationEnum.existing_and_add_new]: {
    label: 'Allow inviting existing or new individual to collaborate',
  },
} as const);
