import { useCallback } from 'react';

import { fileTargetDefinition, FileTargetEnum } from '@dotfile/shared/domain';

import { useUploadFileWithProgress } from './use-upload-file-with-progress';

/**
 * Upload a file to the backend API related to the app using the HttpClient.
 *
 * Url is `/files/upload/:storage?`
 *
 * @param target
 * @param storage Storage that will be append to the upload url, can
 *                be an empty string when the url doesn't expect a
 *                storage in the path
 * @returns
 */
export const useUploadFile = (target: FileTargetEnum) => {
  const { storage } = fileTargetDefinition[target];
  const upload = useUploadFileWithProgress(`/files/upload/${storage}`);

  const uploadFile = useCallback(
    async (file: File): Promise<string> => {
      const { uploadRef } = await upload.mutateAsync(file);

      return uploadRef;
    },
    [upload],
  );

  return {
    uploadFile,
    progress: upload.progress,
    fileName: upload.data?.fileName,
    reset: upload.reset,
  } as const;
};
