import { HStack } from '@dotfile/frontend/shared/design-system';
import { CaseRelationRoleEnum } from '@dotfile/shared/data-access-client-portal';

import {
  BeneficialOwnerPill,
  BusinessContactPill,
  LegalRepresentativePill,
  ShareholderPill,
} from '../../../shared';
import { FormDatastoreIndividual } from '../shared';

export const IndividualPills = ({
  individual,
}: {
  individual: FormDatastoreIndividual;
}) => {
  const isBeneficialOwner = individual.isBeneficialOwner;
  const isLegalRepresentative = individual.roles?.includes(
    CaseRelationRoleEnum.legal_representative,
  );
  const isBusinessContact = individual.isBusinessContact;
  const isShareholder = individual.roles?.includes(
    CaseRelationRoleEnum.shareholder,
  );

  // Signatory / Delegator is not displayed here because it is selected in a following step

  if (
    !isBeneficialOwner &&
    !isLegalRepresentative &&
    !isBusinessContact &&
    !isShareholder
  ) {
    return null;
  }
  return (
    <HStack>
      {isBeneficialOwner && <BeneficialOwnerPill />}
      {isLegalRepresentative && <LegalRepresentativePill />}
      {isBusinessContact && <BusinessContactPill />}
      {isShareholder && <ShareholderPill />}
    </HStack>
  );
};
