import { chakra, CloseButton, ToastProps } from '@chakra-ui/react';

import { Alert, AlertDescription, AlertIcon, AlertTitle } from '../alert/alert';

// based on https://github.com/chakra-ui/chakra-ui/blob/f14966a6de6d365daf54806a10776bd510168e9e/packages/toast/src/use-toast.tsx#L83-L119
// with our Alert/AlertIcon/AlertTitle/AlertDescription components
export const Toast: React.FC<ToastProps> = (props: ToastProps) => {
  const { status, variant, id, title, isClosable, onClose, description } =
    props;

  const alertTitleId =
    typeof id !== 'undefined' ? `toast-${id}-title` : undefined;

  return (
    <Alert
      status={status}
      variant={variant}
      id={typeof id === 'number' ? id.toString() : id}
      flex="1"
      alignItems="flex-start"
      borderRadius="md"
      boxShadow="lg"
      width="100%"
      paddingEnd={8}
      aria-labelledby={alertTitleId}
      // set max with here because it's not customizable in chakra
      // see https://github.com/chakra-ui/chakra-ui/blob/f14966a6de6d365daf54806a10776bd510168e9e/packages/toast/src/toast.tsx#L120-L123
      maxWidth="md"
    >
      <AlertIcon />
      <chakra.div flexGrow="2">
        {title && <AlertTitle id={alertTitleId}>{title}</AlertTitle>}
        {description && (
          <AlertDescription display="block">{description}</AlertDescription>
        )}
      </chakra.div>
      {isClosable && (
        <CloseButton
          size="sm"
          onClick={onClose}
          // remove the absolute positioning
          // position="absolute"
          // insetEnd={1}
          // top={1}
        />
      )}
    </Alert>
  );
};
