import { useMemo } from 'react';

import {
  useToast as useToastChakra,
  UseToastOptions as UseToastOptionsChakra,
} from '@chakra-ui/react';

import { Toast } from './toast';

export type UseToastOptions = UseToastOptionsChakra;

const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  isClosable: true,
  position: 'bottom-right',
  duration: 3000,
};

/**
 * @doc https://chakra-ui.com/docs/feedback/toast
 * @param defaultOptions
 * @returns
 */
export function useToast(
  defaultOptions?: UseToastOptions,
): ReturnType<typeof useToastChakra> {
  const toastChakra = useToastChakra();

  const customToast = useMemo(() => {
    const customToastFn = (options?: UseToastOptions) => {
      const opts = { ...DEFAULT_TOAST_OPTIONS, ...defaultOptions, ...options };
      const render = opts.render ?? ((props) => <Toast {...opts} {...props} />);
      return toastChakra({ ...opts, render });
    };

    // since we override the toast function, we must bind to the instance method like there
    // https://github.com/chakra-ui/chakra-ui/blob/f14966a6de6d365daf54806a10776bd510168e9e/packages/toast/src/use-toast.tsx#L168-L194
    const customToast: typeof toastChakra = customToastFn as typeof toastChakra;
    customToast.close = toastChakra.close;
    customToast.closeAll = toastChakra.closeAll;
    customToast.update = toastChakra.update;
    customToast.isActive = toastChakra.isActive;
    return customToast;
  }, [toastChakra, defaultOptions]);

  return customToast;
}
