import { CaseFlagEnum } from '../../../../shared/models';
import { CaseData, CaseTransitionFlag } from './type';

// Alias action to be less verbose
const contact_has_actions = 'contact_has_actions';
const reviewer_has_actions = 'reviewer_has_actions';
type Actions = typeof contact_has_actions | typeof reviewer_has_actions;

// Alias enum value to be less verbose
const for_review = CaseFlagEnum.for_review;
const for_first_collect = CaseFlagEnum.for_first_collect;
const for_recollection = CaseFlagEnum.for_recollection;
const first_collect_completed = CaseFlagEnum.first_collect_completed;
// all_check_approved is not used here

/**
 * Compute the flag next transition flags from the all current case flags and the next check flags
 *
 * @param currentCaseData Current actions and flags
 * @param nextCaseActions Output from computeFromChecksStatuses
 * @returns The transition flag that should be set on the case
 */
export const computeFlagsFromTransitions = (
  currentCaseData: CaseData,
  nextCaseActions: Pick<CaseData, 'contactHasActions' | 'reviewerHasActions'>,
): CaseTransitionFlag[] => {
  const { has, doesNotHave, willHave, willNotHave, action, set, get } =
    createHelpers(currentCaseData, nextCaseActions);

  // for_first_collect
  if (doesNotHave(first_collect_completed) && willHave(contact_has_actions)) {
    set(for_first_collect);
  }

  // first_collect_completed
  if (
    has(first_collect_completed) ||
    action(contact_has_actions).transitionTo(false)
  ) {
    set(first_collect_completed);
  }

  // for_recollection
  if (has(first_collect_completed) && willHave(contact_has_actions)) {
    // set flag
    if (willNotHave(reviewer_has_actions)) {
      set(for_recollection);
    }
    // keep flag
    else if (has(for_recollection)) {
      set(for_recollection);
    }
  }

  // for_review
  if (willHave(reviewer_has_actions)) {
    // set flag
    if (willNotHave(contact_has_actions)) {
      set(for_review);
    }
    // keep flag
    else if (has(for_review)) {
      set(for_review);
    }
  }

  // handle edge case
  if (
    willHave(contact_has_actions) &&
    willHave(reviewer_has_actions) &&
    willNotHave(for_recollection) &&
    willNotHave(for_review) &&
    willNotHave(for_first_collect)
  ) {
    if (willHave(first_collect_completed)) {
      set(for_recollection);
    }
    // for_first_collect should be already set
  }

  return get();
};

/**
 * This wrap the operations on flags array to expose more readable methods.
 *
 * It will build the new transition flags array from scratch.
 *
 * @param currentCaseFlags Current actions and flags
 * @param nextCaseActions Output from computeFromChecksStatuses
 * @returns
 */
function createHelpers(
  currentCaseFlags: CaseData,
  nextCaseActions: Pick<CaseData, 'contactHasActions' | 'reviewerHasActions'>,
) {
  const nextTransitionFlags: CaseTransitionFlag[] = [];
  const set = (flag: CaseTransitionFlag): void => {
    if (!nextTransitionFlags.includes(flag)) {
      nextTransitionFlags.push(flag);
    }
  };
  const get = () => nextTransitionFlags;

  const has = (actionOrFlag: CaseFlagEnum | Actions): boolean => {
    if (actionOrFlag === contact_has_actions) {
      return currentCaseFlags.contactHasActions;
    } else if (actionOrFlag === reviewer_has_actions) {
      return currentCaseFlags.reviewerHasActions;
    } else {
      return currentCaseFlags.flags.includes(actionOrFlag);
    }
  };

  const doesNotHave = (actionOrFlag: CaseFlagEnum | Actions): boolean => {
    return !has(actionOrFlag);
  };

  const willHave = (actionOrFlag: CaseTransitionFlag | Actions): boolean => {
    if (actionOrFlag === contact_has_actions) {
      return nextCaseActions.contactHasActions;
    } else if (actionOrFlag === reviewer_has_actions) {
      return nextCaseActions.reviewerHasActions;
    } else {
      return get().includes(actionOrFlag);
    }
  };

  const willNotHave = (actionOrFlag: CaseTransitionFlag | Actions): boolean => {
    return !willHave(actionOrFlag);
  };

  const action = (action: Actions) => {
    return {
      transitionTo: (transitionTo: boolean): boolean => {
        const _has = has(action);
        const _willHave = willHave(action);

        const result = transitionTo ? !_has && _willHave : _has && !_willHave;

        return result;
      },
    };
  };

  return { has, doesNotHave, willHave, willNotHave, action, set, get };
}
