import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody as ChakraAlertDialogBody,
  AlertDialogCloseButton as ChakraAlertDialogCloseButton,
  AlertDialogContent as ChakraAlertDialogContent,
  AlertDialogFooter as ChakraAlertDialogFooter,
  AlertDialogHeader as ChakraAlertDialogHeader,
  AlertDialogOverlay as ChakraAlertDialogOverlay,
  AlertDialogProps as ChakraAlertDialogProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/overlay/alert-dialog
export type AlertDialogProps = ChakraAlertDialogProps;
export const AlertDialog = ChakraAlertDialog;

export const AlertDialogHeader = ChakraAlertDialogHeader;
export const AlertDialogBody = ChakraAlertDialogBody;
export const AlertDialogFooter = ChakraAlertDialogFooter;
export const AlertDialogOverlay = ChakraAlertDialogOverlay;
export const AlertDialogContent = ChakraAlertDialogContent;
export const AlertDialogCloseButton = ChakraAlertDialogCloseButton;
