import { TFunction } from 'i18next';
import { Dictionary, groupBy } from 'lodash';

import { entityLegalFormFormat } from './entity-legal-form-format';
import { getAvailableEntityLegalForms } from './get-available-entity-legal-forms-for-country';
import { EntityLegalForm } from './types';

export const mapEntityLegalFormOptions =
  (t?: TFunction) =>
  (
    elf: EntityLegalForm,
  ): {
    value: string;
    label: string;
    active: boolean;
  } => {
    return {
      value: elf.code,
      label: entityLegalFormFormat(elf, { noCode: true, t }),
      active: elf.active,
    };
  };

export function compareEntityLegalFormOptions(
  a: ReturnType<ReturnType<typeof mapEntityLegalFormOptions>>,
  b: ReturnType<ReturnType<typeof mapEntityLegalFormOptions>>,
): number {
  // Set special code 8888 & 9999 at the end
  if (['8888', '9999'].includes(a.value)) {
    return 1;
  } else if (['8888', '9999'].includes(b.value)) {
    return -1;
  }

  // Set deprecated/inactive code at the end
  if (!a.active) {
    return 1;
  } else if (!b.active) {
    return -1;
  }
  // Some label start with a word between parentheses, this force them at the end
  else if (a.label.startsWith('(') === b.label.startsWith('(')) {
    return a.label.localeCompare(b.label);
  } else if (b.label.startsWith('(')) {
    return -1;
  } else {
    return 1;
  }
}

export function generateEntityLegalFormOptions(
  country?: string | null,
  subdivision?: string | null,
  { t }: { t?: TFunction } = {},
): {
  options:
    | { value: string; label: string }[]
    | { label: string; options: { value: string; label: string }[] }[];
  availableEntityLegalForms: EntityLegalForm[];
} {
  const availableEntityLegalForms = getAvailableEntityLegalForms(
    country ?? undefined,
    subdivision ?? undefined,
  );

  if (subdivision) {
    return {
      options: availableEntityLegalForms
        .map(mapEntityLegalFormOptions(t))
        .sort(compareEntityLegalFormOptions),
      availableEntityLegalForms,
    };
  }

  // Group by subdivision if country is specified else group by country
  let groupedBy: Dictionary<EntityLegalForm[]>;
  if (country) {
    groupedBy = groupBy(
      availableEntityLegalForms,
      (value) => value.subdivision ?? country,
    );
  } else {
    groupedBy = groupBy(availableEntityLegalForms, (value) => value.country);
  }

  const options = Object.keys(groupedBy).map((groupLabel) => {
    return {
      label: groupLabel,
      options: groupedBy[groupLabel]
        .map(mapEntityLegalFormOptions(t))
        .sort(compareEntityLegalFormOptions),
    };
  });

  return {
    options:
      options.length === 1 && options[0] && 'options' in options[0]
        ? options[0].options
        : // @NOTE: Sort by subdivision label, put no subdivision options at the end
          options.sort((a, b) =>
            (a.label > b.label || a.label === country) && b.label !== country
              ? 1
              : -1,
          ),
    availableEntityLegalForms,
  };
}
