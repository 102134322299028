import { capitalize, upperFirst } from 'lodash';

// @NOTE: Replaces underscores with spaces and capitalizes only the first character.
// Does not handle fully capitalized or complex names well.
export const humanCase = (str: string): string =>
  upperFirst(str).replace(/_/g, ' ');

/**
 * Converts a string to human case: replaces underscores with spaces,
 * splits on dashes/spaces, and capitalizes each word.
 * Returns null for empty input if nullable is true.
 *
 * @param {string} str - The string to convert.
 * @param {boolean} nullable - True by default, returns null for empty input.
 * @returns {string|null} The transformed string or null.
 */
export const humanCaseAdvanced = (
  str: string,
  nullable = true,
): string | null =>
  str
    ? str
        .replace(/_/g, ' ')
        .split('-')
        .map((segment) =>
          segment
            .split(' ')
            .map((word) => capitalize(word))
            .join(' '),
        )
        .join('-')
    : nullable
      ? null
      : '';
