const SECONDS_IN_HOUR = 60 * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;

export const COMMUNICATION_MIN_REMINDER_MAX_COUNT = 1;
export const COMMUNICATION_MAX_REMINDER_MAX_COUNT = 5;
export const COMMUNICATION_DEFAULT_REMINDER_MAX_COUNT = 5;
export const COMMUNICATION_EVENT_BATCH_WINDOW_IN_SECONDS = 1 * SECONDS_IN_HOUR; // 1h
export const COMMUNICATION_MIN_REMINDER_PERIOD_IN_SECONDS = SECONDS_IN_DAY; // 1 day
export const COMMUNICATION_MAX_REMINDER_PERIOD_IN_SECONDS = 15 * SECONDS_IN_DAY; // 15 days
export const COMMUNICATION_DEFAULT_REMINDER_PERIOD_IN_SECONDS =
  3 * SECONDS_IN_DAY; // 3 day
/**
 * Sender name is the xxxxx part of email header eg: "From: xxxxx <email>"
 * The max length is not bounded but most implementation will try to stay under 78 chars
 * @see https://www.rfc-editor.org/rfc/rfc5322#section-2.1.1
 */
export const COMMUNICATION_SENDER_NAME_MAX_LENGTH = 78;

export const COMMUNICATION_DEFAULT_SENDER_USER = 'communication-hub';
export const COMMUNICATION_DEFAULT_SENDER_DOMAIN = 'dotfile.com';
export const COMMUNICATION_DEFAULT_FROM = `${COMMUNICATION_DEFAULT_SENDER_USER}@${COMMUNICATION_DEFAULT_SENDER_DOMAIN}`;
