import {
  ClientPortalLogicTypeEnum,
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
  PropertyMappingTypeEnum,
} from '../../../../shared/models';
import { BaseClientPortalStepDefinition } from './base-client-portal-step-definition';
import { StepDefinitionAvailableBlocks } from './types';

export class CustomStepDefinition extends BaseClientPortalStepDefinition {
  // General
  // -------
  get type(): ClientPortalStepTypeEnum {
    return ClientPortalStepTypeEnum.custom;
  }
  get title(): string {
    // empty string because it should be set by user at creation
    return '';
  }
  get icon(): string {
    return 'blocks';
  }
  get isUnique(): boolean {
    return false;
  }
  public canHaveLogics(type?: ClientPortalLogicTypeEnum): boolean {
    if (!type) return true;
    return (
      [
        ClientPortalLogicTypeEnum.display,
        ClientPortalLogicTypeEnum.prevent_submit,
        // @TODO - E-2270 - Add @total-typescript/ts-reset
        // `as` should not be necessary anymore
      ] as ClientPortalLogicTypeEnum[]
    ).includes(type);
  }

  get position(): number {
    // Always latest
    return -1;
  }
  get clientPortalType(): ClientPortalTypeEnum[] {
    return [ClientPortalTypeEnum.KYC, ClientPortalTypeEnum.KYB];
  }

  // Actions
  // -------
  get canBeMoved(): boolean {
    return true;
  }
  get canBeRemoved(): boolean {
    return true;
  }

  // Visibility
  // -------
  get canBeHidden(): boolean {
    return true;
  }

  // Blocks
  // --------
  public override availableBlocks(
    clientPortalType: ClientPortalTypeEnum,
  ): StepDefinitionAvailableBlocks {
    return {
      field: {
        company:
          clientPortalType === ClientPortalTypeEnum.KYB
            ? [PropertyMappingTypeEnum.default, PropertyMappingTypeEnum.custom]
            : false,
        individual:
          clientPortalType === ClientPortalTypeEnum.KYC
            ? [PropertyMappingTypeEnum.default, PropertyMappingTypeEnum.custom]
            : false,
        case: [PropertyMappingTypeEnum.custom],
      },
      layout: true,
    };
  }
}

export const customStepDefinition = new CustomStepDefinition();
