import { COUNTRIES } from '../country';
import { CompanyClassificationTypeEnum } from '../models';

export type CompanyClassificationCoverage = Record<
  CompanyClassificationTypeEnum,
  // array of ISO country code
  string[]
>;

export const companyClassificationCoverage: CompanyClassificationCoverage = {
  // worldwide
  [CompanyClassificationTypeEnum.ISIC]: COUNTRIES.map(({ code }) => code),
  // https://connects.world/nace-codes/
  [CompanyClassificationTypeEnum.NACE]: [
    'AT',
    'IE',
    'BE',
    'IT',
    'BG',
    'LT',
    'CY',
    'LU',
    'CZ',
    'LV',
    'DE',
    'MT',
    'DK',
    'NL',
    'EE',
    'PL',
    'EL',
    'PT',
    'ES',
    'RO',
    'FI',
    'SE',
    'FR',
    'SI',
    'HR',
    'SK',
    'HU',
    'UK',
  ],
  // France only
  [CompanyClassificationTypeEnum.NAF]: ['FR'],
  // US / UK
  [CompanyClassificationTypeEnum.SIC]: ['US', 'GB'],
  // UK only
  [CompanyClassificationTypeEnum.SIC_UK]: ['GB'],
};

/**
 * Return sorted (more specific to less specific) available classifications for a country
 * @param country
 * @returns CompanyClassificationTypeEnum[]
 */
export function getAvailableClassificationTypeForCountry(
  // ISO 3166-1 alpha-2
  // see: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
  country: string,
): CompanyClassificationTypeEnum[] {
  return Object.entries(companyClassificationCoverage)
    .filter(([, countriesA]) => countriesA.includes(country))
    .sort(([, countriesA], [, countriesB]) => {
      if (countriesA.length > countriesB.length) return 1;
      return -1;
    })
    .map(([key]) => key) as CompanyClassificationTypeEnum[];
}
