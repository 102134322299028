import { useAuth } from '@dotfile/frontend/shared/common';
import { CaseRelationRoleEnum } from '@dotfile/shared/domain';

import { ContactAuthContext } from '../../../shared';
import { useFormDatastore } from '../shared';
import { FormValues } from './use-business-contact-step-form';

export const useBusinessContactStepFormInit = () => {
  const individuals = useFormDatastore((state) => state.data.individuals);

  const authenticatedContactId = useAuth<ContactAuthContext>().auth.contactId;
  const authenticatedContactIndex =
    individuals?.findIndex((i) => i.id === authenticatedContactId) ?? -1;

  const firstBusinessContactIndex =
    individuals?.findIndex((i) => i.isBusinessContact) ?? -1;

  const defaultBusinessContactIndex =
    authenticatedContactIndex >= 0 // Initialize business contact to authenticated when there is one
      ? authenticatedContactIndex
      : firstBusinessContactIndex;
  const defaultBusinessContact = individuals?.[defaultBusinessContactIndex];

  const defaultSignatoryIndex =
    individuals?.findIndex((i) => i.isSignatory) ?? -1;
  const defaultSignatory = individuals?.[defaultSignatoryIndex];

  const delegatorIndex = individuals?.findIndex((i) => i.isDelegator) ?? -1;

  const isBusinessContactLocked = !!defaultBusinessContact?.id;
  const isSignatoryLocked = !!defaultSignatory?.id;

  const defaultValues: FormValues = {
    businessContactIndex:
      defaultBusinessContactIndex >= 0
        ? defaultBusinessContactIndex.toString()
        : '',
    email: defaultBusinessContact?.email ?? '',

    isBusinessContactTheAuthorizedSignatory:
      defaultBusinessContactIndex >= 0 &&
      defaultBusinessContactIndex === defaultSignatoryIndex
        ? true // Auto select the value when default business contact is also default signatory
        : isSignatoryLocked
          ? false // Auto select the value when default business contact is not the signatory but signatory is locked
          : null, // Set initial value to null to display the alert only when false is selected manually
    signatoryIndex:
      defaultSignatoryIndex >= 0 ? defaultSignatoryIndex.toString() : '',
    isSignatoryLegalRepresentative:
      defaultSignatory?.roles?.includes(
        CaseRelationRoleEnum.legal_representative,
      ) ?? false,

    delegatorIndex: delegatorIndex >= 0 ? delegatorIndex.toString() : '',
  };

  return {
    defaultValues,
    isBusinessContactLocked,
    isSignatoryLocked,
  };
};
