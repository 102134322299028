import { base64Decode, base64Encode } from '@dotfile/shared/common';

/**
 * Related to `libs/backend/shared/storage/src/lib/upload/storage-uploaded-file.ts`
 */
export type UploadRefObject = {
  path: string;
  mimeType: string;
  originalName: string;
  expiredAt: Date;
};

const SEPARATOR = ',';

/**
 * Generate an `uploadRef` string from StorageUploadedFile/UploadRefObject
 *
 * @param file StorageUploadedFile/UploadRefObject
 * @returns string
 */
export const generateUploadRef = (file: UploadRefObject): string => {
  return base64Encode(
    [
      encodeURIComponent(file.path),
      encodeURIComponent(file.mimeType),
      encodeURIComponent(file.originalName),
      encodeURIComponent(file.expiredAt.toISOString()),
    ].join(SEPARATOR),
  );
};

type ParseUploadRefResult =
  | ({
      // status is there to have a discriminated union but not actually set success
      // https://www.typescriptlang.org/docs/handbook/typescript-in-5-minutes-func.html#discriminated-unions
      status?: 'success';
    } & UploadRefObject)
  | {
      status: 'error';
      message: string;
    };

/**
 * Parse minimal StorageUploadedFile/UploadRefObject infos from `uploadRef`
 *
 * In backend code, prefer using FileService.parseUploadRef
 *
 * @param ref string
 * @returns
 */
export const parseUploadRef = (ref: string): ParseUploadRefResult => {
  try {
    const data = base64Decode(ref).split(SEPARATOR);

    if (data.length < 3) {
      return { status: 'error', message: 'Invalid uploadRef' };
    }

    // Check expiration date
    const expiredAt = new Date(decodeURIComponent(data[3]));
    if (isNaN(expiredAt.getTime())) {
      // getTime is NaN for invalid dates
      return { status: 'error', message: 'Invalid uploadRef' };
    }
    if (expiredAt < new Date()) {
      return { status: 'error', message: 'Expired uploadRef' };
    }

    return {
      path: decodeURIComponent(data[0]),
      mimeType: decodeURIComponent(data[1]),
      originalName: decodeURIComponent(data[2]),
      expiredAt,
    };
  } catch (err) {
    const message = err instanceof Error ? err.message : err;
    return {
      status: 'error',
      message: `Unable to parse uploadRef: ${message}`,
    };
  }
};
