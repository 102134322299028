import { LucideIcon } from 'lucide-react';

import {
  Badge,
  HStack,
  Icon,
  Tab,
  Text,
  useIsMobile,
} from '@dotfile/frontend/shared/design-system';

export type TabItemProps = {
  icon: LucideIcon;
  label: string;
  pending?: number;
};
export const ChecksTabItem = ({ icon, label, pending = 0 }: TabItemProps) => {
  const isMobile = useIsMobile();

  return (
    <Tab
      _selected={{
        color: 'black',
        borderBottom: '2px solid',
        borderColor: 'custom.500',
      }}
      px={{ base: 2, md: 4 }}
      mr={{ base: 4, md: 10 }}
      py="0"
    >
      <HStack marginBottom={{ base: 4, md: 0 }} spacing={{ base: 1, md: 2 }}>
        <Icon color="inherit" as={icon} />
        <Text
          color="inherit"
          noOfLines={1}
          _selected={{ color: 'black' }}
          width={isMobile ? 'max-content' : 'full'}
        >
          {label}
        </Text>
        {!isMobile && pending !== 0 && (
          <Badge colorScheme="orange">{pending}</Badge>
        )}
      </HStack>
    </Tab>
  );
};
