import {
  ArrowDownToLine,
  ArrowUpDown,
  Eye,
  ListChecks,
  LucideIcon,
} from 'lucide-react';

import { CaseFlagEnum } from '../../../../shared/models';

export type ProgressDefinition = {
  label: string;
  progress: CaseFlagEnum;
  icon: LucideIcon;
};

export const progressDefinitions: ProgressDefinition[] = [
  {
    label: 'Collecting',
    progress: CaseFlagEnum.for_first_collect,
    icon: ArrowDownToLine,
  },
  { label: 'Reviewing', progress: CaseFlagEnum.for_review, icon: Eye },
  {
    label: 'Recollecting',
    progress: CaseFlagEnum.for_recollection,
    icon: ArrowUpDown,
  },
  {
    label: 'All approved',
    progress: CaseFlagEnum.all_checks_approved,
    icon: ListChecks,
  },
];

export const getProgressDefinition = (
  progress: CaseFlagEnum | null,
): ProgressDefinition | null => {
  const definition =
    progressDefinitions.find(
      (definition) => definition.progress === progress,
    ) ?? null;

  return definition;
};

export const getProgressDefinitionFromFlags = (
  flags: CaseFlagEnum[] | null,
): ProgressDefinition | null => {
  const definition =
    progressDefinitions.find(
      (definition) => flags?.includes(definition.progress),
    ) ?? null;

  return definition;
};
