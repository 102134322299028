import { useEffect } from 'react';

import { ClientPortal_SettingStyling } from '@dotfile/shared/data-access-client-portal';

import { useTheme } from './theme.context';

export const useInitTheme = (
  styling: ClientPortal_SettingStyling | undefined,
) => {
  const { setTheme } = useTheme();

  useEffect(() => {
    if (!styling) {
      return;
    }

    setTheme({
      colors: {
        custom: styling.colorsMain,
      },
    });
  }, [styling, setTheme]);
};
