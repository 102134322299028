import { useMemo } from 'react';

import {
  Box,
  createStylesContext,
  forwardRef,
  HStack,
  RadioProps,
  StackProps,
  useMultiStyleConfig,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
} from '@chakra-ui/react';

import { Text } from '../../typography/text';

const [StylesProvider, useStyles] = createStylesContext('RadioButton');

interface ChildrenProps {
  children: string;
}
export type RadioOptionProps = ChildrenProps & RadioProps;

/**
 * @doc https://chakra-ui.com/docs/form/radio#custom-radio-buttons
 * It seems to be a bug with emotion and storybook: Type 'InterpolationWithTheme<any>' is not assignable to type 'Interpolation<Theme>'
 * https://bleepcoder.com/fr/emotion/846791172/types-of-property-css-are-incompatible-type
 */

// 1. Create a component that consumes the `useRadio` hook
export const RadioOption = forwardRef(
  (props: RadioOptionProps, ref): JSX.Element => {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps({ name: props.name });
    const radio = getRadioProps();

    const styles = useStyles();

    return (
      <Box as="label">
        {/* @ts-ignore Types of property 'css' are incompatible */}
        <input {...input} ref={ref} />
        {/* @ts-ignore Types of property 'css' are incompatible */}
        <Box
          {...radio}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          borderColor="gray.100"
          height="10"
          minWidth="60px"
          textAlign="center"
          __css={styles['option']}
          px={4}
          py={2}
        >
          <Text color="inherit">{props.children}</Text>
        </Box>
      </Box>
    );
  },
);

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export type RadioButtonProps = {
  options:
    | {
        value: UseRadioGroupProps['defaultValue'];
        label: string;
      }[]
    | UseRadioGroupProps['defaultValue'][];
  defaultValue?: UseRadioGroupProps['defaultValue'];
  onChange?: UseRadioGroupProps['onChange'];
  isReadOnly?: boolean;
  name?: string;
} & Omit<StackProps, 'onChange'>;

export const RadioButton = forwardRef<RadioButtonProps, typeof HStack>(
  ({ options, defaultValue, onChange, isReadOnly = false, ...props }, ref) => {
    const styles = useMultiStyleConfig('RadioButton', props);

    // We need to format option because we can pass an string[] only
    const formattedOptions = useMemo(() => {
      return options.map((item) =>
        item !== undefined && typeof item === 'object' && 'label' in item
          ? item
          : { value: item, label: item },
      );
    }, [options]);

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: props.name ?? formattedOptions.toString(),
      defaultValue: defaultValue,
      onChange: onChange,
    });

    const group = getRootProps();

    return (
      <StylesProvider value={styles}>
        <HStack {...group} __css={styles['wrapper']} {...props}>
          {formattedOptions.map(({ value, label }, index) => {
            const radio = getRadioProps(
              { value },
              // Ref forwarded only on the first element to focus on this one
              index === 0 ? ref : undefined,
            );

            return (
              <RadioOption
                key={value}
                value={value}
                {...radio}
                isReadOnly={isReadOnly}
              >
                {`${label}`}
              </RadioOption>
            );
          })}
        </HStack>
      </StylesProvider>
    );
  },
);
