import { useMemo } from 'react';

type UsePaginationNavigationOptions = {
  pageIndex: number;
  pageLength: number;
};
type UsePaginationNavigationResult = {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  truncatePreviousMiddle: boolean;
  truncateMiddleNext: boolean;
  pages: number[];
  previousPages: number[];
  middlePages: number[];
  nextPages: number[];
};

/**
 * Always show this number of pageIndex when there is no
 * truncation between the current pageIndex and first/last
 */
export const EDGE_PAGE_COUNT = 5;

/**
 * Always show this number of pageIndex before/after the current pageIndex
 */
const SIBLING_PAGE_COUNT = 2;

const computeMiddlePages = (
  truncatePreviousMiddle: boolean,
  truncateMiddleNext: boolean,
  pageIndex: number,
  pages: number[],
) => {
  if (!truncatePreviousMiddle && !truncateMiddleNext) {
    // When nothings is truncated, returns all the pageIndex in the middle
    return pages;
  }

  if (!truncatePreviousMiddle) {
    return pages.slice(
      0,
      Math.max(EDGE_PAGE_COUNT, pageIndex + 1 + SIBLING_PAGE_COUNT),
    );
  }

  if (!truncateMiddleNext) {
    return pages.slice(
      pages.length -
        Math.max(
          EDGE_PAGE_COUNT,
          pages.length - pageIndex + SIBLING_PAGE_COUNT,
        ),
    );
  }

  return pages.slice(
    pageIndex - SIBLING_PAGE_COUNT,
    pageIndex + SIBLING_PAGE_COUNT + 1,
  );
};

export const usePaginationNavigation = ({
  pageIndex,
  pageLength,
}: UsePaginationNavigationOptions): UsePaginationNavigationResult => {
  const pages = useMemo(
    () =>
      pageLength > 0
        ? Array(pageLength)
            .fill(0)
            .map((_, i) => i)
        : [],
    [pageLength],
  );

  return useMemo(() => {
    const hasPreviousPage = pageIndex > 0;
    const hasNextPage = pageIndex < pageLength - 1;

    const truncatePreviousMiddle =
      pageLength > EDGE_PAGE_COUNT + 1 && pageIndex - EDGE_PAGE_COUNT + 1 > 0;

    const truncateMiddleNext =
      pageLength > EDGE_PAGE_COUNT + 1 &&
      pageIndex + EDGE_PAGE_COUNT - 1 < pageLength - 1;

    const middlePages = computeMiddlePages(
      truncatePreviousMiddle,
      truncateMiddleNext,
      pageIndex,
      pages,
    );

    // When not truncated, the pageIndex are in `middlePages` and when truncated, only show the first/last pageIndex
    const previousPages = !truncatePreviousMiddle ? [] : pages.slice(0, 1);
    const nextPages = !truncateMiddleNext ? [] : pages.slice(pageLength - 1);

    return {
      hasPreviousPage,
      hasNextPage,
      pages,
      truncatePreviousMiddle,
      truncateMiddleNext,
      previousPages,
      middlePages,
      nextPages,
    };
  }, [pageIndex, pageLength, pages]);
};
