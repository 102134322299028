export const textStyles = {
  h1: {
    fontSize: '64px',
    fontWeight: '700',
    lineHeight: '140%',
  },
  h2: {
    fontSize: '48px',
    fontWeight: '500',
    lineHeight: '150%',
  },
  h3: {
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '150%',
  },
  h4: {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '150%',
  },
  h5: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '170%',
  },
  h6: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '150%',
  },
  main: {
    fontSize: '14px',
    lineHeight: '150%',
    color: 'text',
    fontWeight: 400,
  },
  inlineBold: {
    fontSize: '14px',
    lineHeight: '150%',
    color: 'black',
    fontWeight: 'bold',
  },
  inlineMedium: {
    fontSize: '14px',
    lineHeight: '150%',
    color: 'black',
    fontWeight: '500',
  },
  sub: {
    fontSize: '12px',
    lineHeight: '140%',
    color: 'text',
  },
  tiny: {
    fontSize: '10px',
    lineHeight: '130%',
    color: 'text',
  },
  uppercase: {
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '120%',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    color: 'text',
  },
};
