import { Onfido } from 'onfido-sdk-ui';
import { createRef, memo, useEffect } from 'react';

import { useHandleError } from '@dotfile/frontend/shared/components';
import { Box } from '@dotfile/frontend/shared/design-system';

import { VendorIframeProps } from '../types';

type OnfidoIframeProps = Omit<VendorIframeProps, 'onExpired'>;

// Pure component to not rerender if url doesn't change
export const OnfidoIframe = memo(function OnfidoIframe({
  onComplete,
  onAbort,
  extra,
}: OnfidoIframeProps) {
  const ref = createRef<HTMLDivElement>();
  const handleError = useHandleError();

  useEffect(() => {
    if (!extra?.sdkToken || !extra?.verificationRef) {
      onAbort();
      // Not translated since it is internal error and should never happen
      handleError({
        title: 'Unable to start identity verification',
        error: new Error(
          `Missing required Onfido configuration: ${
            !extra?.sdkToken ? 'sdkToken' : 'verificationRef'
          }`,
        ),
      });
      return;
    }

    const onfidoInstance = Onfido.init({
      token: extra.sdkToken,
      workflowRunId: extra.verificationRef,
      containerId: 'onfido-mount',
      onComplete: (data) => {
        onComplete();
      },
      onError: (error) => {
        onAbort();
        handleError({
          title: 'Unable to start identity verification',
          error,
        });
      },
      theme: {
        name: 'light', // or "dark",
        config: {
          borderStyleSurfaceModal: '0px',
        },
      },
    });

    return () => {
      onfidoInstance.tearDown();
    };
  }, [extra, onComplete, ref, onAbort, handleError]);

  return (
    <Box
      position="relative"
      width="100%"
      id="onfido-mount" // id used by Onfido to mount the UI
      ref={ref}
      className="iframe-ref"
      sx={{
        // Fixes for inner size of the Onfido SDK
        '#onfido-sdk': {
          minHeight: '80vh !important',
          minWidth: '100% !important',
        },
      }}
    />
  );
});
