import { PropertyTypeEnum } from '@dotfile/shared/domain';

import { CountrySelect } from '../country-select/country-select';
import { CustomValueEditorProps } from './type';
import { selectorInsidePopoverStylesWorkaround } from './value-selector-style-workaround';

type ValueEditorCountryProps = CustomValueEditorProps<
  typeof PropertyTypeEnum.countries
> & { multiple?: boolean };

export const ValueEditorCountry = ({
  value,
  handleOnChange,
  multiple,
}: ValueEditorCountryProps): JSX.Element => {
  const parsedValue =
    multiple && typeof value === 'string' ? value.split(',') : value;
  const defaultInitialValue = multiple ? [] : null;

  return (
    <CountrySelect
      isMulti={multiple}
      value={parsedValue ?? defaultInitialValue}
      onChange={(newValue) => {
        if (!newValue) {
          handleOnChange(null);
        } else if (multiple) {
          const newMultiValues = Array.isArray(newValue)
            ? newValue
            : [newValue];
          handleOnChange(newMultiValues.map((v) => v.value));
        } else {
          const newSingleValue = Array.isArray(newValue)
            ? newValue[0]
            : newValue;
          handleOnChange(newSingleValue.value);
        }
      }}
      styles={selectorInsidePopoverStylesWorkaround}
    />
  );
};
