// Use yup-extended to have countryCode2
import {
  validateBIC,
  validateIBAN,
  ValidationErrorsBIC,
  ValidationErrorsIBAN,
} from 'ibantools';

import { normalize } from '@dotfile/shared/common';

import { BankingInformationModel } from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';

const ibanErrorsDefinition: Record<ValidationErrorsIBAN, string> = {
  [ValidationErrorsIBAN.NoIBANProvided]: 'no iban', // should not happen since there is already a min length validation
  [ValidationErrorsIBAN.NoIBANCountry]: 'no country',
  [ValidationErrorsIBAN.WrongBBANLength]: 'invalid length',
  [ValidationErrorsIBAN.WrongBBANFormat]: 'invalid format',
  [ValidationErrorsIBAN.ChecksumNotNumber]: 'invalid checksum',
  [ValidationErrorsIBAN.WrongIBANChecksum]: 'invalid checksum',
  [ValidationErrorsIBAN.WrongAccountBankBranchChecksum]: 'invalid checksum',
  [ValidationErrorsIBAN.QRIBANNotAllowed]: 'QR IBAN not allowed',
};

const bicErrorsDefinition: Record<ValidationErrorsBIC, string> = {
  [ValidationErrorsBIC.NoBICProvided]: 'bic not provided', // should not happen since there is already a min length validation
  [ValidationErrorsBIC.NoBICCountry]: 'no country',
  [ValidationErrorsBIC.WrongBICFormat]: 'invalid format',
};

export const bankingInformationSchema =
  (): yup.SchemaOf<BankingInformationModel> => {
    return yup.object({
      // https://en.wikipedia.org/wiki/ISO_9362
      bic: yup
        .string()
        .transform(
          (value) =>
            value && normalize(value, { removeWhiteSpace: true }).toUpperCase(),
        )
        .optionalString()
        .min(8)
        .max(12)
        .test('bic', function (value) {
          const { createError, path } = this;
          const { valid, errorCodes } = validateBIC(value ?? '');

          return (
            !value ||
            valid ||
            createError({
              path,
              message: `${path} is invalid: ${errorCodes
                .map((errorCode) => bicErrorsDefinition[errorCode])
                .join(', ')}`,
            })
          );
        }),

      // https://en.wikipedia.org/wiki/International_Bank_Account_Number
      iban: yup
        .string()
        .transform(
          (value) =>
            value && normalize(value, { removeWhiteSpace: true }).toUpperCase(),
        )
        .optionalString()
        .min(15)
        .max(34)
        .test('iban', function (value) {
          const { createError, path } = this;
          const { valid, errorCodes } = validateIBAN(value ?? '');

          return (
            !value ||
            valid ||
            createError({
              path,
              message: `${path} is invalid: ${[
                ...new Set(
                  errorCodes.map(
                    (errorCode) => ibanErrorsDefinition[errorCode],
                  ),
                ),
              ].join(', ')}`,
            })
          );
        }),
    });
  };
