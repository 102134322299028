import {
  ClientPortalBlockFieldModel,
  ClientPortalBlockLayoutModel,
  ClientPortalBlockLayoutWidgetEnum,
  ClientPortalBlockTypeEnum,
} from '../../../../shared/models';
import { CLIENT_PORTAL_WIDGET_DYNAMIC_FIELD_REQUIREMENT_MAPPING } from './block-layout-field';

export const isBlockField = <
  T extends {
    type?: ClientPortalBlockTypeEnum;
  },
>(
  maybeField: T,
): maybeField is T & ClientPortalBlockFieldModel =>
  maybeField.type === ClientPortalBlockTypeEnum.field ||
  ('mapping' in maybeField && !!maybeField.mapping);

export const isBlockLayout = (maybeLayout: {
  type?: ClientPortalBlockTypeEnum;
}): maybeLayout is ClientPortalBlockLayoutModel =>
  maybeLayout.type === ClientPortalBlockTypeEnum.layout ||
  ('widget' in maybeLayout && !!maybeLayout.widget);

export const isBlockLayoutPdf = (maybeLayout: {
  type?: ClientPortalBlockTypeEnum;
}): boolean =>
  isBlockLayout(maybeLayout) &&
  maybeLayout.widget === ClientPortalBlockLayoutWidgetEnum.pdf;

export const isBlockLayoutImage = (maybeLayout: {
  type?: ClientPortalBlockTypeEnum;
}): boolean =>
  isBlockLayout(maybeLayout) &&
  maybeLayout.widget === ClientPortalBlockLayoutWidgetEnum.image;

export const isBlockLayoutWidgetRequiringFile = (
  widget: ClientPortalBlockLayoutWidgetEnum,
): boolean => {
  const fileIdRequirementDefinitions =
    CLIENT_PORTAL_WIDGET_DYNAMIC_FIELD_REQUIREMENT_MAPPING.fileId;

  return fileIdRequirementDefinitions.required.includes(widget);
};

export const isBlockLayoutWidgetSupportingLabel = (
  widget: ClientPortalBlockLayoutWidgetEnum,
): boolean => {
  const labelRequirementDefinitions =
    CLIENT_PORTAL_WIDGET_DYNAMIC_FIELD_REQUIREMENT_MAPPING.label;

  return (
    labelRequirementDefinitions.required.includes(widget) ||
    labelRequirementDefinitions.optional.includes(widget)
  );
};

export const isBlockLayoutWidgetRequiringLabel = (
  widget: ClientPortalBlockLayoutWidgetEnum,
): boolean => {
  const labelRequirementDefinitions =
    CLIENT_PORTAL_WIDGET_DYNAMIC_FIELD_REQUIREMENT_MAPPING.label;

  return labelRequirementDefinitions.required.includes(widget);
};
