import { GroupBase, SelectInstance } from 'chakra-react-select';
import { has } from 'lodash';
import { Ref, useMemo } from 'react';

import { Text } from '@chakra-ui/react';

import {
  companyClassifications,
  CompanyClassificationTypeEnum,
  companyClassificationTypeLabels,
} from '@dotfile/shared/domain';

import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { GenericSelect, Option, SelectProps } from '../select';

export type CompanyClassificationSelectProps<IsMulti extends boolean = false> =
  Omit<SelectProps<IsMulti>, 'defaultValue' | 'value'> & {
    defaultValue?: string | string[];
    value?: string | string[];
  };

const defaultOptions: GroupBase<Option>[] = Object.entries(
  companyClassifications,
).map(([type, classifications]) => ({
  label: companyClassificationTypeLabels[type as CompanyClassificationTypeEnum],
  options: classifications.map(({ code, description }) => ({
    label: `${code} - ${description}`,
    value: `${type}__${code}`,
  })),
}));

const MIN_CHAR_FOR_SEARCH = 2;

export const CompanyClassificationSelect = forwardRefWithGeneric(
  function CompanyClassificationSelectWithRef<IsMulti extends boolean = false>(
    { ...props }: CompanyClassificationSelectProps<IsMulti>,
    ref: Ref<SelectInstance<Option, IsMulti>>,
  ): JSX.Element {
    const { placeholder, options, defaultValue, value, ...rest } = props;

    const resolvedOptions =
      options && options.length ? options : defaultOptions;

    const resolvedDefaultValue = useMemo(() => {
      if (typeof defaultValue === 'string') {
        return resolvedOptions.find(
          (o): o is Option => 'value' in o && o.value === defaultValue,
        );
      } else if (Array.isArray(defaultValue)) {
        return resolvedOptions.filter(
          (o): o is Option => 'value' in o && defaultValue.includes(o.value),
        );
      } else {
        return defaultValue;
      }
    }, [defaultValue, resolvedOptions]);

    const resolvedValue = useMemo(() => {
      if (!value) {
        return;
      }

      const hasGroup =
        resolvedOptions &&
        resolvedOptions[0] &&
        has(resolvedOptions[0], 'options');

      const flattenedOptions = hasGroup
        ? (
            resolvedOptions as unknown as {
              label: string;
              options: { label: string; value: string };
            }[]
          ).flatMap((o) => o.options)
        : resolvedOptions;

      if (typeof value === 'string') {
        return flattenedOptions.find(
          (option): option is Option =>
            'value' in option && option.value === value,
        );
      }

      return flattenedOptions.filter(
        (o): o is Option => 'value' in o && value.includes(o.value),
      );
    }, [value, resolvedOptions]);

    return (
      <GenericSelect<Option, IsMulti, GroupBase<Option>>
        ref={ref}
        placeholder={placeholder ?? 'Select company classification'}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length > MIN_CHAR_FOR_SEARCH
            ? `No options for "${inputValue}"`
            : `Type at least ${MIN_CHAR_FOR_SEARCH} characters to search`
        }
        isClearable
        isSearchable
        options={resolvedOptions}
        filterOption={(option, inputValue) => {
          if (!inputValue) return false;
          if (inputValue.length < MIN_CHAR_FOR_SEARCH) return false;
          return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }}
        defaultValue={resolvedDefaultValue}
        value={resolvedValue}
        formatGroupLabel={({ label }) => {
          return (
            <Text color="black" fontWeight="bold">
              {label}
            </Text>
          );
        }}
        {...rest}
      />
    );
  },
);
