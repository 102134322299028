import { Hash, LucideIcon } from 'lucide-react';
import { NumberSchema, SchemaOf } from 'yup';

import { SORTED_CURRENCY_CODES } from '../../../../shared/currency';
import {
  CustomPropertyNumericFormatEnum,
  CustomPropertyNumericSettings,
  CustomPropertyTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import {
  CustomPropertyFormatValueOptions,
  CustomPropertyFormatValueParam,
  CustomPropertyValue,
  CustomPropertyValueSchemaParam,
} from './type';

export class NumericCustomPropertyDefinition extends BaseCustomPropertyDefinition<CustomPropertyNumericSettings> {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.numeric;
  }

  get label(): string {
    return 'Numeric';
  }

  get icon(): LucideIcon {
    return Hash;
  }

  // Settings
  // --------

  /**
   * Maximum value for the `max` settings.
   *
   * Also the maximum positive value for a 32-bit signed binary integer.
   */
  get maxMax(): number {
    return 2_147_483_647;
  }

  /**
   * Minimum value for the `min` settings.
   *
   * Also the minimum positive value for a 32-bit signed binary integer.
   */
  get minMin(): number {
    return -2_147_483_647;
  }

  override get defaultSettings(): CustomPropertyNumericSettings {
    return {
      format: CustomPropertyNumericFormatEnum.number,
      min: null,
      max: null,
      currencyCode: null,
    };
  }

  public override settingsSchema(): SchemaOf<CustomPropertyNumericSettings> {
    const schema = yup
      .object()
      .shape({
        format: yup
          .mixed()
          .oneOf(Object.values(CustomPropertyNumericFormatEnum))
          .required(),
        min: yup
          .number()
          .typeError('${path} must be a number')
          .min(this.minMin)
          .max(this.maxMax)
          .nullable()
          .optional(),
        max: yup
          .number()
          .typeError('${path} must be a number')
          .min(this.minMin)
          .max(this.maxMax)
          .nullable()
          .optional(),
        currencyCode: yup.string().when('format', {
          is: CustomPropertyNumericFormatEnum.currency,
          then: (s) => s.oneOf(SORTED_CURRENCY_CODES).required(),
          otherwise: (s) =>
            s.nullable().equals([null], '${path} must not be set').optional(),
        }),
      })
      .test('min-lower-than-max', (value, { createError, path }) => {
        const isValid =
          typeof value?.min === 'undefined' ||
          typeof value?.max === 'undefined' ||
          value.min === null ||
          value.max === null ||
          value.min < value.max;

        if (isValid) {
          return true;
        } else {
          return createError({
            path: `${path}.max`, // set the error to the min field for the UI
            message: `max must be greater than min`,
          });
        }
      })
      .required();

    return schema;
  }

  // Value
  // -----

  override get defaultValue(): CustomPropertyValue | null {
    return null;
  }

  public valueSchema(
    customProperty: CustomPropertyValueSchemaParam,
  ): NumberSchema<number | undefined | null> {
    const { min, max } = this.settingsSchema().validateSync(
      customProperty.settings,
    );

    const resolvedMin = min ?? this.minMin;
    const resolvedMax = max ?? this.maxMax;

    const schema = yup
      .number()
      .typeError(
        `\${path} must be a number between ${resolvedMin} and ${resolvedMax}`,
      )
      .min(resolvedMin)
      .max(resolvedMax)
      .nullable()
      .strict();

    return schema;
  }

  public override formatValue(
    { value, customProperty }: CustomPropertyFormatValueParam,
    options: CustomPropertyFormatValueOptions,
  ): string {
    try {
      void options; // unused currently but could eventually accept a `locale`

      const { format, currencyCode } = this.settingsSchema().validateSync(
        customProperty.settings,
      );

      const nbrValue =
        format == CustomPropertyNumericFormatEnum.percent
          ? parseFloat(value) / 100 // for percentage, Intl.NumberFormat assume that it is a fraction of 100
          : parseFloat(value);
      const formatted = new Intl.NumberFormat('en', {
        style:
          format === CustomPropertyNumericFormatEnum.number
            ? undefined
            : format,
        currency: currencyCode ?? undefined,
      }).format(nbrValue);

      return formatted;
    } catch {
      return value;
    }
  }

  public override parseValue(rawValue: string): CustomPropertyValue {
    return parseFloat(rawValue);
  }
}

export const numericCustomPropertyDefinition =
  new NumericCustomPropertyDefinition();
