import { humanCase } from '@dotfile/shared/common';

import {
  CaseRelationRoleEnum,
  IndividualModel,
  PropertyMappingEntityEnum,
} from '../../shared/models';
import {
  AddressPropertyKey,
  getAddressDefaultPropertiesDefinition,
} from './address-properties.definition';
import {
  DefaultPropertiesBuilder,
  NestedPropertyPrefix,
} from './default-properties-builder';
import { Property, PropertyNumericFormatEnum, PropertyTypeEnum } from './types';

export const individualDefaultPropertiesDefinition: Record<
  | keyof Omit<
      // @NOTE Also update the test snapshot when modifying the properties
      IndividualModel,
      | 'case'
      | 'caseId'
      | 'checks'
      | 'customPropertyValues'
      | 'relations'
      | 'workspace'
      | 'workspaceId'
    >
  | NestedPropertyPrefix<'address', AddressPropertyKey>,
  Property<PropertyTypeEnum>
> = new DefaultPropertiesBuilder<IndividualModel>(
  PropertyMappingEntityEnum.individual,
)
  .addProperty('id', PropertyTypeEnum.text)
  .addProperty('lastActivityAt', PropertyTypeEnum.date, {
    label: 'Last activity',
  })
  .addProperty('isSignatory', PropertyTypeEnum.boolean)
  .addProperty('isBeneficialOwner', PropertyTypeEnum.boolean)
  .addProperty('isBusinessContact', PropertyTypeEnum.boolean)
  .addProperty('isDelegator', PropertyTypeEnum.boolean)
  .addProperty('isRelevant', PropertyTypeEnum.boolean)
  .addProperty('roles', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: true,
      options: Object.values(CaseRelationRoleEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })

  .addProperty('firstName', PropertyTypeEnum.text, {
    alwaysRequired: true,
  })
  .addProperty('middleName', PropertyTypeEnum.text)
  .addProperty('lastName', PropertyTypeEnum.text, {
    alwaysRequired: true,
  })
  .addProperty('maidenName', PropertyTypeEnum.text)

  .addProperty('email', PropertyTypeEnum.email)

  .addProperty('birthDate', PropertyTypeEnum.date)
  .addProperty('birthPlace', PropertyTypeEnum.text)
  .addProperty('birthCountry', PropertyTypeEnum.countries, {
    settings: {
      allowMultiple: false,
    },
  })

  .addProperty('bankingInformation', PropertyTypeEnum.banking_information)
  .addProperty('address', PropertyTypeEnum.address)
  .addNestedProperties('address', getAddressDefaultPropertiesDefinition)
  .addProperty('taxIdentificationNumber', PropertyTypeEnum.text)
  .addProperty('socialSecurityNumber', PropertyTypeEnum.text)
  .addProperty('phoneNumber', PropertyTypeEnum.phone_number)

  .addProperty('position', PropertyTypeEnum.text)
  .addProperty('ownershipPercentage', PropertyTypeEnum.numeric, {
    settings: {
      min: 0,
      max: 100,
      format: PropertyNumericFormatEnum.percent,
    },
  })
  .addProperty('votingRightsPercentage', PropertyTypeEnum.numeric, {
    settings: {
      min: 0,
      max: 100,
      format: PropertyNumericFormatEnum.percent,
    },
  })
  .addProperty('createdAt', PropertyTypeEnum.date)
  .addProperty('updatedAt', PropertyTypeEnum.date)
  .addProperty('deletedAt', PropertyTypeEnum.date)

  .getDefinition();
