import { useTranslation } from 'react-i18next';

import {
  HStack,
  Text,
  useIsMobile,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { DATE_FORMATS, formatDateTime } from '@dotfile/shared/common';
import { ClientPortalChecks_CheckInterface } from '@dotfile/shared/data-access-client-portal';
import { CheckStatusEnum } from '@dotfile/shared/domain';

type CheckReviewProps = {
  check: ClientPortalChecks_CheckInterface;
};

export const CheckReview = ({ check }: CheckReviewProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const { comment, reviewedAt } = check.data.review;

  if (!comment || check.status === CheckStatusEnum.approved) {
    return null;
  }

  return (
    <VStack
      p="4"
      width="full"
      align="stretch"
      borderRadius="lg"
      backgroundColor="gray.50"
    >
      <HStack justifyContent="space-between">
        <Text noOfLines={1} wordBreak="break-all">
          {t('checks.review_comment', {
            ns: 'client-portal',
            defaultValue: 'Review comment',
          })}
        </Text>

        {reviewedAt && (
          <Text noOfLines={1}>
            {formatDateTime(
              reviewedAt,
              isMobile ? DATE_FORMATS.date_time_short : DATE_FORMATS.date_time,
            )}
          </Text>
        )}
      </HStack>
      <Text color="black">{comment}</Text>
    </VStack>
  );
};
