import {
  Tab as ChakraTab,
  TabList as ChakraTabList,
  TabListProps as ChakraTabListProps,
  TabPanel as ChakraTabPanel,
  TabPanelProps as ChakraTabPanelProps,
  TabPanels as ChakraTabPanels,
  TabPanelsProps as ChakraTabPanelsProps,
  TabProps as ChakraTabProps,
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
} from '@chakra-ui/react';

export type TabListProps = ChakraTabListProps;
export const TabList = ChakraTabList;

export type TabProps = ChakraTabProps;
export const Tab = ChakraTab;

export type TabsProps = ChakraTabsProps;
export const Tabs = ChakraTabs;

export type TabPanelsProps = ChakraTabPanelsProps;
export const TabPanels = ChakraTabPanels;

export type TabPanelProps = ChakraTabPanelProps;
export const TabPanel = ChakraTabPanel;
