import * as linkify from 'linkifyjs';
import { Link, LucideIcon } from 'lucide-react';
import { AnySchema } from 'yup';

import { CustomPropertyTypeEnum } from '../../../../shared/models';
import { CUSTOM_PROPERTY_URL_MAX_LENGTH } from '../../../../shared/properties';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import { CustomPropertyValue } from './type';

export class UrlCustomPropertyDefinition extends BaseCustomPropertyDefinition {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.url;
  }

  get label(): string {
    return 'URL';
  }

  get icon(): LucideIcon {
    return Link;
  }

  // Settings
  // --------

  // no settings for now

  // Value
  // -----

  /**
   * Maximum length for the value
   */
  // @NOTE specific to url
  // @see https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
  get valueMaxLength(): number {
    return CUSTOM_PROPERTY_URL_MAX_LENGTH;
  }

  override get defaultValue(): CustomPropertyValue | null {
    return '';
  }

  public valueSchema(): AnySchema {
    const schema = yup
      .string()

      .transform((value) => {
        if (!value || typeof value !== 'string') {
          // not a string -> make it fails in the yup url() validation
          return value;
        }

        const foundURL = linkify.find(value, 'url', {
          defaultProtocol: 'https',
        });

        if (foundURL.length !== 1) {
          // not exactly one url -> make it fails in the yup url() validation
          return value;
        }

        if (foundURL[0].value !== value) {
          // not only the url -> make it fails in the yup url() validation
          return value;
        }

        return foundURL[0].href;
      })
      .url()
      // @NOTE url is only a regexp match, added this to limit the length
      .max(this.valueMaxLength)
      .nullable()
      // @NOTE cannot do transform in strict mode, no strong type check but all will fallback on the URL validation
      .strict(false);

    return schema;
  }
}

export const urlCustomPropertyDefinition = new UrlCustomPropertyDefinition();
