import BaseSchema from 'yup/lib/schema';

import {
  CommunicationMessageModel,
  CommunicationMessageStatusEnum,
  CommunicationRecipientModel,
  CommunicationRecipientTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import {
  COMMUNICATION_CONTENT_MAX_LENGTH,
  COMMUNICATION_SUBJECT_MAX_LENGTH,
} from '../constants';
import { communicationRecipientSchema } from './communication-recipient.schema';

const whenStatusIsSentOrReceivedFieldIsRequired = (
  status: CommunicationMessageStatusEnum,
  schema: BaseSchema,
) => {
  const statusWhenFieldIsRequired: CommunicationMessageStatusEnum[] = [
    CommunicationMessageStatusEnum.sent,
    CommunicationMessageStatusEnum.received,
  ];

  if (statusWhenFieldIsRequired.includes(status)) {
    return schema.required();
  }

  return schema.is([null]);
};

export const communicationMessageSchema = (): yup.SchemaOf<
  Omit<
    CommunicationMessageModel,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'deletedAt'
    | 'workspace'
    | 'case'
    | 'clientPortal'
    | 'attachments'
    | 'thread'
    | 'threads'
    | 'raw'
  > & {
    recipients: Omit<
      CommunicationRecipientModel,
      | 'id'
      | 'createdAt'
      | 'updatedAt'
      | 'deletedAt'
      | 'communicationMessage'
      | 'communicationMessageId'
      | 'user'
      | 'individual'
    >[];
  }
> => {
  return yup
    .object({
      caseId: yup.string().uuid().required(),
      workspaceId: yup.string().uuid().required(),
      status: yup
        .mixed<CommunicationMessageStatusEnum>()
        .oneOf(Object.values(CommunicationMessageStatusEnum))
        .required(),
      threadId: yup.string().uuid().nullable().default(null).optional(),
      clientPortalId: yup.string().uuid().nullable().default(null).optional(),
      isAutomated: yup.boolean().default(false).optional(),
      subject: yup.string().max(COMMUNICATION_SUBJECT_MAX_LENGTH).required(),
      content: yup.string().max(COMMUNICATION_CONTENT_MAX_LENGTH).required(),
      transporterMessageId: yup
        .string()
        .optional()
        .nullable()
        .default(null)
        .when('status', whenStatusIsSentOrReceivedFieldIsRequired),
      raw: yup.object().default({}),
      sentAt: yup
        .date()
        .nullable()
        .optional()
        .default(null)
        .when('status', whenStatusIsSentOrReceivedFieldIsRequired),
      recipients: yup
        .array()
        .of(communicationRecipientSchema())
        .min(2)
        .test(
          'at-least-one-with-type-to',
          'At least one recipient with type "to" is required',
          (recipients) => {
            return (recipients ?? []).some(
              (recipient) =>
                recipient.type === CommunicationRecipientTypeEnum.to,
            );
          },
        )
        .test(
          'at-least-one-with-type-from',
          'At least one recipient with type "from" is required',
          (recipients) => {
            return (recipients ?? []).some(
              (recipient) =>
                recipient.type === CommunicationRecipientTypeEnum.from,
            );
          },
        ),
    })
    .noUnknown()
    .defined();
};
