import { CircleCheckBig } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { FormattedTrans } from '../../../../../shared';

type SaveForLaterConfirmModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>;

export const SaveForLaterConfirmModal = ({
  isOpen,
  onClose,
}: SaveForLaterConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant="dialog-white"
      isCentered
      scrollBehavior="inside"
      closeOnOverlayClick
      closeOnEsc
      size="xl"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalBody position="relative">
          <VStack spacing="4">
            <Icon as={CircleCheckBig} color="green.500" boxSize={16} />

            <Text color="black" fontSize="lg">
              {t('forms.save_for_later.confirm_modal.title', {
                defaultValue: 'Your progress has been saved',
                ns: 'client-portal',
              })}
            </Text>

            <FormattedTrans
              as={Text}
              i18nKey="forms.save_for_later.confirm_modal.description"
              ns="client-portal"
              defaultValue="We just sent you an email so you can resume flow later on.<br/>You can safely close this page and come back later using the link in the email."
              textAlign="center"
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            {t('common.close', {
              defaultValue: 'Close',
              ns: 'client-portal',
            })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
