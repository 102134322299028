import { uniqBy } from 'lodash';

import { logAndAddError } from '@dotfile/frontend/shared/common';
import {
  CustomPropertyFormValue,
  CustomPropertyFormValues,
} from '@dotfile/frontend/shared/components';
import { ClientPortalForms_CustomPropertyValue } from '@dotfile/shared/data-access-client-portal';
import { getCustomPropertyDefinition } from '@dotfile/shared/domain';

export const initialCustomPropertiesValue = (
  customPropertiesValues: ClientPortalForms_CustomPropertyValue[],
): CustomPropertyFormValues => {
  const customProperties = uniqBy(
    customPropertiesValues.map((cpv) => cpv.customProperty),
    'id',
  );

  // @NOTE: Implementation _similar_ to apps/console/app/src/app/modules/case-management/custom-property/custom-properties-values-form/helpers/initial-custom-properties-values.ts

  const initialValues = Object.fromEntries(
    customProperties.map((customProperty) => {
      const customPropertyDefinition = getCustomPropertyDefinition(
        customProperty.type,
      );

      const existingValues = customPropertiesValues
        .filter(
          (customPropertyValue) =>
            customPropertyValue.customProperty.id === customProperty.id,
        )
        .map((v) => v.value);

      let initialValue: CustomPropertyFormValue;
      if (customPropertyDefinition.multiValues) {
        initialValue = existingValues;
      } else {
        if (existingValues.length > 1) {
          logAndAddError(
            new Error(
              `Expected at most one value for property ${customProperty.key} but found ${existingValues.length}, only the first value will be used`,
            ),
            { customProperty, values: existingValues },
          );
        }

        if (existingValues.length >= 1) {
          initialValue = customPropertyDefinition.parseValue(existingValues[0]);
        } else {
          initialValue = customPropertyDefinition.defaultValue;
        }
      }

      return [customProperty.key, initialValue];
    }),
  );

  return initialValues;
};
