import {
  ForwardedRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { BubbleMenu, EditorEvents, EditorProvider } from '@tiptap/react';

import { nonceId } from '@dotfile/frontend/shared/common';

import { BoxProps } from '../../layout/box/box';
import { forwardRef } from '../../utils/component-factory';
import { usePrevious } from '../../utils/hooks/chakra-hooks';
import { RichTextMenu } from './rich-text-menu';
import { RichTextSeeMore } from './rich-text-see-more';
import { RichTextStyled } from './rich-text-styled';
import { SyncEditor } from './sync-editor';
import {
  RichTextGetSuggestionItemsFn,
  RichTextHandle,
  RichTextModeEnum,
  RichTextVariant,
} from './types';
import { useExtensions } from './use-extensions';

export type RichTextProps = {
  isInvalid?: boolean;
  variant: RichTextVariant;

  mode: RichTextModeEnum;

  /**
   * @WARN The `placeholder` props props is not reactive, changes on the placeholder won't propagate to the RichText after first mount
   */
  placeholder?: string;

  /**
   * Set to true to display the Bubble menu
   * Default: false
   *
   * @see https://tiptap.dev/docs/editor/extensions/functionality/bubble-menu
   */
  withBubbleMenu?: boolean;

  /**
   * Set to true to activate the link extension
   * Default: false
   *
   * @see https://tiptap.dev/docs/editor/extensions/marks/link
   */
  withLink?: boolean;

  /**
   * Function to load suggestion / mention and enable the extensions
   *
   * @see https://tiptap.dev/docs/editor/extensions/nodes/mention
   */
  getSuggestionItems?: RichTextGetSuggestionItemsFn;

  /**
   * @WARN The `defaultContent` props is not reactive. To update the `content ` on a mounted RichText, use a `ref` to imperatively set the content
   */
  defaultContent?: string;
  onContentChange?: (newContent: string) => void;

  onToggleSeeMore?: (isOpen: boolean) => void;

  /**
   * Allow to remap tag used by the Bold and Italic extensions
   *
   * default are
   * - `b` for Bold, `strong` is also available
   * - `i` for Italic, `em` is also available
   *
   * @see https://tiptap.dev/docs/editor/extensions/marks/bold
   * @see https://tiptap.dev/docs/editor/extensions/marks/italic
   */
  extensionsTagMap?: Parameters<typeof useExtensions>[0]['tagMap'];
} & BoxProps;

export const RichText = forwardRef(
  (
    {
      variant,

      mode,
      isInvalid,
      placeholder,

      defaultContent,
      onContentChange,

      withBubbleMenu,
      withLink,
      getSuggestionItems,

      onToggleSeeMore,

      extensionsTagMap,

      ...boxProps
    }: RichTextProps,
    ref: ForwardedRef<RichTextHandle>,
  ): JSX.Element => {
    // Forward the imperative handle
    const syncEditorRef = useRef<RichTextHandle>();
    useImperativeHandle(ref, () => ({
      setContent: (content) => {
        syncEditorRef.current?.setContent(content);
      },
      focus: () => {
        syncEditorRef.current?.focus();
      },
    }));

    // Focus the editor when switching to edit mode
    const previousMode = usePrevious(mode);
    useEffect(() => {
      if (
        previousMode === RichTextModeEnum.display &&
        mode === RichTextModeEnum.edit
      ) {
        syncEditorRef.current?.focus();
      }
    }, [previousMode, mode]);

    const handleUpdate = useCallback(
      ({ editor }: EditorEvents['update']) => {
        if (onContentChange) {
          onContentChange(editor.getHTML());
        }
      },
      [onContentChange],
    );
    const extensions = useExtensions({
      getSuggestionItems,
      placeholder,
      tagMap: extensionsTagMap,
    });

    if (isInvalid) {
      boxProps.backgroundColor = 'red.50';
      boxProps.borderColor = 'red.500';
    }

    return (
      <RichTextStyled
        variant={variant}
        mode={mode}
        borderRadius="base"
        borderWidth="1px"
        borderColor="gray.100"
        _focusWithin={
          mode === RichTextModeEnum.edit ? { borderColor: 'black' } : undefined
        }
        {...boxProps}
        overflow="hidden"
      >
        <RichTextSeeMore
          mode={mode}
          alwaysIn={variant === 'compact'}
          onToggle={onToggleSeeMore}
        >
          <EditorProvider
            extensions={extensions}
            content={defaultContent}
            slotBefore={
              mode === RichTextModeEnum.edit &&
              variant !== 'compact' && (
                <RichTextMenu
                  borderBottomWidth="1px"
                  borderColor="gray.100"
                  padding="1"
                  withMention={!!getSuggestionItems}
                  withLink={withLink}
                />
              )
            }
            injectNonce={nonceId}
            onUpdate={handleUpdate}
          >
            {withBubbleMenu && (
              <BubbleMenu
                editor={null}
                tippyOptions={{
                  duration: 100,
                  hideOnClick: false,
                }}
              >
                <RichTextMenu
                  backgroundColor="gray.25"
                  borderRadius="base"
                  border="1px"
                  borderColor="gray.100"
                  padding="1"
                  // Never display mention in bubble menu
                  withLink={withLink}
                />
              </BubbleMenu>
            )}

            <SyncEditor mode={mode} ref={syncEditorRef} />
          </EditorProvider>
        </RichTextSeeMore>
      </RichTextStyled>
    );
  },
);
