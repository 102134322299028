import { formAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/form.ts

const baseStyleRequiredIndicator: SystemStyleFunction = (props) => {
  return {
    color: mode('gray.800', 'gray.600')(props),
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  requiredIndicator: baseStyleRequiredIndicator(props),
});

export const FormTheme = {
  parts: parts.keys,
  baseStyle,
};
