import { TFunction } from 'i18next';

import { CheckResultEnum } from '../../../shared/models';

export const checkResultDefinition: Record<
  CheckResultEnum | 'no_result',
  { label: (t: TFunction) => string; colorScheme: string; iconName: string }
> = {
  [CheckResultEnum.approved]: {
    label: (t: TFunction) =>
      t('check.result.approved', {
        defaultValue: 'Approved',
        ns: 'domain',
      }),
    colorScheme: 'green',
    iconName: 'check-circle-2',
  },
  [CheckResultEnum.error]: {
    label: (t: TFunction) =>
      t('check.result.error', { defaultValue: 'Error', ns: 'domain' }),
    colorScheme: 'orange',
    iconName: 'alert-circle',
  },
  [CheckResultEnum.rejected]: {
    label: (t: TFunction) =>
      t('check.result.rejected', {
        defaultValue: 'Rejected',
        ns: 'domain',
      }),
    colorScheme: 'red',
    iconName: 'x-circle',
  },
  ['no_result']: {
    label: (t: TFunction) =>
      t('check.result.unknown', {
        defaultValue: 'Unknown',
        ns: 'domain',
      }),
    colorScheme: 'gray',
    iconName: 'circle',
  },
};
