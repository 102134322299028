import { createLinks } from './create-links';

// Here we list all our application pages
export const clientPortalRoutes = {
  Login: '/?:lng&:magic-token',
  Forms: '/forms',
  Checks: '/checks?:checkModal',
  End: '/end',
} as const;

export const ClientPortalLinks = createLinks(clientPortalRoutes);
