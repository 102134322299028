import { CheckCircleIcon, LoaderIcon, XCircleIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { match, P } from 'ts-pattern';

import {
  Badge,
  HStack,
  Icon,
  Text,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_CheckInterface } from '@dotfile/shared/data-access-client-portal';
import { CheckStatusEnum } from '@dotfile/shared/domain';

type CheckStatusBadgeProps = {
  check: ClientPortalChecks_CheckInterface;
};
export const CheckStatusBadge = ({ check }: CheckStatusBadgeProps) => {
  const { t } = useTranslation();
  const statusProps = useMemo(
    () =>
      match(check.status)
        .with(CheckStatusEnum.approved, () => ({
          icon: CheckCircleIcon,
          color: 'green',
          tDefaultValue: 'Approved',
        }))
        .with(CheckStatusEnum.rejected, () => ({
          icon: XCircleIcon,
          color: 'red',
          tDefaultValue: 'Rejected',
        }))
        .with(CheckStatusEnum.expired, () => ({
          icon: XCircleIcon,
          color: 'orange',
          tDefaultValue: 'Expired',
        }))
        .with(
          P.union(CheckStatusEnum.need_review, CheckStatusEnum.processing),
          () => ({
            icon: LoaderIcon,
            color: 'gray',
            tDefaultValue: 'Under review',
          }),
        )
        .otherwise(() => null),
    [check.status],
  );

  if (!statusProps) {
    return null;
  }

  const { icon, color, tDefaultValue: defaultValue } = statusProps;

  return (
    <Badge colorScheme={color}>
      <HStack>
        <Icon as={icon} />
        <Text color={`${color}.700`}>
          {t(`checks.status.${check.status}`, {
            ns: 'client-portal',
            defaultValue,
          })}
        </Text>
      </HStack>
    </Badge>
  );
};
