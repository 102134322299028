import { Plus, Trash } from 'lucide-react';
import 'react-querybuilder/dist/query-builder-layout.css';

import { Icon } from '@chakra-ui/react';
import { ChakraActionElement } from '@react-querybuilder/chakra';

import { CombinatorSelector } from './combinator-selector';
import { MAX_GROUP_DEPTH } from './constants';
import { FieldSelector } from './field-selector';
import { TypedQueryBuilderProps } from './type';
import { ValueEditor } from './value-editor';
import { ValueSelector } from './value-selector';

export const controlElements: TypedQueryBuilderProps['controlElements'] = {
  addRuleAction: (props) => (
    <ChakraActionElement
      {...props}
      leftIcon={<Icon as={Plus} />}
      variant="ghost"
      label="Add condition"
      title="Add condition"
    />
  ),
  removeRuleAction: (props) => (
    <ChakraActionElement
      {...props}
      variant="ghost"
      label={<Icon as={Trash} />}
      title="Remove condition"
    />
  ),
  removeGroupAction: (props) => (
    <ChakraActionElement
      {...props}
      variant="ghost"
      label={<Icon as={Trash} />}
      title="Remove group condition"
    />
  ),
  addGroupAction: (props) =>
    props.level < MAX_GROUP_DEPTH ? (
      <ChakraActionElement
        {...props}
        leftIcon={<Icon as={Plus} />}
        variant="ghost"
        label="Add group"
        title="Add group"
      />
    ) : null,

  valueEditor: ValueEditor,
  valueSelector: ValueSelector,
  combinatorSelector: CombinatorSelector,
  fieldSelector: FieldSelector,
};
