import { CountryCoverage } from '../country-coverage.interface';

export type KompanyCountrySearchAccess = 'indexed' | 'live';
export type KompanyCountryFetchDataset = 'refresh' | 'index';

export interface KompanyCountryCoverage extends CountryCoverage {
  /**
   * Defined the order of preference between `indexed` and `live` access to search and fetch Kompany data
   * Note that `live` is not available in some countries
   * Pay attention to look the last "Coverage Jurisdictions"
   * @see https://drive.google.com/drive/folders/1K7hrNwf-qPgZaebTtv_TyUx4pwOT3BP1
   */
  searchAccess: KompanyCountrySearchAccess[];
  /**
   * Defined the list of available datasets to fetch Kompany data
   * @see https://www.kompany.com/static/cms-media/2024/12/upgrading_to_KYC_API_v2_19122024.pdf
   */
  fetchAccess: KompanyCountryFetchDataset[];
}

export const KOMPANY_COUNTRIES: KompanyCountryCoverage[] = [
  {
    // 'Argentina'
    code: 'AR',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Australia'
    code: 'AU',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Austria'
    code: 'AT',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Bahrain'
    code: 'BH',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Belgium'
    code: 'BE',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Botswana'
    code: 'BW',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Brazil'
    code: 'BR',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Canada Federal'
    code: 'CA',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'China'
    code: 'CN',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Croatia'
    code: 'HR',
    searchAccess: ['indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Cyprus'
    code: 'CY',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Czech Republic'
    code: 'CZ',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Denmark'
    code: 'DK',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Estonia'
    code: 'EE',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Finland'
    code: 'FI',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'France'
    code: 'FR',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Germany'
    code: 'DE',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'Georgia'
    code: 'GE',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Gibraltar'
    code: 'GI',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'Greece'
    code: 'GR',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Greenland'
    code: 'GL',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Hong Kong SAR'
    code: 'HK',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Hungary'
    code: 'HU',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'India'
    code: 'IN',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Ireland'
    code: 'IE',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Israel'
    code: 'IL',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Italy'
    code: 'IT',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Japan'
    code: 'JP',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Jersey'
    code: 'JE',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'Jordan'
    code: 'JO',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Latvia'
    code: 'LV',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Lithuania'
    code: 'LT',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'Luxembourg'
    code: 'LU',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'North Macedonia'
    code: 'MK',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Malaysia'
    code: 'MY',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Malta'
    code: 'MT',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'Netherlands'
    code: 'NL',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'New Zealand'
    code: 'NZ',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Norway'
    code: 'NO',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Poland'
    code: 'PL',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Romania'
    code: 'RO',
    searchAccess: ['indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Russia Federation'
    code: 'RU',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Singapore'
    code: 'SG',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'Slovakia'
    code: 'SK',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Slovenia'
    code: 'SI',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'South Korea'
    code: 'KR',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Spain'
    code: 'ES',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['refresh'],
  },
  {
    // 'Sweden'
    code: 'SE',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Switzerland'
    code: 'CH',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Taiwan, ROC'
    code: 'TW',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'Thailand'
    code: 'TH',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  // Not supported in Q4 - 2022
  // {
  //   // 'Ukraine'
  //   code: 'UA',
  //   searchAccess: ['live','indexed'],
  //   fetchAccess: ['index', 'refresh'],
  // },
  {
    // 'United Arab Emirates'
    code: 'AE',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  {
    // 'United Kingdom'
    code: 'GB',
    searchAccess: ['live', 'indexed'],
    fetchAccess: ['index', 'refresh'],
  },
  // Specific case of US, in fact we should have US-{stateCode} for all jurisdictions
  {
    // 'United States'
    code: 'US',
    searchAccess: ['indexed'],
    fetchAccess: ['index', 'refresh'],
  },
];
