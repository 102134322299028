import {
  Modal as ChakraModal,
  ModalBody as ChakraModalBody,
  ModalBodyProps as ChakraModalBodyProps,
  ModalCloseButton as ChakraModalCloseButton,
  ModalContent as ChakraModalContent,
  ModalContentProps as ChakraModalContentProps,
  ModalFooter as ChakraModalFooter,
  ModalFooterProps as ChakraModalFooterProps,
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps as ChakraModalHeaderProps,
  ModalOverlay as ChakraModalOverlay,
  ModalOverlayProps as ChakraModalOverlayProps,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/overlay/modal
export type ModalProps = ChakraModalProps;
export const Modal = ChakraModal;

export type ModalHeaderProps = ChakraModalHeaderProps;
export const ModalHeader = ChakraModalHeader;

export type ModalBodyProps = ChakraModalBodyProps;
export const ModalBody = ChakraModalBody;

export type ModalFooterProps = ChakraModalFooterProps;
export const ModalFooter = ChakraModalFooter;

export type ModalOverlayProps = ChakraModalOverlayProps;
export const ModalOverlay = ChakraModalOverlay;

export type ModalContentProps = ChakraModalContentProps;
export const ModalContent = ChakraModalContent;

export const ModalCloseButton = ChakraModalCloseButton;
