import { ForwardedRef, useEffect, useImperativeHandle, useState } from 'react';

import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';

import { VStack } from '../../../layout/stack/stack';
import { Text } from '../../../typography/text';
import { forwardRef } from '../../../utils/component-factory';
import { Button } from '../../button/button';
import { RichTextSuggestionItem } from '../types';

export type RichTextSuggestionHandle = {
  onKeyDown: (props: SuggestionKeyDownProps) => boolean;
};

export const RichTextSuggestionList = forwardRef(
  (
    props: SuggestionProps<RichTextSuggestionItem>,
    ref: ForwardedRef<RichTextSuggestionHandle>,
  ) => {
    const [preselectedIndex, setPreselectedIndex] = useState(0);

    const selectSuggestionItem = (index: number) => {
      const item = props.items[index];

      if (item) {
        props.command({ id: item.id, label: item.label });
      }
    };

    const upHandler = () => {
      setPreselectedIndex(
        (preselectedIndex + props.items.length - 1) % props.items.length,
      );
    };

    const downHandler = () => {
      setPreselectedIndex((preselectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
      selectSuggestionItem(preselectedIndex);
    };

    useEffect(() => setPreselectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    return (
      <VStack
        alignItems="start"
        borderRadius="base"
        borderColor="gray.100"
        borderWidth="1px"
        backgroundColor="white"
        p="2"
        overflowY="auto"
      >
        {props.items.length ? (
          props.items.map((item, index) => (
            <Button
              key={item.id}
              variant="ghost"
              isActive={index === preselectedIndex}
              onMouseEnter={() => setPreselectedIndex(index)}
              onClick={() => selectSuggestionItem(index)}
              colorScheme="gray"
              maxWidth="100%"
            >
              <Text
                color="inherit"
                noOfLines={1}
                wordBreak="break-all"
                display="inline"
              >
                {item.label}
              </Text>
            </Button>
          ))
        ) : (
          <Button variant="ghost" isDisabled color="black" colorScheme="gray">
            No result
          </Button>
        )}
      </VStack>
    );
  },
);
