import {
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
  PropertyMappingTypeEnum,
} from '../../../../shared/models';
import { BaseClientPortalStepDefinition } from './base-client-portal-step-definition';
import { StepDefinitionAvailableBlocks } from './types';

export class IndividualsEditStepDefinition extends BaseClientPortalStepDefinition {
  // General
  // -------
  get type(): ClientPortalStepTypeEnum {
    return ClientPortalStepTypeEnum.individuals_edit;
  }
  get title(): string {
    return 'Check the list of individuals related to your company';
  }
  get icon(): string {
    return 'users';
  }
  get isUnique(): boolean {
    return true;
  }
  public canHaveLogics(): boolean {
    return false;
  }

  get position(): number {
    return 4;
  }
  get clientPortalType(): ClientPortalTypeEnum[] {
    return [ClientPortalTypeEnum.KYB];
  }

  // Actions
  // -------
  get canBeMoved(): boolean {
    return false;
  }
  get canBeRemoved(): boolean {
    return false;
  }

  // Visibility
  // -------
  get canBeHidden(): boolean {
    return false;
  }

  // Blocks
  // --------
  public override availableBlocks(): StepDefinitionAvailableBlocks {
    return {
      field: {
        company: false,
        individual: [
          PropertyMappingTypeEnum.default,
          PropertyMappingTypeEnum.custom,
        ],
        case: false,
      },
      layout: true,
    };
  }
}

export const individualsEditStepDefinition =
  new IndividualsEditStepDefinition();
