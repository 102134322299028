import { PropsWithChildren, useEffect, useRef } from 'react';

import { useLocation } from '@swan-io/chicane';

import { useAuth } from '@dotfile/frontend/shared/common';
import { useToast } from '@dotfile/frontend/shared/design-system';

import { AppLoader } from '../../components';
import { useRedeemToken } from '../../hooks';
import { LinkExpiredScreen } from './components/link-expired-screen';

const ensureString = (value: string | string[]): string =>
  Array.isArray(value) ? value[0] : value;

export const SyncAuth = ({ children }: PropsWithChildren) => {
  const toast = useToast();
  const { auth, sync } = useAuth();
  const location = useLocation();
  const magicToken = ensureString(location.search['magic-token']);
  const redeemState = useRedeemToken({ magicToken });

  // This ref deduplicate the useEffect call made twice by React in dev/StrictMode
  const isSyncingRef = useRef(false);
  useEffect(() => {
    if (
      redeemState.status !== 'loading' &&
      auth.isAuthenticated === null &&
      !isSyncingRef.current
    ) {
      isSyncingRef.current = true;
      console.debug('🔄 Contact auth Sync');
      // eslint-disable-next-line promise/prefer-await-to-then
      sync().finally(() => {
        isSyncingRef.current = false;
      });
    }
  }, [auth.isAuthenticated, sync, redeemState.status]);

  // Display redeem errors
  useEffect(() => {
    if (redeemState.status === 'error') {
      toast({
        title: redeemState.message,
        status: 'error',
        duration: null, // persistent error, click on close to dismiss
      });
    }
  }, [redeemState, toast]);

  if (redeemState.status === 'expired-token') {
    return <LinkExpiredScreen token={magicToken} />;
  }

  if (auth.isAuthenticated === null) {
    return <AppLoader />;
  }

  return children;
};
