import { GroupBase } from 'chakra-react-select';
import { useMemo } from 'react';

import {
  formatLanguage,
  languageDefinition,
  LanguageEnum,
} from '@dotfile/shared/domain';

import { GenericSelect, SelectProps } from '../select';

type LanguageOption = { label: string; value: LanguageEnum };

export type LanguageSelectProps<IsMulti extends boolean = false> = Omit<
  SelectProps<IsMulti, LanguageOption>,
  'defaultValue'
> & { defaultValue?: LanguageEnum | LanguageEnum[] | null };

const defaultOptions = Object.keys(languageDefinition).map((code) => ({
  label: formatLanguage(code as LanguageEnum),
  value: code as LanguageEnum,
}));

export const LanguageSelect = <IsMulti extends boolean = false>({
  ...props
}: LanguageSelectProps<IsMulti>): JSX.Element => {
  const {
    isSearchable = true,
    placeholder,
    options,
    defaultValue,
    ...rest
  } = props;

  const resolvedOptions = options && options.length ? options : defaultOptions;

  const resolvedDefaultValue = useMemo(() => {
    if (typeof defaultValue === 'string') {
      return resolvedOptions.find(
        (o): o is LanguageOption => 'value' in o && o.value === defaultValue,
      );
    } else if (Array.isArray(defaultValue)) {
      return resolvedOptions.filter(
        (o): o is LanguageOption =>
          'value' in o && defaultValue.includes(o.value),
      );
    } else {
      return defaultValue;
    }
  }, [defaultValue, resolvedOptions]);

  return (
    <GenericSelect<LanguageOption, IsMulti, GroupBase<LanguageOption>>
      placeholder={placeholder ? placeholder : 'Select language'}
      isSearchable={isSearchable}
      options={resolvedOptions}
      defaultValue={resolvedDefaultValue}
      isClearable
      {...rest}
    />
  );
};
