export const VendorEnum = {
  comply_advantage: 'comply_advantage',
  pappers: 'pappers',
  kompany: 'kompany',
  creditsafe: 'creditsafe',
  veriff: 'veriff',
  kyckr: 'kyckr',
  open_ai: 'open_ai',
  mistral_ai: 'mistral_ai',
  checkout: 'checkout',
  companies_house: 'companies_house',
  postmark: 'postmark',
  inpi: 'inpi',
  idnow: 'idnow',
  onfido: 'onfido',

  /**
   * @deprecated legacy
   */
  ubble: 'ubble',
} as const;
export type VendorEnum = (typeof VendorEnum)[keyof typeof VendorEnum];
