import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Center,
  FormModal,
  Spinner,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { formatDate, nameFormat } from '@dotfile/shared/common';
import {
  ClientPortalChecks_IdDocumentCheck,
  ClientPortalChecks_Individual,
} from '@dotfile/shared/data-access-client-portal';

import {
  CheckDescription,
  CheckModalHeader,
  CheckReview,
  useCanUpdateReferenceData,
} from '../shared';
import {
  DataComparisonAlert,
  FrontBackUploadControllers,
  IsItTheRightDocumentForIndividual,
  IsItTheRightDocumentRadioCard,
  ProvideValidIdentityDocumentForIndividual,
  UpdateReferenceDataControllers,
} from './components';
import { DownloadFiles } from './components/download-files';
import { useUpdateReferenceDataForm, useUploadForm } from './hooks';

type IdDocumentModalProps = {
  check: ClientPortalChecks_IdDocumentCheck;
  individual: ClientPortalChecks_Individual;
  isOpen: boolean;
  onClose: () => void;
};

export const IdDocumentModal = ({
  check,
  individual,
  isOpen,
  onClose,
}: IdDocumentModalProps) => {
  const { t, i18n } = useTranslation();

  const individualFullName = nameFormat(
    individual.firstName,
    individual.lastName,
  );
  const individualBirthDate = individual.birthDate
    ? formatDate(individual.birthDate, {
        locale: i18n.language,
      })
    : null;

  const canUpdateReferenceData = useCanUpdateReferenceData(check);

  const uploadForm = useUploadForm(check.id, onClose);
  const updateReferenceDataForm = useUpdateReferenceDataForm(check, individual);

  const form =
    updateReferenceDataForm.isRightDocumentForIndividual === 'yes'
      ? updateReferenceDataForm
      : uploadForm;

  const submitText =
    canUpdateReferenceData &&
    updateReferenceDataForm.isRightDocumentForIndividual !== 'no'
      ? t('common.confirm', {
          ns: 'client-portal',
          defaultValue: 'Confirm',
        })
      : t('checks.send_for_review', {
          ns: 'client-portal',
          defaultValue: 'Send for review',
        });

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={form.reset}
      hasCloseButton={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onSubmit={form.handleSubmit}
      submitIsDisabled={form.submitIsDisabled}
      header={<CheckModalHeader check={check} />}
      submitText={submitText}
      cancelText={t('common.cancel', {
        defaultValue: 'Cancel',
        ns: 'client-portal',
      })}
      submitIsLoading={form.isSubmitting || uploadForm.pollingLoading}
      textAlign="left"
      size="2xl"
    >
      {uploadForm.pollingLoading || updateReferenceDataForm.isSubmitting ? (
        <Center height="200px">
          <Spinner size="xl" color="custom.500" />
        </Center>
      ) : (
        <VStack align="stretch" as="form" w="100%" spacing="4">
          <CheckDescription check={check} />

          {canUpdateReferenceData ? (
            <>
              <DataComparisonAlert />

              <IsItTheRightDocumentForIndividual
                individualFullName={individualFullName}
                individualBirthDate={individualBirthDate}
              />

              <DownloadFiles {...check.data.information} />

              <IsItTheRightDocumentRadioCard
                value={updateReferenceDataForm.isRightDocumentForIndividual}
                onChange={
                  updateReferenceDataForm.setIsRightDocumentForIndividual
                }
              />

              {updateReferenceDataForm.isRightDocumentForIndividual ===
                'yes' && (
                <FormProvider {...updateReferenceDataForm.methods}>
                  <UpdateReferenceDataControllers
                    isBirthDateRequired={
                      updateReferenceDataForm.isBirthDateRequired
                    }
                  />
                </FormProvider>
              )}

              {updateReferenceDataForm.isRightDocumentForIndividual ===
                'no' && (
                <FormProvider {...uploadForm.methods}>
                  <ProvideValidIdentityDocumentForIndividual
                    individualFullName={individualFullName}
                    individualBirthDate={individualBirthDate}
                  />

                  <FrontBackUploadControllers check={check} />
                </FormProvider>
              )}
            </>
          ) : (
            <>
              <CheckReview check={check} />

              <FormProvider {...uploadForm.methods}>
                <FrontBackUploadControllers check={check} />
              </FormProvider>
            </>
          )}
        </VStack>
      )}
    </FormModal>
  );
};
