import {
  HStack as ChakraHStack,
  Stack as ChakraStack,
  StackDivider as ChakraStackDivider,
  StackProps as ChakraStackProps,
  VStack as ChakraVStack,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/layout/stack
export type StackProps = ChakraStackProps;
export const Stack = ChakraStack;
export const HStack = ChakraHStack;
export const VStack = ChakraVStack;
export const StackDivider = ChakraStackDivider;
