import { LanguageEnum } from './language.enum';
import { subEnum } from './sub-enum.helper';

export const ClientPortalAvailableDefaultLanguageEnum = subEnum(LanguageEnum, [
  LanguageEnum.en,
  LanguageEnum.fr,
  LanguageEnum.it,
  LanguageEnum.de,
  LanguageEnum.es,
  LanguageEnum.nl,
  LanguageEnum.pt,
  LanguageEnum.pl,
]);
export type ClientPortalAvailableDefaultLanguageEnum =
  (typeof ClientPortalAvailableDefaultLanguageEnum)[keyof typeof ClientPortalAvailableDefaultLanguageEnum];
