import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const variantSimple = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    thead: {
      borderBottomWidth: 1,
      borderColor: mode(`${c}.200`, `${c}.700`)(props),
    },
    th: {
      color: mode('gray.600', 'gray.300')(props),

      borderBottomWidth: 0,

      fontFamily: 'heading',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      letterSpacing: 'normal',
      textAlign: 'start',
    },
    td: {
      borderBottomWidth: 0,
    },
    tr: {
      borderBottomWidth: 1,
      borderColor: mode(`${c}.200`, `${c}.700`)(props),
      _last: {
        borderBottomWidth: 0,
      },
      _hover: {
        backgroundColor: 'gray.50',
      },
    },
  };
});

const variantBordered = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    table: {
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      color: 'gray.500',

      fontFamily: 'heading',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      letterSpacing: 'normal',
      textAlign: 'start',

      borderBottomWidth: 1,
      borderColor: mode(`${c}.200`, `${c}.700`)(props),
      _first: {
        borderLeftWidth: 1,
      },
      _last: {
        borderRightWidth: 1,
      },
    },
    tr: {
      _first: {
        th: {
          borderTopWidth: 1,
          _first: {
            borderTopLeftRadius: 'base',
          },
          _last: {
            borderTopRightRadius: 'base',
          },
        },
      },
      _last: {
        td: {
          _first: {
            borderBottomLeftRadius: 'base',
          },
          _last: {
            borderBottomRightRadius: 'base',
          },
        },
      },

      _hover: {
        backgroundColor: 'gray.50',
      },
    },
    td: {
      borderBottomWidth: 1,
      borderColor: mode(`${c}.200`, `${c}.700`)(props),
      _first: {
        borderLeftWidth: 1,
      },
      _last: {
        borderRightWidth: 1,
      },
    },
  };
});

const variants = {
  simple: variantSimple,
  bordered: variantBordered,
};

const sizes = {
  sm: definePartsStyle({
    th: {
      px: '2',
      py: '3',
      fontSize: 'sm',
    },
    td: {
      px: '2',
      py: '3',
      fontSize: 'sm',
      lineHeight: '3',
    },
  }),
  md: {
    th: {
      px: '4',
      py: '3',
      fontSize: 'md',
    },
    td: {
      px: '4',
      py: '3',
      fontSize: 'md',
    },
  },
};

export const TableTheme = defineMultiStyleConfig({
  variants,
  sizes,
  defaultProps: {
    variant: 'simple',
    size: 'sm',
    colorScheme: 'gray',
  },
});
