import { UsersIcon } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  Modal,
  ModalOverlay,
  useDisclosure,
  useIsMobile,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_Individual } from '@dotfile/shared/data-access-client-portal';

import { BusinessContactListModalContent } from './components';
import { InviteUserModalContent } from './components';

const ShareAccessStepEnum = {
  list: 'list',
  invite: 'invite',
};

export const ShareAccessButtonModal = () => {
  const { t } = useTranslation();
  const [businessContact, setBusinessContact] = useState<Pick<
    ClientPortalChecks_Individual,
    'id' | 'firstName' | 'lastName' | 'email'
  > | null>(null);
  const [step, setStep] = useState<
    (typeof ShareAccessStepEnum)[keyof typeof ShareAccessStepEnum]
  >(ShareAccessStepEnum.list);

  const modalDisclosure = useDisclosure();
  const isMobile = useIsMobile();

  return (
    <>
      <Button
        width={isMobile ? 'full' : 'auto'}
        variant="outline"
        leftIcon={<Icon as={UsersIcon} />}
        onClick={modalDisclosure.onOpen}
      >
        {t('collaboration.share_access', {
          defaultValue: 'Share access',
          ns: 'client-portal',
        })}
      </Button>
      <Modal
        variant="dialog-white"
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        size={isMobile ? 'full' : '2xl'}
        isCentered
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />

        {step === ShareAccessStepEnum.list && (
          <BusinessContactListModalContent
            onClose={modalDisclosure.onClose}
            onInviteUser={() => setStep(ShareAccessStepEnum.invite)}
            onEmailUser={(businessContact) => {
              setBusinessContact(businessContact);
              setStep(ShareAccessStepEnum.invite);
            }}
          />
        )}
        {step === ShareAccessStepEnum.invite && (
          <InviteUserModalContent
            businessContact={businessContact}
            onClose={() => {
              setStep(ShareAccessStepEnum.list);
              setBusinessContact(null);
            }}
          />
        )}
      </Modal>
    </>
  );
};
