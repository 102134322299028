import { ComponentStyleConfig } from '@chakra-ui/theme';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/tabs.ts
// for default ChakraUI theme
const variantUnstyled = {
  tab: {
    paddingInlineEnd: 0,
    paddingInlineStart: 0,
  },
};

export const TabsTheme: ComponentStyleConfig = {
  baseStyle: {
    root: {
      height: '100%',
    },
    tablist: {
      height: 'inherit',
      marginBottom: '-1px', // to match with figma and the header border-bottom
    },
    tab: {
      color: 'gray.500',
      borderBottom: '2px solid',
      borderColor: 'transparent',
      lineHeight: '0',

      _focus: {
        zIndex: 1,
        boxShadow: 'unset',
      },
      _selected: {
        color: 'black',
        borderColor: 'blue.700',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  },
  variants: {
    unstyled: variantUnstyled,
  },
  sizes: {
    md: {
      tab: {
        marginRight: 12,
        py: 5,
      },
    },
    sm: {
      tab: {
        marginRight: 10,
        fontSize: 'md',
        py: 2,
      },
    },
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'gray',
    variant: 'unstyled',
  },
};
