import copy from 'copy-to-clipboard';

type CopyToClipboardAsyncParams<T> = {
  fetchValue: () => Promise<T>;
  getCopiedValue: (response: T) => string | null;
};

/*
Handles copy to clipboard, including async copy to clipboard.
Async copy must use the object argument, providing a fetchValue function.
Ex:
const response = await copyToClipboard({
  fetchValue: () => fetch('https://example.com'),
  getCopiedValue: (response) => response.text(),

Inspired from https://wolfgangrittner.dev/how-to-use-clipboard-api-in-safari/
Customized to fit our needs (including returning the fetched value)
 */
export function copyToClipboard(value: string): Promise<void>;
export function copyToClipboard<T>(
  arg: CopyToClipboardAsyncParams<T>,
): Promise<T>;
export async function copyToClipboard<T>(
  arg: string | CopyToClipboardAsyncParams<T>,
): Promise<void | T> {
  if (typeof arg === 'string') {
    copy(arg);
    return;
  }

  // Handle Safari using async clipboard API
  // Safari requires the clipboard.writeText to be called synchronously in the
  // user gesture (meaning there cannot be any async call before calling
  // clipboard.writeText). So we trigger the fetch, but do not await it
  const fetchPromise = arg.fetchValue();

  // To keep synchronous behavior, we cannot await the fetchValue promise
  // eslint-disable-next-line promise/prefer-await-to-then
  const copiedValuePromise = fetchPromise.then((response) => {
    const copiedValue = arg.getCopiedValue(response);

    // If there's no value to copy, throw an abort error
    if (!copiedValue) {
      throw new DOMException('Nothing to copy', 'AbortError');
    }

    return copiedValue;
  });

  if (typeof ClipboardItem !== 'undefined' && navigator.clipboard.write) {
    // Create the ClipboardItem *synchronously* in the user click
    // event. The 'text/plain' entry is an async function or promise that
    // eventually yields the text we want to copy (if any).
    const item = new ClipboardItem({
      // To keep synchronous behavior, we cannot await the fetchValue promise
      // eslint-disable-next-line promise/prefer-await-to-then
      'text/plain': copiedValuePromise.then(
        (text) => new Blob([text], { type: 'text/plain' }),
      ),
    });

    // Actually write to the clipboard. Because the creation of
    // the ClipboardItem is synchronous in this function (user gesture),
    // Safari treats the clipboard action as "user-initiated" even though
    // the data arrives async.
    await navigator.clipboard.write([item]);
  } else {
    // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
    //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
    //   Good news is that other than Safari, Firefox does not care about
    //   Clipboard API being used async in a Promise.
    const value = await copiedValuePromise;
    await navigator.clipboard.writeText(value);
  }

  // We also want the final data to do post-copy actions (toast, etc.)
  return fetchPromise;
}
