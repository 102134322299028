import {
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
  RadioProps as ChakraRadioProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/components/radio

export const Radio = ChakraRadio;
export type RadioProps = ChakraRadioProps;

export const RadioGroup = ChakraRadioGroup;
export type RadioGroupProps = ChakraRadioGroupProps;
