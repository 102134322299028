export const stopWords = {
  lastName: [
    'van',
    'der',
    'de',
    'den',
    'di',
    'du',
    'el',
    'la',
    'le',
    'von',
    'of',
    'the',
    'bin',
    'al',
    'ep',
    'dr',
  ],
  companySuffix: [
    // @NOTE suffix without dot . that needs to be cleaned before comparison also case insensitive
    // Avoid duplication, and order by alphabetical order
    'AG',
    'BV',
    'Co',
    'Corp',
    'Corporation',
    'EI',
    'EIRL',
    'eK',
    'EURL',
    'eV',
    'Foundation',
    'GEIE',
    'GIE',
    'GmbH',
    'haftungsbeschränkt',
    'Inc',
    'Incorporated',
    'KG',
    'KGaA',
    'Limited',
    'LLC',
    'LLP',
    'LP',
    'Ltd',
    'NV',
    'OHG',
    'PartG',
    'PartGG',
    'PC',
    'PLC',
    'Pte',
    'Pty',
    'Pvt',
    'SA',
    'SARL',
    'SAS',
    'SASU',
    'SC',
    'SCA',
    'SCE',
    'SCEA',
    'SCIA',
    'SCIC',
    'SCOP',
    'SCS',
    'SE',
    'SIA',
    'SL',
    'SNC',
    'SPRL',
    'SRL',
    'UG',
  ],
  streetAddress: [
    'Apartment',
    'Appartement',
    'Avenue',
    'Ave',
    'Bd',
    'Blvd',
    'Boulevard',
    'Box',
    'Cir',
    'Court',
    'Ct',
    'Drive',
    'Dr',
    'Flat',
    'floor',
    'Lane',
    'lieu dit',
    'Ln',
    'Loop',
    'Lp',
    'Pl',
    'Place',
    'PO',
    'POB',
    'Pt',
    'Rd',
    'Road',
    'Route',
    'Rr',
    'Rte',
    'Rural',
    'Sq',
    'St',
    'Ste',
    'Street',
    'Suit',
    'Trl',
    'Way',
    'Wy',
  ],
  registrationNumber: ['r.c.s.'],
};
