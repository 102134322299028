import { ChakraStylesConfig, StylesConfig } from 'chakra-react-select';
import { ComponentProps, useCallback, useMemo } from 'react';
import {
  Option,
  OptionGroup,
  ValueSelectorProps as RqbValueSelectorProps,
} from 'react-querybuilder';
import { FullOption } from 'react-querybuilder';

import { GenericSelect } from '../select';
import { selectorInsidePopoverStylesWorkaround } from './value-selector-style-workaround';

type ValueSelectorProps<TOption extends Option> = Pick<
  RqbValueSelectorProps<TOption>,
  'options' | 'handleOnChange' | 'value' | 'multiple'
> & { width?: string };

const isOptionGroup = <TOption extends Option>(
  option: ValueSelectorProps<TOption>['options'][number],
): option is OptionGroup<FullOption<string>> => 'options' in option;

export const ValueSelector = <TOption extends Option>({
  options,
  handleOnChange,
  value,
  multiple,
  width,
}: ValueSelectorProps<TOption>): JSX.Element => {
  const resolvedValue = useMemo(() => {
    if (!value) {
      return null;
    }

    const flatOptions = options.flatMap((o) =>
      isOptionGroup(o) ? o.options : o,
    );

    if (Array.isArray(value)) {
      return flatOptions.filter((o) => value.includes(o.value));
    }

    return flatOptions.find((o) => value === o.value) ?? null;
  }, [value, options]);

  const onChange = useCallback(
    (
      newValue: Parameters<
        Required<ComponentProps<typeof GenericSelect>>['onChange']
      >[0],
    ) => {
      if (!newValue) {
        handleOnChange(null);
      } else if (multiple) {
        const newMultiValues: FullOption[] = Array.isArray(newValue)
          ? newValue
          : [newValue];
        handleOnChange(newMultiValues.map((v) => v.value));
      } else {
        const newSingleValue: FullOption = Array.isArray(newValue)
          ? newValue[0]
          : newValue;
        handleOnChange(newSingleValue.value);
      }
    },
    [handleOnChange, multiple],
  );

  const chakraStyles: ChakraStylesConfig = useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        width,
      }),
    }),
    [width],
  );

  return (
    <GenericSelect
      options={options}
      onChange={onChange}
      value={resolvedValue}
      chakraStyles={chakraStyles}
      styles={selectorInsidePopoverStylesWorkaround as StylesConfig}
      isMulti={multiple}
      isSearchable
    />
  );
};
