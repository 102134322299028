import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const sizes = {
  md: defineStyle({
    fontSize: '24px',
  }),
  sm: defineStyle({
    fontSize: '16px',
  }),
  xs: defineStyle({
    fontSize: '12px',
  }),
};

export const IconTheme = defineStyleConfig({
  sizes,
  defaultProps: {
    size: 'sm',
  },
});
