import { FormProvider } from 'react-hook-form';

import { FullContainer } from '../../../shared';
import {
  Blocks,
  kycIndividualIndexSelector,
  StepFooter,
  StepHeader,
  StepProps,
  useFieldsForm,
  useFormDatastore,
  useHandleFieldsForm,
  useHandleSaveForLater,
} from '../shared';

export const IndividualEditStep = ({ step, onSubmitStep }: StepProps) => {
  const caseData = useFormDatastore((state) => state.data.case);
  const patchCaseData = useFormDatastore((state) => state.patchCaseData);

  const individualIndex = useFormDatastore(kycIndividualIndexSelector);
  const individual = useFormDatastore(
    (state) => state.data.individuals?.[individualIndex],
  );
  const patchIndividualData = useFormDatastore(
    (state) => state.patchIndividualData,
  );

  const { methods, displayedBlocks } = useFieldsForm({
    step,
    data: {
      case: caseData,
      individual,
    },
  });

  const [handleSaveForLater, saveForLaterState] = useHandleSaveForLater();

  const handleSubmit = useHandleFieldsForm({
    methods,
    handleDatastore: ({ data }) => {
      if (data.case) {
        patchCaseData(data.case);
      }
      if (data.individual || !individual?.isBusinessContact) {
        patchIndividualData(
          {
            ...data.individual,
            // KYC: set the individual as Business contact
            isBusinessContact: true,
          },
          individualIndex,
        );
      }
    },
    onSubmitStep,
    onSaveForLater: handleSaveForLater,
  });

  return (
    <FullContainer
      footer={
        <StepFooter
          saveForLaterState={saveForLaterState}
          isNextLoading={methods.formState.isSubmitting}
        />
      }
      as="form"
      noValidate
      onSubmit={handleSubmit}
    >
      <FormProvider {...methods}>
        <StepHeader />
        <Blocks blocks={displayedBlocks} />
      </FormProvider>
    </FullContainer>
  );
};
