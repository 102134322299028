import { snakeCase } from 'lodash';
import { ValidationError } from 'yup';

// @TODO - E-1343 - Yup umbrella issue
// This is responsible to transform the property in error message into
// snake case for the external-api but this is not optimal and brittle when
// some property are not in the yup error path

/**
 * Some errors will be on a path that only contains 'company' or 'individual' but actually
 * the error message is overridden to include both. This will duplicate the path segment
 * to make sure all the properties names are properly overridden in the message
 * @param paths
 * @returns
 */
const duplicateCompanyIndividualPath = (
  paths: string[] | undefined,
): string[] => {
  const duplicatedPaths = (paths ?? []).reduce<string[]>((acc, path) => {
    acc.push(path);

    if (path.includes('company')) {
      acc.push(path.replaceAll('company', 'individual'));
    }
    if (path.includes('Company')) {
      acc.push(path.replaceAll('Company', 'Individual'));
    }

    if (path.includes('individual')) {
      acc.push(path.replaceAll('individual', 'company'));
    }
    if (path.includes('Individual')) {
      acc.push(path.replaceAll('Individual', 'Company'));
    }

    return acc;
  }, []);

  return duplicatedPaths;
};

const snakeCaseifyPath = (
  message: string,
  path: string | undefined,
  label: unknown | undefined,
): string => {
  if (path) {
    const pathElements = duplicateCompanyIndividualPath(path.split('.'));

    pathElements.forEach((pathElement) => {
      message = message.replace(pathElement, snakeCase(pathElement));
    });
  }

  if (typeof label === 'string') {
    message = message.replace(label, snakeCase(label));
  }

  return message;
};

export const snakeCaseifyYupErrorPaths = (
  error: ValidationError,
): ValidationError => {
  error.inner = error.inner.map((innerError) => {
    innerError.message = snakeCaseifyPath(
      innerError.message,
      innerError.path,
      innerError.params?.['label'],
    );

    return innerError;
  });

  if (error.inner.length === 1) {
    error.message = error.inner[0].message;
  } else if (error.path || error.params?.['label']) {
    error.message = snakeCaseifyPath(
      error.message,
      error.path,
      error.params?.['label'],
    );
  }

  return error;
};
