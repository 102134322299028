import { PropertyMappingEntityEnum, PropertyMappingTypeEnum } from '../models';
import { PropertyMapping } from './types';

const SEPARATOR = '__';

export const generateKeyFromMapping = (mapping: PropertyMapping): string => {
  const key = [mapping.entity, mapping.type, mapping.key].join(SEPARATOR);
  return key;
};

export const extractMappingFromKey = (key: string): PropertyMapping => {
  const parts = key.split(SEPARATOR);

  if (parts.length < 3) {
    throw new Error(
      `Unable to extract mapping from key '${key}': only ${parts.length} parts`,
    );
  }

  const entity = parts[0];
  if (!isEntity(entity)) {
    throw new Error(
      `Unable to extract mapping from key '${key}': invalid entity '${entity}'`,
    );
  }

  const type = parts[1];
  if (!isType(type)) {
    throw new Error(
      `Unable to extract mapping from key '${key}': invalid type '${type}'`,
    );
  }

  const mappingKey =
    // Mapping key itself could contains the separator
    parts.slice(2).join(SEPARATOR);
  if (mappingKey.length === 0) {
    throw new Error(
      `Unable to extract mapping from key '${key}': no mapping key`,
    );
  }

  const mapping = { type, entity, key: mappingKey };

  return mapping;
};

const isType = (type: string | undefined): type is PropertyMappingTypeEnum =>
  !!type &&
  Object.values(PropertyMappingTypeEnum).includes(
    type as PropertyMappingTypeEnum,
  );

const isEntity = (
  entity: string | undefined,
): entity is PropertyMappingEntityEnum =>
  !!entity &&
  Object.values(PropertyMappingEntityEnum).includes(
    entity as PropertyMappingEntityEnum,
  );
