import { CURRENCIES } from './currencies.constant';

const { EUR, USD, GBP, CHF, CAD, AUD, JPY, INR, ...otherCurrencies } =
  CURRENCIES;

/**
 * Alphabetically sorted list of all currency code with a few important one arbitrarily put first
 */
export const SORTED_CURRENCY_CODES: string[] = [
  'EUR',
  'USD',
  'GBP',
  'CHF',
  'CAD',
  'AUD',
  'JPY',
  'INR',
  ...Object.keys(otherCurrencies).sort(),
];
