import {
  CompanyClassificationModel,
  CompanyClassificationTypeEnum,
} from '../models';
import isicClassifications from './classifications/isic-classifications';
import naceClassifications from './classifications/nace-classifications';
import nafApeClassifications from './classifications/naf-ape-classifications';
import sicClassifications from './classifications/sic-classifications';
import sicUkClassifications from './classifications/sic-uk-classifications';

export const companyClassifications: Record<
  CompanyClassificationTypeEnum,
  CompanyClassificationModel[]
> = {
  [CompanyClassificationTypeEnum.ISIC]: isicClassifications,
  [CompanyClassificationTypeEnum.NACE]: naceClassifications,
  [CompanyClassificationTypeEnum.NAF]: nafApeClassifications,
  [CompanyClassificationTypeEnum.SIC]: sicClassifications,
  [CompanyClassificationTypeEnum.SIC_UK]: sicUkClassifications,
};

export const companyClassificationTypeLabels: Record<
  CompanyClassificationTypeEnum,
  string
> = {
  [CompanyClassificationTypeEnum.ISIC]: 'ISIC',
  [CompanyClassificationTypeEnum.SIC]: 'SIC',
  [CompanyClassificationTypeEnum.NACE]: 'NACE',
  [CompanyClassificationTypeEnum.NAF]: 'APE/NAF',
  [CompanyClassificationTypeEnum.SIC_UK]: 'SIC (UK)',
};
