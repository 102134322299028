import { CombinatorSelectorProps } from 'react-querybuilder';

import { ValueSelector } from './value-selector';

export const CombinatorSelector = (
  props: CombinatorSelectorProps,
): JSX.Element => {
  return (
    <ValueSelector
      {...props}
      width="107px" // avoid re-sizing after selecting a combinator, 107px is the size for the And option
    />
  );
};
