import { useEffect } from 'react';

import { ClientPortal_Version } from '@dotfile/shared/data-access-client-portal';

import { useInitI18nContext } from './i18n.context';

export const useInitI18n = (
  latestClientPortalVersion: ClientPortal_Version | undefined,
) => {
  const initI18n = useInitI18nContext();

  useEffect(() => {
    if (!latestClientPortalVersion) {
      return;
    }

    const { clientPortal, id, translations } = latestClientPortalVersion;

    initI18n({
      ids: {
        clientPortalId: clientPortal.id,
        workspaceId: clientPortal.workspaceId,
        versionId: id,
      },

      fallbackLng: clientPortal.defaultLanguage,
      supportedLngs: [
        clientPortal.defaultLanguage,
        ...translations.map((t) => t.language),
      ],
    });
  }, [latestClientPortalVersion, initI18n]);
};
