import { FC } from 'react';

import {
  createHttpProvider,
  createHttpProviderWithoutAuth,
  createReactQuery,
} from '@dotfile/frontend/shared/common';

import { environment } from '../../../environments/environment';
import { onRefreshAuthFailed } from './auth';

export const HttpProvider = createHttpProvider({
  environment,
  onRefreshAuthFailed,
});

export const HttpProviderWithoutAuth = createHttpProviderWithoutAuth({
  environment,
});

export const {
  QueryClientProvider,
}: { QueryClientProvider: FC<{ children: React.ReactElement }> } =
  createReactQuery({
    // Set this variable locally in apps/app/.env.local
    devtools: process.env['NX_REACT_QUERY_DEVTOOL_ENABLE'] === 'true',
  });
