import { ClientPortalLogicTypeEnum } from '../../../../shared/models';
import { isBlockField } from '../../client-portal-block';
import { getClientPortalStepDefinition } from '../../client-portal-step';
import { StepOrBlock } from '../types';

export const clientPortalLogicTypeDefinition: Record<
  ClientPortalLogicTypeEnum,
  {
    label: string;
    description: (stepOrBlock: StepOrBlock) => string;
    isAvailable: (stepOrBlock: StepOrBlock) => boolean;
  }
> = {
  [ClientPortalLogicTypeEnum.display]: {
    label: 'Visibility',
    description: ({ step }) =>
      `Hide or display this ${step ? 'step' : 'block'}`,
    isAvailable: ({ step }) =>
      step
        ? getClientPortalStepDefinition(step.type).canHaveLogics(
            ClientPortalLogicTypeEnum.display,
          )
        : true, // display always available for block
  },
  [ClientPortalLogicTypeEnum.required]: {
    label: 'Requirement',
    description: () => `Make field completion mandatory`,
    isAvailable: ({ block }) => (block ? isBlockField(block) : false), // Only available for block field
  },
  [ClientPortalLogicTypeEnum.prevent_submit]: {
    label: 'Block step submission',
    description: () => `Block step submission on specific conditions`,
    isAvailable: ({ step }) =>
      step
        ? getClientPortalStepDefinition(step.type).canHaveLogics(
            ClientPortalLogicTypeEnum.prevent_submit,
          )
        : false,
  },
};
