import { CustomPropertyEntityTypeEnum } from '../../../shared/models';

export const customPropertyEntityTargetDefinition: Record<
  CustomPropertyEntityTypeEnum,
  {
    label: string;
  }
> = {
  [CustomPropertyEntityTypeEnum.case]: {
    label: 'Case',
  },
  [CustomPropertyEntityTypeEnum.company]: {
    label: 'Company',
  },
  [CustomPropertyEntityTypeEnum.individual]: {
    label: 'Individual',
  },
};
