import { companyDefaultPropertiesDefinition } from '../../../shared/properties';
import { createClientPortalPropertiesDefinition } from './helpers';

const CLIENT_PORTAL_COMPANY_PROPERTIES = [
  'name',
  'commercialName',
  'registrationNumber',
  'registrationDate',
  'country',
  'status',
  'legalForm',
  'entityLegalForm',
  'bankingInformation',
  'address',
  'address.city',
  'address.country',
  'address.postalCode',
  'address.region',
  'address.state',
  'address.streetAddress',
  'address.streetAddress2',
  'taxIdentificationNumber',
  'employerIdentificationNumber',
  'classifications',
  'shareCapital',
  'websiteUrl',
] as const;

export const clientPortalCompanyPropertiesDefinition =
  createClientPortalPropertiesDefinition(
    companyDefaultPropertiesDefinition,
    CLIENT_PORTAL_COMPANY_PROPERTIES,
  );

export const allClientPortalCompanyPropertiesDefinition = Object.values(
  clientPortalCompanyPropertiesDefinition,
);
