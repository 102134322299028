import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioButton, RadioButtonProps } from './radio-button';

export type YesNoButtonProps = Omit<
  RadioButtonProps,
  'options' | 'defaultValue' | 'onChange'
> & {
  defaultValue?: boolean;
  onChange?: (nextValue: boolean) => void;
};

/**
 * Yes/No button is a specific use case used for the yes_no CustomProperty type.
 *
 * @param {YesNoButtonProps} props
 * @returns
 */
export const YesNoButton = forwardRef(function YesNoButtonWithRef(
  { defaultValue, onChange, ...props }: YesNoButtonProps,
  ref: Ref<HTMLInputElement>,
): JSX.Element {
  const { t } = useTranslation();

  const options = [
    {
      value: 'true',
      label: t('yes_no_button.yes', {
        defaultValue: 'Yes',
        ns: 'design-system',
      }),
    },
    {
      value: 'false',
      label: t('yes_no_button.no', { defaultValue: 'No', ns: 'design-system' }),
    },
  ];

  const booleanDefaultValue =
    defaultValue === true || defaultValue === false
      ? `${defaultValue}`
      : undefined;

  const booleanOnChange = onChange
    ? (nextValue: string) => {
        onChange(nextValue === 'true');
      }
    : undefined;

  return (
    <RadioButton
      ref={ref}
      options={options}
      defaultValue={booleanDefaultValue}
      onChange={booleanOnChange}
      {...props}
    />
  );
});
