import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationTags = (): JSX.Element => {
  const { stroke, skeleton } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="0 0 508 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M433 1C433 41.8691 400.317 75 360 75L360 1L433 1Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C441.186 75 507 140.814 507 222L360 222L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M63 75C63 34.1309 95.6832 1 136 1L136 75L63 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M286 75C286 156.186 218.843 222 136 222L136 75L286 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C360 34.1309 326.869 0.999999 286 0.999997L286 75L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
      </g>
      <g opacity="0.7" filter="url(#filter0_ddd_1624_81844)">
        <rect x="188" y="40" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M205 51C205 50.4477 205.448 50 206 50H216C216.265 50 216.52 50.1054 216.707 50.2929L225.299 58.885C225.858 59.4471 226.172 60.2074 226.172 61C226.172 61.7926 225.858 62.5529 225.299 63.115L224.59 62.41L225.297 63.1171L218.128 70.2867C218.127 70.2868 218.128 70.2866 218.128 70.2867C217.849 70.5655 217.518 70.787 217.154 70.9379C216.79 71.0889 216.399 71.1666 216.005 71.1666C215.611 71.1666 215.22 71.0889 214.856 70.9379C214.492 70.787 214.161 70.5658 213.883 70.2869C213.883 70.2869 213.883 70.287 213.883 70.2869L205.293 61.7075C205.106 61.5199 205 61.2654 205 61V51ZM223.881 61.705C223.881 61.7047 223.881 61.7044 223.882 61.7041C224.067 61.5168 224.172 61.2638 224.172 61C224.172 60.7362 224.067 60.4832 223.882 60.2959C223.881 60.2956 223.881 60.2953 223.881 60.295L215.586 52H207V60.5854L215.297 68.8725L215.298 68.8733C215.39 68.9663 215.501 69.04 215.622 69.0903C215.743 69.1407 215.874 69.1666 216.005 69.1666C216.136 69.1666 216.267 69.1407 216.388 69.0903C216.509 69.04 216.62 68.9663 216.712 68.8733L223.881 61.705Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 56C210 55.4477 210.448 55 211 55H211.01C211.562 55 212.01 55.4477 212.01 56C212.01 56.5523 211.562 57 211.01 57H211C210.448 57 210 56.5523 210 56Z"
          fill={stroke}
        />
        <rect x="244" y="57" width="115" height="4" rx="2" fill={skeleton} />
      </g>
      <g opacity="0.7" filter="url(#filter1_ddd_1624_81844)">
        <rect x="188" y="90" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M205 101C205 100.448 205.448 100 206 100H216C216.265 100 216.52 100.105 216.707 100.293L225.299 108.885C225.858 109.447 226.172 110.207 226.172 111C226.172 111.793 225.858 112.553 225.299 113.115L224.59 112.41L225.297 113.117L218.128 120.287C218.127 120.287 218.128 120.287 218.128 120.287C217.849 120.565 217.518 120.787 217.154 120.938C216.79 121.089 216.399 121.167 216.005 121.167C215.611 121.167 215.22 121.089 214.856 120.938C214.492 120.787 214.161 120.566 213.883 120.287C213.883 120.287 213.883 120.287 213.883 120.287L205.293 111.708C205.106 111.52 205 111.265 205 111V101ZM223.881 111.705C223.881 111.705 223.881 111.704 223.882 111.704C224.067 111.517 224.172 111.264 224.172 111C224.172 110.736 224.067 110.483 223.882 110.296C223.881 110.296 223.881 110.295 223.881 110.295L215.586 102H207V110.585L215.297 118.872L215.298 118.873C215.39 118.966 215.501 119.04 215.622 119.09C215.743 119.141 215.874 119.167 216.005 119.167C216.136 119.167 216.267 119.141 216.388 119.09C216.509 119.04 216.62 118.966 216.712 118.873L223.881 111.705Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 106C210 105.448 210.448 105 211 105H211.01C211.562 105 212.01 105.448 212.01 106C212.01 106.552 211.562 107 211.01 107H211C210.448 107 210 106.552 210 106Z"
          fill={stroke}
        />
        <rect x="244" y="107" width="162" height="4" rx="2" fill={skeleton} />
      </g>
      <g opacity="0.7" filter="url(#filter2_ddd_1624_81844)">
        <rect x="188" y="140" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M205 151C205 150.448 205.448 150 206 150H216C216.265 150 216.52 150.105 216.707 150.293L225.299 158.885C225.858 159.447 226.172 160.207 226.172 161C226.172 161.793 225.858 162.553 225.299 163.115L224.59 162.41L225.297 163.117L218.128 170.287C218.127 170.287 218.128 170.287 218.128 170.287C217.849 170.565 217.518 170.787 217.154 170.938C216.79 171.089 216.399 171.167 216.005 171.167C215.611 171.167 215.22 171.089 214.856 170.938C214.492 170.787 214.161 170.566 213.883 170.287C213.883 170.287 213.883 170.287 213.883 170.287L205.293 161.708C205.106 161.52 205 161.265 205 161V151ZM223.881 161.705C223.881 161.705 223.881 161.704 223.882 161.704C224.067 161.517 224.172 161.264 224.172 161C224.172 160.736 224.067 160.483 223.882 160.296C223.881 160.296 223.881 160.295 223.881 160.295L215.586 152H207V160.585L215.297 168.872L215.298 168.873C215.39 168.966 215.501 169.04 215.622 169.09C215.743 169.141 215.874 169.167 216.005 169.167C216.136 169.167 216.267 169.141 216.388 169.09C216.509 169.04 216.62 168.966 216.712 168.873L223.881 161.705Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 156C210 155.448 210.448 155 211 155H211.01C211.562 155 212.01 155.448 212.01 156C212.01 156.552 211.562 157 211.01 157H211C210.448 157 210 156.552 210 156Z"
          fill={stroke}
        />
        <rect x="244" y="157" width="71" height="4" rx="2" fill={skeleton} />
      </g>
      <defs>
        <filter
          id="filter0_ddd_1624_81844"
          x="0.399979"
          y="-101.4"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_1624_81844"
          x="0.399979"
          y="-51.4"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_ddd_1624_81844"
          x="0.399979"
          y="-1.40002"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
