import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { GroupController } from '@dotfile/frontend/shared/components';
import { PhoneNumberInput } from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/data-access-client-portal';

import { FieldProps } from './types';

export const FieldPhoneNumber = ({
  field,
  property,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.phone_number>) => {
  const { control, setValue } = useFormContext();

  const handleChange = useCallback(
    (phoneNumber: string) => {
      setValue(
        groupControlProps.name,
        phoneNumber.replace('+', '') ? phoneNumber : null,
        {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        },
      );
    },
    [groupControlProps.name, setValue],
  );

  return (
    <GroupController
      control={control}
      render={(f) => (
        <PhoneNumberInput
          {...f}
          onChange={handleChange}
          value={(f.value ?? '').replace('+', '')}
        />
      )}
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
