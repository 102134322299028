import { useMemo } from 'react';

import { HStack, TextProps } from '@chakra-ui/react';

import {
  riskLevelDefinition,
  RiskLevelEnum,
  RiskLevelScaleEnum,
} from '@dotfile/shared/domain';

import { Box } from '../../layout/box/box';
import { Text } from '../../typography/text';

export type RiskLevelProps = {
  value?: RiskLevelEnum;
  color?: TextProps['color'];
  size?: 'sm' | 'md' | 'lg';
  withLabel?: boolean;
  levelScale: RiskLevelScaleEnum;
};

const sizeFactor: Record<NonNullable<RiskLevelProps['size']>, number> = {
  sm: 0.75,
  md: 1,
  lg: 2,
};

const bars = new Array(4).fill(0);

export const RiskLevel = ({
  value = RiskLevelEnum.not_defined,
  size = 'md',
  color: colorPropsOverride,
  withLabel = false,
  levelScale,
}: RiskLevelProps): JSX.Element => {
  const { label, colorScheme, level } =
    value in riskLevelDefinition
      ? riskLevelDefinition[value]
      : riskLevelDefinition.not_defined;

  const color =
    (colorPropsOverride ?? colorScheme(levelScale) === 'black')
      ? 'black'
      : `${colorScheme(levelScale)}.700`;
  const barColor =
    colorScheme(levelScale) === 'black'
      ? 'black'
      : `${colorScheme(levelScale)}.500`;

  const riskIcon = useMemo(() => {
    if (value === RiskLevelEnum.not_defined)
      return (
        <Text
          lineHeight={`${Math.min(sizeFactor[size] * 10, 24)}px`}
          fontSize={sizeFactor[size] * 14}
          color="gray.500"
          as="span"
        >
          —
        </Text>
      );

    return (
      <HStack align="baseline" spacing={`${sizeFactor[size] * 3}px`} as="span">
        {bars.map((bar, index) => {
          return (
            <Box
              as="span"
              key={`bar-${index}`}
              borderRadius={`${sizeFactor[size] * 3}px`}
              backgroundColor={!level || index < level ? barColor : 'gray.200'}
              width={`${sizeFactor[size] * 3}px`}
              height={`${sizeFactor[size] * (index * 3 + 5)}px`}
            />
          );
        })}
      </HStack>
    );
  }, [value, size, level, barColor]);

  if (withLabel) {
    return (
      <HStack
        width="full"
        as={Text}
        spacing={sizeFactor[size] * 2}
        align="baseline"
      >
        {riskIcon}
        <Text
          noOfLines={1}
          width="full"
          lineHeight="150%"
          fontSize={sizeFactor[size] * 14}
          color={color}
          as="span"
        >
          {label}
        </Text>
      </HStack>
    );
  } else {
    return <Text>{riskIcon}</Text>;
  }
};
