import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../../layout/box/box';
import { HStack } from '../../layout/stack/stack';
import { Text } from '../../typography/text';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
} from '../form-control/form-control';

export type GroupControlProps = Omit<FormControlProps, 'label'> & {
  error?: string | null;
  isRequired?: boolean;

  label?: ReactNode;
  labelFor?: string;
  labelPosition?: 'top' | 'right';
  labelProps?: Omit<
    FormLabelProps,
    // Currently noOfLines doesn't work with label because it hides the required indicator
    'noOfLines'
  >;

  helper?: React.ReactNode;
  helperPosition?: 'top' | 'bottom';
  helperNoOfLines?: number | null;
  indicator?: ReactNode;
  children?: ReactNode;
};

export const RequiredIndicatorText: FC = () => {
  const { t } = useTranslation();

  return (
    <Text as="span" fontSize="xs">
      &nbsp;(
      {t('group_control.required', {
        defaultValue: 'Required',
        ns: 'design-system',
      })}
      )
    </Text>
  );
};

const requiredIndicator = <RequiredIndicatorText />;

/**
 * The GroupControl component is a component that is used with React Hook Form to display Label, Helper, Error for a field form.
 */
export const _GroupControl: FC<GroupControlProps> = ({
  error,
  isRequired = false,
  label,
  labelFor,
  labelPosition = 'top',
  helper,
  helperPosition = 'top',
  helperNoOfLines = 1,
  indicator,
  children,
  labelProps = {},
  ...formControlProps
}) => {
  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!error}
      {...formControlProps}
      data-testid={labelFor}
    >
      {label && labelPosition === 'top' && (
        <FormLabel
          data-testid="top-label"
          htmlFor={labelFor}
          mb={helper && helperPosition === 'top' ? '0' : '2'}
          requiredIndicator={null} // Force to display the required indicator inside a common box to be able to align the label in the forms properly
          {...labelProps}
        >
          <Box as="span">
            {label}
            {indicator}
            {isRequired && requiredIndicator}
          </Box>
        </FormLabel>
      )}

      {helper && helperPosition === 'top' && (
        <FormHelperText
          mb="2"
          noOfLines={helperNoOfLines === null ? undefined : helperNoOfLines}
          title={typeof helper === 'string' ? helper : undefined}
          mt={label && labelPosition === 'right' ? '-1' : '1'}
        >
          {helper}
        </FormHelperText>
      )}

      {label && labelPosition === 'right' ? (
        <HStack>
          {children}
          <FormLabel
            htmlFor={labelFor}
            data-testid="right-label"
            mb="0"
            requiredIndicator={null} // Force to display the required indicator inside a common box to be able to align the label in the forms properly
            {...labelProps}
          >
            <Box as="span">
              {label}
              {indicator}
              {isRequired && requiredIndicator}
            </Box>
          </FormLabel>
        </HStack>
      ) : (
        children
      )}

      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}

      {helper && helperPosition === 'bottom' && (
        <FormHelperText
          noOfLines={helperNoOfLines === null ? undefined : helperNoOfLines}
          title={typeof helper === 'string' ? helper : undefined}
          mt="1"
        >
          {helper}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const GroupControl = memo(_GroupControl);
GroupControl.displayName = 'GroupControl';
