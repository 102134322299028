import { CheckCircleIcon, XCircleIcon } from 'lucide-react';
import { DeepPartial } from 'ts-essentials';

import { removeTypenameFromData } from '@dotfile/frontend/shared/common';
import { Badge, Icon, Spacer } from '@dotfile/frontend/shared/design-system';
import {
  CheckResultEnum,
  IdDocumentDataDetailedResultsModel,
  IdVerificationDataDetailedResultsModel,
} from '@dotfile/shared/domain';

import { DetailedResultsKey } from './types';

const detailedResultsTitle: Record<DetailedResultsKey, string> = {
  document: 'Identity document checks',
  face: 'Face checks',
};

/**
 * @TODO - E-5020 - Remove legacy detailed results and migrations
 * @deprecated
 */
export const DetailedResultsSummary = ({
  detailedResults,
}: {
  detailedResults: DeepPartial<
    IdDocumentDataDetailedResultsModel | IdVerificationDataDetailedResultsModel
  >;
}) => {
  return Object.entries(removeTypenameFromData(detailedResults)).map(
    ([key, resultRecord]) => {
      const title = detailedResultsTitle[key as DetailedResultsKey];

      const allNull = Object.values(resultRecord).every(
        (value) => value === null,
      );
      if (allNull) {
        return null;
      }

      const isAllApproved = Object.values(resultRecord).every(
        (value) =>
          // @NOTE: compromisedDocument is null most of the time, should not check for all approved but more none errored/rejected
          !(
            [
              CheckResultEnum.error,
              CheckResultEnum.rejected,
            ] as CheckResultEnum[]
          )
            // @TODO - E-2270 - Add @total-typescript/ts-reset
            // `as` should not be necessary anymore
            .includes(value as CheckResultEnum),
      );

      return (
        <Badge key={key} colorScheme={isAllApproved ? 'green' : 'red'}>
          <Icon as={isAllApproved ? CheckCircleIcon : XCircleIcon} />
          <Spacer w="2" />
          {title}
        </Badge>
      );
    },
  );
};
