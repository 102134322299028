import * as jsonLogic from 'json-logic-js';

// arrayContains, doesNotArrayContain
const arrayContains = (arr1: unknown, arr2: unknown) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (!arr1 || !arr2) return false;
  if (arr1 === arr2) return true;
  return arr1.some((item: unknown) => arr2.includes(item));
};
jsonLogic.add_operation('arrayContains', arrayContains);
jsonLogic.add_operation(
  'doesNotArrayContain',
  (arr1, arr2) => !arrayContains(arr1, arr2),
);

// arrayContainsAll, doesNotArrayContainAll
const arrayContainsAll = (arr1: unknown, arr2: unknown) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (!arr1 || !arr2) return false;
  if (arr1 === arr2) return true;
  return arr1.every((item: unknown) => arr2.includes(item));
};
jsonLogic.add_operation('arrayContainsAll', arrayContainsAll);
jsonLogic.add_operation(
  'doesNotArrayContainAll',
  (arr1, arr2) => !arrayContainsAll(arr1, arr2),
);

const arrayContainsExactly = (arr1: unknown, arr2: unknown) => {
  if (!arr1 || !arr2) return false;
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1 === arr2) return true;
  if (arr1.length !== arr2.length) return false;
  return arr1.every((item: unknown) => arr2.includes(item));
};
jsonLogic.add_operation('arrayContainsExactly', arrayContainsExactly);

const matchRegexp = (pattern: unknown, subject: unknown) => {
  if (!pattern || !subject) {
    return false;
  }

  if (typeof subject !== 'string' || typeof pattern !== 'string') {
    return false;
  }

  /**
   * @NOTE we allow using regexp with modifier such as `/test/i`
   * If the regexp include modifier, we reconstruct a RegExp object from `source` and `modifier`
   * Otherwise, use the source as pattern without modifier
   */
  const escapedRegexpParts = /\/(.*)\/(.*)/.exec(pattern);
  if (escapedRegexpParts) {
    const [, source, modifier] = escapedRegexpParts;

    return new RegExp(source, modifier).test(subject);
  }

  return new RegExp(pattern).test(subject);
};
jsonLogic.add_operation('matchRegexp', matchRegexp);
jsonLogic.add_operation(
  'doesNotMatchRegexp',
  (pattern, subject) => !matchRegexp(pattern, subject),
);

export default jsonLogic;
