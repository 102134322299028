import { useTranslation } from 'react-i18next';

import {
  Alert,
  AlertIcon,
  AlertTitle,
} from '@dotfile/frontend/shared/design-system';

/**
 * Similar to the component with the same name in id-verification/
 */
export const DataComparisonAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>
        {t('checks.id_document.update_reference_data.mismatch', {
          ns: 'client-portal',
          defaultValue:
            'Mismatch between the data you declared and the data from this document.',
        })}
      </AlertTitle>
    </Alert>
  );
};
