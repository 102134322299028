import { useCallback } from 'react';

import { useIdVerificationCheckQueryLazyQuery } from '@dotfile/shared/data-access-client-portal';

import { useTryCompleteChecks } from '../../shared';

export const useRefreshIdVerificationCheck = (checkId: string) => {
  const [runQuery, result] = useIdVerificationCheckQueryLazyQuery({
    variables: { id: checkId as string },
    fetchPolicy: 'network-only',
  });
  const [tryCompleteChecks] = useTryCompleteChecks();

  const refreshAndComplete = useCallback(async () => {
    const { data } = await runQuery();

    if (data?.idVerificationCheck) {
      await tryCompleteChecks(data?.idVerificationCheck);
    }
  }, [runQuery, tryCompleteChecks]);

  return [refreshAndComplete, result] as const;
};
