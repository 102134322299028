import { PenIcon, XCircle } from 'lucide-react';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Icon,
  RadioCard,
  Skeleton,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalForms_Step,
  CompanySearchInput,
} from '@dotfile/shared/data-access-client-portal';
import { yup } from '@dotfile/shared/domain';

import { FullContainer } from '../../../shared';
import {
  companySearchRefSelector,
  StepFooter,
  StepHeader,
  useFormDatastore,
} from '../shared';
import { CompanySearchCompanyOption } from './company-option';
import { useCompanyFetch, useCompanySearch } from './hooks';

export const CompanySearchStepSelectForm = ({
  companySearch,
  onSubmit,
  onPrevious,
}: {
  step: ClientPortalForms_Step;
  onSubmit: () => void;
  companySearch: CompanySearchInput;
  onPrevious: () => void;
}) => {
  const [fetchCompany] = useCompanyFetch();
  const {
    resetCompaniesAndIndividualsData,
    patchMainCompanyData,
    setSearchRef,
  } = useFormDatastore();
  const currentSearchRef = useFormDatastore(companySearchRefSelector);

  const refAlert = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const methods = useForm<{ searchRef: string | null }>({
    defaultValues: {
      searchRef: currentSearchRef ?? null,
    },
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(
      yup
        .object()
        .shape({
          searchRef: yup.string().required(),
        })
        .required(),
    ),
  });

  const {
    formState: { errors, isSubmitting },
    setValue,
    setFocus,
    handleSubmit,
  } = methods;

  const onFetchSubmit = handleSubmit(
    async ({ searchRef }) => {
      if (searchRef && currentSearchRef !== searchRef) {
        setSearchRef(searchRef);
        const searchData = data?.companySearch.data.find(
          (cs) => cs.searchRef === searchRef,
        );
        if (searchData) await fetchCompany(searchData);
      }

      onSubmit();
    },
    () => {
      // Focus alert on error
      setTimeout(() => {
        // Make sure to scroll after alert has been rendered
        refAlert.current?.scrollIntoView?.({
          behavior: 'smooth',
          block: 'end',
        });
      });
    },
  );

  const onManualInput = () => {
    setSearchRef(null);
    resetCompaniesAndIndividualsData();
    patchMainCompanyData({
      country: companySearch?.country,
      address: {
        country: companySearch?.country,
        streetAddress: '',
        streetAddress2: '',
        city: '',
        postalCode: '',
        state: '',
      },
      name: companySearch?.name ?? '',
      registrationNumber: companySearch?.registrationNumber ?? '',
    });

    onSubmit();
  };

  const { data, loading } = useCompanySearch(companySearch, {
    onNoVendorSupported: onManualInput,
    onCompleted: (data) => {
      if (data.companySearch.data.length) {
        setValue('searchRef', data.companySearch.data[0].searchRef, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setFocus('searchRef');
      }
    },
  });

  const options = (data?.companySearch.data ?? []).map(
    ({ searchRef, ...companySearch }) => ({
      value: searchRef,
      element: <CompanySearchCompanyOption {...companySearch} />,
    }),
  );

  return (
    <FullContainer
      as="form"
      noValidate
      onSubmit={onFetchSubmit}
      footer={
        <StepFooter
          onPrevious={onPrevious}
          isNextLoading={isSubmitting}
          // No save for later on company_search
        />
      }
    >
      <StepHeader />

      {errors.searchRef && (
        <Alert status="error" ref={refAlert}>
          <HStack>
            <AlertIcon />
            <VStack gap={0} align="stretch" width="full">
              <AlertTitle>
                {t('forms.missing_information', {
                  ns: 'client-portal',
                  defaultValue: 'Missing information',
                })}
              </AlertTitle>
              <AlertDescription>
                {t('forms.company_search.no_selection', {
                  defaultValue:
                    'Please select a company or fill information manually',
                  ns: 'client-portal',
                })}
              </AlertDescription>
            </VStack>
          </HStack>
        </Alert>
      )}
      <FormProvider {...methods}>
        {loading ? (
          <VStack>
            <Skeleton
              height="82px"
              width="full"
              border="1px solid"
              borderColor="coal.50"
              borderRadius="md"
            />
          </VStack>
        ) : (
          options.length > 0 && (
            <GroupController
              name="searchRef"
              isRequired
              control={methods.control}
              error={null}
              render={({ value, ...field }) => (
                <RadioCard
                  {...field}
                  direction="column"
                  value={(value as string) || undefined}
                  options={options}
                />
              )}
            />
          )
        )}

        <VStack align="start">
          {loading || options.length ? (
            <Text>
              {t('forms.company_search.you_cannot_find_your_company', {
                ns: 'client-portal',
                defaultValue: 'You cannot find your company ?',
              })}
            </Text>
          ) : (
            <HStack>
              <Icon as={XCircle} color="red" />
              <Text color="black">
                {t(
                  companySearch.registrationNumber
                    ? 'forms.company_search.no_result_registration_number'
                    : 'forms.company_search.no_result_name',
                  {
                    defaultValue: `We didn’t find any results for this ${
                      companySearch.registrationNumber
                        ? 'registration number'
                        : 'company name'
                    }`,
                    ns: 'client-portal',
                  },
                )}
              </Text>
            </HStack>
          )}
          <Button
            width="auto"
            variant="outline"
            onClick={onManualInput}
            leftIcon={<Icon as={PenIcon} />}
          >
            {t('forms.company_search.fill_details_manually', {
              ns: 'client-portal',
              defaultValue: 'Fill details manually',
            })}
          </Button>
        </VStack>
      </FormProvider>
    </FullContainer>
  );
};
