import { AtSign, LucideIcon } from 'lucide-react';
import { AnySchema } from 'yup';

import { CustomPropertyTypeEnum } from '../../../../shared/models';
import { CUSTOM_PROPERTY_EMAIL_MAX_LENGTH } from '../../../../shared/properties';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import { CustomPropertyValue } from './type';

export class EmailCustomPropertyDefinition extends BaseCustomPropertyDefinition {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.email;
  }

  get label(): string {
    return 'Email';
  }

  get icon(): LucideIcon {
    return AtSign;
  }

  // Settings
  // --------

  // no settings for now

  // Value
  // -----

  /**
   * Maximum length for the value
   */
  // @note specific to email
  get valueMaxLength(): number {
    return CUSTOM_PROPERTY_EMAIL_MAX_LENGTH;
  }

  override get defaultValue(): CustomPropertyValue | null {
    return '';
  }

  public valueSchema(): AnySchema {
    const schema = yup
      .string()
      .max(this.valueMaxLength)
      .email()
      .nullable()
      .strict();

    return schema;
  }
}

export const emailCustomPropertyDefinition =
  new EmailCustomPropertyDefinition();
