import { ComponentProps, ElementType, ReactNode } from 'react';

import {
  Flex,
  FlexProps,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { CONTENT_MAX_WIDTH } from './constants';
import { Footer } from './footer';

type FullContainerProps<ComponentAs extends ElementType = 'div'> = {
  footer?: ReactNode;
  centerContent?: boolean;
  as?: ComponentAs;
} & Omit<FlexProps, 'as'> &
  Omit<ComponentProps<ComponentAs>, 'direction' | 'height' | 'width'>;

export const FullContainer = <ComponentAs extends ElementType = 'div'>({
  children,
  footer,
  centerContent,
  ...props
}: FullContainerProps<ComponentAs>) => {
  const isSmallScreen = useIsSmallScreen();
  const spacing = isSmallScreen ? '3' : '6';

  return (
    <Flex direction="column" height="full" width="full" {...props}>
      <VStack
        flex="1"
        maxWidth={CONTENT_MAX_WIDTH}
        width="full"
        marginX="auto"
        alignItems={centerContent ? 'center' : 'stretch'}
        justifyContent={centerContent ? 'center' : 'start'}
        spacing={spacing}
        pb={footer ? spacing : undefined}
      >
        {children}
      </VStack>

      <Footer>{footer}</Footer>
    </Flex>
  );
};
