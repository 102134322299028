import { debounce, DebouncedFunc, DebounceSettings } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

/**
 * Create a useDebounce hook with custom debounce settings.
 *
 * Since the settings are defined outside of the React render loop,
 * they are not in the useMemo dependencies and the memoized function
 * is never invalidated
 *
 * See https://lodash.com/docs/4.17.15#debounce
 * @param wait
 * @param settings
 */
export function createUseDebounce<Args extends unknown[]>(
  wait: number,
  settings?: DebounceSettings & { flushOnUnmount?: boolean },
): (fn: (...args: Args) => void) => DebouncedFunc<(...args: Args) => void> {
  /**
   * Return a debounce function of `fn` by the configured `wait` time
   *
   * See https://lodash.com/docs/4.17.15#debounce
   * @param fn
   */
  function useDebounce(fn: (...args: Args) => void) {
    const fnRef = useRef<(...args: Args) => void>();
    // store latest function in ref
    fnRef.current = fn;

    const debouncedFn = useMemo(
      () =>
        debounce(
          (...args) =>
            // Invoke latest function from ref, without invalidating memo
            fnRef.current?.(...(args as Args)),
          wait,
          settings,
        ),
      [],
    );

    // Stop the invocation of the debounced function after unmounting
    useEffect(() => {
      return () => {
        if (settings?.flushOnUnmount) {
          debouncedFn.flush();
        } else {
          debouncedFn.cancel();
        }
      };
    }, [debouncedFn]);

    return debouncedFn;
  }

  return useDebounce;
}
