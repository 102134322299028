import { useState } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from 'reactflow';

import { Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { colors } from '../../../theme/colors';
import { BaseData } from '../helpers';

const EdgeStyle = styled.g`
  :hover path.react-flow__edge-path {
    stroke: ${colors.black} !important;
  }
`;

// Necessary for the generic
// eslint-disable-next-line react/function-component-definition
export function CustomEdge<TEdgeData>({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  selected,
  data,
  ...props
}: EdgeProps<BaseData<TEdgeData>>): JSX.Element {
  // Hovering system on the EdgeLabelRenderer to set the style on the edge path
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => setIsHovering(true);
  const handleMouseOut = () => setIsHovering(false);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <EdgeStyle>
      <BaseEdge
        id={id}
        path={edgePath}
        // @see https://github.com/xyflow/xyflow/issues/3149#issuecomment-1849386843
        markerStart="url(#1__type=arrowclosed)"
        style={{
          pointerEvents: 'all',
          opacity: data?.readOnly && !data?.selected ? 0.6 : 1,
          stroke:
            isHovering || data?.selected ? colors.black : colors.gray[500],
          strokeWidth: data?.readOnly && data?.selected ? 3 : 1,
          strokeDasharray: data?.readOnly && data?.selected ? 8 : 0,
        }}
        animated
        {...props}
      />
      <EdgeLabelRenderer>
        <Box
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: data?.readOnly ? 'none' : 'all',
          }}
          cursor="pointer"
          _hover={
            !data?.readOnly
              ? {
                  borderColor: colors.black,
                }
              : {}
          }
          className="nodrag nopan"
          h="24px"
          bg="white"
          border="1px solid"
          borderColor="gray.50"
          borderRadius="md"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <Box
            opacity={data?.readOnly && !data?.selected ? 0.6 : 1}
            borderRadius="inherit"
            px="2"
          >
            {data?.renderComponent ? (
              data?.renderComponent(id, data.props)
            ) : (
              <Text>Popover not implemented</Text>
            )}
          </Box>
        </Box>
      </EdgeLabelRenderer>
    </EdgeStyle>
  );
}
