import { PropertyMappingEntityEnum, PropertyMappingTypeEnum } from '../models';

/**
 * PropertyType
 * Compatible with CustomPropertyTypeEnum
 */
export const PropertyTypeEnum = {
  text: 'text',
  numeric: 'numeric',
  boolean: 'boolean',
  date: 'date',
  choices: 'choices',
  countries: 'countries',
  email: 'email',
  phone_number: 'phone_number',
  url: 'url',
  address: 'address',
  banking_information: 'banking_information',
  classifications: 'classifications',
  entity_legal_form: 'entity_legal_form',
  unknown: 'unknown',
} as const;
export type PropertyTypeEnum =
  (typeof PropertyTypeEnum)[keyof typeof PropertyTypeEnum];

export interface PropertyType {
  /**
   * Unique id of the property type
   */
  id: PropertyTypeEnum;

  /**
   * Name of the property (en)
   */
  name: string;

  /**
   * Lucide icon component string
   * @see https://lucide.dev/icons/
   */
  icon: string;
}

export interface PropertyMapping {
  /**
   * Default or custom property
   * Default property are defined in definitions
   */
  type: PropertyMappingTypeEnum;
  /**
   * Target entity: case, company or individual
   */
  entity: PropertyMappingEntityEnum;
  /**
   * Unique key of the property on the target entity
   */
  key: string;

  /**
   * Boolean indicating if it represents a nested property
   */
  nested?: boolean;
}

// Property setting
// ----------------------------------------------------------
// @NOTE Compatible with CustomPropertySettingsModel
export type PropertyChoicesSettings = {
  allowMultiple: boolean;
  /**
   * Available options for some types
   */
  options?: Array<{
    key: string;
    label: string;
  }>;
};

export type PropertyCountriesSettings = {
  allowMultiple: boolean;
};

export type PropertyEmailMaxLength = {
  // @NOTE Not present on CustomPropertySettingsModel since email doesn't have settings
  maxLength?: number;
};

export type PropertyTextSettings = {
  isMultiLine: boolean;

  // @NOTE Not present on CustomPropertySettingsModel since it is inferred from isMultiLine there
  maxLength?: number;
};

export const PropertyNumericFormatEnum = {
  number: 'number',
  percent: 'percent',
  currency: 'currency',
} as const;
export type PropertyNumericFormatEnum =
  (typeof PropertyNumericFormatEnum)[keyof typeof PropertyNumericFormatEnum];

export type PropertyNumericSettings = {
  format: PropertyNumericFormatEnum;
  min?: number | null;
  max?: number | null;
  /**
   * In format ISO 4217
   * @see https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes
   */
  currencyCode?: string | null;
};

export type PropertyUrlMaxLength = {
  // @NOTE Not present on CustomPropertySettingsModel since url doesn't have settings
  maxLength?: number;
};

export type PropertySettingsModel<T extends PropertyTypeEnum> =
  T extends typeof PropertyTypeEnum.choices
    ? PropertyChoicesSettings
    : T extends typeof PropertyTypeEnum.countries
      ? PropertyCountriesSettings
      : T extends typeof PropertyTypeEnum.text
        ? PropertyTextSettings
        : T extends typeof PropertyTypeEnum.numeric
          ? PropertyNumericSettings
          : T extends typeof PropertyTypeEnum.email
            ? PropertyEmailMaxLength
            : T extends typeof PropertyTypeEnum.url
              ? PropertyUrlMaxLength
              : never;

// Property
// ----------------------------------------------------------
export interface Property<T extends PropertyTypeEnum> {
  /**
   * Property type
   * Can get more information to the property type (name, icon) from the definition
   */
  type: T;

  /**
   * Target entity type: case, company, individual
   */
  mapping: PropertyMapping;

  /**
   * Default label of the property in English
   */
  label: string;

  /**
   * Is the property should always be required.
   * Necessary for default, required properties of our entities (company.country for example)
   */
  alwaysRequired: boolean;

  /**
   * Optional setting for some PropertyType
   */
  settings?: PropertySettingsModel<T>;

  // @LATER isEditable for property such as updatedAt

  /**
   * Archived date for property coming from custom properties
   */
  archivedAt?: string | null;
}
