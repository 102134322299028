import { useToken } from '../../../utils/hooks/chakra-hooks';

export const useIllustrationTokens = (): {
  stroke: string;
  background: string;
  skeleton: string;
  approved: string;
  rejected: string;
  task1: string;
  task2: string;
  gray200: string;
  blue500: string;
  blue700: string;
} => {
  const [
    stroke,
    background,
    skeleton,
    approved,
    rejected,
    task1,
    task2,
    gray200,
    blue500,
    blue700,
  ] = useToken('colors', [
    'blue.500',
    'blue.100',
    'gray.50',
    'green.100',
    'red.100',
    'orange.200',
    'yellow.200',
    'gray.100',
    'blue.500',
    'blue.700',
  ]);
  return {
    stroke,
    background,
    skeleton,
    approved,
    rejected,
    task1,
    task2,
    gray200,
    blue500,
    blue700,
  };
};
