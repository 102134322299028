import { useTranslation } from 'react-i18next';

import { Box, Text, VStack } from '@dotfile/frontend/shared/design-system';

import { useCurrentStep } from '../../../context';
import { TransBlockDescription } from '../shared';
import { LayoutProps } from './types';

export const LayoutPdf = ({ layout }: LayoutProps) => {
  const { t } = useTranslation();
  const step = useCurrentStep();

  const pdfHref = layout.file?.publicUrl;

  return (
    <VStack spacing="2" align="start">
      <Text color="black" fontSize="14px" fontWeight="bold">
        {t(`steps.${step.key}.blocks.${layout.key}.label`, {
          defaultValue: layout.label || 'PDF document',
          ns: 'dynamic',
        })}
      </Text>

      <TransBlockDescription block={layout} />

      {pdfHref && (
        <Box
          mt="2"
          display="flex"
          w="100%"
          height="60vh"
          justifyContent="center"
          alignItems="center"
        >
          <embed
            title={layout.key}
            src={`${pdfHref}#navpanes=0&view=fitH`}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </Box>
      )}
    </VStack>
  );
};
