import slugify from 'slugify';

const lowerFollowedByUpperRegex = /([a-z])([A-Z])/g;

// slugify replace some character with a word eg `♥` -> `love`
const removeSpecialCharacterRegex = /[%$£¤€&<>|º¥®©¢♥]/g;

/**
 * Slugify text input (label or title or name) for key (which respects our standard human key constraints)
 * @param input
 * @param maxLength
 * @returns
 */
export const slugifyForKey = (input: string, maxLength = 100): string => {
  const lowerFollowedByUpperUnderscoreAdded = input.replaceAll(
    lowerFollowedByUpperRegex,
    (match, p1, p2) => `${p1}_${p2}`,
  );

  const specialCharacterRemoved =
    lowerFollowedByUpperUnderscoreAdded.replaceAll(
      removeSpecialCharacterRegex,
      '',
    );

  const slugged = slugify(specialCharacterRemoved, {
    lower: true,
    replacement: '_',
    trim: true,
    strict: true,
  });

  return slugged.slice(0, maxLength);
};
