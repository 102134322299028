import { Fragment } from 'react';
import { match } from 'ts-pattern';

import { ClientPortalStepTypeEnum } from '@dotfile/shared/data-access-client-portal';

import { AffiliatedCompaniesEditStep } from '../../affiliated-companies-edit-step';
import { BusinessContactStep } from '../../business-contact-step';
import { CompanyEditStep } from '../../company-edit-step';
import { CompanySearchStep } from '../../company-search-step';
import { CustomStep } from '../../custom-step';
import { IndividualEditStep } from '../../individual-edit-step';
import { IndividualsEditStep } from '../../individuals-edit-step';
import { CurrentStepContextProvider, StepProps } from '../../shared';

export const Step = (props: StepProps) => {
  return (
    <CurrentStepContextProvider step={props.step}>
      <Fragment
        // Force remount when step change
        key={props.step.id}
      >
        {match(props.step.type)
          .with(ClientPortalStepTypeEnum.individual_edit, () => (
            <IndividualEditStep {...props} />
          ))
          .with(ClientPortalStepTypeEnum.company_search, () => (
            <CompanySearchStep {...props} />
          ))
          .with(ClientPortalStepTypeEnum.company_edit, () => (
            <CompanyEditStep {...props} />
          ))
          .with(ClientPortalStepTypeEnum.affiliated_companies_edit, () => (
            <AffiliatedCompaniesEditStep {...props} />
          ))
          .with(ClientPortalStepTypeEnum.individuals_edit, () => (
            <IndividualsEditStep {...props} />
          ))
          .with(ClientPortalStepTypeEnum.business_contact, () => (
            <BusinessContactStep {...props} />
          ))
          .with(ClientPortalStepTypeEnum.custom, () => (
            <CustomStep {...props} />
          ))
          .exhaustive()}
      </Fragment>
    </CurrentStepContextProvider>
  );
};
