import {
  ChakraStylesConfig,
  components,
  GroupBase,
  SingleValueProps,
  StylesConfig,
} from 'chakra-react-select';
import { ComponentProps, useCallback, useMemo } from 'react';
import { FieldSelectorProps as RqbFieldSelectorProps } from 'react-querybuilder';
import { FullOption } from 'react-querybuilder';

import { humanCase } from '@dotfile/shared/common';

import { Text } from '../../typography/text';
import { GenericSelect } from '../select';
import { TypedQueryBuilderField } from './type';

type FieldSelectorProps = Pick<
  RqbFieldSelectorProps<TypedQueryBuilderField>,
  'options' | 'handleOnChange' | 'value'
> & { width?: string };

const isOptionGroup = (
  option: TypedQueryBuilderField | GroupBase<TypedQueryBuilderField>,
): option is GroupBase<TypedQueryBuilderField> => 'options' in option;

/**
 * Override the SingleValue component to allow to display a prefix on the selected value
 * @see https://react-select.com/components
 */
const SingleValue = ({
  ...props
}: SingleValueProps<TypedQueryBuilderField>) => {
  const value = props.getValue()[0];

  return (
    <components.SingleValue {...props}>
      {value.selectedPrefix && (
        <>
          <Text as="span" color="gray.500">
            {humanCase(value.selectedPrefix)}
          </Text>{' '}
        </>
      )}

      {value.label}
    </components.SingleValue>
  );
};
const overrideComponents = { SingleValue };

export const FieldSelector = ({
  options,
  handleOnChange,
  value,
  width,
}: FieldSelectorProps): JSX.Element => {
  const resolvedValue = useMemo(() => {
    if (!value) {
      return null;
    }

    const flatOptions = options.flatMap((o) =>
      isOptionGroup(o) ? o.options : o,
    );

    if (Array.isArray(value)) {
      return flatOptions.filter((o) => value.includes(o.value));
    }

    return flatOptions.find((o) => value === o.value) ?? null;
  }, [value, options]);

  const onChange = useCallback(
    (
      newValue: Parameters<
        Required<ComponentProps<typeof GenericSelect>>['onChange']
      >[0],
    ) => {
      if (!newValue) {
        handleOnChange(null);
      } else {
        const newSingleValue: FullOption = Array.isArray(newValue)
          ? newValue[0]
          : newValue;
        handleOnChange(newSingleValue.value);
      }
    },
    [handleOnChange],
  );

  const chakraStyles: ChakraStylesConfig<TypedQueryBuilderField> = useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        width,
      }),
    }),
    [width],
  );

  const styles = useMemo(
    (): StylesConfig<TypedQueryBuilderField> => ({
      menuPortal: () => ({
        // Work-around positioning of menu inside popover like for view table filters
        // @warn this break if the select was inside an accordion like for template forms
        // @see https://github.com/JedWatson/react-select/issues/4680#issuecomment-883375414
        position: 'relative',
        top: '0px',
      }),
    }),
    [],
  );

  return (
    <GenericSelect
      options={options}
      onChange={onChange}
      value={resolvedValue}
      chakraStyles={chakraStyles}
      styles={styles}
      isSearchable
      components={overrideComponents}
    />
  );
};
