import { match } from 'ts-pattern';

import { ClientPortal_Case } from '@dotfile/shared/data-access-client-portal';
import { CLIENT_PORTAL_REDIRECT_URL_VARIABLES } from '@dotfile/shared/domain';

export const useInterpolateRedirectUrl = (
  redirectUrl: string | null | undefined,
  caseQ: Pick<ClientPortal_Case, 'id' | 'externalId'> | null | undefined,
): string | null => {
  if (!redirectUrl || !caseQ) {
    return null;
  }

  let interpolatedRedirectUrl = redirectUrl;

  for (const variable of CLIENT_PORTAL_REDIRECT_URL_VARIABLES) {
    const value = match(variable)
      .with('{caseId}', () => caseQ.id)
      .with('{externalId}', () => caseQ.externalId ?? '')
      .exhaustive();
    interpolatedRedirectUrl = interpolatedRedirectUrl.replaceAll(
      variable,
      value,
    );
  }

  return interpolatedRedirectUrl;
};
