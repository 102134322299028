import { CompanyTypeEnum } from '../../../shared/models';

export const companyTypeDefinition: Record<CompanyTypeEnum, { label: string }> =
  {
    [CompanyTypeEnum.main]: {
      label: 'Main',
    },
    [CompanyTypeEnum.affiliated]: {
      label: 'Affiliated',
    },
  };
