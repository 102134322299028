import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  AddFilesDocumentCheckInput,
  useAddFilesDocumentCheckMutation,
} from '@dotfile/shared/data-access-client-portal';
import { CheckStatusEnum } from '@dotfile/shared/domain';

import { useTryCompleteChecks } from '../../shared';

export const useAddFiles = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const [runMutation, result] = useAddFilesDocumentCheckMutation();

  const [tryCompleteChecks] = useTryCompleteChecks();

  const addFile = useCallback(
    async (
      input: AddFilesDocumentCheckInput,
    ): Promise<CheckStatusEnum | null> => {
      try {
        const { data } = await runMutation({
          variables: {
            input,
          },
        });

        await tryCompleteChecks(data?.addFilesDocumentCheck);

        return data?.addFilesDocumentCheck.status ?? null;
      } catch (error) {
        handleError({
          title: t('checks.document.add_files.error', {
            defaultValue: 'Unable to add the file',
            count: input.data.files.length,
            ns: 'client-portal',
          }),
          error,
        });
        return null;
      }
    },
    [runMutation, handleError, t, tryCompleteChecks],
  );

  return [addFile, result] as const;
};
