import { merge } from 'lodash';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DeepPartial, StrictDeepOmit } from 'ts-essentials';

import { nonceId } from '@dotfile/frontend/shared/common';
import {
  baseTheme,
  DesignSystemProvider,
  extendTheme,
  Theme,
} from '@dotfile/frontend/shared/design-system';

import { customExtension } from './client-portal-extension';

type ThemeWithPrimary = StrictDeepOmit<
  DeepPartial<Theme>,
  {
    colors: {
      custom: never;
    };
  }
> & { colors: { custom: string } };

const ThemeContext = createContext<{
  theme: DeepPartial<Theme>;
  setTheme: (theme: ThemeWithPrimary) => void;
}>({ theme: baseTheme, setTheme: () => null });

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeDesignSystemProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState(baseTheme);

  const setTheme = useCallback((theme: ThemeWithPrimary) => {
    setState((t) =>
      extendTheme(merge({}, t, customExtension(theme.colors.custom))),
    );
  }, []);

  const value = useMemo(() => ({ theme: state, setTheme }), [state, setTheme]);

  return (
    <ThemeContext.Provider value={value}>
      <DesignSystemProvider nonce={nonceId} theme={state as Theme}>
        {children}
      </DesignSystemProvider>
    </ThemeContext.Provider>
  );
};
