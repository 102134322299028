import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  FormModal,
  HStack,
  Input,
  ModalProps,
  Text,
  useIsMobile,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { useFormDatastore } from '../../context';
import { useSaveForLater } from './hooks';
import {
  IndividualSelectGroupController,
  NEW_INDIVIDUAL_INDEX,
  NO_INDIVIDUAL_INDEX,
} from './individual-select';
import { SaveForLaterAddBusinessContactFormValues } from './type';

type SaveForLaterAddBusinessContactModalProps = Pick<
  ModalProps,
  'isOpen' | 'onClose'
> & { onSaved: () => void };

export const SaveForLaterAddBusinessContactModal = ({
  isOpen,
  onClose,
  onSaved,
}: SaveForLaterAddBusinessContactModalProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const hasExistingIndividuals = useFormDatastore(
    (state) => state.data.individuals && state.data.individuals?.length > 0,
  );
  const defaultValues = useMemo(
    () => ({
      _individualIndex: hasExistingIndividuals
        ? NO_INDIVIDUAL_INDEX
        : NEW_INDIVIDUAL_INDEX,
      _showEmail: !hasExistingIndividuals,

      firstName: '',
      lastName: '',
      email: '',
    }),
    [hasExistingIndividuals],
  );

  const methods = useForm<SaveForLaterAddBusinessContactFormValues>({
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });
  const { handleSubmit, control, watch, setFocus, reset } = methods;

  const previousIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (isOpen === true && previousIsOpen === false) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, previousIsOpen, reset]);

  const individualIndex = watch('_individualIndex');
  const showEmail = watch('_showEmail');

  const saveForLater = useSaveForLater();
  const { addIndividualData, patchIndividualData } = useFormDatastore();
  const submit = handleSubmit(
    async ({ _individualIndex, _showEmail, ...individualValues }) => {
      if (individualIndex === NEW_INDIVIDUAL_INDEX) {
        addIndividualData({ ...individualValues, isBusinessContact: true });
      } else {
        patchIndividualData(
          { ...individualValues, isBusinessContact: true },
          individualIndex,
        );
      }

      const saved = await saveForLater.run();
      if (saved) {
        onClose();
        onSaved();
      }
    },
  );

  useEffect(() => {
    if (individualIndex !== NEW_INDIVIDUAL_INDEX && showEmail) {
      setFocus('email');
    }
  }, [individualIndex, setFocus, showEmail]);

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      variant="dialog-white"
      header={t('forms.save_for_later.add_business_contact.header', {
        defaultValue: 'Save for later',
        ns: 'client-portal',
      })}
      size={isMobile ? 'full' : 'xl'}
      onCloseComplete={reset}
      onSubmit={submit}
      cancelText={t('common.close', {
        defaultValue: 'Close',
        ns: 'client-portal',
      })}
      submitText={t('forms.save_for_later.label', {
        defaultValue: 'Save for later',
        ns: 'client-portal',
      })}
      submitIsLoading={saveForLater.loading}
      hasCloseButton={false}
      bodyProps={{ position: 'relative', textAlign: 'start' }}
    >
      <VStack alignItems="stretch" as="form">
        <FormProvider {...methods}>
          <Text color="black" fontWeight="bold">
            {t('forms.save_for_later.add_business_contact.label', {
              defaultValue: 'Business contact',
              ns: 'client-portal',
            })}
          </Text>
          <Text>
            {t('forms.save_for_later.add_business_contact.helper', {
              defaultValue:
                'Add an individual as business contact being able to save for later',
              ns: 'client-portal',
            })}
          </Text>

          {individualIndex !== NEW_INDIVIDUAL_INDEX ? (
            <IndividualSelectGroupController />
          ) : (
            <HStack>
              <GroupController
                name="firstName"
                label={t(
                  'forms.save_for_later.add_business_contact.first_name.label',
                  {
                    defaultValue: 'First name',
                    ns: 'client-portal',
                  },
                )}
                isRequired
                control={control}
                render={(field) => <Input type="text" {...field} />}
              />

              <GroupController
                name="lastName"
                label={t(
                  'forms.save_for_later.add_business_contact.last_name.label',
                  {
                    defaultValue: 'Last name',
                    ns: 'client-portal',
                  },
                )}
                isRequired
                control={control}
                render={(field) => <Input type="text" {...field} />}
              />
            </HStack>
          )}

          {showEmail && (
            <GroupController
              name="email"
              label={t(
                'forms.save_for_later.add_business_contact.email.label',
                {
                  defaultValue: 'Email',
                  ns: 'client-portal',
                },
              )}
              isRequired
              control={control}
              render={(field) => <Input type="email" {...field} />}
            />
          )}
        </FormProvider>
      </VStack>
    </FormModal>
  );
};
