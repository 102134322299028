import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationDocuments = (): JSX.Element => {
  const { stroke, skeleton } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="0 0 508 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M433 1C433 41.8691 400.317 75 360 75L360 1L433 1Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C441.186 75 507 140.814 507 222L360 222L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M63 75C63 34.1309 95.6832 1 136 1L136 75L63 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M286 75C286 156.186 218.843 222 136 222L136 75L286 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C360 34.1309 326.869 0.999999 286 0.999997L286 75L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
      </g>
      <g opacity="0.7" filter="url(#filter0_ddd_1624_81844)">
        <rect x="188" y="40" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M207.879 50.8787C208.441 50.3161 209.204 50 210 50H218.5C218.765 50 219.02 50.1054 219.207 50.2929L224.707 55.7929C224.895 55.9804 225 56.2348 225 56.5V69C225 69.7957 224.684 70.5587 224.121 71.1213C223.559 71.6839 222.796 72 222 72H210C209.204 72 208.441 71.6839 207.879 71.1213C207.316 70.5587 207 69.7957 207 69V53C207 52.2044 207.316 51.4413 207.879 50.8787ZM210 52C209.735 52 209.48 52.1054 209.293 52.2929C209.105 52.4804 209 52.7348 209 53V69C209 69.2652 209.105 69.5196 209.293 69.7071C209.48 69.8946 209.735 70 210 70H222C222.265 70 222.52 69.8946 222.707 69.7071C222.895 69.5196 223 69.2652 223 69V56.9142L218.086 52H210Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M218 50C218.552 50 219 50.4477 219 51V56H224C224.552 56 225 56.4477 225 57C225 57.5523 224.552 58 224 58H218C217.448 58 217 57.5523 217 57V51C217 50.4477 217.448 50 218 50Z"
          fill={stroke}
        />
        <rect x="244" y="57" width="115" height="4" rx="2" fill={skeleton} />
      </g>
      <g opacity="0.7" filter="url(#filter1_ddd_1624_81844)">
        <rect x="188" y="90" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M207.879 100.879C208.441 100.316 209.204 100 210 100H218.5C218.765 100 219.02 100.105 219.207 100.293L224.707 105.793C224.895 105.98 225 106.235 225 106.5V119C225 119.796 224.684 120.559 224.121 121.121C223.559 121.684 222.796 122 222 122H210C209.204 122 208.441 121.684 207.879 121.121C207.316 120.559 207 119.796 207 119V103C207 102.204 207.316 101.441 207.879 100.879ZM210 102C209.735 102 209.48 102.105 209.293 102.293C209.105 102.48 209 102.735 209 103V119C209 119.265 209.105 119.52 209.293 119.707C209.48 119.895 209.735 120 210 120H222C222.265 120 222.52 119.895 222.707 119.707C222.895 119.52 223 119.265 223 119V106.914L218.086 102H210Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M218 100C218.552 100 219 100.448 219 101V106H224C224.552 106 225 106.448 225 107C225 107.552 224.552 108 224 108H218C217.448 108 217 107.552 217 107V101C217 100.448 217.448 100 218 100Z"
          fill={stroke}
        />
        <rect x="244" y="107" width="115" height="4" rx="2" fill={skeleton} />
      </g>
      <g opacity="0.7" filter="url(#filter2_ddd_1624_81844)">
        <rect x="188" y="140" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M207.879 150.879C208.441 150.316 209.204 150 210 150H218.5C218.765 150 219.02 150.105 219.207 150.293L224.707 155.793C224.895 155.98 225 156.235 225 156.5V169C225 169.796 224.684 170.559 224.121 171.121C223.559 171.684 222.796 172 222 172H210C209.204 172 208.441 171.684 207.879 171.121C207.316 170.559 207 169.796 207 169V153C207 152.204 207.316 151.441 207.879 150.879ZM210 152C209.735 152 209.48 152.105 209.293 152.293C209.105 152.48 209 152.735 209 153V169C209 169.265 209.105 169.52 209.293 169.707C209.48 169.895 209.735 170 210 170H222C222.265 170 222.52 169.895 222.707 169.707C222.895 169.52 223 169.265 223 169V156.914L218.086 152H210Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M218 150C218.552 150 219 150.448 219 151V156H224C224.552 156 225 156.448 225 157C225 157.552 224.552 158 224 158H218C217.448 158 217 157.552 217 157V151C217 150.448 217.448 150 218 150Z"
          fill={stroke}
        />
        <rect x="244" y="157" width="115" height="4" rx="2" fill={skeleton} />
      </g>
      <defs>
        <filter
          id="filter0_ddd_1624_81844"
          x="0.399979"
          y="-101.4"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_1624_81844"
          x="0.399979"
          y="-51.4"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_ddd_1624_81844"
          x="0.399979"
          y="-1.40002"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
