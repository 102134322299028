import { useAuth } from '@dotfile/frontend/shared/common';
import { useDisclosure } from '@dotfile/frontend/shared/design-system';

import { ContactAuthContext } from '../../../../../shared';
import { businessContactSelector, useFormDatastoreApi } from '../../context';
import { canSaveForLater } from '../../helpers';
import { useSaveForLater } from './hooks';

export type SaveForLaterState = {
  isLoading: boolean;
  can: boolean;
  modals: {
    confirm: ReturnType<typeof useDisclosure>;
    saveBusinessContact: ReturnType<typeof useDisclosure>;
  };
};

export const useHandleSaveForLater = () => {
  const { auth } = useAuth<ContactAuthContext>();
  const dataStoreApi = useFormDatastoreApi();

  const confirmDisclosure = useDisclosure();
  const saveBusinessContactDisclosure = useDisclosure();

  const saveForLater = useSaveForLater();

  const handle = async () => {
    if (
      !canSaveForLater({
        ...dataStoreApi.getState().data,
        isAuthenticated: auth.isAuthenticated ?? false,
      })
    ) {
      // "Save for later" can have become invalid because of the submitted data
      return;
    }

    const businessContact = businessContactSelector(dataStoreApi.getState());

    if (businessContact && businessContact.email) {
      const saved = await saveForLater.run();
      if (saved) {
        confirmDisclosure.onOpen();
      }
    } else {
      saveBusinessContactDisclosure.onOpen();
    }
  };

  const state: SaveForLaterState = {
    can: canSaveForLater({
      ...dataStoreApi.getState().data,
      isAuthenticated: auth.isAuthenticated ?? false,
    }),
    isLoading: saveForLater.loading,
    modals: {
      confirm: confirmDisclosure,
      saveBusinessContact: saveBusinessContactDisclosure,
    },
  };

  return [handle, state] as const;
};
