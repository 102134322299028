import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

// @NOTE: Similar to custom property numeric validation but
// with isRequired

export const numericValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.numeric
> = ({ isRequired, property, localizedValidation }) => {
  let schema = yup
    .number()
    .typeError(localizedValidation.numeric.number)
    .nullable();

  // Add min/max depending on settings
  if (typeof property.settings?.min === 'number') {
    schema = schema.min(property.settings.min, localizedValidation.numeric.min);
  }
  if (typeof property.settings?.max === 'number') {
    schema = schema.max(property.settings.max, localizedValidation.numeric.max);
  }

  // Add required depending on settings
  if (isRequired) {
    schema = schema.required(localizedValidation.required);
  }

  return schema.strict();
};
