import { upperFirst } from 'lodash';
import { createElement, MouseEventHandler, ReactNode } from 'react';

import { Button } from '../../form/button/button';
import { Box } from '../../layout/box/box';
import { Center } from '../../layout/center/center';
import { HStack, StackProps, VStack } from '../../layout/stack/stack';
import { Tooltip } from '../../overlay/tooltip/tooltip';
import { Heading } from '../../typography/heading';
import { Text } from '../../typography/text';
import { EmptyStateIllustration, illustrationMap } from './illustrations';

export type EmptyStateProps = StackProps & {
  title?: string;
  description?: React.ReactNode;
  illustration?: EmptyStateIllustration | null;
} & {
  /**
   * ReactNode for the whole main action
   *
   * takes priority over granular `mainActionXXX` props and `children`
   */
  mainAction?: ReactNode;
  /**
   * `mainAction` props takes priority
   *
   * takes priority over `children`
   */
  mainActionLabel?: string /**
   * `mainAction` props takes priority

   * takes priority over `children`
   */;
  onMainAction?: MouseEventHandler;
  /**
   * `mainAction` props takes priority
   *
   * takes priority over `children`
   */
  mainActionIsLoading?: boolean;
  /**
   * `mainAction` props takes priority
   *
   * takes priority over `children`
   */
  mainActionIsDisabled?: boolean;
  /**
   * `mainAction` props takes priority
   *
   * takes priority over `children`
   */
  mainActionDisabledTooltip?: string;

  /**
   * ReactNode for the whole main action
   *
   * takes priority over granular `secondaryActionXXX` props and `children`
   */
  secondaryAction?: ReactNode;
  /**
   * `secondaryAction` props takes priority
   *
   * takes priority over `children`
   */
  secondaryActionLabel?: string;
  /**
   * `secondaryAction` props takes priority
   *
   *
   * takes priority over `children`
   */
  onSecondaryAction?: MouseEventHandler;
  /**
   * `secondaryAction` props takes priority
   *
   *
   * takes priority over `children`
   */
  secondaryActionIsLoading?: boolean;
  /**
   * `secondaryAction` props takes priority
   *
   * takes priority over `children`
   */
  secondaryActionIsDisabled?: boolean;
  /**
   * `secondaryAction` props takes priority
   *
   * takes priority over `children`
   */
  secondaryActionDisabledTooltip?: string;

  children?: React.ReactNode;
};

/**
 * We have to use this component when the result of a filtering is empty
 * or for example when it is a new connection and the workflows, submissions, apiKeys... are empty.
 */
export const EmptyState = ({
  title,
  description,
  illustration = 'default',

  mainAction,
  mainActionLabel,
  onMainAction,
  mainActionIsLoading,
  mainActionIsDisabled,
  mainActionDisabledTooltip,

  secondaryAction,
  secondaryActionLabel,
  onSecondaryAction,
  secondaryActionIsLoading,
  secondaryActionIsDisabled,
  secondaryActionDisabledTooltip,

  children,
  ...props
}: EmptyStateProps): JSX.Element => {
  const hasMainAction = mainAction || (!!mainActionLabel && !!onMainAction);
  const hasSecondaryAction =
    secondaryAction || (!!secondaryActionLabel && !!onSecondaryAction);

  const hasAction = hasMainAction || hasSecondaryAction || children;

  return (
    <Center data-testid="empty-state">
      <HStack
        w="full"
        bgColor="blue.100"
        justify="space-between"
        borderRadius="8"
        border="1px"
        borderColor="blue.200"
        p="6"
        {...props}
      >
        <VStack
          spacing="4"
          maxWidth={
            // Full width when no illustration
            !illustration
              ? 'full'
              : // array syntax for sm, md, lg, xl
                ['100%', null, '80%', '50%']
          }
          position="relative"
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          {title && (
            <Heading
              mb={illustration ? 4 : 0}
              size="md"
              textAlign="left"
              wordBreak="break-all"
            >
              {title}
            </Heading>
          )}

          {description && (
            <Text mb={illustration ? 10 : 2} textAlign="left" color="gray.500">
              {description}
            </Text>
          )}

          {hasAction && (
            <HStack spacing="4">
              {hasMainAction &&
                (mainAction != null ? (
                  mainAction
                ) : (
                  <Tooltip
                    label={mainActionDisabledTooltip}
                    isDisabled={!mainActionIsDisabled}
                  >
                    <Button
                      onClick={onMainAction}
                      isDisabled={mainActionIsDisabled}
                      data-testid="emptyState-button"
                      size="md"
                      isLoading={mainActionIsLoading}
                    >
                      {upperFirst(mainActionLabel)}
                    </Button>
                  </Tooltip>
                ))}

              {hasSecondaryAction &&
                (secondaryAction != null ? (
                  secondaryAction
                ) : (
                  <Tooltip
                    label={secondaryActionDisabledTooltip}
                    isDisabled={!secondaryActionIsDisabled}
                  >
                    <Button
                      onClick={onSecondaryAction}
                      isDisabled={secondaryActionIsDisabled}
                      data-testid="emptyState-button-secondary"
                      variant="ghost"
                      size="md"
                      isLoading={secondaryActionIsLoading}
                    >
                      {upperFirst(secondaryActionLabel)}
                    </Button>
                  </Tooltip>
                ))}
              {children}
            </HStack>
          )}
        </VStack>

        {illustration && (
          <Box
            w="482px"
            ml="auto"
            overflow="hidden"
            display={{ sm: 'none', md: 'block' }}
          >
            {illustration ? createElement(illustrationMap[illustration]) : null}
          </Box>
        )}
      </HStack>
    </Center>
  );
};
