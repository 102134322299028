import { replaceUnsafe } from '@swan-io/chicane';

import { useMaybeGraphQLClient } from '@dotfile/frontend/shared/common';
import {
  Button,
  Center,
  EmptyState,
  Flex,
  Heading,
  Image,
  ImageProps,
  Text,
} from '@dotfile/frontend/shared/design-system';
import { Box } from '@dotfile/frontend/shared/design-system';

interface NotFoundProps {
  title?: string;
  description?: string;
  buttonLabel?: string;
  imgUrl?: string;
  imgWidth?: ImageProps['width'];
  showButton?: boolean;
  redirectRoute?: string;
  buttonAction?: () => void | Promise<void>;
}

/**
 * <NotFound/> is used by the error-boundary
 */
export const NotFound = ({
  title,
  description,
  buttonLabel,
  imgUrl,
  buttonAction,
  showButton = true,
  imgWidth = 'auto',
  redirectRoute,
}: NotFoundProps) => {
  const graphQLClient = useMaybeGraphQLClient();

  const handleClick = async () => {
    buttonAction && (await buttonAction());

    // We must reset the graphQLClient because the change of atom
    // does not seem to be taken into account
    graphQLClient?.clear();

    // redirectRoute is picked fro domain Links so it is safe
    redirectRoute && replaceUnsafe(redirectRoute);
  };

  return (
    <Center h="80vh">
      <Flex w="45vw" minW="450px" direction="column">
        {imgUrl ? (
          <>
            <Box mt="12" mb="10" mx="auto">
              <Image alt="Empty-state" src={imgUrl} width={imgWidth} />
            </Box>
            <Heading mb={2} size="lg" textAlign="center">
              {title ?? 'Oops... Something went wrong'}
            </Heading>
            <Text mb={10} textAlign="center">
              {description ?? "We cannot find the page, you're looking for."}
            </Text>
            {showButton && (
              <Center>
                <Button size="md" onClick={() => handleClick()}>
                  {buttonLabel ?? 'Back home'}
                </Button>
              </Center>
            )}
          </>
        ) : (
          <EmptyState
            title={title ?? 'Oops... Something went wrong'}
            description={
              description ?? "We cannot find the page, you're looking for."
            }
            mainActionLabel={
              showButton ? buttonLabel ?? 'Back home' : undefined
            }
            onMainAction={handleClick}
          />
        )}
      </Flex>
    </Center>
  );
};
