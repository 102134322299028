import {
  useBoolean as useBooleanChakra,
  useBreakpointValue as useBreakpointValueChakra,
  useControllableState as useControllableStateChakra, // useClipboard is not exported because we have a wrapper hook useCopyToClipBoard
  useDisclosure as useDisclosureChakra,
  useFormControlContext as useFormControlContextChakra, // useMediaQuery has it owns wrapper in ./use-media-query.ts
  useMergeRefs as useMergeRefsChakra,
  useOutsideClick as useOutsideClickChakra,
  usePrefersReducedMotion as usePrefersReducedMotionChakra,
  usePrevious as usePreviousChakra,
  useTheme as useThemeChakra,
  useToken as useTokenChakra,
} from '@chakra-ui/react';

export const useBoolean = useBooleanChakra;
export const useBreakpointValue = useBreakpointValueChakra;
export const useDisclosure = useDisclosureChakra;
export const useMergeRefs = useMergeRefsChakra;
export const useOutsideClick = useOutsideClickChakra;
export const usePrefersReducedMotion = usePrefersReducedMotionChakra;
export const useTheme = useThemeChakra;
export const useToken = useTokenChakra;
export const useFormControlContext = useFormControlContextChakra;
export const usePrevious = usePreviousChakra;
export const useControllableState = useControllableStateChakra;
