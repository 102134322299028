import styled from '@emotion/styled';

import { Box, BoxProps } from '../../layout/box/box';
import {
  RICH_TEXT_FONT_SIZE,
  RICH_TEXT_LINE_HEIGHT,
  RICH_TEXT_PADDING,
} from './constants';
import { RichTextModeEnum, RichTextVariant } from './types';

export const RichTextStyled = styled(Box)<
  BoxProps & { variant: RichTextVariant; mode: RichTextModeEnum }
>/* css */ `
  .tiptap {
    padding: ${RICH_TEXT_PADDING}px;
    font-size: ${RICH_TEXT_FONT_SIZE}px;
    line-height: ${RICH_TEXT_LINE_HEIGHT}px;
  }

  // This remove the default outline style of ProseMirror (ugly blue border)
  .ProseMirror-focused:focus {
    outline: none;
  }

  // Set a min-height to display 2 lines on large
  .ProseMirror {
    word-break: break-word;
    min-height: ${(props) =>
      props.variant === 'large' && props.mode === RichTextModeEnum.edit
        ? `${RICH_TEXT_PADDING * 2 + RICH_TEXT_LINE_HEIGHT * 2}px`
        : '0px'};
  }

  // Placeholder via css
  // https://tiptap.dev/docs/editor/api/extensions/placeholder
  .tiptap p.is-editor-empty:first-of-type::before {
    color: var(--chakra-colors-gray-500);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  // Style mentions
  .tiptap span[data-type='mention'] {
    color: var(--chakra-colors-blue-700);

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
    word-break: break-all;
    white-space: nowrap;
    vertical-align: bottom;
  }

  // Style links
  .tiptap a {
    text-decoration: underline;
    text-decoration-color: currentcolor;
  }
  .tiptap a:visited {
    color: var(--chakra-colors-purple-700);
  }
  .tiptap a:hover {
    color: var(--chakra-colors-gray-800);
  }

  // Style lists
  .tiptap ul,
  ol {
    padding: 0 1rem;
  }
  .tiptap li p {
    margin: 0;
  }
`;
