import { useTranslation } from 'react-i18next';

import { Box, Text, VStack } from '@dotfile/frontend/shared/design-system';

import { useCurrentStep } from '../../../context';
import { TransBlockDescription } from '../shared';
import { LayoutProps } from './types';

export const LayoutImage = ({ layout }: LayoutProps) => {
  const imageHref = layout.file?.publicUrl;
  const { t } = useTranslation();
  const step = useCurrentStep();
  const translatedLabel = t(`steps.${step.key}.blocks.${layout.key}.label`, {
    defaultValue: layout.label || layout.key,
    ns: 'dynamic',
  });

  return (
    <VStack spacing="2" align="start">
      {layout.label && (
        <Text color="black" fontSize="14px" fontWeight="bold">
          {translatedLabel}
        </Text>
      )}

      <TransBlockDescription block={layout} />

      {imageHref && (
        <Box
          display="flex"
          maxWidth="100%"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt={translatedLabel}
            src={imageHref}
            width="100%"
            height="100%"
          />
        </Box>
      )}
    </VStack>
  );
};
