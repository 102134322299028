import { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@dotfile/frontend/shared/design-system';

export type ThumbnailLayoutProps = PropsWithChildren<BoxProps>;

export const ThumbnailLayout = ({
  children,
  ...props
}: ThumbnailLayoutProps) => <Box {...props}>{children}</Box>;
