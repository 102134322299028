import { createContext, useContext, useEffect, useState } from 'react';
import * as RudderAnalytics from 'rudder-sdk-js';

/**
 * Rudder Analytics
 * @see https://www.npmjs.com/package/rudder-sdk-js
 */
export type Analytics = typeof RudderAnalytics;

const initialAnalytics = RudderAnalytics;
const stub = Object.keys(RudderAnalytics).reduce(
  (acc, key) => ({
    ...acc,
    [key]: () => {
      console.debug(`Stub - Analytics - ${key}`);
    },
    track: (event: string, payload: Record<string, unknown>) => {
      console.debug(`Stub - Analytics - track - ${event}`, payload);
    },
    identify: (id: string, properties: Record<string, unknown>) => {
      console.debug(`Stub - Analytics - identify - ${id}`, properties);
    },
  }),
  {},
) as typeof RudderAnalytics;

export type UseLoadAnalyticsParams = {
  writeKey: string;
  dataPlaneUrl: string;
  options?: Parameters<NonNullable<Analytics>['load']>[2];
};
function useLoadAnalytics({
  writeKey,
  dataPlaneUrl,
  options,
}: UseLoadAnalyticsParams) {
  const [state, setState] = useState<Analytics>(stub);

  useEffect(() => {
    async function loadAnalytics(): Promise<void> {
      if (!writeKey || !dataPlaneUrl) {
        setState(stub);
      } else {
        try {
          RudderAnalytics.load(writeKey, dataPlaneUrl, options);

          return new Promise((res) =>
            RudderAnalytics.ready(() => {
              console.debug('Analytics loaded');
              setState(RudderAnalytics);
              return res();
            }),
          );
        } catch (err) {
          // Only warn because this is not critical to the apps
          console.warn(
            'Error loading rudder analytics, check your anti-tracker add-on',
            err,
          );
        }
      }
    }

    loadAnalytics();
  }, [writeKey, dataPlaneUrl, options]);

  return state;
}

export const AnalyticsContext = createContext<Analytics>(initialAnalytics);

/**
 * Retrieve the Rudder analytics object from the context provided by `AnalyticsProvider`.
 */
export function useAnalytics(): Analytics {
  return useContext(AnalyticsContext);
}

type AnalyticsProps = React.PropsWithChildren<UseLoadAnalyticsParams>;

export const AnalyticsProvider = ({
  children,
  ...props
}: AnalyticsProps): JSX.Element => {
  const value = useLoadAnalytics(props);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
