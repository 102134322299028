import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import {
  useEnvironmentContext,
  useReleaseInfo,
} from '@dotfile/frontend/shared/common';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Grid,
  Heading,
  HStack,
  Skeleton,
  useDisclosure,
} from '@dotfile/frontend/shared/design-system';

import { useNewVersionChecker } from '../../new-version';
import { useSecretCode } from '../hooks/use-secret-code';
import { LabelValue } from './label-value';

export const InfoDialog = ({
  noDotfileMention = false,
}: {
  noDotfileMention?: boolean;
}) => {
  const env = useEnvironmentContext();

  // Inject new versionChecker
  useNewVersionChecker({ noDotfileMention });

  const cancelRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: releaseInfo } = useReleaseInfo();
  const [apiInfo, setApiInfo] = useState({});
  useSecretCode(onOpen);

  // Fetch API Info
  useEffect(() => {
    const fetchAPIInfo = async () => {
      const response = await (await fetch(`${env.baseAPI}/info`)).json();
      setApiInfo(response);
    };
    fetchAPIInfo();
  }, [env.baseAPI]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="2xl"
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Dotfile App Info</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody textAlign="left">
            <Heading size="sm" mb={2}>
              App Release info
            </Heading>
            <Skeleton
              w="100%"
              minH="80px"
              mb={4}
              isLoaded={!isEmpty(releaseInfo)}
            >
              {!!releaseInfo && (
                <Grid
                  rowGap={{ lg: '2', base: '1' }}
                  gridTemplateColumns={{ lg: '45% 55%', base: 'auto' }}
                  overflow="auto"
                >
                  {Object.entries(releaseInfo).map(([key, value]) => (
                    <LabelValue key={key} label={key} value={value} />
                  ))}
                </Grid>
              )}
            </Skeleton>
            <Heading size="sm" mb={2}>
              API info
            </Heading>
            <Skeleton w="100%" minH="138px" mb={4} isLoaded={!isEmpty(apiInfo)}>
              <Grid
                rowGap={{ lg: '2', base: '1' }}
                gridTemplateColumns={{ lg: '45% 55%', base: 'auto' }}
                overflow="auto"
              >
                {Object.entries(apiInfo).map(([key, value]) => (
                  <LabelValue key={key} label={key} value={value} />
                ))}
              </Grid>
            </Skeleton>
            <Heading size="sm" mb={2}>
              App Environment
            </Heading>
            <Grid
              rowGap={{ lg: '2', base: '1' }}
              gridTemplateColumns={{ lg: '45% 55%', base: 'auto' }}
              overflow="auto"
              mb={4}
            >
              {Object.entries(env).map(([key, value]) => (
                <LabelValue key={key} label={key} value={value} />
              ))}
            </Grid>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack spacing={4}>
              <Button
                size="md"
                ref={cancelRef}
                onClick={onClose}
                variant="ghost"
              >
                Close
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
