import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useLayoutEffect,
} from 'react';

import { useAuth } from '../features/auth';
import { HttpClient, HttpClientOptions } from './http-client';

type HttpProviderProps = PropsWithChildren<{
  // Empty
}>;

const HttpContext = createContext<HttpClient | null>(null);

export const useHttpClient = (): HttpClient => {
  const context = useContext(HttpContext);
  if (!context) {
    throw new Error(
      `value for HttpContext was not initialized. Make sure the HttpProvider createHttpClient from  is set up.`,
    );
  }
  return context;
};

export function createHttpProvider(
  options: HttpClientOptions,
): React.FC<HttpProviderProps> {
  const httpClient = new HttpClient(options);

  function useSyncAuthWithHttpClient() {
    const { getToken } = useAuth();

    // using useLayoutEffect effect ensure the effect are run synchronously
    useLayoutEffect(() => {
      // Inject AuthToken
      httpClient.setAuthToken(getToken());
    }, [getToken]);
  }

  const HttpProvider: React.FC<HttpProviderProps> = ({
    children,
  }: {
    children?: ReactNode;
  }) => {
    useSyncAuthWithHttpClient();
    return (
      <HttpContext.Provider value={httpClient}>{children}</HttpContext.Provider>
    );
  };

  return HttpProvider;
}

export function createHttpProviderWithoutAuth(
  options: HttpClientOptions,
): React.FC<HttpProviderProps> {
  const httpClient = new HttpClient(options);

  const HttpProvider: React.FC<HttpProviderProps> = ({
    children,
  }: {
    children?: ReactNode;
  }) => {
    return (
      <HttpContext.Provider value={httpClient}>{children}</HttpContext.Provider>
    );
  };

  return HttpProvider;
}
