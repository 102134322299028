import { snakeCase } from 'lodash';
import { DeepPartial, StrictDeepPick } from 'ts-essentials';

import { humanCase } from '@dotfile/shared/common';

import {
  PropertyMappingEntityEnum,
  PropertyMappingTypeEnum,
} from '../../shared/models';
import { Property, PropertyTypeEnum } from '../../shared/properties';

export const NESTED_PROPERTY_SEPARATOR = '.';

/**
 * Prefix each element of a union type with a given prefix separated by a dot
 */
export type NestedPropertyPrefix<Prefix extends string, K> = K extends string
  ? `${Prefix}${typeof NESTED_PROPERTY_SEPARATOR}${K}`
  : K;

/**
 * Build the properties definition for an entity
 *
 * It has a strong typing:
 * - the name of the properties depends on the model as first parameter
 * - the settings type will depends on the property type
 * - the definition will have a type with all the name of the property added (can `satisfy` a specific interface or subset of the model )
 */
export class DefaultPropertiesBuilder<
  TModel,
  TPropertyNames extends string = never,
> {
  private readonly properties: Record<string, Property<PropertyTypeEnum>> = {};

  constructor(private readonly entity: PropertyMappingEntityEnum) {}

  /**
   * Add a property on the definition
   * @param name Property name on the Typescript model
   * @param type Type
   * @param property.label Override label (default is human-case from the property name)
   * @param property.mapping.key Override mapping key (default is snake-case from the property name)
   * @param property.alwaysRequired Optionally mark this property as always required
   * @param property.settings Optional settings depending on the `type`
   *
   * @returns
   */
  public addProperty<
    const TName extends Exclude<keyof TModel & string, TPropertyNames>,
    TType extends PropertyTypeEnum,
  >(
    name: TName,
    type: TType,
    property?: DeepPartial<
      StrictDeepPick<
        Property<TType>,
        {
          label: true;
          mapping: { key: true; nested: true; type: true };
          alwaysRequired: true;
        }
      >
    > &
      Pick<Property<TType>, 'settings'>,
  ): DefaultPropertiesBuilder<TModel, TPropertyNames | TName> {
    const key = property?.mapping?.key ?? snakeCase(name);
    const mappingType =
      property?.mapping?.type ?? PropertyMappingTypeEnum.default;
    const label = property?.label ?? humanCase(key);

    this.properties[name] = {
      type,
      mapping: {
        entity: this.entity,
        type: mappingType,
        key,
      },
      label,

      alwaysRequired: property?.alwaysRequired ?? false,
    };

    if (property?.mapping?.nested) {
      this.properties[name].mapping.nested = true;
    }

    if (property?.settings) {
      this.properties[name].settings = property.settings;
    }

    return this as DefaultPropertiesBuilder<TModel, TPropertyNames | TName>;
  }

  public addNestedProperties<const TName extends keyof TModel & string>(
    name: TName,
    propertyDefinition: (
      entity: PropertyMappingEntityEnum,
    ) => Record<keyof TModel[TName], Property<PropertyTypeEnum>>,
  ): DefaultPropertiesBuilder<
    TModel,
    TPropertyNames | NestedPropertyPrefix<TName, keyof TModel[TName] & string>
  > {
    Object.entries(propertyDefinition(this.entity)).forEach(([key, value]) => {
      const nestedKey = `${name}${NESTED_PROPERTY_SEPARATOR}${key}` as Exclude<
        keyof TModel & string,
        TPropertyNames
      >;

      this.addProperty(nestedKey, (value as Property<PropertyTypeEnum>).type, {
        ...(value as Property<PropertyTypeEnum>),
        mapping: { key: nestedKey, nested: true },
        label: `${humanCase(name)} > ${(value as Property<PropertyTypeEnum>).label}`,
      });
    });

    return this as DefaultPropertiesBuilder<
      TModel,
      TPropertyNames | NestedPropertyPrefix<TName, keyof TModel[TName] & string>
    >;
  }

  public getDefinition(): Record<TPropertyNames, Property<PropertyTypeEnum>> {
    return this.properties;
  }
}
