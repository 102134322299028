import { get } from 'lodash';

/**
 * Decode base64 data using btoa (web) or Buffer.from (node)
 *
 * @param data
 * @param urlSafe default false (to make the base64 string URL safe)
 * @returns
 */
export function base64Encode(data: string, urlSafe = false): string {
  if (globalThis.btoa) {
    //web
    // https://developer.mozilla.org/en-US/docs/Web/API/btoa
    const encoded = globalThis.btoa(data);
    return urlSafe ? encodeUrlSafe(encoded) : encoded;
  }

  if (globalThis.Buffer) {
    // node
    const buff = globalThis.Buffer.from(data);
    const encoded = buff.toString('base64');
    return urlSafe ? encodeUrlSafe(encoded) : encoded;
  }

  throw new Error(
    'Unable to encode base64 string, btoa and Buffer are not in globalThis',
  );
}

/**
 * Decode base64 data using atob (web) or Buffer.from (node)
 *
 * @param data
 * @param urlSafe default false (to decode from an url safe base64 string)
 * @returns
 */
export function base64Decode(data: string, urlSafe = false): string {
  if (globalThis.atob) {
    //web
    // https://developer.mozilla.org/en-US/docs/Web/API/atob
    return globalThis.atob(urlSafe ? decodeUrlSafe(data) : data);
  }

  if (globalThis.Buffer) {
    // node
    const buff = Buffer.from(urlSafe ? decodeUrlSafe(data) : data, 'base64');
    return buff.toString('ascii');
  }

  throw new Error(
    'Unable to encode base64 string, btoa and Buffer are not in globalThis',
  );
}

// @NOTE URL safe encode inspired by https://github.com/commenthol/url-safe-base64/blob/master/src/index.js
const ENC = {
  '+': '-',
  '/': '_',
};
const DEC = {
  '-': '+',
  _: '/',
  '.': '=',
};

/**
 * encode base64 string url safe
 * @param {String} base64 - base64 encoded string
 * @return {String} url-safe-base64 encoded
 */
const encodeUrlSafe = (base64: string) => {
  return base64.replace(/[+/]/g, (m) => get(ENC, m));
};

/**
 * decode url-safe-base64 string to base64
 * @param {String} safe - url-safe-base64 string
 * @return {String} base64 encoded
 */
const decodeUrlSafe = (safe: string) => {
  return safe.replace(/[-_.]/g, (m) => get(DEC, m));
};

/**
 * checks if `string` is base64 encoded
 * @param {String} string
 * @return {Boolean} true if base64 encoded
 */
export const isBase64 = (string: string): boolean =>
  /^[A-Za-z0-9+/]*[=]{0,2}$/.test(string);

/**
 * checks if `string` is url-safe-base64 encoded
 * @param {String} string
 * @return {Boolean} true if url-safe-base64 encoded
 */
export const isUrlSafeBase64 = (string: string): boolean =>
  /^[A-Za-z0-9_-]*[.=]{0,2}$/.test(string);
