import {
  Grid as ChakraGrid,
  GridItem as ChakraGridItem,
  GridItemProps as ChakraGridItemProps,
  GridProps as ChakraGridProps,
  SimpleGrid as ChakraSimpleGrid,
  SimpleGridProps as ChakraSimpleGridProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/layout/grid
export type GridProps = ChakraGridProps;
export const Grid = ChakraGrid;

export type GridItemProps = ChakraGridItemProps;
export const GridItem = ChakraGridItem;

// @doc https://chakra-ui.com/docs/layout/simple-grid
export type SimpleGridProps = ChakraSimpleGridProps;
export const SimpleGrid = ChakraSimpleGrid;
