import { usePageParams } from '@dotfile/frontend/shared/common';

import { useRouter } from '../../../../routes';

/**
 * Hook to control Check modal via query params
 * @param checkId
 * @returns
 */
export const useCheckModalWithQueryParam = (checkId: string) => {
  const router = useRouter();
  const [params, setParams, removeParams] = usePageParams(router.Checks);

  const isOpen = params.checkModal === checkId;

  const onOpen = () => {
    setParams({ checkModal: checkId }, { override: true, replace: true });
  };

  const onClose = () => {
    removeParams(['checkModal']);
  };

  return {
    isOpen,
    onOpen,
    onClose,
  };
};
