import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@dotfile/frontend/shared/common';
import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  ClientPortalStepTypeEnum,
  CompanyDataInput,
  IndividualDataInput,
  useAutoSaveForLaterMutation,
} from '@dotfile/shared/data-access-client-portal';

import { ContactAuthContext } from '../../../../shared';
import { useFormDatastoreApi } from '../context';
import { canSaveForLater } from '../helpers';

export const useAutoSaveForLater = () => {
  const handleError = useHandleError();
  const { t } = useTranslation();
  const { auth } = useAuth<ContactAuthContext>();

  const [autoSaveForLater, autoSaveForLaterResult] =
    useAutoSaveForLaterMutation();

  const storeApi = useFormDatastoreApi();

  const run = useCallback(
    /**
     * Auto-save the whole form data except when not authenticated or on some specific steps when set
     *
     * @param currentStep Current step to possibly ignore the auto-save
     * @returns true if it was saved
     */
    async (currentStep?: string): Promise<boolean> => {
      // If not authenticated, we cannot auto save
      if (!auth.isAuthenticated) return false;

      // Excluding some step when set
      if (
        currentStep &&
        (
          [
            ClientPortalStepTypeEnum.company_search, // no auto-save since after this step, the company data is often incomplete, in particular when filling manually
            ClientPortalStepTypeEnum.individuals_edit, // already auto-save on each individual drawer submit
            ClientPortalStepTypeEnum.affiliated_companies_edit, // already auto-save on each company drawer submit

            // @TODO - E-2270 - Add @total-typescript/ts-reset
            // `as` should not be necessary anymore
          ] as string[]
        ).includes(currentStep)
      ) {
        return false;
      }

      try {
        const {
          data: storeData, // Must use getState to get up-to-date data, otherwise the closure contains stale value
          setFromCaseQuery,
          setLastAutoSavedAt,
        } = storeApi.getState();

        // Don't auto save when we cannot save for later (edge-case about invalid entities)
        // @see apps/client-portal/app/src/app/modules/forms/shared/helpers/use-can-save-for-later.ts
        if (!canSaveForLater(storeData)) return false;

        // Update case
        const { data } = await autoSaveForLater({
          variables: {
            input: {
              ...storeData.case,
              // Store contains data typed as fully partial but by construction, the client-portal
              // should collect all required fields / attributes
              companies: (storeData.companies ?? []) as CompanyDataInput[],
              individuals: (storeData.individuals ??
                []) as IndividualDataInput[],
            },
          },
        });
        if (!data) {
          // data could be missing in result in some case
          // https://www.apollographql.com/docs/react/data/mutations/#mutationhookoptions-interface-ignoreresults
          throw new Error(`No data from auto save for later`);
        }

        // Update dataStore
        const caseQuery = data.autoSaveForLater.case;

        // Sync case data with Store
        setFromCaseQuery(caseQuery);

        // Success toast to reassure the user
        setLastAutoSavedAt(new Date());

        return true;
      } catch (error) {
        handleError({
          error,
          title: t('forms.auto_save_for_later.error', {
            ns: 'client-portal',
            defaultValue:
              'Unable to auto save your data, please retry or contact support if the problem persist',
          }),
          duration: null,
        });

        return false;
      }
    },
    [t, autoSaveForLater, storeApi, handleError, auth.isAuthenticated],
  );

  return {
    run,
    data: autoSaveForLaterResult.data,
    error: autoSaveForLaterResult.error,
    loading: autoSaveForLaterResult.loading,
  };
};
