import './entity-legal-forms.constant';
import {
  ENTITY_LEGAL_FORMS,
  GENERIC_DEFAULT_COUNTRY_CODE,
} from './entity-legal-forms.constant';
import { EntityLegalForm } from './types';

export const entityLegalFormByCode = Object.entries(ENTITY_LEGAL_FORMS).reduce<
  Record<string, EntityLegalForm>
>((acc, [country, countryDefinition]) => {
  return {
    ...acc,
    ...Object.entries(countryDefinition).reduce(
      (acc, [code, codeDefinition]) => {
        return {
          ...acc,
          [code]: {
            code,
            country,
            ...codeDefinition,
          },
        };
      },
      {},
    ),
  };
}, {});

/**
 * Get definition for a given ISO 20275 code
 * @param code - The ISO 20275 code for entity legal form
 * @param country - Optional filter
 * @param subdivision - Optional filter
 * @returns null if code don't exist or is not matching filter (country/subdivision)
 */
export function getEntityLegalFormByCode(
  code: string,
  country?: string,
  subdivision?: string,
): EntityLegalForm | null {
  let entityLegalForm: EntityLegalForm | null =
    entityLegalFormByCode[code] ?? null;

  const codeIsAvailableForAllCountry = Object.keys(
    ENTITY_LEGAL_FORMS[GENERIC_DEFAULT_COUNTRY_CODE],
  ).includes(code);

  if (country && !codeIsAvailableForAllCountry) {
    entityLegalForm = ENTITY_LEGAL_FORMS[country]?.[code]
      ? { ...ENTITY_LEGAL_FORMS[country]?.[code], code, country }
      : null;
  }

  if (
    subdivision &&
    !codeIsAvailableForAllCountry &&
    entityLegalForm &&
    entityLegalForm.subdivision !== subdivision
  ) {
    entityLegalForm = null;
  }

  return entityLegalForm ? entityLegalForm : null;
}
