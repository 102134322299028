import { ChevronLeft, ChevronRight, Search } from 'lucide-react';
import { useRef, useState } from 'react';

import { RenderSearchProps } from '@react-pdf-viewer/search';

import { createUseDebounce } from '@dotfile/frontend/shared/common';
import {
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Spinner,
  Text,
} from '@dotfile/frontend/shared/design-system';

const useDebounce = createUseDebounce<[string] | []>(500);

export const PdfSearchPopover = ({
  keyword,
  clearKeyword,
  setKeyword,
  search: pdfSearch,
  numberOfMatches,
  currentMatch,
  jumpToNextMatch,
  jumpToPreviousMatch,
  jumpToMatch,
}: RenderSearchProps) => {
  const initialFocusRef = useRef<HTMLInputElement>(null);

  const [isSearching, setIsSearching] = useState(false);
  const [lastSearchedKeyword, setLastSearchedKeyword] = useState<string | null>(
    null,
  );

  const search = useDebounce(async (keywordFromEvent?: string) => {
    const resolvedKeyword = keywordFromEvent ?? keyword;

    if (resolvedKeyword === lastSearchedKeyword) {
      return;
    }

    if (!resolvedKeyword) {
      setLastSearchedKeyword(null);
      clearKeyword();
    } else {
      setIsSearching(true);
      await pdfSearch();
      setLastSearchedKeyword(resolvedKeyword as string);
      setIsSearching(false);
    }
  });

  const shouldDisplayResult =
    !isSearching &&
    // wait for keyword equals the lastSearchedKeyword searched to not display
    // results when the search is still pending debounce delay
    keyword === lastSearchedKeyword;

  return (
    <Popover placement="bottom-end" initialFocusRef={initialFocusRef}>
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={Search} />}
          aria-label="Search"
          variant="link"
          color="black"
        />
      </PopoverTrigger>
      <PopoverContent w="2xs">
        <PopoverArrow />
        <PopoverBody p="2">
          <InputGroup>
            <Input
              placeholder="Search"
              value={keyword}
              ref={initialFocusRef}
              onChange={(e) => {
                setKeyword(e.target.value);
                // need to pass the latest value as argument since it's stale in the closure
                search(e.target.value);
              }}
              onBlur={() => {
                search();
                // immediately invoke the search on blur
                search.flush();
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  search();
                  // immediately invoke the search on Enter press
                  search.flush();
                }
              }}
            />
            {isSearching && (
              <InputRightElement>
                <Spinner size="sm" />
              </InputRightElement>
            )}
          </InputGroup>
          <HStack pt="2">
            <Text color="gray.500">Results:</Text>
            {shouldDisplayResult && numberOfMatches === 0 && <Text>none</Text>}
            {shouldDisplayResult && numberOfMatches > 0 && (
              <>
                <Text>
                  {currentMatch}/{numberOfMatches}
                </Text>
                <Spacer />
                <IconButton
                  icon={<Icon as={ChevronLeft} />}
                  aria-label="Previous match"
                  variant="ghost"
                  onClick={() => {
                    if (currentMatch === 1) {
                      // cycle to last match
                      jumpToMatch(numberOfMatches);
                    } else {
                      jumpToPreviousMatch();
                    }
                  }}
                />
                <IconButton
                  icon={<Icon as={ChevronRight} />}
                  aria-label="Next match"
                  variant="ghost"
                  onClick={() => {
                    if (currentMatch === numberOfMatches) {
                      // cycle to first match
                      jumpToMatch(1);
                    } else {
                      jumpToNextMatch();
                    }
                  }}
                />
              </>
            )}
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
