import { useMemo } from 'react';

import {
  ClientPortalChecks_Company,
  ClientPortalChecks_Individual,
} from '@dotfile/shared/data-access-client-portal';
import { CheckStatusEnum, CompanyTypeEnum } from '@dotfile/shared/domain';

import { useCaseChecks } from '../../shared';

export const useSortedEntities = (withCheckStatus: CheckStatusEnum[]) => {
  const { data } = useCaseChecks();

  return useMemo(() => {
    const mainCompany = data?.case?.companies.find(
      (company) => company.type === CompanyTypeEnum.main,
    );
    const affiliatedCompanies =
      data?.case?.companies.filter(
        (company) => company.type === CompanyTypeEnum.affiliated,
      ) ?? [];

    const entities = [
      mainCompany,
      ...affiliatedCompanies,
      ...(data?.case?.individuals ?? []),
    ].reduce<(ClientPortalChecks_Individual | ClientPortalChecks_Company)[]>(
      (acc, entity) => {
        if (!entity) {
          return acc;
        }
        const checks = entity.checks.filter((check) =>
          withCheckStatus.includes(check.status),
        );

        if (!checks.length) return acc;

        return [
          ...acc,
          {
            ...entity,
            checks,
          },
        ];
      },
      [],
    );

    return entities;
  }, [data, withCheckStatus]);
};
