import styled from '@emotion/styled';

import { Box } from '../../layout/box/box';

export const StyledWrapper = styled(Box)/* css */ `
  /* Remove padding for top level rule group */
  .queryBuilder > .ruleGroup {
    padding: 0;
  }

  /* Display the "Add condition" / "Add group condition" below the rules */
  .ruleGroup {
    flex-direction: column-reverse;
  }

  /* Remove gap when there is no rules */
  .ruleGroup .ruleGroup-body:empty {
    margin-bottom: -0.5rem;
  }

  /* Align the group and rule "Remove" action on the right */
  .ruleGroup-remove {
    margin-left: auto;
  }

  /* color for rule group depending on depth */
  .ruleGroup {
    border-radius: var(--chakra-radii-base);
    padding-inline: 0.75rem;
  }
  .ruleGroup[data-level='0'] {
    border-color: transparent;
    background-color: var(--chakra-colors-white);
  }
  .ruleGroup[data-level='1'] {
    border-color: var(--chakra-colors-gray-100);
    background-color: var(--chakra-colors-gray-50);
  }
  .ruleGroup[data-level='2'] {
    border-color: var(--chakra-colors-gray-200);
    background-color: var(--chakra-colors-gray-100);
  }

  /* Inline selector */
  /* see https://react-querybuilder.js.org/docs/tips/showcase#inline-combinator-selectors */
  .ruleGroup-body {
    /* Override the default flex layout */
    display: grid !important;
    /* Allow the right-hand column (the rule/subgroup) to expand as needed */
    /* Collapse the left-hand column (the combinator) to the width of the content */
    grid-template-columns: min-content auto;
    /* Keep the combinator aligned with the top of the rule/subgroup */
    align-items: start;
  }

  /* Indent the first rule/subgroup since it has no preceding combinator */
  .ruleGroup-body > .rule,
  .ruleGroup-body > .ruleGroup {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  /* Indent the first rule/subgroup in the first grid column to avoid gap when no combinator */
  .queryBuilder > .ruleGroup > .ruleGroup-body > .rule:only-child,
  .queryBuilder > .ruleGroup > .ruleGroup-body > .ruleGroup:only-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  /* Only display the last combinator (nth-last-child=2 because last child is the rule) */
  .ruleGroup-body .betweenRules {
    visibility: hidden;
  }
  .ruleGroup-body .betweenRules:nth-last-of-type(2) {
    visibility: visible;
  }

  /* Override branch positioning to works with inline combinator
    This use a custom class queryBuilder-branchesInline to completely
    re-implement since default style was too different than the required
    style for inline combinator */
  .queryBuilder-branchesInline .rule,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup {
    position: relative;
  }
  .queryBuilder-branchesInline .rule::before,
  .queryBuilder-branchesInline .rule::after,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::before,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::after {
    content: '';
    width: 1.5rem;
    left: calc(-1.5rem - 1px);
    border-radius: 0;
    position: absolute;
  }
  .queryBuilder-branchesInline .rule::before,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::before {
    top: -0.5rem;
    height: calc(50% + 0.5rem);
    border-width: 0 0 1px 0;
  }
  .queryBuilder-branchesInline .rule:first-of-type::before,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup:first-of-type::before {
    display: none;
  }
  .queryBuilder-branchesInline .rule:last-child::before,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup:last-child::before {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
  }
  .queryBuilder-branchesInline
    .rule:not(:first-of-type):not(:last-child)::before,
  .queryBuilder-branchesInline
    .ruleGroup
    .ruleGroup:not(:first-of-type):not(:last-child)::before {
    border-width: 0 0 0 1px;
    height: calc(50% + 28px);
    top: -0.5rem;
  }
  .queryBuilder-branchesInline .rule::after,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::after {
    top: 50%;
    height: calc(50% + 28px);
    border-width: 1px 0 0 1px;
  }
  .queryBuilder-branchesInline .rule:last-child::after,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup:last-child::after {
    display: none;
  }
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::before,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::after {
    left: calc(-1.5rem - 1px - 1px);
  }
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::before {
    top: calc(1.5rem - 1px);
    height: 1px;
  }
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::before:last-child {
    display: none;
  }
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::after {
    height: calc(50% + 28px);
  }

  /* Colorize branchesInline */
  .queryBuilder-branchesInline .rule::before,
  .queryBuilder-branchesInline .rule::after,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::before,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup::after,
  .queryBuilder-branchesInline .betweenRules::before {
    border-color: var(--chakra-colors-gray-300);
    border-style: solid;
  }
  .queryBuilder-branchesInline .rule:first-of-type::after,
  .queryBuilder-branchesInline .ruleGroup .ruleGroup:first-of-type::after {
    border-top-left-radius: 0.25rem;
  }
  .queryBuilder-branchesInline .betweenRules {
    position: relative;
  }

  /* Fix width for each rule field/operator/value/remove action to prevent
     the select to resize when selecting an option
  */
  .rule {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr min-content;
  }
`;
