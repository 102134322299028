import { validate } from 'uuid';

const dataIdPattern = '(?<type>[a-z]+):(?<id>[a-f0-9-]*)';

/**
 * Extract the type and id from the value of a data-id attribute value:
 *
 * - **type**: contains only characters between `a-z`
 * - `:` character separator
 * - **id** as an uuid (contains only characters between `a-f`, `0-9` and `-`)
 *
 * @param html The HTML string
 * @returns
 */
export const MENTION_DATA_ID_REGEX = new RegExp(dataIdPattern);

const fullRegex = new RegExp(`data-id="${dataIdPattern}"`, 'g');

/**
 * Extract the ids from mentions in a HTML string.
 *
 * This assume that mentions are well formatted: in a `data-id` attribute and contains:
 * - **type**: contains only characters between `a-z`
 * - `:` character separator
 * - **id** as an uuid (contains only characters between `a-f`, `0-9` and `-`)
 *
 * @param html The HTML string
 * @returns
 */
export const extractHtmlMention = (html: string): string[] => {
  const matches = Array.from(html.matchAll(fullRegex));

  if (matches.length === 0) return [];

  const ids = new Set<string>();
  matches.forEach((match) => {
    const matchedId = match.groups?.['id'];
    if (matchedId && validate(matchedId)) {
      ids.add(matchedId);
    }
  });

  return Array.from(ids.values());
};
