import { useMemo } from 'react';
import { MarkOptional } from 'ts-essentials';

import '@dotfile/frontend/shared/design-system';
import { VStack } from '@dotfile/frontend/shared/design-system';
import { AnalysisResultModel } from '@dotfile/shared/domain';

import { AnalysisResultGroup } from './analysis-result-group';
import { AnalysisResultVariant } from './types';
import { buildAnalysisResultsGroup, isAnalysisResultGroup } from './utils';

export interface AnalysisResultsProps {
  analysisResults: Pick<
    MarkOptional<
      AnalysisResultModel,
      'description' | 'expected' | 'received' | 'translation'
    >,
    'key' | 'description' | 'result' | 'expected' | 'received' | 'translation'
  >[];
  variant?: AnalysisResultVariant;
  buildGroupOptions?: Omit<
    Parameters<typeof buildAnalysisResultsGroup>[1],
    'language'
  >;
}

export const AnalysisResults = ({
  analysisResults,
  buildGroupOptions,
  variant = 'console',
}: AnalysisResultsProps) => {
  const { displayableResultGroups } = useMemo(() => {
    // not grouping on client-portal
    if (variant === 'client-portal') {
      return {
        displayableResultGroups: analysisResults.map((analysisResult) => ({
          analysisResult,
        })),
      };
    }

    const displayableResultGroups = buildAnalysisResultsGroup(analysisResults, {
      ...buildGroupOptions,
    });

    return {
      displayableResultGroups,
    };
  }, [analysisResults, buildGroupOptions, variant]);

  return (
    <VStack
      align="start"
      width="full"
      spacing={variant === 'client-portal' ? 1 : 0}
    >
      {displayableResultGroups.map((resultGroup) => {
        const key = isAnalysisResultGroup(resultGroup)
          ? resultGroup.key
          : resultGroup.analysisResult.key;

        return (
          <AnalysisResultGroup
            key={key}
            variant={variant}
            analysisResultGroup={resultGroup}
          />
        );
      })}
    </VStack>
  );
};
