import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Text } from '@dotfile/frontend/shared/design-system';

export const DataComparisonNoLabel = ({
  informationDefinition,
}: {
  informationDefinition: { label: (t: TFunction) => string };
}) => {
  const { t } = useTranslation();

  return (
    <Text color="black" fontSize="sm">
      {t('document_analysis.data_comparison_no_data', {
        label: informationDefinition.label(t),
        ns: 'components',
        defaultValue: `{{label}}: no data extracted or to compare with`,
      })}
    </Text>
  );
};
