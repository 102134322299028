import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  GridItem,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { FullContainer } from '../../../shared';
import {
  StepFooter,
  StepHeader,
  StepProps,
  useFormDrawerState,
  useHandleSaveForLater,
} from '../shared';
import { BusinessContactLockedAlert } from './business-contact-locked-alert';
import { BusinessContactSelectController } from './business-contact-select-controller';
import { DelegatorSelectController } from './delegator-select-controller';
import { EmailInputController } from './email-input-controller';
import { IndividualFormDrawer } from './individual-form-drawer';
import { IsBusinessContactTheAuthorizedSignatoryController } from './is-business-contact-the-authorized-signatory-controller';
import { SignatoryLockedAlert } from './signatory-locked-alert';
import { SignatorySelectController } from './signatory-select-controller';
import { useBusinessContactStepEffects } from './use-business-contact-step-effects';
import { useBusinessContactStepForm } from './use-business-contact-step-form';
import { useBusinessContactStepFormInit } from './use-business-contact-step-form-init';
import { useBusinessContactHandleSubmit } from './use-business-contact-step-handle-submit';

export const BusinessContactStep = ({ step, onSubmitStep }: StepProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();

  const requestDelegator = step.config.requestDelegator ?? false;

  const addBusinessContactDrawerState = useFormDrawerState();
  const addSignatoryDrawerState = useFormDrawerState();

  const { defaultValues, isBusinessContactLocked, isSignatoryLocked } =
    useBusinessContactStepFormInit();

  const methods = useBusinessContactStepForm(requestDelegator, defaultValues);

  useBusinessContactStepEffects(methods.watch, methods.setValue);

  const businessContactIndex = methods.watch('businessContactIndex');
  const signatoryIndex = methods.watch('signatoryIndex');
  const isSignatoryLegalRepresentative = methods.watch(
    'isSignatoryLegalRepresentative',
  );
  const isBusinessContactTheAuthorizedSignatory = methods.watch(
    'isBusinessContactTheAuthorizedSignatory',
  );

  const [handleSaveForLater, saveForLaterState] = useHandleSaveForLater();
  const handleSubmit = useBusinessContactHandleSubmit(
    methods.handleSubmit,
    requestDelegator,
    { handleSaveForLater, onSubmitStep },
  );

  return (
    <>
      <FullContainer
        as="form"
        noValidate
        onSubmit={handleSubmit}
        footer={<StepFooter saveForLaterState={saveForLaterState} />}
      >
        <StepHeader />

        <FormProvider {...methods}>
          <VStack alignItems="stretch" gap={isSmallScreen ? '2' : '4'}>
            {/* Business contact */}
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap={isSmallScreen ? '2' : '4'}
            >
              <GridItem colSpan={isSmallScreen ? 2 : 1}>
                <BusinessContactSelectController
                  isBusinessContactLocked={isBusinessContactLocked}
                  onAddNew={addBusinessContactDrawerState.handleAdd}
                />
              </GridItem>
              <GridItem colSpan={isSmallScreen ? 2 : 1}>
                <EmailInputController
                  isBusinessContactLocked={isBusinessContactLocked}
                />
              </GridItem>
            </Grid>

            {isBusinessContactLocked && <BusinessContactLockedAlert />}

            {/* Delegator & signatory */}
            {requestDelegator && businessContactIndex && (
              <>
                <IsBusinessContactTheAuthorizedSignatoryController
                  isSignatoryLocked={isSignatoryLocked}
                />

                {isBusinessContactTheAuthorizedSignatory === false && (
                  <SignatorySelectController
                    onAddNew={addSignatoryDrawerState.handleAdd}
                    isSignatoryLocked={isSignatoryLocked}
                  />
                )}

                {isSignatoryLocked && <SignatoryLockedAlert />}

                {signatoryIndex && !isSignatoryLegalRepresentative && (
                  <DelegatorSelectController />
                )}
              </>
            )}
          </VStack>
        </FormProvider>
      </FullContainer>

      <IndividualFormDrawer
        isOpen={addBusinessContactDrawerState.isOpen}
        onClose={addBusinessContactDrawerState.handleClose}
        header={t('forms.business_contact.select.add.form_drawer.header', {
          ns: 'client-portal',
          defaultValue: 'Business contact information',
        })}
        onSubmit={(index) => {
          methods.setValue('businessContactIndex', index.toString());

          // Revalidate
          methods.trigger();
        }}
        step={step}
      />

      <IndividualFormDrawer
        isOpen={addSignatoryDrawerState.isOpen}
        onClose={addSignatoryDrawerState.handleClose}
        header={t('forms.business_contact.signatory.add.form_drawer.header', {
          ns: 'client-portal',
          defaultValue: 'Signatory information',
        })}
        onSubmit={(index) => {
          methods.setValue('signatoryIndex', index.toString());

          // Revalidate
          methods.trigger();
        }}
        step={step}
      />
    </>
  );
};
