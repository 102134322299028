import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { GlobalStyleProps, mode } from '@chakra-ui/theme-tools';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts
// for default ChakraUI theme

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

const baseStyle = defineStyle({
  lineHeight: '1.5',
  borderRadius: 'base',
  fontWeight: 'medium',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _focusVisible: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
});

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

const variantSolid = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c } = props;

  if (c === 'coal') {
    return {
      _hover: {
        bg: 'coal.400',
        _disabled: {
          bg: 'coal.100',
        },
      },
      _active: {
        bg: 'coal.300',
      },
      _disabled: {
        opacity: 1,
        bg: 'coal.100',
      },
    };
  }

  const {
    bg = `${c}.600`,
    color = 'white',
    hoverBg = `${c}.700`,
    activeBg = `${c}.800`,
  } = accessibleColorMap[c] ?? {};

  const background = mode(bg, `${c}.200`)(props);

  return {
    bg: background,
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
  };
});

const variantGhost = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c } = props;

  if (c === 'coal') {
    return {
      color: mode(`${c}.500`, `${c}.200`)(props),
      _hover: {
        bg: 'coal.50',
      },
      _active: {
        bg: 'coal.200',
      },
      _disabled: {
        opacity: 1,
        color: 'coal.100',
      },
    };
  }

  return {
    color: mode(`${c}.500`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, `${c}.200`)(props),
    },
    _active: {
      bg: mode(`${c}.100`, `${c}.200`)(props),
    },
  };
});

const variantOutline = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c } = props;
  const borderColor = mode(`gray.100`, `whiteAlpha.300`)(props);

  if (c === 'coal') {
    return {
      _hover: {
        bg: 'coal.50',
      },
      _active: {
        bg: 'coal.200',
      },
      _disabled: {
        opacity: 1,
        bg: 'white',
        color: 'coal.100',
      },
    };
  }

  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    '.chakra-button__group[data-attached] > &:not(:last-of-type)': {
      marginEnd: '-1px',
    },
    bg: 'transparent',
    color: c,
    _active: { bg: `${c}.100` },
    _hover: { bg: `${c}.50` },
  };
});

const variantLink = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    verticalAlign: 'baseline',
    color: mode(`${c}.500`, `${c}.200`)(props),
    minW: 0,
    _hover: {
      textDecoration: 'underline',
      cursor: 'pointer',
      _disabled: {
        textDecoration: 'none',
        cursor: 'not-allowed',
      },
    },
    _active: {
      color: mode(`${c}.700`, `${c}.500`)(props),
    },
  };
});

const variantUnstyled = defineStyle({
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: '0',
  p: '0',
});

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  unstyled: variantUnstyled,
};

const sizes = {
  lg: defineStyle({
    fontSize: 'lg',
  }),
  md: defineStyle({
    fontSize: 'md',
  }),
  sm: defineStyle({
    fontSize: 'sm',
  }),
  xs: defineStyle({
    fontSize: 'xs',
  }),
};

export const ButtonTheme = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'solid',
    size: 'sm',
    colorScheme: 'coal',
  },
});
