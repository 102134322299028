import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { GlobalStyleProps } from '@chakra-ui/theme-tools';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const baseStyle = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c } = props;

  return {
    _light: {
      [$startColor.variable]: `colors.${c}.50`, // Override default skeleton startColor (${c}.100)
      [$endColor.variable]: `colors.${c}.200`, // changing default skeleton endColor (${c}.400)
    },
  };
});

export const SkeletonTheme = defineStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'gray',
  },
});
