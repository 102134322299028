import {
  Popover as ChakraPopover,
  PopoverAnchor as ChakraPopoverAnchor,
  PopoverArrow as ChakraPopoverArrow,
  PopoverBody as ChakraPopoverBody,
  PopoverBodyProps as ChakraPopoverBodyProps,
  PopoverCloseButton as ChakraPopoverCloseButton,
  PopoverCloseButtonProps as ChakraPopoverCloseButtonProps,
  PopoverContent as ChakraPopoverContent,
  PopoverContentProps as ChakraPopoverContentProps,
  PopoverFooter as ChakraPopoverFooter,
  PopoverFooterProps as ChakraPopoverFooterProps,
  PopoverHeader as ChakraPopoverHeader,
  PopoverHeaderProps as ChakraPopoverHeaderProps,
  PopoverProps as ChakraPopoverProps,
  PopoverTrigger as ChakraPopoverTrigger,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/overlay/popover
export type PopoverProps = ChakraPopoverProps;
export const Popover = ChakraPopover;

export const PopoverTrigger = ChakraPopoverTrigger;

export type PopoverHeaderProps = ChakraPopoverHeaderProps;
export const PopoverHeader = ChakraPopoverHeader;

export type PopoverBodyProps = ChakraPopoverBodyProps;
export const PopoverBody = ChakraPopoverBody;

export type PopoverFooterProps = ChakraPopoverFooterProps;
export const PopoverFooter = ChakraPopoverFooter;

export type PopoverContentProps = ChakraPopoverContentProps;
export const PopoverContent = ChakraPopoverContent;

export const PopoverArrow = ChakraPopoverArrow;
export const PopoverAnchor = ChakraPopoverAnchor;

export type PopoverCloseButtonProps = ChakraPopoverCloseButtonProps;
export const PopoverCloseButton = ChakraPopoverCloseButton;
