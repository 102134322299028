import { upperFirst } from 'lodash';

import { LanguageEnum } from '../../../shared/models';
import { languageDefinition } from './language.definition';

export const formatLanguage = (
  language: LanguageEnum,
  {
    withEmoji = true,
    localizedName = false,
    withCode = true,
  }: {
    withEmoji?: boolean;
    localizedName?: boolean;
    withCode?: boolean;
  } = {},
): string => {
  return [
    withEmoji && languageDefinition[language].emoji,

    localizedName
      ? upperFirst(
          new Intl.DisplayNames([language], { type: 'language' }).of(language),
        )
      : languageDefinition[language].name,

    withCode && `(${language.toUpperCase()})`,
  ]
    .filter(Boolean)
    .join(' ');
};
