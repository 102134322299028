import { useCallback, useReducer } from 'react';
import { match } from 'ts-pattern';

export type RemoveDialogItem = { index: number; label: string };
type State = {
  isOpen: boolean;
  item: RemoveDialogItem | null;
};

const initialState: State = {
  isOpen: false,
  item: null,
};

function reducer(
  state: State,
  action: { type: 'close' } | { type: 'open'; item: RemoveDialogItem },
): State {
  return match(action)
    .with({ type: 'close' }, () => ({
      isOpen: false,
      item: null,
    }))
    .with({ type: 'open' }, ({ item }) => ({
      isOpen: true,
      item,
    }))
    .exhaustive();
}

export const useRemoveDialogState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    ...state,
    handleOpen: useCallback(
      (item: RemoveDialogItem) => dispatch({ type: 'open', item }),
      [],
    ),
    handleClose: useCallback(() => dispatch({ type: 'close' }), []),
  };
};
