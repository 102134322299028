import { i18n } from 'i18next';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { I18nextProvider } from 'react-i18next';

import { useHandleError } from '@dotfile/frontend/shared/components';

import { initI18next, InitI18nextParam } from './init-i18next';

type InitI18nContextValue = (
  param: Omit<InitI18nextParam, 'handleError'>,
) => ReturnType<typeof initI18next>;

const I18nContext = createContext<InitI18nContextValue | undefined>(undefined);

export const useInitI18nContext = () => {
  const context = useContext(I18nContext);
  if (context === undefined) {
    throw new Error(
      `value for I18nContext was not initialized. Make sure the I18nProvider is set up.`,
    );
  }

  return context;
};

export const I18nProvider = ({ children }: PropsWithChildren) => {
  const handleError = useHandleError();
  const [i18nInstance, setI18nInstance] = useState<i18n>(() =>
    initI18next({ handleError, ids: null }),
  );

  const initI18n = useCallback(
    (param: Parameters<InitI18nContextValue>[0]) => {
      const i18n = initI18next({ handleError, ...param });
      setI18nInstance(i18n);
      return i18n;
    },
    [handleError],
  );

  return (
    <I18nContext.Provider value={initI18n}>
      <I18nextProvider i18n={i18nInstance} i18nIsDynamicList>
        {children}
      </I18nextProvider>
    </I18nContext.Provider>
  );
};
