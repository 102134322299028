import { ChevronDown } from 'lucide-react';
import { Children, PropsWithChildren, useCallback } from 'react';

import {
  Button,
  Collapse,
  CollapseProps,
  Icon,
  StackProps,
  useControllableState,
  VStack,
} from '@dotfile/frontend/shared/design-system';

// @TODO - E-3452 - Design: SeeMore components

const NO_COLLAPSE_ANIMATION: CollapseProps['transition'] = {
  enter: { duration: 0, delay: 0 },
  exit: { duration: 0, delay: 0 },
};

type SeeMoreProps = PropsWithChildren<{
  /**
   * Number of item to display when collapse
   *
   * @default 5
   */
  limit?: number;

  /**
   * When collapse state is uncontrolled
   */
  defaultIsCollapseOpen?: boolean;
  /**
   * When collapse state is controlled
   */
  isCollapseOpen?: boolean;
  /**
   * When collapse state is controlled
   */
  onCollapseChange?: (isCollapseOpen: boolean) => void;
  /**
   * Fired when the collapse is manually toggled by an user click. Useful for analytics purpose.
   * @param isCollapseOpen
   * @returns
   */
  onCollapseClick?: (isOpen: boolean) => void;
  noCollapseAnimation?: boolean;
}> &
  StackProps;

export const SeeMore = ({
  limit = 5,
  children,

  defaultIsCollapseOpen,
  isCollapseOpen,
  onCollapseChange,
  onCollapseClick,
  noCollapseAnimation,

  ...stackProps
}: SeeMoreProps) => {
  const arrayChildren = Children.toArray(children);
  const [showPart, hiddenPart] = [
    arrayChildren.slice(0, limit),
    arrayChildren.slice(limit),
  ];

  const [isOpen, setIsOpen] = useControllableState({
    defaultValue: defaultIsCollapseOpen,
    value: isCollapseOpen,
    onChange: onCollapseChange,
  });

  const toggleCollapse = useCallback(() => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    onCollapseClick?.(newIsOpen);
  }, [setIsOpen, onCollapseClick, isOpen]);

  return (
    <VStack width="full" alignItems="start" spacing="4" {...stackProps}>
      {showPart}

      {hiddenPart?.length > 0 && (
        <>
          <Collapse
            in={isOpen}
            transition={noCollapseAnimation ? NO_COLLAPSE_ANIMATION : undefined}
          >
            <VStack width="full" alignItems="start" spacing="4" {...stackProps}>
              {hiddenPart}
            </VStack>
          </Collapse>

          <Button
            size="md"
            variant="ghost"
            borderRadius="0"
            onClick={toggleCollapse}
            flexShrink="0"
            alignSelf="stretch"
            justifyContent="start"
            iconSpacing="1"
            fontWeight="400"
            leftIcon={
              <Icon
                __css={{
                  // Same as accordion icon
                  transform: isOpen ? 'rotate(-180deg)' : undefined,
                  transition: 'transform 0.2s',
                  transformOrigin: 'center',
                }}
                as={ChevronDown}
              />
            }
          >
            See {isOpen ? 'less' : 'more'} ({hiddenPart.length})
          </Button>
        </>
      )}
    </VStack>
  );
};
