import { useCallback } from 'react';

import {
  ClientPortalStepTypeEnum,
  CompanyTypeEnum,
} from '@dotfile/shared/data-access-client-portal';

import { useFormDatastore, useFormDatastoreApi } from '../context';
import { useStepsDisplayLogic } from '../logics';

export const useGetDisplayedSteps = () => {
  const getFilteredStepsByLogic = useStepsDisplayLogic();

  const storeApi = useFormDatastoreApi();
  const caseId = useFormDatastore((state) => state.data.case?.id);

  const getDisplayedSteps = useCallback(() => {
    // Use the storeApi to get the latest state when computing the display steps
    // but also add the current state `case.id` in the hook dependencies to invalid the
    // useCallback when state `case.id` changes and trigger a re-compute of the displayed
    // steps in the consumers of this hook
    void caseId;

    const filteredByLogic = getFilteredStepsByLogic();

    const hasExistingMainCompany = storeApi
      .getState()
      .data.companies?.find((c) => c.type === CompanyTypeEnum.main && !!c.id);

    const displayedStep = hasExistingMainCompany
      ? filteredByLogic.filter(
          (s) => s.type !== ClientPortalStepTypeEnum.company_search,
        )
      : filteredByLogic;

    return displayedStep;
  }, [getFilteredStepsByLogic, storeApi, caseId]);

  return getDisplayedSteps;
};
