import { get } from 'lodash';
import { ValidationError } from 'yup';

import {
  CustomDocumentTypeModel,
  DocumentAnalysisModelEnum,
  DocumentDataSettingsModel,
  EntityTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { MAX_FILE_PER_DOCUMENT_DATA } from '../constants';
import { getDocumentAnalysisDefinition } from '../document-analysis';
import { DocumentCheckInput } from '../document-check-input';
import {
  companyDocumentTypes,
  individualDocumentTypes,
} from '../document-type-definition';

export const documentSettingsSchema = (
  // TODO - E-4952 - Remove documentType and customDocumentTypeId from the schema
  //  https://linear.app/dotfile/issue/E-4952/console-migration-to-document-type-key
  legacyDocumentTypeValidation = true,
): yup.SchemaOf<Omit<DocumentDataSettingsModel, 'customDocumentType'>> => {
  return yup
    .object()
    .shape({
      documentTypeKey: yup
        .string()
        .test('document-type-key', function (value) {
          if (!value) {
            // @NOTE accept null and undefined if customDocumentTypeId is set
            // Validation that one of documentType or customDocumentTypeId is required is done below
            return true;
          }
          const entityType = get(
            this?.options?.context,
            'entityType',
            EntityTypeEnum.company,
          ) as EntityTypeEnum;
          const customDocumentTypes = get(
            this?.options?.context,
            'customDocumentTypes',
            [],
          ) as CustomDocumentTypeModel[];
          const customDocumentTypeKeys = customDocumentTypes
            .filter((cdt: CustomDocumentTypeModel) =>
              cdt.targetEntities.includes(entityType),
            )
            .map((cdt: CustomDocumentTypeModel) => cdt.key);

          const defaultDocumentTypes =
            entityType === EntityTypeEnum.company
              ? companyDocumentTypes
              : individualDocumentTypes;
          const availableDocumentType = [
            null,
            ...defaultDocumentTypes,
            ...customDocumentTypeKeys,
          ];

          const { createError, path } = this;

          return (
            availableDocumentType.includes(value) ||
            createError({
              path,
              message: `${path} must be one of the following values: null, ${defaultDocumentTypes.join(', ')} or custom document type key for ${entityType}`,
            })
          );
        })
        .when((value, schema) => {
          if (legacyDocumentTypeValidation)
            return schema.default(null).nullable();
          return schema.required();
        }),
      documentType: yup
        .mixed()
        .when('$entityType', {
          is: EntityTypeEnum.individual,
          then: (schema) =>
            schema.test('individual-document-type', function (value) {
              if (!value) {
                // @NOTE accept null and undefined if customDocumentTypeId is set
                // Validation that one of documentType or customDocumentTypeId is required is done below
                return true;
              }
              const availableDocumentType = [null, ...individualDocumentTypes];

              const { createError, path } = this;

              return (
                availableDocumentType.includes(value) ||
                createError({
                  path,
                  message: `${path} must be one of the following values: null, ${individualDocumentTypes.join(', ')}`,
                })
              );
            }),
        })
        .when('$entityType', {
          is: EntityTypeEnum.company,
          then: (schema) =>
            schema.test('company-document-type-key', function (value) {
              if (!value) {
                // @NOTE accept null and undefined if customDocumentTypeId is set
                // Validation that one of documentType or customDocumentTypeId is required is done below
                return true;
              }

              const availableDocumentType = [null, ...companyDocumentTypes];

              const { createError, path } = this;

              return (
                availableDocumentType.includes(value) ||
                createError({
                  path,
                  message: `${path} must be one of the following values: null, ${companyDocumentTypes.join(', ')}`,
                })
              );
            }),
        })
        .default(null)
        .nullable(),
      customDocumentTypeId: yup.string().uuid().default(null).nullable(),
      documentAnalysis: yup
        .object()
        .shape({
          automaticApproval: yup.boolean().default(false).optional(),
          automaticRejection: yup.boolean().default(false).optional(),
          parameters: yup
            .mixed()
            .when('$model', (model: DocumentAnalysisModelEnum, schema) => {
              if (model) {
                const definition = getDocumentAnalysisDefinition(model);

                if (!definition) {
                  throw new ValidationError(`model=${model} does not exist`);
                }

                return schema.concat(definition.validationSchemas.parameters);
              }
              return schema;
            }),
        })
        .default(null)
        .nullable(),
    })
    .when((value, schema) => {
      if (legacyDocumentTypeValidation)
        return schema.exactlyOneOfProperties([
          'documentTypeKey',
          'documentType',
          'customDocumentTypeId',
        ]);
      return schema;
    })

    .noUnknown()
    .defined();
};

export const documentDataSchema = (): yup.SchemaOf<
  Required<DocumentCheckInput['data']>
> => {
  return yup
    .object({
      files: yup
        .array(yup.object({ uploadRef: yup.string().required() }))
        .uniqueItems((i) => i.uploadRef)
        .strict()
        .max(MAX_FILE_PER_DOCUMENT_DATA)
        .required(),
    })
    .noUnknown()
    .defined();
};
