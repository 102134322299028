import {
  Collapse,
  forwardRef,
  HStack,
  useControllableState,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import {
  DraggableProvided,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';

import { Indicator } from '../../feedback/indicator/indicator';
import { Box, BoxProps } from '../../layout/box/box';
import { Spacer } from '../../layout/flex/flex';
import { VStack } from '../../layout/stack/stack';
import { LucideIcon } from '../../media-icon/icon/lucide-icons';
import { Text } from '../../typography/text';
import { useHover } from '../../utils/hooks/use-hover';

export type FieldBoxProps = {
  label: string;
  subLabel?: string;
  icon: string;
  error?: string | null;
  action?: React.ReactNode;
  isOpen?: boolean;
  onToggleOpen?: (isOpen: boolean) => void;
  itemWrapperProps?: {
    draggableProps: DraggableProvidedDraggableProps;
    dragHandleProps: DraggableProvidedDragHandleProps;
    ref: DraggableProvided['innerRef'];
  };
} & BoxProps;

export const FieldBox = forwardRef((props: FieldBoxProps, ref) => {
  const {
    label,
    subLabel,
    icon,
    error,
    action,
    isOpen,
    onToggleOpen,
    children,
    itemWrapperProps,
    ...rest
  } = props;
  const [hoverRef, isHovered] = useHover();

  const [internalIsOpen, setInternalIsOpen] = useControllableState({
    defaultValue: false,
    value: isOpen,
    onChange: onToggleOpen,
  });

  // when isOpen is not undefined and there is no onToggleOpen props,
  // the FieldBox is not openable
  const isNotOpenable = isOpen !== undefined && onToggleOpen === undefined;

  return (
    <Box
      ref={itemWrapperProps?.ref}
      {...itemWrapperProps?.draggableProps}
      // Fix drag position in a drawer
      // https://github.com/atlassian/react-beautiful-dnd/issues/485#issuecomment-754398585
      css={css`
        top: auto !important;
        left: auto !important;
      `}
    >
      <Box
        {...rest}
        ref={(node) => {
          hoverRef.current = node;
          if (typeof ref === 'function') {
            ref?.(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        px={10}
        top="auto !important"
        left="auto !important"
      >
        <HStack
          {...itemWrapperProps?.dragHandleProps}
          alignItems="center"
          py={4}
          spacing={4}
          onClick={() => {
            !isNotOpenable && setInternalIsOpen((v) => !v);
          }}
          cursor={isNotOpenable ? 'default' : 'pointer'}
        >
          <Indicator label={error} status="error">
            <LucideIcon
              name={icon}
              borderRadius="full"
              py={2}
              boxSize={8}
              bg="blue.100"
              color="blue.700"
            />
          </Indicator>
          <VStack alignItems="start" spacing={0}>
            {subLabel && (
              <Text
                flexGrow={1}
                flexShrink={1}
                flexBasis="auto"
                noOfLines={1}
                fontWeight="400"
                color="gray.500"
              >
                {subLabel}
              </Text>
            )}
            <Text
              flexGrow={1}
              flexShrink={1}
              flexBasis="auto"
              noOfLines={1}
              fontWeight="500"
              color="black"
              fontSize="lg"
            >
              {label}
            </Text>
          </VStack>
          {action && (isHovered || internalIsOpen) && (
            <>
              <Spacer />
              {action}
            </>
          )}
        </HStack>
        <Collapse in={internalIsOpen} unmountOnExit>
          <Box paddingLeft={12} paddingBottom={4}>
            {children}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
});
