import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MarkOptional } from 'ts-essentials';

import {
  HStack,
  LucideIcon,
  Text,
  Tooltip,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { humanCase } from '@dotfile/shared/common';
import {
  CHECK_ANALYSIS_RESULT_KEY_SEPARATOR,
  CheckResultEnum,
  ClientPortalAvailableDefaultLanguageEnum,
  documentAnalysisDetailedResultsDefinition,
  isClientPortalAvailableDefaultLanguage,
} from '@dotfile/shared/domain';

import { AnalysisResult, AnalysisResultVariant } from './types';

export interface DocumentAnalysisItemProps {
  analysisResult: MarkOptional<AnalysisResult, 'translation'>;
  variant?: AnalysisResultVariant;
}

/**
 * Cosmetic, uppercase some tokens
 * eg: Mrz -> MRZ
 */
const upperCaseToken = (label: string, tokens = ['mrz', 'id', 'ocr']) => {
  const tokenRegExp = new RegExp(`(^|.*\\s)(${tokens.join('|')})\\s(.*)`, 'i');
  const parts = label.match(tokenRegExp);
  if (parts?.length) {
    const [_fullMatch, start, toUpperCase, ...rest] = parts;
    void _fullMatch; // unused

    return [start, toUpperCase.toUpperCase(), ...rest].join(' ');
  }

  return label;
};

export const AnalysisResultItem = ({
  analysisResult,
  variant = 'console',
}: DocumentAnalysisItemProps) => {
  const isClientPortalVariant = variant === 'client-portal';
  const textProps = {
    color: isClientPortalVariant ? 'black' : undefined,
    fontSize: isClientPortalVariant ? 'sm' : undefined,
  };
  const { t, i18n } = useTranslation();
  const { key, result, description, expected, received, translation } =
    analysisResult;

  const definition = documentAnalysisDetailedResultsDefinition[result];
  const { color, iconName } = definition;
  const label = useMemo(() => {
    const parts = key.split(CHECK_ANALYSIS_RESULT_KEY_SEPARATOR);
    const suffix = parts[parts.length - 1];

    const label = upperCaseToken(humanCase(suffix));

    return label;
  }, [key]);

  const displayExpectedReceived = (
    [CheckResultEnum.rejected, CheckResultEnum.error] as CheckResultEnum[]
  ).includes(result);

  const innerItem = (
    <HStack
      cursor={
        variant === 'client-portal' ? 'unset' : description ? 'help' : 'pointer'
      }
      key={key}
      align="flex-start"
      spacing="2"
    >
      <LucideIcon mt="1" boxSize={4} color={color} name={iconName} />
      {variant === 'client-portal' ? (
        <Text title="" {...textProps}>
          {translation && isClientPortalAvailableDefaultLanguage(i18n.language)
            ? (translation?.[i18n.language]?.description ?? description)
            : description}
        </Text>
      ) : null}

      {variant === 'console' ? (
        <VStack align="flex-start" alignItems="flex-start" spacing={0}>
          <Text title="" {...textProps} noOfLines={1}>
            {label}
            {displayExpectedReceived && received && (
              <>
                .&nbsp;
                <Text as="span" {...textProps}>
                  {t('checks.document.detected', {
                    defaultValue: 'Detected',
                    ns: 'client-portal',
                  })}
                  :&nbsp;
                  <Text {...textProps} as="span" color="blue.700">
                    {received}
                  </Text>
                </Text>
              </>
            )}
          </Text>
          {displayExpectedReceived && expected && (
            <Text {...textProps}>
              {t('checks.document.expected', {
                defaultValue: 'Expected',
                ns: 'client-portal',
              })}
              :&nbsp;
              <Text
                as="span"
                {...textProps}
                color="blue.700"
                fontSize={isClientPortalVariant ? 'sm' : undefined}
              >
                {expected}
              </Text>
            </Text>
          )}
        </VStack>
      ) : null}
    </HStack>
  );

  if (variant === 'console' && description) {
    const label =
      translation?.[i18n.language as ClientPortalAvailableDefaultLanguageEnum]
        ?.description ?? description;
    return <Tooltip label={label}>{innerItem}</Tooltip>;
  }
  return innerItem;
};
