import {
  createContext,
  PropsWithChildren,
  useContext,
  useLayoutEffect,
} from 'react';

import { ApolloProvider } from '@apollo/client';

import { useAuth } from '../features/auth';
import { GraphQLClient, GraphQLClientOptions } from './graphql-client';

type GraphqlProviderProps = PropsWithChildren<{
  // empty
}>;

const GraphQLClientContext = createContext<GraphQLClient | null>(null);

// useGraphQLClient (provided by GraphQLClientContext)
export const useMaybeGraphQLClient = (): GraphQLClient | null =>
  useContext(GraphQLClientContext);
export const useGraphQLClient = (): GraphQLClient => {
  const context = useMaybeGraphQLClient();
  if (!context) {
    throw new Error(
      `value for GraphQLClientContext was not initialized. Make sure the GraphQLClientProvider is set up.`,
    );
  }
  return context;
};

const GraphQLProvider = ({
  graphQLClient,
  children,
}: PropsWithChildren<{
  graphQLClient: GraphQLClient;
}>): JSX.Element => {
  return (
    <ApolloProvider client={graphQLClient.apolloClient}>
      <GraphQLClientContext.Provider value={graphQLClient}>
        {children}
      </GraphQLClientContext.Provider>
    </ApolloProvider>
  );
};

export function createGraphqlClient(
  options: GraphQLClientOptions,
): React.FC<GraphqlProviderProps> {
  const graphQLClient = new GraphQLClient(options);

  function useSyncAuthWithGraphQLClient() {
    const { getToken } = useAuth();

    // using useLayoutEffect effect ensure the effect are run synchronously
    useLayoutEffect(() => {
      // Handle inactivity with the token
      graphQLClient.setAuthToken(getToken());
    }, [getToken]);
  }

  const graphQLProvider: React.FC<GraphqlProviderProps> =
    function GraphQLClientProvider({ children }) {
      useSyncAuthWithGraphQLClient();
      return (
        <GraphQLProvider graphQLClient={graphQLClient}>
          {children}
        </GraphQLProvider>
      );
    };

  return graphQLProvider;
}

export function createGraphqlClientWithoutAuth(
  options: Omit<GraphQLClientOptions, ''>,
): React.FC<GraphqlProviderProps> {
  const graphQLClient = new GraphQLClient(options);

  const graphQLProvider: React.FC<GraphqlProviderProps> =
    function GraphQLClientProvider({ children }) {
      return (
        <GraphQLProvider graphQLClient={graphQLClient}>
          {children}
        </GraphQLProvider>
      );
    };

  return graphQLProvider;
}
