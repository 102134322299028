import { useCallback, useState } from 'react';

import { Spinner } from '../../feedback/spinner/spinner';
import { Box } from '../../layout/box/box';
import { Flex } from '../../layout/flex/flex';
import { Text } from '../../typography/text';
import { Button } from '../button/button';

export type AutoCompleteFormatOptionFn<Option> = (option: Option) => {
  /**
   * Unique identifier of each options
   */
  id: string;

  /**
   * Main label of an option, displayed in bold black
   */
  label: string;
  /**
   * Optional main helper of an option, displayed in parentheses and blue.700
   */
  mainHelper?: string | null;
  /**
   * Optional right helper of an option, display in gray
   */
  rightHelper?: string | null;
};

export type AutoCompleteOptionProps<Option> = {
  option: Option;
  formatOption: AutoCompleteFormatOptionFn<Option>;
  onAutoComplete: (option: Option) => void | Promise<void>;

  hasBrandColor?: boolean;
};

export const AutoCompleteOption = <Option,>({
  option,
  formatOption,
  onAutoComplete,
  hasBrandColor,
}: AutoCompleteOptionProps<Option>): JSX.Element => {
  const { label, mainHelper, rightHelper } = formatOption(option);

  const [isSelecting, setIsSelecting] = useState(false);
  const handleSelect = useCallback(async () => {
    try {
      setIsSelecting(true);
      // onSelect might not return a Promise, Promise.resolve make sure we await a Promise
      await Promise.resolve(onAutoComplete(option));
    } finally {
      setIsSelecting(false);
    }
  }, [option, onAutoComplete, setIsSelecting]);

  return (
    <Button
      variant="link"
      width="full"
      textAlign="left"
      display="block"
      padding="12px 20px"
      size="md"
      _hover={{
        backgroundColor: 'gray.100',
      }}
      onClick={handleSelect}
    >
      <Flex justifyContent="space-between">
        <Box noOfLines={1}>
          <Text as="span" fontWeight="500" color="black">
            {label}
          </Text>
          {mainHelper && (
            <>
              {' '}
              <Text as="span" color={hasBrandColor ? 'gray.800' : 'blue.700'}>
                ({mainHelper})
              </Text>
            </>
          )}
        </Box>
        {isSelecting ? (
          <Spinner
            color="gray.500"
            size="md"
            data-testid="autocomplete-option-spinner"
          />
        ) : (
          rightHelper && <Text color="gray.500">{rightHelper}</Text>
        )}
      </Flex>
    </Button>
  );
};
