import { PropertyMappingTypeEnum } from './property-mapping-type.enum';
import { subEnum } from './sub-enum.helper';

export const ClientPortalFieldMappingTypeEnum = subEnum(
  PropertyMappingTypeEnum,
  ['default', 'custom'],
);

export type ClientPortalFieldMappingTypeEnum =
  (typeof ClientPortalFieldMappingTypeEnum)[keyof typeof ClientPortalFieldMappingTypeEnum];
