export const AnalysisResultCategoryEnum = {
  data_comparison: 'data_comparison',
  image_integrity: 'image_integrity',
  document_expiration: 'document_expiration',
  document_acceptability: 'document_acceptability',
  document_integrity: 'document_integrity',
  fraud: 'fraud',
  other: 'other',
} as const;
export type AnalysisResultCategoryEnum =
  (typeof AnalysisResultCategoryEnum)[keyof typeof AnalysisResultCategoryEnum];
