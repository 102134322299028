import { useCallback } from 'react';

import { useToast } from '../../feedback/toast/use-toast';
import { copyToClipboard } from '../copy-to-clipboard';

type UseCopyToClipBoardOptions = {
  /**
   * Show a confirmation toast after the value has been copied to the clipboard, default `true`
   */
  hasToast?: boolean;

  /**
   * Called after copy
   * @returns
   */
  onCopied?: () => void;
};

/**
 *
 * @param options.hasToast Show a confirmation toast after the value has been copied to the clipboard, default `true`
 * @returns A function to call to trigger to copy
 */
export const useCopyToClipBoard = ({
  hasToast = true,
  onCopied,
}: UseCopyToClipBoardOptions = {}): ((value: string) => Promise<void>) => {
  const toast = useToast();

  return useCallback(
    async (value: string) => {
      // @TODO - E-5010 better error handling in design system
      //  https://linear.app/dotfile/issue/E-5010/error-handling-in-design-system
      await copyToClipboard(value);
      if (hasToast) {
        toast({
          title: 'Copied!',
          description: `'${value}' has been successfully copied`,
          status: 'success',
          duration: 5000,
        });
      }
      onCopied?.();
    },
    [hasToast, onCopied, toast],
  );
};
