import { Plus } from 'lucide-react';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  GroupControl,
  Icon,
} from '@dotfile/frontend/shared/design-system';

import { FilesFormValues } from '../types';
import { FormDocumentItem } from './form-document-item';

type FormDocumentListProps = {
  label?: string;
  maxItem?: number;
  onUpload?: CallableFunction;
};

export const FormDocumentList = ({
  label,
  maxItem,
  onUpload,
}: FormDocumentListProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<FilesFormValues>();

  const {
    fields: fieldArray,
    remove,
    append,
  } = useFieldArray({
    control,
    name: 'files',
  });

  const handleAppendValue = useCallback(() => {
    append({
      uploadRef: '',
    });
  }, [append]);

  return (
    <>
      <GroupControl label={label}>
        {fieldArray?.map((fieldArrayItem, index) => (
          <FormDocumentItem
            key={fieldArrayItem.id}
            index={index}
            remove={remove}
            onUpload={onUpload}
          />
        ))}
      </GroupControl>
      {(!maxItem || fieldArray.length < maxItem) && (
        <Button
          type="button"
          variant="ghost"
          colorScheme="gray"
          leftIcon={<Icon as={Plus} />}
          onClick={handleAppendValue}
        >
          {t('file.add', {
            defaultValue: 'Add a document',
            ns: 'components',
          })}
        </Button>
      )}
    </>
  );
};
