import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { GlobalStyleProps } from '@chakra-ui/theme-tools';

const baseStyle = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c } = props;

  return {
    color: `${c}.500`,
    textDecoration: 'underline',
    _hover: {
      color: `${c}.600`,
    },
    _active: { color: `${c}.700` },
    _visited: {
      color: 'purple.900',
    },
  };
});

const variantBlue500 = defineStyle({
  color: 'blue.500',
  textDecoration: 'underline',
  _hover: {
    color: 'blue.700',
  },
  _active: {
    color: 'blue.700',
  },
  _visited: { color: `blue.800` },
});

const variants = {
  blue: variantBlue500,
};

export const LinkTheme = defineStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    colorScheme: 'gray',
  },
});
