/* eslint-disable no-restricted-imports */
// @NOTE special Client portal use-case here to override Design System theme
// thus we need some imports from Chakra under layer library
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleObject } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/modal.ts
// for default ChakraUI theme
// Modal theme is also used for AlertDialog

const variantDialogWhite: PartsStyleObject<typeof parts> = {
  dialog: {
    padding: 0,
    marginInline: 3,
  },
  header: {
    color: 'primary',
    borderBottomWidth: '1px',
    backgroundColor: 'white',
    paddingBlock: 4,
    paddingInline: 6, // same as body
  },
  closeButton: { top: 3, right: 4, color: 'gray.200' },
  body: { padding: 6 },
  footer: { paddingInline: 4, paddingBlock: 4 },
};

export const ModalTheme: ComponentStyleConfig = {
  variants: {
    // @TODO - E-3622 - Design system: Fix modal theme (dialog-white)
    'dialog-white': variantDialogWhite,
  },
};
