import { uniqBy } from 'lodash';

import { MemberRoleEnum, PermissionAbilityEnum } from '../../../shared/models';
import { basePermissionDefinition } from './permission.definition';
import type { RolePermission } from './type';

const definePermissionDefinitionForRole = (
  role: MemberRoleEnum,
): RolePermission[] =>
  basePermissionDefinition.map((basePermission) => ({
    role,
    action: basePermission.action,
    resource: basePermission.resource,
    // @NOTE: Currently, all system roles have the ability `can` for all permissions
    ability: PermissionAbilityEnum.can,
  }));

export const memberPermissionDefinition = definePermissionDefinitionForRole(
  MemberRoleEnum.member,
);

export const adminPermissionDefinition = definePermissionDefinitionForRole(
  MemberRoleEnum.admin,
);

export const ownerPermissionDefinition = definePermissionDefinitionForRole(
  MemberRoleEnum.owner,
);

export const permissionDefinition = [
  ...memberPermissionDefinition,
  ...adminPermissionDefinition,
  ...ownerPermissionDefinition,
];

// Check uniqueness of role/action/resource
if (
  uniqBy(
    permissionDefinition,
    (definition) =>
      `${definition.role}-${definition.action}-${definition.resource}`,
  ).length !== permissionDefinition.length
) {
  // If this error happen too often, maybe improve the error message to include the duplicate values
  throw new Error(
    'There is duplicate permission definition on the same `role`/`action`/`resource`',
  );
}
