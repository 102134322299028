import { Trans, useTranslation } from 'react-i18next';

import { DeleteDialog } from '@dotfile/frontend/shared/design-system';

import { useAutoSaveForLater, useFormDatastore } from '../shared';

export type RemoveIndividualDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  individual: { index: number; label: string } | null;
};

export const RemoveIndividualDialog = ({
  isOpen,
  onClose,
  individual,
}: RemoveIndividualDialogProps) => {
  const { t } = useTranslation();

  const autoSaveForLater = useAutoSaveForLater();
  const { deleteIndividualData } = useFormDatastore();

  return (
    <DeleteDialog
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      onDelete={async () => {
        if (!individual) return;

        deleteIndividualData(individual.index);

        await autoSaveForLater.run();

        onClose();
      }}
      noCloseCross
      variant="dialog-white"
      header={t('forms.individuals_edit.remove_dialog.title', {
        ns: 'client-portal',
        defaultValue: 'Remove individual',
      })}
      cancelText={t('forms.dialog.remove.cancel', {
        ns: 'client-portal',
        defaultValue: 'No, keep it',
      })}
      deleteText={t('forms.dialog.remove.confirm', {
        ns: 'client-portal',
        defaultValue: 'Yes, remove it',
      })}
      questionText={t('forms.dialog.remove.label', {
        ns: 'client-portal',
        defaultValue: 'Do you confirm the remove?',
      })}
    >
      <Trans
        i18nKey="forms.individuals_edit.remove_dialog.body"
        ns="client-portal"
        defaults="You are about to remove <b>{{label}}</b>"
        tOptions={{
          label: individual?.label ?? 'Individual',
        }}
      />
    </DeleteDialog>
  );
};
