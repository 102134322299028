import { LogOutIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { useAsyncFn, useAuth } from '@dotfile/frontend/shared/common';
import { Icon, IconButton } from '@dotfile/frontend/shared/design-system';

import { useMaybeFormDatastoreApi } from '../../../modules/forms/shared/context/datastore/datastore.context';
import { useRouter } from '../../../routes';

export const LogoutButton = () => {
  const { t } = useTranslation();
  const {
    logout,
    auth: { isAuthenticated },
  } = useAuth();

  // useMaybe because this component could be rendered where there is not FormDataStore provider
  const maybeFormDatastoreApi = useMaybeFormDatastoreApi();

  const router = useRouter();

  const [{ loading }, handleClick] = useAsyncFn(async () => {
    await logout();

    // Force redirect to Login after logout
    // In a timeout to wait for the ValidateRouteGuard to have re-rendered with the up to date auth / formInProgress sate
    setTimeout(() => {
      if (maybeFormDatastoreApi) {
        // Must reset the whole store otherwise it will detect that a form in in progress (from the progress.currentStepKey)
        maybeFormDatastoreApi.getState().reset();
      }

      router.push('Login');
    }, 50);
  }, [logout, maybeFormDatastoreApi]);

  return (
    isAuthenticated && (
      <IconButton
        isLoading={loading}
        onClick={handleClick}
        icon={<Icon as={LogOutIcon} />}
        aria-label={t('logout', {
          ns: 'client-portal',
          defaultValue: 'Log out',
        })}
      />
    )
  );
};
