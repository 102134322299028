import { useMediaQuery as useMediaQueryChakra } from '@chakra-ui/react';

export const useMediaQuery: typeof useMediaQueryChakra = (query, options) =>
  useMediaQueryChakra(query, {
    // We don't use ssr and this ensure there is no flickering & unnecessary re-renders
    // @see https://github.com/chakra-ui/chakra-ui/issues/6452
    ssr: false,
    ...options,
  });

/**
 * Match media query `min-width: 1800px`
 * @returns
 */
export const useIsVeryLargeScreen = (): boolean =>
  useMediaQuery('(min-width: 1800px)')[0];

/**
 * Match media query `min-width: 1480px`
 * @returns
 */
export const useIsLargeScreen = (): boolean =>
  useMediaQuery('(min-width: 1480px)')[0];

/**
 * Match media query `min-width: 1280px`
 * @returns
 */
export const useIsMediumScreen = (): boolean =>
  useMediaQuery('(min-width: 1280px)')[0];

/**
 * Match media query `max-width: 1080px`
 * @returns
 */
export const useIsSmallScreen = (): boolean =>
  useMediaQuery('(max-width: 1080px)')[0];

/**
 * Match media query `max-width: 48em`
 * @returns
 */
export const useIsMobile = (): boolean => useMediaQuery('(max-width: 48em)')[0];
