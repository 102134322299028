import {
  DocumentAnalysisModelEnum,
  DocumentAnalysisProofOfAddressDocumentTypesEnum,
  DocumentAnalysisProofOfAddressParametersModel,
} from '../../../../../../shared/models';
import yup from '../../../../../../utils/yup-extended';

export const documentAnalysisProofOfAddressParametersSchema = (options?: {
  forceDefined?: boolean;
}): yup.SchemaOf<DocumentAnalysisProofOfAddressParametersModel> => {
  const definedOrOptional = options?.forceDefined ? 'defined' : 'optional';

  return yup
    .object({
      model: yup
        .mixed()
        .is([DocumentAnalysisModelEnum.proof_of_address])
        .required(),
      maxAgeInDays: yup.number().min(0)[definedOrOptional]().nullable(),
      authorizedDocuments: yup
        .array()
        .of(
          yup
            .mixed()
            .oneOf(
              Object.values(DocumentAnalysisProofOfAddressDocumentTypesEnum),
            ),
        )
        [definedOrOptional]()
        .min(1) // At least one item or null
        .nullable(),
    })
    .required()
    .strict()
    .noUnknown();
};
