import { AmlMatchStatusEnum } from '../../../shared/models';

type AmlMatchStatusDefinition = {
  label: string;
  color: string;
};

export const amlMatchStatusDefinition: Record<
  AmlMatchStatusEnum,
  AmlMatchStatusDefinition
> = {
  [AmlMatchStatusEnum.no_match]: {
    label: 'No match',
    color: 'green',
  },
  [AmlMatchStatusEnum.potential_match]: {
    label: 'Potential match',
    color: 'orange',
  },
  [AmlMatchStatusEnum.false_positive]: {
    label: 'False positive',
    color: 'gray',
  },
  [AmlMatchStatusEnum.true_positive]: {
    label: 'True positive',
    color: 'red',
  },
} as const;
