import { AmlListTypeEnum } from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { AmlCheckInput } from '../aml-check-input';

export const amlSettingsSchema = (): yup.SchemaOf<
  AmlCheckInput['settings']
> => {
  return yup
    .object()
    .shape({
      fuzziness: yup.number().min(0).max(1).optional(),
      listTypes: yup
        .array(yup.mixed().oneOf(Object.values(AmlListTypeEnum)))
        .uniqueItems()
        .strict()
        .min(1)
        .optional(),
      enableEntityFilter: yup.boolean().optional(),
    })
    .noUnknown()
    .defined();
};
