import { Trans, TransProps } from 'react-i18next';

import styled from '@emotion/styled';

import { Box, BoxProps, Link } from '@dotfile/frontend/shared/design-system';

// Override list styles
const StyledBox = styled(Box)`
  /* Add padding for list in description */
  ul,
  ol {
    padding-left: 1rem;
  }

  /* Make sure empty paragraph are still displayed with content to match
     the rendering in the editor when adding line break with "Enter"
     @see https://linear.app/dotfile/issue/E-4200 */
  p:empty::before {
    content: '';
    display: inline-block;
  }
`;

// Override <a> tag to use our link component
const components: TransProps<string>['components'] = { a: <Link /> };

type FormattedTransProps = {
  i18nKey: string;
  /**
   * Will render nothing if the defaultValue is falsy
   */
  defaultValue: string | null | undefined;
  values?: Record<string, string>;

  ns: string;
} & Omit<BoxProps, 'defaultValue'>;

// @TODO - E-1948 - Shared i18n lib for Console and Client-portal
// Could be moved in a shared lib

export const FormattedTrans = ({
  i18nKey,
  defaultValue,
  values,
  ns,
  ...boxProps
}: FormattedTransProps) => {
  if (!defaultValue) {
    return null;
  }

  return (
    <StyledBox {...boxProps}>
      <Trans // Use Trans component instead of t() because description can contain basic html
        i18nKey={i18nKey}
        values={values}
        defaults={defaultValue}
        components={components}
        ns={ns}
        shouldUnescape // Description HTML is created using tiptap-powered editor that escape HTML entities &, <, >
      />
    </StyledBox>
  );
};
