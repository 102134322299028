import { DocumentAnalysisModelEnum } from '../../../../shared/models';
import { documentAnalysisIBANDefinition } from './iban';
import { documentAnalysisPromptDefinition } from './prompt';
import { documentAnalysisProofOfAddressDefinition } from './proof-of-address';
import { documentAnalysisRegistrationCertificateDefinition } from './registration-certificate';

export const documentAnalysisDefinitionsRecord = {
  [DocumentAnalysisModelEnum.iban]: documentAnalysisIBANDefinition,
  [DocumentAnalysisModelEnum.registration_certificate]:
    documentAnalysisRegistrationCertificateDefinition,
  [DocumentAnalysisModelEnum.proof_of_address]:
    documentAnalysisProofOfAddressDefinition,
  [DocumentAnalysisModelEnum.prompt]: documentAnalysisPromptDefinition,
} as const;

export function getDocumentAnalysisDefinition<
  Model extends DocumentAnalysisModelEnum,
>(model: Model): (typeof documentAnalysisDefinitionsRecord)[Model] {
  return documentAnalysisDefinitionsRecord[model];
}
