import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationDefault = (): JSX.Element => {
  const { gray200, blue500 } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="0 0 508 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1149_59355)">
        <g opacity="0.3">
          <path
            d="M433 1C433 41.8691 400.317 75 360 75L360 1L433 1Z"
            stroke={blue500}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M360 75C441.186 75 507 140.814 507 222L360 222L360 75Z"
            stroke={blue500}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M63 75C63 34.1309 95.6832 1 136 1L136 75L63 75Z"
            stroke={blue500}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M286 75C286 156.186 218.843 222 136 222L136 75L286 75Z"
            stroke={blue500}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M360 75C360 34.1309 326.869 0.999999 286 0.999997L286 75L360 75Z"
            stroke={blue500}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
        </g>
        <g opacity="0.7" filter="url(#filter0_ddd_1149_59355)">
          <rect x="188" y="28" width="256" height="48" rx="10" fill="white" />
          <rect x="290" y="44" width="48" height="16" rx="5" fill={gray200} />
          <rect x="376" y="44" width="48" height="16" rx="5" fill={gray200} />
          <rect x="200" y="44" width="80" height="16" rx="5" fill={gray200} />
          <rect
            x="188.5"
            y="28.5"
            width="256"
            height="48"
            rx="9.5"
            stroke={gray200}
          />
        </g>
        <g filter="url(#filter1_d_2923_10065)">
          <rect x="188" y="92" width="256" height="48" rx="10" fill="white" />
          <rect x="290" y="108" width="48" height="16" rx="5" fill={gray200} />
          <rect x="376" y="108" width="48" height="16" rx="5" fill={gray200} />
          <rect x="200" y="108" width="80" height="16" rx="5" fill={gray200} />
          <rect
            x="188.5"
            y="92.5"
            width="256"
            height="48"
            rx="9.5"
            stroke={gray200}
          />
        </g>
        <g filter="url(#filter2_d_2923_10065)">
          <rect x="188" y="156" width="256" height="48" rx="10" fill="white" />
          <rect x="290" y="172" width="48" height="16" rx="5" fill={gray200} />
          <rect x="376" y="172" width="48" height="16" rx="5" fill={gray200} />
          <rect x="200" y="172" width="80" height="16" rx="5" fill={gray200} />
          <rect
            x="188.5"
            y="156.5"
            width="256"
            height="48"
            rx="9.5"
            stroke={gray200}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2923_10065"
            x="0.0141373"
            y="20.3851"
            width="771.972"
            height="85.9717"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="7.37098" />
            <feGaussianBlur stdDeviation="9.49293" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2923_10065"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2923_10065"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_2923_10065"
            x="0.0141373"
            y="84.3851"
            width="771.972"
            height="85.9717"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="7.37098" />
            <feGaussianBlur stdDeviation="9.49293" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2923_10065"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2923_10065"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_2923_10065"
            x="0.0141373"
            y="148.385"
            width="771.972"
            height="85.9717"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="7.37098" />
            <feGaussianBlur stdDeviation="9.49293" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2923_10065"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2923_10065"
              result="shape"
            />
          </filter>
        </defs>
      </g>
    </svg>
  );
};
