import { useTranslation } from 'react-i18next';

import { Pill } from '@dotfile/frontend/shared/design-system';

export const ShareholderPill = () => {
  const { t } = useTranslation();

  return (
    <Pill>
      {t('common.shareholder', {
        defaultValue: 'Shareholder',
        ns: 'client-portal',
      })}
    </Pill>
  );
};
