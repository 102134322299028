import { getMonth, getYear, isValid } from 'date-fns';
import { upperFirst } from 'lodash';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Ref, SyntheticEvent, useCallback, useMemo } from 'react';
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { Box, Flex, HStack, Icon } from '@chakra-ui/react';

import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { Input } from '../input/input';
import { DropDownDatePicker } from './date-picker-dropdown';
import { OverrideStyle } from './styles';
import { generateYearsData, months } from './utils';

// @TODO - E-4257 - Upgrade react-datepicker

export type DatePickerProps<WithRange extends boolean> = Omit<
  ReactDatePickerProps<WithRange>,
  | 'disabled' // use isReadOnly instead
  | 'startDate'
  | 'endDate'
  | 'selected'
  | 'value'
> & {
  isReadOnly?: boolean;
  years?: number[];
  width?: string;
} & (WithRange extends true
    ? {
        value?: never;
        startDate: Date | null | undefined;
        endDate: Date | null | undefined;
      }
    : {
        value: Date | null | undefined;
        startDate?: never;
        endDate?: never;
      });

/**
 * Based on react-datepicker
 * @see https://github.com/Hacker0x01/react-datepicker
 */
export const DatePicker = forwardRefWithGeneric(
  <WithRange extends boolean = false>(
    props: DatePickerProps<WithRange>,
    ref: Ref<ReactDatePicker<WithRange>>,
  ): JSX.Element => {
    const {
      startDate,
      endDate,
      onChange,
      selectsRange,
      inline = false,
      isReadOnly = false,
      years = generateYearsData(100),
      width = '300px',
      value: selected,
      ...rest
    } = props;

    const { t, i18n } = useTranslation();

    const formatMonth = useMemo(() => {
      const intlDateDateFormat = new Intl.DateTimeFormat(
        i18n.resolvedLanguage,
        {
          month: 'long',
        },
      );
      return (monthNumber: number) =>
        upperFirst(intlDateDateFormat.format(new Date(0, monthNumber)));
    }, [i18n.resolvedLanguage]);

    const formatWeekday = useMemo(() => {
      const intlDateDateFormat = new Intl.DateTimeFormat(
        i18n.resolvedLanguage,
        {
          weekday: 'short',
        },
      );
      return (weekday: string) =>
        upperFirst(
          intlDateDateFormat
            .format(
              new Date(
                0,
                0,
                [
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ].indexOf(weekday),
              ),
            )
            .slice(0, 3),
        );
    }, [i18n.resolvedLanguage]);

    // @NOTE Typing is ugly because of the WithRange generic parameter that condition
    // the type of the first parameter of both onChanges but at least, from an user perspective
    // the typing is ok
    const handleChange = useCallback(
      (
        date: Parameters<DatePickerProps<WithRange>['onChange']>[0],
        e: SyntheticEvent | undefined,
      ) => {
        if (Array.isArray(date) && selectsRange) {
          const updatedDates =
            // When we click two times on the same date in order to unselected it
            date[0]?.getTime() === date[1]?.getTime() ? [null, null] : date;
          onChange(
            updatedDates as Parameters<
              DatePickerProps<WithRange>['onChange']
            >[0],
            e,
          );
        } else {
          onChange(date, e);
        }
      },
      [onChange, selectsRange],
    ) as unknown as DatePickerProps<WithRange>['onChange'];

    const renderCustomHeader = useCallback(
      ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
      }: ReactDatePickerCustomHeaderProps) => (
        <Flex
          data-testid="date-picker"
          justifyContent="space-between"
          paddingBottom={1}
          paddingX="3"
          onClick={(e) => e.preventDefault()}
        >
          <Box color="gray.500">
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <Icon as={ChevronLeft} />
            </button>
          </Box>
          <Box>
            <HStack>
              <DropDownDatePicker
                value={getMonth(date)}
                onChange={changeMonth}
                options={months}
                format={formatMonth}
                w="175px"
              />

              <DropDownDatePicker
                value={getYear(date)}
                onChange={changeYear}
                options={years}
                w="100px"
              />
            </HStack>
          </Box>
          <Box color="gray.500">
            <button onClick={increaseMonth}>
              <Icon as={ChevronRight} />
            </button>
          </Box>
        </Flex>
      ),
      [formatMonth, years],
    );

    return (
      <OverrideStyle
        style={
          inline ? { display: 'flex', justifyContent: 'center' } : { width }
        }
        className={inline ? 'inline' : 'notInline'}
      >
        <ReactDatePicker<WithRange>
          customInput={<Input data-testid="date-picker-input" />}
          placeholderText={t('date_picker.placeholder', {
            defaultValue: 'Enter your date here (dd/mm/yyyy)',
            ns: 'design-system',
          })}
          ref={(element) => {
            // @NOTE: focus will be invoked by form validation
            if (element && 'input' in element && typeof ref === 'function') {
              const input = element['input'] as HTMLInputElement;
              (ref as unknown as (element: HTMLInputElement) => void)(input);
            }
          }}
          locale="en" // Force default "en" local because we translate the week days and months using Intl.DateTimeFormat instead
          formatWeekDay={formatWeekday}
          renderCustomHeader={renderCustomHeader}
          disabled={isReadOnly}
          showMonthDropdown
          showTimeSelect={false}
          showYearDropdown
          inline={inline}
          onChange={handleChange}
          dropdownMode="select"
          startDate={startDate}
          endDate={endDate}
          // If date is invalid, DatePicker will throw "Invalid time value"
          selected={selected && isValid(selected) ? selected : null}
          selectsRange={selectsRange}
          dateFormat="dd/MM/yyyy"
          {...rest}
        />
      </OverrideStyle>
    );
  },
);
