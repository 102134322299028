import { AlertCircleIcon, EditIcon, TrashIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  forwardRef,
  HStack,
  Icon,
  Stack,
  StackProps,
  Text,
  Tooltip,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { FormDatastoreIndividual } from '../shared';
import { IndividualPills } from './individual-pills';

export type IndividualItemProps = {
  index: number;
  individual: FormDatastoreIndividual;
  hasMissingInformation: boolean;
  onEdit: () => void;
  onRemove: () => void;
} & StackProps;

export const IndividualItem = forwardRef(
  (
    {
      hasMissingInformation,
      index,
      individual,
      onEdit,
      onRemove,
      ...stackProps
    }: IndividualItemProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen();

    const isDelegator = !!individual.isDelegator;
    const isExistingSignatory = !!individual.isSignatory && !!individual.id;
    const isExistingBusinessContact =
      individual.isBusinessContact && individual.id;

    const individualFullName = [individual.firstName, individual.lastName]
      .filter(Boolean)
      .join(' ');

    return (
      <Stack
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems={isSmallScreen ? 'stretch' : 'center'}
        justifyContent="space-between"
        p="4"
        spacing="4"
        borderRadius="lg"
        borderWidth="1px"
        borderColor="gray.200"
        ref={ref}
        {...stackProps}
      >
        <VStack alignItems="start">
          <HStack>
            <Text color="black">{individualFullName}</Text>
            {hasMissingInformation && (
              <>
                <Icon color="orange.700" as={AlertCircleIcon} />
                <Text color="orange.700">
                  {t('forms.missing_information', {
                    ns: 'client-portal',
                    defaultValue: 'Missing information',
                  })}
                </Text>
              </>
            )}
          </HStack>
          <IndividualPills individual={individual} />
        </VStack>

        <HStack spacing="4">
          <Button
            leftIcon={<Icon as={EditIcon} />}
            colorScheme="coal"
            variant="ghost"
            onClick={onEdit}
          >
            {t('common.edit', {
              ns: 'client-portal',
              defaultValue: 'Edit',
            })}
          </Button>

          {!isExistingBusinessContact && !isExistingSignatory && (
            <Tooltip
              isDisabled={!isDelegator}
              label={t('forms.individuals_edit.no_remove_for_delegator', {
                defaultValue: "You can't remove a delegator",
                ns: 'client-portal',
              })}
            >
              <Button
                leftIcon={<Icon as={TrashIcon} />}
                colorScheme="red"
                variant="ghost"
                onClick={onRemove}
                isDisabled={isDelegator}
              >
                {t('common.remove', {
                  ns: 'client-portal',
                  defaultValue: 'Remove',
                })}
              </Button>
            </Tooltip>
          )}
        </HStack>
      </Stack>
    );
  },
);
