import { CustomPropertyTypeEnum } from '../../../../shared/models';
import {
  BaseCustomPropertyDefinition,
  booleanCustomPropertyDefinition,
  choicesCustomPropertyDefinition,
  countriesCustomPropertyDefinition,
  dateCustomPropertyDefinition,
  emailCustomPropertyDefinition,
  numericCustomPropertyDefinition,
  phoneNumberCustomPropertyDefinition,
  textCustomPropertyDefinition,
  urlCustomPropertyDefinition,
} from '../definitions';

/**
 * Record of all custom property definitions indexed by CustomPropertyTypeEnum
 * @NOTE Add new CustomPropertyTypeEnum only here and everything should be magic
 */
export const customPropertyDefinitionsRecord = {
  [CustomPropertyTypeEnum.choices]: choicesCustomPropertyDefinition,
  [CustomPropertyTypeEnum.countries]: countriesCustomPropertyDefinition,
  [CustomPropertyTypeEnum.date]: dateCustomPropertyDefinition,
  [CustomPropertyTypeEnum.email]: emailCustomPropertyDefinition,
  [CustomPropertyTypeEnum.numeric]: numericCustomPropertyDefinition,
  [CustomPropertyTypeEnum.phone_number]: phoneNumberCustomPropertyDefinition,
  [CustomPropertyTypeEnum.text]: textCustomPropertyDefinition,
  [CustomPropertyTypeEnum.url]: urlCustomPropertyDefinition,
  [CustomPropertyTypeEnum.boolean]: booleanCustomPropertyDefinition,
} as const satisfies Record<
  CustomPropertyTypeEnum,
  // Must be `any` otherwise there are error in projects that are not in typescript strict mode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  BaseCustomPropertyDefinition<any>
>;

/**
 * All Check definitions type
 */
export type CustomPropertyDefinitionMapping =
  typeof customPropertyDefinitionsRecord;
export type AllCustomPropertyDefinitions =
  (typeof customPropertyDefinitionsRecord)[keyof CustomPropertyDefinitionMapping];

/**
 * Get the custom property definition for CustomPropertyTypeEnum
 * @param type
 * @returns
 */
export function getCustomPropertyDefinition<T extends CustomPropertyTypeEnum>(
  type: T,
): CustomPropertyDefinitionMapping[T] {
  return customPropertyDefinitionsRecord[type];
}
