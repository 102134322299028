import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { createRouter } from '@swan-io/chicane';

import { useEnvironmentContext } from '@dotfile/frontend/shared/common';
import { clientPortalRoutes } from '@dotfile/shared/domain';

import { useClientPortalId } from '../shared';

const __routerOnlyForType = createRouter(clientPortalRoutes);
const RouterContext = createContext<typeof __routerOnlyForType | undefined>(
  undefined,
);

export const useRouter = () => {
  const router = useContext(RouterContext);
  if (!router) {
    throw new Error(
      `value for RouterContext was not initialized. Make sure the RouterProvider is set up.`,
    );
  }

  return router;
};

export const RouterProvider = ({ children }: PropsWithChildren) => {
  const isDefaultDomain =
    useEnvironmentContext().baseApp === window.location.origin;
  const clientPortalId = useClientPortalId();

  const router = useMemo(() => {
    const basePath = isDefaultDomain ? `/${clientPortalId}/` : '';
    return createRouter(clientPortalRoutes, { basePath });
  }, [clientPortalId, isDefaultDomain]);

  return (
    <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
  );
};
