import { get, has } from 'lodash';
import { ValueOf } from 'ts-essentials';
import { match, P } from 'ts-pattern';

import { logAndAddError } from '@dotfile/frontend/shared/common';
import {
  ClientPortalBlockFieldProperty,
  ClientPortalForms_BlockField,
  ClientPortalForms_Step,
} from '@dotfile/shared/data-access-client-portal';
import {
  generateKeyFromMapping,
  PropertyMapping,
  PropertyMappingEntityEnum,
  PropertyMappingTypeEnum,
  PropertyTypeEnum,
  resolveDefaultPropertyFromMapping,
} from '@dotfile/shared/domain';

import {
  FormDatastoreCase,
  FormDatastoreCompany,
  FormDatastoreIndividual,
} from '../context';
import { isPropertyType } from './is-property-type';
import { FieldsValues } from './types';

export type GenerateDefaultValuesFromDataErrorContext = {
  step: ClientPortalForms_Step;
};

export type GenerateDefaultValuesFromDataParam = {
  fields: Pick<ClientPortalForms_BlockField, 'mapping' | 'property'>[];
  data: {
    case: FormDatastoreCase | undefined;
    company?: FormDatastoreCompany;
    individual?: FormDatastoreIndividual;
  };
  errorContext: GenerateDefaultValuesFromDataErrorContext;
};

export const generateDefaultValuesFromData = ({
  fields,
  data,
  errorContext,
}: GenerateDefaultValuesFromDataParam): FieldsValues => {
  const defaultValues: FieldsValues = {};

  let _errorContext: Record<string, unknown> = { ...errorContext };
  for (const field of fields) {
    try {
      const { property, mapping } = field;
      if (!property) {
        // Fields with unresolved property are handled else-where
        continue;
      }

      _errorContext = { ..._errorContext, field };

      const entityData = match(mapping.entity)
        .with(PropertyMappingEntityEnum.case, () => data.case)
        .with(PropertyMappingEntityEnum.company, () => data.company)
        .with(PropertyMappingEntityEnum.individual, () => data.individual)
        .exhaustive();

      const propertyPathInEntityData =
        mapping.type === PropertyMappingTypeEnum.custom
          ? `customProperties.${mapping.key}`
          : // mapping type = default
            resolveDefaultPropertyFromMapping(mapping).propertyName;

      const value = has(entityData, propertyPathInEntityData)
        ? parseInitialValue(
            mapping,
            property,
            get(entityData, propertyPathInEntityData),
          )
        : defaultValueForType(property);

      const key = generateKeyFromMapping(mapping);
      defaultValues[key] = value;
    } catch (error) {
      logAndAddError(error, _errorContext);
    }
  }

  return defaultValues;
};

const defaultValueForType = (
  property: ClientPortalBlockFieldProperty,
): ValueOf<FieldsValues> => {
  const defaultValue = match(property)
    .with(P.when(isPropertyType(PropertyTypeEnum.address)), () => ({
      streetAddress: '',
      streetAddress2: '',
      city: '',
      postalCode: '',
      state: '',
      country: null,
    }))

    .with(P.when(isPropertyType(PropertyTypeEnum.banking_information)), () => ({
      iban: '',
      bic: '',
    }))

    .with(P.when(isPropertyType(PropertyTypeEnum.boolean)), () => null)

    .with(P.when(isPropertyType(PropertyTypeEnum.choices)), () => null)

    .with(P.when(isPropertyType(PropertyTypeEnum.classifications)), () => [])

    .with(P.when(isPropertyType(PropertyTypeEnum.countries)), () => null)

    .with(P.when(isPropertyType(PropertyTypeEnum.date)), () => null)

    .with(P.when(isPropertyType(PropertyTypeEnum.email)), () => '')

    .with(P.when(isPropertyType(PropertyTypeEnum.entity_legal_form)), () => '')

    .with(P.when(isPropertyType(PropertyTypeEnum.numeric)), () => null)

    .with(P.when(isPropertyType(PropertyTypeEnum.phone_number)), () => '')

    .with(P.when(isPropertyType(PropertyTypeEnum.text)), () => '')

    .with(P.when(isPropertyType(PropertyTypeEnum.url)), () => '')

    .otherwise(() => {
      throw new Error(
        `Default value for property type ${property.type} not implemented`,
      );
    });

  return defaultValue;
};

const parseInitialValue = (
  mapping: PropertyMapping,
  property: ClientPortalBlockFieldProperty,
  rawValue: ValueOf<FieldsValues>,
): ValueOf<FieldsValues> => {
  const defaultValue = match(property)
    .with(P.when(isPropertyType(PropertyTypeEnum.address)), () => ({
      // Make sure each text field default form value is a string if it was undefined/null in company data fetch
      streetAddress: get(rawValue, 'streetAddress') ?? '',
      streetAddress2: get(rawValue, 'streetAddress2') ?? '',
      city: get(rawValue, 'city') ?? '',
      postalCode: get(rawValue, 'postalCode') ?? '',
      state: get(rawValue, 'state') ?? '',
      country: get(rawValue, 'country') || null,
    }))

    .with(P.when(isPropertyType(PropertyTypeEnum.banking_information)), () => ({
      // Make sure each text field default form value is a string if it was undefined/null in company data fetch
      iban: get(rawValue, 'iban') ?? '',
      bic: get(rawValue, 'bic') ?? '',
    }))

    .with(P.when(isPropertyType(PropertyTypeEnum.boolean)), () =>
      mapping.type === PropertyMappingTypeEnum.custom &&
      typeof rawValue === 'string'
        ? // @NOTE match the implementation of parseValue in custom properties definition
          rawValue === 'true'
        : rawValue,
    )

    .with(P.when(isPropertyType(PropertyTypeEnum.numeric)), () =>
      mapping.type === PropertyMappingTypeEnum.custom &&
      typeof rawValue === 'string'
        ? // @NOTE match the implementation of parseValue in custom properties definition
          parseFloat(rawValue)
        : rawValue,
    )

    .otherwise(() => rawValue);

  return defaultValue;
};
