import { LucideIcon } from 'lucide-react';
import { AnySchema, SchemaOf } from 'yup';

import { CustomPropertyTypeEnum } from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import {
  CustomPropertyFormatValueOptions,
  CustomPropertyFormatValueParam,
  CustomPropertyValue,
  CustomPropertyValueSchemaParam,
} from './type';

export abstract class BaseCustomPropertyDefinition<TSettings = null> {
  // General
  // -------

  /**
   * Unique CustomProperty identifier.
   */
  abstract get type(): CustomPropertyTypeEnum;

  /**
   * Default label (in english) for this Custom Property type.
   */
  abstract get label(): string;

  /**
   * Lucide icon component
   * @see https://lucide.dev/icons/
   */
  abstract get icon(): LucideIcon;

  /**
   * Determine wether this CustomProperty type use option or not.
   *
   * @default false
   */
  get hasOptions(): boolean {
    return false;
  }

  // Settings
  // --------

  /**
   * Default / initial settings for this CustomProperty, for instance to initialize a form.
   */
  get defaultSettings(): TSettings {
    return null as TSettings;
  }

  /**
   * Validation schema for the `settings` of this CustomProperty.
   *
   * @returns The schema
   * @default Schema that only accept `null` settings
   */
  public settingsSchema(): SchemaOf<TSettings> {
    const schema = yup
      .mixed()
      .nullable()
      .default(null)
      .equals([null], '${path} must be null') as SchemaOf<TSettings>;

    return schema;
  }

  // Value
  // -----

  /**
   * Default / initial value for the CustomPropertyValue, for instance to initialize a form.
   *
   * @default null
   */
  get defaultValue(): CustomPropertyValue | null {
    return null;
  }

  /**
   * Generate a Yup validation schema for this CustomProperty
   *
   * @returns The schema
   */
  public abstract valueSchema(
    customProperty: CustomPropertyValueSchemaParam,
  ): AnySchema;

  /**
   * Indicate wether a property can have multiple value (in an array) for the same entity
   *
   * @default false
   */
  get multiValues(): boolean {
    return false;
  }

  /**
   * Format the value of a CustomPropertyValue of this type to be displayed to
   * an human.
   *
   * By default return the `value.value`.
   *
   * @param value The CustomPropertyValue with the CustomProperty relation
   * @param options
   */
  public formatValue(
    value: CustomPropertyFormatValueParam,
    options?: CustomPropertyFormatValueOptions,
  ): string {
    void options; // unused in default implementation

    return value.value;
  }

  /**
   * Parse the raw string value of a Custom property into a more appropriate type
   * to return in External-API or to initialize a form.
   *
   * For instance `yes_no` parse to `boolean`.
   *
   * By default returns the raw value.
   *
   * @param rawValue
   */
  public parseValue(rawValue: string): CustomPropertyValue {
    return rawValue;
  }

  /**
   * Stringify a CustomProperty value that comes from a more specific Javascript type
   * (like `boolean` for `yes_no`)
   *
   * By default transform into a string by putting it into a string template using backticks
   *
   * @param value
   */
  public stringifyValue(value: CustomPropertyValue): string {
    return `${value}`;
  }
}
