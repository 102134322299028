import { useCallback, useEffect, useState } from 'react';

import { NumberInputProps as ChakraNumberInputProps } from '@chakra-ui/react';

type NumberInputValue = number | string | null;

export type UseNumberInputValueParam = {
  value?: NumberInputValue;
  onChange?: (value: NumberInputValue) => void;
};

type UseNumberInputValueReturn = [
  ChakraNumberInputProps['value'],
  ChakraNumberInputProps['onChange'],
];

export const useNumberInputValue = ({
  value: propsValue,
  onChange,
}: UseNumberInputValueParam): UseNumberInputValueReturn => {
  const [internal, setInternal] = useState<{
    /**
     * Keep the string value as the internal value to keep every character typed by the user even
     * when it is not a completed number ('-' before typing '-1) or the number as string is shorter
     * ('1.0' before typing '1.01')
     */
    value?: NumberInputValue;
    /**
     * Keep the last onChange value to decide if the internal state should be reset when the value props
     * change to a value other that is different than the last onChange
     */
    lastOnChange?: NumberInputValue;
  }>({ value: propsValue });

  const handleChange: ChakraNumberInputProps['onChange'] = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      const indexOfFirstDot = valueAsString.indexOf('.');
      const cleanedValueAsString =
        indexOfFirstDot === -1
          ? valueAsString
          : // Only allow to type at most one `.`
            valueAsString.slice(0, indexOfFirstDot + 1) +
            valueAsString.slice(indexOfFirstDot + 1).replaceAll('.', '');

      let onChangeValue: NumberInputValue;

      if (!cleanedValueAsString) {
        onChangeValue = null;
      } else if (isNaN(valueAsNumber)) {
        // This happen when the user has only type a negative sign so onChange with the string to raise a
        // validation error and "force" the user continue to type his number
        onChangeValue = cleanedValueAsString;
      } else {
        onChangeValue = valueAsNumber;
      }

      onChange?.(onChangeValue);

      setInternal({
        value: cleanedValueAsString,
        lastOnChange: onChangeValue,
      });
    },
    [onChange],
  );

  useEffect(() => {
    setInternal((prev) => ({
      ...prev,
      value:
        prev.lastOnChange === propsValue
          ? // keep the same internal value when the propsValue has change but it the same as the last onChange call
            prev.value
          : propsValue,
    }));
  }, [propsValue]);

  return [
    internal.value === undefined
      ? // Keep undefined for uncontrolled mode
        undefined
      : (internal.value ?? ''),
    handleChange,
  ];
};
