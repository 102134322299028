import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';

import {
  NetworkStatus,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { TableState } from '@tanstack/react-table';

import { useHandleError } from '../../error-handler';
import {
  TableInstance,
  tableToAggregateGqlVariables,
  TAggregateQuery,
  TAggregateVariables,
  TBase,
} from '../helpers';
import { TableProps } from '../types';
import { AggregateContextValue } from './use-aggregate-context';

export type UseAggregateQuery<T extends TBase> = (
  baseOptions?: QueryHookOptions<TAggregateQuery<T>, TAggregateVariables<T>>,
) => QueryResult<TAggregateQuery<T>, OperationVariables>;

export const useAggregateQueryStub: AggregateContextValue = {
  isLoading: false,
  data: {},
  _isStubContext: true,
};

const queryStub = {
  variables: {},
  data: {},
  error: undefined,
  networkStatus: NetworkStatus.ready,
  loading: false,
  refetch: () => {
    /* Do nothing*/
  },
};

export const useGetAggregate = <T extends TBase>(
  {
    search,
    useAggregateQuery,
  }: Pick<TableProps<T>, 'search' | 'useAggregateQuery'>,
  tableState: Pick<TableState, 'columnFilters' | 'globalFilter'>,
  tableInstance: Pick<TableInstance<T>, 'getAllColumns' | 'getColumn'>,
): AggregateContextValue => {
  const handleError = useHandleError();

  const query =
    useAggregateQuery?.({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: tableToAggregateGqlVariables(
        {
          columnFilters: tableState.columnFilters,
          globalFilter: tableState.globalFilter,
        },
        tableInstance,
        search,
      ),
      onError: (error) => {
        handleError({ error, title: 'Could not load aggregate data' });
      },
    }) ?? queryStub;

  useEffect(() => {
    if (!useAggregateQuery) {
      return;
    }
    const nextVariables = tableToAggregateGqlVariables(
      {
        columnFilters: tableState.columnFilters,
        globalFilter: tableState.globalFilter,
      },
      tableInstance,
      search,
    );
    if (!isEqual(query.variables, nextVariables) && !query.loading) {
      query.refetch(nextVariables);
    }
  }, [search, useAggregateQuery, query, tableInstance, tableState]);

  const { networkStatus, data, error } = query;
  const context: AggregateContextValue = useMemo(() => {
    return {
      isLoading:
        // set loading status for first load or when variable
        // (like filter) changes
        networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.setVariables,
      data: data ?? {},
      error,
      _isStubContext: false,
    };
  }, [networkStatus, data, error]);

  return context;
};
