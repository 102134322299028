import i18n, { type i18n as i18nType, InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { upperFirst } from 'lodash';
import { match, P } from 'ts-pattern';

import { HandleErrorFn } from '@dotfile/frontend/shared/components';
import {
  CLIENT_PORTAL_AVAILABLE_DEFAULT_LANGUAGES,
  fileTargetDefinition,
  LanguageEnum,
} from '@dotfile/shared/domain';

import { environment } from '../../../../environments/environment';

export type InitI18nextParam = Partial<
  Pick<InitOptions, 'fallbackLng' | 'supportedLngs'>
> & {
  ids: {
    workspaceId: string;
    clientPortalId: string;
    versionId: string;
  } | null;
  handleError: HandleErrorFn;
};

export const initI18next = ({
  fallbackLng,
  supportedLngs,
  ids,
  handleError,
}: InitI18nextParam): i18nType => {
  const initOptions: InitOptions = {
    fallbackLng: fallbackLng ?? LanguageEnum.en,
    supportedLngs: supportedLngs ?? CLIENT_PORTAL_AVAILABLE_DEFAULT_LANGUAGES,

    ns: [
      'client-portal', // static translation embedded in the client-portal-app codebase
      'design-system', // static translation embedded in the design-system lib codebase
      'components', // static translation embedded in the components lib codebase
      'domain', // static translation embedded in the domain lib codebase
      ...(ids
        ? // Only declare 'dynamic' namespace when ids are available to load it
          [
            'dynamic', // dynamic translation edited in the ClientPortal builder in the Console
          ]
        : []),
    ],
    defaultNS: ids ? 'dynamic' : 'client-portal',

    debug:
      !environment.production &&
      process.env['NX_REACT_I18NEXT_DEVTOOL_ENABLE'] === 'true',

    react: {
      transKeepBasicHtmlNodesFor: [
        'p',
        'br',
        'b',
        'i',
        'ul',
        'ol',
        'li',
        // 'a' are not included there because they must be enabled manually with a component to be able to keep the props
      ],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },

    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      // cache user language
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
  };

  const fetchResources = async (url: string) => {
    const res = await fetch(url);

    if (!res.ok) {
      // statusText can be empty so use status instead
      throw new Error(res.status.toString());
    }

    return res.json();
  };

  const i18next = i18n
    .createInstance()
    // Do not use initReactI18next because the different instances (default and built from settings)
    // will be bind manually using a provider

    // Language detector: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector);

  i18next.use({
    // custom backend to handle all the loading logic for each namespace and mode
    // inspired from https://gist.github.com/SimeonC/6a738467c691eef7f21ebf96918cd95f
    type: 'backend',
    read: async (
      language: string,
      namespace:
        | 'client-portal'
        | 'design-system'
        | 'components'
        | 'domain'
        | 'dynamic',
      callback: (error: unknown, translations: null | unknown) => void,
    ) => {
      try {
        const bundle = await match({
          namespace,
          production: environment.production,
        })
          .with(
            { namespace: 'client-portal', production: false },
            () =>
              import(
                `../../../../assets/locales/${language}_client-portal.json`
              ),
          )
          .with(
            { namespace: 'design-system', production: false },
            () =>
              import(
                `../../../../../../../../libs/frontend/shared/design-system/src/assets/locales/${language}_design-system.json`
              ),
          )
          .with(
            { namespace: 'components', production: false },
            () =>
              import(
                `../../../../../../../../libs/frontend/shared/components/src/assets/locales/${language}_components.json`
              ),
          )
          .with(
            { namespace: 'domain', production: false },
            () =>
              import(
                `../../../../../../../../libs/shared/domain/src/assets/locales/${language}_domain.json`
              ),
          )
          .with(
            {
              namespace: P.union(
                'client-portal',
                'design-system',
                'components',
                'domain',
              ),
              production: true,
            },
            async () => {
              // On build, each translations are copied there
              const url = `assets/locales/${language}_${namespace}.json`;

              return fetchResources(url);
            },
          )
          .with({ namespace: 'dynamic', production: P.any }, () => {
            if (!ids) {
              throw new Error('No ids available to load dynamic translation');
            }

            const fileName = `/${language}.json`;
            const url =
              environment.baseMainStorage +
              fileTargetDefinition.client_portal_translation.path(ids) +
              fileName;

            return fetchResources(url);
          })
          .exhaustive();

        callback(null, bundle);
      } catch (error) {
        handleError({
          title: `Unable to load namespace '${namespace}' for language '${language}'`,
          error,
        });

        callback(error, null);
      }
    },
  });

  i18next.init(initOptions);

  i18next.services.formatter?.add('upperFirst', (value, lng, options) => {
    return upperFirst(value);
  });

  return i18next;
};
