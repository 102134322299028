import { RiskLevelEnum, RiskLevelScaleEnum } from '../../../shared/models';

export const getRiskLevels = (
  scale: RiskLevelScaleEnum,
  excludeNotDefined = false,
): RiskLevelEnum[] => {
  let levels = Object.values(RiskLevelEnum);

  if (scale === RiskLevelScaleEnum.four_levels) {
    levels = Object.values(RiskLevelEnum).filter(
      (l) => l !== RiskLevelEnum.critical,
    );
  }

  if (excludeNotDefined) {
    levels = levels.filter((r) => r !== RiskLevelEnum.not_defined);
  }

  return levels;
};

export const riskLevelDefinition: Record<
  RiskLevelEnum,
  {
    label: string;
    colorScheme: (levelScale?: RiskLevelScaleEnum) => string;
    level: number;
  }
> = {
  [RiskLevelEnum.not_defined]: {
    label: 'Not defined',
    colorScheme: () => 'gray',
    level: 0,
  },
  [RiskLevelEnum.low]: {
    label: 'Low',
    colorScheme: () => 'green',
    level: 1,
  },
  [RiskLevelEnum.medium]: {
    label: 'Medium',
    colorScheme: (levelScale) =>
      levelScale === RiskLevelScaleEnum.four_levels ? 'orange' : 'yellow',
    level: 2,
  },
  [RiskLevelEnum.high]: {
    label: 'High',
    colorScheme: (levelScale) =>
      levelScale === RiskLevelScaleEnum.four_levels ? 'red' : 'orange',
    level: 3,
  },
  [RiskLevelEnum.critical]: {
    label: 'Critical',
    colorScheme: () => 'red',
    level: 4,
  },
  [RiskLevelEnum.prohibited]: {
    colorScheme: () => 'black',
    label: 'Prohibited',
    level: 4,
  },
};
