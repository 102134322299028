import { generateSwatch } from './generate-swatch';
import { ButtonTheme } from './override/button.theme';
import { ModalTheme } from './override/modal.theme';
import { RadioButtonTheme } from './override/radio-button.theme';

/**
 * Return base theme extension to use when custom color is dynamically set
 * @param custom
 * @returns
 */
export function customExtension(custom: string) {
  return {
    colors: {
      custom: generateSwatch(custom),
    },
    components: {
      Modal: {
        ...ModalTheme,
        defaultProps: {
          variant: 'dialog-white',
        },
      },
      Button: {
        ...ButtonTheme,
        defaultProps: {
          colorScheme: 'custom',
        },
      },
      Checkbox: {
        parts: ['control'],
        variants: {
          // The variant can be used for multi-line option labels, to avoid the control being centered horizontally
          atTop: {
            control: {
              alignSelf: 'start',
              mt: 'calc(var(--chakra-space-0-5) + 1px)',
            },
          },
        },
      },
      Radio: {
        parts: ['control'],
        variants: {
          // The variant can be used for multi-line option labels, to avoid the control being centered horizontally
          atTop: {
            control: {
              alignSelf: 'start',
              mt: 'calc(var(--chakra-space-0-5) + 1px)',
            },
          },
        },
      },
      Link: {
        defaultProps: {
          colorScheme: 'custom',
        },
      },
      Progress: {
        defaultProps: {
          colorScheme: 'custom',
        },
      },
      RadioButton: {
        ...RadioButtonTheme,
        defaultProps: {
          colorScheme: 'custom',
        },
      },
    },
  };
}
