import {
  AddressModel,
  ViewColumnMappingEntityEnum,
} from '../../../shared/models';
import { getAddressDefaultPropertiesDefinition } from '../../../shared/properties';
import { ViewColumn } from './types';
import { ViewColumnBuilder } from './view-column-builder';

type AddressViewColumn = AddressModel & {
  checks: unknown;
};
type AddressViewColumnKey = keyof AddressModel;

export const getAddressViewColumnDefinition = (
  entity: ViewColumnMappingEntityEnum,
): Record<AddressViewColumnKey, ViewColumn> => {
  if (entity === ViewColumnMappingEntityEnum.check) {
    // getAddressDefaultPropertiesDefinition use PropertyMappingTypeEnum which does not contains check
    throw new Error(
      'check entity not supported for getAddressViewColumnDefinition',
    );
  }

  const addressPropertiesDefinition =
    getAddressDefaultPropertiesDefinition(entity);

  return new ViewColumnBuilder<AddressViewColumn>(entity)
    .addViewColumn('city', addressPropertiesDefinition.city, {
      canSort: false,
    })
    .addViewColumn('country', addressPropertiesDefinition.country, {
      canSort: false,
    })
    .addViewColumn('postalCode', addressPropertiesDefinition.postalCode, {
      canSort: false,
    })
    .addViewColumn('region', addressPropertiesDefinition.region, {
      canSort: false,
    })
    .addViewColumn('state', addressPropertiesDefinition.state, {
      canSort: false,
    })
    .addViewColumn('streetAddress', addressPropertiesDefinition.streetAddress, {
      canSort: false,
    })
    .addViewColumn(
      'streetAddress2',
      addressPropertiesDefinition.streetAddress2,
      {
        canSort: false,
      },
    )
    .getDefinition();
};
