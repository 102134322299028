import {
  Portal as ChakraPortal,
  PortalProps as ChakraPortalProps,
  useModalContext,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/components/portal
export type PortalProps = ChakraPortalProps;

export const Portal = (props: ChakraPortalProps): JSX.Element => {
  try {
    // Fix positioning of Portal inside modal
    // @see https://github.com/chakra-ui/chakra-ui/issues/6794#issuecomment-1613474576
    const modalContext = useModalContext();
    return <ChakraPortal containerRef={modalContext?.dialogRef} {...props} />;
  } catch {
    return <ChakraPortal {...props} />;
  }
};
