import {
  IdVerificationDataModeEnum,
  IdVerificationVendorEnum,
} from '../../../shared/models';
import { idVerificationModeDefinition } from './id-verification-mode-definition';

type IdVerificationVendorDefinition = {
  [key in IdVerificationVendorEnum]: {
    label: string;
    availableModes: IdVerificationDataModeEnum[];
  };
};

// @NOTE avoids data dupplication
export const idVerificationVendorDefinition: IdVerificationVendorDefinition =
  Object.keys(idVerificationModeDefinition).reduce((acc, vendor) => {
    const vendorEnum = vendor as IdVerificationVendorEnum;
    const modes = idVerificationModeDefinition[vendorEnum];
    const availableModeKeys = Object.keys(
      modes,
    ) as IdVerificationDataModeEnum[];
    const firstModeLabel =
      availableModeKeys.length > 0
        ? (modes[availableModeKeys[0]]?.label ?? '')
        : '';

    acc[vendorEnum] = {
      label: firstModeLabel,
      availableModes: availableModeKeys,
    };

    return acc;
  }, {} as IdVerificationVendorDefinition);
