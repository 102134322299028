import type { Translator } from 'short-uuid';

import {
  API_KEY_SECRET_PREFIX,
  API_KEY_TOKEN_SEPARATOR,
} from './generate-api-key-secret';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const short = require('short-uuid');

export const translator: Translator = short(short.constants.flickrBase58);

interface ApiKeyTokenParts {
  /**
   * The api key secret (including prefix)
   * @see libs/shared/models/domain/src/lib/features/api-key/generate-api-key-secret.ts
   */
  secret: string;
  id: string;
}

/**
 * Build the full api token from the secret and id
 * @param {ApiKeyTokenParts} param The api key secret (including prefix) and id
 * @returns {string}
 */
export function buildApiKeyToken({ secret, id }: ApiKeyTokenParts): string {
  const token = secret + API_KEY_TOKEN_SEPARATOR + translator.fromUUID(id);

  return token;
}

/**
 * Parse the secret and api key id from a api key token
 * @param {string} token
 * @returns {ApiKeyTokenParts}
 * @throws {Error} When the token has not the expected number of separator or on any other error
 */
export function parseApiKeyToken(token: string): ApiKeyTokenParts {
  const splitted = token.split(API_KEY_TOKEN_SEPARATOR);

  if (splitted.length !== 3) {
    throw new Error(`Expected 3 parts but received ${splitted.length}`);
  }

  const [secretPrefix, secretValue, translatedId] = splitted;

  const fullPrefix = `${secretPrefix}${API_KEY_TOKEN_SEPARATOR}`;
  if (fullPrefix !== API_KEY_SECRET_PREFIX) {
    throw new Error(
      `Invalid prefix, expected '${API_KEY_SECRET_PREFIX}' but received '${fullPrefix}'`,
    );
  }
  const secret = secretPrefix + API_KEY_TOKEN_SEPARATOR + secretValue;

  if (!translator.validate(translatedId)) {
    throw new Error('Invalid id');
  }
  const id = translator.toUUID(translatedId);

  return { secret, id };
}
