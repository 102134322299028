import { ComponentStyleConfig } from '@chakra-ui/theme';

import {
  variantFilled,
  variantFlushed,
  variantInline,
  variantOutline,
} from '../input/input.theme';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/textarea.ts
// for default ChakraUI theme
export const TextareaTheme: ComponentStyleConfig = {
  variants: {
    inline: (props) => variantInline(props)['field'] ?? {},
    outline: (props) => variantOutline(props)['field'] ?? {},
    filled: (props) => variantFilled(props)['field'] ?? {},
    flushed: (props) => variantFlushed(props)['field'] ?? {},
  },
};
