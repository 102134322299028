import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { logAndAddError } from '@dotfile/frontend/shared/common';

export const UbbleCheckoutSdkContext = createContext({
  loaded: false,
});

// Add global onUbbleReady on the window
interface WindowWithUbble extends Window {
  onUbbleReady?: () => void;
}
declare let window: WindowWithUbble;

export const UbbleCheckoutSdkProvider = ({ children }: PropsWithChildren) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    window.onUbbleReady = () => {
      setLoaded(true);
    };
    let node: HTMLScriptElement;
    try {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'application/javascript';
      scriptElement.id = 'ubble-sdk-js';
      scriptElement.src = 'https://cdn.ubble.ai/iframe-sdk-1.0.0.js';

      node = document.head.appendChild(scriptElement);
    } catch (error) {
      logAndAddError('Error appending ubble-sdk script', { error });
    }

    // Remove script
    return () => {
      if ('onUbbleReady' in window) delete window.onUbbleReady;
      node?.remove();
    };
  }, []);

  const value = useMemo(() => ({ loaded }), [loaded]);

  return (
    <UbbleCheckoutSdkContext.Provider value={value}>
      {children}
    </UbbleCheckoutSdkContext.Provider>
  );
};
