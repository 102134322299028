import { useCallback, useMemo, useState } from 'react';

import { createUseDebounce } from '@dotfile/frontend/shared/common';

import { Button } from '../../../form/button/button';
import { Spacer } from '../../../layout/flex/flex';
import { HStack, VStack } from '../../../layout/stack/stack';
import { Text } from '../../../typography/text';
import {
  MultiSelectList,
  MultiSelectListOptions,
} from './multi-select-filter-list';
import { MultiSelectOperator } from './multi-select-operator';
import { MultiSelectSearch } from './multi-select-search';
import { FilterOperatorEnum } from './type';

export type MultiSelectFilterOptions = MultiSelectListOptions;

export type FilterValues<
  T extends string | number | boolean | Date | null =
    | string
    | number
    | boolean
    | Date
    | null,
> = {
  values: T[];
  operator?: FilterOperatorEnum;
};

export type MultiSelectFilterProps = {
  filters: FilterValues<string> | null;
  setFilters: (value: string[] | null) => void;
  setOperator: (operator: FilterOperatorEnum) => void;
  options: MultiSelectFilterOptions[];
  withOperator?: boolean;
};

const useDebounce = createUseDebounce<[string[] | null]>(500, {
  flushOnUnmount: true,
});

export const MultiSelectFilter = ({
  filters: tableFilters,
  setFilters: setTableFilters,
  setOperator: setTableOperator,
  options,
  withOperator,
}: MultiSelectFilterProps): JSX.Element => {
  const [search, setSearch] = useState('');
  const filteredOptions = useMemo(
    () =>
      !search
        ? options
        : options.filter((opt) =>
            opt.label.toLowerCase().includes(search.toLowerCase()),
          ),
    [options, search],
  );

  // State for the selected options to debounce the tableFilters/setTableFilters
  const [selectedOptions, setSelectedOptions] = useState<string[] | null>(
    tableFilters?.values ?? null,
  );

  const debounceSetTableFilters = useDebounce((selectedOptions) =>
    setTableFilters(selectedOptions),
  );

  const handleSelect = useCallback(
    (selectedOptions: string[] | null) => {
      setSelectedOptions(selectedOptions);
      // Debounce-ly set table filters
      debounceSetTableFilters(selectedOptions);
    },
    [debounceSetTableFilters],
  );

  const handleSelectAll = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const filteredOptionValues = filteredOptions.map((o) => o.value);
      setSelectedOptions(filteredOptionValues);
      // Bypass debounce for Select All but doesn't block render
      setTimeout(() => setTableFilters(filteredOptionValues), 0);

      // Blur to automatically close the popover
      e.currentTarget.blur();
    },
    [setTableFilters, filteredOptions],
  );

  const handleClearAll = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setSelectedOptions(null);
      // Bypass debounce for Clear All but doesn't block render
      setTimeout(() => setTableFilters(null), 0);

      // Blur to automatically close the popover
      e.currentTarget.blur();
    },
    [setTableFilters],
  );

  return (
    <>
      <HStack pb="3" align="baseline" spacing="0">
        <Text color="black">Filter</Text>

        <Spacer />

        {options.length > 0 && (
          <>
            <Button
              variant="ghost"
              onClick={handleSelectAll}
              isDisabled={
                selectedOptions?.length === options.length ||
                filteredOptions.length === 0
              }
            >
              Select all
            </Button>

            <Text>-</Text>

            <Button
              variant="ghost"
              onClick={handleClearAll}
              isDisabled={!selectedOptions}
            >
              Clear all
            </Button>
          </>
        )}
      </HStack>

      <VStack align="stretch" px="1" mx="-1" position="relative">
        {withOperator ? (
          <MultiSelectOperator
            operator={tableFilters?.operator}
            onChangeOperator={setTableOperator}
          />
        ) : null}

        {options.length > 5 && <MultiSelectSearch onChangeSearch={setSearch} />}

        <MultiSelectList
          values={selectedOptions}
          onChange={handleSelect}
          options={filteredOptions}
          maxHeight={withOperator ? 'md' : 'lg'}
        />
      </VStack>
    </>
  );
};
