import {
  CompanyClassificationModel,
  CompanyClassificationTypeEnum,
} from '../models';
import { companyClassifications } from './classifications.definition';

export function findKnownClassificationByTypeAndCode({
  type,
  code,
}: Pick<
  CompanyClassificationModel,
  'type' | 'code'
>): CompanyClassificationModel | null {
  if (!isKnownType(type)) {
    return null;
  }
  const classifications = companyClassifications[type];

  const classification = code
    ? classifications.find((c) => c.code === code)
    : null;
  if (!classification) {
    return null;
  }

  return classification;
}

const isKnownType = (type: string): type is CompanyClassificationTypeEnum => {
  return Object.values(CompanyClassificationTypeEnum).includes(
    type as CompanyClassificationTypeEnum,
  );
};
