import { ChangeEvent, useEffect, useState } from 'react';

import { createUseDebounce } from '@dotfile/frontend/shared/common';
import { SearchInput } from '@dotfile/frontend/shared/design-system';

const useDebounce = createUseDebounce<[string]>(500);

export type SearchProps = {
  isLoading: boolean;
  search: string;
  onSearchChange: (search: string) => void;
  activePlaceholder?: string;
};

export const Search = ({
  search,
  onSearchChange,
  isLoading,
  activePlaceholder,
}: SearchProps) => {
  // State for the search input to debounce the search/onSearchChange from props
  const [inputSearch, setInputSearch] = useState(search);

  // Sync back inputSearch with search, necessary to clear the inputSearch when table filters are cleared
  useEffect(() => {
    setInputSearch(search);
  }, [search]);

  const debouncedOnSearchChange = useDebounce(onSearchChange);
  useEffect(() => {
    debouncedOnSearchChange(inputSearch);
  }, [inputSearch, debouncedOnSearchChange]);

  return (
    <SearchInput
      activePlaceholder={activePlaceholder}
      isLoading={isLoading}
      search={inputSearch}
      onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
        setInputSearch(target.value)
      }
      onReset={() => {
        setInputSearch('');
        onSearchChange('');
      }}
    />
  );
};
