import { useAuth } from '@dotfile/frontend/shared/common';
import { useCaseFormsQuery } from '@dotfile/shared/data-access-client-portal';

import { ContactAuthContext } from '../../../../shared';

export function useCaseForms() {
  const {
    auth: { caseId },
  } = useAuth<ContactAuthContext>();

  return useCaseFormsQuery({
    variables: { id: caseId as string },
    skip: !caseId,
  });
}
