import { CaseRelationRoleEnum } from '../../../shared/models';

type CaseRelationRoleDefinition = {
  type: CaseRelationRoleEnum;
  label: string;
};

const legal_representative: CaseRelationRoleDefinition = {
  type: CaseRelationRoleEnum.legal_representative,
  label: 'Legal representative',
};

const shareholder: CaseRelationRoleDefinition = {
  type: CaseRelationRoleEnum.shareholder,
  label: 'Shareholder',
};

export const caseRelationRoleDefinition: Record<
  CaseRelationRoleEnum,
  CaseRelationRoleDefinition
> = {
  legal_representative,
  shareholder,
};

export function isCaseRelationRole(v: unknown): v is CaseRelationRoleEnum {
  return (
    !!v &&
    Object.values(CaseRelationRoleEnum).includes(v as CaseRelationRoleEnum)
  );
}
