type UnstyledErrorScreenProps = { error: unknown };

/**
 * This is the very last resort error handler that doesn't have access to the design-system theme or i18n
 */
export const UnstyledErrorScreen = ({ error }: UnstyledErrorScreenProps) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        textAlign: 'center',
      }}
    >
      <p style={{ maxWidth: '80%' }}>An unexpected error occurred:</p>
      <pre style={{ maxWidth: '80%', whiteSpace: 'pre-wrap' }}>
        {`${error}`}
      </pre>
      <p style={{ maxWidth: '80%' }}>
        Try refreshing the page and if the problem persists contact support.
      </p>
    </div>
  );
};
