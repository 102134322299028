import { TFunction } from 'i18next';
import { upperFirst } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Text } from '@dotfile/frontend/shared/design-system';
import {
  CheckResultEnum,
  DocumentAnalysisDetailedResultModel,
} from '@dotfile/shared/domain';

import { DataComparisonNoLabel } from './data-comparison-no-data';

export const DataComparisonLabel = ({
  detailedResult,
  informationDefinition,
  entityName,
}: {
  detailedResult: DocumentAnalysisDetailedResultModel;
  informationDefinition: { label: (t: TFunction) => string };
  entityName: string;
}) => {
  const { t } = useTranslation();

  const label = informationDefinition.label(t).toLowerCase();

  if (detailedResult.result === CheckResultEnum.approved) {
    return (
      <Text color="black" fontSize="sm">
        {t('document_analysis.data_comparison_expected_detected', {
          label,
          defaultValue: `Expected {{label}} detected`,
          ns: 'components',
        })}
        :&nbsp;
        <Text color="blue.700" fontSize="sm" as="span">
          {detailedResult.received}
        </Text>
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.rejected) {
    return (
      <Text color="black" fontSize="sm">
        {t('document_analysis.data_comparison_unexpected_detected', {
          label,
          defaultValue: `Unexpected {{label}} detected`,
          ns: 'components',
        })}
        :&nbsp;
        <Text color="blue.700" fontSize="sm" as="span">
          {detailedResult.received}
        </Text>
        <br />
        {t('document_analysis.data_comparison_expected', {
          value: detailedResult.expected,
          defaultValue: `Expected: ${detailedResult.expected}`,
          ns: 'components',
        })}
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.error) {
    if (!detailedResult.expected) {
      return (
        <Text color="black" fontSize="sm">
          {t('document_analysis.data_comparison_not_found_entity', {
            label,
            name: entityName,
            ns: 'components',
            defaultValue: `${upperFirst(label)} not found on ${entityName}`,
          })}
          <br />
          {t('document_analysis.data_comparison_detected', {
            value: detailedResult.received,
            defaultValue: 'Detected',
            ns: 'components',
          })}
          :&nbsp;
          <Text color="blue.700" fontSize="sm" as="span">
            {detailedResult.received}
          </Text>
        </Text>
      );
    }

    if (!detailedResult.received) {
      return (
        <Text color="black" fontSize="sm">
          {t('document_analysis.data_comparison_not_found_document', {
            label,
            ns: 'components',
            defaultValue: `${upperFirst(label)} not found on document`,
          })}
        </Text>
      );
    }
  }

  return (
    <DataComparisonNoLabel informationDefinition={informationDefinition} />
  );
};
