import { createRef, memo, useEffect } from 'react';

import { useIsMobile } from '@dotfile/frontend/shared/design-system';

import { VendorIframeProps } from '../types';

/**
 * @NOTE no TypeScript SDK available for Ubble module
 * Since it's loaded from global, we will declare our own
 */
declare const Ubble: {
  IDV: new (
    ref: HTMLDivElement | null,
    options: {
      allowCamera: boolean;
      verificationUrl: string;
      width?: string | number;
      height?: string | number;
      events: Partial<{
        onComplete: () => void;
        onExpired: () => void;
        onAbort: () => void;
        onRefused: () => void;
      }>;
    },
  ) => {
    destroy: () => void;
  };
};

// Pure component to not rerender if url don't change
// Make sure you wrap handlers in useCallback to not trigger rerender
export const UbbleCheckoutIframe = memo(function UbbleCheckoutIframe({
  verificationUrl,
  onComplete,
  onAbort,
  onExpired,
}: VendorIframeProps) {
  const ref = createRef<HTMLDivElement>();

  const isMobile = useIsMobile();

  useEffect(() => {
    const ubble = new Ubble.IDV(ref.current, {
      width: '100%',
      height: '100%',
      verificationUrl: verificationUrl,
      allowCamera: true,
      events: {
        onComplete: () => {
          ubble.destroy();
          onComplete();
        },
        onAbort: () => {
          ubble.destroy();
          onAbort();
        },
        onRefused: () => {
          ubble.destroy();
          onAbort();
        },
        onExpired: () => {
          ubble.destroy();
          onExpired();
        },
      },
    });

    return () => {
      ubble.destroy();
    };
  }, [verificationUrl, ref, onComplete, onAbort, onExpired]);

  return (
    <div
      ref={ref}
      className="iframe-ref"
      style={{
        width: '100%',
        height: isMobile ? '80vh' : '70vh',
        overflow: 'hidden',
      }}
    />
  );
});
