import { useFormContext } from 'react-hook-form';

import { GroupController } from '@dotfile/frontend/shared/components';
import { AbsoluteDatePicker } from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/data-access-client-portal';
import {
  getMinBirthDate,
  individualViewColumnDefinition,
} from '@dotfile/shared/domain';

import { FieldProps } from './types';

export const FieldDate = ({
  field,
  property,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.date>) => {
  const { control } = useFormContext();

  const isBirthDate =
    field.key === individualViewColumnDefinition.birthDate.key;
  const dateProps = isBirthDate
    ? { minDate: getMinBirthDate(), maxDate: new Date() }
    : {};

  return (
    <GroupController
      control={control}
      render={(field) => (
        <AbsoluteDatePicker width="100%" {...field} {...dateProps} />
      )}
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
