import { Trans } from 'react-i18next';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  HStack,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalStepTypeEnum } from '@dotfile/shared/domain';

import { SupportButton, useLatestClientPortalVersion } from '../../../shared';
import { useFormDatastore } from '../shared';

export const NoLegalRepresentativeIndividualsAlert = () => {
  const { data } = useLatestClientPortalVersion();
  const supportEmail = data?.latestClientPortalVersion.setting.supportEmail;

  const setProgress = useFormDatastore((state) => state.setProgress);
  const progress = useFormDatastore((state) => state.progress);

  return (
    <Alert status="warning">
      <HStack>
        <AlertIcon />
        <AlertDescription>
          <Trans
            ns="client-portal"
            i18nKey="forms.business_contact.legal_representative_alert.no_legal_representative_individuals"
            defaults="The authorized signatory is not a legal representative of the company. You will need a legal representative granting you power of attorney, you can go back to <a>this step</a> to add one or contact support."
            components={{
              a: (
                <Button
                  variant="link"
                  onClick={() => {
                    setProgress({
                      ...progress,
                      currentStepKey: ClientPortalStepTypeEnum.individuals_edit,
                    });
                  }}
                />
              ),
            }}
          />

          {supportEmail && (
            <SupportButton
              supportEmail={supportEmail}
              variant="link"
              ml="0.5"
            />
          )}
        </AlertDescription>
      </HStack>
    </Alert>
  );
};
