/**
 * Max original file size in MB for ID Document check powered by Veriff, before Base64 encoding for upload to Veriff
 *
 * Veriff documentation is explicit about the limit with base64 encoding (24MB) and without (17MB)
 *
 * @see https://developers.veriff.com/#post-sessions-sessionid-media
 */
export const ID_DOCUMENT_VERIFF_MAX_FILE_SIZE = 17_000_000; // 17 MB

/**
 * Max original file size in MB for ID Document check powered by IDnow, before Base64 encoding for upload to IDnow
 *
 * Their doc is not explicit about it, their is a figure of `4Mb`  given in the error codes documentation, without precision about
 * Base64 encoding (Base64 encoding increases file size by approximately 33% because for every 3 bytes of binary data, Base64 produces 4
 * bytes of encoded data).
 *
 * In practice the limit looks to be a bit above 4MB (as in Bytes, not Mb with bits) from the original file size, before Base64 encoding
 *
 * @see https://api.idcheck-sandbox.ariadnext.io/gw/cis/api/index.html#_available_error_codes
 * @note You can find valid and invalid idnow file in tools/assets/tests/idnow_*
 */
export const ID_DOCUMENT_IDNOW_MAX_FILE_SIZE = 4_000_000;
