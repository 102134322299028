import { ChevronDown } from 'lucide-react';
import React, { useCallback } from 'react';

import { Skeleton } from '../../feedback/skeleton/skeleton';
import { IconButton } from '../../form/icon-button/icon-button';
import { Box, BoxProps } from '../../layout/box/box';
import { Center } from '../../layout/center/center';
import { Spacer } from '../../layout/flex/flex';
import {
  Grid,
  GridItem,
  GridProps,
  SimpleGrid,
  SimpleGridProps,
} from '../../layout/grid/grid';
import { HStack } from '../../layout/stack/stack';
import { Icon } from '../../media-icon/icon/icon';
import { Heading } from '../../typography/heading';
import { Text } from '../../typography/text';
import { useControllableState } from '../../utils/hooks/chakra-hooks';
import { Collapse, CollapseProps } from '../../utils/transitions';
import { Badge } from '../badge/badge';

// @NOTE: This component does many things:
// - display a section/title heading with title, count of items, action / control
// - display list of items in a grid or children
// - optionality add collapse behaviors
// Currently all this behaviors are used together but eventually this could be split into
// - Section: title, count as props, control, action, collapse and content as children
// - GridList: render items
// Must current usage will use a Section with GridList in children

const NO_COLLAPSE_ANIMATION: CollapseProps['transition'] = {
  enter: { duration: 0, delay: 0 },
  exit: { duration: 0, delay: 0 },
};

export type GridListProps<Item extends { id: string }> = {
  /**
   * @default 'heading'
   */
  variant?: 'heading' | 'title';
  title?: React.ReactNode;
  control?: React.ReactNode;
  action?: React.ReactNode;

  /**
   * Spacing between the heading/title and content
   * @default `'2'`
   */
  spacing?: BoxProps['pt'];

  isCollapsible?: boolean;
  noCollapseAnimation?: boolean;
  /**
   * When collapse state is uncontrolled
   */
  defaultIsCollapseOpen?: boolean;
  /**
   * When collapse state is controlled
   */
  isCollapseOpen?: boolean;
  /**
   * When collapse state is controlled
   */
  onCollapseChange?: (isCollapseOpen: boolean) => void;
  /**
   * Fired when the collapse is manually toggled by an user click. Useful for analytics purpose.
   * @param isCollapseOpen
   * @returns
   */
  onCollapseClick?: (isCollapseOpen: boolean) => void;

  isLoading?: boolean;
  items: Item[];
} & (
  | {
      /**
       * Short-props to set the columns on the inner grid
       */
      columns?: SimpleGridProps['columns'];
      innerGridProps?: SimpleGridProps;
      outerGridProps?: GridProps;
      renderItem?: (item: Item, index: number) => React.ReactNode;

      allowToggleWhenEmptyItems?: never;
      children?: never;
      /**
       * Will render no empty label if set to false
       */
      emptyLabel?: string | false;
    }
  | {
      columns?: never;
      innerGridProps?: never;
      outerGridProps?: never;
      renderItem?: never;

      /**
       * Allow to open the collapse when there are no items.
       */
      allowToggleWhenEmptyItems?: boolean;
      children?: React.ReactNode;
      emptyLabel?: never;
    }
);

const defaultGridTemplate: GridProps = {
  templateAreas: '"title" "content"',
  templateColumns: '1fr',
  templateRows: '1fr',
};
const collapsibleGridTemplate: GridProps = {
  templateAreas: '"icon title" ". content"',
  templateColumns: 'min-content 1fr',
  templateRows: 'min-content 1fr',
};

export const GridList = <Item extends { id: string }>(
  props: GridListProps<Item>,
): JSX.Element => {
  const {
    variant = 'heading',
    title,
    control = null,
    action = null,

    emptyLabel = 'Nothing',
    isLoading,
    items,
    renderItem,
    allowToggleWhenEmptyItems,
    children,

    isCollapsible,
    noCollapseAnimation,
    defaultIsCollapseOpen = true,
    isCollapseOpen,
    onCollapseChange,
    onCollapseClick,

    columns,
    spacing = '2',
    innerGridProps,
    outerGridProps,
  } = props;

  const [_isCollapseOpen, setIsCollapseOpen] = useControllableState({
    defaultValue: defaultIsCollapseOpen,
    onChange: onCollapseChange,
    value: isCollapseOpen,
  });

  const isCollapseForcedClosed =
    !allowToggleWhenEmptyItems &&
    // Force isOpen to false and disabled when there are no items
    (!items || items.length === 0);
  const isOpen =
    !isCollapsible || // Always open when not collapsible
    (_isCollapseOpen && !isCollapseForcedClosed);
  const isCollapseDisabled = !isCollapsible || isCollapseForcedClosed;

  const toggleCollapse = useCallback(() => {
    const newIsOpen = !isOpen;
    setIsCollapseOpen(newIsOpen);
    onCollapseClick?.(newIsOpen);
  }, [setIsCollapseOpen, onCollapseClick, isOpen]);

  const gridTemplate = isCollapsible
    ? collapsibleGridTemplate
    : defaultGridTemplate;

  return (
    <Grid
      rowGap="O" // 0 row gap is important to avoid extra space when content is collapsed
      columnGap="0"
      alignItems="center"
      width="100%"
      {...outerGridProps}
      {...gridTemplate}
    >
      {isCollapsible && (
        <IconButton
          gridArea="icon"
          aria-label={isOpen ? 'close' : 'open'}
          variant="ghost"
          size="xs"
          onClick={toggleCollapse}
          icon={
            <Icon
              __css={{
                // Similar animation to accordion icon
                // @TODO - E-3452 - Design: SeeMore component
                // But this one is a bit special since it's only an IconButton and arrow as a different orientation
                transform: isOpen ? undefined : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                transformOrigin: 'center',
              }}
              as={ChevronDown}
            />
          }
          h={variant === 'heading' ? '6' : '4'}
          isDisabled={isCollapseDisabled}
        />
      )}

      <HStack spacing="4" gridArea="title">
        {variant === 'heading' ? (
          <HStack
            h="8" // Force a 8 (= 32px) height to have all the heading grid list have the same height even without action button
            spacing="4"
            onClick={isCollapseDisabled ? undefined : toggleCollapse}
            cursor={isCollapseDisabled ? undefined : 'pointer'}
          >
            <Heading as="h5" variant="h5">
              {title}
            </Heading>
            <Skeleton isLoaded={!isLoading} border="base" w="6" h="6" ml="-2">
              <Badge>{items.length}</Badge>
            </Skeleton>
          </HStack>
        ) : (
          <Text
            color="black"
            fontWeight="medium"
            onClick={isCollapseDisabled ? undefined : toggleCollapse}
            cursor={isCollapseDisabled ? undefined : 'pointer'}
          >
            {title}{' '}
            <Skeleton
              as="span"
              isLoaded={!isLoading}
              border="base"
              w="4"
              h="4"
              display="inline"
            >
              ({items.length})
            </Skeleton>
          </Text>
        )}

        {control}

        <Spacer />

        {action}
      </HStack>

      <GridItem gridArea="content">
        <Collapse
          in={isOpen}
          transition={noCollapseAnimation ? NO_COLLAPSE_ANIMATION : undefined}
        >
          <Box pt={spacing}>
            {renderItem ? (
              items.length ? (
                <SimpleGrid
                  columns={columns ?? 2}
                  spacing="4"
                  {...innerGridProps}
                >
                  {items.map((item, index) => (
                    <React.Fragment key={item.id}>
                      {renderItem(item, index)}
                    </React.Fragment>
                  ))}
                </SimpleGrid>
              ) : emptyLabel === false ? null : (
                <Box h="16" bgColor="white" w="full">
                  <Center h="full">
                    <Text>{emptyLabel}</Text>
                  </Center>
                </Box>
              )
            ) : (
              children
            )}
          </Box>
        </Collapse>
      </GridItem>
    </Grid>
  );
};
