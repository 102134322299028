import { format, isValid, parse } from 'date-fns';

export const ABSOLUTE_DATE_FORMAT = 'yyyy-MM-dd';
const REFERENCE_DATE = new Date(0, 0, 0, 0, 0, 0, 0);

export function isValidAbsoluteDate(str: string): boolean {
  try {
    return isValid(parseAbsoluteDate(str));
  } catch (err) {
    return false;
  }
}

export function parseAbsoluteDate(str: string): Date {
  const date = parse(str, ABSOLUTE_DATE_FORMAT, REFERENCE_DATE);
  return date;
}

export function stringifyAbsoluteDate(date: Date): string {
  return format(date, ABSOLUTE_DATE_FORMAT);
}

/**
 * Convert slashed DMY date format to ISO 8601 absolute date format
 * @note return null for invalid absolute date
 * @param slashedDMYDate - eg: '21/02/1992'
 * @returns - '1992-02-21'
 */
export function convertSlashedDMYToAbsoluteDate(
  slashedDMYDate: string,
): string | null {
  if (!slashedDMYDate) return null;

  const [day, month, year] = slashedDMYDate.split('/');
  const birthDateNonValidated = [year, month, day].join('-');
  return isValidAbsoluteDate(birthDateNonValidated)
    ? birthDateNonValidated
    : null;
}
