import {
  Download as IconDownload,
  RotateCcw,
  RotateCw,
  ZoomIn,
  ZoomOut,
} from 'lucide-react';
import { useLayoutEffect, useState } from 'react';

import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Img,
  Spacer,
  Text,
  usePrefersReducedMotion,
} from '@dotfile/frontend/shared/design-system';

import { useImage, useSaveFile } from '../../../file/hooks';
import { ViewerFile } from '../../type';
import { SkeletonViewer, ViewerLayout, ViewerLayoutProps } from '../shared';

const initialViewerState = {
  scale: 1,
  rotation: 0,
  isDragging: false,
  startX: 0,
  startY: 0,
  offsetX: 0,
  offsetY: 0,
  noAnimation: true,
};

const SCALE_STEP = 0.2;
const SCALE_MIN = 0.5; // Min zoom level 0.5x
const SCALE_MAX = 5; // Max zoom level 5x

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

type ImageViewerProps = {
  file: ViewerFile;
  withToolbar?: boolean;
  onDownloaded?: () => void;
} & ViewerLayoutProps;

export const ImageViewer = ({
  file,
  withToolbar = true,
  onDownloaded,
  ...layoutProps
}: ImageViewerProps): JSX.Element => {
  const image = useImage(file);
  const saveFile = useSaveFile();

  const reduceMotion = usePrefersReducedMotion();

  // State to manage zoom, rotation, and panning
  const [viewerState, setViewerState] = useState(initialViewerState);
  // Must be a layout effect to disable the animation quickly enough
  useLayoutEffect(() => {
    //Reset state when file changes
    setViewerState({ ...initialViewerState, noAnimation: true });

    // Enable animation after first render
    setTimeout(() => {
      setViewerState({ ...initialViewerState, noAnimation: false });
    }, 16);
  }, [file.id]);

  // Handlers for zooming and rotating buttons
  const handleZoomIn = () =>
    setViewerState((prev) => ({
      ...prev,
      scale: clamp(prev.scale + SCALE_STEP, SCALE_MIN, SCALE_MAX),
    }));
  const handleZoomOut = () =>
    setViewerState((prev) => ({
      ...prev,
      scale: clamp(prev.scale - SCALE_STEP, SCALE_MIN, SCALE_MAX),
    }));

  const handleRotateLeft = () =>
    setViewerState((prev) => ({
      ...prev,
      rotation: prev.rotation - 90, // Rotate counter-clockwise
    }));
  const handleRotateRight = () =>
    setViewerState((prev) => ({
      ...prev,
      rotation: prev.rotation + 90, // Rotate clockwise
    }));

  // Handlers for panning
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default selection behavior

    setViewerState((prev) => ({
      ...prev,
      isDragging: true,
      startX: e.clientX - prev.offsetX,
      startY: e.clientY - prev.offsetY,
    }));
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!viewerState.isDragging) return;

    setViewerState((prev) => ({
      ...prev,
      offsetX: e.clientX - prev.startX,
      offsetY: e.clientY - prev.startY,
    }));
  };

  const handleMouseUp = () => {
    if (!viewerState.isDragging) return;
    setViewerState((prev) => ({ ...prev, isDragging: false }));
  };

  const handleMouseLeave = () => {
    if (!viewerState.isDragging) return;
    setViewerState((prev) => ({ ...prev, isDragging: false }));
  };

  // Handler for wheel Zoom
  const handleWheel = (event: React.WheelEvent) => {
    let scaleChange = -event.deltaY * SCALE_STEP; // Adjust scale based on trackpad delta

    // Fine-tuning the trackpad sensitivity by dividing deltaY
    scaleChange /= 120; // Decrease sensitivity for smooth zoom

    // Ensure the new scale stays within bounds (0.5x to 5x)
    setViewerState((prev) => ({
      ...prev,
      scale: clamp(prev.scale + scaleChange, SCALE_MIN, SCALE_MAX),
    }));
  };

  if (image.isLoading) {
    return <SkeletonViewer {...layoutProps} withToolbar={withToolbar} />;
  }

  return (
    <ViewerLayout {...layoutProps}>
      {withToolbar && (
        <HStack
          alignSelf="stretch"
          fontSize="md"
          alignItems="center"
          role="toolbar"
          aria-orientation="horizontal"
        >
          <Text flex="1 1 auto" noOfLines={1} color="black" fontWeight="500">
            {file.name}
          </Text>

          <Spacer />

          <IconButton
            flex="0 0 auto"
            variant="link"
            color="black"
            icon={<Icon as={ZoomIn} />}
            aria-label="Zoom In"
            onClick={handleZoomIn}
          />
          <IconButton
            flex="0 0 auto"
            variant="link"
            color="black"
            icon={<Icon as={ZoomOut} />}
            aria-label="Zoom Out"
            onClick={handleZoomOut}
          />
          <IconButton
            flex="0 0 auto"
            variant="link"
            color="black"
            icon={<Icon as={RotateCw} />}
            aria-label="Rotate Right"
            onClick={handleRotateRight}
          />
          <IconButton
            flex="0 0 auto"
            variant="link"
            color="black"
            icon={<Icon as={RotateCcw} />}
            aria-label="Rotate Left"
            onClick={handleRotateLeft}
          />

          <IconButton
            flex="0 0 auto"
            icon={<Icon as={IconDownload} />}
            aria-label="Download"
            variant="link"
            color="black"
            isLoading={saveFile.isPending}
            onClick={() => {
              onDownloaded?.();
              saveFile.mutate(file);
            }}
          />
        </HStack>
      )}

      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        py="4"
        overflow="hidden" // Keep 'hidden' for better panning control
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        cursor={viewerState.isDragging ? 'grabbing' : 'grab'} // Change cursor during dragging
        userSelect="none" // Disable text selection while dragging
      >
        <Img
          src={image.data}
          alt={file.name}
          objectFit="contain"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            transform: `translate(${viewerState.offsetX}px, ${viewerState.offsetY}px) scale(${viewerState.scale}) rotate(${viewerState.rotation}deg)`,
            transition:
              viewerState.isDragging || viewerState.noAnimation || reduceMotion
                ? 'none'
                : 'transform 0.2s ease',
          }}
          onWheel={handleWheel}
        />
      </Flex>
    </ViewerLayout>
  );
};
