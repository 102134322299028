import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Spacer,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { AnalysisResultItem } from './analysis-result-item';
import {
  AnalysisResultGroup as AnalysisResultGroupType,
  AnalysisResultVariant,
} from './types';
import { isAnalysisResultElement, isAnalysisResultGroup } from './utils';

interface AnalysisResultGroupProps {
  analysisResultGroup: AnalysisResultGroupType;
  variant?: AnalysisResultVariant;
}

export const AnalysisResultGroup = ({
  analysisResultGroup,
  variant,
}: AnalysisResultGroupProps) => {
  const isClientPortalVariant = variant === 'client-portal';
  if (isAnalysisResultElement(analysisResultGroup)) {
    return (
      <AnalysisResultItem
        variant={variant}
        analysisResult={analysisResultGroup.analysisResult}
      />
    );
  } else if (isAnalysisResultGroup(analysisResultGroup)) {
    const { key, groupResult, results } = analysisResultGroup;

    return (
      <Accordion
        width="full"
        variant="unstyled"
        defaultIndex={isClientPortalVariant ? [0] : []}
        allowMultiple
      >
        <AccordionItem width="full">
          {() => (
            <>
              <AccordionButton
                p="0"
                my={isClientPortalVariant ? 1 : 0}
                as={HStack}
                cursor="pointer"
              >
                <AnalysisResultItem
                  variant={variant}
                  analysisResult={{
                    key,
                    result: groupResult,
                  }}
                />
                <Spacer />
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                p="0"
                spacing={isClientPortalVariant ? 1 : 0}
                as={VStack}
                textAlign="start"
                align="flex-start"
                pl="4"
              >
                {results.map((result) => {
                  const key = isAnalysisResultElement(result)
                    ? result.analysisResult.key
                    : result.key;

                  return (
                    <AnalysisResultGroup
                      key={key}
                      variant={variant}
                      analysisResultGroup={result}
                    />
                  );
                })}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    );
  }

  return null;
};
