import { mergeWith } from 'lodash';

import {
  BaseRepresentativeModel,
  CaseRelationRoleEnum,
  CompanyBeneficialOwnerModel,
  CompanyEntityTypeEnum,
  CompanyRepresentativeModel,
  CompanyShareholderModel,
  CompanyTypeEnum,
  IndividualBeneficialOwnerModel,
  IndividualRepresentativeModel,
  IndividualShareholderModel,
  MergedCompaniesModel,
} from '../../../../shared/models';
import { CompanyComparator } from './company-comparison.helper';

function entityTypeCompanyFilter(representative: BaseRepresentativeModel) {
  return representative.entityType === CompanyEntityTypeEnum.company;
}

type MergeCompaniesParam = {
  beneficialOwners?:
    | (IndividualBeneficialOwnerModel | CompanyBeneficialOwnerModel)[]
    | null;
  shareholders?:
    | (IndividualShareholderModel | CompanyShareholderModel)[]
    | null;
  legalRepresentatives?:
    | (IndividualRepresentativeModel | CompanyRepresentativeModel)[]
    | null;
};

// @TODO - E-4415 - Refactor merge-companies and merge-individuals helpers
/**
 * Merge a list of companies
 * Also strip out 'individual' entityType
 * @see https://linear.app/dotfile/issue/E-3339/company-data-merged-affiliated-companies
 *
 * @param param.beneficialOwners - An array of beneficial owners
 * @param param.shareholders - An array of shareholders
 * @param param.legalRepresentatives - An array of legal representatives
 * @returns A merged array of MergedCompaniesModel
 */
export function mergeCompanies({
  beneficialOwners,
  shareholders,
  legalRepresentatives,
}: MergeCompaniesParam): MergedCompaniesModel[] {
  if (!beneficialOwners) beneficialOwners = [];
  if (!shareholders) shareholders = [];
  if (!legalRepresentatives) legalRepresentatives = [];

  const companies: MergedCompaniesModel[] = [
    ...beneficialOwners
      .filter((b0): b0 is CompanyBeneficialOwnerModel =>
        entityTypeCompanyFilter(b0),
      )
      .map(
        (bo): MergedCompaniesModel => ({
          type: CompanyTypeEnum.affiliated,
          name: bo.name ?? '',
          country: bo.country ?? null,
          registrationNumber: bo.registrationNumber ?? null,
          address: bo.address,
          legalForm: bo.legalForm ?? null,
          entityLegalForm: bo.entityLegalForm ?? null,
          relations: [
            {
              position: bo.position,
              ownershipPercentage: bo.ownershipPercentage,
              roles: [],
            },
          ],
        }),
      ),
    ...shareholders
      .filter((s): s is CompanyShareholderModel => entityTypeCompanyFilter(s))
      .map(
        (sh): MergedCompaniesModel => ({
          type: CompanyTypeEnum.affiliated,
          name: sh.name ?? '',
          country: sh.country ?? null,
          registrationNumber: sh.registrationNumber ?? null,
          address: sh.address,
          legalForm: sh.legalForm ?? null,
          entityLegalForm: sh.entityLegalForm ?? null,
          relations: [
            {
              position: sh.position,
              ownershipPercentage: sh.ownershipPercentage,
              roles: [CaseRelationRoleEnum.shareholder],
            },
          ],
        }),
      ),
    ...legalRepresentatives
      .filter((lr): lr is CompanyRepresentativeModel =>
        entityTypeCompanyFilter(lr),
      )
      .map(
        (lr): MergedCompaniesModel => ({
          type: CompanyTypeEnum.affiliated,
          name: lr.name ?? '',
          country: lr.country ?? null,
          registrationNumber: lr.registrationNumber ?? null,
          address: lr.address,
          legalForm: lr.legalForm ?? null,
          entityLegalForm: lr.entityLegalForm ?? null,
          relations: [
            {
              position: lr.position,
              ownershipPercentage: null,
              roles: [CaseRelationRoleEnum.legal_representative],
            },
          ],
        }),
      ),
  ];

  return companies.reduce<MergedCompaniesModel[]>((acc, currentCompany) => {
    if (!currentCompany.name) {
      // Ignore company without name
      return acc;
    }

    const comparator = new CompanyComparator(currentCompany);
    const matchingIndex = comparator.isComparable
      ? acc.findIndex((otherCompany) => comparator.isSame(otherCompany))
      : -1;

    if (matchingIndex === -1) {
      // current company is not comparable or did not match with any other
      return [...acc, currentCompany];
    }

    return acc.map((otherCompany, index) => {
      if (index === matchingIndex) {
        const matchingCompany = otherCompany;

        return mergeWith(
          {},
          currentCompany,
          matchingCompany,
          (objValue, srcValue) =>
            // takes in priority info from the last object or one that it truthy
            srcValue || objValue,
        );
      }

      return otherCompany;
    });
  }, []);
}
