import { TFunction } from 'i18next';

import { ClientPortalBlockFieldMapping } from '@dotfile/shared/data-access-client-portal';
import {
  clientPortalCompanyPropertiesDefinition,
  yup,
} from '@dotfile/shared/domain';

import { generatePropertyValidationSchema } from './generate-property-validation-schema';
import { getLocalizedValidation } from './localize-validation';

const { country, name, registrationNumber } =
  clientPortalCompanyPropertiesDefinition;

export const generateCompanySearchValidationSchema = ({
  t,
}: {
  t: TFunction;
}) => {
  const localizedValidation = getLocalizedValidation(t);

  return yup
    .object()
    .shape({
      country: generatePropertyValidationSchema({
        isRequired: true,
        property: country,
        localizedValidation,
        mapping: country.mapping as unknown as ClientPortalBlockFieldMapping,
        t,
      }),
      name: yup
        .string()
        .optionalString()
        .when('$searchType', {
          is: (searchType: 'name' | 'registrationNumber') =>
            searchType === 'name',
          then: () =>
            generatePropertyValidationSchema({
              isRequired: true,
              property: name,
              localizedValidation,
              mapping: name.mapping as unknown as ClientPortalBlockFieldMapping,
              t,
            }),
          otherwise: (schema) => schema,
        }),
      registrationNumber: yup
        .string()
        .optionalString()
        .when('$searchType', {
          is: (searchType: 'name' | 'registrationNumber') =>
            searchType === 'registrationNumber',
          then: () =>
            generatePropertyValidationSchema({
              isRequired: true,
              property: registrationNumber,
              localizedValidation,
              mapping:
                registrationNumber.mapping as unknown as ClientPortalBlockFieldMapping,
              t,
            }),
          otherwise: (schema) => schema,
        }),
    })
    .defined();
};
