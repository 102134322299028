import { keyframes } from '@emotion/react';

import {
  Box,
  BoxProps,
  Center,
  useToken,
} from '@dotfile/frontend/shared/design-system';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

type AppLoaderProps = {
  isInline?: boolean;
} & BoxProps;

export const AppLoader = ({ isInline, ...boxProps }: AppLoaderProps) => {
  const color = useToken('colors', 'custom.500', 'gray.500');

  const loader = (
    // Same style as in the index.html
    <Box
      width="12"
      height="12"
      borderStyle="solid"
      borderWidth="4px"
      borderColor={color}
      borderBottomColor="transparent"
      transition="border 500ms linear"
      borderRadius="50%"
      animation={`${rotation} 1s linear infinite`}
      {...boxProps}
    />
  );

  return isInline ? (
    loader
  ) : (
    <Center w="100%" h="100vh" color="white">
      {loader}
    </Center>
  );
};
