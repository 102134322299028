import { pick } from 'lodash';

/**
 * Create a new Enum with only a sub-set of the values of the original enum
 *
 * @param originalEnum The original enum
 * @param keysSubset Subset of keys to keep
 */
export function subEnum<
  const TEnum extends Record<string, unknown>,
  const TKey extends keyof TEnum,
>(originalEnum: TEnum, keysSubset: TKey[]): Pick<TEnum, TKey> {
  return pick(originalEnum, keysSubset);
}
