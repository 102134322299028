import { datadogRum } from '@datadog/browser-rum';

/**
 * Log the error in the browser console and add it to datadog RUM if enabled
 *
 * @param error Prefer sending instance of Error from a `catch` or manually created using `new` to have a better stack trace.
 *              Falsy value will be ignored, string will be transformed to an Error
 * @param context
 * @see https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
 */
export const logAndAddError = (error: unknown, context?: object): void => {
  if (!error) {
    // Make sure there is an error to log / send because unknown could allow null/undefined
    return;
  }

  // This should be the only place were console.error is called, otherwise this method should be used
  // eslint-disable-next-line no-console
  console.error(error, context);

  if (datadogRum.getInternalContext()) {
    if (typeof error === 'string') {
      // In case the error was only a string, create an Error object from it to send to Datadog otherwise Datadog will ignore it
      // but the stack trace will be less helpful since the error will be created here
      error = new Error(error);
    }
    datadogRum.addError(error, context);
  }
};
