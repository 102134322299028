import {
  ClientPortalLogicTypeEnum,
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
  PropertyMappingTypeEnum,
} from '../../../../shared/models';
import { BaseClientPortalStepDefinition } from './base-client-portal-step-definition';
import { StepDefinitionAvailableBlocks } from './types';

export class CompanyEditStepDefinition extends BaseClientPortalStepDefinition {
  // General
  // -------
  get type(): ClientPortalStepTypeEnum {
    return ClientPortalStepTypeEnum.company_edit;
  }
  get title(): string {
    return 'Verify your company information';
  }
  get icon(): string {
    return 'building';
  }
  get isUnique(): boolean {
    return true;
  }
  public canHaveLogics(type?: ClientPortalLogicTypeEnum): boolean {
    if (!type) return true;
    return type === ClientPortalLogicTypeEnum.prevent_submit;
  }

  get position(): number {
    return 2;
  }
  get clientPortalType(): ClientPortalTypeEnum[] {
    return [ClientPortalTypeEnum.KYB];
  }

  // Actions
  // -------
  get canBeMoved(): boolean {
    return false;
  }
  get canBeRemoved(): boolean {
    return false;
  }

  // Visibility
  // -------
  get canBeHidden(): boolean {
    return false;
  }

  // Blocks
  // --------
  public override availableBlocks(): StepDefinitionAvailableBlocks {
    return {
      field: {
        company: [
          PropertyMappingTypeEnum.default,
          PropertyMappingTypeEnum.custom,
        ],
        individual: false,
        case: [PropertyMappingTypeEnum.custom],
      },
      layout: true,
    };
  }
}

export const companyEditStepDefinition = new CompanyEditStepDefinition();
