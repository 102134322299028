import {
  Image as ChakraImage,
  ImageProps as ChakraImageProps,
  Img as ChakraImg,
  ImgProps as ChakraImgProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/media-and-icons/image
export type ImageProps = ChakraImageProps;
export const Image = ChakraImage;

export type ImgProps = ChakraImgProps;
export const Img = ChakraImg;
