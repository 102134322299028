import { ComponentStyleConfig } from '@chakra-ui/theme';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/checkbox.ts
// for default ChakraUI theme
export const CheckboxTheme: ComponentStyleConfig = {
  baseStyle: (props) => ({
    icon: {},
    control: {
      borderRadius: '3px',
      border: '1px solid',
      borderColor: 'gray.200',
      _hover: {
        borderColor: 'gray.300',
        _disabled: {
          borderColor: 'gray.50',
        },
      },
    },
    label: {
      color: 'black',
      _hover: {
        color: 'black',
      },
      _checked: {
        color: 'black',
      },
    },
  }),
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
};
