import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerProps } from '@dotfile/frontend/shared/design-system';
import { ClientPortalForms_Step } from '@dotfile/shared/data-access-client-portal';

import {
  Blocks,
  DrawerFieldsForm,
  DrawerFieldsFormSubmit,
  useFieldsForm,
  useFormDatastore,
} from '../shared';

export type IndividualFormDrawerProps = Pick<
  DrawerProps,
  'isOpen' | 'onClose'
> & {
  onClose: () => void;
  onSubmit: (newIndividualIndex: number) => void;
  step: ClientPortalForms_Step;
  header: string;
};

export const IndividualFormDrawer = ({
  isOpen,
  onClose,
  onSubmit,
  step,
  header,
}: IndividualFormDrawerProps) => {
  const {
    addIndividualData,
    data: { individuals },
  } = useFormDatastore();

  const { methods, displayedBlocks } = useFieldsForm({
    step,
    data: { case: undefined },
    isHidden: !isOpen,
  });
  const { t } = useTranslation();

  const handleSubmit: DrawerFieldsFormSubmit = useCallback(
    ({ data }) => {
      if (data.individual) {
        addIndividualData(data.individual);

        const newIndividualIndex = (individuals ?? []).length;
        onSubmit(newIndividualIndex);
      }

      onClose();
    },
    [onClose, onSubmit, addIndividualData, individuals],
  );

  return (
    <DrawerFieldsForm
      isOpen={isOpen}
      onClose={onClose}
      methods={methods}
      onSubmit={handleSubmit}
      header={header}
      submitLabel={t('forms.add_individual', {
        ns: 'client-portal',
        defaultValue: 'Add individual',
      })}
    >
      <Blocks blocks={displayedBlocks} />
    </DrawerFieldsForm>
  );
};
