import { Select } from '../../../form/select';
import { FilterOperatorEnum } from './type';

type OperatorOptions = {
  label: string;
  value: string;
};

const OPERATOR_OPTIONS: OperatorOptions[] = [
  { value: FilterOperatorEnum.include_any_of, label: 'Include any of' },
  { value: FilterOperatorEnum.include_all_of, label: 'Include all of' },
  { value: FilterOperatorEnum.exclude_if_any_of, label: 'Exclude if any of' },
  { value: FilterOperatorEnum.exclude_if_all_of, label: 'Exclude if all of' },
];

type MultiSelectOperatorProps = {
  operator?: FilterOperatorEnum;
  onChangeOperator: (operator: FilterOperatorEnum) => void;
};
export const MultiSelectOperator = ({
  operator,
  onChangeOperator,
}: MultiSelectOperatorProps): JSX.Element => {
  const handleChangeOperator = (option: OperatorOptions | null): void => {
    const selectedItem = OPERATOR_OPTIONS.find(
      ({ value }) => option?.value === value,
    );
    if (selectedItem) {
      onChangeOperator(selectedItem.value as FilterOperatorEnum);
    }
  };

  return (
    <Select
      menuPosition="absolute"
      options={OPERATOR_OPTIONS}
      value={
        OPERATOR_OPTIONS.find(({ value }) => value === operator) ??
        OPERATOR_OPTIONS[0]
      }
      onChange={handleChangeOperator}
    />
  );
};
