import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Radio,
  RadioGroup,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { FormValues } from './use-business-contact-step-form';

export const IsBusinessContactTheAuthorizedSignatoryController = ({
  isSignatoryLocked,
}: {
  isSignatoryLocked: boolean;
}) => {
  const { t } = useTranslation();
  const { control, setValue, getValues, watch } = useFormContext<FormValues>();

  const businessContactIndex = watch('businessContactIndex');
  return (
    <GroupController
      key={businessContactIndex} // Use key to force remount of the radio to reset they checked state when the business contact changes
      name="isBusinessContactTheAuthorizedSignatory"
      label={t(
        `forms.business_contact.is_business_contact_the_authorized_signatory.label`,
        {
          ns: 'client-portal',
          defaultValue: 'Are you an authorized signatory of the company?',
        },
      )}
      helper={t(
        `forms.business_contact.is_business_contact_the_authorized_signatory.helper`,
        {
          ns: 'client-portal',
          defaultValue:
            'It means you are a legal representative or someone empowered by a legal representative',
        },
      )}
      isRequired
      control={control}
      render={({ isInvalid, ...field }) => (
        <RadioGroup
          {...field}
          value={
            typeof field.value === 'boolean'
              ? field.value
                ? 'true'
                : 'false'
              : undefined
          }
          onChange={(nextValue) => {
            const isBusinessContactTheAuthorizedSignatory =
              nextValue === 'true';
            field.onChange(isBusinessContactTheAuthorizedSignatory);

            setValue(
              'signatoryIndex',
              isBusinessContactTheAuthorizedSignatory
                ? // When business contact is the authorized signatory, update the signatoryIndex accordingly
                  getValues().businessContactIndex
                : '',
            );
          }}
        >
          <VStack alignItems="start">
            <Radio value="true" isDisabled={isSignatoryLocked}>
              {t(
                `forms.business_contact.is_business_contact_the_authorized_signatory.yes`,
                {
                  ns: 'client-portal',
                  defaultValue: 'Yes, I am',
                },
              )}
            </Radio>
            <Radio value="false" isDisabled={isSignatoryLocked}>
              {t(
                `forms.business_contact.is_business_contact_the_authorized_signatory.no`,
                {
                  ns: 'client-portal',
                  defaultValue: 'No, I am not',
                },
              )}
            </Radio>
          </VStack>
        </RadioGroup>
      )}
    />
  );
};
