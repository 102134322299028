import {
  AsyncProps,
  AsyncSelect as ChakraSelect,
  AsyncCreatableSelect as CharkaCreatableSelect,
  GroupBase,
  SelectInstance,
} from 'chakra-react-select';
import { MutableRefObject } from 'react';

import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { useGenericSelectStyles } from './use-generic-select-styles';

export const GenericAsyncSelect = forwardRefWithGeneric(
  <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    {
      chakraStyles: propsChakraStyles,
      styles: propsStyles,
      isCreatable = false,
      ...props
    }: AsyncProps<Option, IsMulti, Group> & { isCreatable?: boolean },
    ref:
      | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
      | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
      | null,
  ) => {
    const { chakraStyles, styles } = useGenericSelectStyles(
      propsChakraStyles,
      propsStyles,
    );

    if (isCreatable) {
      return (
        <CharkaCreatableSelect<Option, IsMulti, Group>
          ref={ref}
          menuPlacement="auto"
          menuPosition="fixed"
          chakraStyles={chakraStyles}
          styles={styles}
          selectedOptionStyle="check"
          isSearchable={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={!props.isMulti}
          formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
          {...props}
        />
      );
    }

    return (
      <ChakraSelect<Option, IsMulti, Group>
        ref={ref}
        menuPlacement="auto"
        menuPosition="fixed"
        onKeyDown={(event) => {
          // ChakraSelect will contain an input with aria-expanded attributes when menu is open
          const isMenuOpen =
            event.currentTarget.querySelector('input')?.ariaExpanded === 'true';
          // Don't propagate enter key event to form if menu is open
          if (event.key === 'Enter' && isMenuOpen) event.stopPropagation();

          props?.onKeyDown?.(event);
        }}
        chakraStyles={chakraStyles}
        styles={styles}
        selectedOptionStyle="check"
        hideSelectedOptions={false}
        closeMenuOnSelect={!props.isMulti}
        {...props}
      />
    );
  },
);
