import { CountryCoverage } from '../country-coverage.interface';

export interface CreditsafeCountryCoverage extends CountryCoverage {
  /**
   * Billing price tier for consumption
   */
  priceTier: 'tier_0' | 'tier_2'; // More tier could be added here
}

export const CREDITSAFE_COUNTRIES: CreditsafeCountryCoverage[] = [
  {
    code: 'FR', // 🇫🇷 France
    priceTier: 'tier_0',
  },
  {
    code: 'DE', // 🇩🇪 Germany
    priceTier: 'tier_0',
  },
  {
    code: 'BE', // 🇧🇪 Belgium
    priceTier: 'tier_0',
  },
  {
    code: 'DK', // 🇩🇰 Denmark
    priceTier: 'tier_0',
  },
  // No regNo for United States on search / fetch result so disabled for now
  // {
  //   code: 'US', // 🇺🇸 United States
  //   priceTier: 'tier_0',
  // },
  {
    code: 'IE', // 🇮🇪 Ireland
    priceTier: 'tier_0',
  },
  {
    code: 'JP', // 🇯🇵 Japan
    priceTier: 'tier_0',
  },
  {
    code: 'LU', // 🇱🇺 Luxembourg
    priceTier: 'tier_0',
  },
  {
    code: 'NO', // 🇳🇴 Norway
    priceTier: 'tier_0',
  },
  {
    code: 'NL', // 🇳🇱 Netherlands
    priceTier: 'tier_0',
  },
  {
    code: 'GB', // 🇬🇧 United Kingdom
    priceTier: 'tier_0',
  },
  {
    code: 'SE', // 🇸🇪 Sweden
    priceTier: 'tier_0',
  },
  {
    code: 'IT', // 🇮🇹 Italy
    priceTier: 'tier_0',
  },
  // Tier 2
  {
    code: 'AF', // 🇦🇫 Afghanistan
    priceTier: 'tier_2',
  },
  {
    code: 'ZA', // 🇿🇦 South Africa
    priceTier: 'tier_2',
  },
  {
    code: 'AL', // 🇦🇱 Albania
    priceTier: 'tier_2',
  },
  {
    code: 'AO', // 🇦🇴 Angola
    priceTier: 'tier_2',
  },
  {
    code: 'AR', // 🇦🇷 Argentina
    priceTier: 'tier_2',
  },
  {
    code: 'AU', // 🇦🇺 Australia
    priceTier: 'tier_2',
  },
  {
    code: 'AT', // 🇦🇹 Austria
    priceTier: 'tier_2',
  },
  {
    code: 'BA', // 🇧🇦 Bosnia and Herzegovina
    priceTier: 'tier_2',
  },
  {
    code: 'BW', // 🇧🇼 Botswana
    priceTier: 'tier_2',
  },
  {
    code: 'BG', // 🇧🇬 Bulgaria
    priceTier: 'tier_2',
  },
  {
    code: 'BI', // 🇧🇮 Burundi
    priceTier: 'tier_2',
  },
  {
    code: 'KH', // 🇰🇭 Cambodia
    priceTier: 'tier_2',
  },
  {
    code: 'CM', // 🇨🇲 Cameroon
    priceTier: 'tier_2',
  },
  {
    code: 'CV', // 🇨🇻 Cape Verde
    priceTier: 'tier_2',
  },
  {
    code: 'CL', // 🇨🇱 Chile
    priceTier: 'tier_2',
  },
  {
    code: 'CY', // 🇨🇾 Cyprus
    priceTier: 'tier_2',
  },
  {
    code: 'CG', // 🇨🇬 Republic of the Congo
    priceTier: 'tier_2',
  },
  {
    code: 'KR', // 🇰🇷 South Korea
    priceTier: 'tier_2',
  },
  {
    code: 'CI', // 🇨🇮 Côte d'Ivoire
    priceTier: 'tier_2',
  },
  {
    code: 'HR', // 🇭🇷 Croatia
    priceTier: 'tier_2',
  },
  {
    code: 'DJ', // 🇩🇯 Djibouti
    priceTier: 'tier_2',
  },
  {
    code: 'ER', // 🇪🇷 Eritrea
    priceTier: 'tier_2',
  },
  {
    code: 'ES', // 🇪🇸 Spain
    priceTier: 'tier_2',
  },
  {
    code: 'EE', // 🇪🇪 Estonia
    priceTier: 'tier_2',
  },
  {
    code: 'SZ', // 🇸🇿 Eswatini
    priceTier: 'tier_2',
  },
  {
    code: 'ET', // 🇪🇹 Ethiopia
    priceTier: 'tier_2',
  },
  {
    code: 'FI', // 🇫🇮 Finland
    priceTier: 'tier_2',
  },
  {
    code: 'GA', // 🇬🇦 Gabon
    priceTier: 'tier_2',
  },
  {
    code: 'GM', // 🇬🇲 Gambia
    priceTier: 'tier_2',
  },
  {
    code: 'GH', // 🇬🇭 Ghana
    priceTier: 'tier_2',
  },
  {
    code: 'GN', // 🇬🇳 Guinea
    priceTier: 'tier_2',
  },
  {
    code: 'GQ', // 🇬🇶 Equatorial Guinea
    priceTier: 'tier_2',
  },
  {
    code: 'GW', // 🇬🇼 Guinea-Bissau
    priceTier: 'tier_2',
  },
  {
    code: 'HU', // 🇭🇺 Hungary
    priceTier: 'tier_2',
  },
  {
    code: 'IS', // 🇮🇸 Iceland
    priceTier: 'tier_2',
  },
  {
    code: 'KE', // 🇰🇪 Kenya
    priceTier: 'tier_2',
  },
  {
    code: 'XK', // 🇽🇰 Kosovo
    priceTier: 'tier_2',
  },
  {
    code: 'LA', // 🇱🇦 Laos
    priceTier: 'tier_2',
  },
  {
    code: 'LS', // 🇱🇸 Lesotho
    priceTier: 'tier_2',
  },
  {
    code: 'LV', // 🇱🇻 Latvia
    priceTier: 'tier_2',
  },
  {
    code: 'LR', // 🇱🇷 Liberia
    priceTier: 'tier_2',
  },
  {
    code: 'LI', // 🇱🇮 Liechtenstein
    priceTier: 'tier_2',
  },
  {
    code: 'LT', // 🇱🇹 Lithuania
    priceTier: 'tier_2',
  },
  {
    code: 'MK', // 🇲🇰 North Macedonia
    priceTier: 'tier_2',
  },
  {
    code: 'MG', // 🇲🇬 Madagascar
    priceTier: 'tier_2',
  },
  {
    code: 'MY', // 🇲🇾 Malaysia
    priceTier: 'tier_2',
  },
  {
    code: 'MW', // 🇲🇼 Malawi
    priceTier: 'tier_2',
  },
  {
    code: 'ML', // 🇲🇱 Mali
    priceTier: 'tier_2',
  },
  {
    code: 'MT', // 🇲🇹 Malta
    priceTier: 'tier_2',
  },
  {
    code: 'MU', // 🇲🇺 Mauritius
    priceTier: 'tier_2',
  },
  {
    code: 'MR', // 🇲🇷 Mauritania
    priceTier: 'tier_2',
  },
  {
    code: 'YT', // 🇾🇹 Mayotte
    priceTier: 'tier_2',
  },
  {
    code: 'MX', // 🇲🇽 Mexico
    priceTier: 'tier_2',
  },
  {
    code: 'MD', // 🇲🇩 Moldova
    priceTier: 'tier_2',
  },
  {
    code: 'ME', // 🇲🇪 Montenegro
    priceTier: 'tier_2',
  },
  {
    code: 'MZ', // 🇲🇿 Mozambique
    priceTier: 'tier_2',
  },
  {
    code: 'MM', // 🇲🇲 Myanmar (Burma)
    priceTier: 'tier_2',
  },
  {
    code: 'NA', // 🇳🇦 Namibia
    priceTier: 'tier_2',
  },
  {
    code: 'NE', // 🇳🇪 Niger
    priceTier: 'tier_2',
  },
  {
    code: 'NG', // 🇳🇬 Nigeria
    priceTier: 'tier_2',
  },
  {
    code: 'UG', // 🇺🇬 Uganda
    priceTier: 'tier_2',
  },
  {
    code: 'PL', // 🇵🇱 Poland
    priceTier: 'tier_2',
  },
  {
    code: 'PT', // 🇵🇹 Portugal
    priceTier: 'tier_2',
  },
  {
    code: 'CF', // 🇨🇫 Central African Republic
    priceTier: 'tier_2',
  },
  {
    code: 'CZ', // 🇨🇿 Czech Republic
    priceTier: 'tier_2',
  },
  {
    code: 'RE', // 🇷🇪 Réunion
    priceTier: 'tier_2',
  },
  {
    code: 'RO', // 🇷🇴 Romania
    priceTier: 'tier_2',
  },
  {
    code: 'RW', // 🇷🇼 Rwanda
    priceTier: 'tier_2',
  },
  {
    code: 'SH', // 🇸🇭 Saint Helena
    priceTier: 'tier_2',
  },
  {
    code: 'ST', // 🇸🇹 São Tomé and Príncipe
    priceTier: 'tier_2',
  },
  {
    code: 'SN', // 🇸🇳 Senegal
    priceTier: 'tier_2',
  },
  {
    code: 'RS', // 🇷🇸 Serbia
    priceTier: 'tier_2',
  },
  {
    code: 'SC', // 🇸🇨 Seychelles
    priceTier: 'tier_2',
  },
  {
    code: 'SL', // 🇸🇱 Sierra Leone
    priceTier: 'tier_2',
  },
  {
    code: 'SK', // 🇸🇰 Slovakia
    priceTier: 'tier_2',
  },
  {
    code: 'SI', // 🇸🇮 Slovenia
    priceTier: 'tier_2',
  },
  {
    code: 'SO', // 🇸🇴 Somalia
    priceTier: 'tier_2',
  },
  {
    code: 'CH', // 🇨🇭 Switzerland
    priceTier: 'tier_2',
  },
  {
    code: 'TW', // 🇹🇼 Taiwan
    priceTier: 'tier_2',
  },
  {
    code: 'TZ', // 🇹🇿 Tanzania
    priceTier: 'tier_2',
  },
  {
    code: 'TD', // 🇹🇩 Chad
    priceTier: 'tier_2',
  },
  {
    code: 'TH', // 🇹🇭 Thailand
    priceTier: 'tier_2',
  },
  {
    code: 'TG', // 🇹🇬 Togo
    priceTier: 'tier_2',
  },
  {
    code: 'UA', // 🇺🇦 Ukraine
    priceTier: 'tier_2',
  },
  {
    code: 'VN', // 🇻🇳 Vietnam
    priceTier: 'tier_2',
  },
  {
    code: 'ZM', // 🇿🇲 Zambia
    priceTier: 'tier_2',
  },
  {
    code: 'ZW', // 🇿🇼 Zimbabwe
    priceTier: 'tier_2',
  },
];
