import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps as ChakraFormErrorMessageProps,
  FormHelperText as ChakraFormHelperText,
  FormHelperTextProps as ChakraFormHelperTextProps,
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
  RequiredIndicator as ChakraRequiredIndicator,
  RequiredIndicatorProps as ChakraRequiredIndicatorProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/form/form-control
export type FormControlProps = ChakraFormControlProps;
export const FormControl = ChakraFormControl;

export type FormLabelProps = ChakraFormLabelProps;
export const FormLabel = ChakraFormLabel;

export type FormErrorMessageProps = ChakraFormErrorMessageProps;
export const FormErrorMessage = ChakraFormErrorMessage;

export type FormHelperTextProps = ChakraFormHelperTextProps;
export const FormHelperText = ChakraFormHelperText;

export type RequiredIndicatorProps = ChakraRequiredIndicatorProps;
export const RequiredIndicator = ChakraRequiredIndicator;
