import { useCallback } from 'react';

import { logAndAddError } from '@dotfile/frontend/shared/common';
import {
  useToast,
  UseToastOptions,
} from '@dotfile/frontend/shared/design-system';

export type HandleErrorFn = (param: HandleErrorParam) => void;

type HandleErrorParam = {
  /**
   * The caught error, should be an instance of Error
   */
  error: unknown;

  /**
   * Toast title, without any trailing punctuation
   */
  title: string;

  /**
   * toast display duration
   */
  duration?: UseToastOptions['duration'];

  /**
   * Will append "please retry later" to the toast title
   */
  // @TODO - E-69 - better handle of error
  // Ideally this should also be computed from the exact error: some error like NetworkError are always retryable, some like BadRequest are never retryable
  isRetryable?: boolean;

  /**
   * Skip logging and sending the error to Datadog RUM
   */
  skipRUM?: boolean;

  /**
   * Skip toast (if true no toast is displayed)
   */
  skipToast?: boolean;
};

/**
 *
 * @returns
 */
export const useHandleError = () => {
  const toast = useToast();

  return useCallback(
    ({
      error,
      title,
      duration,
      isRetryable,
      skipRUM,
      skipToast,
    }: HandleErrorParam): void => {
      if (!skipRUM) {
        logAndAddError(error);
      }

      // @TODO - E-69 - better handle of error
      if (!skipToast) {
        toast({
          status: 'error',
          title: isRetryable
            ? `${title}, please retry later or contact Dotfile support if the problem persist.`
            : `${title}.`,
          description:
            error instanceof Error
              ? error.message
              : error &&
                  typeof error === 'object' &&
                  'message' in error &&
                  typeof error.message === 'string'
                ? error.message
                : `${error}`,
          duration,
        });
      }
    },
    [toast],
  );
};
