import { map } from 'lodash';

import {
  ClientPortalAvailableDefaultLanguageEnum,
  LanguageEnum,
  PropertyMappingEntityEnum,
  PropertyMappingTypeEnum,
} from '../../shared/models';
import { CUSTOM_PROPERTY_LABEL_KEY_MAX_LENGTH } from '../case-management';

export const CLIENT_PORTAL_DEFAULT_LANGUAGE = LanguageEnum.en;
export const CLIENT_PORTAL_AVAILABLE_DEFAULT_LANGUAGES = Object.values(
  ClientPortalAvailableDefaultLanguageEnum,
);

/**
 * Maximum number of client portals per workspace
 */
export const MAX_CLIENT_PORTAL_PER_WORKSPACE = 50;

export const MAGIC_LINK_TTL_MIN = 1;
export const MAGIC_LINK_TTL_MAX = 21_600; // 15 days
export const MAGIC_LINK_TTL_DEFAULT = 15;

/**
 * Maximum number of steps per client portal
 * Including system and custom steps
 */
export const MAX_CLIENT_PORTAL_STEPS = 20; // 5 system + 15 custom
export const STEP_TITLE_MAX_LENGTH = 250;
export const STEP_KEY_MAX_LENGTH = 100;

/**
 * Maximum number of blocks per step
 */
export const MAX_STEP_BLOCKS = 50;
export const BLOCK_LABEL_MAX_LENGTH = 250;
export const BLOCK_KEY_MAX_LENGTH =
  // Max entity length
  Math.max(
    ...map(Object.values(PropertyMappingEntityEnum), (entity) => entity.length),
  ) +
  // Max type length
  Math.max(
    ...map(Object.values(PropertyMappingTypeEnum), (type) => type.length),
  ) +
  // Max custom property key length
  CUSTOM_PROPERTY_LABEL_KEY_MAX_LENGTH +
  // double underscore between entity, type and key
  4;
export const FIELD_OPTION_LABEL_MAX_LENGTH = 250;
export const WORDING_LABEL_MAX_LENGTH = 100;

/**
 * Maximum number of translations per client portal
 */
export const MAX_CLIENT_PORTAL_TRANSLATIONS = 20;

/**
 * Maximum number of character of the collaboration email max length
 *
 * This will include the html tag from formatting so this limit is not exactly the number of
 * character typed in the message
 */
export const COLLABORATION_EMAIL_CUSTOM_MESSAGE_MAX_LENGTH = 1000;
