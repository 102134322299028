import {
  Text as ChakraText,
  TextProps as CharkaTextProps,
  forwardRef,
} from '@chakra-ui/react';

import { isString } from '@dotfile/shared/common';

// @doc https://chakra-ui.com/docs/typography/text
export type TextProps = CharkaTextProps;
export const Text = forwardRef<CharkaTextProps, typeof ChakraText>(
  ({ children, ...rest }, ref) => {
    const titleProps: Pick<TextProps, 'title'> = {};
    if (!('title' in rest) && rest.noOfLines) {
      if (typeof children === 'string') {
        titleProps.title = children;
      } else if (Array.isArray(children)) {
        // When some text is conditionally rendered using boolean like
        // <Text>{!someBoolean && 'Prefix '}value<Text>
        // then children is an array
        titleProps.title = children.filter(Boolean).join('');
      } else {
        if (children) {
          console.warn(
            'Unable to set `title` props of text with non-string children',
            { children },
          );
        }
      }
    }

    return (
      <ChakraText
        as={
          isString(rest.variant) && rest.variant?.startsWith('inline')
            ? 'span'
            : 'p'
        }
        {...titleProps}
        {...rest}
        ref={ref}
      >
        {children}
      </ChakraText>
    );
  },
);
