import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

export type InProgressUploadContextValue = {
  hasInProgressUpload: boolean;
  startUpload: (id: string) => void;
  endUpload: (id: string) => void;
};

const InProgressUploadContext = createContext<
  InProgressUploadContextValue | undefined
>(undefined);

export const InProgressUploadContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [requests, setRequests] = useState<string[]>([]);

  const context: InProgressUploadContextValue = useMemo(
    () => ({
      hasInProgressUpload: requests.length > 0,
      startUpload: (id: string) => setRequests((prev) => [...prev, id]),
      endUpload: (id: string) =>
        setRequests((prev) => prev.filter((p) => p !== id)),
    }),
    [requests],
  );

  return (
    <InProgressUploadContext.Provider value={context}>
      {children}
    </InProgressUploadContext.Provider>
  );
};

export const useInProgressUploadContext = (): InProgressUploadContextValue => {
  const context = useContext(InProgressUploadContext);

  if (context === undefined) {
    throw new Error(
      'useInProgressUploadContext must be used within a InProgressUploadContextProvider',
    );
  }
  return context;
};
