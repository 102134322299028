import { AmlListTypeEnum } from '../../../shared/models';

export type AmlListTypeDefinition = {
  label: string;
  /**
   * List from Comply Advantage for each list type
   * @see https://docs.complyadvantage.com/api-docs?javascript#filters-in-searches
   */
  lists: string[];
};

export const amlListTypeDefinitions: Record<
  AmlListTypeEnum,
  AmlListTypeDefinition
> = {
  [AmlListTypeEnum.sanction]: {
    label: 'Sanction',
    lists: ['sanction'],
  },
  [AmlListTypeEnum.warning]: {
    label: 'Warning',
    lists: ['warning'],
  },
  [AmlListTypeEnum.fitness_probity]: {
    label: 'Fitness & Probity',
    lists: ['fitness-probity'],
  },
  [AmlListTypeEnum.pep]: {
    label: 'PEP',
    lists: ['pep', 'pep-class-1', 'pep-class-2', 'pep-class-3', 'pep-class-4'],
  },
  [AmlListTypeEnum.adverse_media]: {
    label: 'Adverse Media',
    lists: [
      'adverse-media',
      'adverse-media-v2-property',
      'adverse-media-v2-financial-aml-cft',
      'adverse-media-v2-fraud-linked',
      'adverse-media-v2-narcotics-aml-cft',
      'adverse-media-v2-violence-aml-cft',
      'adverse-media-v2-terrorism',
      'adverse-media-v2-cybercrime',
      'adverse-media-v2-general-aml-cft',
      'adverse-media-v2-regulatory',
      'adverse-media-v2-financial-difficulty',
      'adverse-media-v2-violence-non-aml-cft',
      'adverse-media-v2-other-financial',
      'adverse-media-v2-other-serious',
      'adverse-media-v2-other-minor',
    ],
  },
};
