import {
  AuthTokenService,
  BaseAuthService,
  fetchResponseToError,
} from '@dotfile/frontend/shared/common';
import { ClientPortalLinks } from '@dotfile/shared/domain';

import { ContactAccessToken } from './type';

export class ContactAuthService extends BaseAuthService<ContactAccessToken> {
  private clientPortalId;

  constructor(
    private readonly baseAPI: string,
    clientPortalId: string | null,
  ) {
    super(
      new AuthTokenService({
        refreshUrl: `${baseAPI}/auth/session/refresh/${clientPortalId}`,
        syncLogoutKey: 'auth_sync_logout',
      }),
    );

    this.clientPortalId = clientPortalId;
  }

  public async requestMagicLink(email: string): Promise<boolean> {
    const response = await fetch(`${this.baseAPI}/auth/magic-link/request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email,
        clientPortalId: this.clientPortalId,
      }),
    });

    // @TODO - E-69 - better handle of error
    if (!response.ok) {
      throw await fetchResponseToError(
        response,
        'Unable to request magic link',
      );
    }

    return true;
  }

  public async requestMagicLinkFromToken(token: string): Promise<boolean> {
    const response = await fetch(
      `${this.baseAPI}/auth/magic-link/request-from-token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          token,
          clientPortalId: this.clientPortalId,
        }),
      },
    );

    // @TODO - E-69 - better handle of error
    if (!response.ok) {
      throw await fetchResponseToError(
        response,
        'Unable to request magic link from token',
      );
    }

    return true;
  }

  public async redeemMagicToken(token: string): Promise<ContactAccessToken> {
    const response = await fetch(`${this.baseAPI}/auth/magic-link/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        token,
      }),
    });

    // @TODO - E-69 - better handle of error
    if (!response.ok) {
      throw await fetchResponseToError(response, 'Unable to redeem token');
    }

    const contactToken: ContactAccessToken = await response.json(); //we only get here if there is no error

    if (contactToken.accessToken) {
      this.authToken.setAuthToken(contactToken);
    }

    return contactToken;
  }

  public setAuthToken(contactToken: ContactAccessToken) {
    this.authToken.setAuthToken(contactToken);
  }

  public async logout(): Promise<string> {
    const accessToken = this.authToken.getAccessToken();
    this.authToken.clearAuthToken();
    if (accessToken) {
      const request = new Request(
        `${this.baseAPI}/auth/session/logout/${this.clientPortalId}`,
        {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          }),
          credentials: 'include',
        },
      );
      await fetch(request);
    }

    return ClientPortalLinks.Login();
  }
}
