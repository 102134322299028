import { HStack } from '@chakra-ui/react';

import { Skeleton } from '../../feedback/skeleton/skeleton';
import { Grid, GridItem } from '../../layout/grid/grid';
import { TimelineEdge } from './timeline-edge';

const skeletonWidths = ['80%', '100%', '65%'];

export type TimelineSkeletonProps = {
  loadingItemCount: number;
};

export const TimelineSkeleton = ({
  loadingItemCount,
}: TimelineSkeletonProps): JSX.Element => (
  <>
    {new Array(loadingItemCount).fill('').map((_, index) => {
      const width = skeletonWidths[index % skeletonWidths.length];
      return (
        <Grid
          key={index}
          gap="4"
          alignItems="start"
          gridTemplateColumns="var(--chakra-sizes-8) auto"
        >
          <GridItem h="100%">
            <TimelineEdge isLoading withEdge={loadingItemCount - 1 !== index} />
          </GridItem>
          <GridItem as={HStack} minHeight="8" alignItems="center">
            <Skeleton w={width} h="4" />
          </GridItem>
        </Grid>
      );
    })}
  </>
);
