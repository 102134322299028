import { useCallback, useRef } from 'react';
import { OptionalKeys } from 'ts-essentials';

import {
  encodeSearch,
  Location,
  pushUnsafe,
  replaceUnsafe,
  Search,
  useLocation,
} from '@swan-io/chicane';

type SetParamsOptions = {
  override?: boolean;
  replace?: boolean;
};

/**
 * This hooks is created on top of the useLocation/Router from '@swan-io/chicane'.
 * Useful when we need to update the url query params of a page from different components
 *
 * @param pushFn The router function to update the url.
 *               Query params will be inferred from this function parameters.
 *               If undefined, Query Param won't be updated.
 * @returns [0] The query params as an object
 * @returns [1] A function to update the query params
 * @returns [2] A function to remove query params
 */
export const usePageParams = <P extends PushFn>(
  // Only used for typing of query param
  pushFn?: P,
): readonly [
  PageParams<P>,
  (value: Partial<PageParams<P>>, opts?: SetParamsOptions) => void,
  (params: PageParamsKeys<P>[]) => void,
] => {
  // When the pushFn is undefined, this will prevent any write to the Query Param
  const isEnable = !!pushFn;

  // Internally use useLocation to extract existing route path
  const {
    search: params,
    raw: { path },
  } = useLocation() as unknown as {
    search: PageParams<P>;
  } & Omit<Location, 'search'>;

  // Use ref for current param/path in callback in order to keep a stable function
  // identity when param/path changes
  const paramRef = useRef(params);
  const pathRef = useRef(path);
  paramRef.current = params;
  pathRef.current = path;

  const setParams = useCallback(
    (
      value: Partial<PageParams<P>>,
      { override, replace }: SetParamsOptions = {},
    ) => {
      if (!isEnable) return;

      const nextSearch = override ? value : { ...paramRef.current, ...value };

      const next = pathRef.current + encodeSearch(nextSearch as Search);

      replace ? replaceUnsafe(next) : pushUnsafe(next);
    },
    [isEnable],
  );

  const removeParams = useCallback(
    (paramNames: PageParamsKeys<P>[]) => {
      if (!isEnable) return;

      const filtered = { ...paramRef.current } as PageParams<P> & Search;
      paramNames.forEach((key) => {
        // @swan-io/chicane Search doesn't accept `null` but it's the way to remove a query param
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filtered[key] = null as any;
      });

      pushUnsafe(pathRef.current + encodeSearch(filtered));
    },
    [isEnable],
  );

  return [params, setParams, removeParams] as const;
};

// With Record<string, unknown>, for some route typescript
// complains about missing required parameter for path params
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PushFn = (params: any) => void;

type PageParams<P extends PushFn> = Pick<
  Parameters<P>[0],
  OptionalKeys<Parameters<P>[0]>
>;

type PageParamsKeys<P extends PushFn> = OptionalKeys<Parameters<P>[0]>;
