import { humanCase } from '@dotfile/shared/common';

import {
  CaseModel,
  CaseStatusEnum,
  PropertyMappingEntityEnum,
  PropertyMappingTypeEnum,
  RiskModeEnum,
} from '../../shared/models';
import { CASE_NAME_MAX_LENGTH } from './constants';
import { DefaultPropertiesBuilder } from './default-properties-builder';
import { Property, PropertyTypeEnum } from './types';

type CaseExtraProperties = {
  currentRisk: RiskModeEnum;
};

type CaseModelWithExtraProperties = CaseModel & CaseExtraProperties;

// @NOTE Also update the test snapshot when modifying the properties
type CasePropertyKey = keyof Omit<
  CaseModelWithExtraProperties,
  | 'assignee'
  | 'assigneeId'
  | 'caseTags'
  | 'clientPortal'
  | 'clientPortalId'
  | 'communicationMessages'
  | 'companies'
  | 'customPropertyValues'
  | 'flags'
  | 'individuals'
  | 'metadata'
  | 'notes'
  | 'relations'
  | 'report'
  | 'reviews'
  | 'risks'
  | 'template'
  | 'templateId'
  | 'workspace'
  | 'workspaceId'
>;

export const caseDefaultPropertiesDefinition: Record<
  CasePropertyKey,
  Property<PropertyTypeEnum>
> = new DefaultPropertiesBuilder<CaseModelWithExtraProperties>(
  PropertyMappingEntityEnum.case,
)
  .addProperty('id', PropertyTypeEnum.text)
  .addProperty('lastActivityAt', PropertyTypeEnum.date, {
    label: 'Last activity',
  })
  .addProperty('name', PropertyTypeEnum.text, {
    alwaysRequired: true,
    settings: {
      isMultiLine: false,
      maxLength: CASE_NAME_MAX_LENGTH,
    },
  })
  .addProperty('status', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CaseStatusEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })
  .addProperty('contactHasActions', PropertyTypeEnum.boolean)
  .addProperty('reviewerHasActions', PropertyTypeEnum.boolean)
  .addProperty('externalId', PropertyTypeEnum.text)
  .addProperty('reminder', PropertyTypeEnum.unknown)
  .addProperty('lastContactActionAt', PropertyTypeEnum.date)
  .addProperty('createdAt', PropertyTypeEnum.date)
  .addProperty('updatedAt', PropertyTypeEnum.date)
  .addProperty('deletedAt', PropertyTypeEnum.date)
  .addProperty('currentRisk', PropertyTypeEnum.unknown, {
    label: 'Risk',
    mapping: {
      type: PropertyMappingTypeEnum.relation,
    },
  })
  .getDefinition();
