import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import { Input } from '@dotfile/frontend/shared/design-system';

import { FormValues } from './use-business-contact-step-form';

export const EmailInputController = ({
  isBusinessContactLocked,
}: {
  isBusinessContactLocked?: boolean;
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<FormValues>();

  return (
    <GroupController
      isRequired
      key="email"
      name="email"
      label={t(`steps.business_contact.email.label`, {
        ns: 'dynamic',
        defaultValue: 'Email',
      })}
      control={control}
      render={(field) => (
        <Input type="email" {...field} isDisabled={isBusinessContactLocked} />
      )}
    />
  );
};
