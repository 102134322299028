import { LucideIcon, Type } from 'lucide-react';
import { AnySchema, SchemaOf } from 'yup';

import {
  CustomPropertyTextSettings,
  CustomPropertyTypeEnum,
} from '../../../../shared/models';
import {
  CUSTOM_PROPERTY_TEXT_MULTILINE_MAX_LENGTH,
  CUSTOM_PROPERTY_TEXT_SINGLELINE_MAX_LENGTH,
} from '../../../../shared/properties';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import { CustomPropertyValue, CustomPropertyValueSchemaParam } from './type';

export class TextCustomPropertyDefinition extends BaseCustomPropertyDefinition<CustomPropertyTextSettings> {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.text;
  }

  get label(): string {
    return 'Text';
  }

  get icon(): LucideIcon {
    return Type;
  }

  // Settings
  // --------

  override get defaultSettings(): CustomPropertyTextSettings {
    return { isMultiLine: false };
  }

  public override settingsSchema(): SchemaOf<CustomPropertyTextSettings> {
    const schema = yup.object().shape({
      isMultiLine: yup.boolean().required(),
    });
    return schema;
  }

  // Value
  // -----
  /**
   * singleLine-> 250 characters
   * multiLine -> 1500 characters
   * @param settings
   * @returns
   */
  public valueMaxLength(settings: CustomPropertyTextSettings): number {
    if (settings.isMultiLine) {
      return CUSTOM_PROPERTY_TEXT_MULTILINE_MAX_LENGTH;
    }
    return CUSTOM_PROPERTY_TEXT_SINGLELINE_MAX_LENGTH;
  }

  override get defaultValue(): CustomPropertyValue | null {
    return '';
  }

  public valueSchema(
    customProperty: CustomPropertyValueSchemaParam,
  ): AnySchema {
    const maxLength = this.valueMaxLength(
      this.settingsSchema().validateSync(customProperty.settings),
    );

    const schema = yup.string().max(maxLength).nullable().strict();

    return schema;
  }
}

export const textCustomPropertyDefinition = new TextCustomPropertyDefinition();
