import { SchemaOf } from 'yup';

import {
  CheckTypeEnum,
  EntityTypeEnum,
  IdDocumentDataModel,
  IdDocumentVendorEnum,
  PermissionResourceEnum,
  VendorEnum,
} from '../../../shared/models';
import {
  BaseCheckDefinition,
  BaseCheckSettingsDefinition,
} from '../definitions/base-check-definition';
import { FindOptions } from '../definitions/types';
import {
  ID_DOCUMENT_IDNOW_MAX_FILE_SIZE,
  ID_DOCUMENT_VERIFF_MAX_FILE_SIZE,
} from './constants';
import { defaultIdDocumentSettings } from './default-settings';
import { IdDocumentCheckInput } from './id-document-check-input';
import {
  idDocumentDataSchema,
  idDocumentSettingsSchema,
} from './validation/id-document.schema';

export class IdDocumentCheckDefinition extends BaseCheckDefinition<
  IdDocumentDataModel,
  IdDocumentCheckInput
> {
  // General
  // -------

  get type(): CheckTypeEnum {
    return CheckTypeEnum.id_document;
  }
  get icon(): string {
    // This is a custom icon
    // @see libs/frontend/shared/design-system/src/lib/media-icon/icon/custom/identity-card-check.ts
    return 'IdentityCardCheck';
  }
  get checkRelationKey(): 'idDocumentData' {
    return 'idDocumentData';
  }
  public findOptions(): FindOptions<IdDocumentDataModel> {
    return {
      order: { createdAt: 'DESC' },
    };
  }
  get availableTarget(): EntityTypeEnum[] {
    return [EntityTypeEnum.individual];
  }

  get permissionResource(): PermissionResourceEnum {
    return PermissionResourceEnum.check_id_document;
  }

  // Integrations
  // ------------

  get availableVendors(): VendorEnum[] {
    return [VendorEnum.veriff, VendorEnum.idnow];
  }
  // Settings
  // --------

  get settings(): BaseCheckSettingsDefinition<IdDocumentDataModel> {
    return { default: defaultIdDocumentSettings };
  }

  // Display
  // -------

  get label(): string {
    return 'Identity document';
  }
  // Validation
  // ------

  override get validationSchemas(): {
    settings?: SchemaOf<IdDocumentCheckInput['settings']>;
    data?: SchemaOf<IdDocumentCheckInput['data']>;
  } {
    return {
      settings: idDocumentSettingsSchema(),
      data: idDocumentDataSchema(),
    };
  }

  public getMaxFileSize(vendor: IdDocumentVendorEnum): number {
    const maxSizePerVendor: Record<IdDocumentVendorEnum, number> = {
      [IdDocumentVendorEnum.veriff]: ID_DOCUMENT_VERIFF_MAX_FILE_SIZE,
      [IdDocumentVendorEnum.idnow]: ID_DOCUMENT_IDNOW_MAX_FILE_SIZE,
    };

    return maxSizePerVendor[vendor];
  }
}

export const idDocumentCheckDefinition = new IdDocumentCheckDefinition();
