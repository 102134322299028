import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Text } from '@dotfile/frontend/shared/design-system';
import { formatDate, parseAbsoluteDate } from '@dotfile/shared/common';
import {
  CheckResultEnum,
  DocumentAnalysisDetailedResultModel,
} from '@dotfile/shared/domain';

import { DataComparisonNoLabel } from './data-comparison-no-data';

export const ValidAge = ({
  detailedResult,
  issuingDate,
  informationDefinition,
}: {
  detailedResult: DocumentAnalysisDetailedResultModel;
  issuingDate: string | null;
  informationDefinition: { label: (t: TFunction) => string };
}) => {
  const { t, i18n } = useTranslation();
  const receivedDays =
    // We're using != (not triple) to be resilient against null and undefined
    detailedResult.received != null
      ? parseInt(detailedResult.received, 10)
      : null;
  const expectedDays =
    // We're using != (not triple) to be resilient against null and undefined
    detailedResult.expected != null
      ? parseInt(detailedResult.expected, 10)
      : null;

  if (
    detailedResult.result === CheckResultEnum.approved &&
    receivedDays !== null
  ) {
    return (
      <Text fontSize="sm" color="black">
        {t('document_analysis.age_validation_approved', {
          ns: 'components',
          defaultValue: 'Document age is valid',
        })}
        :&nbsp;
        <Text fontSize="sm" color="blue.700" as="span">
          {t(`document_analysis.age_day_received`, {
            ns: 'components',
            count: receivedDays,
            defaultValue: `(${receivedDays} day${
              receivedDays > 1 ? 's' : ''
            } old)`,
          })}
        </Text>
      </Text>
    );
  }

  if (
    detailedResult.result === CheckResultEnum.rejected &&
    receivedDays !== null &&
    expectedDays !== null &&
    issuingDate
  ) {
    return (
      <Text fontSize="sm" color="black">
        {t('document_analysis.age_validation_rejected', {
          ns: 'components',
          defaultValue: 'Document is too old',
        })}
        :&nbsp;
        <Text fontSize="sm" color="blue.700" as="span">
          {formatDate(parseAbsoluteDate(issuingDate), {
            locale: i18n.language,
          })}
          &nbsp;
          {t(`document_analysis.age_day_received`, {
            ns: 'components',
            count: receivedDays,
            defaultValue: `(${receivedDays} day${
              receivedDays > 1 ? 's' : ''
            } old)`,
          })}
        </Text>
        <br />
        {t(`document_analysis.age_day_expected`, {
          ns: 'components',
          count: expectedDays,
          defaultValue: `Expected to be under ${expectedDays} day${
            expectedDays > 1 ? 's' : ''
          }`,
        })}
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.error) {
    return (
      <Text fontSize="sm" color="black">
        {t('document_analysis.age_validation_error', {
          ns: 'components',
          defaultValue: 'Document age not found',
        })}
      </Text>
    );
  }

  return (
    <DataComparisonNoLabel informationDefinition={informationDefinition} />
  );
};
