import { PartsStyleInterpolation, StyleFunctionProps } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';
import { cssVar, mode } from '@chakra-ui/theme-tools';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/tooltip.ts
// for default ChakraUI theme

const $bg = cssVar('tooltip-bg');
const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle: Partial<PartsStyleInterpolation> = (
  props: StyleFunctionProps,
) => {
  const bg = mode('gray.800', 'gray.800')(props);
  return {
    [$bg.variable]: `colors.${bg}`,
    px: '8px',
    py: '2px',
    bg: [$bg.reference],
    [$arrowBg.variable]: [$bg.reference],
    color: mode('whiteAlpha.900', 'gray.900')(props),
    borderRadius: 'sm',
    fontWeight: 'medium',
    fontSize: 'sm',
    boxShadow: 'md',
    maxW: '320px',
    zIndex: 'tooltip',
  };
};

export const TooltipTheme: ComponentStyleConfig = {
  baseStyle,
};
