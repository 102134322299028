import { TFunction } from 'i18next';
import { SchemaOf } from 'yup';

import { formatDate } from '@dotfile/shared/common';

import { COUNTRIES } from '../../../../../shared/country';
import {
  DocumentAnalysisModelEnum,
  DocumentAnalysisProofOfAddressDetailedResultsModel,
  DocumentAnalysisProofOfAddressDocumentTypesEnum,
  DocumentAnalysisProofOfAddressInformationModel,
  DocumentAnalysisProofOfAddressParametersModel,
} from '../../../../../shared/models';
import {
  DocumentAnalysisDefinition,
  DocumentAnalysisDefinitionDetailedResults,
  DocumentAnalysisDefinitionInformation,
  DocumentAnalysisDefinitionParameters,
} from '../document-analysis-definition';
import { documentAnalysisProofOfAddressParametersSchema } from './validation';

export class DocumentAnalysisProofOfAddressDefinition extends DocumentAnalysisDefinition<
  DocumentAnalysisProofOfAddressParametersModel,
  DocumentAnalysisProofOfAddressDetailedResultsModel,
  DocumentAnalysisProofOfAddressInformationModel
> {
  override get model(): DocumentAnalysisModelEnum {
    return DocumentAnalysisModelEnum.proof_of_address;
  }

  override get label(): string {
    return 'Proof of address';
  }

  override get parameters(): DocumentAnalysisDefinitionParameters<DocumentAnalysisProofOfAddressParametersModel> {
    return {
      model: {
        label: 'Model',
      },
      maxAgeInDays: {
        label: 'Max age in days',
      },
      authorizedDocuments: {
        label: 'Authorized documents',
      },
    };
  }

  override get detailedResults(): DocumentAnalysisDefinitionDetailedResults<DocumentAnalysisProofOfAddressDetailedResultsModel> {
    return {
      nameMatching: {
        label: 'Name match',
      },
      addressMatching: {
        label: 'Address match',
      },
      validAgeInDays: {
        label: 'Valid age since issuing date',
      },
      validDocumentType: {
        label: 'Valid document type',
      },
    } as const;
  }

  override get information(): DocumentAnalysisDefinitionInformation<DocumentAnalysisProofOfAddressInformationModel> {
    return {
      address: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.address', {
            defaultValue: 'Address',
            ns: 'domain',
          }),
      },
      issuingDate: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.issuing_date', {
            ns: 'domain',
            defaultValue: 'Issuing date',
          }),
        format: (data) => formatDate(data),
      },
      name: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.name', {
            ns: 'domain',
            defaultValue: 'Name',
          }),
      },
      documentType: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.document_type', {
            ns: 'domain',
            defaultValue: 'Document type',
          }),
        format: (value: string, t: TFunction) =>
          this.isValidDocumentType(value)
            ? this.documentTypes[value].label(t)
            : value,
      },
      otherDocumentType: {
        label: (t: TFunction) =>
          t(
            'check.document.document_analysis.information.other_document_type',
            {
              ns: 'domain',
              defaultValue: 'Other Document type',
            },
          ),
      },
      country: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.country', {
            ns: 'domain',
            defaultValue: 'Country',
          }),
        format: (value) =>
          COUNTRIES.find(({ code }) => code === value)?.name ?? value,
      },
      issuer: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.issuer', {
            ns: 'domain',
            defaultValue: 'Issuer',
          }),
      },
    };
  }

  get documentTypes(): Record<
    DocumentAnalysisProofOfAddressDocumentTypesEnum,
    { label: (t: TFunction) => string }
  > {
    return {
      energy_bill: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.energy_bill', {
            defaultValue: 'Energy bill',
            ns: 'domain',
          }),
      },
      official_document: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.official_document', {
            defaultValue: 'Official document',
            ns: 'domain',
          }),
      },
      telco_bill: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.telco_bill', {
            defaultValue: 'Telecom bill',
            ns: 'domain',
          }),
      },
      other: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.other', {
            defaultValue: 'Other',
            ns: 'domain',
          }),
      },
    };
  }

  override get defaultParameters(): Omit<
    DocumentAnalysisProofOfAddressParametersModel,
    'model'
  > {
    return {
      maxAgeInDays: 90,
    };
  }

  override get validationSchemas(): {
    parameters?: SchemaOf<DocumentAnalysisProofOfAddressParametersModel>;
  } {
    return { parameters: documentAnalysisProofOfAddressParametersSchema() };
  }

  private isValidDocumentType(
    value: string,
  ): value is DocumentAnalysisProofOfAddressDocumentTypesEnum {
    return Object.values(
      DocumentAnalysisProofOfAddressDocumentTypesEnum,
    ).includes(value as DocumentAnalysisProofOfAddressDocumentTypesEnum);
  }
}

export const documentAnalysisProofOfAddressDefinition =
  new DocumentAnalysisProofOfAddressDefinition();
