import {
  ChakraStylesConfig,
  GroupBase,
  StylesConfig,
} from 'chakra-react-select';
import { useMemo } from 'react';

export const useGenericSelectStyles = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  propsChakraStyles: ChakraStylesConfig<Option, IsMulti, Group> | undefined,
  propStyles: StylesConfig<Option, IsMulti, Group> | undefined,
): {
  chakraStyles: ChakraStylesConfig<Option, IsMulti, Group>;
  styles: StylesConfig<Option, IsMulti, Group>;
} => {
  const chakraStyles = useMemo(
    (): ChakraStylesConfig<Option, IsMulti, Group> => ({
      ...propsChakraStyles,
      dropdownIndicator: (provided, state) => ({
        ...provided,
        background: 'transparent',
        w: '10',
        ...propsChakraStyles?.dropdownIndicator?.(provided, state),
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
        // keep base border radius regardless of size
        borderRadius: 'base',
        boxSizing: 'border-box',
        ...propsChakraStyles?.control?.(provided, state),
      }),
      downChevron: (provided, state) => ({
        ...provided,
        fontSize: '24px', // custom size because of the down chevron svg view-box which including small padding
        ...propsChakraStyles?.downChevron?.(provided, state),
      }),
      crossIcon: (provided, state) => ({
        ...provided,
        fontSize: '12px', // custom size because of the down chevron svg view-box which including small padding
        ...propsChakraStyles?.crossIcon?.(provided, state),
      }),
      container: (provided, state) => ({
        ...provided,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        minWidth: '0', // This prevent the select from growing when selecting an option with very long label
        ...propsChakraStyles?.container?.(provided, state),
      }),
      menuList: (provided, state) => ({
        ...provided,
        // When there are more options than what fit given the max height, 310px make so the bottom
        // options is partially visible to better communicate that it's possible to scroll to see
        // more options
        maxHeight: '310px',
        ...propsChakraStyles?.menuList?.(provided, state),
      }),
    }),
    [propsChakraStyles],
  );

  const styles = useMemo(
    (): StylesConfig<Option, IsMulti, Group> => ({
      // Fix the positioning inside a container
      // @see https://github.com/JedWatson/react-select/issues/4680
      menuPortal: ({ left, top, ...provided }, state) => ({
        ...provided,
        /**
         * @NOTE:  Most of our UI components that should be
         * on top are declared with zIndex: 10; (e.g: Client portal footer)
         * Make sure that select are always accessible.
         */
        zIndex: 11,
        top,
        ...propStyles?.menuPortal?.({ top, left, ...provided }, state),
      }),
    }),
    [propStyles],
  );

  return { chakraStyles, styles };
};
