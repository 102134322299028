import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  UpdateIdDocumentReferenceDataInput,
  useUpdateIdDocumentReferenceDataMutation,
} from '@dotfile/shared/data-access-client-portal';

export const useUpdateReferenceData = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();

  const [runMutation, result] = useUpdateIdDocumentReferenceDataMutation();

  const updateReferenceData = useCallback(
    async (input: UpdateIdDocumentReferenceDataInput) => {
      try {
        const res = await runMutation({
          variables: {
            input,
          },
        });

        return res.data?.updateIdDocumentReferenceData;
      } catch (error) {
        handleError({
          title: t('checks.id_document.update_reference_data.error', {
            defaultValue: 'Unable to update reference data',
            ns: 'client-portal',
          }),
          error,
        });
        return null;
      }
    },
    [runMutation, handleError, t],
  );

  return [updateReferenceData, result] as const;
};
