export const IdentityDocumentTypeEnum = {
  passport: 'passport',
  identity_card: 'identity_card',
  driving_license: 'driving_license',
  residence_permit: 'residence_permit',
  visa: 'visa',
  other: 'other',
} as const;

export type IdentityDocumentTypeEnum =
  (typeof IdentityDocumentTypeEnum)[keyof typeof IdentityDocumentTypeEnum];
