import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

const EXCLUDED_WORKSPACE_ID = [
  // PRODUCTION
  '406b96ef-e4fb-45cc-bf70-624850cfde32', // Arkham
  '4f5ef6a3-b351-4ec5-b104-a2299f5d653a', // Arkham STAGING
];

export const useStartDatadogRumRecordingForWorkspace = (
  workspaceId: string | undefined | null,
) => {
  useEffect(() => {
    if (workspaceId) {
      // Set workspace-id in Datadog context when available
      // @see https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#global-context
      datadogRum.setGlobalContextProperty('workspace-id', workspaceId);

      if (!EXCLUDED_WORKSPACE_ID.includes(workspaceId)) {
        // Datadog RUM Session replay recording is configured to start manually
        // because we want to exclude some specific workspaces
        datadogRum.startSessionReplayRecording();

        console.debug(`🐶 Datadog RUM Session recording started`);
      }
    }
  }, [workspaceId]);
};
