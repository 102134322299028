import { SaveForLaterAddBusinessContactModal } from './save-for-later-add-business-contact-modal';
import { SaveForLaterConfirmModal } from './save-for-later-confirm-modal';
import { SaveForLaterState } from './use-handle-save-for-later';

type SaveForLaterModalsProps = SaveForLaterState['modals'];

export const SaveForLaterModals = ({
  confirm,
  saveBusinessContact,
}: SaveForLaterModalsProps) => {
  return (
    <>
      <SaveForLaterConfirmModal {...confirm} />

      <SaveForLaterAddBusinessContactModal
        {...saveBusinessContact}
        onSaved={confirm.onOpen}
      />
    </>
  );
};
