import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

export const entityLegalFormValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.entity_legal_form
> = ({ isRequired, localizedValidation }) => {
  let schema = yup.string().nullable().entityLegalForm();

  // Add required depending on settings
  if (isRequired) {
    schema = schema
      .required(localizedValidation.required)
      .typeError(localizedValidation.required);
  }

  return schema.strict();
};
