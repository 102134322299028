import { MarkOptional } from 'ts-essentials';

import {
  CaseRelationModel,
  CaseRelationRoleEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { CASE_RELATION_POSITION_MAX_LENGTH } from '../constants';

export const caseRelationSchema = ({
  percentageNoValueLabel = 'null',
}: {
  // This is not optimal and should be improved
  // @TODO - E-1343 - Yup umbrella issue
  /**
   * Allow to change the wording for no value for ownership percentage
   * - `'null'` for API
   * - `'empty'` for UI
   */
  percentageNoValueLabel?: 'null' | 'empty';
} = {}): yup.SchemaOf<
  MarkOptional<
    Pick<
      CaseRelationModel,
      | 'caseId'
      | 'sourceCompanyId'
      | 'targetIndividualId'
      | 'targetCompanyId'
      | 'ownershipPercentage'
      | 'votingRightsPercentage'
      | 'roles'
      | 'position'
    >,
    'targetIndividualId' | 'targetCompanyId'
  >
> => {
  return yup
    .object()
    .shape(
      {
        caseId: yup.string().uuid().required(),
        sourceCompanyId: yup.string().uuid().required(),

        targetIndividualId: yup
          .string()
          .uuid()
          .when('targetCompanyId', {
            is: (targetCompanyId: string) => !targetCompanyId,
            then: (schema) =>
              schema.required(
                'targetCompanyId or targetIndividualId is required',
              ),
            otherwise: (schema) =>
              schema
                .nullable()
                .oneOf(
                  [null],
                  'Only one of targetCompanyId and targetIndividualId can be set',
                )
                .default(null),
          }),
        targetCompanyId: yup
          .string()
          .uuid()
          .when('targetIndividualId', {
            is: (targetIndividualId: string) => !targetIndividualId,
            then: (schema) =>
              // Keep it optional here to avoid creating 2 errors since
              // there will already be a validation error on targetIndividualId
              // when both are missing
              schema.optional().nullable(),
            otherwise: (schema) =>
              // Keep it optional here to avoid creating 2 errors since
              // there will already be a validation error on targetIndividualId
              // when both are set
              schema.optional().nullable(),
          }),

        ownershipPercentage: yup
          .number()
          .strict()
          .typeError(
            `ownershipPercentage must be a number or ${percentageNoValueLabel}`,
          )
          .min(0)
          .max(100)
          .optional()
          .nullable()
          .default(null),
        votingRightsPercentage: yup
          .number()
          .strict()
          .typeError(
            `votingRightsPercentage must be a number or ${percentageNoValueLabel}`,
          )
          .min(0)
          .max(100)
          .optional()
          .nullable()
          .default(null),

        roles: yup
          .array(yup.mixed().oneOf(Object.values(CaseRelationRoleEnum)))
          .uniqueItems()
          .strict()
          .optional()
          .default([]),
        position: yup
          .string()
          .optionalString()
          .max(CASE_RELATION_POSITION_MAX_LENGTH),
      },
      [['targetCompanyId', 'targetIndividualId']],
    )
    .noUnknown()
    .defined();
};
