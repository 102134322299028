// Focus only visible if keyboard
// @NOTE - later we will need to tackle outline focus for A11Y properly
import 'focus-visible/dist/focus-visible';
import { PropsWithChildren } from 'react';

import { ChakraProvider, ChakraTheme, DeepPartial } from '@chakra-ui/react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
// Fonts
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/merriweather/400.css';

import baseTheme from './theme/theme';

export type DesignSystemProviderProps = PropsWithChildren<{
  nonce?: string;
  theme?: DeepPartial<ChakraTheme>;
}>;

export const DesignSystemProvider: React.FC<DesignSystemProviderProps> = ({
  nonce,
  children,
  theme = baseTheme,
}: DesignSystemProviderProps) => {
  const BaseProvider = (
    <ChakraProvider resetCSS theme={theme}>
      {children}
    </ChakraProvider>
  );

  // Cache style with nonce value for CSP
  if (nonce) {
    const cache = createCache({ key: 'dot-ds', nonce });

    return <CacheProvider value={cache}>{BaseProvider}</CacheProvider>;
  }

  return BaseProvider;
};
