import { MemberRoleEnum } from '../../../shared/models';
import {
  adminPermissionDefinition,
  memberPermissionDefinition,
  ownerPermissionDefinition,
} from './permission-per-role.definition';
import { RolePermission } from './type';

const permissionDefinitionPerRole: Record<MemberRoleEnum, RolePermission[]> = {
  [MemberRoleEnum.member]: memberPermissionDefinition,
  [MemberRoleEnum.admin]: adminPermissionDefinition,
  [MemberRoleEnum.owner]: ownerPermissionDefinition,
};

export function getPermissionsForRole(
  role: MemberRoleEnum = MemberRoleEnum.member,
): RolePermission[] {
  return permissionDefinitionPerRole[role];
}
