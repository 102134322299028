import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationProcessing = (): JSX.Element => {
  const { stroke, skeleton } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="214 0 772 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g opacity="0.3">
          <path
            d="M697 1C697 41.8691 664.317 75 624 75L624 1L697 1Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M624 75C705.186 75 771 140.814 771 222L624 222L624 75Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M327 75C327 34.1309 359.683 1 400 1L400 75L327 75Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M550 75C550 156.186 482.843 222 400 222L400 75L550 75Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M624 75C624 34.1309 590.869 0.999999 550 0.999997L550 75L624 75Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
        </g>
        <g filter="url(#filter0_ddd_2200_2075)">
          <rect x="530" y="55" width="112" height="112" rx="4" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M586 81.6667C587.473 81.6667 588.667 82.8606 588.667 84.3333V95C588.667 96.4728 587.473 97.6667 586 97.6667C584.527 97.6667 583.333 96.4728 583.333 95V84.3333C583.333 82.8606 584.527 81.6667 586 81.6667Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M586 124.333C587.473 124.333 588.667 125.527 588.667 127V137.667C588.667 139.139 587.473 140.333 586 140.333C584.527 140.333 583.333 139.139 583.333 137.667V127C583.333 125.527 584.527 124.333 586 124.333Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M565.261 90.2611C566.302 89.2197 567.991 89.2197 569.032 90.2611L576.579 97.8077C577.62 98.8491 577.62 100.538 576.579 101.579C575.538 102.62 573.849 102.62 572.808 101.579L565.261 94.0323C564.22 92.9909 564.22 91.3024 565.261 90.2611Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M595.421 120.421C596.462 119.38 598.151 119.38 599.192 120.421L606.739 127.968C607.78 129.009 607.78 130.698 606.739 131.739C605.697 132.78 604.009 132.78 602.968 131.739L595.421 124.192C594.38 123.151 594.38 121.462 595.421 120.421Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M556.667 111C556.667 109.527 557.861 108.333 559.333 108.333H570C571.473 108.333 572.667 109.527 572.667 111C572.667 112.473 571.473 113.667 570 113.667H559.333C557.861 113.667 556.667 112.473 556.667 111Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M599.333 111C599.333 109.527 600.527 108.333 602 108.333H612.667C614.14 108.333 615.333 109.527 615.333 111C615.333 112.473 614.14 113.667 612.667 113.667H602C600.527 113.667 599.333 112.473 599.333 111Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M576.579 120.421C577.62 121.462 577.62 123.151 576.579 124.192L569.032 131.739C567.991 132.78 566.302 132.78 565.261 131.739C564.22 130.698 564.22 129.009 565.261 127.968L572.808 120.421C573.849 119.38 575.538 119.38 576.579 120.421Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M606.739 90.2611C607.78 91.3024 607.78 92.9909 606.739 94.0323L599.192 101.579C598.151 102.62 596.462 102.62 595.421 101.579C594.38 100.538 594.38 98.8491 595.421 97.8077L602.968 90.2611C604.009 89.2197 605.697 89.2197 606.739 90.2611Z"
            fill={skeleton}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddd_2200_2075"
          x="342.4"
          y="-86.4"
          width="487.2"
          height="487.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2200_2075"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2200_2075"
            result="effect2_dropShadow_2200_2075"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_2200_2075"
            result="effect3_dropShadow_2200_2075"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_2200_2075"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
