import { createLucideIcon } from 'lucide-react';

export const IdentityCardCheck = createLucideIcon('IdentityCardCheck', [
  [
    'path',
    {
      d: 'M1 5a2 2 0 012-2h16a2 2 0 012 2v2m0 8v4a2 2 0 01-2 2h-16a2 2 0 01-2-2v-14',
      key: 'frame',
    },
  ],
  ['polyline', { points: '17,11 19,13 23,9', key: 'check' }],
  ['circle', { cx: '5.5', cy: '11', r: '2', key: 'head' }],
  [
    'path',
    {
      d: 'M1 18a2 2 0 012-2 h5a2 2 0 012 2v3',
      key: 'body',
    },
  ],
]);
