import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useClientPortalId } from '../shared';
import { GenericErrorScreen } from '../shared/components/generic-error-screen';

export const ClientPortalIdResolvedGuard = ({
  children,
}: PropsWithChildren) => {
  const clientPortalId = useClientPortalId();
  const { t } = useTranslation();

  if (!clientPortalId) {
    return (
      <GenericErrorScreen
        withMainLayout
        error={t('errors.not_accessible.message', {
          ns: 'client-portal',
          defaultValue:
            'This page is not accessible, please reach your contact to get a new link.',
        })}
      />
    );
  }

  return children;
};
