export const ClientPortalStepTypeEnum = {
  company_search: 'company_search',
  company_edit: 'company_edit',
  affiliated_companies_edit: 'affiliated_companies_edit',
  individuals_edit: 'individuals_edit',
  individual_edit: 'individual_edit',
  business_contact: 'business_contact',
  custom: 'custom',
} as const;
export type ClientPortalStepTypeEnum =
  (typeof ClientPortalStepTypeEnum)[keyof typeof ClientPortalStepTypeEnum];
