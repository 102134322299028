import { SchemaOf } from 'yup';

import {
  AmlDataModel,
  CheckTypeEnum,
  EntityTypeEnum,
  PermissionResourceEnum,
  VendorEnum,
} from '../../../shared/models';
import {
  BaseCheckDefinition,
  BaseCheckSettingsDefinition,
} from '../definitions/base-check-definition';
import { FindOptions } from '../definitions/types';
import { AmlCheckInput } from './aml-check-input';
import {
  AML_DEFAULT_SETTINGS_FUZZINESS,
  AML_DEFAULT_SETTINGS_LIST_TYPES,
} from './constants';
import { amlSettingsSchema } from './validation/aml.schema';

export class AmlCheckDefinition extends BaseCheckDefinition<
  AmlDataModel,
  AmlCheckInput
> {
  // General
  // -------

  get type(): CheckTypeEnum {
    return CheckTypeEnum.aml;
  }
  get icon(): string {
    return 'ScanLine';
  }
  get checkRelationKey(): 'amlData' {
    return 'amlData';
  }
  public findOptions(): FindOptions<AmlDataModel> {
    // @TODO - E-3501 - Improve typing of FindOptions
    return {
      relations: ['hits', 'hits.review.user'],
      order: {
        createdAt: 'DESC',
        hits: { createdAt: 'DESC', position: 'DESC' },
      },
    } as unknown as FindOptions<AmlDataModel>;
  }
  get availableTarget(): EntityTypeEnum[] {
    return [EntityTypeEnum.individual, EntityTypeEnum.company];
  }

  get permissionResource(): PermissionResourceEnum {
    return PermissionResourceEnum.check_aml;
  }

  // Integrations
  // ------------

  get availableVendors(): VendorEnum[] {
    return [VendorEnum.comply_advantage];
  }

  // isInternal
  override get isInternal(): boolean {
    return true;
  }

  // Settings
  // --------

  get settings(): BaseCheckSettingsDefinition<AmlDataModel> {
    return {
      default: {
        listTypes: AML_DEFAULT_SETTINGS_LIST_TYPES,
        fuzziness: AML_DEFAULT_SETTINGS_FUZZINESS,
      },
    };
  }

  // Display
  // -------

  get label(): string {
    return 'AML screening';
  }

  // Validation
  // ------

  override get validationSchemas(): {
    settings: SchemaOf<AmlCheckInput['settings']>;
    data?: never;
  } {
    return { settings: amlSettingsSchema() };
  }
}

export const amlCheckDefinition = new AmlCheckDefinition();
