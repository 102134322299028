import {
  Accordion as ChakraAccordion,
  AccordionButton as ChakraAccordionButton,
  AccordionButtonProps as ChakraAccordionButtonProps,
  AccordionIcon as ChakraAccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps as ChakraAccordionItemProps,
  AccordionPanel as ChakraAccordionPanel,
  AccordionPanelProps as ChakraAccordionPanelProps,
  AccordionProps as ChakraAccordionProps,
} from '@chakra-ui/react';

export type AccordionProps = ChakraAccordionProps;
export const Accordion = ChakraAccordion;

export type AccordionItemProps = ChakraAccordionItemProps;
export const AccordionItem = ChakraAccordionItem;

export type AccordionButtonProps = ChakraAccordionButtonProps;
export const AccordionButton = ChakraAccordionButton;

export type AccordionPanelProps = ChakraAccordionPanelProps;
export const AccordionPanel = ChakraAccordionPanel;

// IconProps
export const AccordionIcon = ChakraAccordionIcon;
