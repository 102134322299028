import { subEnum } from './sub-enum.helper';
import { VendorEnum } from './vendor.enum';

export const CompanyDataVendorEnum = subEnum(VendorEnum, [
  VendorEnum.pappers,
  VendorEnum.kompany,
  VendorEnum.creditsafe,
  VendorEnum.kyckr,
  VendorEnum.companies_house,
  VendorEnum.inpi,
]);
export type CompanyDataVendorEnum =
  (typeof CompanyDataVendorEnum)[keyof typeof CompanyDataVendorEnum];
