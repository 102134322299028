/* eslint-disable no-restricted-imports */
// @NOTE special Client portal use-case here to override Design System theme
// thus we need some imports from Chakra under layer library
import { get } from 'lodash';
import { getLuminance, readableColor } from 'polished';

import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { GlobalStyleProps, mode } from '@chakra-ui/theme-tools';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts
// for default ChakraUI theme

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

const variantSolid = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c, theme } = props;

  const { bg = `${c}.600`, color = 'white' } = accessibleColorMap[c] ?? {};

  if (c === 'custom') {
    const background = mode(bg, `${c}.200`)(props);

    return {
      color: readableColor(
        get(theme, `colors.${background}`),
        mode('gray.800', `gray.200`)(props),
        mode(color, `gray.800`)(props),
        false,
      ),
    };
  }

  return {
    color: mode(color, `gray.800`)(props),
  };
});

const variantGhost = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c, theme } = props;

  if (c === 'coal') {
    return {
      color: mode(`${c}.500`, `${c}.200`)(props),
      _hover: {
        bg: 'coal.50',
      },
      _active: {
        bg: 'coal.200',
      },
      _disabled: {
        opacity: 1,
        color: 'coal.100',
      },
    };
  }

  if (c === 'custom') {
    return {
      color: readableColor(
        get(theme, 'colors.custom.200'),
        get(theme, 'colors.gray.800'),
        get(theme, 'colors.custom.500'),
        false,
      ),
      bg: 'transparent',
      _hover: {
        bg: mode(`gray.50`, `gray.200`)(props),
      },
      _active: {
        bg: mode(`gray.100`, `gray.200`)(props),
      },
    };
  }

  return {
    color: mode(`${c}.500`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, `${c}.200`)(props),
    },
    _active: {
      bg: mode(`${c}.100`, `${c}.200`)(props),
    },
  };
});

const variantOutline = defineStyle((props: GlobalStyleProps) => {
  const { colorScheme: c, theme } = props;

  if (c === 'coal') {
    return {
      color: mode(`${c}.500`, `${c}.200`)(props),
      _hover: {
        bg: 'coal.50',
      },
      _active: {
        bg: 'coal.200',
      },
      _disabled: {
        opacity: 1,
        color: 'coal.100',
      },
    };
  }

  if (c === 'custom') {
    // Outline button will be on background white or gray.200
    const background = get(theme, 'colors.gray.200');

    const lightColor = get(theme, 'colors.custom.500');
    const darkColor = get(theme, 'colors.custom.200');

    return {
      color: readableColor(
        background,
        lightColor,
        darkColor,
        // If the luminance of the light color is too hight, switch to strict mode
        // to fallback to a pure black
        // @see https://polished.js.org/docs/#readablecolor
        getLuminance(lightColor) > 0.8,
      ),
      bg: 'transparent',
      _hover: {
        bg: mode(
          get(theme, 'colors.custom.50'),
          get(theme, 'colors.custom.200'),
        )(props),
      },
      _active: {
        bg: mode(
          get(theme, 'colors.custom.100'),
          get(theme, 'colors.custom.200'),
        )(props),
      },
    };
  }

  return {
    color: mode(`${c}.500`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, `${c}.200`)(props),
    },
    _active: {
      bg: mode(`${c}.100`, `${c}.200`)(props),
    },
  };
});

const variants = {
  ghost: variantGhost,
  solid: variantSolid,
  outline: variantOutline,
};

export const ButtonTheme = defineStyleConfig({
  variants,
});
