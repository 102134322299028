import { PropertyTypeEnum } from '@dotfile/shared/domain';

import { EntityLegalFormSelect } from '../entity-legal-form-select/entity-legal-form-select';
import { CustomValueEditorProps } from './type';
import { selectorInsidePopoverStylesWorkaround } from './value-selector-style-workaround';

type ValueEditorEntityLegalFormProps = CustomValueEditorProps<
  typeof PropertyTypeEnum.entity_legal_form
> & {
  multiple?: boolean;
};

export const ValueEditorEntityLegalForm = ({
  value,
  handleOnChange,
  multiple,
}: ValueEditorEntityLegalFormProps): JSX.Element => {
  let parsedValue = value;
  if (multiple) {
    if (typeof parsedValue === 'string') {
      parsedValue = parsedValue.split(',');
    }
  }
  return (
    <EntityLegalFormSelect
      isSearchable
      isMulti={multiple}
      value={parsedValue}
      onChange={(newValue) => {
        if (!newValue) {
          handleOnChange(null);
        } else if (multiple) {
          const newMultiValues = Array.isArray(newValue)
            ? newValue
            : [newValue];
          handleOnChange(newMultiValues.map((v) => v.value));
        } else {
          const newSingleValue = Array.isArray(newValue)
            ? newValue[0]
            : newValue;
          handleOnChange(newSingleValue.value);
        }
      }}
      styles={selectorInsidePopoverStylesWorkaround}
    />
  );
};
