import {
  ClientPortalBlockFieldMappingModel,
  ClientPortalBlockFieldModel,
} from '../../../../shared/models';

export const matchFieldMapping =
  ({ mapping }: Partial<Pick<ClientPortalBlockFieldModel, 'mapping'>>) =>
  (field: Partial<Pick<ClientPortalBlockFieldModel, 'mapping'>>): boolean =>
    (
      ['entity', 'type', 'key'] as (keyof ClientPortalBlockFieldMappingModel)[]
    ).every((key) => field?.mapping?.[key] === mapping?.[key]);
