import { useEffect, useRef, useState } from 'react';
import { MutableRefObject } from 'react';

// Based on https://usehooks.com/useHover/

// Hook
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
// hook returns tuple(array) with type [any, boolean]
export function useHover<T extends HTMLElement>(): [
  MutableRefObject<T | null>,
  boolean,
] {
  const [value, setValue] = useState<boolean>(false);

  const ref = useRef<T | null>(null);

  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        // enter/leave gave better result then hover/out when the
        // watched element has interactive children (like button)
        node.addEventListener('mouseenter', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);

        return () => {
          node.removeEventListener('mouseenter', handleMouseOver);
          node.removeEventListener('mouseleave', handleMouseOut);
        };
      }
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current], // Recall only if ref changes
  );

  return [ref, value];
}
