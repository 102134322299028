import { PropsWithChildren } from 'react';

import { useAuth } from '@dotfile/frontend/shared/common';
import { Redirect } from '@dotfile/frontend/shared/components';

import { useFormDatastore } from '../modules/forms/shared';
import { useCase, useLatestClientPortalVersion } from '../shared';
import { getValidRoutes } from './get-valid-routes';
import { ROOT_ROUTES } from './root-routes.constant';
import { useRouter } from './router.context';

export const ValidateRouteGuard = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const route = router.useRoute(ROOT_ROUTES);

  const isFormInProgress =
    typeof useFormDatastore((state) => state.progress.currentStepKey) ===
    'string';

  // @NOTE No need to handle loading or error, they should all
  // be already loaded from parent components
  const isCaseCreationAllowed =
    useLatestClientPortalVersion().data?.latestClientPortalVersion.setting
      .isCaseCreationAllowed === true;

  const { auth } = useAuth();
  const caseQuery = useCase();

  const caseStatus = caseQuery.data?.case?.status ?? null;
  const caseContactHasAction = caseQuery.data?.case?.contactHasActions === true;

  if (auth.isAuthenticated && !caseStatus) {
    // Authenticated without caseData is a temporary state that happen either when the app is initializing (in this case
    // the parent component handle it and display a loading) OR for first time "Save for later" and in this case,
    // it's better to keep showing the children / current route because the case data will be loaded shortly after
    return children;
  }
  // Otherwise, there is either
  // - no authentication and no values caseStatus / caseContactHasAction
  // - authentication and values for caseStatus / caseContactHasAction
  // So getValidRoutes does not check authentication but only if caseStatus is null or not

  const validRoutes = getValidRoutes({
    caseStatus,
    caseContactHasAction,
    isCaseCreationAllowed,
    isFormInProgress,
  });

  if (!route || !validRoutes.includes(route.name)) {
    // Redirect to the first valid route
    // This ensure that there is always at most one redirect and prevent any
    // redirect loop because it is guarantee that is redirect to a valid root
    // on the first time
    const to = router[validRoutes[0]]();

    return <Redirect to={to} />;
  }

  return children;
};
