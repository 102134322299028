import { match } from 'ts-pattern';

import {
  ClientPortalBlockFieldModel,
  ClientPortalBlockModel,
  ClientPortalFieldMappingTypeEnum,
  ClientPortalStepModel,
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
  PropertyMappingEntityEnum,
} from '../../../shared/models';
import { NestedPropertyMappingEntityEnum } from '../../../shared/models/enum/nested-property-mapping-entity.enum';
import {
  generateKeyFromMapping,
  Property,
  PropertyTypeEnum,
  QueryBuilderField,
  QueryBuilderFieldGroup,
  resolveDefaultPropertyFromMapping,
} from '../../../shared/properties';
import { matchFieldMapping } from '../client-portal-block';
import {
  ClientPortalProperty,
  getClientPortalCustomPropertyAdapter,
} from '../client-portal-properties';
import { clientPortalCompanyPropertiesDefinition } from '../client-portal-properties/client-portal-company-properties.definition';
import { clientPortalIndividualPropertiesDefinition } from '../client-portal-properties/client-portal-individual-properties.definition';
import {
  UNSUPPORTED_PROPERTY_MAPPING_KEYS,
  UNSUPPORTED_PROPERTY_TYPES,
} from './constants';

type LogicBuilderFieldByEntity = {
  case: QueryBuilderField[];
  mainCompany: QueryBuilderField[];
  individual: QueryBuilderField[];
  affiliatedCompany: QueryBuilderField[];
};

type GetLogicBuilderFieldsParams = {
  clientPortalType: ClientPortalTypeEnum;
  customProperties: Parameters<
    typeof getClientPortalCustomPropertyAdapter
  >[0][];

  /**
   * Omit to get the fields for any steps (logic at the **step** level)
   *
   * Set to get the fields available for the blocks of a specific step (logic at the **block** level)
   */
  step?: Pick<ClientPortalStepModel, 'type'> & {
    blocks: (
      | Partial<Omit<ClientPortalBlockModel, 'logics'>>
      | Pick<ClientPortalBlockFieldModel, 'mapping'>
    )[];
  };
};

export const getLogicBuilderFields = ({
  clientPortalType,
  step,
  customProperties: customPropertiesAsCustomProperties,
}: GetLogicBuilderFieldsParams): QueryBuilderFieldGroup[] => {
  const customPropertiesAsProperties = customPropertiesAsCustomProperties.map(
    getClientPortalCustomPropertyAdapter,
  );

  let fields = getLogicBuilderGlobalFields(
    clientPortalType,
    customPropertiesAsProperties,
  );

  if (step) {
    const localFields = getLogicBuilderLocalFields(
      step,
      customPropertiesAsProperties,
    );

    fields = mergeGlobalAndLocalFieldsByEntities({
      globalFields: fields,
      localFields,
    });
  }

  const fieldGroups: QueryBuilderFieldGroup[] = [];

  if (fields.case.length > 0) {
    fieldGroups.push({ label: 'Case', options: fields.case });
  }

  if (fields.mainCompany.length > 0) {
    fieldGroups.push({ label: 'Main company', options: fields.mainCompany });
  }

  if (fields.individual.length > 0) {
    fieldGroups.push({ label: 'Individual', options: fields.individual });
  }

  if (fields.affiliatedCompany.length > 0) {
    fieldGroups.push({
      label: 'Affiliated company',
      options: fields.affiliatedCompany,
    });
  }

  return fieldGroups;
};

const getLogicBuilderGlobalFields = (
  clientPortalType: ClientPortalTypeEnum,
  customProperties: Property<PropertyTypeEnum>[],
): LogicBuilderFieldByEntity => {
  const fieldsByEntities: LogicBuilderFieldByEntity = {
    case: [],
    mainCompany: [],
    individual: [],
    affiliatedCompany: [],
  };

  // Case custom properties are always available for both client portal type
  // @NOTE There is no default case properties yet
  fieldsByEntities.case.push(
    ...customProperties
      .filter(
        (property) =>
          property.mapping.entity === PropertyMappingEntityEnum.case,
      )
      .map((property) => propertyToLogicBuilderField('global.case', property)),
  );

  if (clientPortalType === ClientPortalTypeEnum.KYB) {
    // Main company default/ custom properties are available for KYB client portal
    fieldsByEntities.mainCompany.push(
      ...Object.values(clientPortalCompanyPropertiesDefinition)
        .filter(isPropertySupported)
        .map((property) =>
          propertyToLogicBuilderField('global.main_company', property),
        ),
    );

    fieldsByEntities.mainCompany.push(
      ...customProperties
        .filter(
          (property) =>
            property.mapping.entity === PropertyMappingEntityEnum.company,
        )
        .map((property) =>
          propertyToLogicBuilderField('global.main_company', property),
        ),
    );
  }

  if (clientPortalType === ClientPortalTypeEnum.KYC) {
    // Individual default/ custom properties are available for KYB client portal
    fieldsByEntities.individual.push(
      ...Object.values(clientPortalIndividualPropertiesDefinition)
        .filter(isPropertySupported)
        .map((property) =>
          propertyToLogicBuilderField('global.current_individual', property),
        ),
    );

    fieldsByEntities.individual.push(
      ...customProperties
        .filter(
          (property) =>
            property.mapping.entity === PropertyMappingEntityEnum.individual,
        )
        .map((property) =>
          propertyToLogicBuilderField('global.current_individual', property),
        ),
    );
  }

  return fieldsByEntities;
};

const isPropertySupported = (property: ClientPortalProperty): boolean =>
  !UNSUPPORTED_PROPERTY_TYPES.includes(property.type) &&
  !UNSUPPORTED_PROPERTY_MAPPING_KEYS.includes(property.mapping.key);

const getLogicBuilderLocalFields = (
  step: Required<GetLogicBuilderFieldsParams>['step'],
  customProperties: ClientPortalProperty[],
): LogicBuilderFieldByEntity => {
  const stepFields = step.blocks.filter(
    (b): b is Pick<ClientPortalBlockFieldModel, 'mapping'> => 'mapping' in b,
  );

  // Resolve properties from step fields
  const rawProperties = stepFields
    .map((field) => {
      if (field.mapping.type === ClientPortalFieldMappingTypeEnum.default) {
        return resolveDefaultPropertyFromMapping(field.mapping).property;
      }

      const matchField = matchFieldMapping(field);
      const resolvedCustomProperty = customProperties.find((field) =>
        matchField(field),
      );

      return resolvedCustomProperty;
    })
    .filter((property): property is ClientPortalProperty => !!property);

  // Hydrate local properties with nested field definition if detected (only address for now)
  // We pick the field definition from the individual default properties
  const properties = rawProperties
    .flatMap((property) => {
      if (property.type in NestedPropertyMappingEntityEnum) {
        const defaultParentPropertiesDefinition =
          resolveParentPropertiesDefinition(property);

        return Object.values(defaultParentPropertiesDefinition).filter(
          (properties) =>
            properties.mapping.key.startsWith(property.type) &&
            properties.mapping.nested,
        );
      }

      return property;
    })
    .filter(isPropertySupported);

  // Map property to logic fields
  const fieldsByEntities: LogicBuilderFieldByEntity = {
    case: [],
    mainCompany: [],
    individual: [],
    affiliatedCompany: [],
  };

  properties.forEach((property) => {
    const field = propertyToLogicBuilderField('local', property);

    const fields = match(property.mapping.entity)
      .with(PropertyMappingEntityEnum.case, () => fieldsByEntities.case)
      .with(PropertyMappingEntityEnum.company, () =>
        step.type === ClientPortalStepTypeEnum.affiliated_companies_edit
          ? fieldsByEntities.affiliatedCompany
          : fieldsByEntities.mainCompany,
      )
      .with(
        PropertyMappingEntityEnum.individual,
        () => fieldsByEntities.individual,
      )
      .exhaustive();

    fields.push(field);
  });

  return fieldsByEntities;
};

const propertyToLogicBuilderField = (
  context: `global.${'case' | 'main_company' | 'current_individual'}` | 'local',
  property: Property<PropertyTypeEnum>,
): QueryBuilderField => {
  return {
    name: `${context}.${generateKeyFromMapping(property.mapping)}`,
    label: property.label,

    type: property.type,
    values:
      property.settings &&
      'options' in property.settings &&
      property.settings.options
        ? property.settings.options.map((option) => ({
            label: option.label,
            value: option.key,
          }))
        : undefined,

    property,
  };
};

const mergeGlobalAndLocalFieldsByEntities = ({
  globalFields,
  localFields,
}: {
  globalFields: LogicBuilderFieldByEntity;
  localFields: LogicBuilderFieldByEntity;
}): LogicBuilderFieldByEntity => {
  return {
    case: mergeGlobalAndLocalFields({
      globalFields: globalFields.case,
      localFields: localFields.case,
    }),
    mainCompany: mergeGlobalAndLocalFields({
      globalFields: globalFields.mainCompany,
      localFields: localFields.mainCompany,
    }),
    individual: mergeGlobalAndLocalFields({
      globalFields: globalFields.individual,
      localFields: localFields.individual,
    }),
    affiliatedCompany: mergeGlobalAndLocalFields({
      globalFields: globalFields.affiliatedCompany,
      localFields: localFields.affiliatedCompany,
    }),
  };
};

const mergeGlobalAndLocalFields = ({
  globalFields,
  localFields,
}: {
  globalFields: QueryBuilderField[];
  localFields: QueryBuilderField[];
}): QueryBuilderField[] => {
  return [
    ...localFields,

    // Remove global fields that are also in local
    ...globalFields.filter((globalField) => {
      const localFieldName = globalField.name.split('.').at(-1);
      return !localFields.find(
        (localField) => localField.name.split('.').at(-1) === localFieldName,
      );
    }),
  ];
};

const resolveParentPropertiesDefinition = (
  property: ClientPortalProperty,
): Record<string, ClientPortalProperty> => {
  const parentPropertiesDefinition = match(property.mapping.entity)
    .with(
      PropertyMappingEntityEnum.company,
      () => clientPortalCompanyPropertiesDefinition,
    )
    .with(
      PropertyMappingEntityEnum.individual,
      () => clientPortalIndividualPropertiesDefinition,
    )
    .otherwise(() => {
      throw new Error(
        `No default properties definition for entity ${property.mapping.entity}`,
      );
    });

  return parentPropertiesDefinition;
};
