import type { Environment } from '@dotfile/frontend/shared/common';
import { DotfileStageEnum } from '@dotfile/shared/domain';

export const environment: Environment = {
  production: true,
  baseApp: 'https://client-portal.dotfile.com',
  baseAPI: 'https://client-portal-api.dotfile.com',
  baseMainStorage:
    'https://dotfile-production-main-storage.s3.eu-west-3.amazonaws.com/',
  stage: DotfileStageEnum.production,
  analyticsWriteKey: '2QYQO9WVDEwxlLyOTrDLwwFTyCo',
  analyticsDataPlane: 'https://dotfilevaltqx.dataplane.rudderstack.com',
  datadogRUM: {
    applicationId: '410f75cc-4cba-49a7-b5c6-ad4bc3f0e423',
    clientToken: 'pubcdd4d764c70cec722b76afa1f68534af',
  },
};
