import { useMemo } from 'react';

import {
  Box,
  Progress,
  useIsSmallScreen,
} from '@dotfile/frontend/shared/design-system';

import { useGetDisplayedSteps, useStepProgress } from '../hooks';

export const ProgressBar = () => {
  const isSmallScreen = useIsSmallScreen();
  const { current } = useStepProgress();
  const getDisplayedSteps = useGetDisplayedSteps();

  const progress = useMemo(() => {
    const steps = getDisplayedSteps();
    if (steps.length === 0) {
      // Avoid dividing by zero
      // should always have at least one step displayed so
      // indeterminate progress while steps are loaded
      return undefined;
    }

    const currentStepIndex = steps.findIndex((step) => step.key === current);
    const progress = ((currentStepIndex + 1) * 100) / steps.length;

    return progress;
  }, [current, getDisplayedSteps]);

  return (
    <Box width="full" mb={isSmallScreen ? '3' : '6'}>
      <Progress
        sx={{
          '& > div:first-of-type': {
            transition: 'width 0.4s ease-in-out',
          },
        }}
        transition="all 0.4s linear"
        size="sm"
        value={progress}
        borderRadius="lg"
      />
    </Box>
  );
};
