import { formatNumber } from 'libphonenumber-js';
import { LucideIcon, Phone } from 'lucide-react';
import { AnySchema } from 'yup';

import { CustomPropertyTypeEnum } from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import { CustomPropertyFormatValueParam, CustomPropertyValue } from './type';

export class PhoneNumberCustomPropertyDefinition extends BaseCustomPropertyDefinition {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.phone_number;
  }

  get label(): string {
    return 'Phone number';
  }

  get icon(): LucideIcon {
    return Phone;
  }

  // Settings
  // --------

  // no settings for now

  // Value
  // -----
  override get defaultValue(): CustomPropertyValue | null {
    return '';
  }

  public valueSchema(): AnySchema {
    const schema = yup.string().optionalString().phoneNumber();

    return schema;
  }

  public override formatValue({
    value,
  }: CustomPropertyFormatValueParam): string {
    return formatNumber(value, 'INTERNATIONAL') || value;
  }
}

export const phoneNumberCustomPropertyDefinition =
  new PhoneNumberCustomPropertyDefinition();
