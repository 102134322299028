import {
  COMPANY_COUNTRY_VENDOR_NONE,
  CompanyDataVendorEnum,
  WorkspaceSettingsCompanyDataVendorModel,
} from '../../../shared/models';

export const defaultCompanyDataVendor: WorkspaceSettingsCompanyDataVendorModel =
  {
    AF: COMPANY_COUNTRY_VENDOR_NONE,
    AX: COMPANY_COUNTRY_VENDOR_NONE,
    AL: COMPANY_COUNTRY_VENDOR_NONE,
    DZ: COMPANY_COUNTRY_VENDOR_NONE,
    AS: COMPANY_COUNTRY_VENDOR_NONE,
    AD: COMPANY_COUNTRY_VENDOR_NONE,
    AO: COMPANY_COUNTRY_VENDOR_NONE,
    AI: COMPANY_COUNTRY_VENDOR_NONE,
    AQ: COMPANY_COUNTRY_VENDOR_NONE,
    AG: COMPANY_COUNTRY_VENDOR_NONE,
    AR: CompanyDataVendorEnum.kompany,
    AM: COMPANY_COUNTRY_VENDOR_NONE,
    AW: COMPANY_COUNTRY_VENDOR_NONE,
    AU: CompanyDataVendorEnum.kompany,
    AT: CompanyDataVendorEnum.kompany,
    AZ: COMPANY_COUNTRY_VENDOR_NONE,
    BS: COMPANY_COUNTRY_VENDOR_NONE,
    BH: CompanyDataVendorEnum.kompany,
    BD: COMPANY_COUNTRY_VENDOR_NONE,
    BB: COMPANY_COUNTRY_VENDOR_NONE,
    BY: COMPANY_COUNTRY_VENDOR_NONE,
    BE: CompanyDataVendorEnum.kompany,
    BZ: COMPANY_COUNTRY_VENDOR_NONE,
    BJ: COMPANY_COUNTRY_VENDOR_NONE,
    BM: COMPANY_COUNTRY_VENDOR_NONE,
    BT: COMPANY_COUNTRY_VENDOR_NONE,
    BO: COMPANY_COUNTRY_VENDOR_NONE,
    BQ: COMPANY_COUNTRY_VENDOR_NONE,
    BA: COMPANY_COUNTRY_VENDOR_NONE,
    BW: CompanyDataVendorEnum.kompany,
    BV: COMPANY_COUNTRY_VENDOR_NONE,
    BR: CompanyDataVendorEnum.kompany,
    IO: COMPANY_COUNTRY_VENDOR_NONE,
    VG: COMPANY_COUNTRY_VENDOR_NONE,
    BN: COMPANY_COUNTRY_VENDOR_NONE,
    BG: COMPANY_COUNTRY_VENDOR_NONE,
    BF: COMPANY_COUNTRY_VENDOR_NONE,
    BI: COMPANY_COUNTRY_VENDOR_NONE,
    CV: COMPANY_COUNTRY_VENDOR_NONE,
    KH: COMPANY_COUNTRY_VENDOR_NONE,
    CM: COMPANY_COUNTRY_VENDOR_NONE,
    CA: CompanyDataVendorEnum.kompany,
    KY: COMPANY_COUNTRY_VENDOR_NONE,
    CF: COMPANY_COUNTRY_VENDOR_NONE,
    TD: COMPANY_COUNTRY_VENDOR_NONE,
    CL: COMPANY_COUNTRY_VENDOR_NONE,
    CN: CompanyDataVendorEnum.kompany,
    CX: COMPANY_COUNTRY_VENDOR_NONE,
    CC: COMPANY_COUNTRY_VENDOR_NONE,
    CO: COMPANY_COUNTRY_VENDOR_NONE,
    KM: COMPANY_COUNTRY_VENDOR_NONE,
    CG: COMPANY_COUNTRY_VENDOR_NONE,
    CK: COMPANY_COUNTRY_VENDOR_NONE,
    CR: COMPANY_COUNTRY_VENDOR_NONE,
    CI: COMPANY_COUNTRY_VENDOR_NONE,
    HR: CompanyDataVendorEnum.kompany,
    CU: COMPANY_COUNTRY_VENDOR_NONE,
    CW: COMPANY_COUNTRY_VENDOR_NONE,
    CY: CompanyDataVendorEnum.kompany,
    CZ: CompanyDataVendorEnum.kompany,
    CD: COMPANY_COUNTRY_VENDOR_NONE,
    DK: CompanyDataVendorEnum.kompany,
    DJ: COMPANY_COUNTRY_VENDOR_NONE,
    DM: COMPANY_COUNTRY_VENDOR_NONE,
    DO: COMPANY_COUNTRY_VENDOR_NONE,
    EC: COMPANY_COUNTRY_VENDOR_NONE,
    EG: COMPANY_COUNTRY_VENDOR_NONE,
    SV: COMPANY_COUNTRY_VENDOR_NONE,
    GQ: COMPANY_COUNTRY_VENDOR_NONE,
    ER: COMPANY_COUNTRY_VENDOR_NONE,
    EE: CompanyDataVendorEnum.kompany,
    ET: COMPANY_COUNTRY_VENDOR_NONE,
    FK: COMPANY_COUNTRY_VENDOR_NONE,
    FO: COMPANY_COUNTRY_VENDOR_NONE,
    FJ: COMPANY_COUNTRY_VENDOR_NONE,
    FI: CompanyDataVendorEnum.kompany,
    FR: CompanyDataVendorEnum.pappers,
    GF: COMPANY_COUNTRY_VENDOR_NONE,
    PF: CompanyDataVendorEnum.pappers,
    TF: CompanyDataVendorEnum.pappers,
    GA: COMPANY_COUNTRY_VENDOR_NONE,
    GM: COMPANY_COUNTRY_VENDOR_NONE,
    GE: CompanyDataVendorEnum.kompany,
    DE: CompanyDataVendorEnum.kompany,
    GH: COMPANY_COUNTRY_VENDOR_NONE,
    GI: CompanyDataVendorEnum.kompany,
    GR: CompanyDataVendorEnum.kompany,
    GL: CompanyDataVendorEnum.kompany,
    GD: COMPANY_COUNTRY_VENDOR_NONE,
    GP: CompanyDataVendorEnum.pappers,
    GU: COMPANY_COUNTRY_VENDOR_NONE,
    GT: COMPANY_COUNTRY_VENDOR_NONE,
    GG: COMPANY_COUNTRY_VENDOR_NONE,
    GN: COMPANY_COUNTRY_VENDOR_NONE,
    GW: COMPANY_COUNTRY_VENDOR_NONE,
    GY: CompanyDataVendorEnum.pappers,
    HT: COMPANY_COUNTRY_VENDOR_NONE,
    HM: COMPANY_COUNTRY_VENDOR_NONE,
    VA: COMPANY_COUNTRY_VENDOR_NONE,
    HN: COMPANY_COUNTRY_VENDOR_NONE,
    HK: CompanyDataVendorEnum.kompany,
    HU: CompanyDataVendorEnum.kompany,
    IS: COMPANY_COUNTRY_VENDOR_NONE,
    IN: CompanyDataVendorEnum.kompany,
    ID: COMPANY_COUNTRY_VENDOR_NONE,
    IR: COMPANY_COUNTRY_VENDOR_NONE,
    IQ: COMPANY_COUNTRY_VENDOR_NONE,
    IE: CompanyDataVendorEnum.kompany,
    IM: COMPANY_COUNTRY_VENDOR_NONE,
    IL: CompanyDataVendorEnum.kompany,
    IT: CompanyDataVendorEnum.kompany,
    JM: COMPANY_COUNTRY_VENDOR_NONE,
    JP: CompanyDataVendorEnum.kompany,
    JE: CompanyDataVendorEnum.kompany,
    JO: CompanyDataVendorEnum.kompany,
    KZ: COMPANY_COUNTRY_VENDOR_NONE,
    KE: COMPANY_COUNTRY_VENDOR_NONE,
    KI: COMPANY_COUNTRY_VENDOR_NONE,
    XK: COMPANY_COUNTRY_VENDOR_NONE,
    KW: COMPANY_COUNTRY_VENDOR_NONE,
    KG: COMPANY_COUNTRY_VENDOR_NONE,
    LA: COMPANY_COUNTRY_VENDOR_NONE,
    LV: CompanyDataVendorEnum.kompany,
    LB: COMPANY_COUNTRY_VENDOR_NONE,
    LS: COMPANY_COUNTRY_VENDOR_NONE,
    LR: COMPANY_COUNTRY_VENDOR_NONE,
    LY: COMPANY_COUNTRY_VENDOR_NONE,
    LI: COMPANY_COUNTRY_VENDOR_NONE,
    LT: CompanyDataVendorEnum.kompany,
    LU: CompanyDataVendorEnum.kompany,
    MO: COMPANY_COUNTRY_VENDOR_NONE,
    MG: COMPANY_COUNTRY_VENDOR_NONE,
    MW: COMPANY_COUNTRY_VENDOR_NONE,
    MY: CompanyDataVendorEnum.kompany,
    MV: COMPANY_COUNTRY_VENDOR_NONE,
    ML: COMPANY_COUNTRY_VENDOR_NONE,
    MT: CompanyDataVendorEnum.kompany,
    MH: COMPANY_COUNTRY_VENDOR_NONE,
    MQ: CompanyDataVendorEnum.pappers,
    MR: COMPANY_COUNTRY_VENDOR_NONE,
    MU: COMPANY_COUNTRY_VENDOR_NONE,
    YT: CompanyDataVendorEnum.pappers,
    MX: COMPANY_COUNTRY_VENDOR_NONE,
    FM: COMPANY_COUNTRY_VENDOR_NONE,
    MD: COMPANY_COUNTRY_VENDOR_NONE,
    MC: COMPANY_COUNTRY_VENDOR_NONE,
    MN: COMPANY_COUNTRY_VENDOR_NONE,
    ME: COMPANY_COUNTRY_VENDOR_NONE,
    MS: COMPANY_COUNTRY_VENDOR_NONE,
    MA: COMPANY_COUNTRY_VENDOR_NONE,
    MZ: COMPANY_COUNTRY_VENDOR_NONE,
    MM: COMPANY_COUNTRY_VENDOR_NONE,
    NA: COMPANY_COUNTRY_VENDOR_NONE,
    NR: COMPANY_COUNTRY_VENDOR_NONE,
    NP: COMPANY_COUNTRY_VENDOR_NONE,
    NL: CompanyDataVendorEnum.kompany,
    NC: CompanyDataVendorEnum.pappers,
    NZ: CompanyDataVendorEnum.kompany,
    NI: COMPANY_COUNTRY_VENDOR_NONE,
    NE: COMPANY_COUNTRY_VENDOR_NONE,
    NG: COMPANY_COUNTRY_VENDOR_NONE,
    NU: COMPANY_COUNTRY_VENDOR_NONE,
    NF: COMPANY_COUNTRY_VENDOR_NONE,
    KP: COMPANY_COUNTRY_VENDOR_NONE,
    MK: CompanyDataVendorEnum.kompany,
    MP: COMPANY_COUNTRY_VENDOR_NONE,
    NO: CompanyDataVendorEnum.kompany,
    OM: COMPANY_COUNTRY_VENDOR_NONE,
    PK: COMPANY_COUNTRY_VENDOR_NONE,
    PW: COMPANY_COUNTRY_VENDOR_NONE,
    PS: COMPANY_COUNTRY_VENDOR_NONE,
    PA: COMPANY_COUNTRY_VENDOR_NONE,
    PG: COMPANY_COUNTRY_VENDOR_NONE,
    PY: COMPANY_COUNTRY_VENDOR_NONE,
    PE: COMPANY_COUNTRY_VENDOR_NONE,
    PH: COMPANY_COUNTRY_VENDOR_NONE,
    PN: COMPANY_COUNTRY_VENDOR_NONE,
    PL: CompanyDataVendorEnum.kompany,
    PT: COMPANY_COUNTRY_VENDOR_NONE,
    PR: COMPANY_COUNTRY_VENDOR_NONE,
    QA: COMPANY_COUNTRY_VENDOR_NONE,
    RE: CompanyDataVendorEnum.pappers,
    RO: CompanyDataVendorEnum.kompany,
    RU: CompanyDataVendorEnum.kompany,
    RW: COMPANY_COUNTRY_VENDOR_NONE,
    BL: CompanyDataVendorEnum.pappers,
    SH: COMPANY_COUNTRY_VENDOR_NONE,
    KN: COMPANY_COUNTRY_VENDOR_NONE,
    LC: COMPANY_COUNTRY_VENDOR_NONE,
    MF: CompanyDataVendorEnum.pappers,
    PM: CompanyDataVendorEnum.pappers,
    VC: COMPANY_COUNTRY_VENDOR_NONE,
    WS: COMPANY_COUNTRY_VENDOR_NONE,
    SM: COMPANY_COUNTRY_VENDOR_NONE,
    ST: COMPANY_COUNTRY_VENDOR_NONE,
    SA: COMPANY_COUNTRY_VENDOR_NONE,
    SN: COMPANY_COUNTRY_VENDOR_NONE,
    RS: COMPANY_COUNTRY_VENDOR_NONE,
    SC: COMPANY_COUNTRY_VENDOR_NONE,
    SL: COMPANY_COUNTRY_VENDOR_NONE,
    SG: CompanyDataVendorEnum.kompany,
    SX: COMPANY_COUNTRY_VENDOR_NONE,
    SK: CompanyDataVendorEnum.kompany,
    SI: CompanyDataVendorEnum.kompany,
    SB: COMPANY_COUNTRY_VENDOR_NONE,
    SO: COMPANY_COUNTRY_VENDOR_NONE,
    ZA: COMPANY_COUNTRY_VENDOR_NONE,
    GS: COMPANY_COUNTRY_VENDOR_NONE,
    KR: CompanyDataVendorEnum.kompany,
    SS: COMPANY_COUNTRY_VENDOR_NONE,
    ES: CompanyDataVendorEnum.kompany,
    LK: COMPANY_COUNTRY_VENDOR_NONE,
    SD: COMPANY_COUNTRY_VENDOR_NONE,
    SR: COMPANY_COUNTRY_VENDOR_NONE,
    SJ: COMPANY_COUNTRY_VENDOR_NONE,
    SZ: COMPANY_COUNTRY_VENDOR_NONE,
    SE: CompanyDataVendorEnum.kompany,
    CH: CompanyDataVendorEnum.kompany,
    SY: COMPANY_COUNTRY_VENDOR_NONE,
    TW: CompanyDataVendorEnum.kompany,
    TJ: COMPANY_COUNTRY_VENDOR_NONE,
    TZ: COMPANY_COUNTRY_VENDOR_NONE,
    TH: CompanyDataVendorEnum.kompany,
    TL: COMPANY_COUNTRY_VENDOR_NONE,
    TG: COMPANY_COUNTRY_VENDOR_NONE,
    TK: COMPANY_COUNTRY_VENDOR_NONE,
    TO: COMPANY_COUNTRY_VENDOR_NONE,
    TT: COMPANY_COUNTRY_VENDOR_NONE,
    TN: COMPANY_COUNTRY_VENDOR_NONE,
    TR: COMPANY_COUNTRY_VENDOR_NONE,
    TM: COMPANY_COUNTRY_VENDOR_NONE,
    TC: COMPANY_COUNTRY_VENDOR_NONE,
    TV: COMPANY_COUNTRY_VENDOR_NONE,
    UG: COMPANY_COUNTRY_VENDOR_NONE,
    UA: COMPANY_COUNTRY_VENDOR_NONE,
    AE: CompanyDataVendorEnum.kompany,
    GB: CompanyDataVendorEnum.companies_house,
    US: CompanyDataVendorEnum.kompany,
    UM: COMPANY_COUNTRY_VENDOR_NONE,
    UY: COMPANY_COUNTRY_VENDOR_NONE,
    VI: COMPANY_COUNTRY_VENDOR_NONE,
    UZ: COMPANY_COUNTRY_VENDOR_NONE,
    VU: COMPANY_COUNTRY_VENDOR_NONE,
    VE: COMPANY_COUNTRY_VENDOR_NONE,
    VN: COMPANY_COUNTRY_VENDOR_NONE,
    WF: CompanyDataVendorEnum.pappers,
    EH: COMPANY_COUNTRY_VENDOR_NONE,
    YE: COMPANY_COUNTRY_VENDOR_NONE,
    ZM: COMPANY_COUNTRY_VENDOR_NONE,
    ZW: COMPANY_COUNTRY_VENDOR_NONE,
  };
