import {
  CheckResultEnum,
  ClientPortalChecks_IdDocumentCheck,
} from '@dotfile/shared/data-access-client-portal';
import {
  CHECK_ANALYSIS_RESULT_DATA_COMPARISON_BIRTH_DATE_KEY,
  CHECK_ANALYSIS_RESULT_DATA_COMPARISON_NAME_KEY,
  computeDataResultFromAnalysisResults,
  getIdentityDataComparisonResult,
} from '@dotfile/shared/domain';

import {
  CheckCard,
  CheckDescription,
  CheckHeading,
  CheckReview,
  PendingStatuses,
  UploadButton,
} from '../shared';
import { useCheckModalWithQueryParam } from '../shared/hooks/use-modal-with-query-param';
import { DataComparisonAlert } from './components';

type IdDocumentCheckProps = {
  check: ClientPortalChecks_IdDocumentCheck;
};

export const IdDocumentCheck = ({ check }: IdDocumentCheckProps) => {
  const { onOpen } = useCheckModalWithQueryParam(check.id);

  const isVendorRejected =
    computeDataResultFromAnalysisResults(check.data.analysisResults, {
      excludeKeys: [
        CHECK_ANALYSIS_RESULT_DATA_COMPARISON_BIRTH_DATE_KEY,
        CHECK_ANALYSIS_RESULT_DATA_COMPARISON_NAME_KEY,
      ],
    }) === CheckResultEnum.rejected;
  const showDataComparisonAlert =
    getIdentityDataComparisonResult(check.data) === CheckResultEnum.rejected &&
    PendingStatuses.includes(check.status) &&
    !isVendorRejected;

  return (
    <CheckCard
      heading={<CheckHeading check={check} />}
      description={<CheckDescription check={check} />}
      action={<UploadButton check={check} onClick={onOpen} />}
      review={
        <>
          {showDataComparisonAlert && <DataComparisonAlert />}

          <CheckReview check={check} />
        </>
      }
    />
  );
};
