/**
 * @doc https://www.notion.so/Permissions-tables-023baaeda4744d2487b31103894c3502
 */
export const PermissionResourceEnum = {
  all: 'all', // Wildcard for typing with CASL
  // later
  // user : 'user',
  // workspace : 'workspace',
  // invite : 'invite',
  // custom_document_type : 'custom_document_type',
  // api_key : 'api_key',
  // tag : 'tag',
  // webhook : 'webhook',
  // webhook_log : 'webhook_log',
  case_tag: 'case_tag',
  case_relation: 'case_relation',
  template: 'template',
  case: 'case',
  individual: 'individual',
  company: 'company',
  company_data: 'company_data',
  check_aml: 'check_aml',
  check_document: 'check_document',
  check_id_verification: 'check_id_verification',
  check_id_document: 'check_id_document',
  document_order: 'document_order',
  note: 'note',
  communication: 'communication',
  note_comment: 'note_comment',
  analytics_dashboard: 'analytics_dashboard',
} as const;
export type PermissionResourceEnum =
  (typeof PermissionResourceEnum)[keyof typeof PermissionResourceEnum];
