import { TFunction } from 'i18next';
import { SchemaOf } from 'yup';

import { formatDate } from '@dotfile/shared/common';

import { COUNTRIES } from '../../../../../shared/country';
import {
  DocumentAnalysisModelEnum,
  DocumentAnalysisRegistrationCertificateDetailedResultsModel,
  DocumentAnalysisRegistrationCertificateDocumentTypesEnum,
  DocumentAnalysisRegistrationCertificateInformationModel,
  DocumentAnalysisRegistrationCertificateParametersModel,
} from '../../../../../shared/models';
import {
  DocumentAnalysisDefinition,
  DocumentAnalysisDefinitionDetailedResults,
  DocumentAnalysisDefinitionInformation,
  DocumentAnalysisDefinitionParameters,
} from '../document-analysis-definition';
import { documentAnalysisRegistrationCertificateParametersSchema } from './validation';

export class DocumentAnalysisRegistrationCertificateDefinition extends DocumentAnalysisDefinition<
  DocumentAnalysisRegistrationCertificateParametersModel,
  DocumentAnalysisRegistrationCertificateDetailedResultsModel,
  DocumentAnalysisRegistrationCertificateInformationModel
> {
  override get model(): DocumentAnalysisModelEnum {
    return DocumentAnalysisModelEnum.registration_certificate;
  }

  override get label(): string {
    return 'Registration Certificate';
  }

  override get parameters(): DocumentAnalysisDefinitionParameters<DocumentAnalysisRegistrationCertificateParametersModel> {
    return {
      model: {
        label: 'Model',
      },
      maxAgeInDays: {
        label: 'Max age in days',
      },
      authorizedDocuments: {
        label: 'Authorized documents',
      },
    };
  }

  override get detailedResults(): DocumentAnalysisDefinitionDetailedResults<DocumentAnalysisRegistrationCertificateDetailedResultsModel> {
    return {
      nameMatching: {
        label: 'Name match',
      },
      addressMatching: {
        label: 'Address match',
      },
      registrationNumberMatching: {
        label: 'Registration number match',
      },
      validAgeInDays: {
        label: 'Valid age since issuing date',
      },
      validDocumentType: {
        label: 'Valid document type',
      },
    };
  }

  override get information(): DocumentAnalysisDefinitionInformation<DocumentAnalysisRegistrationCertificateInformationModel> {
    return {
      address: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.address', {
            defaultValue: 'Address',
            ns: 'domain',
          }),
      },
      issuingDate: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.issuing_date', {
            defaultValue: 'Issuing date',
            ns: 'domain',
          }),
        format: (data) => formatDate(data),
      },
      name: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.name', {
            defaultValue: 'Name',
            ns: 'domain',
          }),
      },
      registrationNumber: {
        label: (t: TFunction) =>
          t(
            'check.document.document_analysis.information.registration_number',
            {
              defaultValue: 'Registration number',
              ns: 'domain',
            },
          ),
      },
      registrationCity: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.registration_city', {
            defaultValue: 'Registration city',
            ns: 'domain',
          }),
      },
      documentType: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.document_type', {
            defaultValue: 'Document type',
            ns: 'domain',
          }),
        format: (value, t: TFunction) =>
          this.isValidDocumentType(value)
            ? this.documentTypes[value].label(t)
            : value,
      },
      otherDocumentType: {
        label: (t: TFunction) =>
          t(
            'check.document.document_analysis.information.other_document_type',
            {
              defaultValue: 'Other Document type',
              ns: 'domain',
            },
          ),
      },
      country: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.country', {
            defaultValue: 'Country',
            ns: 'domain',
          }),
        format: (value) =>
          COUNTRIES.find(({ code }) => code === value)?.name ?? value,
      },
    };
  }

  get documentTypes(): Record<
    DocumentAnalysisRegistrationCertificateDocumentTypesEnum,
    { label: (t: TFunction) => string }
  > {
    return {
      kbis: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.kbis', {
            defaultValue: 'KBIS',
            ns: 'domain',
          }),
      },
      handelsregister: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.handelsregister', {
            defaultValue: 'Handelsregister',
            ns: 'domain',
          }),
      },
      infonet_extract: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.infonet_extract', {
            defaultValue: 'Infonet extract',
            ns: 'domain',
          }),
      },
      inscription_rne: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.inscription_rne', {
            defaultValue: 'Inscription RNE',
            ns: 'domain',
          }),
      },
      pappers_extract: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.pappers_extract', {
            defaultValue: 'Papper extract',
            ns: 'domain',
          }),
      },
      repertoire_metiers_extract: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.repertoire_metiers_extract', {
            defaultValue: 'Repertoire metiers  extract',
            ns: 'domain',
          }),
      },
      sirene_extract: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.sirene_extract', {
            defaultValue: 'Sirene extract',
            ns: 'domain',
          }),
      },
      visura_camerale: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.visura_camerale', {
            defaultValue: 'Visura camerale',
            ns: 'domain',
          }),
      },
      other: {
        label: (t: TFunction) =>
          t('document_analysis.document_types.other', {
            defaultValue: 'Other',
            ns: 'domain',
          }),
      },
    };
  }

  override get defaultParameters(): Omit<
    DocumentAnalysisRegistrationCertificateParametersModel,
    'model'
  > {
    return {
      maxAgeInDays: 90,
      authorizedDocuments: null,
    };
  }

  override get validationSchemas(): {
    parameters?: SchemaOf<DocumentAnalysisRegistrationCertificateParametersModel>;
  } {
    return {
      parameters: documentAnalysisRegistrationCertificateParametersSchema(),
    };
  }

  private isValidDocumentType(
    value: string,
  ): value is DocumentAnalysisRegistrationCertificateDocumentTypesEnum {
    return Object.values(
      DocumentAnalysisRegistrationCertificateDocumentTypesEnum,
    ).includes(
      value as DocumentAnalysisRegistrationCertificateDocumentTypesEnum,
    );
  }
}

export const documentAnalysisRegistrationCertificateDefinition =
  new DocumentAnalysisRegistrationCertificateDefinition();
