import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import { Input, Textarea } from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/data-access-client-portal';

import { FieldProps } from './types';

export const FieldText = ({
  field,
  property,

  helper,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.text>) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const isMultiLine = property.settings?.isMultiLine ?? false;

  const maxLength = property.settings?.maxLength ?? null;
  const maxLengthHelper =
    maxLength &&
    t('forms.field.text.helper.max_length', {
      ns: 'client-portal',
      maxLength,
      defaultValue: `${maxLength} characters max`,
    });

  return (
    <GroupController
      control={control}
      render={(f) =>
        isMultiLine ? (
          <Textarea {...f} value={f.value ?? ''} />
        ) : (
          <Input type="text" {...f} value={f.value ?? ''} />
        )
      }
      helper={
        helper || maxLengthHelper ? (
          <>
            {helper}
            {maxLengthHelper && <p>{maxLengthHelper}</p>}
          </>
        ) : (
          maxLengthHelper
        )
      }
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
