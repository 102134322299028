import React from 'react';

/**
 * Forward ref for a component with generic props
 * @see https://www.totaltypescript.com/forwardref-with-generic-components
 * @see https://react.dev/reference/react/forwardRef
 */
export function forwardRefWithGeneric<T, P = unknown>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.forwardRef(render as any) as any;
}
