import { NotificationWorkflowEnum } from '../../../shared/models';

export const NotificationWorkflowDefinition: Record<
  NotificationWorkflowEnum,
  {
    label: string;
    description: string;
    isConfigurable: boolean;
  }
> = {
  // Case
  [NotificationWorkflowEnum.case_created]: {
    label: 'Any case creation',
    description: 'Notify me when any new case is created.',
    isConfigurable: true,
  },
  [NotificationWorkflowEnum.case_assignee_updated]: {
    label: 'Case assignment',
    description: 'Notify me when I am assigned to a case.',
    isConfigurable: true,
  },
  [NotificationWorkflowEnum.case_status_updated]: {
    label: 'Case status update',
    description:
      'Notify me when the status of a case I am assigned to is updated.',
    isConfigurable: true,
  },
  [NotificationWorkflowEnum.case_review_due]: {
    label: 'Case review due',
    description:
      'Notify me when the periodic review of a case I am assigned to is due.',
    isConfigurable: true,
  },

  // Case report
  [NotificationWorkflowEnum.case_report_generated]: {
    label: 'Case report generated',
    description: 'Notify me when a report I requested is ready to download',
    isConfigurable: true,
  },

  // Case progress
  [NotificationWorkflowEnum.case_waiting_for_new_information]: {
    label: 'Case waiting for new information',
    description:
      'Notify me when the case I am assigned is waiting for new information.',
    isConfigurable: true,
  },
  [NotificationWorkflowEnum.case_ready_for_review]: {
    label: 'Case ready for review',
    description: 'Notify me when the case I am assigned is ready for review.',
    isConfigurable: true,
  },
  [NotificationWorkflowEnum.case_checks_all_approved]: {
    label: "Case's checks all approved",
    description:
      'Notify me when the checks of case I am assigned are all approved.',
    isConfigurable: true,
  },

  // Note
  [NotificationWorkflowEnum.note_comment_created]: {
    label: 'New note comment',
    description:
      "Notify me when a new comment is added on a case note I've created, commented or where I'm mentioned.",
    isConfigurable: true,
  },
  [NotificationWorkflowEnum.user_mentioned]: {
    label: 'New mention',
    description: 'Notify me when I’m mentioned on a case note or comment.',
    isConfigurable: true,
  },

  // Other
  [NotificationWorkflowEnum.webhook_disabled]: {
    label: 'Webhook disabled',
    description:
      'Notify me when a webhook is disabled due to high amount of errored calls.',
    isConfigurable: true,
  },
  [NotificationWorkflowEnum.webhook_high_error_rate]: {
    label: 'Webhook high error rate',
    description:
      'Notify me when a production webhook is experiencing a high error rate.',
    isConfigurable: true,
  },

  // Other (Non configurable)
  [NotificationWorkflowEnum.document_order_completed]: {
    label: 'Document order completed',
    description: 'Notify me when a document I ordered is available.',
    isConfigurable: false,
  },
  [NotificationWorkflowEnum.import_completed]: {
    label: 'Import completed',
    description: 'Notify me when an import I requested is done.',
    isConfigurable: false,
  },
  [NotificationWorkflowEnum.export_completed]: {
    label: 'Export completed',
    description: 'Notify me when an export I requested is ready to download.',
    isConfigurable: false,
  },

  // Communication
  [NotificationWorkflowEnum.communication_received]: {
    label: 'Communication received',
    description: 'Notify me an individual answer to a communication.',
    isConfigurable: true,
  },
};
