import { ClientPortalStepTypeEnum } from '../../../../shared/models';
import { affiliatedCompaniesEditStepDefinition } from './affiliated-companies-edit-step-definition';
import { BaseClientPortalStepDefinition } from './base-client-portal-step-definition';
import { businessContactStepDefinition } from './business-contact-step-definition';
import { companyEditStepDefinition } from './company-edit-step-definition';
import { companySearchStepDefinition } from './company-search-step-definition';
import { customStepDefinition } from './custom-step-definition';
import { individualEditStepDefinition } from './individual-edit-step-definition';
import { individualsEditStepDefinition } from './individuals-edit-step-definition';

export const clientPortalStepDefinitionsRecord = {
  [ClientPortalStepTypeEnum.company_search]: companySearchStepDefinition,
  [ClientPortalStepTypeEnum.company_edit]: companyEditStepDefinition,
  [ClientPortalStepTypeEnum.affiliated_companies_edit]:
    affiliatedCompaniesEditStepDefinition,
  [ClientPortalStepTypeEnum.individuals_edit]: individualsEditStepDefinition,
  [ClientPortalStepTypeEnum.individual_edit]: individualEditStepDefinition,
  [ClientPortalStepTypeEnum.business_contact]: businessContactStepDefinition,
  [ClientPortalStepTypeEnum.custom]: customStepDefinition,
} as const satisfies Record<
  ClientPortalStepTypeEnum,
  // Must be `any` otherwise there are error in projects that are not in typescript strict mode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  BaseClientPortalStepDefinition<any>
>;

/**
 * All Check definitions type
 */
export type ClientPortalStepDefinitionMapping =
  typeof clientPortalStepDefinitionsRecord;
export type AllClientPortalStepDefinitions =
  (typeof clientPortalStepDefinitionsRecord)[keyof ClientPortalStepDefinitionMapping];

/**
 * Get Client portal step definition for ClientPortalStepTypeEnum
 * @param type
 * @returns
 */
export function getClientPortalStepDefinition<
  T extends ClientPortalStepTypeEnum,
>(type: T): ClientPortalStepDefinitionMapping[T] {
  return clientPortalStepDefinitionsRecord[type];
}
