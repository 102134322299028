import {
  Drawer as ChakraDrawer,
  DrawerBody as ChakraDrawerBody,
  DrawerCloseButton as ChakraDrawerCloseButton,
  DrawerContent as ChakraDrawerContent,
  DrawerContentProps as ChakraDrawerContentProps,
  DrawerFooter as ChakraDrawerFooter,
  DrawerHeader as ChakraDrawerHeader,
  DrawerOverlay as ChakraDrawerOverlay,
  DrawerProps as ChakraDrawerProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/overlay/drawer
export type DrawerProps = ChakraDrawerProps;
export const Drawer = ChakraDrawer;

export const DrawerHeader = ChakraDrawerHeader;
export const DrawerBody = ChakraDrawerBody;
export const DrawerFooter = ChakraDrawerFooter;
export const DrawerOverlay = ChakraDrawerOverlay;

export type DrawerContentProps = ChakraDrawerContentProps;
export const DrawerContent = ChakraDrawerContent;

export const DrawerCloseButton = ChakraDrawerCloseButton;
