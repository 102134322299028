import { PendingStatuses, useCaseChecks } from '../../shared';

export function usePendingActions(entityId?: string) {
  const { data, loading } = useCaseChecks();

  const checks = [
    ...(data?.case?.companies.flatMap((company) =>
      company.checks.map((c) => ({ ...c, entityId: company.id })),
    ) ?? []),
    ...(data?.case?.individuals.flatMap((individual) =>
      individual.checks.map((c) => ({ ...c, entityId: individual.id })),
    ) ?? []),
  ];

  return {
    count: checks.filter(
      (check) =>
        PendingStatuses.includes(check.status) &&
        (!entityId || check.entityId === entityId),
    ).length,
    loading,
  };
}
