import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  CaseQueryDocument,
  ClientPortalChecks_ContactHasActionsRefresh,
  useTryCompleteChecksMutation,
} from '@dotfile/shared/data-access-client-portal';

export const useTryCompleteChecks = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const [runMutation, result] = useTryCompleteChecksMutation();

  const tryCompleteChecks = useCallback(
    async (
      data: ClientPortalChecks_ContactHasActionsRefresh | boolean | undefined,
    ): Promise<void> => {
      try {
        const contactHasActions =
          typeof data === 'boolean'
            ? data
            : (data?.company?.case.contactHasActions ??
              data?.individual?.case.contactHasActions);

        if (contactHasActions === false) {
          await runMutation({
            // This will force reevaluate ValidateRouteGuard to go to End page
            refetchQueries: [CaseQueryDocument],
            awaitRefetchQueries: true,
          });
        }
      } catch (error) {
        handleError({
          title: t('errors.default.title', {
            ns: 'client-portal',
            defaultValue: 'An error occurred',
          }),
          error,
        });
      }
    },
    [runMutation, handleError, t],
  );

  return [tryCompleteChecks, result] as const;
};
