import { Check } from 'lucide-react';
import { useImperativeHandle, useMemo, useRef } from 'react';

import {
  Box,
  forwardRef,
  Icon,
  RadioProps,
  Stack,
  StackProps,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
} from '@chakra-ui/react';

import { Text } from '../../typography/text';

interface ChildrenProps {
  children: string | JSX.Element;
}
export type RadioCardOptionProps = ChildrenProps &
  RadioProps & { checkIndicatorPosition?: 'left' | 'right' };

/**
 * @doc https://chakra-ui.com/docs/form/radio#custom-radio-buttons
 * It seems to be a bug with emotion and storybook: Type 'InterpolationWithTheme<any>' is not assignable to type 'Interpolation<Theme>'
 * https://bleepcoder.com/fr/emotion/846791172/types-of-property-css-are-incompatible-type
 */

// 1. Create a component that consumes the `useRadio` hook
export const RadioCardOption = forwardRef(
  (props: RadioCardOptionProps, ref): JSX.Element => {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps();
    const radio = getRadioProps();
    return (
      <Box
        as="label"
        {...radio}
        position="relative"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        borderColor={props.isInvalid ? 'red.400' : 'gray.100'}
        bg={props.isInvalid ? 'red.50' : 'white'}
        flex="1 1 0"
        // width="0"
        _readOnly={{
          cursor: 'not-allowed',
        }}
        _hover={{
          bg: 'white',
          borderColor: 'gray.300',
        }}
        _checked={{
          bg: 'blue.50',
          borderColor: 'blue.500',
        }}
        _focusVisible={{ borderColor: 'blue.300' }}
        px={6}
        py={4}
      >
        {props.isChecked && (
          <Box
            borderTopRightRadius={
              props.checkIndicatorPosition === 'right' ? 'md' : undefined
            }
            borderBottomLeftRadius={
              props.checkIndicatorPosition === 'right' ? 'md' : undefined
            }
            borderTopLeftRadius={
              props.checkIndicatorPosition === 'left' ? 'md' : undefined
            }
            borderBottomRightRadius={
              props.checkIndicatorPosition === 'left' ? 'md' : undefined
            }
            bg="blue.500"
            w="6"
            h="6"
            position="absolute"
            top="-1px"
            right={
              props.checkIndicatorPosition === 'right' ? '-1px' : undefined
            }
            left={props.checkIndicatorPosition === 'left' ? '-1px' : undefined}
            textAlign="center"
            lineHeight="6"
            pt="2px"
          >
            <Icon as={Check} color="white" />
          </Box>
        )}
        {/* @ts-ignore Types of property 'css' are incompatible */}
        <input {...input} ref={ref} />
        {/* @ts-ignore Types of property 'css' are incompatible */}
        {typeof props.children === 'string' ? (
          <Text color="inherit">{props.children}</Text>
        ) : (
          props.children
        )}
      </Box>
    );
  },
);

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export type RadioCardProps = {
  options:
    | {
        value: UseRadioGroupProps['defaultValue'];
        element: string | JSX.Element;
      }[]
    | UseRadioGroupProps['defaultValue'][];
  value?: UseRadioGroupProps['value'];
  defaultValue?: UseRadioGroupProps['defaultValue'];
  onChange?: UseRadioGroupProps['onChange'];
  isReadOnly?: boolean;
  isInvalid?: boolean;
  checkIndicatorPosition?: 'left' | 'right';
} & Omit<StackProps, 'onChange'>;

export const RadioCard = forwardRef<RadioCardProps, typeof Stack>(
  (
    {
      options,
      defaultValue,
      value,
      onChange,
      isReadOnly = false,
      isInvalid, // Avoid spreading it with props
      checkIndicatorPosition = 'right',
      ...props
    },
    ref,
  ) => {
    // We need to format option because we can pass an string[] only
    const formattedOptions = useMemo(() => {
      return options.map((item) =>
        item !== undefined && typeof item === 'object' && 'element' in item
          ? item
          : { value: item, element: item },
      );
    }, [options]);

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: formattedOptions.toString(),
      value: value,
      defaultValue: defaultValue,
      onChange: onChange,
    });

    // @NOTE: the focus on the root element will not allow to "Enter" submit the form. Need to focus the underlying input of the selected option
    const selectedInputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          setTimeout(() => {
            selectedInputRef.current?.focus?.();
          }, 0);
        },
      }),
      [],
    );

    const group = getRootProps();
    return (
      //@ts-ignore Types of property 'css' are incompatible
      <Stack
        direction="row"
        {...group}
        ref={ref}
        {...props}
        alignItems="stretch"
      >
        {formattedOptions.map(({ value, element }) => {
          const radio = getRadioProps({ value });
          return (
            //@ts-ignore Types of property 'css' are incompatible
            <RadioCardOption
              key={value}
              value={value}
              {...radio}
              isInvalid={isInvalid}
              ref={radio['isChecked'] ? selectedInputRef : null}
              isReadOnly={isReadOnly}
              checkIndicatorPosition={checkIndicatorPosition}
            >
              {element ? element : ''}
            </RadioCardOption>
          );
        })}
      </Stack>
    );
  },
);
RadioCard.displayName = 'RadioCard';
