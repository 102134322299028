import {
  Avatar,
  AvatarProps,
  forwardRef,
  Image,
  Skeleton,
} from '@dotfile/frontend/shared/design-system';

type LogoProps = Omit<AvatarProps, 'src'> & {
  url?: string;
  name?: string;
};

export const Logo = forwardRef<LogoProps, typeof Avatar>(
  ({ url, name, ...props }, ref) => {
    if (!(url || name)) {
      return <Skeleton w="full" h="full" borderRadius="md" {...props} />;
    }

    return (
      <Image
        h="100%"
        w="auto"
        alt={name ?? 'Logo'}
        src={url}
        ref={ref}
        {...props}
      />
    );
  },
);
