import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import 'react-virtualized/styles.css';

import { Bootstrap } from './app/bootstrap';

const root = ReactDOM.createRoot(
  document.getElementById('app-root') as HTMLElement,
);
root.render(
  <StrictMode>
    <Bootstrap />
  </StrictMode>,
);
