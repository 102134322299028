import { LlmVendorEnum } from './models';

/**
 * Maximum number of item in a list, enforced at the time of creation and
 * used in the frontend as the maximum number to retrieve in statically-paginated list
 *
 * There are several follow-up to get ride of this constants and use a proper pagination for this lists:
 * @TODO - E-1248 - Proper pagination of Members, Invites in workspace setting
 * @TODO - E-1668 - Workspace: Implement pagination
 * @TODO - E-2585 - Handle Tags pagination properly
 * @TODO - E-2603 - Handle Users/Assignee pagination properly
 * @TODO - E-2604 - Handle Template pagination properly
 * @TODO - E-2606 - Handle API Key pagination properly
 * @TODO - E-2607 - Handle Custom Document Type pagination properly
 *
 */
export const DEFAULT_MAX_ITEMS = 50;

export const DEFAULT_MAX_PAGE_SIZE = 100;
export const PAGE_SIZES = [25, 50, DEFAULT_MAX_PAGE_SIZE];

// Default LLM Vendor, takes in order of the array
export const DEFAULT_LLM_VENDOR_PREFERENCE = [
  LlmVendorEnum.open_ai,
  LlmVendorEnum.mistral_ai,
] satisfies LlmVendorEnum[];
