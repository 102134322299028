import {
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuButtonProps as ChakraMenuButtonProps,
  MenuDivider as ChakraMenuDivider,
  MenuDividerProps as ChakraMenuDividerProps,
  MenuGroup as ChakraMenuGroup,
  MenuGroupProps as ChakraMenuGroupProps,
  MenuItem as ChakraMenuItem,
  MenuItemOption as ChakraMenuItemOption,
  MenuItemOptionProps as ChakraMenuItemOptionProps,
  MenuItemProps as ChakraMenuItemProps,
  MenuList as ChakraMenuList,
  MenuListProps as ChakraMenuListProps,
  MenuOptionGroup as ChakraMenuOptionGroup,
  MenuOptionGroupProps as ChakraMenuOptionGroupProps,
  MenuProps as ChakraMenuProps,
} from '@chakra-ui/react';

export type MenuProps = ChakraMenuProps;
export const Menu = ChakraMenu;

export type MenuButtonProps = ChakraMenuButtonProps;
export const MenuButton = ChakraMenuButton;

export type MenuDividerProps = ChakraMenuDividerProps;
export const MenuDivider = ChakraMenuDivider;

export type MenuGroupProps = ChakraMenuGroupProps;
export const MenuGroup = ChakraMenuGroup;

export type MenuItemProps = ChakraMenuItemProps;
export const MenuItem = ChakraMenuItem;

export type MenuOptionGroupProps = ChakraMenuOptionGroupProps;
export const MenuOptionGroup = ChakraMenuOptionGroup;

export type MenuItemOptionProps = ChakraMenuItemOptionProps;
export const MenuItemOption = ChakraMenuItemOption;

export type MenuListProps = ChakraMenuListProps;
export const MenuList = ChakraMenuList;
