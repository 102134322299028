import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  generateCompanySearchValidationSchema,
  mainCompanySelector,
  useFormDatastore,
} from '../../shared';

export type SearchType = 'name' | 'registrationNumber';

export function useCompanySearchForm() {
  const { t } = useTranslation();
  const mainCompany = useFormDatastore(mainCompanySelector);

  const [searchType, setSearchType] = useState<SearchType>(
    mainCompany?.registrationNumber ? 'registrationNumber' : 'name',
  );

  const defaultValues = useMemo(() => {
    return {
      country: mainCompany?.country ?? null,
      name: mainCompany?.name ?? '',
      registrationNumber: mainCompany?.registrationNumber ?? '',
    };
  }, [mainCompany]);

  const resolver = useMemo(() => {
    const schema = generateCompanySearchValidationSchema({ t });
    return yupResolver(schema);
  }, [t]);

  const methods = useForm<{
    country: string | null;
    name: string;
    registrationNumber: string;
  }>({
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
    context: { searchType },
    resolver,
  });

  return { methods, searchType, setSearchType };
}
