import { ChevronDown, ChevronUp } from 'lucide-react';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Collapse,
  Icon,
  Skeleton,
  StackProps,
  useControllableState,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_CheckInterface } from '@dotfile/shared/data-access-client-portal';

import { FormattedTrans } from '../../../../shared';
import { getCheckTranslationKey } from '../utils';

type CheckDescriptionProps = {
  check: ClientPortalChecks_CheckInterface;
} & StackProps;

// Approximately 5 lines
const SEE_MORE_LIMIT = 90;

// @TODO - E-3452 - Design: SeeMore component

export const CheckDescription = ({
  check,
  ...props
}: CheckDescriptionProps) => {
  const { t } = useTranslation();
  const innerDivRef = useRef<HTMLDivElement>(null);
  const [_isCollapseOpen, setIsCollapseOpen] = useControllableState({
    defaultValue: false,
  });
  const [isCollapsible, setIsCollapsible] = useState(true);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver;

    const trigger = () => {
      const contentHeight = innerDivRef.current?.clientHeight;
      if (contentHeight) {
        if (contentHeight <= SEE_MORE_LIMIT) {
          setIsCollapsible(false);
        } else {
          setIsCollapsible(true);
        }
      }
    };

    if (innerDivRef.current && !loading) {
      if ('ResizeObserver' in window) {
        resizeObserver = new ResizeObserver(trigger);
        resizeObserver.observe(innerDivRef.current);
      }

      trigger();
    }

    return () => {
      resizeObserver?.disconnect();
    };
  }, [setIsCollapsible, loading]);

  // @NOTE: When rendering in the modal, only the end of the text get displayed
  // Easiest fix is to force rerender.
  useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), 0);
    }
  }, [loading]);

  const isOpen = !isCollapsible || _isCollapseOpen;

  const toggleCollapse = useCallback(() => {
    const newIsOpen = !isOpen;
    setIsCollapseOpen(newIsOpen);
  }, [setIsCollapseOpen, isOpen]);

  const checkLabelTranslationKey = getCheckTranslationKey(check);
  const description = t(`${checkLabelTranslationKey}.description`, {
    defaultValue: '',
    ns: 'dynamic',
    fallbackLng: [],
  });

  // Since default value is required with t function, don't render if equal to defaultValue
  if (description === '') {
    return null;
  }

  if (loading) {
    return <Skeleton w="full" height="8" />;
  }

  return (
    <VStack align="stretch" width="full" {...props}>
      <Collapse
        in={isOpen}
        startingHeight={isCollapsible ? `${SEE_MORE_LIMIT}px` : undefined}
      >
        <Box width="full" ref={innerDivRef}>
          <FormattedTrans
            position="relative"
            fontSize="sm"
            color="gray.700"
            defaultValue={description}
            i18nKey={`${checkLabelTranslationKey}.description`}
            ns="dynamic"
          />
        </Box>
      </Collapse>
      {isCollapsible && (
        <Button
          width="min-content"
          mt="1"
          variant="ghost"
          onClick={toggleCollapse}
          leftIcon={<Icon as={isOpen ? ChevronUp : ChevronDown} />}
        >
          {isOpen ? 'See less' : 'See more'}
        </Button>
      )}
    </VStack>
  );
};
