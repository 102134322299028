import {
  ClientPortalCollaborationEnum,
  ClientPortalSettingModel,
  ClientPortalSettingStylingModel,
} from '../../../../shared/models';
import { DEFAULT_STYLING_COLORS_MAIN } from '../../../../shared/styling';
import yup from '../../../../utils/yup-extended';
import {
  MAGIC_LINK_TTL_DEFAULT,
  MAGIC_LINK_TTL_MAX,
  MAGIC_LINK_TTL_MIN,
} from '../../constants';
import { CLIENT_PORTAL_REDIRECT_URL_VARIABLES } from '../constants';

export const clientPortalSettingStylingSchema = (): yup.SchemaOf<
  Pick<ClientPortalSettingStylingModel, 'colorsMain' | 'showDotfileLogo'> & {
    logoUploadRef: string | null;
    emailLogoUploadRef: string | null;
    faviconUploadRef: string | null;
  }
> => {
  return yup
    .object()
    .shape({
      logoUploadRef: yup.string().nullable().optional(),
      emailLogoUploadRef: yup.string().nullable().optional(),
      faviconUploadRef: yup.string().nullable().optional(),
      showDotfileLogo: yup.boolean().default(true).required(),
      colorsMain: yup
        .string()
        .hexColor()
        .default(DEFAULT_STYLING_COLORS_MAIN)
        .required(),
    })
    .defined();
};

export const clientPortalSettingSchema = (): yup.SchemaOf<
  Pick<
    ClientPortalSettingModel,
    | 'clientPortalId'
    | 'clientPortalVersionId'
    | 'isCaseCreationAllowed'
    | 'collaboration'
    | 'magicLinkTTL'
    | 'supportEmail'
    | 'redirectUrl'
    | 'refId'
    | 'styling'
    | 'workflowCode'
    | 'templateId'
  >
> => {
  return yup
    .object()
    .shape({
      clientPortalId: yup.string().uuid().required(),
      clientPortalVersionId: yup.string().uuid().nullable().optional(),
      isCaseCreationAllowed: yup.boolean().default(true).optional(),
      collaboration: yup
        .string()
        .oneOf(Object.values(ClientPortalCollaborationEnum))
        .required(),
      magicLinkTTL: yup
        .number()
        .min(MAGIC_LINK_TTL_MIN)
        .max(MAGIC_LINK_TTL_MAX)
        .default(MAGIC_LINK_TTL_DEFAULT)
        .optional(),
      supportEmail: yup.string().optionalString().email(),
      redirectUrl: yup
        .string()
        .optionalString()
        .test(
          'valid-url-with-variables',
          'redirectUrl must be a valid URL',
          (value) => {
            if (!value) {
              return true;
            }

            let valueWithoutVariables = value;
            for (const variable of CLIENT_PORTAL_REDIRECT_URL_VARIABLES) {
              valueWithoutVariables = valueWithoutVariables.replace(
                variable,
                'placeholder',
              );
            }

            return yup.string().url().isValidSync(valueWithoutVariables);
          },
        ),
      refId: yup.string().optionalString(),
      styling: clientPortalSettingStylingSchema().default({}),
      workflowCode: yup.string().optionalString(),
      templateId: yup.string().uuid().nullable().optional(),
    })
    .noUnknown()
    .defined();
};
