import { useFormContext, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { createUseDebounce } from '@dotfile/frontend/shared/common';
import { GroupController } from '@dotfile/frontend/shared/components';
import {
  HStack,
  RICH_TEXT_ONLY_EMPTY_PARAGRAPH_REGEX,
  RichText,
  RichTextModeEnum,
  Text,
  Toggle,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { InviteUserForm } from '../hooks';

const useDebounce =
  createUseDebounce<Parameters<UseFormSetValue<InviteUserForm>>>(100);

export const InviteCustomMessage = () => {
  const { t } = useTranslation();

  const { watch, control, setValue } = useFormContext<InviteUserForm>();
  const debouncedSetValue = useDebounce(setValue);

  const addCustomMessage = watch('_addCustomMessage');

  return (
    <VStack width="full" alignItems="start">
      <GroupController
        name="_addCustomMessage"
        control={control}
        render={({ value, ...field }) => {
          const handleChange = () => {
            setValue(field.name, !value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          };
          return (
            <HStack>
              <Toggle
                {...field}
                onChange={handleChange}
                isChecked={value}
                sx={{
                  'span.chakra-switch__track[data-checked]': {
                    backgroundColor: 'custom.500',
                  },
                }}
              />
              <Text
                onClick={handleChange}
                cursor="pointer"
                color="black"
                fontWeight="medium"
              >
                {t('collaboration.invite.add_custom_message', {
                  defaultValue: 'Add custom message',
                  ns: 'client-portal',
                })}
              </Text>
            </HStack>
          );
        }}
      />

      {addCustomMessage && (
        <GroupController
          name="customMessage"
          control={control}
          render={({ value, ...field }) => (
            <RichText
              {...field}
              onContentChange={(newContent) => {
                const newValue = newContent.match(
                  RICH_TEXT_ONLY_EMPTY_PARAGRAPH_REGEX,
                )
                  ? ''
                  : newContent;
                debouncedSetValue('customMessage', newValue, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                });
              }}
              defaultContent={value}
              backgroundColor="white"
              variant="large"
              mode={RichTextModeEnum.edit}
            />
          )}
        />
      )}
    </VStack>
  );
};
