import {
  DeepNonNullable,
  DeepNullable,
  DeepPartial,
  MarkRequired,
  StrictDeepPick,
} from 'ts-essentials';

import {
  maxSorensenDiceCoefficient,
  normalize,
  stopWords as STOP_WORDS,
  tokenize,
} from '@dotfile/shared/common';

import { CompanyModel } from '../../../../shared/models';

export type MaybeComparableCompany = DeepNullable<
  DeepPartial<
    StrictDeepPick<
      CompanyModel,
      { country: true; registrationNumber: true; address: { city: true } }
    >
  >
>;
type ComparableCompany = DeepNonNullable<
  MarkRequired<MaybeComparableCompany, 'country' | 'registrationNumber'>
> &
  MaybeComparableCompany;

type BoundCompanyComparisonFunction = (
  other: MaybeComparableCompany,
) => boolean;

/**
 * Create a instance to compare `current` company with multiple other companies
 */
export class CompanyComparator {
  /**
   * Determine if an company is comparable, that mean it
   * has a country and a registration number
   *
   * @param company
   * @returns
   */
  public static isCompanyComparable(
    company: MaybeComparableCompany,
  ): company is ComparableCompany {
    return !!company.country && !!company.registrationNumber;
  }

  private readonly _isComparable: boolean;
  private readonly _isSame: BoundCompanyComparisonFunction;

  constructor(current: MaybeComparableCompany) {
    if (!CompanyComparator.isCompanyComparable(current)) {
      this._isComparable = false;
      this._isSame = () => false;
    } else {
      this._isComparable = true;

      const currentNormalizedRegistrationNumberTokens =
        this.tokenizeNormalizeCompanyRegistrationNumber(current);

      this._isSame = (other: MaybeComparableCompany) => {
        if (
          !CompanyComparator.isCompanyComparable(other) ||
          other.country.toLocaleUpperCase() !==
            current.country.toLocaleUpperCase()
        ) {
          return false;
        }

        const otherNormalizedRegistrationNumberTokens =
          this.tokenizeNormalizeCompanyRegistrationNumber(other);

        const bestCoefficient = maxSorensenDiceCoefficient(
          currentNormalizedRegistrationNumberTokens,
          otherNormalizedRegistrationNumberTokens,
        );

        // @NOTE: THis is registration number comparison is very similar to
        // libs/backend/modules/checks/src/lib/shared/data-comparison/data-comparison.service.ts
        return bestCoefficient > 0.9;
      };
    }
  }

  /**
   * Whether `current` company is comparable.
   *
   * When it is not, `isSame` will always returns false without checking the `other`
   * company
   */
  get isComparable(): boolean {
    return this._isComparable;
  }

  /**
   * Determine if `other` company is the same.
   *
   * Will compare each first and last name, transforming to lowercase, ignoring spaces/dashes
   * and removing combining diacritical marks.
   *
   * Will return false when any companies is missing first or last name
   *
   * @param other
   * @returns
   */
  public isSame(other: MaybeComparableCompany): boolean {
    return this._isSame(other);
  }

  private tokenizeNormalizeCompanyRegistrationNumber(
    company: ComparableCompany,
  ): string[] {
    const stopWords = [...STOP_WORDS.registrationNumber];
    if (company?.address?.city) {
      stopWords.push(company?.address.city);
    }

    const normalizedRegistrationNumber = normalize(company.registrationNumber, {
      removeWhiteSpace: true,
      stopWords,
    });

    return tokenize(normalizedRegistrationNumber);
  }
}
