import * as lucide from 'lucide-react';

import { IdentityCardCheck } from './custom';

const { createLucideIcon /* omitted */, ...lucideIcons } = lucide;

export const ALL_ICONS = {
  ...lucideIcons,
  IdentityCardCheck,
};
