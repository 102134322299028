import { useCallback } from 'react';

import {
  parseAbsoluteDate,
  stringifyAbsoluteDate,
} from '@dotfile/shared/common';

import { forwardRef } from '../../utils/component-factory';
import { DatePicker, DatePickerProps } from './date-picker';

export type AbsoluteDatePickerProps = Omit<
  DatePickerProps<false>, // Only single date picker is implemented
  'onChange' | 'value'
> & {
  onChange: (newAbsoluteDate: string | null) => void;
  value: string | null | undefined;
};

/**
 * DatePicker for absolute date (yyyy-MM-dd)
 */
export const AbsoluteDatePicker = forwardRef(
  (
    { onChange, value, ...props }: AbsoluteDatePickerProps,
    ref,
  ): JSX.Element => {
    const handleChange = useCallback(
      (newValue: Date | null) => {
        const newDate = newValue ? stringifyAbsoluteDate(newValue) : null;
        onChange(newDate);
      },
      [onChange],
    );

    return (
      <DatePicker
        ref={ref}
        onChange={handleChange}
        value={value ? parseAbsoluteDate(value) : undefined}
        {...props}
      />
    );
  },
);
