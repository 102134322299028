import { useEffect } from 'react';

import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

import { useEnvironmentContext } from '../contexts';
import { useReleaseInfo } from '../hooks';
import { datadogRUMDefaultConfig } from './default-config';

export const useInitDatadogRUM = (
  service: string,
  config?: Partial<RumInitConfiguration>,
): void => {
  const environment = useEnvironmentContext();
  const { data: releaseInfo } = useReleaseInfo();

  useEffect(() => {
    if (
      !datadogRum.getInternalContext() &&
      environment.datadogRUM &&
      releaseInfo
    ) {
      const initConfig = {
        ...datadogRUMDefaultConfig,
        ...config,
        applicationId: environment.datadogRUM.applicationId,
        clientToken: environment.datadogRUM.clientToken,
        env: environment.stage,
        version: releaseInfo?.commit,
        service,
      };
      datadogRum.init(initConfig);
      console.debug(`🐶 Datadog initialized for ${service}`, { initConfig });
    }
  }, [environment.datadogRUM, environment.stage, releaseInfo, service, config]);
};
