import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';

import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useHttpClient } from '@dotfile/frontend/shared/common';

import { useHandleError } from '../../error-handler';
import { useInProgressUploadContext } from '../contexts';

/**
 * From apps/api/src/modules/foundation/file/dto/uploaded-file.dto.ts
 */
type UploadResponse = {
  uploadRef: string;
};

type UploadFileResult = {
  uploadRef: string;
  fileName: string;
};

/**
 * `useUploadFile` is a shared hook that allow us to handle the formData logic to upload a file
 * with progress
 * @param url The endpoint to upload to. This will determine the storage in the backend.
 */
export const useUploadFileWithProgress = (
  url: string,
): UseMutationResult<UploadFileResult, unknown, File, unknown> & {
  progress?: number;
} => {
  const httpClient = useHttpClient();
  const { t } = useTranslation();
  const handleError = useHandleError();

  const [progress, updateProgress] = useState<undefined | number>(undefined);
  const { startUpload, endUpload } = useInProgressUploadContext();

  const uploadFile = useMutation({
    mutationFn: async (file: File): Promise<UploadFileResult> => {
      const formData = new FormData();
      formData.append('file', file);

      // Set initial progress to 0, onUploadProgress event are not fire initially
      updateProgress(0);
      const { data } = await httpClient.post<UploadResponse>(
        url,
        // browser will automatically set header 'Content-Type: multipart/form-data; boundary=----WebKitFormBoundary...'
        // @see https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
        formData,
        {
          onUploadProgress: (ev) => {
            const progress = Math.round(
              (ev.loaded / (ev.total ?? Number.POSITIVE_INFINITY)) * 100,
            );
            updateProgress(progress);
            if (progress === 100) {
              updateProgress(undefined);
            }
          },
        },
      );

      const createFileInput: UploadFileResult = {
        fileName: file.name,
        uploadRef: data.uploadRef,
      };

      return createFileInput;
    },
    onError: (error) => {
      handleError({
        error,
        title: t('file.error.on_save', {
          defaultValue:
            'Something went wrong, try re-importing your document and if the problem persists contact support.',
          ns: 'components',
        }),
      });
    },
    onMutate: () => {
      const uploadId = uuidV4();
      startUpload(uploadId);
      return { uploadId };
    },
    onSettled: (data, error, variables, context) => {
      context && endUpload(context.uploadId);
    },
  });

  return { ...uploadFile, progress } as const;
};
