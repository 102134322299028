import { createContext, PropsWithChildren, useContext } from 'react';

import { ClientPortalForms_Step } from '@dotfile/shared/data-access-client-portal';

const CurrentStepContext = createContext<ClientPortalForms_Step | undefined>(
  undefined,
);

export const useCurrentStep = () => {
  const context = useContext(CurrentStepContext);
  if (context === undefined) {
    throw new Error(
      `value for CurrentStepContext was not initialized. Make sure the CurrentStepProvider is set up.`,
    );
  }

  return context;
};

export type CurrentStepContextProviderProps = PropsWithChildren<{
  step: ClientPortalForms_Step;
}>;
export const CurrentStepContextProvider = ({
  step,
  children,
}: CurrentStepContextProviderProps) => {
  return (
    <CurrentStepContext.Provider value={step}>
      {children}
    </CurrentStepContext.Provider>
  );
};
