import {
  Tag as ChakraTag,
  TagLabel as ChakraTagLabel,
  TagProps as ChakraTagProps,
  TagCloseButton as CharkaTagCloseButton,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/react';

import {
  extractTextFromNode,
  useAsyncFn,
} from '@dotfile/frontend/shared/common';

import { Spinner } from '../../feedback/spinner/spinner';
import { colors } from '../../theme/colors';

export type TagProps = {
  label: React.ReactNode;
  canBeClosed?: boolean;
  tooltip?: string;
  maxWidth?: number;
  onClick?: () => Promise<void>;
} & ChakraTagProps;

const defaultOnClick = () => Promise.resolve();

export const Tag = forwardRef<TagProps, 'span'>(
  (
    {
      label,
      maxWidth,
      canBeClosed = true,
      tooltip,
      onClick,
      ...props
    }: TagProps,
    ref,
  ): JSX.Element => {
    const [{ loading }, click] = useAsyncFn(onClick || defaultOnClick);
    return (
      <ChakraTag
        color={colors.blue['700']}
        backgroundColor={colors.blue['100']}
        borderRadius="full"
        {...props}
        ref={ref}
        px="2"
      >
        <ChakraTagLabel
          display="inline-block"
          title={extractTextFromNode(label)}
          maxWidth={maxWidth ? maxWidth : 'auto'}
          h="4"
          overflow="hidden"
          wordBreak="break-all"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          noOfLines={1}
        >
          {label}
        </ChakraTagLabel>
        {canBeClosed &&
          (loading ? (
            <Spinner ml="2" size="xs" />
          ) : (
            <CharkaTagCloseButton
              ml="1"
              fontSize="sm"
              transition="all 0.4s"
              _hover={{
                background: colors.blue['200'],
              }}
              onClick={click}
            />
          ))}
      </ChakraTag>
    );
  },
);
