import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useHttpClient } from '@dotfile/frontend/shared/common';
import { useToast } from '@dotfile/frontend/shared/design-system';
import { FileModel } from '@dotfile/shared/domain';

import { useHandleError } from '../../error-handler';

export type SaveFileMutation = UseMutationResult<
  void,
  unknown,
  Pick<FileModel, 'id' | 'name'>
>;

export const useSaveFile = ({ showSuccessToast = false } = {}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const httpClient = useHttpClient();
  const handleError = useHandleError();

  const saveFile = useMutation({
    mutationFn: async (file: Pick<FileModel, 'id' | 'name'>) => {
      const res = await httpClient.get(`/files/preview/${file.id}`, {
        responseType: 'blob',
      });

      saveAs(res.data, file.name);

      if (showSuccessToast)
        toast({
          title: t('file.save.success', {
            defaultValue: 'File {{fileName}} downloaded',
            ns: 'components',
            fileName: file.name,
          }),
          description: file.name,
          status: 'success',
        });
    },
    onError: (error: Error, file) => {
      handleError({
        title: t('file.save.error', {
          defaultValue: 'Something went wrong when downloading {{fileName}}',
          ns: 'components',
          fileName: file.name,
        }),
        error,
      });
    },
  });

  return saveFile;
};
