import { DeepPartial, StrictDeepOmit } from 'ts-essentials';

import {
  CommunicationEventTypeEnum,
  WorkspaceSettingsCommunicationModel,
  WorkspaceSettingsCommunicationStylingModel,
} from '../../../../shared/models';
import { DEFAULT_STYLING_COLORS_MAIN } from '../../../../shared/styling';
import yup from '../../../../utils/yup-extended';
import {
  COMMUNICATION_MAX_REMINDER_MAX_COUNT,
  COMMUNICATION_MAX_REMINDER_PERIOD_IN_SECONDS,
  COMMUNICATION_MIN_REMINDER_MAX_COUNT,
  COMMUNICATION_MIN_REMINDER_PERIOD_IN_SECONDS,
  COMMUNICATION_SENDER_NAME_MAX_LENGTH,
  communicationSenderUserOptions,
} from '../../communication-hub';
import { WORKSPACE_SETTINGS_COMMUNICATION_FOOTER_MAX_LENGTH } from '../constants';

export const communicationSettingsStylingSchema = (): yup.SchemaOf<
  Pick<WorkspaceSettingsCommunicationStylingModel, 'colorsMain' | 'footer'> & {
    logoUploadRef: string | null;
  }
> => {
  return yup
    .object()
    .shape({
      logoUploadRef: yup.string().nullable().optional(),
      colorsMain: yup
        .string()
        .optionalString()
        .hexColor()
        .default(DEFAULT_STYLING_COLORS_MAIN),
      footer: yup
        .string()
        .optionalString()
        .max(WORKSPACE_SETTINGS_COMMUNICATION_FOOTER_MAX_LENGTH),
    })
    .optional();
};

export const communicationSettingsSchema = (): yup.SchemaOf<
  StrictDeepOmit<
    WorkspaceSettingsCommunicationModel,
    {
      eventBatchWindowInSeconds: never;
      styling: {
        logo: never;
        logoId: never;
      };
    }
  >
> =>
  yup
    .object()
    .required()
    .shape({
      senderName: yup
        .string()
        .optionalString()
        .max(COMMUNICATION_SENDER_NAME_MAX_LENGTH),
      senderUser: yup
        .string()
        .optionalString()
        .oneOf([null, ...communicationSenderUserOptions])
        .nullable(),
      eventTypes: yup
        .array()
        .of(yup.mixed().oneOf(Object.values(CommunicationEventTypeEnum))),
      reminderMaxCount: yup
        .number()
        .min(COMMUNICATION_MIN_REMINDER_MAX_COUNT)
        .max(COMMUNICATION_MAX_REMINDER_MAX_COUNT)
        .default(1),
      reminderPeriodInSeconds: yup
        .number()
        .min(COMMUNICATION_MIN_REMINDER_PERIOD_IN_SECONDS)
        .max(COMMUNICATION_MAX_REMINDER_PERIOD_IN_SECONDS)
        .default(3 * 60 * 60 * 24),
      customDomain: yup.object().shape({
        domainId: yup.string().nullable().defined(),
        domainName: yup.string().nullable().defined(),
        dkimHost: yup.string().nullable().defined(),
        dkimValue: yup.string().nullable().defined(),
        isDkimVerified: yup.boolean().default(false),
        returnPathDomain: yup.string().nullable().defined(),
        returnPathDomainCnameValue: yup.string().nullable().defined(),
        isReturnPathVerified: yup.boolean().default(false),
      }),
      styling: communicationSettingsStylingSchema().default({}),
    })
    .strict();

export const validateWorkspaceSettingsCommunication = (
  communicationSettings: DeepPartial<
    StrictDeepOmit<
      WorkspaceSettingsCommunicationModel,
      {
        styling: {
          logo: never;
          logoId: never;
        };
      }
    >
  > & {
    styling?: {
      logoUploadRef?: string | null;
    };
  } = {},
): void => {
  communicationSettingsSchema().validateSync(communicationSettings, {
    abortEarly: false,
  });
};
