import { useTranslation } from 'react-i18next';

import { Heading } from '@dotfile/frontend/shared/design-system';

import { FormattedTrans } from '../../../../shared';
import { useCurrentStep } from '../context';
import { ProgressBar } from './progress-bar';

export const StepHeader = () => {
  const { t } = useTranslation();
  const step = useCurrentStep();

  return (
    <>
      <ProgressBar />

      <Heading>
        {t(`steps.${step.key}.title`, {
          ns: 'dynamic',
          defaultValue: step.title,
        })}
      </Heading>

      <FormattedTrans
        i18nKey={`steps.${step.key}.description`}
        ns="dynamic"
        defaultValue={step.description}
      />
    </>
  );
};
