import { Fragment } from 'react';
import { match } from 'ts-pattern';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Grid,
  GridItem,
  useIsSmallScreen,
} from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalBlockTypeEnum,
  ClientPortalForms_Block,
  ClientPortalForms_BlockField,
  ClientPortalForms_BlockLayout,
} from '@dotfile/shared/data-access-client-portal';

import { BlockField } from './block-field';
import { BlockLayout } from './block-layout';

type BlocksProps = {
  blocks: ClientPortalForms_Block[];
};

export const Blocks = ({ blocks }: BlocksProps) => {
  const isSmallScreen = useIsSmallScreen();
  const spacing = isSmallScreen ? 2 : 4;

  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      columnGap={spacing}
      rowGap={0} // Cannot have row gap otherwise it also add it in the subgrid of each fields
      mb={-spacing} // Remove spacing of last row
    >
      {blocks.map((block) => (
        <Fragment key={block.id}>
          {match(block.type)
            .with(ClientPortalBlockTypeEnum.field, () => {
              const field = block as ClientPortalForms_BlockField;
              return <BlockField field={field} mb={spacing} />;
            })

            .with(ClientPortalBlockTypeEnum.layout, () => {
              const layout = block as ClientPortalForms_BlockLayout;
              return <BlockLayout layout={layout} mb={spacing} />;
            })

            .otherwise(({ type }) => (
              <Alert as={GridItem} status="warning" colSpan={2}>
                <AlertIcon />
                <AlertTitle mr={2}>
                  Block type {`'`}
                  {type}
                  {`'`} not implemented yet
                </AlertTitle>
              </Alert>
            ))}
        </Fragment>
      ))}
    </Grid>
  );
};
