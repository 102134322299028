import { useTranslation } from 'react-i18next';

import { Button } from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_IdVerificationCheck } from '@dotfile/shared/data-access-client-portal';
import {
  CheckResultEnum,
  getIdentityDataComparisonResult,
} from '@dotfile/shared/domain';

import {
  CheckCard,
  CheckDescription,
  CheckHeading,
  CheckReview,
  PendingStatuses,
} from '../shared';
import { useCheckModalWithQueryParam } from '../shared/hooks/use-modal-with-query-param';
import { DataComparisonAlert } from './components';

export const IdVerificationCheck = ({
  check,
}: {
  check: ClientPortalChecks_IdVerificationCheck;
}) => {
  const { t } = useTranslation();
  const { onOpen } = useCheckModalWithQueryParam(check.id);

  const showDataComparisonAlert =
    getIdentityDataComparisonResult(check.data) === CheckResultEnum.rejected &&
    PendingStatuses.includes(check.status);

  return (
    <CheckCard
      heading={<CheckHeading check={check} />}
      description={<CheckDescription check={check} />}
      action={
        PendingStatuses.includes(check.status) && (
          <Button onClick={onOpen}>
            {t(
              `checks.id_verification.${showDataComparisonAlert ? 'resume' : 'start'}`,
              {
                ns: 'client-portal',
                defaultValue: showDataComparisonAlert
                  ? 'Resume verification'
                  : 'Start verification',
              },
            )}
          </Button>
        )
      }
      review={
        <>
          {showDataComparisonAlert && <DataComparisonAlert />}

          <CheckReview check={check} />
        </>
      }
    />
  );
};
