import { useEffect, useState } from 'react';

import { useAuth } from '@dotfile/frontend/shared/common';
import { useCaseQuery } from '@dotfile/shared/data-access-client-portal';

import { ContactAuthContext } from '../contexts';

export function useCase() {
  const {
    auth: { caseId },
  } = useAuth<ContactAuthContext>();

  const {
    data,
    error,
    loading: caseQueryLoading,
  } = useCaseQuery({
    variables: { id: caseId as string },
    skip: !caseId,
  });

  // Handle loading in a separate state to prevent showing loading state when the query is fetched
  // after app initialization (for instance for first time "Save for later")
  const [isInitialized, setIsInitialized] = useState(!caseId ? true : false);

  useEffect(() => {
    setIsInitialized((prevIsInitialized) =>
      !prevIsInitialized && !caseQueryLoading ? true : prevIsInitialized,
    );
  }, [caseQueryLoading]);

  return { data, error, loading: !isInitialized };
}
