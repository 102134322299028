import { getMinBirthDate, PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

export const birthdateValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.date
> = ({ isRequired, localizedValidation }) => {
  let schema = yup
    .string()
    .nullable()
    .birthdate({
      formatErrorMessage: localizedValidation.birthdate.invalid,
      min: {
        value: getMinBirthDate(),
        errorMessage: localizedValidation.birthdate.min,
      },
      max: {
        value: new Date(),
        errorMessage: localizedValidation.birthdate.max,
      },
    });

  if (isRequired) {
    schema = schema.required(localizedValidation.required);
  }

  return schema.strict();
};
