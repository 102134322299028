import { CheckStatusEnum } from '@dotfile/shared/domain';

export const PendingStatuses: CheckStatusEnum[] = [
  CheckStatusEnum.in_progress,
  CheckStatusEnum.rejected,
  CheckStatusEnum.expired,
];

export const UnderReviewStatuses: CheckStatusEnum[] = [
  CheckStatusEnum.need_review,
  CheckStatusEnum.processing,
];
