import { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  FilesFormValues,
  FormDocumentList,
} from '@dotfile/frontend/shared/components';
import {
  Box,
  FormModal,
  useIsMobile,
} from '@dotfile/frontend/shared/design-system';
import {
  CaseChecksQueryDocument,
  ClientPortalChecks_DocumentCheck,
} from '@dotfile/shared/data-access-client-portal';
import {
  CheckStatusEnum,
  isClientPortalAvailableDefaultLanguage,
  MAX_FILE_PER_DOCUMENT_DATA,
  yup,
} from '@dotfile/shared/domain';

import { useLatestClientPortalVersion } from '../../../shared';
import { CheckDescription, CheckModalHeader } from '../shared';
import { Entity } from '../types';
import { DocumentAnalysisReport } from './components/document-analysis-report';
import { useAddFiles, useForceReviewDocumentCheck } from './hooks';

const defaultValues: FilesFormValues = {
  files: [
    {
      uploadRef: '',
    },
  ],
};

type DocumentModalProps = {
  check: ClientPortalChecks_DocumentCheck;
  entity: Entity;
  isOpen: boolean;
  onClose: () => void;
};

export const DocumentModal = ({
  check,
  entity,
  isOpen,
  onClose,
}: DocumentModalProps) => {
  const { t } = useTranslation();
  const [addFiles] = useAddFiles();
  const isMobile = useIsMobile();
  const [onForceReview, { loading }] = useForceReviewDocumentCheck();
  const apolloClient = useApolloClient();
  const { data } = useLatestClientPortalVersion();

  const defaultLanguage =
    data?.latestClientPortalVersion.clientPortal.defaultLanguage;
  const languages = [
    defaultLanguage,
    ...(data?.latestClientPortalVersion.translations ?? []).map(
      (translation) => translation.language,
    ),
  ].filter(
    (language) =>
      !!language && isClientPortalAvailableDefaultLanguage(language),
  );
  const label = useMemo(
    () =>
      t('checks.document.add_files.label', {
        defaultValue: 'Documents',
        ns: 'client-portal',
      }),
    [t],
  );
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        files: yup
          .array(
            yup.object({
              uploadRef: yup.string().required(({ label }) =>
                t('forms.validation.required', {
                  label,
                  defaultValue: 'This field is required',
                  ns: 'client-portal',
                }),
              ),
            }),
          )
          .optional()
          .min(1)
          .max(MAX_FILE_PER_DOCUMENT_DATA),
      }),
    [t],
  );
  const methods = useForm<FilesFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
    reset,
  } = methods;

  const onSubmit: SubmitHandler<FilesFormValues> = async (formData) => {
    const data = {
      files: formData.files.filter((file) => file.uploadRef),
      languages,
    };

    const status = await addFiles({ id: check.id, data });
    if (status && status !== CheckStatusEnum.in_progress) {
      onClose();
    } else if (!status) {
      // status == null means an error occurred
      // @TODO - https://linear.app/dotfile/issue/E-4697/better-error-handling-in-client-side-mutation
      await apolloClient.refetchQueries({
        include: [CaseChecksQueryDocument],
      });
      onClose();
    } else {
      reset();
    }
  };

  const onCloseCallback = () => {
    onClose();
  };

  const handleCloseCompleted = () => {
    reset(defaultValues);
  };

  if (!data) return null;

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onCloseCallback}
      onCloseComplete={handleCloseCompleted}
      hasCloseButton={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onSubmit={handleSubmit(onSubmit)}
      submitIsDisabled={!isValid || !isDirty}
      header={<CheckModalHeader check={check} />}
      submitText={t('checks.send_for_review', {
        ns: 'client-portal',
        defaultValue: 'Send for review',
      })}
      cancelText={t('common.cancel', {
        ns: 'client-portal',
        defaultValue: 'Cancel',
      })}
      submitIsLoading={isSubmitting}
      textAlign="left"
      size={isMobile ? 'full' : 'lg'}
    >
      <FormProvider {...methods}>
        <Box onSubmit={handleSubmit(onSubmit)} as="form" w="100%">
          <CheckDescription check={check} mb="4" />

          <DocumentAnalysisReport
            loadingSubmit={isSubmitting}
            entity={entity}
            check={check}
            checkData={check.data}
            onClose={onClose}
            onForceReview={onForceReview}
            loadingForceReview={loading}
          />
          <FormDocumentList
            label={label}
            maxItem={MAX_FILE_PER_DOCUMENT_DATA}
          />
        </Box>
      </FormProvider>
    </FormModal>
  );
};
