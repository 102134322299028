import { pick } from 'lodash';
import { match } from 'ts-pattern';

import { PropertyMappingTypeEnum } from '../../../shared/models';
import { ClientPortalFieldMappingTypeEnum } from '../../../shared/models/enum/client-portal-block-property-mapping-type.enum';
import {
  Property,
  PropertyMapping,
  PropertyTypeEnum,
} from '../../../shared/properties/types';
import { ClientPortalProperty } from './type';

const transformPropertyMappingToClientPortalPropertyMapping = (
  mapping: PropertyMapping,
): ClientPortalProperty['mapping'] => {
  return {
    ...mapping,
    type: match(mapping.type)
      .with(
        PropertyMappingTypeEnum.default,
        () => ClientPortalFieldMappingTypeEnum.default,
      )
      .with(
        PropertyMappingTypeEnum.custom,
        () => ClientPortalFieldMappingTypeEnum.custom,
      )
      .otherwise(() => {
        throw new Error(
          'Only custom and default property mapping type are supported for client portal property',
        );
      }),
  };
};

// Transform the picked properties to use ClientPortalFieldMappingTypeEnum
export const transformPropertyToClientPortalProperty = (
  property: Property<PropertyTypeEnum>,
): ClientPortalProperty => ({
  ...property,
  mapping: transformPropertyMappingToClientPortalPropertyMapping(
    property.mapping,
  ),
});

const transformPropertiesToClientPortalProperties = <T extends string>(
  properties: Record<T, Property<PropertyTypeEnum>>,
): Record<T, ClientPortalProperty> => {
  return Object.fromEntries(
    Object.entries(properties).map(([key, value]) => [
      key,
      transformPropertyToClientPortalProperty(
        value as Property<PropertyTypeEnum>,
      ),
    ]),
  ) as Record<T, ClientPortalProperty>;
};

export const createClientPortalPropertiesDefinition = <
  TDefinition extends Record<string, Property<PropertyTypeEnum>>,
  TKeys extends readonly (keyof TDefinition)[],
>(
  propertiesDefinition: TDefinition,
  properties: TKeys,
): Record<TKeys[number], ClientPortalProperty> => {
  return transformPropertiesToClientPortalProperties(
    pick(propertiesDefinition, ...properties),
  ) as Record<TKeys[number], ClientPortalProperty>;
};
