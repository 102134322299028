import {
  LanguageSelect,
  useIsSmallScreen,
} from '@dotfile/frontend/shared/design-system';
import { formatLanguage } from '@dotfile/shared/domain';

import { useLanguageState } from '../contexts';
import { useLatestClientPortalVersion } from '../hooks';

type LanguageSelectorProps = {
  color?: string;
};

export const LanguageSelector = ({ color }: LanguageSelectorProps) => {
  const { data } = useLatestClientPortalVersion();
  const isSmallScreen = useIsSmallScreen();
  const [{ currentLanguage, isLoading }, setLanguage] = useLanguageState();

  if (!data) return null;

  const defaultLanguage =
    data.latestClientPortalVersion.clientPortal.defaultLanguage;
  const languages = [
    defaultLanguage,
    ...data.latestClientPortalVersion.translations.map(
      (translation) => translation.language,
    ),
  ];

  const options = languages.map((language) => ({
    value: language,
    label: formatLanguage(language, { localizedName: true, withCode: false }),
  }));
  const value = options.find((o) => o.value === currentLanguage);

  return (
    <LanguageSelect
      isLoading={isLoading}
      onChange={(v) => {
        if (v) {
          setLanguage(v.value);
        }
      }}
      value={value}
      options={options}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: 'transparent',
        }),
        container: (provided) => ({
          ...provided,
          textAlign: 'right',
        }),
        valueContainer: (provided) => ({
          ...provided,
          color: color ?? 'initial',
          fontSize: isSmallScreen ? 'sm' : 'initial',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          paddingLeft: 0,
        }),
        downChevron: (provided) => ({
          ...provided,
          color: color ?? 'initial',
        }),
        option: (provided) => ({
          ...provided,
          // Prevent overflow / ugly display of long country name
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'inline-box',
          wordBreak: 'break-all',
          textOverflow: 'ellipsis',
        }),
      }}
      menuPlacement="top"
      menuPosition="absolute"
      isSearchable={false}
      isClearable={false}
      variant="ghost"
    />
  );
};
