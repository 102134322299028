import { deepFreeze } from '@dotfile/shared/common';

export const DEFAULT_QUERY_OPERATOR_LABELS = deepFreeze({
  '=': 'Equal to',
  '!=': 'Not equal to',
  '<': 'Less than',
  '<=': 'Less than or equal to',
  '>': 'Greater than',
  '>=': 'Greater than or equal to',
  contains: 'Contains',
  doesNotContain: 'Does not contain',
  beginsWith: 'Begins with',
  doesNotBeginWith: 'Does not begin with',
  endsWith: 'Ends with',
  doesNotEndWith: 'Does not end with',
  null: 'Is empty',
  notNull: 'Is not empty',
  in: 'In',
  notIn: 'Not in',
  between: 'Between',
  notBetween: 'Not between',
  // @NOTE custom operator, needs to be implemented in
  // - libs/shared/domain/src/lib/utils/query/json-logic-react-querybuilder-extended.ts
  // - libs/shared/domain/src/lib/utils/query/json-logic-extended.ts
  // String operator
  doesNotMatchRegexp: 'Does not match regular expression',
  matchRegexp: 'Matches regular expression',
  // Array operator to be able to compare array with array
  arrayContains: 'Contains',
  arrayContainsAll: 'Contains all',
  arrayContainsExactly: 'Contains exactly',
  doesNotArrayContain: 'Does not contain',
  doesNotArrayContainAll: 'Does not contain all',
} as const);

export const QUERY_BUILDER_OPERATORS: QueryBuilderOperator = Object.keys(
  DEFAULT_QUERY_OPERATOR_LABELS,
) as unknown as QueryBuilderOperator; // cast is okay because object is frozen so new keys cannot be added

export type QueryBuilderOperator = keyof typeof DEFAULT_QUERY_OPERATOR_LABELS;

export const isQueryBuilderOperator = (
  maybeOperator: string,
): maybeOperator is QueryBuilderOperator =>
  QUERY_BUILDER_OPERATORS.includes(maybeOperator);
