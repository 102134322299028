import { sortBy } from 'lodash';

import {
  IntegrationConsumptionModel,
  IntegrationConsumptionProductDomainEnum,
  IntegrationConsumptionProductTypeEnum,
  VendorEnum,
} from '../../../shared/models';
import { idVerificationVendorDefinition } from '../../checks';

/**
 * This define all the valid integration consumption from
 * `domain > item > vendor > type`
 * where type is null or an array of the valid types.
 *
 * Based on the billable metric as documented here
 * @see https://www.notion.so/dotfile/Set-up-Lago-Billable-metrics-sync-5d1a8e1defed451fae5e2a19c413c5fe
 */
export const integrationConsumptionDefinition: Record<
  IntegrationConsumptionProductDomainEnum,
  Partial<
    Record<
      IntegrationConsumptionProductTypeEnum,
      Partial<Record<VendorEnum, { items: string[] | null }>>
    >
  >
> = {
  aml: {
    screening: {
      comply_advantage: { items: ['default', 'monitoring_yearly_fee'] },
    },
  },
  company_data: {
    company_search: {
      pappers: { items: ['default'] },
      kompany: { items: ['live', 'indexed'] },
      creditsafe: { items: ['default'] },
      kyckr: { items: ['default'] }, // to double check
      companies_house: { items: ['default'] }, // to double check
      inpi: { items: ['default'] }, // to double check
    },
    company_fetch: {
      pappers: { items: ['default'] },
      kompany: { items: ['refresh_dataset', 'super_dataset', 'index_dataset'] },
      creditsafe: { items: ['tier_0', 'tier_2'] },
      kyckr: {
        items: ['tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_8'],
      },
      companies_house: { items: ['default'] }, // to double check
      inpi: { items: ['default'] }, // to double check
    },
    document_order: {
      pappers: { items: ['tier_0'] },
      kyckr: {
        items: ['tier_0', 'tier_1', 'tier_2', 'tier_3', 'tier_4', 'tier_5'],
      },
    },
  },
  idv: {
    liveness: {
      ubble: { items: idVerificationVendorDefinition.ubble.availableModes },
      checkout: {
        items: idVerificationVendorDefinition.checkout.availableModes,
      },
      veriff: {
        items: idVerificationVendorDefinition.veriff.availableModes,
      },
      onfido: {
        items: idVerificationVendorDefinition.veriff.availableModes,
      },
    },
    document: {
      veriff: { items: null },
      idnow: { items: null },
    },
  },
};

export type CompanySearchProductItem = 'default' | 'live' | 'indexed';

export type CompanyFetchProductItem =
  | 'default'
  | 'refresh_dataset'
  | 'super_dataset'
  | 'index_dataset'
  | 'tier_0'
  | 'tier_1'
  | 'tier_2'
  | 'tier_3'
  | 'tier_4'
  | 'tier_5'
  | 'tier_8';

type AllConsumptions = Pick<
  IntegrationConsumptionModel,
  'productDomain' | 'productItem' | 'vendor' | 'productType'
>[];
export const allValidIntegrationConsumption = (): AllConsumptions => {
  const consumptions = sortBy(
    Object.entries(integrationConsumptionDefinition),
    '0',
  ).flatMap(([productDomain, productTypes]) =>
    sortBy(Object.entries(productTypes), '0').flatMap(
      ([productType, vendors]) =>
        sortBy(Object.entries(vendors), '0').flatMap(
          ([vendor, conf]): {
            vendor: string;
            productDomain: string;
            productType: string;
            productItem: null | string;
          }[] => {
            const type = conf.items;
            if (Array.isArray(type)) {
              return sortBy(type).flatMap((productItem) => ({
                vendor,
                productDomain,
                productItem,
                productType,
              }));
            } else {
              return [
                { vendor, productDomain, productType, productItem: null },
              ];
            }
          },
        ),
    ),
  );

  // As is necessary because Object.entries loose the key typing
  return consumptions as AllConsumptions;
};
