import React, { ErrorInfo } from 'react';

import { logAndAddError } from '@dotfile/frontend/shared/common';

import { NotFound } from './not-found';
import { createReactRenderingError } from './utils';

export type ErrorBoundaryRenderErrorOptions = {
  redirectRoute?: 'Login' | 'Register' | 'ConsoleBase';
  resetError: () => void;
  error: unknown;
};

/**
 * We use this component on top of the `authenticated.routes.tsx`
 * This allows us to catch errors in the same place.
 * https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<{
  redirectRoute?: 'Login' | 'Register' | 'ConsoleBase';
  children: React.ReactNode;
  renderError?: (options: ErrorBoundaryRenderErrorOptions) => React.ReactNode;
}> {
  override state = { error: null };

  public static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  public override componentDidCatch(error: Error, info: ErrorInfo) {
    logAndAddError(createReactRenderingError(error, info));
  }

  resetError = () => this.setState({ error: null });

  public override render() {
    if (this.state.error) {
      if (this.props.renderError) {
        return this.props.renderError({
          resetError: this.resetError,
          redirectRoute: this.props.redirectRoute,
          error: this.state.error,
        });
      } else {
        return (
          <NotFound
            buttonAction={this.resetError}
            redirectRoute={this.props.redirectRoute}
          />
        );
      }
    }

    return this.props.children;
  }
}
