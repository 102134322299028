import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Grid,
  GridItem,
  Input,
  Text,
  useIsMobile,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/domain';

import { FieldProps } from './types';

export const FieldBankingInformation = ({
  field,
  property,

  helper,
  label,
  name,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.banking_information>) => {
  const isMobile = useIsMobile();
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <VStack align="stretch" my="2" width="full" {...wrapperProps}>
      <Text color="black" fontWeight="bold">
        {label}
      </Text>
      {!!helper && <Text>{helper}</Text>}

      <Grid
        templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
        gap={isMobile ? '2' : '4'}
      >
        <GridItem colSpan={isMobile ? 1 : 2}>
          <GroupController
            control={control}
            render={(field) => (
              <Input
                {...field}
                placeholder="XXXX XXXX XXXX XXXX XXXX XXXX XXX"
                type="text"
              />
            )}
            {...groupControlProps}
            name={`${name}.iban`}
            label={t('forms.field.banking_information.iban.label', {
              ns: 'client-portal',
              defaultValue: 'IBAN',
            })}
          />
        </GridItem>
        <GridItem>
          <GroupController
            control={control}
            render={(field) => (
              <Input {...field} placeholder="XXXXXXXXXXX" type="text" />
            )}
            {...groupControlProps}
            name={`${name}.bic`}
            label={t('forms.field.banking_information.bic.label', {
              ns: 'client-portal',
              defaultValue: 'BIC',
            })}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
};
