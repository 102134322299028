import { AuthTokenService } from './token.service';
import { BaseAccessToken } from './types';

export abstract class BaseAuthService<TToken extends BaseAccessToken> {
  protected readonly authToken: AuthTokenService<TToken>;

  constructor(authToken: AuthTokenService<TToken>) {
    this.authToken = authToken;
  }

  public checkAuth(): boolean {
    return this.authToken.getAccessToken() === null ? false : true;
  }

  public async silentRefresh(): Promise<TToken> {
    const token: TToken = await this.authToken.refreshAccessToken();
    return token;
  }

  public getToken(): AuthTokenService<TToken> {
    return this.authToken;
  }
}
