import { Copy } from 'lucide-react';
import React, { Ref, useMemo } from 'react';

import { TextProps } from '@chakra-ui/react';

import { logAndAddError } from '@dotfile/frontend/shared/common';

import { SkeletonText } from '../../feedback/skeleton/skeleton';
import { IconButton } from '../../form/icon-button/icon-button';
import { Box } from '../../layout/box/box';
import { Grid, GridItem, GridProps } from '../../layout/grid/grid';
import { Text } from '../../typography/text';
import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { useCopyToClipBoard } from '../../utils/hooks/use-copy-to-clipboard';

export type LabelledDataProps<T = string> = GridProps & {
  label: React.ReactNode;
  textProps?: TextProps;
  isLoading?: boolean;
  direction?: 'vertical' | 'horizontal';
} & (
    | {
        children: React.ReactNode;
        value?: never;
        format?: never;
        render?: never;
        isCopyable?: never;
        onCopied?: never;
      }
    | {
        /**
         * Will takes precedence of over `value`->`format()`->`render`
         */
        children?: React.ReactNode;

        value: T | null | undefined;

        /**
         * Format the value into a string to be copied or displayed (when no children)
         * @param value
         * @returns
         */
        format?: (value: NonNullable<T>) => string | null;

        /**
         * Render a formatted string value into a React Node.
         *
         * If the formatted value is `null`, it will not call `render()` and display a `'—'` instead
         * @param value
         * @returns
         */
        render?: (value: string) => React.ReactNode;

        /**
         * Add a button to copy the formatted value when there is one
         */
        isCopyable?: boolean;
        onCopied?: () => void;
      }
  );

// Necessary for the generic
// eslint-disable-next-line react/function-component-definition
function _LabelledData<T>(
  {
    label,
    value,
    format,
    render,
    isCopyable,
    children,
    textProps,
    isLoading,
    onCopied,
    direction = 'vertical',
    ...props
  }: LabelledDataProps<T>,
  // Since _LabelledData is a generic, we cannot simply wrap
  // it with forwardRef otherwise the generic is lost
  // It is cast below
  // @see https://stackoverflow.com/a/58473012
  ref: Ref<HTMLDivElement>,
): JSX.Element {
  const formattedValue: string | undefined | null = useMemo(() => {
    if (value === undefined || value === null || value === '') {
      return null;
    }

    if (format) {
      const formatted = format(value);
      return formatted === undefined || formatted === null || formatted === ''
        ? null
        : formatted;
    }

    if (typeof value === 'string') {
      return value;
    }

    logAndAddError(
      new Error(
        `Need a \`format\` props if \`value\` is not a string (was ${typeof value}) on <LabelledData /> with \`label\` '${label}'`,
      ),
      { label, value },
    );
    // fallback stringify for value
    return `${value}`;
  }, [format, value, label]);

  const hasCopyableValue =
    value &&
    formattedValue &&
    // some formatter could chose to return the — directly when there is no value
    formattedValue !== '—';
  const copyToClipBoard = useCopyToClipBoard({
    onCopied,
  });

  return (
    <Grid
      gap="1"
      templateColumns={direction === 'horizontal' ? 'repeat(2, 1fr)' : '1fr'}
      {...props}
      ref={ref}
    >
      <GridItem>
        <Text {...textProps} color="gray.500">
          {label}
        </Text>
      </GridItem>

      <GridItem display="inline-flex">
        {isLoading ? (
          <SkeletonText height="14px" noOfLines={1} width="52" />
        ) : (
          <Text
            as={children ? 'div' : 'p'}
            color="black"
            sx={
              children
                ? undefined
                : {
                    lineBreak: 'anywhere', // make sure very long value don't overflow
                  }
            }
            {...textProps}
            maxWidth="100%"
            flex="0 0 auto"
          >
            {children ? (
              children
            ) : formattedValue ? (
              (render?.(formattedValue) ?? formattedValue)
            ) : (
              <Text {...textProps} color="gray.500" as="span">
                —
              </Text>
            )}
          </Text>
        )}

        {hasCopyableValue && isCopyable ? (
          <Box
            // the relative/absolute `position` with the `flex` make it so that
            // the copy icon is right to the text when there is enough room for
            // it or above the content otherwise but never trigger a reflow of
            // the text
            position="relative"
            flex="0 1 27px"
          >
            <IconButton
              variant="ghost"
              backgroundColor="white"
              aria-label="copy"
              icon={<Copy size="16px" />}
              onClick={() => copyToClipBoard(formattedValue ?? '')}
              size="xs"
              position="absolute"
              right="0px"
            />
          </Box>
        ) : null}
      </GridItem>
    </Grid>
  );
}

export const LabelledData = forwardRefWithGeneric(_LabelledData);
