import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationIndividualCompany = (): JSX.Element => {
  const { stroke, background, skeleton, blue700 } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="0 0 508 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M433 1C433 41.8691 400.317 75 360 75L360 1L433 1Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C441.186 75 507 140.814 507 222L360 222L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M63 75C63 34.1309 95.6832 1 136 1L136 75L63 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M286 75C286 156.186 218.843 222 136 222L136 75L286 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C360 34.1309 326.869 0.999999 286 0.999997L286 75L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
      </g>
      <g filter="url(#filter0_ddd_1148_44015)">
        <rect x="188" y="44" width="256" height="102" rx="4" fill="white" />
        <circle cx="216" cy="65" r="12" fill={background} />
        <mask
          id="mask0_1148_44015"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="204"
          y="53"
          width="24"
          height="24"
        >
          <circle cx="216" cy="65" r="12" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_1148_44015)">
          <ellipse
            cx="215.999"
            cy="74.1203"
            rx="9.12"
            ry="5.28"
            fill={blue700}
          />
        </g>
        <circle cx="216.001" cy="63.2004" r="4.32" fill={blue700} />
        <rect x="244" y="61" width="100" height="8" rx="4" fill={skeleton} />
        <rect x="244" y="85" width="126" height="4" rx="2" fill={skeleton} />
        <rect x="244" y="105" width="74" height="4" rx="2" fill={skeleton} />
        <rect x="244" y="125" width="126" height="4" rx="2" fill={skeleton} />
      </g>
      <g filter="url(#filter1_ddd_1148_44015)">
        <rect x="220" y="77" width="256" height="102" rx="4" fill="white" />
        <rect x="236" y="86" width="32" height="32" rx="16" fill={background} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M248 95.9998C247.632 95.9998 247.334 96.2983 247.334 96.6665V107.333C247.334 107.701 247.632 108 248 108H256C256.369 108 256.667 107.701 256.667 107.333V96.6665C256.667 96.2983 256.369 95.9998 256 95.9998H248ZM246 96.6665C246 95.5619 246.896 94.6665 248 94.6665H256C257.105 94.6665 258 95.5619 258 96.6665V107.333C258 108.438 257.105 109.333 256 109.333H248C246.896 109.333 246 108.438 246 107.333V96.6665Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M249.333 106C249.333 105.631 249.632 105.333 250 105.333H254C254.368 105.333 254.667 105.631 254.667 106V108.666C254.667 109.035 254.368 109.333 254 109.333C253.632 109.333 253.333 109.035 253.333 108.666V106.666H250.667V108.666C250.667 109.035 250.368 109.333 250 109.333C249.632 109.333 249.333 109.035 249.333 108.666V106Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M248.667 97.9997C248.667 97.6315 248.965 97.333 249.334 97.333H249.34C249.709 97.333 250.007 97.6315 250.007 97.9997C250.007 98.3679 249.709 98.6663 249.34 98.6663H249.334C248.965 98.6663 248.667 98.3679 248.667 97.9997Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M254 97.9997C254 97.6315 254.299 97.333 254.667 97.333H254.674C255.042 97.333 255.34 97.6315 255.34 97.9997C255.34 98.3679 255.042 98.6663 254.674 98.6663H254.667C254.299 98.6663 254 98.3679 254 97.9997Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M251.333 97.9997C251.333 97.6315 251.632 97.333 252 97.333H252.007C252.375 97.333 252.673 97.6315 252.673 97.9997C252.673 98.3679 252.375 98.6663 252.007 98.6663H252C251.632 98.6663 251.333 98.3679 251.333 97.9997Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M251.333 100.667C251.333 100.298 251.632 100 252 100H252.007C252.375 100 252.673 100.298 252.673 100.667C252.673 101.035 252.375 101.333 252.007 101.333H252C251.632 101.333 251.333 101.035 251.333 100.667Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M251.333 103.333C251.333 102.965 251.632 102.667 252 102.667H252.007C252.375 102.667 252.673 102.965 252.673 103.333C252.673 103.701 252.375 104 252.007 104H252C251.632 104 251.333 103.701 251.333 103.333Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M254 100.667C254 100.298 254.299 100 254.667 100H254.674C255.042 100 255.34 100.298 255.34 100.667C255.34 101.035 255.042 101.333 254.674 101.333H254.667C254.299 101.333 254 101.035 254 100.667Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M254 103.333C254 102.965 254.299 102.667 254.667 102.667H254.674C255.042 102.667 255.34 102.965 255.34 103.333C255.34 103.701 255.042 104 254.674 104H254.667C254.299 104 254 103.701 254 103.333Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M248.667 100.667C248.667 100.298 248.965 100 249.334 100H249.34C249.709 100 250.007 100.298 250.007 100.667C250.007 101.035 249.709 101.333 249.34 101.333H249.334C248.965 101.333 248.667 101.035 248.667 100.667Z"
          fill={blue700}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M248.667 103.333C248.667 102.965 248.965 102.667 249.334 102.667H249.34C249.709 102.667 250.007 102.965 250.007 103.333C250.007 103.701 249.709 104 249.34 104H249.334C248.965 104 248.667 103.701 248.667 103.333Z"
          fill={blue700}
        />
        <rect x="284" y="94" width="100" height="8" rx="4" fill={skeleton} />
        <rect x="284" y="118" width="126" height="4" rx="2" fill={skeleton} />
        <rect x="284" y="138" width="74" height="4" rx="2" fill={skeleton} />
        <rect x="284" y="158" width="126" height="4" rx="2" fill={skeleton} />
      </g>
      <defs>
        <filter
          id="filter0_ddd_1148_44015"
          x="0.399979"
          y="-97.4"
          width="631.2"
          height="477.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1148_44015"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1148_44015"
            result="effect2_dropShadow_1148_44015"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1148_44015"
            result="effect3_dropShadow_1148_44015"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1148_44015"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_1148_44015"
          x="32.4"
          y="-64.4"
          width="631.2"
          height="477.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1148_44015"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1148_44015"
            result="effect2_dropShadow_1148_44015"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1148_44015"
            result="effect3_dropShadow_1148_44015"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1148_44015"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
