import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  AddFilesIdDocumentCheckInput,
  useAddFilesIdDocumentCheckMutation,
} from '@dotfile/shared/data-access-client-portal';
import { CheckStatusEnum } from '@dotfile/shared/domain';

export const useAddFiles = () => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const [runMutation, result] = useAddFilesIdDocumentCheckMutation();

  const addFile = useCallback(
    async (
      input: AddFilesIdDocumentCheckInput,
    ): Promise<CheckStatusEnum | null> => {
      try {
        const { data } = await runMutation({
          variables: {
            input,
          },
        });

        return data?.addFilesIdDocumentCheck.status ?? null;
      } catch (error) {
        handleError({
          title: t('checks.id_document.add_files.error', {
            defaultValue: 'Unable to add the file',
            count: input.data.backUploadRef ? 2 : 1,
            ns: 'client-portal',
          }),
          error,
        });
        return null;
      }
    },
    [runMutation, handleError, t],
  );

  return [addFile, result] as const;
};
