import { set } from 'lodash';

import {
  CHECK_ANALYSIS_RESULT_KEY_SEPARATOR,
  CheckResultEnum,
} from '@dotfile/shared/domain';

import {
  AnalysisResult,
  AnalysisResultGroup,
  AnalysisResultGroups,
} from '../types';

/**
 * Intermediate object to build nested array groups
 */
type NestedRecord = {
  [key: string]: NestedRecord | AnalysisResult;
};

export const isAnalysisResultElement = (
  maybeAnalysisResultElement: AnalysisResultGroup,
): maybeAnalysisResultElement is AnalysisResultGroup<false> => {
  return 'analysisResult' in maybeAnalysisResultElement;
};
export const isAnalysisResultGroup = (
  maybeAnalysisResultGroup: AnalysisResultGroup,
): maybeAnalysisResultGroup is AnalysisResultGroup<true> => {
  return 'groupResult' in maybeAnalysisResultGroup;
};

/**
 * Recursively computing groupResult
 */
const recurseComputeAnalysisResultGroupResult = (
  results: AnalysisResultGroups,
  currentResult: CheckResultEnum = CheckResultEnum.approved,
): CheckResultEnum => {
  return results.reduce((acc, analysisResultGroup) => {
    // No need to compute deeper if rejected
    if (acc === CheckResultEnum.rejected) {
      return acc;
    }

    if (isAnalysisResultElement(analysisResultGroup)) {
      if (
        analysisResultGroup.analysisResult.result === CheckResultEnum.rejected
      ) {
        return CheckResultEnum.rejected;
      }

      if (analysisResultGroup.analysisResult.result === CheckResultEnum.error) {
        return CheckResultEnum.error;
      }
    } else if (isAnalysisResultGroup(analysisResultGroup)) {
      return recurseComputeAnalysisResultGroupResult(
        analysisResultGroup.results,
        acc,
      );
    }

    return acc;
  }, currentResult);
};

/**
 * Rejected, then error, then approved
 */
const positionPerResult: Record<CheckResultEnum, number> = {
  [CheckResultEnum.rejected]: 0,
  [CheckResultEnum.error]: 1,
  [CheckResultEnum.approved]: 2,
} as const;
/**
 * Sort by [result ASC, key ASC]
 */
const sortAnalysisResultGroup = (results: AnalysisResultGroups) => {
  return results.sort((resultA, resultB) => {
    const { keyA, checkResultA } = isAnalysisResultGroup(resultA)
      ? {
          checkResultA: resultA.groupResult,
          keyA: resultA.key,
        }
      : isAnalysisResultElement(resultA)
        ? {
            checkResultA: resultA.analysisResult.result,
            keyA: resultA.analysisResult.key,
          }
        : { keyA: '' };
    const { keyB, checkResultB } = isAnalysisResultGroup(resultB)
      ? {
          checkResultB: resultB.groupResult,
          keyB: resultB.key,
        }
      : isAnalysisResultElement(resultB)
        ? {
            checkResultB: resultB.analysisResult.result,
            keyB: resultB.analysisResult.key,
          }
        : { keyB: '' };

    const positionA = checkResultA ? positionPerResult[checkResultA] : 0;
    const positionB = checkResultB ? positionPerResult[checkResultB] : 0;

    if (positionA !== positionB) {
      return positionA > positionB ? 1 : -1;
    }

    return keyA > keyB ? 1 : -1;
  });
};

const isAnalysisResult = (
  maybeAnalysisResult: object,
): maybeAnalysisResult is AnalysisResult => {
  return ['key', 'result'].every((key) => key in maybeAnalysisResult);
};
/**
 * Recursively sort and build a nested array from the original nested record
 */
const recurseBuildAnalysisResultGroupFromNestedRecord = (
  record: NestedRecord,
) => {
  return sortAnalysisResultGroup(
    Object.entries(record).reduce<AnalysisResultGroups>(
      (acc, [prefix, result]): AnalysisResultGroups => {
        if (isAnalysisResult(result)) {
          const analysisResult = {
            analysisResult: result,
          } as AnalysisResultGroup<false>;

          return [...acc, analysisResult];
        } else {
          const results = recurseBuildAnalysisResultGroupFromNestedRecord(
            result as NestedRecord,
          );
          // If only one result in group, ignore grouping and display as is
          if (
            results.length === 1 &&
            'analysisResult' in results[0] &&
            isAnalysisResult(results[0].analysisResult)
          ) {
            return [...acc, { analysisResult: results[0].analysisResult }];
          }

          const groupResult = recurseComputeAnalysisResultGroupResult(results);

          const analysisResultGroup = {
            key: prefix,
            groupResult,
            results,
          } as AnalysisResultGroup<true>;

          return [...acc, analysisResultGroup];
        }
      },
      [] as AnalysisResultGroups,
    ),
  );
};

/**
 * Build analysisResultGroup recursively, sorted by result and key.
 * Will compute an intermediate groupResult: CheckResultEnum for group
 * @NOTE First map the analysis result to a nested object, then to a
 * react "render-able" nested array
 * Cant' use lodash groupBy, it's not supporting the recursion
 */
export const buildAnalysisResultsGroup = (
  results: AnalysisResult[],
  {
    excludeKeys = [],
  }: {
    excludeKeys?: string[];
  } = {},
) => {
  const record: NestedRecord = {};
  results.forEach((result) => {
    if (!excludeKeys.includes(result.key)) {
      const parts = result.key.split(CHECK_ANALYSIS_RESULT_KEY_SEPARATOR);
      set(record, parts.join('.'), result);
    }
  });

  const analysisResultsGroups =
    recurseBuildAnalysisResultGroupFromNestedRecord(record);

  return analysisResultsGroups;
};
