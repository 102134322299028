import {
  ClientPortalChecks_CheckInterface,
  ClientPortalChecks_DocumentCheck,
} from '@dotfile/shared/data-access-client-portal';
import { CheckTypeEnum } from '@dotfile/shared/domain';

export const getCheckTranslationKey = (
  check: ClientPortalChecks_CheckInterface,
) => {
  const checkType = check.type;
  const keyParts: string[] = [checkType];
  if (check.type === CheckTypeEnum.document) {
    const { documentType: _documentType, customDocumentType } = (
      check as ClientPortalChecks_DocumentCheck
    ).data.settings;

    const documentType = customDocumentType
      ? customDocumentType.key
      : (_documentType as string);

    if (documentType) {
      keyParts.push(documentType);
    }
  }
  const key = keyParts.join('__');

  return `checks.${key}`;
};
