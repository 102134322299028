import { validate as isUuid } from 'uuid';

import { logAndAddError } from '@dotfile/frontend/shared/common';

import { extractMaybeClientPortalId } from '../helpers';

export const onRefreshAuthFailed = (authError: unknown) => {
  const match = window.location.pathname.match(/^\/([^/]+)/);
  const basePath =
    match && isUuid(match[1])
      ? `/${match[1]}`
      : // no uuid in path for custom domain
        '/';

  if (window.location.pathname === basePath) {
    // ignore auth refresh errors when already login route
    return;
  }

  // clear localStorage to remove any data since authentication failed with it
  localStorage.clear();

  logAndAddError(authError);

  const maybeClientPortalId = extractMaybeClientPortalId(
    window.location.pathname,
  );

  const redirectLocation = window.location
    .toString()
    .replace(
      window.location.pathname,
      maybeClientPortalId ? `/${maybeClientPortalId}` : '',
    );

  window.location.replace(redirectLocation);
};
