import { useLayoutEffect } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { CaseRelationRoleEnum } from '@dotfile/shared/domain';

import { useFormDatastore } from '../shared';
import { FormValues } from './use-business-contact-step-form';

/**
 * Those effect cannot be in sub-component because sometime, the sub-component is not rendered but the effect must trigger
 *
 * For instance when the business contact is legal representative and selecting "Yes" on "Are you the authorized signatory of the company?"
 * it should reset the delegator but the Signatory select is not displayed
 *
 * @param watch
 * @param setValue
 */
export const useBusinessContactStepEffects = (
  watch: UseFormWatch<FormValues>,
  setValue: UseFormSetValue<FormValues>,
) => {
  const individuals = useFormDatastore((state) => state.data.individuals);

  const delegatorIndex = watch('delegatorIndex');
  const signatoryIndex = watch('signatoryIndex');

  // When Signatory change update isSignatoryLegalRepresentative accordingly
  useLayoutEffect(
    // layout effect to avoid a glitch where the alert could be displayed before a render with
    // the updated value of isSignatoryLegalRepresentative
    () => {
      if (individuals && signatoryIndex) {
        const { roles } = individuals[Number(signatoryIndex)];
        setValue(
          'isSignatoryLegalRepresentative',
          roles?.includes(CaseRelationRoleEnum.legal_representative) ?? false,
        );
      }
    },
    [signatoryIndex, setValue, individuals],
  );

  // EdgeCase: If the signatory is the same has the delegator, reset the delegator
  useLayoutEffect(() => {
    if (signatoryIndex === delegatorIndex) {
      setValue('delegatorIndex', '', { shouldValidate: true });
    }
  }, [signatoryIndex, delegatorIndex, setValue]);
};
