import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Radio,
  RadioGroup,
  Select,
  SelectOnChangeValue,
  useDisclosure,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalBlockFieldDisplayEnum,
  ClientPortalFieldMappingTypeEnum,
  ClientPortalForms_BlockField,
  PropertyTypeEnum,
} from '@dotfile/shared/data-access-client-portal';

import { FormattedTrans } from '../../../../../../shared';
import { useCurrentStep } from '../../../context';
import { FieldProps } from './types';

type ChoicesOption = {
  value: string;
  label: string;
  context: string | null;
};

const OptionContextDrawer = ({
  option,
  field,
}: {
  option: ChoicesOption;
  field: Pick<ClientPortalForms_BlockField, 'key'>;
}) => {
  const { t } = useTranslation();
  const disclosure = useDisclosure();
  const step = useCurrentStep();

  const i18nKey = `steps.${step.key}.blocks.${field.key}.options_contexts.${option.value}`;

  return (
    <>
      <Button
        variant="link"
        onClick={(e) => {
          e.stopPropagation();
          disclosure.onOpen();
        }}
      >
        {t('forms.field.choices.more_info', {
          ns: 'client-portal',
          defaultValue: 'More info',
        })}
      </Button>
      <Drawer
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        size="sm"
        isFullHeight
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            backgroundColor="unset"
            color="black"
            borderBottomWidth="1px"
          >
            {t('forms.field.choices.more_info', {
              ns: 'client-portal',
              defaultValue: 'More info',
            })}
          </DrawerHeader>
          <DrawerBody px={4}>
            <FormattedTrans
              ns="dynamic"
              i18nKey={i18nKey}
              defaultValue={option.context}
            />
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={disclosure.onClose}>
              {t('common.close', {
                ns: 'client-portal',
                defaultValue: 'Close',
              })}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const FieldChoices = ({
  field,
  property,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.choices>) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const step = useCurrentStep();

  const options = useMemo(
    () =>
      property.settings?.options?.map((option): ChoicesOption => {
        const fieldOption = field.options.find(
          (fieldOption) => fieldOption.key === option.key,
        );

        return {
          value: option.key,
          context: fieldOption?.context ?? null,
          label: t(
            `steps.${step.key}.blocks.${field.key}.options.${option.key}`,
            {
              defaultValue: fieldOption?.label ?? option.label,
              ns: 'dynamic',
            },
          ),
        };
      }) ?? [],
    [field.key, field.options, property.settings?.options, step.key, t],
  );
  const isMulti = property.settings?.allowMultiple ?? false;
  const shouldValueBeAnArray =
    isMulti || field.mapping.type === ClientPortalFieldMappingTypeEnum.custom;

  const handleChange = useCallback(
    (option: SelectOnChangeValue) => {
      const newValues =
        option === null || (Array.isArray(option) && option.length === 0)
          ? isMulti
            ? // For default properties, currently there is only the individual roles which cannot be null
              // For custom properties, both null and empty array works and won't create any options
              []
            : null
          : shouldValueBeAnArray
            ? (Array.isArray(option) ? option : [option]).map((o) => o.value)
            : (Array.isArray(option) ? option[0] : option).value;
      setValue(groupControlProps.name, newValues, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    },
    [groupControlProps.name, setValue, isMulti, shouldValueBeAnArray],
  );

  if (options.length === 0) {
    // Should be caught in CustomProperty validation: it should not be possible
    // to have a CustomProperty of type choices without options
    return (
      <Alert status="error" {...wrapperProps}>
        <AlertIcon />
        <AlertTitle mr={2}>
          Error: Field {`'`}${groupControlProps.name}
          {`'`} does not have options
        </AlertTitle>
      </Alert>
    );
  }

  return (
    <GroupController
      control={control}
      render={({ value, ...f }) => {
        if (field.display === ClientPortalBlockFieldDisplayEnum.dropdown)
          return (
            <Select
              {...f}
              options={options}
              onChange={handleChange}
              isMulti={isMulti}
              placeholder={t('forms.field.choices.placeholder', {
                ns: 'client-portal',
                defaultValue: 'Select ...',
              })}
              isSearchable={options.length > 5}
              defaultValue={
                shouldValueBeAnArray
                  ? options.filter((option) =>
                      (value ?? []).includes(option.value),
                    )
                  : options.find((option) => option.value === value)
              }
              required={false}
              value={undefined}
              isClearable
            />
          );

        if (isMulti) {
          return (
            <CheckboxGroup value={value}>
              <VStack alignItems="start" gap={1}>
                {options.map((option) => (
                  <Checkbox
                    key={option.value}
                    value={option.value}
                    variant="atTop"
                    onChange={(e) => {
                      // For some reason, the onChange prop is not working on
                      // the CheckboxGroup. So we have to handle it here.
                      const currentValue: string[] = value ?? [];
                      const checked = e.target.checked;
                      const nextValue = checked
                        ? [...currentValue, option.value]
                        : currentValue.filter((v) => v !== option.value);
                      f.onChange(nextValue);
                    }}
                  >
                    {option.label}{' '}
                    {option.context && (
                      <OptionContextDrawer field={field} option={option} />
                    )}
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>
          );
        }

        return (
          <RadioGroup
            value={Array.isArray(value) ? value[0] : value}
            onChange={(value) => {
              f.onChange(shouldValueBeAnArray ? [value] : value);
            }}
          >
            <VStack alignItems="start" gap={1}>
              {options.map((option) => (
                <Radio key={option.value} value={option.value} variant="atTop">
                  {option.label}{' '}
                  {option.context && (
                    <OptionContextDrawer field={field} option={option} />
                  )}
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        );
      }}
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
