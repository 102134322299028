import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ElementOf } from 'ts-essentials';

import { GroupController } from '@dotfile/frontend/shared/components';
import { SelectMenu, Text } from '@dotfile/frontend/shared/design-system';

import { FormDatastoreIndividual, useFormDatastore } from '../shared';
import { IndividualItem } from './individual-item';
import { FormValues } from './use-business-contact-step-form';

const NEW_INDIVIDUAL = 'NEW_INDIVIDUAL';

export const SignatorySelectController = ({
  isSignatoryLocked,
  onAddNew,
}: {
  isSignatoryLocked?: boolean;
  onAddNew: () => void;
}) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext<FormValues>();

  const businessContactIndex = watch('businessContactIndex');

  const individuals = useFormDatastore((state) => state.data.individuals);
  const individualOptions: {
    value: string;
    individual: Partial<FormDatastoreIndividual> | null;
  }[] = useMemo(
    () => [
      ...(
        individuals?.map((individual, index) => ({
          value: index.toString(),
          individual,
        })) || []
      ).filter(
        ({ individual }, index) =>
          // Cannot be the business contact since this field is only displayed when
          // the business contact selected the option to indicate that they are not
          // the authorized signatory
          index.toString() !== businessContactIndex,
      ),
      {
        value: NEW_INDIVIDUAL,
        individual: null,
      },
    ],
    [individuals, businessContactIndex],
  );

  const onSelectSignatory = useCallback(
    ({ value }: { value: string }) => {
      if (value === NEW_INDIVIDUAL) {
        // Open drawer to add new Individual as Signatory
        onAddNew();
      } else {
        setValue('signatoryIndex', value, { shouldValidate: true });
      }
    },
    [onAddNew, setValue],
  );

  return (
    <GroupController
      isRequired
      name="signatoryIndex"
      label={t(`forms.business_contact.signatory.label`, {
        ns: 'client-portal',
        defaultValue: 'Who is the authorized signatory?',
      })}
      control={control}
      render={(field) => (
        <SelectMenu
          {...field}
          buttonProps={{
            w: '100%',
            isDisabled: isSignatoryLocked,
          }}
          variant="select"
          onChange={onSelectSignatory}
          options={individualOptions}
          // @NOTE: option require to explicitly define typing, inferred will resolve to {value: Type} from SelectMenu
          renderOption={(option: ElementOf<typeof individualOptions>) => (
            <IndividualItem
              individual={option.individual}
              addLabel={t(`forms.business_contact.signatory.add.option`, {
                ns: 'client-portal',
                defaultValue: 'Add new individual',
              })}
            />
          )}
        >
          {field.value && individuals ? (
            <IndividualItem individual={individuals[Number(field.value)]} />
          ) : (
            <Text align="start">
              {t(`forms.business_contact.signatory.placeholder`, {
                ns: 'client-portal',
                defaultValue: 'Select ...', // Same as other <Select /> but this one is a <SelectMenu /> so the placeholder has to be set here
              })}
            </Text>
          )}
        </SelectMenu>
      )}
    />
  );
};
