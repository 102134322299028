import { useTranslation } from 'react-i18next';

import { HStack, Text } from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_CheckInterface } from '@dotfile/shared/data-access-client-portal';

import { formatCheckTitle, getCheckTranslationKey } from '../utils';
import { CheckStatusBadge } from './check-status-badge';

export const CheckModalHeader = ({
  check,
}: {
  check: ClientPortalChecks_CheckInterface;
}) => {
  const { t } = useTranslation();
  const checkLabelTranslationKey = getCheckTranslationKey(check);

  return (
    <HStack justifyContent="space-between">
      <Text color="black" fontSize="lg">
        {t(`${checkLabelTranslationKey}.label`, {
          ns: 'dynamic',
          defaultValue: formatCheckTitle(check, t),
        })}
      </Text>
      <CheckStatusBadge check={check} />
    </HStack>
  );
};
