import { ClientPortalBlock } from '@dotfile/shared/data-access-client-portal';

import { FormattedTrans } from '../../../../../../shared';
import { useCurrentStep } from '../../../context';

type TransBlockDescriptionProps = {
  block: Pick<ClientPortalBlock, 'key' | 'description'>;
};

export const TransBlockDescription = ({
  block,
}: TransBlockDescriptionProps) => {
  const step = useCurrentStep();

  return (
    <FormattedTrans
      i18nKey={`steps.${step.key}.blocks.${block.key}.description`}
      defaultValue={block.description}
      ns="dynamic"
    />
  );
};
