import { AnalysisResultCategoryEnum } from '../../../../shared/models';

export const IDnowControlTreeCategory: Record<
  string,
  AnalysisResultCategoryEnum
> = {
  DOC_EXPIRATION_DATE: AnalysisResultCategoryEnum.document_expiration,
  DOC_SPECIMEN: AnalysisResultCategoryEnum.fraud,
  DOC_BLACKLISTED: AnalysisResultCategoryEnum.image_integrity,
  EMISSION_COUNTRY: AnalysisResultCategoryEnum.document_acceptability,
  EMISSION_DATE: AnalysisResultCategoryEnum.document_acceptability,
  DOC_NATIONALITY: AnalysisResultCategoryEnum.document_acceptability,
  VALIDITY_PERIOD: AnalysisResultCategoryEnum.document_expiration,
  MRZ_FIELDS_SYNTAX: AnalysisResultCategoryEnum.fraud,
  MRZ_CHECKSUMS: AnalysisResultCategoryEnum.fraud,
  MRZ_EXPECTED_FOUND: AnalysisResultCategoryEnum.fraud,
  OCR_FIRSTNAMES: AnalysisResultCategoryEnum.fraud,
  OCR_LASTNAME: AnalysisResultCategoryEnum.fraud,
  OCR_BIRTHDATE: AnalysisResultCategoryEnum.fraud,
  OCR_DOCNUM: AnalysisResultCategoryEnum.fraud,
  OCR_EXPIRATIONDATE: AnalysisResultCategoryEnum.fraud,
  OCR_EMISSIONDATE: AnalysisResultCategoryEnum.fraud,
  OCR_PERSONALNUM: AnalysisResultCategoryEnum.fraud,
  MRZ_ALIGNEMENT: AnalysisResultCategoryEnum.fraud,
  MRZ_CLASSIFIER: AnalysisResultCategoryEnum.fraud,
  PHOTO_CONFORMITY: AnalysisResultCategoryEnum.fraud,
  PHOTO_LOCATION: AnalysisResultCategoryEnum.fraud,
  VISUAL_SECURITY: AnalysisResultCategoryEnum.fraud,
  BARCODE_EXPECTED_FOUND: AnalysisResultCategoryEnum.fraud,
  BARCODE_FIELDS_SYNTAX: AnalysisResultCategoryEnum.fraud,
  BARCODE_SIGNATURE: AnalysisResultCategoryEnum.fraud,
  PHOTOCOPY_DETECTION: AnalysisResultCategoryEnum.image_integrity,
  SCREEN_DETECTION: AnalysisResultCategoryEnum.image_integrity,
  INVALIDATED_DOCUMENT: AnalysisResultCategoryEnum.document_integrity,
  FIRST_NAME_MATCH: AnalysisResultCategoryEnum.data_comparison,
  LAST_NAME_MATCH: AnalysisResultCategoryEnum.data_comparison,
  BIRTH_DATE_MATCH: AnalysisResultCategoryEnum.data_comparison,
  GENDER_MATCH: AnalysisResultCategoryEnum.data_comparison,
  BIRTH_PLACE_MATCH: AnalysisResultCategoryEnum.data_comparison,
  FULL_NAME_MATCH: AnalysisResultCategoryEnum.data_comparison,
  SIDES_MODEL_MATCHING: AnalysisResultCategoryEnum.fraud,
  SIDES_DATA_MATCHING: AnalysisResultCategoryEnum.fraud,
  ALLCORNERSVISIBLE: AnalysisResultCategoryEnum.image_integrity,
};

/**
 * Ignore some group to limit depths in display
 */
export const IDnowControlTreeIgnoredGroupIdentifier: (keyof typeof IDnowControlTreeCategory)[] =
  ['ID_ANALYSIS'];

/**
 * Ignore some identifier that are not relevant for us
 */
export const IDnowControlTreeIgnoredIdentifier: (keyof typeof IDnowControlTreeCategory)[] =
  [
    'BACKEND_ANALYSIS',
    'MODEL_RECOGNIZED',
    // We run our own data comparison
    'FIRST_NAME_MATCH',
    'LAST_NAME_MATCH',
    'BIRTH_DATE_MATCH',
  ];
