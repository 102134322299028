import { useEffect, useRef } from 'react';

import {
  Box,
  BoxProps,
  forwardRef,
  Progress,
  useStyleConfig,
} from '@chakra-ui/react';

import { Spinner } from '../../feedback/spinner/spinner';

export type DropZonePropsType = 'logo' | 'file' | 'image';

export type DropzoneProps = {
  type: DropZonePropsType;
  children: React.ReactNode;
  uploadProgress?: number;
  isFocused?: boolean;
} & BoxProps;

export const Dropzone = forwardRef<DropzoneProps, typeof Box>(
  ({ children, uploadProgress, isFocused, type, ...rest }, ref) => {
    const dropzoneRef = useRef<HTMLHeadingElement>(null);
    const styles = useStyleConfig('Dropzone');

    useEffect(() => {
      if (isFocused === false || !dropzoneRef.current) return;
      dropzoneRef.current.focus();
    }, [isFocused, ref]);

    return typeof uploadProgress === 'number' && type === 'file' ? (
      <Box
        h={rest?.h}
        display="flex"
        __css={styles}
        bg="white"
        cursor="not-allowed"
        justifyContent="center"
        alignItems="center"
        data-testid="progress-bar"
      >
        <Progress value={uploadProgress} h="20%" w="80%" hasStripe />
      </Box>
    ) : (
      <Box __css={styles} {...rest} ref={dropzoneRef}>
        {uploadProgress ? <Spinner size="xs" /> : children}
      </Box>
    );
  },
);
