import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { nameFormat } from '@dotfile/shared/common';
import {
  DocumentAnalysisDetailedResultModel,
  DocumentAnalysisEntity,
} from '@dotfile/shared/domain';

import { DataComparisonLabel } from './data-comparison-label';

export const ValidDocumentType = ({
  definition,
  detailedResult,
  entity,
}: {
  definition: {
    information: { documentType: { label: (t: TFunction) => string } };
    documentTypes: Record<string, { label: (t: TFunction) => string }>;
  };

  detailedResult: DocumentAnalysisDetailedResultModel;
  entity: DocumentAnalysisEntity;
}) => {
  const { t } = useTranslation();

  const documentTypeExpectedDefinition =
    detailedResult.expected &&
    detailedResult.expected in definition.documentTypes &&
    definition.documentTypes[detailedResult.expected];

  const expected =
    documentTypeExpectedDefinition && documentTypeExpectedDefinition.label(t);

  const documentTypeReceivedDefinition =
    detailedResult.received &&
    detailedResult.received in definition.documentTypes &&
    definition.documentTypes[detailedResult.received];

  const received =
    documentTypeReceivedDefinition && documentTypeReceivedDefinition.label(t);

  return (
    <DataComparisonLabel
      informationDefinition={definition.information.documentType}
      detailedResult={{
        ...detailedResult,
        expected: expected || detailedResult.expected,
        received: received || detailedResult.received,
      }}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.firstName, entity.individual?.lastName)
      }
    />
  );
};
