/**
 * Transform a fetch Response to an Error by attempting to extract a top-level property
 * `message` from the response body as JSON
 *
 * @param response
 * @param baseMessage
 */
export const fetchResponseToError = async (
  response: Response,
  baseMessage: string,
): Promise<Error> => {
  let message = baseMessage;
  // Attempt to extract body.message since all our error returned by our API should contains a top-level property message
  try {
    const body = await response.json();
    if (body && typeof body === 'object') {
      if (typeof body.message === 'string') {
        message = `${baseMessage}: ${body.message}`;
      } else if (Array.isArray(body.message)) {
        // class-validator error message are an array of string
        message = `${baseMessage}: ${body.message.join(', ')}`;
      }
    }
  } catch (error) {
    // Unable to extract a message from the response
  }

  throw new Error(message, { cause: response });
};
