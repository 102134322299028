import { match } from 'ts-pattern';

import { ChecksPage } from '../modules/checks';
import { EndPage } from '../modules/end/page';
import { FormDatastoreProvider, FormsPage } from '../modules/forms';
import { LoginPage } from '../modules/login';
import {
  AppLoader,
  GenericErrorScreen,
  MainLayout,
  useCase,
  useLatestClientPortalVersion,
} from '../shared';
import { ROOT_ROUTES } from './root-routes.constant';
import { useRouter } from './router.context';
import { ValidateRouteGuard } from './validate-route.guard';

export const ClientPortalApp = () => {
  const router = useRouter();
  const route = router.useRoute(ROOT_ROUTES);

  const caseQuery = useCase();
  const latestClientPortalVersionQuery = useLatestClientPortalVersion();

  if (caseQuery.loading) {
    return <AppLoader />;
  }
  if (caseQuery.error) {
    return <GenericErrorScreen error={caseQuery.error} />;
  }

  if (!latestClientPortalVersionQuery.data) {
    // @NOTE No need to handle loading or error, already loaded from parent components
    return null;
  }
  const clientPortalId =
    latestClientPortalVersionQuery.data.latestClientPortalVersion.clientPortal
      .id;

  return (
    <FormDatastoreProvider clientPortalId={clientPortalId}>
      <ValidateRouteGuard>
        {match(route)
          .with({ name: 'Login' }, () => (
            <MainLayout>
              <LoginPage />
            </MainLayout>
          ))

          .with({ name: 'Forms' }, () => (
            <MainLayout>
              <FormsPage />
            </MainLayout>
          ))

          .with({ name: 'Checks' }, () => (
            <MainLayout backgroundColor="gray.50">
              <ChecksPage />
            </MainLayout>
          ))

          .with({ name: 'End' }, () => (
            <MainLayout backgroundColor="gray.50">
              <EndPage />
            </MainLayout>
          ))

          .otherwise(
            () =>
              // Will be redirected by ValidateRouteGuard
              null,
          )}
      </ValidateRouteGuard>
    </FormDatastoreProvider>
  );
};
