import { match } from 'ts-pattern';

import { logAndAddError } from '@dotfile/frontend/shared/common';
import { ClientPortalForms_Step } from '@dotfile/shared/data-access-client-portal';
import {
  extractMappingFromKey,
  PropertyMappingEntityEnum,
  PropertyMappingTypeEnum,
  resolveDefaultPropertyFromMapping,
} from '@dotfile/shared/domain';

import {
  FormDatastoreCase,
  FormDatastoreCompany,
  FormDatastoreIndividual,
} from '../context';
import { FieldsValues } from './types';

export type FormValuesToDataErrorContext = { step: ClientPortalForms_Step };

export type FormValuesToDataResult = {
  case?: FormDatastoreCase;
  company?: FormDatastoreCompany;
  individual?: FormDatastoreIndividual;
};

export const formValuesToData = (
  formValues: FieldsValues,
  errorContext: FormValuesToDataErrorContext,
): FormValuesToDataResult => {
  const data: Required<FormValuesToDataResult> = {
    case: {},
    individual: {},
    company: {},
  };

  let _errorContext: Record<string, unknown> = { ...errorContext };
  for (const [key, value] of Object.entries(formValues)) {
    try {
      const mapping = extractMappingFromKey(key);
      _errorContext = { ..._errorContext, mapping };

      const entityData = match(mapping.entity)
        .with(PropertyMappingEntityEnum.case, () => data.case)
        .with(PropertyMappingEntityEnum.company, () => data.company)
        .with(PropertyMappingEntityEnum.individual, () => data.individual)
        .exhaustive();

      if (mapping.type === PropertyMappingTypeEnum.custom) {
        entityData.customProperties = {
          ...entityData.customProperties,
          [mapping.key]: value,
        };
      }
      // mapping type = default
      else {
        const { propertyName } = resolveDefaultPropertyFromMapping(mapping);

        // This is very dynamic and cannot be asserted with our current typing
        (entityData as Record<string, unknown>)[propertyName] = value;
      }
    } catch (error) {
      logAndAddError(error, _errorContext);
    }
  }

  return Object.fromEntries(
    // Remove entityData that are not modified
    Object.entries(data).filter((entry) => Object.keys(entry[1]).length > 0),
  );
};
