import {
  ClientPortalBlockLayoutWidgetEnum,
  FileTargetEnum,
} from '../../../../shared/models';

export type PortalLayoutBlockWidgetWithFile = 'pdf' | 'image';
export type PortalLayoutBlockFileTarget =
  | 'client_portal_block_image'
  | 'client_portal_block_pdf';

export const PortalBlockLayoutWidgetFileTargetMapping: Record<
  PortalLayoutBlockWidgetWithFile,
  PortalLayoutBlockFileTarget
> = {
  [ClientPortalBlockLayoutWidgetEnum.pdf]:
    FileTargetEnum.client_portal_block_pdf,
  [ClientPortalBlockLayoutWidgetEnum.image]:
    FileTargetEnum.client_portal_block_image,
};

export const getPortalBlockLayoutFileTarget = (
  clientPortalBlockLayoutWidget: ClientPortalBlockLayoutWidgetEnum,
): PortalLayoutBlockFileTarget | null => {
  if (
    clientPortalBlockLayoutWidget === ClientPortalBlockLayoutWidgetEnum.pdf ||
    clientPortalBlockLayoutWidget === ClientPortalBlockLayoutWidgetEnum.image
  ) {
    return PortalBlockLayoutWidgetFileTargetMapping[
      clientPortalBlockLayoutWidget
    ];
  }

  return null;
};
