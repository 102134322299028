import {
  ClientPortalChecks_Check,
  ClientPortalChecks_IdDocumentData,
  ClientPortalChecks_IdVerificationData,
} from '@dotfile/shared/data-access-client-portal';
import {
  AnalysisResultModel,
  CHECK_ANALYSIS_RESULT_DATA_COMPARISON_BIRTH_DATE_KEY,
  CHECK_ANALYSIS_RESULT_DATA_COMPARISON_NAME_KEY,
  CheckResultEnum,
  CheckTypeEnum,
  computeDataResultFromAnalysisResults,
  getIdentityDataComparisonResult,
} from '@dotfile/shared/domain';

export const useCanUpdateReferenceData = (check: ClientPortalChecks_Check) => {
  const hasData = 'data' in check && check.data;
  if (
    !hasData ||
    !(
      [
        CheckTypeEnum.id_document,
        CheckTypeEnum.id_verification,
      ] as CheckTypeEnum[]
    ).includes(check.type)
  )
    return false;

  const checkData = check.data as
    | ClientPortalChecks_IdDocumentData
    | ClientPortalChecks_IdVerificationData;

  const analysisResults: Pick<AnalysisResultModel, 'key' | 'result'>[] =
    checkData.analysisResults ? checkData.analysisResults : [];
  const isVendorRejected = checkData
    ? computeDataResultFromAnalysisResults(analysisResults, {
        excludeKeys: [
          CHECK_ANALYSIS_RESULT_DATA_COMPARISON_BIRTH_DATE_KEY,
          CHECK_ANALYSIS_RESULT_DATA_COMPARISON_NAME_KEY,
        ],
      }) === CheckResultEnum.rejected
    : false;
  const isDataComparisonRejected =
    getIdentityDataComparisonResult(checkData) === CheckResultEnum.rejected;

  const canUpdateReferenceData = isDataComparisonRejected && !isVendorRejected;

  return canUpdateReferenceData;
};
