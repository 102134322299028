import axios, { Axios, AxiosInstance } from 'axios';
import { clone } from 'lodash';

import { logAndAddError } from '../datadog';
import { AuthTokenService, BaseAccessToken } from '../features/auth';

export interface HttpClientOptions {
  environment: {
    production: boolean;
    baseAPI: string;
  };
  onRefreshAuthFailed?: (error: unknown) => Promise<void> | void;
}

export class HttpClient {
  private instance: AxiosInstance;

  private authToken: AuthTokenService<BaseAccessToken> | null = null;

  private workspaceId: string | null = null;
  private clientPortalId: string | null = null;

  private onRefreshAuthFailed = (error: unknown): void => {
    logAndAddError(error);
  };

  constructor(private readonly options: HttpClientOptions) {
    this.instance = this.createInstance();

    if (options.onRefreshAuthFailed) {
      this.onRefreshAuthFailed = options.onRefreshAuthFailed;
    }
  }

  private createInstance(): AxiosInstance {
    const instance = axios.create({
      baseURL: this.options.environment.baseAPI,
      withCredentials: true,
      // axios will reject the request when http status is not 2XX
      // see https://axios-http.com/docs/req_config
      validateStatus: function (status) {
        return status >= 200 && status < 300;
      },
    });

    // @TODO - E-501 - Media cache /!\ read the issue this line introduce bug for image loading and abort
    //setupCache(instance, { ttl: 1000 * 60 * 15, interpretHeader: true });

    instance.interceptors.request.use(
      async (config) => {
        config.headers = clone(config.headers);

        Object.entries(this.getHeaders()).forEach(([key, value]) => {
          config.headers[key] = value;
        });

        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (
          error?.response?.status === 401 &&
          !error?.config?._retry &&
          error?.config?.headers?.Authorization
        ) {
          try {
            await this.authToken?.refreshAccessToken();

            const originalRequest = error.config;
            originalRequest._retry = true;
            return instance(originalRequest);
          } catch (error) {
            this.authToken?.clearAuthToken();
            this.onRefreshAuthFailed(error);

            throw error;
          }
        }

        return Promise.reject(error);
      },
    );

    return instance;
  }

  public setWorkspaceId(workspaceId: string | null): void {
    if (this.workspaceId !== workspaceId) {
      this.workspaceId = workspaceId;

      // re-create instance to update the workspace in header
      this.instance = this.createInstance();
    }
  }

  public setClientPortalId(clientPortalId: string | null): void {
    if (this.clientPortalId !== clientPortalId) {
      this.clientPortalId = clientPortalId;

      // re-create instance to update the client portal in header
      this.instance = this.createInstance();
    }
  }

  public getHeaders(): Record<string, string> {
    const headers: Record<string, string> = {};

    const accessToken = this.authToken?.getAccessToken();
    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    if (this.workspaceId) {
      headers['dotfile_workspace'] = this.workspaceId;
    }

    if (this.clientPortalId) {
      headers['dotfile_client_portal'] = this.clientPortalId;
    }

    return headers;
  }

  public setAuthToken(authToken: AuthTokenService<BaseAccessToken>): void {
    this.authToken = authToken;
  }

  public getAxios(): AxiosInstance {
    return this.instance;
  }

  // Proxy common axios methods
  get: Axios['get'] = (...args) => this.instance.get(...args);
  post: Axios['post'] = (...args) => this.instance.post(...args);
}
