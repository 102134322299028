import tippy, { Instance as TippyInstance } from 'tippy.js';

import { MentionOptions } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';

import { RichTextGetSuggestionItemsFn } from '../types';
import {
  RichTextSuggestionHandle,
  RichTextSuggestionList,
} from './rich-text-suggestion-list';

/**
 * @see https://tiptap.dev/docs/editor/api/utilities/suggestion
 */
export const createSuggestion = (
  getSuggestionItems: RichTextGetSuggestionItemsFn,
): MentionOptions['suggestion'] => ({
  char: '@',

  items: ({ query }) => getSuggestionItems(query),

  render: () => {
    let component: ReactRenderer<RichTextSuggestionHandle>;
    let popup: TippyInstance;

    return {
      onStart: (props) => {
        component = new ReactRenderer(RichTextSuggestionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        const element = document.getElementsByTagName('body')[0];
        if (!element) {
          return;
        }

        popup = tippy(element, {
          getReferenceClientRect: () => {
            const rect = props.clientRect?.();
            // see  https://github.com/ueberdosis/tiptap/issues/2795#issuecomment-1160623792
            return rect as DOMRect;
          },
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup.setProps({
          // @ts-expect-error https://github.com/ueberdosis/tiptap/issues/2795#issuecomment-1160623792
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup.hide();

          return true;
        }

        return component.ref ? component.ref.onKeyDown(props) : false;
      },

      onExit() {
        !popup.state.isDestroyed && popup.destroy();
        component.destroy();
      },
    };
  },
});
