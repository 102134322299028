import { merge, mergeWith, pick } from 'lodash';
import { ElementOf } from 'ts-essentials';
import { createStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { removeTypenameFromData } from '@dotfile/frontend/shared/common';
import { notEmpty } from '@dotfile/shared/common';
import {
  CaseDataInput,
  ClientPortalForms_Case,
  CompanyDataInput,
  CompanyTypeEnum,
  IndividualDataInput,
} from '@dotfile/shared/data-access-client-portal';

import { environment } from '../../../../../../environments/environment';
import { initialCustomPropertiesValue } from '../../helpers';
import { affiliatedCompaniesSelector, mainCompanySelector } from './selectors';

interface State {
  progress: {
    currentStepKey: string | null;
    completedStepKeys: string[] | null;
  };
  lastAutoSavedAt: Date | null;
  companySearch: {
    searchRef: string | null;
  };
  data: {
    case?: Omit<Partial<CaseDataInput>, 'companies' | 'individuals'>;
    companies?: Array<Partial<CompanyDataInput>>;
    individuals?: Array<Partial<IndividualDataInput>>;
  };
}
interface Action {
  setProgress: (progress: State['progress']) => void;
  setLastAutoSavedAt: (lastAutoSavedAt: State['lastAutoSavedAt']) => void;
  setSearchRef: (searchRef: State['companySearch']['searchRef']) => void;
  setFromCaseQuery: (caseQueryData: ClientPortalForms_Case) => void;
  patchCaseData: (caseData: FormDatastoreCase) => void;
  patchMainCompanyData: (companyData: FormDatastoreCompany) => void;
  addAffiliatedCompanyData: (companyData: FormDatastoreCompany) => void;
  patchAffiliatedCompanyData: (
    companyData: FormDatastoreCompany,
    atIndex: number,
  ) => void;
  deleteAffiliatedCompanyData: (atIndex: number) => void;
  addIndividualData: (individualData: FormDatastoreIndividual) => void;
  patchIndividualData: (
    individualData: FormDatastoreIndividual,
    atIndex: number,
  ) => void;
  deleteIndividualData: (atIndex: number) => void;
  setSignatory: (atIndex: number) => void;
  setDelegator: (atIndex: number) => void;
  setBusinessContact: (atIndex: number) => void;
  resetCompaniesAndIndividualsData: () => void;
  resetData: () => void;
  reset: () => void;
}

export type FormDatastoreState = State & Action;
export type FormDatastoreApi = ReturnType<typeof initFormDatastore>;

export type FormDatastoreCase = State['data']['case'];
export type FormDatastoreCompany = ElementOf<
  Required<State['data']>['companies']
>;
export type FormDatastoreIndividual = ElementOf<
  Required<State['data']>['individuals']
>;

/**
 * Initialize Form Datastore using Zustand
 * Save state in LocalStorage
 * @see https://docs.pmnd.rs/zustand/getting-started/introduction
 *
 * ClearStorage: useDatastore.persist.clearStorage()
 */
export const initFormDatastore = (
  cacheKey: string,
  initialState?: Partial<State>,
) => {
  const _initialState: State = merge(
    {},
    {
      progress: {
        currentStepKey: null,
        completedStepKeys: null,
      },
      lastAutoSavedAt: null,
      companySearch: {
        searchRef: null,
      },
      data: {},
    },
    initialState,
  );
  return createStore<FormDatastoreState>()(
    devtools(
      persist(
        (set, get) => ({
          ..._initialState,
          // actions + selectors
          setProgress: (progress) =>
            set(
              () => ({
                progress,
              }),
              false,
              {
                type: 'setProgress', // @see https://github.com/pmndrs/zustand#logging-actions
                progress,
              },
            ),

          setLastAutoSavedAt: (lastAutoSavedAt) =>
            set(
              () => ({
                lastAutoSavedAt,
              }),
              false,
              {
                type: 'setLastAutoSavedAt', // @see https://github.com/pmndrs/zustand#logging-actions
                lastAutoSavedAt,
              },
            ),

          setSearchRef: (searchRef) =>
            set(
              () => ({
                companySearch: { searchRef },
              }),
              false,
              {
                type: 'setSearchRef',
                searchRef,
              },
            ),

          setFromCaseQuery: (queryData) => {
            const {
              companies: companiesQueryData,
              individuals: individualsQueryData,
              customPropertyValues: caseCustomPropertyValuesQueryData,
              ...caseQueryData
            } = removeTypenameFromData(queryData);

            // Overwrite the whole data with the query
            const data = {
              case: {
                ...caseQueryData,
                customProperties: initialCustomPropertiesValue(
                  caseCustomPropertyValuesQueryData,
                ),
              },
              companies: companiesQueryData.map(
                ({ customPropertyValues, ...companyData }) => ({
                  ...companyData,
                  customProperties:
                    initialCustomPropertiesValue(customPropertyValues),
                }),
              ),
              individuals: individualsQueryData.map(
                ({ customPropertyValues, ...individualData }) => ({
                  ...individualData,
                  customProperties:
                    initialCustomPropertiesValue(customPropertyValues),
                }),
              ),
            };

            set(() => ({ data }), false, { type: 'setFromCaseQuery', data });
          },

          patchCaseData: (caseData) =>
            set(
              ({ data: prevData }) => ({
                data: mergeExceptArray(prevData, { case: caseData }),
              }),
              false,
              { type: 'patchCaseData', caseData },
            ),

          patchMainCompanyData: (companyData) =>
            set(
              ({ data: prevData }) => {
                const companiesCopy = [...(prevData.companies ?? [])];
                let mainCompanyIndex = companiesCopy.findIndex(
                  ({ type }) => type === CompanyTypeEnum.main,
                );

                if (mainCompanyIndex === -1) {
                  mainCompanyIndex = companiesCopy.length;
                }

                companiesCopy[mainCompanyIndex] = mergeExceptArray(
                  prevData.companies?.[mainCompanyIndex],
                  { ...companyData, type: CompanyTypeEnum.main },
                );

                return {
                  data: {
                    ...prevData,
                    companies: companiesCopy,
                  },
                };
              },
              false,
              { type: 'patchMainCompany', companyData },
            ),
          addAffiliatedCompanyData: (companyData) =>
            set(
              ({ data: prevData }) => {
                const patchedCompanies = [
                  ...(prevData?.companies ?? []),
                  { ...companyData, type: CompanyTypeEnum.affiliated },
                ];

                return {
                  data: {
                    ...prevData,
                    companies: patchedCompanies,
                  },
                };
              },
              false,
              { type: 'addAffiliatedCompanyData', companyData },
            ),
          patchAffiliatedCompanyData: (companyData, atIndex) =>
            set(
              ({ data: prevData }) => {
                const companiesCopy = [...(prevData.companies ?? [])];
                let { index: affiliatedCompanyIndex } = companiesCopy.reduce(
                  (acc, company, index) => {
                    if (company.type === CompanyTypeEnum.affiliated) {
                      if (acc.found === atIndex) {
                        return { found: acc.found + 1, index };
                      } else {
                        return { ...acc, found: acc.found + 1 };
                      }
                    }

                    return acc;
                  },
                  { found: 0, index: -1 },
                );

                if (affiliatedCompanyIndex === -1) {
                  affiliatedCompanyIndex = companiesCopy.length;
                }

                companiesCopy[affiliatedCompanyIndex] = mergeExceptArray(
                  prevData.companies?.[affiliatedCompanyIndex],
                  companyData,
                );

                return {
                  data: {
                    ...prevData,
                    companies: companiesCopy,
                  },
                };
              },
              false,
              { type: 'patchAffiliatedCompanyData', companyData, atIndex },
            ),
          deleteAffiliatedCompanyData: (atIndex) =>
            set(
              (prevState) => {
                const mainCompany = mainCompanySelector(prevState);
                const affiliatedCompanies =
                  affiliatedCompaniesSelector(prevState);

                const patchedAffiliatedCompanies = (
                  affiliatedCompanies ?? []
                ).filter((_, index) => index !== atIndex);

                return {
                  data: {
                    ...prevState.data,
                    companies: [
                      mainCompany,
                      ...patchedAffiliatedCompanies,
                    ].filter(notEmpty),
                  },
                };
              },
              false,
              { type: 'deleteAffiliatedCompanyData', atIndex },
            ),

          addIndividualData: (individualData) =>
            set(
              ({ data: prevData }) => {
                const patchedIndividuals = [
                  ...(prevData?.individuals ?? []),
                  individualData,
                ];

                return {
                  data: {
                    ...prevData,
                    individuals: patchedIndividuals,
                  },
                };
              },
              false,
              { type: 'addIndividualData', individualData },
            ),
          patchIndividualData: (individualData, atIndex) =>
            set(
              ({ data: prevData }) => {
                const patchedIndividual = mergeExceptArray(
                  prevData.individuals?.[atIndex],
                  individualData,
                );
                const patchedIndividuals = [...(prevData?.individuals ?? [])];
                patchedIndividuals[atIndex] = patchedIndividual;

                return {
                  data: {
                    ...prevData,
                    individuals: patchedIndividuals,
                  },
                };
              },
              false,
              { type: 'patchIndividualData', individualData, atIndex },
            ),
          deleteIndividualData: (atIndex) =>
            set(
              ({ data: prevData }) => {
                const patchedIndividuals = (prevData?.individuals ?? []).filter(
                  (_, index) => index !== atIndex,
                );

                return {
                  data: {
                    ...prevData,
                    individuals: patchedIndividuals,
                  },
                };
              },
              false,
              { type: 'deleteIndividualData', atIndex },
            ),
          setSignatory: (atIndex) =>
            set(
              ({ data: prevData }) => {
                let individuals: Partial<IndividualDataInput>[] = [];
                if (prevData.individuals?.find((i) => i.isSignatory && i.id)) {
                  // Do nothing if signatory is an existing individual
                  return { data: prevData };
                }

                if (prevData.individuals) {
                  individuals = prevData.individuals?.map((i) => ({
                    ...i,
                    isSignatory: i.id
                      ? // Keep existing value for existing individual, by construction in
                        // the form it should not be possible to change the signatory if
                        // an existing individual is already signatory
                        i.isSignatory
                      : false,
                  }));

                  individuals[atIndex].isSignatory = true;
                  individuals[atIndex].isDelegator = false; // Force remove isDelegator for the selected signatory
                }

                return {
                  data: {
                    ...prevData,
                    individuals,
                  },
                };
              },
              false,
              { type: 'setSignatory', atIndex },
            ),
          setDelegator: (atIndex) =>
            set(
              ({ data: prevData }) => {
                let individuals: Partial<IndividualDataInput>[] = [];
                if (prevData.individuals) {
                  individuals = prevData.individuals?.map((i) => ({
                    ...i,
                    isDelegator: false,
                  }));

                  individuals[atIndex].isDelegator = true;
                  individuals[atIndex].isSignatory = false; // Force remove isSignatory for the selected delegator
                }

                return {
                  data: {
                    ...prevData,
                    individuals,
                  },
                };
              },
              false,
              { type: 'setDelegator', atIndex },
            ),
          setBusinessContact: (atIndex) =>
            set(
              ({ data: prevData }) => {
                let individuals: Partial<IndividualDataInput>[] = [];
                if (prevData.individuals) {
                  individuals = prevData.individuals?.map((i) => ({
                    ...i,
                    isBusinessContact: i.id
                      ? i.isBusinessContact // Keep existing value for existing individual
                      : false,
                  }));

                  individuals[atIndex].isBusinessContact = true;
                }

                return {
                  data: {
                    ...prevData,
                    individuals,
                  },
                };
              },
              false,
              { type: 'setBusinessContact', atIndex },
            ),

          resetCompaniesAndIndividualsData: () =>
            set(
              ({ data }) => {
                // @NOTE: Keep custom properties possibly set before company search step and company search information
                const companies = [];
                const mainCompany = data.companies?.find(
                  (company) => company.type === CompanyTypeEnum.main,
                );
                if (mainCompany) {
                  companies.push(
                    pick(mainCompany, ['type', 'customProperties']),
                  );
                }

                // @NOTE: Keep existing individuals from the case (with an id)
                const individuals = data.individuals?.filter((i) => i.id);

                return {
                  data: {
                    case: data.case,
                    companies: companies,
                    individuals,
                  },
                };
              },
              false,
              { type: 'resetCompaniesAndIndividualsData' },
            ),
          resetData: () =>
            set(
              () => {
                return {
                  data: {
                    // reset data
                  },
                };
              },
              false,
              { type: 'resetData' },
            ),
          reset: () => {
            set(_initialState, false, { type: 'reset' });
          },
        }),
        {
          name: `dot-client-portal-form-datastore-${cacheKey}`,
          version: 0, // @see https://docs.pmnd.rs/zustand/integrations/persisting-store-data#version
          // migrate - storage migration system to apply changes on the local state

          // Skip hydration during test as it can introduce side effect between tests
          skipHydration: process.env['NODE_ENV'] === 'test',
          // @see https://docs.pmnd.rs/zustand/integrations/persisting-store-data#storage
          // @TODO - E-5284 - Client portal: handle date hydration in datastore properly
          // Need to rehydrate Date
          // storage: createJSONStorage<FormDatastoreState>(() => localStorage, {
          //   replacer: (key: string, value: unknown) => {
          //     if (value instanceof Date) {
          //       return { $type: 'Date', value: value.toISOString() };
          //     }
          //     return value;
          //   },
          //   reviver: (key: string, value: unknown) => {
          //     if (
          //       value &&
          //       typeof value === 'object' &&
          //       '$type' in value &&
          //       value.$type === 'Date' &&
          //       'value' in value &&
          //       typeof value.value === 'string'
          //     ) {
          //       return parseISO(value.value);
          //     }
          //     return value;
          //   },
          // }),
          merge: (
            persistedState: unknown,
            currentState: FormDatastoreState,
          ) => ({
            ...currentState,
            // Remove Typename to fix corrupted local storage from https://linear.app/dotfile/issue/E-4813
            ...removeTypenameFromData(
              persistedState && typeof persistedState === 'object'
                ? persistedState
                : {},
            ),
          }),
        },
      ),
      {
        enabled:
          environment.stage === 'dev' && process.env['NODE_ENV'] !== 'test',
        name: 'Client portal - Form',
      },
    ),
  );
};

function mergeExceptArray<T>(prevData: T | undefined, newData: T): T {
  return mergeWith({}, prevData, newData, (obj, src) => {
    return Array.isArray(src)
      ? // if the new value is an array, override instead of merge
        src
      : // fallback to default merge
        undefined;
  });
}
