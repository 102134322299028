import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { fetchResponseToError } from '../http';

export interface ReleaseInfo {
  tag?: string;
  commit: string;
  date: Date;
}

export const useReleaseInfo = (
  options?: Omit<UseQueryOptions<ReleaseInfo | null>, 'queryKey' | 'queryFn'>,
): UseQueryResult<ReleaseInfo | null, Error> => {
  const query = useQuery({
    queryKey: ['release.json'],
    queryFn: async () => {
      const response = await fetch(
        '/assets/release.json',
        // Download a resource with cache busting,
        // to bypass the cache completely.
        { cache: 'no-store' },
      );
      if (!response.ok)
        throw await fetchResponseToError(
          response,
          'Unable to fetch release info',
        );
      return response.json() as Promise<ReleaseInfo>;
    },
    initialData: null,
    ...options,
  });

  return query;
};
