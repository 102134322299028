import { useCallback } from 'react';

import { useFormDatastore } from '../context';
import { useStepProgress } from './use-step-progress';

export function useSubmitStepProgress() {
  const { progress, setProgress } = useFormDatastore((state) => state);
  const { current } = useStepProgress();

  return useCallback(
    (next: string) => {
      if (next) {
        setProgress({
          currentStepKey: next,
          completedStepKeys: [
            ...(progress.completedStepKeys ?? []),
            ...(current &&
            progress.completedStepKeys &&
            !progress.completedStepKeys.includes(current)
              ? [current]
              : []),
          ],
        });
      }
    },
    [current, setProgress, progress],
  );
}
