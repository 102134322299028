import { Building2Icon, BuildingIcon, UserIcon } from 'lucide-react';
import { useMemo } from 'react';

import {
  Heading,
  HStack,
  Icon,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalChecks_Company,
  ClientPortalChecks_Individual,
  CompanyTypeEnum,
} from '@dotfile/shared/data-access-client-portal';
import { fullNameHelper } from '@dotfile/shared/domain';

import { Entity } from '../../types';
import { ChecksCardList } from './check-card-list';
import { PendingActionBadge } from './pending-action-badge';

const isCompany = (entity: Entity): entity is ClientPortalChecks_Company =>
  'name' in entity;
const isIndividual = (
  entity: Entity,
): entity is ClientPortalChecks_Individual => 'firstName' in entity;

export type EntityCardProps = {
  entity: Entity;
  pendingActions?: boolean;
};

export const ChecksEntityCard = ({
  entity,
  pendingActions,
}: EntityCardProps) => {
  const { name, icon } = useMemo(() => {
    let name = '';
    let icon;
    if (isCompany(entity)) {
      name = entity.name;
      if (entity.type === CompanyTypeEnum.main) {
        icon = BuildingIcon;
      } else {
        icon = Building2Icon;
      }
    } else if (isIndividual(entity)) {
      name = fullNameHelper(entity);
      icon = UserIcon;
    }

    return { name, icon };
  }, [entity]);

  return (
    <VStack
      align="start"
      width="full"
      padding="4"
      spacing="4"
      borderRadius="lg"
      backgroundColor="white"
    >
      <HStack width="full" justifyContent="space-between">
        <HStack>
          <Icon color="black" as={icon} />
          <Heading size="md" color="black" fontWeight="400">
            {name}
          </Heading>
        </HStack>
        {pendingActions && <PendingActionBadge entityId={entity.id} />}
      </HStack>
      <ChecksCardList checks={entity.checks} />
    </VStack>
  );
};
