import { Box, BoxProps, forwardRef, useStyleConfig } from '@chakra-ui/react';

export type PillProps = {
  colorScheme?: string;
} & BoxProps;

export const Pill = forwardRef<PillProps, 'span'>(
  ({ colorScheme, ...rest }: PillProps, ref): JSX.Element => {
    const styles = useStyleConfig('Pill', { colorScheme });

    return <Box __css={styles} {...rest} ref={ref} />;
  },
);
