import {
  ENTITY_LEGAL_FORMS,
  GENERIC_DEFAULT_COUNTRY_CODE,
} from './entity-legal-forms.constant';
import { entityLegalFormByCode } from './get-entity-legal-form-by-code';
import { EntityLegalForm } from './types';

function mapEntityLegalFormForCountry(country: string) {
  return Object.entries(ENTITY_LEGAL_FORMS[country] ?? {}).map(
    ([code, codeDefinition]) => ({
      ...codeDefinition,
      country,
      code,
    }),
  );
}

const noCountryEntityLegalForms = mapEntityLegalFormForCountry(
  GENERIC_DEFAULT_COUNTRY_CODE,
);
const allCountryEntityLegalForms = Object.values(entityLegalFormByCode);

export function getAvailableEntityLegalForms(
  country?: string,
  subdivision?: string,
): EntityLegalForm[] {
  let entityLegalForms = allCountryEntityLegalForms;

  if (country) {
    entityLegalForms = mapEntityLegalFormForCountry(country);
  }

  if (subdivision) {
    entityLegalForms = entityLegalForms.filter(
      (elf) => elf.subdivision === subdivision,
    );
  }

  // Only add no country entity Legal Forms if there is a specified country or subdivision (filter)
  if (country || subdivision) {
    entityLegalForms = [...entityLegalForms, ...noCountryEntityLegalForms];
  }

  return entityLegalForms;
}
