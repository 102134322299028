import { ClientPortalBlockFieldProperty } from '@dotfile/shared/data-access-client-portal';
import { Property, PropertyTypeEnum } from '@dotfile/shared/domain';

/**
 * Subset of Property with only the attribute expose by the GraphQL api
 */
export type ClientPortalProperty<T extends PropertyTypeEnum> = Pick<
  Property<T>,
  keyof ClientPortalBlockFieldProperty
>;

export const isPropertyType =
  <T extends PropertyTypeEnum>(type: T) =>
  (
    property: ClientPortalProperty<PropertyTypeEnum>,
  ): property is ClientPortalProperty<T> =>
    property.type === type;
