import {
  IdVerificationDataModeEnum,
  IdVerificationVendorEnum,
} from '../../../shared/models';

type IdVerificationModeDefinition = {
  [key in IdVerificationVendorEnum]: Partial<{
    [key in IdVerificationDataModeEnum]: {
      label: string;
    };
  }>;
};

export const idVerificationModeDefinition: IdVerificationModeDefinition = {
  [IdVerificationVendorEnum.ubble]: {
    [IdVerificationDataModeEnum.liveness]: {
      label: 'VideoExpert (v1)',
    },
  },
  [IdVerificationVendorEnum.checkout]: {
    [IdVerificationDataModeEnum.auto]: {
      label: 'VideoAuto',
    },
    [IdVerificationDataModeEnum.expert]: {
      label: 'VideoExpert (v2)',
    },
    [IdVerificationDataModeEnum.certified]: {
      label: 'VideoCertified (PVID)',
    },
  },
  [IdVerificationVendorEnum.veriff]: {
    [IdVerificationDataModeEnum.default]: {
      label: 'Default',
    },
  },
  [IdVerificationVendorEnum.onfido]: {
    [IdVerificationDataModeEnum.default]: {
      label: 'Default',
    },
  },
};

export const flatIdVerificationModeDefinition: Record<
  IdVerificationDataModeEnum,
  { label: string }
> = Object.values(idVerificationModeDefinition)
  .flatMap((definitionsByVendor) => Object.entries(definitionsByVendor))
  .reduce(
    (acc, [modeKey, value]) => ({ ...acc, [modeKey]: value }),
    {} as Record<IdVerificationDataModeEnum, { label: string }>,
  );
