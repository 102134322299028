import { useTranslation } from 'react-i18next';

import { Text } from '@dotfile/frontend/shared/design-system';

import { SupportButton, useLatestClientPortalVersion } from '../../../shared';

export const SignatoryLockedAlert = () => {
  const { t } = useTranslation();

  const { data } = useLatestClientPortalVersion();
  const supportEmail = data?.latestClientPortalVersion.setting.supportEmail;

  return (
    <Text>
      {t('forms.business_contact.signatory.alert.locked', {
        ns: 'client-portal',
        defaultValue: 'Contact support to request a change of signatory.',
      })}

      {supportEmail && (
        <SupportButton supportEmail={supportEmail} variant="link" ml="0.5" />
      )}
    </Text>
  );
};
