import { SchemaOf } from 'yup';

import {
  ClientPortalBusinessContactConfig,
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BaseClientPortalStepDefinition } from './base-client-portal-step-definition';

export class BusinessContactStepDefinition extends BaseClientPortalStepDefinition<ClientPortalBusinessContactConfig> {
  // General
  // -------
  get type(): ClientPortalStepTypeEnum {
    return ClientPortalStepTypeEnum.business_contact;
  }
  get title(): string {
    return 'Who should be our contact regarding this application?';
  }
  get icon(): string {
    return 'square-user-round';
  }
  get isUnique(): boolean {
    return true;
  }
  public canHaveLogics(): boolean {
    return false;
  }

  get position(): number {
    return 5;
  }
  get clientPortalType(): ClientPortalTypeEnum[] {
    return [ClientPortalTypeEnum.KYB];
  }
  // Actions
  // -------
  get canBeMoved(): boolean {
    return false;
  }
  get canBeRemoved(): boolean {
    return false;
  }

  // Visibility
  // -------
  get canBeHidden(): boolean {
    return false;
  }

  // Config
  // --------

  override get defaultConfig(): ClientPortalBusinessContactConfig {
    return { requestDelegator: false };
  }

  public override configSchema(): SchemaOf<ClientPortalBusinessContactConfig> {
    const schema = yup.object().shape({
      requestDelegator: yup.boolean().required(),
    });

    return schema;
  }

  // @TODO - E-4124 - Client portal: configure blocks for Business contact step
}

export const businessContactStepDefinition =
  new BusinessContactStepDefinition();
