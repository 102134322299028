import {
  IdVerificationDataModeEnum,
  IdVerificationDataSettingsModel,
  IdVerificationVendorEnum,
} from '../../../shared/models';

export const defaultIdVerificationSettings: IdVerificationDataSettingsModel = {
  vendor: IdVerificationVendorEnum.checkout,
  // We want to incentivize `auto` for business related reason
  mode: IdVerificationDataModeEnum.auto,
  automaticApproval: true,
  automaticRejection: true,
  redirectUrl: 'https://dotfile.com',
};
