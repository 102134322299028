import { nameFormat } from '@dotfile/shared/common';
import {
  DocumentAnalysisModelEnum,
  DocumentAnalysisProofOfAddressDetailedResultsModel,
  DocumentAnalysisProofOfAddressInformationModel,
  getDocumentAnalysisDefinition,
} from '@dotfile/shared/domain';

import { DataComparisonLabel } from './data-comparison-label';
import { AnalysisDetailedResult } from './types';
import { ValidAge } from './valid-age';
import { ValidDocumentType } from './valid-document-type';

const definition = getDocumentAnalysisDefinition(
  DocumentAnalysisModelEnum.proof_of_address,
);

export const analysisProofOfAddressDetailedResults: AnalysisDetailedResult<
  DocumentAnalysisProofOfAddressDetailedResultsModel,
  DocumentAnalysisProofOfAddressInformationModel
> = {
  validAgeInDays: ({ detailedResult, information }) => (
    <ValidAge
      detailedResult={detailedResult}
      issuingDate={information.issuingDate}
      informationDefinition={definition.information.issuingDate}
    />
  ),
  nameMatching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.name}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.firstName, entity.individual?.lastName)
      }
    />
  ),
  addressMatching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.address}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.firstName, entity.individual?.lastName)
      }
    />
  ),
  validDocumentType: ({ detailedResult, entity }) => (
    <ValidDocumentType
      definition={definition}
      detailedResult={detailedResult}
      entity={entity}
    />
  ),
};
