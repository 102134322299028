import { flexRender, Header } from '@tanstack/react-table';

import {
  TableColumnHeader,
  TableColumnHeaderProps,
  Text,
} from '@dotfile/frontend/shared/design-system';

import { TBase, TData } from '../helpers';

export const SELECTION_COLUMN_ID = '__selection__';

export type ColumnHeaderProps<T extends TBase> = {
  header: Header<TData<T>, unknown>;
} & TableColumnHeaderProps;

export const ColumnHeader = <T extends TBase>({
  header,
  ...props
}: ColumnHeaderProps<T>) => {
  const size = header.column.columnDef.size;
  const renderedHeader = flexRender(
    header.column.columnDef.header,
    header.getContext(),
  );

  return (
    <TableColumnHeader
      flex={`${size} 0 auto`}
      minWidth="0"
      width={`${size}px`}
      display="flex"
      alignItems="center"
      {...props}
    >
      {header.isPlaceholder ? null : header.column.id ===
        SELECTION_COLUMN_ID ? (
        renderedHeader
      ) : (
        <Text
          fontFamily="heading"
          fontWeight="normal"
          fontSize="inherit"
          noOfLines={1}
          wordBreak="break-all"
        >
          {renderedHeader}
        </Text>
      )}
    </TableColumnHeader>
  );
};
