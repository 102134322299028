// This file is autogenerated by the script "constants:currencies"

/**
 *
 */
type Currency = {
  /**
   * ISO 4217 also assigns a three-digit numeric code to each currency. This numeric code is
   * usually the same as the numeric code assigned to the corresponding country by ISO 3166-1.
   * For example, USD (United States dollar) has numeric code 840 which is also the ISO 3166-1
   * code for "US" (United States).
   */
  numericCode: number;
  /**
   * The number of digits after the decimal separator. Some currency doesn't have any decimals.
   */
  minorUnits: number | null;
  name: string;
  /**
   * Extracted from Intl.NumberFormat but not available for all currencies
   */
  symbol: string | null;
};

/**
 * Currency from ISO 4217, indexed by currency code
 *
 * Generated from XML source at https://www.six-group.com/en/products-services/financial-information/data-standards.html
 * @see https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes
 */
export const CURRENCIES: Record<string, Currency> = {
  AFN: {
    name: 'Afghani',
    numericCode: 971,
    minorUnits: 2,
    symbol: null,
  },
  EUR: {
    name: 'Euro',
    numericCode: 978,
    minorUnits: 2,
    symbol: '€',
  },
  ALL: {
    name: 'Lek',
    numericCode: 8,
    minorUnits: 2,
    symbol: null,
  },
  DZD: {
    name: 'Algerian Dinar',
    numericCode: 12,
    minorUnits: 2,
    symbol: null,
  },
  USD: {
    name: 'US Dollar',
    numericCode: 840,
    minorUnits: 2,
    symbol: '$',
  },
  AOA: {
    name: 'Kwanza',
    numericCode: 973,
    minorUnits: 2,
    symbol: null,
  },
  XCD: {
    name: 'East Caribbean Dollar',
    numericCode: 951,
    minorUnits: 2,
    symbol: 'EC$',
  },
  ARS: {
    name: 'Argentine Peso',
    numericCode: 32,
    minorUnits: 2,
    symbol: null,
  },
  AMD: {
    name: 'Armenian Dram',
    numericCode: 51,
    minorUnits: 2,
    symbol: null,
  },
  AWG: {
    name: 'Aruban Florin',
    numericCode: 533,
    minorUnits: 2,
    symbol: null,
  },
  AUD: {
    name: 'Australian Dollar',
    numericCode: 36,
    minorUnits: 2,
    symbol: 'A$',
  },
  AZN: {
    name: 'Azerbaijan Manat',
    numericCode: 944,
    minorUnits: 2,
    symbol: null,
  },
  BSD: {
    name: 'Bahamian Dollar',
    numericCode: 44,
    minorUnits: 2,
    symbol: null,
  },
  BHD: {
    name: 'Bahraini Dinar',
    numericCode: 48,
    minorUnits: 3,
    symbol: null,
  },
  BDT: {
    name: 'Taka',
    numericCode: 50,
    minorUnits: 2,
    symbol: null,
  },
  BBD: {
    name: 'Barbados Dollar',
    numericCode: 52,
    minorUnits: 2,
    symbol: null,
  },
  BYN: {
    name: 'Belarusian Ruble',
    numericCode: 933,
    minorUnits: 2,
    symbol: null,
  },
  BZD: {
    name: 'Belize Dollar',
    numericCode: 84,
    minorUnits: 2,
    symbol: null,
  },
  XOF: {
    name: 'CFA Franc BCEAO',
    numericCode: 952,
    minorUnits: 0,
    symbol: 'F CFA',
  },
  BMD: {
    name: 'Bermudian Dollar',
    numericCode: 60,
    minorUnits: 2,
    symbol: null,
  },
  INR: {
    name: 'Indian Rupee',
    numericCode: 356,
    minorUnits: 2,
    symbol: '₹',
  },
  BTN: {
    name: 'Ngultrum',
    numericCode: 64,
    minorUnits: 2,
    symbol: null,
  },
  BOB: {
    name: 'Boliviano',
    numericCode: 68,
    minorUnits: 2,
    symbol: null,
  },
  BOV: {
    name: 'Mvdol',
    numericCode: 984,
    minorUnits: 2,
    symbol: null,
  },
  BAM: {
    name: 'Convertible Mark',
    numericCode: 977,
    minorUnits: 2,
    symbol: null,
  },
  BWP: {
    name: 'Pula',
    numericCode: 72,
    minorUnits: 2,
    symbol: null,
  },
  NOK: {
    name: 'Norwegian Krone',
    numericCode: 578,
    minorUnits: 2,
    symbol: null,
  },
  BRL: {
    name: 'Brazilian Real',
    numericCode: 986,
    minorUnits: 2,
    symbol: 'R$',
  },
  BND: {
    name: 'Brunei Dollar',
    numericCode: 96,
    minorUnits: 2,
    symbol: null,
  },
  BGN: {
    name: 'Bulgarian Lev',
    numericCode: 975,
    minorUnits: 2,
    symbol: null,
  },
  BIF: {
    name: 'Burundi Franc',
    numericCode: 108,
    minorUnits: 0,
    symbol: null,
  },
  CVE: {
    name: 'Cabo Verde Escudo',
    numericCode: 132,
    minorUnits: 2,
    symbol: null,
  },
  KHR: {
    name: 'Riel',
    numericCode: 116,
    minorUnits: 2,
    symbol: null,
  },
  XAF: {
    name: 'CFA Franc BEAC',
    numericCode: 950,
    minorUnits: 0,
    symbol: 'FCFA',
  },
  CAD: {
    name: 'Canadian Dollar',
    numericCode: 124,
    minorUnits: 2,
    symbol: 'CA$',
  },
  KYD: {
    name: 'Cayman Islands Dollar',
    numericCode: 136,
    minorUnits: 2,
    symbol: null,
  },
  CLP: {
    name: 'Chilean Peso',
    numericCode: 152,
    minorUnits: 0,
    symbol: null,
  },
  CLF: {
    name: 'Unidad de Fomento',
    numericCode: 990,
    minorUnits: 4,
    symbol: null,
  },
  CNY: {
    name: 'Yuan Renminbi',
    numericCode: 156,
    minorUnits: 2,
    symbol: 'CN¥',
  },
  COP: {
    name: 'Colombian Peso',
    numericCode: 170,
    minorUnits: 2,
    symbol: null,
  },
  COU: {
    name: 'Unidad de Valor Real',
    numericCode: 970,
    minorUnits: 2,
    symbol: null,
  },
  KMF: {
    name: 'Comorian Franc',
    numericCode: 174,
    minorUnits: 0,
    symbol: null,
  },
  CDF: {
    name: 'Congolese Franc',
    numericCode: 976,
    minorUnits: 2,
    symbol: null,
  },
  NZD: {
    name: 'New Zealand Dollar',
    numericCode: 554,
    minorUnits: 2,
    symbol: 'NZ$',
  },
  CRC: {
    name: 'Costa Rican Colon',
    numericCode: 188,
    minorUnits: 2,
    symbol: null,
  },
  CUP: {
    name: 'Cuban Peso',
    numericCode: 192,
    minorUnits: 2,
    symbol: null,
  },
  CUC: {
    name: 'Peso Convertible',
    numericCode: 931,
    minorUnits: 2,
    symbol: null,
  },
  ANG: {
    name: 'Netherlands Antillean Guilder',
    numericCode: 532,
    minorUnits: 2,
    symbol: null,
  },
  CZK: {
    name: 'Czech Koruna',
    numericCode: 203,
    minorUnits: 2,
    symbol: null,
  },
  DKK: {
    name: 'Danish Krone',
    numericCode: 208,
    minorUnits: 2,
    symbol: null,
  },
  DJF: {
    name: 'Djibouti Franc',
    numericCode: 262,
    minorUnits: 0,
    symbol: null,
  },
  DOP: {
    name: 'Dominican Peso',
    numericCode: 214,
    minorUnits: 2,
    symbol: null,
  },
  EGP: {
    name: 'Egyptian Pound',
    numericCode: 818,
    minorUnits: 2,
    symbol: null,
  },
  SVC: {
    name: 'El Salvador Colon',
    numericCode: 222,
    minorUnits: 2,
    symbol: null,
  },
  ERN: {
    name: 'Nakfa',
    numericCode: 232,
    minorUnits: 2,
    symbol: null,
  },
  SZL: {
    name: 'Lilangeni',
    numericCode: 748,
    minorUnits: 2,
    symbol: null,
  },
  ETB: {
    name: 'Ethiopian Birr',
    numericCode: 230,
    minorUnits: 2,
    symbol: null,
  },
  FKP: {
    name: 'Falkland Islands Pound',
    numericCode: 238,
    minorUnits: 2,
    symbol: null,
  },
  FJD: {
    name: 'Fiji Dollar',
    numericCode: 242,
    minorUnits: 2,
    symbol: null,
  },
  XPF: {
    name: 'CFP Franc',
    numericCode: 953,
    minorUnits: 0,
    symbol: 'CFPF',
  },
  GMD: {
    name: 'Dalasi',
    numericCode: 270,
    minorUnits: 2,
    symbol: null,
  },
  GEL: {
    name: 'Lari',
    numericCode: 981,
    minorUnits: 2,
    symbol: null,
  },
  GHS: {
    name: 'Ghana Cedi',
    numericCode: 936,
    minorUnits: 2,
    symbol: null,
  },
  GIP: {
    name: 'Gibraltar Pound',
    numericCode: 292,
    minorUnits: 2,
    symbol: null,
  },
  GTQ: {
    name: 'Quetzal',
    numericCode: 320,
    minorUnits: 2,
    symbol: null,
  },
  GBP: {
    name: 'Pound Sterling',
    numericCode: 826,
    minorUnits: 2,
    symbol: '£',
  },
  GNF: {
    name: 'Guinean Franc',
    numericCode: 324,
    minorUnits: 0,
    symbol: null,
  },
  GYD: {
    name: 'Guyana Dollar',
    numericCode: 328,
    minorUnits: 2,
    symbol: null,
  },
  HTG: {
    name: 'Gourde',
    numericCode: 332,
    minorUnits: 2,
    symbol: null,
  },
  HNL: {
    name: 'Lempira',
    numericCode: 340,
    minorUnits: 2,
    symbol: null,
  },
  HKD: {
    name: 'Hong Kong Dollar',
    numericCode: 344,
    minorUnits: 2,
    symbol: 'HK$',
  },
  HUF: {
    name: 'Forint',
    numericCode: 348,
    minorUnits: 2,
    symbol: null,
  },
  ISK: {
    name: 'Iceland Krona',
    numericCode: 352,
    minorUnits: 0,
    symbol: null,
  },
  IDR: {
    name: 'Rupiah',
    numericCode: 360,
    minorUnits: 2,
    symbol: null,
  },
  XDR: {
    name: 'SDR (Special Drawing Right)',
    numericCode: 960,
    minorUnits: null,
    symbol: null,
  },
  IRR: {
    name: 'Iranian Rial',
    numericCode: 364,
    minorUnits: 2,
    symbol: null,
  },
  IQD: {
    name: 'Iraqi Dinar',
    numericCode: 368,
    minorUnits: 3,
    symbol: null,
  },
  ILS: {
    name: 'New Israeli Sheqel',
    numericCode: 376,
    minorUnits: 2,
    symbol: '₪',
  },
  JMD: {
    name: 'Jamaican Dollar',
    numericCode: 388,
    minorUnits: 2,
    symbol: null,
  },
  JPY: {
    name: 'Yen',
    numericCode: 392,
    minorUnits: 0,
    symbol: '¥',
  },
  JOD: {
    name: 'Jordanian Dinar',
    numericCode: 400,
    minorUnits: 3,
    symbol: null,
  },
  KZT: {
    name: 'Tenge',
    numericCode: 398,
    minorUnits: 2,
    symbol: null,
  },
  KES: {
    name: 'Kenyan Shilling',
    numericCode: 404,
    minorUnits: 2,
    symbol: null,
  },
  KPW: {
    name: 'North Korean Won',
    numericCode: 408,
    minorUnits: 2,
    symbol: null,
  },
  KRW: {
    name: 'Won',
    numericCode: 410,
    minorUnits: 0,
    symbol: '₩',
  },
  KWD: {
    name: 'Kuwaiti Dinar',
    numericCode: 414,
    minorUnits: 3,
    symbol: null,
  },
  KGS: {
    name: 'Som',
    numericCode: 417,
    minorUnits: 2,
    symbol: null,
  },
  LAK: {
    name: 'Lao Kip',
    numericCode: 418,
    minorUnits: 2,
    symbol: null,
  },
  LBP: {
    name: 'Lebanese Pound',
    numericCode: 422,
    minorUnits: 2,
    symbol: null,
  },
  LSL: {
    name: 'Loti',
    numericCode: 426,
    minorUnits: 2,
    symbol: null,
  },
  ZAR: {
    name: 'Rand',
    numericCode: 710,
    minorUnits: 2,
    symbol: null,
  },
  LRD: {
    name: 'Liberian Dollar',
    numericCode: 430,
    minorUnits: 2,
    symbol: null,
  },
  LYD: {
    name: 'Libyan Dinar',
    numericCode: 434,
    minorUnits: 3,
    symbol: null,
  },
  CHF: {
    name: 'Swiss Franc',
    numericCode: 756,
    minorUnits: 2,
    symbol: null,
  },
  MOP: {
    name: 'Pataca',
    numericCode: 446,
    minorUnits: 2,
    symbol: null,
  },
  MKD: {
    name: 'Denar',
    numericCode: 807,
    minorUnits: 2,
    symbol: null,
  },
  MGA: {
    name: 'Malagasy Ariary',
    numericCode: 969,
    minorUnits: 2,
    symbol: null,
  },
  MWK: {
    name: 'Malawi Kwacha',
    numericCode: 454,
    minorUnits: 2,
    symbol: null,
  },
  MYR: {
    name: 'Malaysian Ringgit',
    numericCode: 458,
    minorUnits: 2,
    symbol: null,
  },
  MVR: {
    name: 'Rufiyaa',
    numericCode: 462,
    minorUnits: 2,
    symbol: null,
  },
  MRU: {
    name: 'Ouguiya',
    numericCode: 929,
    minorUnits: 2,
    symbol: null,
  },
  MUR: {
    name: 'Mauritius Rupee',
    numericCode: 480,
    minorUnits: 2,
    symbol: null,
  },
  XUA: {
    name: 'ADB Unit of Account',
    numericCode: 965,
    minorUnits: null,
    symbol: null,
  },
  MXN: {
    name: 'Mexican Peso',
    numericCode: 484,
    minorUnits: 2,
    symbol: 'MX$',
  },
  MXV: {
    name: 'Mexican Unidad de Inversion (UDI)',
    numericCode: 979,
    minorUnits: 2,
    symbol: null,
  },
  MDL: {
    name: 'Moldovan Leu',
    numericCode: 498,
    minorUnits: 2,
    symbol: null,
  },
  MNT: {
    name: 'Tugrik',
    numericCode: 496,
    minorUnits: 2,
    symbol: null,
  },
  MAD: {
    name: 'Moroccan Dirham',
    numericCode: 504,
    minorUnits: 2,
    symbol: null,
  },
  MZN: {
    name: 'Mozambique Metical',
    numericCode: 943,
    minorUnits: 2,
    symbol: null,
  },
  MMK: {
    name: 'Kyat',
    numericCode: 104,
    minorUnits: 2,
    symbol: null,
  },
  NAD: {
    name: 'Namibia Dollar',
    numericCode: 516,
    minorUnits: 2,
    symbol: null,
  },
  NPR: {
    name: 'Nepalese Rupee',
    numericCode: 524,
    minorUnits: 2,
    symbol: null,
  },
  NIO: {
    name: 'Cordoba Oro',
    numericCode: 558,
    minorUnits: 2,
    symbol: null,
  },
  NGN: {
    name: 'Naira',
    numericCode: 566,
    minorUnits: 2,
    symbol: null,
  },
  OMR: {
    name: 'Rial Omani',
    numericCode: 512,
    minorUnits: 3,
    symbol: null,
  },
  PKR: {
    name: 'Pakistan Rupee',
    numericCode: 586,
    minorUnits: 2,
    symbol: null,
  },
  PAB: {
    name: 'Balboa',
    numericCode: 590,
    minorUnits: 2,
    symbol: null,
  },
  PGK: {
    name: 'Kina',
    numericCode: 598,
    minorUnits: 2,
    symbol: null,
  },
  PYG: {
    name: 'Guarani',
    numericCode: 600,
    minorUnits: 0,
    symbol: null,
  },
  PEN: {
    name: 'Sol',
    numericCode: 604,
    minorUnits: 2,
    symbol: null,
  },
  PHP: {
    name: 'Philippine Peso',
    numericCode: 608,
    minorUnits: 2,
    symbol: '₱',
  },
  PLN: {
    name: 'Zloty',
    numericCode: 985,
    minorUnits: 2,
    symbol: null,
  },
  QAR: {
    name: 'Qatari Rial',
    numericCode: 634,
    minorUnits: 2,
    symbol: null,
  },
  RON: {
    name: 'Romanian Leu',
    numericCode: 946,
    minorUnits: 2,
    symbol: null,
  },
  RUB: {
    name: 'Russian Ruble',
    numericCode: 643,
    minorUnits: 2,
    symbol: null,
  },
  RWF: {
    name: 'Rwanda Franc',
    numericCode: 646,
    minorUnits: 0,
    symbol: null,
  },
  SHP: {
    name: 'Saint Helena Pound',
    numericCode: 654,
    minorUnits: 2,
    symbol: null,
  },
  WST: {
    name: 'Tala',
    numericCode: 882,
    minorUnits: 2,
    symbol: null,
  },
  STN: {
    name: 'Dobra',
    numericCode: 930,
    minorUnits: 2,
    symbol: null,
  },
  SAR: {
    name: 'Saudi Riyal',
    numericCode: 682,
    minorUnits: 2,
    symbol: null,
  },
  RSD: {
    name: 'Serbian Dinar',
    numericCode: 941,
    minorUnits: 2,
    symbol: null,
  },
  SCR: {
    name: 'Seychelles Rupee',
    numericCode: 690,
    minorUnits: 2,
    symbol: null,
  },
  SLE: {
    name: 'Leone',
    numericCode: 925,
    minorUnits: 2,
    symbol: null,
  },
  SGD: {
    name: 'Singapore Dollar',
    numericCode: 702,
    minorUnits: 2,
    symbol: null,
  },
  XSU: {
    name: 'Sucre',
    numericCode: 994,
    minorUnits: null,
    symbol: null,
  },
  SBD: {
    name: 'Solomon Islands Dollar',
    numericCode: 90,
    minorUnits: 2,
    symbol: null,
  },
  SOS: {
    name: 'Somali Shilling',
    numericCode: 706,
    minorUnits: 2,
    symbol: null,
  },
  SSP: {
    name: 'South Sudanese Pound',
    numericCode: 728,
    minorUnits: 2,
    symbol: null,
  },
  LKR: {
    name: 'Sri Lanka Rupee',
    numericCode: 144,
    minorUnits: 2,
    symbol: null,
  },
  SDG: {
    name: 'Sudanese Pound',
    numericCode: 938,
    minorUnits: 2,
    symbol: null,
  },
  SRD: {
    name: 'Surinam Dollar',
    numericCode: 968,
    minorUnits: 2,
    symbol: null,
  },
  SEK: {
    name: 'Swedish Krona',
    numericCode: 752,
    minorUnits: 2,
    symbol: null,
  },
  CHE: {
    name: 'WIR Euro',
    numericCode: 947,
    minorUnits: 2,
    symbol: null,
  },
  CHW: {
    name: 'WIR Franc',
    numericCode: 948,
    minorUnits: 2,
    symbol: null,
  },
  SYP: {
    name: 'Syrian Pound',
    numericCode: 760,
    minorUnits: 2,
    symbol: null,
  },
  TWD: {
    name: 'New Taiwan Dollar',
    numericCode: 901,
    minorUnits: 2,
    symbol: 'NT$',
  },
  TJS: {
    name: 'Somoni',
    numericCode: 972,
    minorUnits: 2,
    symbol: null,
  },
  TZS: {
    name: 'Tanzanian Shilling',
    numericCode: 834,
    minorUnits: 2,
    symbol: null,
  },
  THB: {
    name: 'Baht',
    numericCode: 764,
    minorUnits: 2,
    symbol: null,
  },
  TOP: {
    name: 'Pa’anga',
    numericCode: 776,
    minorUnits: 2,
    symbol: null,
  },
  TTD: {
    name: 'Trinidad and Tobago Dollar',
    numericCode: 780,
    minorUnits: 2,
    symbol: null,
  },
  TND: {
    name: 'Tunisian Dinar',
    numericCode: 788,
    minorUnits: 3,
    symbol: null,
  },
  TRY: {
    name: 'Turkish Lira',
    numericCode: 949,
    minorUnits: 2,
    symbol: null,
  },
  TMT: {
    name: 'Turkmenistan New Manat',
    numericCode: 934,
    minorUnits: 2,
    symbol: null,
  },
  UGX: {
    name: 'Uganda Shilling',
    numericCode: 800,
    minorUnits: 0,
    symbol: null,
  },
  UAH: {
    name: 'Hryvnia',
    numericCode: 980,
    minorUnits: 2,
    symbol: null,
  },
  AED: {
    name: 'UAE Dirham',
    numericCode: 784,
    minorUnits: 2,
    symbol: null,
  },
  USN: {
    name: 'US Dollar (Next day)',
    numericCode: 997,
    minorUnits: 2,
    symbol: null,
  },
  UYU: {
    name: 'Peso Uruguayo',
    numericCode: 858,
    minorUnits: 2,
    symbol: null,
  },
  UYI: {
    name: 'Uruguay Peso en Unidades Indexadas (UI)',
    numericCode: 940,
    minorUnits: 0,
    symbol: null,
  },
  UYW: {
    name: 'Unidad Previsional',
    numericCode: 927,
    minorUnits: 4,
    symbol: null,
  },
  UZS: {
    name: 'Uzbekistan Sum',
    numericCode: 860,
    minorUnits: 2,
    symbol: null,
  },
  VUV: {
    name: 'Vatu',
    numericCode: 548,
    minorUnits: 0,
    symbol: null,
  },
  VES: {
    name: 'Bolívar Soberano',
    numericCode: 928,
    minorUnits: 2,
    symbol: null,
  },
  VED: {
    name: 'Bolívar Soberano',
    numericCode: 926,
    minorUnits: 2,
    symbol: null,
  },
  VND: {
    name: 'Dong',
    numericCode: 704,
    minorUnits: 0,
    symbol: '₫',
  },
  YER: {
    name: 'Yemeni Rial',
    numericCode: 886,
    minorUnits: 2,
    symbol: null,
  },
  ZMW: {
    name: 'Zambian Kwacha',
    numericCode: 967,
    minorUnits: 2,
    symbol: null,
  },
  ZWL: {
    name: 'Zimbabwe Dollar',
    numericCode: 932,
    minorUnits: 2,
    symbol: null,
  },
  ZWG: {
    name: 'Zimbabwe Gold',
    numericCode: 924,
    minorUnits: 2,
    symbol: null,
  },
  XBA: {
    name: 'Bond Markets Unit European Composite Unit (EURCO)',
    numericCode: 955,
    minorUnits: null,
    symbol: null,
  },
  XBB: {
    name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
    numericCode: 956,
    minorUnits: null,
    symbol: null,
  },
  XBC: {
    name: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
    numericCode: 957,
    minorUnits: null,
    symbol: null,
  },
  XBD: {
    name: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
    numericCode: 958,
    minorUnits: null,
    symbol: null,
  },
  XTS: {
    name: 'Codes specifically reserved for testing purposes',
    numericCode: 963,
    minorUnits: null,
    symbol: null,
  },
  XXX: {
    name: 'The codes assigned for transactions where no currency is involved',
    numericCode: 999,
    minorUnits: null,
    symbol: '¤',
  },
  XAU: {
    name: 'Gold',
    numericCode: 959,
    minorUnits: null,
    symbol: null,
  },
  XPD: {
    name: 'Palladium',
    numericCode: 964,
    minorUnits: null,
    symbol: null,
  },
  XPT: {
    name: 'Platinum',
    numericCode: 962,
    minorUnits: null,
    symbol: null,
  },
  XAG: {
    name: 'Silver',
    numericCode: 961,
    minorUnits: null,
    symbol: null,
  },
};
