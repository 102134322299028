import { readableColor } from 'polished';

import { useToken } from '@dotfile/frontend/shared/design-system';

import { useLatestClientPortalVersion } from './use-latest-client-portal-version';

export const useReadableColor = () => {
  const [gray200, gray800] = useToken('colors', ['gray.200', 'gray.800']);
  const { data } = useLatestClientPortalVersion();

  const contrastColor = readableColor(
    data?.latestClientPortalVersion.setting.styling.colorsMain ?? '#FFF',
    gray800,
    gray200,
    false,
  );

  return contrastColor;
};
