import { MfaMethodEnum } from '../../../shared/models';

export const mfaMethodDefinition: Record<
  MfaMethodEnum,
  {
    label: string;
    shortLabel: string;
  }
> = {
  [MfaMethodEnum.authenticator]: {
    label: 'Authenticator app',
    shortLabel: 'Authenticator',
  },
  [MfaMethodEnum.email]: {
    label: 'Email',
    shortLabel: 'Email',
  },
};
