import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { Box } from '../../layout/box/box';
import { Flex } from '../../layout/flex/flex';
import { HStack } from '../../layout/stack/stack';
import { Text } from '../../typography/text';
import { usePrevious } from '../../utils/hooks/chakra-hooks';
import { Button } from '../button/button';
import { DatePicker, DatePickerProps } from './date-picker';
import { getDaysBetweenTwoDates } from './utils';

export type DateRange = [Date | null, Date | null];

export type DateRangePickerProps = Omit<DatePickerProps<true>, 'selectRange'>;

export const DateRangePicker = ({
  endDate = null,
  startDate = null,
  onChange,
  inline,
  ...props
}: DateRangePickerProps): JSX.Element => {
  const [localDateRange, setLocalDateRange] = useState<DateRange>([
    startDate,
    endDate,
  ]);

  const [localStartDate, localEndDate] = localDateRange;

  // Force update of local state when props changes
  const previousStartDate = usePrevious(startDate);
  const previousEndDate = usePrevious(endDate);
  useEffect(() => {
    if (
      (previousStartDate && !isEqual(startDate, previousStartDate)) ||
      (previousEndDate && !isEqual(endDate, previousEndDate))
    ) {
      setLocalDateRange((prevState) =>
        isEqual(startDate, prevState[0]) && isEqual(endDate, prevState[1])
          ? prevState
          : [startDate, endDate],
      );
    }
  }, [startDate, endDate, previousStartDate, previousEndDate]);

  if (inline) {
    const countDaysInRange = getDaysBetweenTwoDates(
      localStartDate,
      localEndDate,
    );

    return (
      <Box w="248px">
        <DatePicker
          {...props}
          inline
          selectsRange
          onChange={setLocalDateRange}
          startDate={localStartDate}
          endDate={localEndDate}
        />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          padding="3"
          borderTop="1px solid"
          borderColor="gray.100"
        >
          <HStack spacing={1}>
            <Text fontSize="xs" color="gray.200" fontWeight="light">
              {/* @TODO - E-4234 - Translate DateRangePicker wording */}
              Range:
            </Text>
            <Text fontSize="xs" color="gray.500">
              {/* @TODO - E-4234 - Translate DateRangePicker wording */}
              {countDaysInRange} day{countDaysInRange !== 1 && 's'}
            </Text>
          </HStack>

          <HStack spacing={1}>
            <Button
              size="xs"
              variant="ghost"
              isDisabled={
                startDate === localStartDate && endDate === localEndDate
              }
              onClick={() => setLocalDateRange([startDate, endDate])}
            >
              {/* @TODO - E-4234 - Translate DateRangePicker wording */}
              Cancel
            </Button>
            <Button
              size="xs"
              onClick={(e) => {
                e.currentTarget.blur();
                onChange(localDateRange, e);
              }}
              isDisabled={localStartDate === null}
            >
              {/* @TODO - E-4234 - Translate DateRangePicker wording */}
              Apply
            </Button>
          </HStack>
        </Flex>
      </Box>
    );
  } else {
    return (
      <DatePicker
        {...props}
        selectsRange
        onChange={(value, e) => {
          setLocalDateRange(value);

          // auto apply when both are set when not inline
          onChange(value, e);
        }}
        startDate={localStartDate}
        endDate={localEndDate}
        onCalendarClose={() => {
          if (localStartDate && !localEndDate) {
            // Reset the local range when closing and the range was incomplete
            setLocalDateRange([null, null]);
          }
        }}
      />
    );
  }
};
