import { DeepReadonly } from 'ts-essentials';

/**
 * Deep freeze an object
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 * @param obj a object
 * @returns
 */
export function deepFreeze<O extends object>(object: O): DeepReadonly<O> {
  if (object === undefined || typeof object !== 'object' || object === null) {
    return object as DeepReadonly<O>;
  }

  Object.freeze(object);

  Object.getOwnPropertyNames(object).forEach(function (_key) {
    const key = _key as keyof O;

    if (
      object[key] !== null &&
      typeof object[key] === 'object' &&
      !Object.isFrozen(object[key])
    ) {
      deepFreeze(object[key] as object);
    }
  });

  return object as DeepReadonly<O>;
}
