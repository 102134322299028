import {
  AnalysisResultCategoryEnum,
  AnalysisResultModel,
  CheckResultEnum,
} from '../../../../shared/models';

/**
 * Mapping of checkout code to analysis result
 *
 * `key` is Checkout `summary`
 *
 * @see https://docs.ubble.ai/#section/Handle-verification-results/Response-codes
 */
export const checkoutVerificationCodesDefinition: Record<
  number,
  Pick<AnalysisResultModel, 'key' | 'description' | 'result' | 'category'>
> = {
  // Status: Approved
  10_000: {
    key: 'approved',
    description: 'Approved',
    result: CheckResultEnum.approved,
    category: AnalysisResultCategoryEnum.other,
  },

  // Status: retry_required
  // Application engagement issue
  61_101: {
    key: 'applicant_never_started',
    description:
      'Applicant was probably not redirected to the application and never started the flow',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_111: {
    key: 'applicant_not_ready',
    description:
      'Applicant explicitly stated that they wanted to perform the identity verification later by clicking on the dedicated button',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_112: {
    key: 'applicant_no_document',
    description:
      'Applicant explicitly stated that they did not have their document with them by clicking on the dedicated button',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_113: {
    key: 'camera_access_refused',
    description:
      'Applicant did not give access to the camera despite the dedicated instructions',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_121: {
    key: 'applicant_drop',
    description:
      'Applicant left the process before the capture phase and the verification URL expired (by default the expiration time is 15 minutes)',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  // Technical issue
  61_201: {
    key: 'connexion_insufficient',
    description:
      'Applicant did not have a sufficient connexion to perform the capture',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_202: {
    key: 'browser_not_supported',
    description: 'Applicant’s browser was not suitable for video streaming',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_203: {
    key: 'camera_not_found',
    description: 'Applicant’s device did not have any camera',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_204: {
    key: 'focus_lost',
    description:
      'Applicant switched tabs/application while performing the id verification',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_205: {
    key: 'sms_not_received',
    description: 'Applicant did not receive the SMS',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  // Document capture issue
  61_301: {
    key: 'document_video_blurry',
    description:
      'Applicant’s document video is too blurry (mostly due to too much movement but if this error persists the camera quality might be at fault)',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_302: {
    key: 'document_video_lighting_issue',
    description:
      'Applicant performed the document capture under poor lighting conditions',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_310: {
    key: 'document_front_not_captured',
    description: 'Applicant has not captured the front of the document',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_311: {
    key: 'document_back_not_captured',
    description: 'Applicant has not captured the back of the document',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_312: {
    key: 'document_partially_hidden',
    description: 'Applicant hides part of the document',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_313: {
    key: 'document_not_tilted',
    description: 'Applicant did not present a dynamic view of the document',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_314: {
    key: 'document_challenge_timeout',
    description:
      'Applicant failed to meet the document challenge and the verification expired',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_315: {
    key: 'document_barcode_unreadable',
    description: 'The barcode of the document is unreadable',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  // Face capture issue
  61_401: {
    key: 'face_video_blurry',
    description:
      'Applicant’s video of their face is too blurry (mostly due to too much movement but if this error persists the camera quality might be at fault)',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_402: {
    key: 'face_video_lighting_issue',
    description:
      'Applicant performed their identity verification under poor lighting conditions',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_410: {
    key: 'face_not_captured',
    description: 'Applicant has not presented a face',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_411: {
    key: 'face_partially_hidden',
    description: 'Applicant did not show the full front view of their face',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_412: {
    key: 'face_not_turned',
    description: 'Applicant did not move to prove the liveness',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_413: {
    key: 'face_challenge_timeout',
    description:
      'Applicant failed to meet the face challenge and the verification expired',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  61_414: {
    key: 'various_faces_detected',
    description: 'Applicant also captured another person face',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  // Other issues
  61_901: {
    key: 'internal_error',
    description:
      'An internal error prevents us from completing the verification, we do our best to reduce the occurrence of this case',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  61_911: {
    key: 'corrupted_videos',
    description: 'The received videos cannot be played',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  64_001: {
    key: 'forcibly_closed',
    description:
      'You have created a new attempt or the verification has been anonymised while the URL was still valid',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },

  // Status: declined
  // Document issues
  62_101: {
    key: 'document_expired',
    description: 'Applicant presented an expired document',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.document_expiration,
  },
  62_102: {
    key: 'document_not_accepted',
    description: 'Applicant presented a document which is not accepted',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.document_acceptability,
  },
  62_103: {
    key: 'document_damaged',
    description: 'Applicant has submitted a damaged document',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.document_integrity,
  },
  62_104: {
    key: 'unsupported_alphabet',
    description:
      'Applicant has submitted a document containing an unsupported alphabet',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
  // Copy issue
  62_201: {
    key: 'document_photocopy',
    description: 'Applicant presented a photocopy of the document',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  62_202: {
    key: 'document_screenshot',
    description: 'Applicant presented the document on a screen',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.image_integrity,
  },
  // Identity fraud
  62_301: {
    key: 'document_counterfeit',
    description: 'Applicant has submitted a counterfeit or falsification',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_302: {
    key: 'document_stolen',
    description: 'Applicant presented a document declared as stolen or lost',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_303: {
    key: 'document_swap',
    description:
      'Applicant presented the front and back of two different documents',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_304: {
    key: 'face_mismatch',
    description: 'Applicant does not match the photograph of the document',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_305: {
    key: 'face_not_live',
    description:
      "Applicant has presented a photography or a video of someone else's face on a screen or on a physical medium",
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_306: {
    key: 'face_alteration',
    description: 'Applicant has altered their appearance',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_307: {
    key: 'videos_digital_alteration',
    description: 'Applicant has digitally altered the videos',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_321: {
    key: 'face_face_mismatch',
    description:
      'Applicant of the face authentication is not the one who performed the identity verification',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_399: {
    key: 'generic_fraud',
    description:
      'Generic code when a fraud has been detected within a certified identity verification',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  // Suspicious behavior
  62_401: {
    key: 'declared_identity_mismatch',
    description: 'Applicant’s identity does not match with the expected one',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.data_comparison,
  },
  62_402: {
    key: 'suspicious_device',
    description: 'Applicant used a device that has been technically altered',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_403: {
    key: 'consent_unclear',
    description:
      'Applicant seems to have performed the capture against their will',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  62_404: {
    key: 'multiple_documents',
    description: 'Applicant shows multiple documents',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.fraud,
  },
  // Status: refused
  63_001: {
    key: 'applicant_refusal',
    description:
      'Applicant explicitly refused to do the verification process by clicking on the dedicated button',
    result: CheckResultEnum.rejected,
    category: AnalysisResultCategoryEnum.other,
  },
};
