import {
  AnalysisResultModel,
  CheckResultEnum,
  DetailedResultsDocumentModel,
} from '../../../shared/models';
import { computeIdDataComparisonResultFromAnalysisResults } from '../analysis-result/compute-id-data-comparison-result-from-analysis-results';

/**
 * Use to compute dataComparison result based on analysisResults if exists,
 * else fallback to deprecated detailsResults.document.dataComparison
 * Typing is voluntary loose to allow frontend fragment types to use it
 **/
export const getIdentityDataComparisonResult = (documentData: {
  analysisResults?: Array<Partial<AnalysisResultModel>> | null;
  detailedResults?: {
    document: Partial<
      Pick<DetailedResultsDocumentModel, 'dataComparison'>
    > | null;
  } | null;
}): CheckResultEnum | null => {
  if (documentData.analysisResults?.length) {
    return computeIdDataComparisonResultFromAnalysisResults(
      documentData.analysisResults as AnalysisResultModel[],
    );
  }

  return documentData.detailedResults?.document?.dataComparison ?? null;
};
