import { Trash } from 'lucide-react';
import { Fragment, useMemo, useState } from 'react';
import {
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext,
} from 'react-hook-form';

import {
  Button,
  Divider,
  FormErrorMessage,
  GridItem,
  Icon,
  Input,
  useIsMobile,
} from '@dotfile/frontend/shared/design-system';
import {
  CompanyClassificationModel,
  CompanyClassificationTypeEnum,
} from '@dotfile/shared/domain';

import { GroupController } from '../../../input';
import { CompanyClassificationCodeField } from './company-classification-code-field';
import { CompanyClassificationTypeField } from './company-classification-type-field';
import { CompanyClassificationLabels } from './types';

export type CompanyClassificationFieldProps = {
  index: number;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<FieldValues, string>;
  name?: string;
  country?: string;
  labels: CompanyClassificationLabels;
};

export const CompanyClassificationField = ({
  index,
  remove,
  update,
  name,
  country,
  labels,
}: CompanyClassificationFieldProps) => {
  const isMobile = useIsMobile();
  const prefix = `${name}.${index}`;
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const classifications: CompanyClassificationModel[] = watch(name ?? '');
  const classification = watch(prefix);
  const knownType = classification.type in CompanyClassificationTypeEnum;
  const [isManual, setManual] = useState(!knownType && !!classification.type);

  const notUnique = name && errors[name]?.type === 'uniqueItems';
  const isNotFirstNotUnique = useMemo(() => {
    if (!notUnique) return false;

    const indexMatching = classifications.findIndex(
      (c) =>
        c.code === classification.code &&
        c.type === classification.type &&
        c.description === classification.description,
    );

    if (indexMatching === -1 || indexMatching === index) return false;
    return true;
  }, [classifications, classification, index, notUnique]);

  return (
    <Fragment key={`company-classification-${index}`}>
      <GridItem>
        <CompanyClassificationTypeField
          name={name}
          index={index}
          update={update}
          isManual={isManual}
          setManual={setManual}
          country={country}
          label={labels.type}
        />
      </GridItem>
      {isMobile && (
        <GridItem display="flex" alignItems="flex-start">
          <Button
            mt="7"
            width="full"
            height="10"
            aria-label="delete"
            variant="outline"
            leftIcon={<Icon as={Trash} />}
            colorScheme="red"
            onClick={() => remove(index)}
          >
            {labels.delete}
          </Button>
        </GridItem>
      )}
      <GridItem colSpan={!isManual || isMobile ? 2 : 1}>
        <CompanyClassificationCodeField
          name={name}
          index={index}
          update={update}
          isManual={isManual}
          label={labels.code}
        />
      </GridItem>
      {isManual && (
        <GridItem colSpan={isMobile ? 2 : 1}>
          <GroupController
            name={`${prefix}.description`}
            label={labels.description}
            control={control}
            isDisabled={!classification.type}
            render={(field) => (
              <Input {...field} value={classification.description ?? ''} />
            )}
          />
        </GridItem>
      )}
      {!isMobile && (
        <GridItem
          display="flex"
          justifyContent={isMobile ? 'flex-end' : 'center'}
        >
          <Icon
            mt="8"
            h="8"
            as={Trash}
            cursor="pointer"
            onClick={() => remove(index)}
          />
        </GridItem>
      )}
      {isNotFirstNotUnique && name && (
        <GridItem colSpan={isMobile ? 1 : 4}>
          <GroupController
            marginTop="-2"
            marginBottom="2"
            name={name}
            error={labels.notUnique}
            render={() => <FormErrorMessage />}
          />
        </GridItem>
      )}
      {isMobile && (
        <GridItem colSpan={2} my="2">
          <Divider />
        </GridItem>
      )}
    </Fragment>
  );
};
