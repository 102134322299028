import { humanCase } from '@dotfile/shared/common';

import {
  CompanyModel,
  CompanyStatusEnum,
  CompanyTypeEnum,
  PropertyMappingEntityEnum,
} from '../../shared/models';
import {
  AddressPropertyKey,
  getAddressDefaultPropertiesDefinition,
} from './address-properties.definition';
import { COMPANY_NAME_MAX_LENGTH } from './constants';
import {
  DefaultPropertiesBuilder,
  NestedPropertyPrefix,
} from './default-properties-builder';
import { Property, PropertyTypeEnum } from './types';

export const companyDefaultPropertiesDefinition: Record<
  | keyof Omit<
      // @NOTE Also update the test snapshot when modifying the properties
      CompanyModel,
      | 'case'
      | 'caseId'
      | 'checks'
      | 'documentOrders'
      | 'relations'
      | 'workspace'
      | 'workspaceId'
      | 'customPropertyValues'
      | 'vendorData'
      | 'vendorIds'
    >
  | NestedPropertyPrefix<'address', AddressPropertyKey>,
  Property<PropertyTypeEnum>
> = new DefaultPropertiesBuilder<CompanyModel>(
  PropertyMappingEntityEnum.company,
)
  .addProperty('id', PropertyTypeEnum.text)
  .addProperty('lastActivityAt', PropertyTypeEnum.date, {
    label: 'Last activity',
  })
  .addProperty('isRelevant', PropertyTypeEnum.boolean)
  .addProperty('name', PropertyTypeEnum.text, {
    alwaysRequired: true,
    settings: {
      isMultiLine: false,
      maxLength: COMPANY_NAME_MAX_LENGTH,
    },
  })
  .addProperty('commercialName', PropertyTypeEnum.text, {
    settings: {
      isMultiLine: false,
      maxLength: COMPANY_NAME_MAX_LENGTH,
    },
  })
  .addProperty('registrationNumber', PropertyTypeEnum.text, {
    alwaysRequired: false,
  })
  .addProperty('registrationDate', PropertyTypeEnum.date)
  .addProperty('type', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CompanyTypeEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })
  .addProperty('country', PropertyTypeEnum.countries, {
    alwaysRequired: false,
    settings: { allowMultiple: false },
  })
  .addProperty('status', PropertyTypeEnum.choices, {
    settings: {
      allowMultiple: false,
      options: Object.values(CompanyStatusEnum).map((key) => ({
        key,
        label: humanCase(key),
      })),
    },
  })
  .addProperty('legalForm', PropertyTypeEnum.text)
  .addProperty('entityLegalForm', PropertyTypeEnum.entity_legal_form)
  .addProperty('bankingInformation', PropertyTypeEnum.banking_information)
  .addProperty('address', PropertyTypeEnum.address)
  .addNestedProperties('address', getAddressDefaultPropertiesDefinition)
  .addProperty('taxIdentificationNumber', PropertyTypeEnum.text)
  .addProperty('employerIdentificationNumber', PropertyTypeEnum.text)
  .addProperty('classifications', PropertyTypeEnum.classifications)
  .addProperty('shareCapital', PropertyTypeEnum.text)
  .addProperty('websiteUrl', PropertyTypeEnum.url, {
    label: 'Website URL',
  })
  .addProperty('createdAt', PropertyTypeEnum.date)
  .addProperty('updatedAt', PropertyTypeEnum.date)
  .addProperty('deletedAt', PropertyTypeEnum.date)
  .getDefinition();
