import { SchemaOf } from 'yup';

import {
  ClientPortalLogicTypeEnum,
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { StepDefinitionAvailableBlocks } from './types';

export abstract class BaseClientPortalStepDefinition<TConfig = null> {
  // General
  // -------

  /**
   * Step identifier.
   */
  abstract get type(): ClientPortalStepTypeEnum;

  /**
   * Is the step unique per Client portal.
   * If not unique, new step with this type can be added.
   */
  abstract get isUnique(): boolean;

  /**
   * Default title (in english) for this Step type.
   * Mainly use for basic fixtures
   */
  abstract get title(): string;

  /**
   * Can the step have logics, for instance to control its display
   */
  public abstract canHaveLogics(type?: ClientPortalLogicTypeEnum): boolean;

  /**
   * Lucide icon name
   * @see https://lucide.dev/icons/
   */
  abstract get icon(): string;

  /**
   * Default position for this Step type.
   */
  abstract get position(): number;

  /**
   * Step available on KYB or KYC portal, return an array of supported client portal type
   */
  abstract get clientPortalType(): ClientPortalTypeEnum[];

  // Actions
  // -------

  /**
   * Is the step can be moved/reorder
   */
  abstract get canBeMoved(): boolean;

  /**
   * Can be removed
   */
  abstract get canBeRemoved(): boolean;

  // Visibility
  // -------
  /**
   * Default step visibility
   */
  get defaultVisibility(): boolean {
    return true;
  }
  /**
   * Is the step can be hidden (update isVisible).
   */
  abstract get canBeHidden(): boolean;

  // Config
  // --------

  /**
   * Default / initial config for this Step, for instance to initialize a form.
   */
  get defaultConfig(): TConfig {
    return null as TConfig;
  }

  /**
   * Validation schema for the `config` of this Step.
   *
   * @returns The schema
   * @default Schema that only accept `null` config
   */
  public configSchema(): SchemaOf<TConfig> {
    const schema = yup
      .mixed()
      .nullable()
      .default(null)
      .equals([null], '${path} must be null') as SchemaOf<TConfig>;

    return schema;
  }

  // Blocks
  // --------

  /**
   * Available blocks (field or layout) and can add blocks
   * Use boolean to disabled all or enable all
   * Otherwise for field, specify the mapping (Entity and type) available
   *           for layout, specify the widget types
   */
  public availableBlocks(
    clientPortalType?: ClientPortalTypeEnum,
  ): StepDefinitionAvailableBlocks {
    void clientPortalType;
    return {
      field: false,
      layout: false,
    };
  }
}
