import { Check } from 'lucide-react';

import {
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
  forwardRef,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { Icon } from '../../media-icon/icon/icon';

export type ComposedMenuItemProps = ChakraMenuItemProps & {
  isActive?: boolean;
  subText?: string;
};

export const ComposedMenuItem = forwardRef(
  (props: ComposedMenuItemProps, ref): JSX.Element => {
    const { isActive, subText, children, ...rest } = props;

    const bg = isActive ? 'blue.200' : undefined;
    const color = isActive ? 'black' : undefined;

    const lineHeight = subText ? '4' : 'inherit';

    return (
      <ChakraMenuItem
        bg={bg}
        color={color}
        lineHeight={lineHeight}
        {...rest}
        ref={ref}
      >
        {subText ? (
          <HStack alignItems="center" spacing="4">
            <VStack alignItems="start" spacing="1" maxW="300px">
              <Text as="span" noOfLines={1}>
                {children}
              </Text>
              <Text title={subText} as="span" noOfLines={1} color="gray.300">
                {subText}
              </Text>
            </VStack>
            {isActive && <Icon as={Check} color="blue.700" boxSize="1rem" />}
          </HStack>
        ) : (
          children
        )}
      </ChakraMenuItem>
    );
  },
);
