import { ViewColumnMappingTypeEnum } from '../../../shared/models';
import {
  CustomPropertiesByViewEntity,
  getCustomPropertyViewColumnAdapter,
} from './get-custom-property-view-column-adapter';
import {
  extractViewColumnMappingFromKey,
  generateViewColumnKeyFromMapping,
} from './mapping-key';
import { resolveViewDefinition } from './resolve-view-definition';
import { ViewColumn, ViewColumnMapping } from './types';

type ResolveDefinitionFromMappingParam = string | ViewColumnMapping;

export const resolveViewColumnDefinition = (
  mappingOrKey: ResolveDefinitionFromMappingParam,
  customProperties: CustomPropertiesByViewEntity,
): {
  columnName: string;
  definition: ViewColumn;
  mapping: ViewColumnMapping;
} => {
  const { entity, type, key } = resolveMappingFromMappingOrKey(mappingOrKey);
  const mapping = { entity, type, key };

  const customProperty = customProperties[entity]?.find(
    (customProperty) => customProperty.key === key,
  );
  if (type === ViewColumnMappingTypeEnum.custom && customProperty) {
    return {
      columnName: key,
      definition: getCustomPropertyViewColumnAdapter(customProperty),
      mapping,
    };
  } else if (
    !(
      [
        ViewColumnMappingTypeEnum.default,
        ViewColumnMappingTypeEnum.computed,
        ViewColumnMappingTypeEnum.relation,
        // @TODO - E-2270 - Add @total-typescript/ts-reset
        // `as` should not be necessary anymore
      ] as string[]
    ).includes(type)
  ) {
    throw new Error(
      `Cannot resolve view column for mapping of type ${type} for column ${generateViewColumnKeyFromMapping(mapping)}`,
    );
  }

  const viewColumnDefinition = resolveViewDefinition(entity);

  const entry = Object.entries(viewColumnDefinition).find(
    ([, definition]) =>
      definition.mapping.key === key && definition.mapping.type === type,
  );

  if (!entry) {
    throw new Error(
      `No view column for entity=${entity}, type=${type} and key=${key}`,
    );
  }

  return {
    columnName: entry[0],
    definition: entry[1],
    mapping: { entity, type, key },
  };
};

const resolveMappingFromMappingOrKey = (
  mapping: ResolveDefinitionFromMappingParam,
): ViewColumnMapping => {
  if (typeof mapping === 'string') {
    return extractViewColumnMappingFromKey(mapping);
  } else {
    return mapping;
  }
};
