import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Text } from '@dotfile/frontend/shared/design-system';
import {
  CheckResultEnum,
  DocumentAnalysisDetailedResultModel,
} from '@dotfile/shared/domain';

export const DataValidationLabel = ({
  detailedResult,
  informationDefinition,
}: {
  detailedResult: DocumentAnalysisDetailedResultModel;
  informationDefinition: { label: (t: TFunction) => string };
}) => {
  const { t } = useTranslation();

  const label = informationDefinition.label(t).toLowerCase();

  if (detailedResult.result === CheckResultEnum.approved) {
    return (
      <Text fontSize="sm" color="black">
        {t('document_analysis.data_validation_approved', {
          label,
          ns: 'components',
          defaultValue: `Valid {{label}} detected`,
        })}
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.rejected) {
    return (
      <Text fontSize="sm" color="black">
        {t('document_analysis.data_validation_rejected', {
          label,
          ns: 'components',
          defaultValue: `Invalid {{label}} detected`,
        })}
      </Text>
    );
  }

  if (detailedResult.result === CheckResultEnum.error) {
    return (
      <Text fontSize="sm" color="black">
        {t('document_analysis.data_validation_error', {
          label,
          ns: 'components',
          defaultValue: `No {{label}} detected`,
        })}
      </Text>
    );
  }

  return null;
};
