import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationIndividuals = (): JSX.Element => {
  const { stroke, skeleton } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="0 0 508 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M433 1C433 41.8691 400.317 75 360 75L360 1L433 1Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C441.186 75 507 140.814 507 222L360 222L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M63 75C63 34.1309 95.6832 1 136 1L136 75L63 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M286 75C286 156.186 218.843 222 136 222L136 75L286 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C360 34.1309 326.869 0.999999 286 0.999997L286 75L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
      </g>
      <g opacity="0.7" filter="url(#filter0_ddd_1624_81844)">
        <rect x="188" y="40" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M208.464 64.4645C209.402 63.5268 210.674 63 212 63H220C221.326 63 222.598 63.5268 223.535 64.4645C224.473 65.4021 225 66.6739 225 68V70C225 70.5523 224.552 71 224 71C223.448 71 223 70.5523 223 70V68C223 67.2044 222.684 66.4413 222.121 65.8787C221.559 65.3161 220.796 65 220 65H212C211.204 65 210.441 65.3161 209.879 65.8787C209.316 66.4413 209 67.2044 209 68V70C209 70.5523 208.552 71 208 71C207.448 71 207 70.5523 207 70V68C207 66.6739 207.527 65.4021 208.464 64.4645Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M216 53C214.343 53 213 54.3431 213 56C213 57.6569 214.343 59 216 59C217.657 59 219 57.6569 219 56C219 54.3431 217.657 53 216 53ZM211 56C211 53.2386 213.239 51 216 51C218.761 51 221 53.2386 221 56C221 58.7614 218.761 61 216 61C213.239 61 211 58.7614 211 56Z"
          fill={stroke}
        />
        <rect x="244" y="57" width="58" height="4" rx="2" fill={skeleton} />
        <rect x="318" y="57" width="74" height="4" rx="2" fill={skeleton} />
      </g>
      <g opacity="0.7" filter="url(#filter1_ddd_1624_81844)">
        <rect x="188" y="90" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M208.464 114.464C209.402 113.527 210.674 113 212 113H220C221.326 113 222.598 113.527 223.535 114.464C224.473 115.402 225 116.674 225 118V120C225 120.552 224.552 121 224 121C223.448 121 223 120.552 223 120V118C223 117.204 222.684 116.441 222.121 115.879C221.559 115.316 220.796 115 220 115H212C211.204 115 210.441 115.316 209.879 115.879C209.316 116.441 209 117.204 209 118V120C209 120.552 208.552 121 208 121C207.448 121 207 120.552 207 120V118C207 116.674 207.527 115.402 208.464 114.464Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M216 103C214.343 103 213 104.343 213 106C213 107.657 214.343 109 216 109C217.657 109 219 107.657 219 106C219 104.343 217.657 103 216 103ZM211 106C211 103.239 213.239 101 216 101C218.761 101 221 103.239 221 106C221 108.761 218.761 111 216 111C213.239 111 211 108.761 211 106Z"
          fill={stroke}
        />
        <rect x="244" y="107" width="40" height="4" rx="2" fill={skeleton} />
        <rect x="300" y="107" width="128" height="4" rx="2" fill={skeleton} />
      </g>
      <g opacity="0.7" filter="url(#filter2_ddd_1624_81844)">
        <rect x="188" y="140" width="256" height="42" rx="4" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M208.464 164.464C209.402 163.527 210.674 163 212 163H220C221.326 163 222.598 163.527 223.535 164.464C224.473 165.402 225 166.674 225 168V170C225 170.552 224.552 171 224 171C223.448 171 223 170.552 223 170V168C223 167.204 222.684 166.441 222.121 165.879C221.559 165.316 220.796 165 220 165H212C211.204 165 210.441 165.316 209.879 165.879C209.316 166.441 209 167.204 209 168V170C209 170.552 208.552 171 208 171C207.448 171 207 170.552 207 170V168C207 166.674 207.527 165.402 208.464 164.464Z"
          fill={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M216 153C214.343 153 213 154.343 213 156C213 157.657 214.343 159 216 159C217.657 159 219 157.657 219 156C219 154.343 217.657 153 216 153ZM211 156C211 153.239 213.239 151 216 151C218.761 151 221 153.239 221 156C221 158.761 218.761 161 216 161C213.239 161 211 158.761 211 156Z"
          fill={stroke}
        />
        <rect x="244" y="157" width="67" height="4" rx="2" fill={skeleton} />
        <rect x="327" y="157" width="74" height="4" rx="2" fill={skeleton} />
      </g>
      <defs>
        <filter
          id="filter0_ddd_1624_81844"
          x="0.399979"
          y="-101.4"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_1624_81844"
          x="0.399979"
          y="-51.4"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_ddd_1624_81844"
          x="0.399979"
          y="-1.40002"
          width="631.2"
          height="417.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1624_81844"
            result="effect2_dropShadow_1624_81844"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1624_81844"
            result="effect3_dropShadow_1624_81844"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1624_81844"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
