import {
  Center,
  chakra,
  Flex,
  usePrefersReducedMotion,
  useToken,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

const inner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const outer = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = (): JSX.Element => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const blue500 = useToken('colors', 'blue.500');

  const innerAnimation = prefersReducedMotion
    ? undefined
    : `${inner} infinite 2s ease-in-out`;
  const outerAnimation = prefersReducedMotion
    ? undefined
    : `${outer} infinite 2s ease-in-out`;

  return (
    <Flex
      position="relative"
      direction="column"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      width="100%"
      height="100%"
    >
      <chakra.svg
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        margin="auto"
        width="135"
        height="135"
        viewBox="0 0 135 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <chakra.path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.9241 44.4546V90.3028C44.2678 90.3028 34 80.0436 34 67.3787C34 54.7138 44.2678 44.4546 56.9241 44.4546Z"
          fill={blue500}
          animation={innerAnimation}
          transformOrigin="56.92px 67.5px"
        />
        <chakra.path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.772 67.3795C102.772 79.5373 97.9408 91.199 89.3483 99.7968C80.8315 108.308 69.3208 113.131 57.2913 113.228C57.0968 113.228 56.9238 113.066 56.9238 112.861V90.3036C69.5802 90.3036 79.8479 80.0444 79.8479 67.3795C79.8479 54.7146 69.5802 44.4554 56.9238 44.4554V21.898C56.9238 21.6931 57.086 21.5312 57.2913 21.5312C69.3208 21.6283 80.8315 26.4505 89.3483 34.9621C97.9516 43.56 102.772 55.2216 102.772 67.3795Z"
          fill={blue500}
          animation={outerAnimation}
          transformOrigin="56.92px 67.5px"
        />
      </chakra.svg>
    </Flex>
  );
};

export const AppLoader = (): JSX.Element => {
  // Similar to the app index.html layout before app startup
  return (
    <Center w="100%" h="100vh" color="white">
      <Loader />
    </Center>
  );
};
