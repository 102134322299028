import { camelCase, upperFirst } from 'lodash';
import { useMemo } from 'react';

import { forwardRef } from '@chakra-ui/react';

import { SkeletonCircle } from '../../feedback/skeleton/skeleton';
import { ALL_ICONS } from './all-icons';
import { Icon, IconProps } from './icon';

export type IconName = keyof typeof ALL_ICONS;

export type LucideIconProps = {
  name: IconName | string;
  isLoading?: boolean;
} & Omit<
  IconProps,
  // Types of property 'css' are incompatible.
  'css'
>;

export const LucideIcon = forwardRef(
  ({ name, isLoading = false, ...rest }: LucideIconProps, ref): JSX.Element => {
    const IconComponent = useMemo(() => {
      if (isLoading) {
        return null;
      }
      if (name in ALL_ICONS) {
        // Try with React component name like HelpCircle
        return ALL_ICONS[name as keyof typeof ALL_ICONS];
      }

      const toComponent = upperFirst(camelCase(name));
      if (toComponent in ALL_ICONS) {
        // Try with icon name like help-circle
        return ALL_ICONS[toComponent as keyof typeof ALL_ICONS];
      }

      // Fallback to this icon
      console.warn(
        `Could not find icon with name '${name}' or '${toComponent}'`,
      );
      return ALL_ICONS.HelpCircle;
    }, [isLoading, name]);

    return isLoading ? (
      <SkeletonCircle minH={rest.boxSize} minW={rest.boxSize} />
    ) : (
      // @ts-ignore Type of property 'as' is incompatible
      <Icon as={IconComponent} {...rest} ref={ref} />
    );
  },
);
