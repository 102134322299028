import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast, VStack } from '@dotfile/frontend/shared/design-system';

import { AppLoader, GenericErrorScreen } from '../../../shared';
import {
  useAutoSaveForLater,
  useCompleteForms,
  useLatestClientPortalVersionForms,
  useStepProgress,
  useStepProgressInit,
  useSubmitStepProgress,
} from '../shared';
import { useCaseDataInit } from '../shared/hooks/use-case-data-init';
import { useQueryParamInit } from '../shared/hooks/use-query-params-init';
import { useStepPreventSubmitLogic } from '../shared/logics';
import { CreateCaseScreen, Step } from './components';

export const FormsPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  // Init
  useQueryParamInit();
  const caseDataInit = useCaseDataInit();

  // Load Client portal version for Forms
  const latestClientPortalVersionFormsQuery =
    useLatestClientPortalVersionForms();

  const computePreventSubmitStep = useStepPreventSubmitLogic();
  const completeForms = useCompleteForms();
  const autoSaveForLater = useAutoSaveForLater();

  // Init progress
  useStepProgressInit();

  const handleSubmitStepProgress = useSubmitStepProgress();
  const { current, getNext } = useStepProgress();
  const handleSubmitStep = useCallback(async () => {
    // Compute logics to prevent step submission
    if (computePreventSubmitStep(current)) {
      toast({
        status: 'error',
        title: t('forms.prevent_submit.title', {
          defaultValue: 'Cannot continue to next step',
          ns: 'client-portal',
        }),
        description: t('forms.prevent_submit.description', {
          defaultValue: 'Information cannot let you continue to the next step.',
          ns: 'client-portal',
        }),
      });
      return;
    }

    // Get up to date next on submission
    // Step display logic are already re-computed with the form data submitted in the store
    const next = getNext();

    if (next) {
      // Here auto save when session is authenticated
      await autoSaveForLater.run(current);
      handleSubmitStepProgress(next);
    } else {
      completeForms.run();
    }
  }, [
    computePreventSubmitStep,
    current,
    getNext,
    handleSubmitStepProgress,
    autoSaveForLater,
    completeForms,
    toast,
    t,
  ]);

  const currentStep =
    latestClientPortalVersionFormsQuery.data?.latestClientPortalVersion.steps.find(
      (step) => step.key === current,
    );

  if (
    latestClientPortalVersionFormsQuery.loading ||
    !latestClientPortalVersionFormsQuery ||
    caseDataInit.loading ||
    currentStep === undefined
  ) {
    return <AppLoader />;
  }

  if (latestClientPortalVersionFormsQuery.error || completeForms.error) {
    return (
      <GenericErrorScreen
        error={latestClientPortalVersionFormsQuery.error || completeForms.error}
      />
    );
  }

  if (completeForms.loading) {
    return <CreateCaseScreen />;
  }

  return (
    <VStack alignItems="stretch" height="100%" width="full">
      <Step step={currentStep} onSubmitStep={handleSubmitStep} />
    </VStack>
  );
};
