import { AbilityBuilder, RawRuleOf } from '@casl/ability';

import { MemberRoleEnum, WorkspaceMemberModel } from '../../../shared/models';
import { AppAbility, AppAction } from './acl.type';

export type AclWorkspaceMember = Pick<
  WorkspaceMemberModel,
  'role' | 'userId' | 'workspaceId'
> &
  Partial<Pick<WorkspaceMemberModel, 'customRoleId'>>;

// @NOTE: this is a bit complex, we generate directly Ability for specific action update/delete
// maybe not the best way to handle ACL globally. Need to think of a global ACL setup.

export const workspaceMemberAbility = (
  userMember: AclWorkspaceMember,
  member: AclWorkspaceMember,
): AppAbility => {
  return new AppAbility([
    ...turnOwnerWorkspaceMemberRules(userMember, member),
    ...turnAdminWorkspaceMemberRules(userMember, member),
    ...turnMemberWorkspaceMemberRules(userMember, member),
    ...turnCustomRoleWorkspaceMemberRules(userMember, member),
    ...suspendMemberRules(userMember, member),
    ...activateMemberRules(userMember, member),
    ...disableWorkspaceMemberMfa(userMember, member),
  ]);
};

// Turn Owner -----
const turnOwnerWorkspaceMemberRules = (
  userMember: AclWorkspaceMember,
  member: AclWorkspaceMember,
): RawRuleOf<AppAbility>[] => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // isOwner
  if (userMember.role === MemberRoleEnum.owner) {
    // other -> can set to member, admin, owner
    can(AppAction.TurnOwner, 'WorkspaceMember');
  }

  // itself -> cannot
  if (_isItsefl(userMember, member)) {
    cannot(AppAction.TurnOwner, 'WorkspaceMember');
  }

  // Member or admin -> cannot
  if (userMember.role !== MemberRoleEnum.owner) {
    cannot(AppAction.TurnOwner, 'WorkspaceMember');
  }

  // Already owner
  if (member.role === MemberRoleEnum.owner) {
    cannot(AppAction.TurnOwner, 'WorkspaceMember');
  }

  // Cannot if not in same workspace
  if (userMember.workspaceId !== member.workspaceId) {
    // @SECURITY data isolation by workspace
    cannot(AppAction.TurnOwner, 'WorkspaceMember');
  }

  return rules;
};

// Turn Admin -----
const turnAdminWorkspaceMemberRules = (
  userMember: AclWorkspaceMember,
  member: AclWorkspaceMember,
): RawRuleOf<AppAbility>[] => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // Owner or admin
  if (
    ([MemberRoleEnum.owner, MemberRoleEnum.admin] as MemberRoleEnum[])
      // @TODO - E-2270 - Add @total-typescript/ts-reset
      // `as` should not be necessary anymore
      .includes(userMember.role)
  ) {
    // other -> can set to member, admin, owner
    can(AppAction.TurnAdmin, 'WorkspaceMember');
  }

  // itself -> cannot
  if (_isItsefl(userMember, member)) {
    cannot(AppAction.TurnAdmin, 'WorkspaceMember');
  }

  // Member -> cannot
  if (userMember.role === MemberRoleEnum.member) {
    cannot(AppAction.TurnAdmin, 'WorkspaceMember');
  }

  // Cannot turn owner Admin
  if (member.role === MemberRoleEnum.owner) {
    cannot(AppAction.TurnAdmin, 'WorkspaceMember');
  }

  // Already admin
  if (member.role === MemberRoleEnum.admin) {
    // can if custom role
    if (member.customRoleId) {
      can(AppAction.TurnAdmin, 'WorkspaceMember');
    }
    // cannot if already admin
    else {
      cannot(AppAction.TurnAdmin, 'WorkspaceMember');
    }
  }

  // Cannot if not in same workspace
  if (userMember.workspaceId !== member.workspaceId) {
    // @SECURITY data isolation by workspace
    cannot(AppAction.TurnAdmin, 'WorkspaceMember');
  }

  return rules;
};

// Turn Member -----
const turnMemberWorkspaceMemberRules = (
  userMember: AclWorkspaceMember,
  member: AclWorkspaceMember,
): RawRuleOf<AppAbility>[] => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // Owner or admin
  if (
    ([MemberRoleEnum.owner, MemberRoleEnum.admin] as MemberRoleEnum[])
      // @TODO - E-2270 - Add @total-typescript/ts-reset
      // `as` should not be necessary anymore
      .includes(userMember.role)
  ) {
    // other -> can set to member, admin, owner
    can(AppAction.TurnMember, 'WorkspaceMember');
  }

  // itself -> cannot
  if (_isItsefl(userMember, member)) {
    cannot(AppAction.TurnMember, 'WorkspaceMember');
  }

  // Member -> cannot
  if (userMember.role === MemberRoleEnum.member) {
    cannot(AppAction.TurnMember, 'WorkspaceMember');
  }

  // Already member
  if (member.role === MemberRoleEnum.member) {
    // can if custom role
    if (member.customRoleId) {
      can(AppAction.TurnMember, 'WorkspaceMember');
    }
    // cannot if already member
    else {
      cannot(AppAction.TurnMember, 'WorkspaceMember');
    }
  }

  // Cannot turn owner member
  if (member.role === MemberRoleEnum.owner) {
    cannot(AppAction.TurnMember, 'WorkspaceMember');
  }

  // Cannot if not in same workspace
  if (userMember.workspaceId !== member.workspaceId) {
    // @SECURITY data isolation by workspace
    cannot(AppAction.TurnMember, 'WorkspaceMember');
  }

  return rules;
};

// Turn CustomRole -----
const turnCustomRoleWorkspaceMemberRules = (
  userMember: AclWorkspaceMember,
  member: AclWorkspaceMember,
): RawRuleOf<AppAbility>[] => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // Owner or admin
  if (
    ([MemberRoleEnum.owner, MemberRoleEnum.admin] as MemberRoleEnum[])
      // @TODO - E-2270 - Add @total-typescript/ts-reset
      // `as` should not be necessary anymore
      .includes(userMember.role)
  ) {
    // other -> can set to member, admin, owner
    can(AppAction.TurnCustomRole, 'WorkspaceMember');
  }

  // itself -> cannot
  if (_isItsefl(userMember, member)) {
    cannot(AppAction.TurnCustomRole, 'WorkspaceMember');
  }

  // Member -> cannot
  if (userMember.role === MemberRoleEnum.member) {
    cannot(AppAction.TurnCustomRole, 'WorkspaceMember');
  }

  // Cannot turn owner member
  if (member.role === MemberRoleEnum.owner) {
    cannot(AppAction.TurnCustomRole, 'WorkspaceMember');
  }

  // Cannot if not in same workspace
  if (userMember.workspaceId !== member.workspaceId) {
    // @SECURITY data isolation by workspace
    cannot(AppAction.TurnCustomRole, 'WorkspaceMember');
  }

  return rules;
};

// Suspend member -----
const suspendMemberRules = (
  userMember: AclWorkspaceMember,
  member: AclWorkspaceMember,
): RawRuleOf<AppAbility>[] => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // isOwner
  if (userMember.role === MemberRoleEnum.owner) {
    if (_isItsefl(userMember, member)) {
      // itself -> cannot
      cannot(AppAction.SuspendMember, 'WorkspaceMember');
    } else {
      // other -> can suspend
      can(AppAction.SuspendMember, 'WorkspaceMember');
    }
  }

  // isAdmin
  if (userMember.role === MemberRoleEnum.admin) {
    // other -> can update member, admin
    if (member.role === MemberRoleEnum.owner) {
      cannot(AppAction.SuspendMember, 'WorkspaceMember');
    } else {
      can(AppAction.SuspendMember, 'WorkspaceMember');
    }
  }

  // isMember -> cannot
  if (userMember.role === MemberRoleEnum.member) {
    cannot(AppAction.SuspendMember, 'WorkspaceMember');
  }

  // Cannot if not in same workspace
  if (userMember.workspaceId !== member.workspaceId) {
    // @SECURITY data isolation by workspace
    cannot(AppAction.SuspendMember, 'WorkspaceMember');
  }

  return rules;
};

// Activate member -----
const activateMemberRules = (
  userMember: AclWorkspaceMember,
  member: AclWorkspaceMember,
): RawRuleOf<AppAbility>[] => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // isOwner
  if (userMember.role === MemberRoleEnum.owner) {
    if (_isItsefl(userMember, member)) {
      // itself -> cannot
      cannot(AppAction.ActivateMember, 'WorkspaceMember');
    } else {
      // other -> can activate
      can(AppAction.ActivateMember, 'WorkspaceMember');
    }
  }

  // isAdmin
  if (userMember.role === MemberRoleEnum.admin) {
    // other -> can update member, admin
    if (member.role === MemberRoleEnum.owner) {
      cannot(AppAction.ActivateMember, 'WorkspaceMember');
    } else {
      can(AppAction.ActivateMember, 'WorkspaceMember');
    }
  }

  // isMember -> cannot
  if (userMember.role === MemberRoleEnum.member) {
    cannot(AppAction.ActivateMember, 'WorkspaceMember');
  }

  // Cannot if not in same workspace
  if (userMember.workspaceId !== member.workspaceId) {
    // @SECURITY data isolation by workspace
    cannot(AppAction.ActivateMember, 'WorkspaceMember');
  }

  return rules;
};

// Disable workspace member MFA -----
const disableWorkspaceMemberMfa = (
  userMember: AclWorkspaceMember,
  member: AclWorkspaceMember,
): RawRuleOf<AppAbility>[] => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  // isOwner
  if (userMember.role === MemberRoleEnum.owner) {
    if (_isItsefl(userMember, member)) {
      // itself -> cannot
      cannot(AppAction.DisableWorkspaceMemberMfa, 'WorkspaceMember');
    } else {
      // other -> can remove
      can(AppAction.DisableWorkspaceMemberMfa, 'WorkspaceMember');
    }
  }

  // isAdmin
  if (userMember.role === MemberRoleEnum.admin) {
    // other -> can update member, admin
    if (member.role === MemberRoleEnum.owner || _isItsefl(userMember, member)) {
      cannot(AppAction.DisableWorkspaceMemberMfa, 'WorkspaceMember');
    } else {
      can(AppAction.DisableWorkspaceMemberMfa, 'WorkspaceMember');
    }
  }

  // isMember -> cannot
  if (userMember.role === MemberRoleEnum.member) {
    cannot(AppAction.DisableWorkspaceMemberMfa, 'WorkspaceMember');
  }

  // Cannot if not in same workspace
  if (userMember.workspaceId !== member.workspaceId) {
    // @SECURITY data isolation by workspace
    cannot(AppAction.DisableWorkspaceMemberMfa, 'WorkspaceMember');
  }

  return rules;
};

// Private helper
function _isItsefl(userMember: AclWorkspaceMember, member: AclWorkspaceMember) {
  return userMember.userId === member.userId;
}
