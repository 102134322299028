import React, { FC } from 'react';

import {
  QueryCache,
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { logAndAddError } from '../datadog';
import { nonceId } from '../security';

type CreateReactQueryResult = {
  queryClient: QueryClient;
  QueryClientProvider: FC<{ children: React.ReactElement }>;
};

export function createReactQuery({
  devtools,
}: {
  devtools?: boolean;
}): CreateReactQueryResult {
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => logAndAddError(error, { query }),
    }),
    defaultOptions: {
      queries: {},
      mutations: {
        onError: (error, variables, context) =>
          logAndAddError(error, { variables, context }),
      },
    },
  });

  const QueryClientProvider: FC<{ children: React.ReactElement }> = ({
    children,
  }) => (
    <ReactQueryClientProvider client={queryClient}>
      <>
        {children}
        {devtools && (
          <ReactQueryDevtools initialIsOpen={false} styleNonce={nonceId} />
        )}
      </>
    </ReactQueryClientProvider>
  );

  return { queryClient, QueryClientProvider };
}
