import { forwardRef, useCallback } from 'react';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Container } from './styles';
import { PhoneNumberInputProps } from './types';

export const PhoneNumberInput = forwardRef(function PhoneNumberInputWithRef(
  {
    onChange,
    value,
    isReadOnly = false,
    defaultCountryCode = 'FR',
    isInvalid = false,
  }: PhoneNumberInputProps,
  ref,
): JSX.Element {
  const [phoneNumber, setState] = useState('');
  const handleOnChange = useCallback(
    (phoneNumber: string) => {
      onChange && onChange(`+${phoneNumber}`);
      setState(phoneNumber);
    },
    [onChange],
  );

  return (
    <Container
      className={isReadOnly ? 'readOnly' : ''}
      data-testid="phone-number-input"
    >
      <PhoneInput
        data-testid="input"
        country={
          // We must force the type because countryCode is type as a string in 'react-phone-input-2'
          defaultCountryCode && defaultCountryCode.toLowerCase().toString()
        }
        inputProps={ref ? { ref } : undefined}
        dropdownClass="dropDown"
        inputClass={`input ${isInvalid ? 'invalid' : ''}`}
        buttonClass="button"
        value={value ?? phoneNumber}
        enableLongNumbers
        onChange={(phoneNumber, country) => {
          if ('countryCode' in country) {
            handleOnChange(phoneNumber);
          }
        }}
      />
    </Container>
  );
});
