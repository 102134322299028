import {
  ANALYTICS_DASHBOARD_BASE_URL,
  DotfileStageEnum,
} from '@dotfile/shared/domain';

import { CSPHeadersProps } from './csp-headers';

/**
 * Generate the content of a meta tag for Content-Security-Policy
 *
 * @see CSPHeaders for the documentation of the policies
 */
export const generateCspContent = ({
  environment,
  nonce,
  extraConnectSrc,
  extraScriptSrc,
  extraStyleSrc,
  allowBlob,
  allowKnock,
  allowDatadogRUM,
  allowGoogle,
  allowMetabase,
  allowUbble,
  allowMonacoEditor,
  allowParagon,
  allowApproximated,
  allowHyperline,
  allowVeriff,
  allowOnfido,
}: CSPHeadersProps): string => {
  const cspPolicies = {
    'default-src': [allowVeriff && "'self' *.veriff.me *.veriff.com"],
    'img-src': [
      allowVeriff && "'self' *.probity.io",
      environment.baseApp,
      environment.baseMainStorage,
      'blob:', // File uploaded in console such as workspace logo, user avatar
      'data:',
    ],
    'child-src': ["'self'", environment.baseApp, allowBlob && 'blob:'],

    'frame-src': [
      environment.baseMainStorage,
      allowMetabase && ANALYTICS_DASHBOARD_BASE_URL,
      allowUbble && 'https://id.ubble.ai',
      allowParagon && 'https://connect.useparagon.com',
      allowHyperline &&
        'https://sandbox.app.hyperline.co https://app.hyperline.co https://billing.dotfile.com',
      allowVeriff && `'self' *.veriff.me *.veriff.com *.hotjar.com`,
      allowOnfido && 'https://sdk.onfido.com',
      environment.baseApp,
    ],

    'worker-src': ["'self'", environment.baseApp, allowBlob && 'blob:'],

    'connect-src': [
      "'self'",
      environment.baseApp, // always allow base app for local dev when opening the app on localhost
      environment.baseAPI,
      environment.analyticsDataPlane,
      allowKnock && 'https://api.knock.app wss://api.knock.app',
      allowParagon && 'https://zeus.useparagon.com',
      allowDatadogRUM &&
        'https://browser-intake-datadoghq.eu https://sessions.bugsnag.com',
      allowApproximated && 'https://cloud.approximated.app', // Instructions for custom domain config
      allowVeriff && `*.veriff.me *.veriff.com *.probity.io`,
      allowOnfido &&
        '*.onfido.com onfido.com https://sdk.onfido.com wss://*.onfido.com',
      environment.baseMainStorage,
      extraConnectSrc,
    ],

    'script-src': [
      `'self'`,
      `'nonce-${nonce}'`,
      // @WARNING incompatibility with strict-dynamic and Onfido/Checkout SDK and MonacoEditor
      // With strict-dynamic policies on URL are disabled
      allowMonacoEditor && "'strict-dynamic'",

      'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js',
      allowGoogle && 'https://apis.google.com/',
      allowVeriff &&
        `*.veriff.me *.veriff.com *.hotjar.com *.probity.io *.sentry.io`,
      allowOnfido && 'https://sdk.onfido.com https://assets.onfido.com',
      allowUbble && '*.ubble.ai',
      environment.baseApp,
      extraScriptSrc,
      "'unsafe-inline'",
    ],

    'style-src': [
      ...(allowMonacoEditor
        ? [
            'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs/editor/editor.main.css',
            // @TODO - E-4270 - monaco-editor - CSP style-src must be 'unsafe-inline'
            "'unsafe-inline'",
          ]
        : [
            // Some browser (like Firefox) log a warning if nonce/sha are used with 'unsafe-inline' but
            // other browser (like Chrome) disabled 'unsafe-inline' if there is a nonce/sha
            `'nonce-${nonce}'`,
            "'sha256-Q61bDQPgXhXJM0KRBMX+u6YXfcEtPAxqo0SHvNG3uoU='",
            "'sha256-deDIoPlRijnpfbTDYsK+8JmDfUBmpwpnb0L/SUV8NeU='",
            "'sha256-LA4KTjHIvt/e0fK4wBIK0x4Rx0vUv3/rVZ6n+vpT+GM='",
            "'sha256-d8+FRLATLC2M5M7tg4DHE7TNYGW0kr6ijwsDc7+G6DI='",
            "'sha256-GNF74DLkXb0fH3ILHgILFjk1ozCF3SNXQ5mQb7WLu/Y='",
          ]),
      allowVeriff && `'self' *.veriff.com *.veriff.me`,
      allowOnfido && 'https://sdk.onfido.com',
      environment.baseApp,
      environment.stage === DotfileStageEnum.dev &&
        `${environment.baseApp}/main.css`,
      extraStyleSrc,
    ],

    'font-src': [
      "'self'",
      environment.baseApp,
      allowMonacoEditor &&
        'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs/base/browser/ui/codicons/codicon/codicon.ttf',
      allowOnfido && 'https://sdk.onfido.com',
    ],

    'base-uri': ["'none'"],

    'object-src': [environment.baseMainStorage],

    'media-src': ["'self'", 'data:'],
  };

  return `${Object.entries(cspPolicies)
    .map(([policy, values]) => `${policy} ${values.filter(Boolean).join(' ')}`)
    .join('; ')}; `;
};
