import { HStack } from '@dotfile/frontend/shared/design-system';
import { FileFragment } from '@dotfile/shared/data-access-client-portal';

import { DownloadButton } from '../../shared';

type DownloadFilesProps = {
  frontFile?: FileFragment | null;
  backFile?: FileFragment | null;
};

export const DownloadFiles = ({ frontFile, backFile }: DownloadFilesProps) => {
  if (!frontFile) {
    // Back is always optional
    return null;
  }

  return (
    <HStack spacing="4" flexWrap="wrap">
      {frontFile && <DownloadButton file={frontFile} />}

      {backFile && <DownloadButton file={backFile} />}
    </HStack>
  );
};
