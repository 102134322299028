import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleObject } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/menu.ts
// for default ChakraUI theme

const baseStyle: PartsStyleObject<typeof parts> = {
  button: {
    fontSize: 'md',
    _expanded: {
      background: 'whiteAlpha.300',
    },
    _hover: {
      background: 'whiteAlpha.300',
    },
  },
  list: {
    py: '1',
    p: '1',
    // Usually on top or closed
    zIndex: 10,
    borderRadius: 'base',
  },
  item: {
    my: '2px',
    px: '1rem',
    py: '0.5rem',
    color: 'gray.600',
    alignItems: 'end',
    borderRadius: 'base',
    fontSize: 'md',
    _hover: {
      bg: 'gray.100',
      color: 'black',
    },
    _active: {
      color: 'black',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },
  groupTitle: {
    color: 'gray.500',
    fontWeight: 'medium',
  },
};

export const MenuTheme: ComponentStyleConfig = {
  baseStyle,
};
