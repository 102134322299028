import { Country } from '@dotfile/frontend/shared/design-system';
import { Text } from '@dotfile/frontend/shared/design-system';
import { AddressModel } from '@dotfile/shared/domain';

import { EmptyData } from './empty-data';

type AddressDisplayProps = {
  address: Partial<AddressModel> | null;
};

const isEmptyAddress = (address: Partial<AddressModel>): boolean =>
  Object.entries(address).filter(
    ([key, values]) =>
      !!values &&
      // Apollo client automatically query the field `__typename`
      key !== '__typename',
  ).length === 0;

export const AddressDisplay = ({ address }: AddressDisplayProps) => {
  if (!address || isEmptyAddress(address)) {
    return <EmptyData />;
  }

  return (
    <Text
      color="black"
      sx={{
        lineBreak: 'anywhere',
      }}
    >
      <>
        {address.streetAddress && (
          <>
            {address.streetAddress}
            <br />
          </>
        )}
        {address.streetAddress2 && (
          <>
            {address.streetAddress2}
            <br />
          </>
        )}
        {(address.postalCode || address.city) && (
          <>
            {address.postalCode} {address.city}
            <br />
          </>
        )}
        {address.state && (
          <>
            {address.state}
            <br />
          </>
        )}
        {address.region && (
          <>
            {address.region}
            <br />
          </>
        )}
        {address.country && <Country code={address.country} />}
      </>
    </Text>
  );
};
