import { CaseStatusEnum } from '../../../../shared/models';

export type CaseStatusDefinition = {
  value: CaseStatusEnum;
  label: string;
  colorScheme: string;
  icon:
    | 'PencilLine'
    | 'FolderOpenIcon'
    | 'FolderCheckIcon'
    | 'FolderXIcon'
    | 'FolderClosedIcon';
  optionDescription?: string;
};

const draft: CaseStatusDefinition = {
  value: CaseStatusEnum.draft,
  label: 'Draft',
  colorScheme: 'gray',
  icon: 'PencilLine',
  optionDescription: 'User will access the form to fill information',
};

const open: CaseStatusDefinition = {
  value: CaseStatusEnum.open,
  label: 'Open',
  colorScheme: 'orange',
  icon: 'FolderOpenIcon',
  optionDescription: 'User will access the checks to submit documents',
};

const approved: CaseStatusDefinition = {
  value: CaseStatusEnum.approved,
  label: 'Approved',
  colorScheme: 'green',
  icon: 'FolderCheckIcon',
};

const rejected: CaseStatusDefinition = {
  value: CaseStatusEnum.rejected,
  label: 'Rejected',
  colorScheme: 'red',
  icon: 'FolderXIcon',
};

const closed: CaseStatusDefinition = {
  value: CaseStatusEnum.closed,
  label: 'Closed',
  colorScheme: 'gray',
  icon: 'FolderClosedIcon',
};

export const caseStatusDefinition: Record<
  CaseStatusEnum,
  CaseStatusDefinition
> = {
  draft,
  open,
  approved,
  rejected,
  closed,
} as const;
