import { TFunction } from 'i18next';

import { CheckResultEnum } from '../../../../shared/models';

export const documentAnalysisDetailedResultsDefinition: Record<
  CheckResultEnum,
  { label: (t: TFunction) => string; color: string; iconName: string }
> = {
  [CheckResultEnum.approved]: {
    label: (t: TFunction) =>
      t('check.result.approved', { ns: 'domain', defaultValue: 'Approved' }),
    color: 'green.700',
    iconName: 'check-circle-2',
  },
  [CheckResultEnum.error]: {
    label: (t: TFunction) =>
      t('check.result.error', { ns: 'domain', defaultValue: 'Error' }),
    color: 'gray.700',
    iconName: 'circle-slash-2',
  },
  [CheckResultEnum.rejected]: {
    label: (t: TFunction) =>
      t('check.result.rejected', { ns: 'domain', defaultValue: 'Rejected' }),
    color: 'red.700',
    iconName: 'x-circle',
  },
};
