import {
  Avatar as ChakraAvatar,
  AvatarGroup as ChakraAvatarGroup,
  AvatarGroupProps as ChakraAvatarGroupProps,
  AvatarProps as ChakraAvatarProps,
} from '@chakra-ui/react';

export type AvatarProps = ChakraAvatarProps;
export const Avatar = ChakraAvatar;

export type AvatarGroupProps = ChakraAvatarGroupProps;
export const AvatarGroup = ChakraAvatarGroup;
