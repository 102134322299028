import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  Checkbox,
  RequiredIndicatorText,
  YesNoButton,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalBlockFieldDisplayEnum } from '@dotfile/shared/data-access-client-portal';
import { PropertyTypeEnum } from '@dotfile/shared/domain';

import { FieldProps } from './types';

export const FieldBoolean = ({
  field,
  property,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.boolean>) => {
  const { control, setValue, getValues } = useFormContext();
  const passedProps = { ...groupControlProps };

  useEffect(() => {
    if (!groupControlProps.isRequired) return;
    if (field.display !== ClientPortalBlockFieldDisplayEnum.checkbox_radio)
      return;

    // We set the default value to null to handle the case where the field is required
    // but was set to false due to a changed display option (was yes no, user clicked false, now checkbox)
    // (trigger validation error)
    const value = getValues(groupControlProps.name);
    if (value !== false) return;

    setValue(groupControlProps.name, null);
  }, [
    field.display,
    getValues,
    groupControlProps.isRequired,
    groupControlProps.name,
    setValue,
  ]);

  if (field.display === ClientPortalBlockFieldDisplayEnum.checkbox_radio) {
    // When the display is set to checkbox, we use the field label as the checkbox label.
    // So we delete it to avoid passing it to the GroupController
    delete passedProps.label;
  }

  const handleChange = useCallback(
    (newValue: boolean) => {
      setValue(groupControlProps.name, newValue, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    },
    [groupControlProps.name, setValue],
  );

  return (
    <GroupController
      control={control}
      render={({ value, isInvalid, ...f }) => {
        if (field.display === ClientPortalBlockFieldDisplayEnum.yes_no)
          return (
            <YesNoButton {...f} defaultValue={value} onChange={handleChange} />
          );

        return (
          <Checkbox
            {...f}
            variant="atTop"
            isChecked={value ?? false}
            onChange={(e) => {
              let nextValue: boolean | null = e.target.checked;
              // We set the value to null when the checkbox is unchecked
              // To handle the case where the field is required (trigger validation error)
              if (groupControlProps.isRequired && !nextValue) nextValue = null;

              f.onChange(nextValue);
            }}
          >
            {groupControlProps.label}{' '}
            {groupControlProps.isRequired && <RequiredIndicatorText />}
          </Checkbox>
        );
      }}
      {...passedProps}
      {...wrapperProps}
    />
  );
};
