import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type ReleaseInfo,
  useReleaseInfo,
} from '@dotfile/frontend/shared/common';
import { useToast } from '@dotfile/frontend/shared/design-system';

import { NewVersionCheckerAlert } from '../components/alert';

const CHECK_PERIOD = 2 * 60 * 1000; //  2 * 60 * 1000 2min

export const useNewVersionChecker = (
  {
    noDotfileMention = false,
  }: {
    noDotfileMention?: boolean;
  } = {
    noDotfileMention: false,
  },
) => {
  const { t } = useTranslation();

  const toast = useToast();
  const [releaseInfo, setReleaseInfo] = useState<ReleaseInfo | null>(null);
  const { data, isFetched } = useReleaseInfo({ refetchInterval: CHECK_PERIOD });

  useEffect(() => {
    if (data && isFetched) {
      // Set releaseInfo initial state
      if (!releaseInfo) {
        setReleaseInfo(data);
        return;
      }

      // If different commit detected, then display toast
      if (releaseInfo.commit !== data.commit) {
        toast({
          id: 1,
          title: `${t('version_checker.title', {
            ns: 'components',
            defaultValue: 'New version is available',
            context: noDotfileMention ? undefined : 'dotfile',
          })}. ${t('version_checker.refresh_action', {
            ns: 'components',
            defaultValue: 'Click to refresh',
          })}`,
          status: 'info',
          position: 'top',
          duration: null,
          isClosable: false,
          render: () => (
            <NewVersionCheckerAlert noDotfileMention={noDotfileMention} />
          ),
        });
      }
    }
  }, [data, isFetched, noDotfileMention, releaseInfo, t, toast]);
};
