import { AlertCircleIcon, EditIcon, TrashIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Country,
  forwardRef,
  HStack,
  Icon,
  Stack,
  StackProps,
  Text,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { FormDatastoreCompany } from '../shared';

export type AffiliatedCompanyItemProps = {
  index: number;
  affiliatedCompany: FormDatastoreCompany;
  hasMissingInformation: boolean;
  onEdit: () => void;
  onRemove: () => void;
} & StackProps;

export const AffiliatedCompanyItem = forwardRef(
  (
    {
      hasMissingInformation,
      index,
      affiliatedCompany,
      onEdit,
      onRemove,
      ...stackProps
    }: AffiliatedCompanyItemProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen();

    return (
      <Stack
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems={isSmallScreen ? 'stretch' : 'center'}
        justifyContent="space-between"
        p="4"
        spacing="4"
        borderRadius="lg"
        borderWidth="1px"
        borderColor="gray.200"
        ref={ref}
        {...stackProps}
      >
        <VStack alignItems="start">
          <HStack>
            <Text color="black">{affiliatedCompany.name ?? '—'}</Text>
            {hasMissingInformation && (
              <>
                <Icon color="orange.700" as={AlertCircleIcon} />
                <Text color="orange.700">
                  {t('forms.missing_information', {
                    ns: 'client-portal',
                    defaultValue: 'Missing information',
                  })}
                </Text>
              </>
            )}
          </HStack>
          <HStack>
            <Text fontSize="sm">
              {affiliatedCompany.registrationNumber ?? '—'}
            </Text>
            {affiliatedCompany.country && (
              <Country code={affiliatedCompany.country} />
            )}
          </HStack>
        </VStack>

        <HStack spacing="4">
          <Button
            leftIcon={<Icon as={EditIcon} />}
            colorScheme="coal"
            variant="ghost"
            onClick={onEdit}
          >
            {t('common.edit', {
              ns: 'client-portal',
              defaultValue: 'Edit',
            })}
          </Button>

          <Button
            leftIcon={<Icon as={TrashIcon} />}
            colorScheme="red"
            variant="ghost"
            onClick={onRemove}
          >
            {t('common.remove', {
              ns: 'client-portal',
              defaultValue: 'Remove',
            })}
          </Button>
        </HStack>
      </Stack>
    );
  },
);
