import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleObject } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ComponentStyleConfig } from '@chakra-ui/theme';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/modal.ts
// for default ChakraUI theme
// Modal theme is also used for AlertDialog

const baseStyleOverlay: SystemStyleObject = {
  backdropFilter: 'blur(3px)',
};

const baseStyleDialog: SystemStyleObject = {
  maxHeight: '95vh',
};

const baseStyleBody: SystemStyleObject = {
  overflow: 'auto',
};

const baseStyleHeader: SystemStyleObject = {
  textAlign: 'center',
  color: 'white',
  fontWeight: '500',
  backgroundColor: 'primary',
  padding: 4,
  borderTopRadius: 'md',
};

const baseStyleFooter: SystemStyleObject = {
  borderTop: '1px solid',
  borderColor: 'gray.100',
  justifyContent: 'center',
};

const baseStyle: PartsStyleObject<typeof parts> = {
  overlay: baseStyleOverlay,
  dialog: baseStyleDialog,
  body: baseStyleBody,
  header: baseStyleHeader,
  footer: baseStyleFooter,
};

const variantDialog: PartsStyleObject<typeof parts> = {
  dialog: {
    padding: 0,
  },
  header: {
    backgroundColor: 'gray.800',
  },
  closeButton: { top: 3, right: 4, color: 'gray.200' },
  body: {
    padding: 6,
    textAlign: 'center',
    alignItems: 'center',
  },
  footer: {},
};

const variantOverview: PartsStyleObject<typeof parts> = {
  dialog: {
    padding: 0,
  },
  header: {
    color: 'primary',
    backgroundColor: 'white',
    textAlign: 'start',
    borderBottomWidth: '1px',
    paddingInline: 4,
    paddingBlock: 4,
  },
  closeButton: { top: 3, right: 4, color: 'primary' },
  body: { padding: 'unset' },
  footer: { paddingInline: 4, paddingBlock: 4 },
};

export const ModalTheme: ComponentStyleConfig = {
  baseStyle,
  variants: {
    // @TODO - E-3622 - Design system: Fix modal theme (dialog-white)
    dialog: variantDialog,
    overview: variantOverview,
  },
  sizes: {
    full: {
      header: {
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
      },
      // this style on dialog and body make sure the modal body
      // doesn't trigger a full page scroll and the footer is always
      // visible
      dialog: {
        minHeight: 'unset',
        maxHeight: 'unset',
        height: 'calc(100% - 32px)',
        width: 'calc(100% - 32px)',
        borderRadius: 'lg',
      },
      body: { minHeight: 0 },
    },
  },

  defaultProps: { variant: 'dialog' },
};
