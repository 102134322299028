import { useTranslation } from 'react-i18next';

import {
  Heading,
  HStack,
  StackProps,
  useIsMobile,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalChecks_CheckInterface } from '@dotfile/shared/data-access-client-portal';

import { formatCheckTitle, getCheckTranslationKey } from '../utils';
import { CheckStatusBadge } from './check-status-badge';

type CheckHeadingProps = {
  check: ClientPortalChecks_CheckInterface;
} & StackProps;

export const CheckHeading = ({ check, ...props }: CheckHeadingProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const checkLabelTranslationKey = getCheckTranslationKey(check);

  return (
    <HStack
      {...props}
      gap="12px"
      justifyContent={isMobile ? 'space-between' : 'flex-start'}
    >
      <Heading size="sm" color="black" lineHeight="32px">
        {t(`${checkLabelTranslationKey}.label`, {
          ns: 'dynamic',
          defaultValue: formatCheckTitle(check, t),
        })}
      </Heading>
      <CheckStatusBadge check={check} />
    </HStack>
  );
};
