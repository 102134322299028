import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuListProps,
} from '../../overlay/menu/menu';

type DropDownDatePickerProps = Omit<MenuListProps, 'onChange'> & {
  options: number[];
  value: number;
  onChange: (value: number) => void;
  format?: (value: number) => string;
};

export const DropDownDatePicker = ({
  options,
  value,
  onChange,
  format,
  ...menuListProps
}: DropDownDatePickerProps): JSX.Element => {
  return (
    <Menu>
      <MenuButton fontWeight="500" fontFamily="Inter" width="auto">
        {format ? format(value) : value}
      </MenuButton>

      <MenuList h="200px" minW="auto" overflow="auto" {...menuListProps}>
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            fontSize="xs"
            onClick={() => {
              onChange(option);
            }}
          >
            {format ? format(option) : option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
