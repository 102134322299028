import { ReactNode } from 'react';

import {
  HStack,
  useIsMobile,
  VStack,
} from '@dotfile/frontend/shared/design-system';

export const CheckCard = ({
  heading,
  action,
  description = null,
  review,
}: {
  heading: ReactNode;
  description?: ReactNode | null;
  action: ReactNode;
  review: ReactNode;
}) => {
  const isMobile = useIsMobile();
  return (
    <VStack
      minH="8"
      spacing="4"
      justifyContent="center"
      align="stretch"
      width="full"
    >
      {isMobile ? (
        <VStack spacing="2" justify="unset" width="full" align="stretch">
          {heading}
          {description}
          {action}
        </VStack>
      ) : (
        <HStack align="flex-start" justifyContent="space-between" width="full">
          <VStack spacing="2" maxWidth="70%" align="stretch">
            {heading}
            {description}
          </VStack>
          {action}
        </HStack>
      )}

      {review}
    </VStack>
  );
};
