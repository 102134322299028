import { PropsWithChildren } from 'react';

import { useIllustrationTokens } from './tokens';

export const BaseEmptyStateIllustrationIcon = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const { stroke, background, skeleton } = useIllustrationTokens();

  return (
    <svg
      width="508"
      height="222"
      viewBox="0 0 508 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M433 1C433 41.8691 400.317 75 360 75L360 1L433 1Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C441.186 75 507 140.814 507 222L360 222L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M63 75C63 34.1309 95.6832 1 136 1L136 75L63 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M286 75C286 156.186 218.843 222 136 222L136 75L286 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M360 75C360 34.1309 326.869 0.999999 286 0.999997L286 75L360 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
      </g>
      <g opacity="0.7" filter="url(#filter0_ddd_1148_44015)">
        <rect x="188" y="32" width="256" height="162" rx="4" fill="white" />
        <rect x="204" y="41" width="32" height="32" rx="16" fill={background} />
        {children}
        <rect x="252" y="49" width="100" height="8" rx="4" fill={skeleton} />
        <rect x="252" y="73" width="126" height="4" rx="2" fill={skeleton} />
        <rect x="252" y="93" width="74" height="4" rx="2" fill={skeleton} />
        <rect x="252" y="113" width="126" height="4" rx="2" fill={skeleton} />
        <rect x="252" y="133" width="74" height="4" rx="2" fill={skeleton} />
        <rect x="252" y="153" width="126" height="4" rx="2" fill={skeleton} />
        <rect x="252" y="173" width="74" height="4" rx="2" fill={skeleton} />
      </g>
      <defs>
        <filter
          id="filter0_ddd_1148_44015"
          x="0.399979"
          y="-109.4"
          width="631.2"
          height="537.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1148_44015"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1148_44015"
            result="effect2_dropShadow_1148_44015"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1148_44015"
            result="effect3_dropShadow_1148_44015"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_1148_44015"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
