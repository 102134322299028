import { useTranslation } from 'react-i18next';

import {
  Heading,
  HStack,
  Spacer,
  Text,
  useIsMobile,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { ClientPortalCollaborationEnum } from '@dotfile/shared/data-access-client-portal';

import { useLatestClientPortalVersion } from '../../../../shared';
import { ShareAccessButtonModal } from '../../collaboration/';
import { PendingActionBadge } from './pending-action-badge';

export const ChecksHeader = () => {
  const { t } = useTranslation();
  const { data } = useLatestClientPortalVersion();
  const isMobile = useIsMobile();

  const canCollaborate =
    data?.latestClientPortalVersion.setting.collaboration !==
    ClientPortalCollaborationEnum.none;

  return (
    <VStack spacing="6" width="full" align="stretch">
      <HStack
        justifyContent="space-between"
        spacing="4"
        width="full"
        flexWrap="wrap"
      >
        <Heading>
          {t(`checks.header.title`, {
            ns: 'client-portal',
            defaultValue: 'Client Portal',
          })}
        </Heading>
        {!isMobile && canCollaborate && <ShareAccessButtonModal />}
        {!isMobile && <Spacer />}
        <PendingActionBadge />
        {isMobile && canCollaborate && <ShareAccessButtonModal />}
      </HStack>

      <Text>
        {t(`checks.header.description`, {
          ns: 'client-portal',
          defaultValue:
            'Please provide the following information and documents. Your progress is automatically saved, so you can leave and come back anytime.',
        })}
      </Text>
    </VStack>
  );
};
