import { ClientPortalChecks_DocumentCheck } from '@dotfile/shared/data-access-client-portal';

import {
  CheckCard,
  CheckDescription,
  CheckHeading,
  CheckReview,
  UploadButton,
} from '../shared';
import { useCheckModalWithQueryParam } from '../shared/hooks/use-modal-with-query-param';

export const DocumentCheck = ({
  check,
}: {
  check: ClientPortalChecks_DocumentCheck;
}) => {
  const { onOpen } = useCheckModalWithQueryParam(check.id);

  return (
    <CheckCard
      heading={<CheckHeading check={check} />}
      description={<CheckDescription check={check} />}
      action={<UploadButton onClick={onOpen} check={check} />}
      review={<CheckReview check={check} />}
    />
  );
};
