import { cloneElement, ForwardedRef, forwardRef, useCallback } from 'react';
import { apiObject } from 'rudder-sdk-js';

import { EventName } from '../events';
import { useAnalyticsTrack } from '../hooks';

export type TrackClickProps<
  E extends EventName,
  P extends apiObject,
  T extends JSX.Element = JSX.Element,
> = {
  children: T;
  event: E;
  payload: P;
  // Also track auxClick as click (useful on middle click)
  excludeAuxClick?: boolean;
};
export const TrackClick = forwardRef(
  <E extends EventName, P extends apiObject, T extends JSX.Element>(
    {
      event,
      payload,
      excludeAuxClick,
      children,
      ...props
    }: TrackClickProps<E, P, T>,
    ref: ForwardedRef<T>,
  ) => {
    const _track = useAnalyticsTrack();

    const trackClick = useCallback(() => {
      _track(event, payload);
    }, [event, payload, _track]);

    return (
      <>
        {cloneElement(children, {
          ...props,
          onAuxClick: excludeAuxClick
            ? undefined
            : (...args: unknown[]) => {
                children.props?.onAuxClick?.apply?.(null, args);
                trackClick();
              },
          onClick: (...args: unknown[]) => {
            children.props?.onClick?.apply?.(null, args);
            trackClick();
          },
          ref,
        })}
      </>
    );
  },
);

TrackClick.displayName = 'TrackClick';
