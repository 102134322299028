import { TFunction } from 'i18next';
import { SchemaOf } from 'yup';

import {
  CompanyModel,
  DocumentAnalysisModelEnum,
  IndividualModel,
} from '../../../../shared/models';

export type DocumentAnalysisDefinitionParameters<
  TParameters extends { model: DocumentAnalysisModelEnum },
> = Record<keyof TParameters, { label: string }>;

export type DocumentAnalysisDefinitionDetailedResults<
  TDetailedResults extends { model: DocumentAnalysisModelEnum },
> = Record<keyof Omit<TDetailedResults, 'model'>, { label: string }>;

export type DocumentAnalysisDefinitionInformation<
  TInformation extends { model: DocumentAnalysisModelEnum },
> = Record<
  keyof Omit<TInformation, 'model'>,
  {
    label: (t: TFunction) => string;
    format?: (value: string, t: TFunction) => string;
  }
>;

type RequiredCompanyField = Pick<
  CompanyModel,
  'address' | 'name' | 'bankingInformation' | 'registrationNumber'
>;

type RequiredIndividualField = Pick<
  IndividualModel,
  'address' | 'bankingInformation' | 'firstName' | 'lastName' | 'birthDate'
>;

export type DocumentAnalysisEntity =
  | {
      company: RequiredCompanyField;
      individual?: never;
    }
  | {
      company?: never;
      individual: RequiredIndividualField;
    };

export abstract class DocumentAnalysisDefinition<
  TParameters extends { model: DocumentAnalysisModelEnum },
  TDetailedResults extends { model: DocumentAnalysisModelEnum },
  TInformation extends { model: DocumentAnalysisModelEnum },
> {
  abstract get model(): DocumentAnalysisModelEnum;

  abstract get label(): string;

  abstract get parameters(): DocumentAnalysisDefinitionParameters<TParameters>;

  abstract get detailedResults(): DocumentAnalysisDefinitionDetailedResults<TDetailedResults>;

  abstract get information(): DocumentAnalysisDefinitionInformation<TInformation>;

  abstract get defaultParameters(): Omit<TParameters, 'model'>;

  abstract get validationSchemas(): {
    parameters?: SchemaOf<TParameters>;
  };
}
