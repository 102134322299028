import { SchemaOf } from 'yup';

import {
  CheckTypeEnum,
  EntityTypeEnum,
  IdVerificationDataModel,
  PermissionResourceEnum,
  VendorEnum,
} from '../../../shared/models';
import {
  BaseCheckDefinition,
  BaseCheckSettingsDefinition,
} from '../definitions/base-check-definition';
import { FindOptions } from '../definitions/types';
import { defaultIdVerificationSettings } from './default-settings';
import { IdVerificationCheckInput } from './id-verification-check-input';
import { idVerificationSettingsSchema } from './validation';

export class IdVerificationCheckDefinition extends BaseCheckDefinition<
  IdVerificationDataModel,
  IdVerificationCheckInput
> {
  // General
  // -------

  get type(): CheckTypeEnum {
    return CheckTypeEnum.id_verification;
  }
  get icon(): string {
    return 'UserCheck';
  }
  get checkRelationKey(): 'idVerificationData' {
    return 'idVerificationData';
  }
  public findOptions(): FindOptions<IdVerificationDataModel> {
    return {
      order: { createdAt: 'DESC' },
    };
  }
  get availableTarget(): EntityTypeEnum[] {
    return [EntityTypeEnum.individual];
  }

  get permissionResource(): PermissionResourceEnum {
    return PermissionResourceEnum.check_id_verification;
  }

  // Integrations
  // ------------

  get availableVendors(): VendorEnum[] {
    return [VendorEnum.ubble, VendorEnum.checkout];
  }
  // Settings
  // --------

  get settings(): BaseCheckSettingsDefinition<IdVerificationDataModel> {
    return {
      default: {
        vendor: defaultIdVerificationSettings.vendor,
        mode: defaultIdVerificationSettings.mode,
      },
    };
  }

  // Display
  // -------

  get label(): string {
    return 'Identity verification';
  }

  // Validation
  // ------

  override get validationSchemas(): {
    settings?: SchemaOf<IdVerificationCheckInput['settings']>;
    data?: never;
  } {
    return {
      settings: idVerificationSettingsSchema(),
    };
  }
}

export const idVerificationCheckDefinition =
  new IdVerificationCheckDefinition();
