export const CustomPropertyTypeEnum = {
  boolean: 'boolean',
  choices: 'choices',
  countries: 'countries',
  date: 'date',
  email: 'email',
  numeric: 'numeric',
  phone_number: 'phone_number',
  text: 'text',
  url: 'url',
} as const;
export type CustomPropertyTypeEnum =
  (typeof CustomPropertyTypeEnum)[keyof typeof CustomPropertyTypeEnum];
