import {
  Flex as ChakraFlex,
  FlexProps as ChakraFlexProps,
  Spacer as ChakraSpacer,
  SpacerProps as ChakraSpacerProps,
} from '@chakra-ui/react';

// @doc https://chakra-ui.com/docs/layout/flex
export type FlexProps = ChakraFlexProps;
export const Flex = ChakraFlex;

export type SpacerProps = ChakraSpacerProps;
export const Spacer = ChakraSpacer;
