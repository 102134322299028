import { LanguageEnum } from '../../../shared/models';

export const languageDefinition = {
  aa: { name: 'Afar', localName: 'Afar', emoji: '🇩🇯' },
  // ab: { name: 'Abkhazian', localName: 'Abkhazian', emoji: '🇦🇳' },
  // ae: { name: 'Avestan', localName: 'Avestan', emoji: '🇦🇳' },
  af: { name: 'Afrikaans', localName: 'Afrikaans', emoji: '🇿🇦' },
  ak: { name: 'Akan', localName: 'Akan', emoji: '🇬🇭' },
  am: { name: 'Amharic', localName: 'አማርኛ', emoji: '🇪🇹' },
  // an: { name: 'Aragonese', localName: 'Aragonese', emoji: '🇦🇳' },
  ar: { name: 'Arabic', localName: 'العربية', emoji: '🇸🇦' },
  as: { name: 'Assamese', localName: 'অসমীয়া', emoji: '🇮🇳' },
  // av: { name: 'Avaric', localName: 'Avaric', emoji: '🇦🇳' },
  // ay: { name: 'Aymara', localName: 'Aymara', emoji: '🇦🇳' },
  az: { name: 'Azerbaijani', localName: 'Azərbaycan', emoji: '🇦🇿' },
  ba: { name: 'Bashkir', localName: 'Bashkir', emoji: '🇰🇿' },
  be: { name: 'Belarusian', localName: 'Беларуская', emoji: '🇧🇾' },
  bg: { name: 'Bulgarian', localName: 'Български', emoji: '🇧🇬' },
  bh: { name: 'Bhojpuri', localName: 'Bhojpuri', emoji: '🇫🇯' },
  bi: { name: 'Bislama', localName: 'Bislama', emoji: '🇻🇺' },
  bm: { name: 'Bambara', localName: 'Bamanakan', emoji: '🇲🇱' },
  bn: { name: 'Bangla', localName: 'বাংলা', emoji: '🇧🇩' },
  bo: { name: 'Tibetan', localName: 'བོད་སྐད་', emoji: '🇳🇵' },
  bs: { name: 'Bosnian', localName: 'Bosanski', emoji: '🇧🇦' },
  ca: { name: 'Catalan', localName: 'Català', emoji: '🇪🇸' },
  // ce: { name: 'Chechen', localName: 'Нохчийн', emoji: '🇦🇳' },
  ch: { name: 'Chamorro', localName: 'Chamorro', emoji: '🇲🇵' },
  // cr: { name: 'Cree', localName: 'Cree', emoji: '🇦🇳' },
  cs: { name: 'Czech', localName: 'Čeština', emoji: '🇨🇿' },
  // cv: { name: 'Chuvash', localName: 'Чӑваш', emoji: '🇦🇳' },
  cy: { name: 'Welsh', localName: 'Cymraeg', emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
  da: { name: 'Danish', localName: 'Dansk', emoji: '🇩🇰' },
  de: { name: 'German', localName: 'Deutsch', emoji: '🇩🇪' },
  dv: { name: 'Divehi', localName: 'Divehi', emoji: '🇲🇻' },
  dz: { name: 'Dzongkha', localName: 'རྫོང་ཁ', emoji: '🇧🇹' },
  ee: { name: 'Ewe', localName: 'Eʋegbe', emoji: '🇬🇭' },
  el: { name: 'Greek', localName: 'Ελληνικά', emoji: '🇬🇷' },
  en: { name: 'English', localName: 'English', emoji: '🇺🇸' },
  // eo: { name: 'Esperanto', localName: 'Esperanto', emoji: '🇦🇳' },
  es: { name: 'Spanish', localName: 'Español', emoji: '🇪🇸' },
  et: { name: 'Estonian', localName: 'Eesti', emoji: '🇪🇪' },
  fa: { name: 'Persian', localName: 'فارسی', emoji: '🇮🇷' },
  // ff: { name: 'Fula', localName: 'Pulaar', emoji: '🇦🇳' },
  fi: { name: 'Finnish', localName: 'Suomi', emoji: '🇫🇮' },
  fj: { name: 'Fijian', localName: 'Fijian', emoji: '🇫🇯' },
  fo: { name: 'Faroese', localName: 'Føroyskt', emoji: '🇫🇴' },
  fr: { name: 'French', localName: 'Français', emoji: '🇫🇷' },
  fy: { name: 'Western Frisian', localName: 'Frysk', emoji: '🇳🇱' },
  ga: { name: 'Irish', localName: 'Gaeilge', emoji: '🇮🇪' },
  gd: { name: 'Scottish Gaelic', localName: 'Gàidhlig', emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿' },
  gl: { name: 'Galician', localName: 'Galego', emoji: '🇪🇸' },
  gn: { name: 'Guarani', localName: 'Guarani', emoji: '🇵🇾' },
  gu: { name: 'Gujarati', localName: 'ગુજરાતી', emoji: '🇮🇳' },
  // gv: { name: 'Manx', localName: 'Gaelg', emoji: '🇦🇳' },
  // ha: { name: 'Hausa', localName: 'Hausa', emoji: '🇦🇳' },
  he: { name: 'Hebrew', localName: 'עברית', emoji: '🇮🇱' },
  hi: { name: 'Hindi', localName: 'हिन्दी', emoji: '🇮🇳' },
  ho: { name: 'Hiri Motu', localName: 'Hiri Motu', emoji: '🇵🇬' },
  hr: { name: 'Croatian', localName: 'Hrvatski', emoji: '🇭🇷' },
  ht: {
    name: 'Haitian Creole',
    localName: 'Haitian Creole',
    emoji: '🇭🇹',
  },
  hu: { name: 'Hungarian', localName: 'Magyar', emoji: '🇭🇺' },
  hy: { name: 'Armenian', localName: 'Հայերեն', emoji: '🇦🇲' },
  hz: { name: 'Herero', localName: 'Herero', emoji: '🇳🇦' },
  // ia: { name: 'Interlingua', localName: 'Interlingua', emoji: '🇦🇳' },
  id: { name: 'Indonesian', localName: 'Indonesia', emoji: '🇮🇩' },
  // ie: { name: 'Interlingue', localName: 'Interlingue', emoji: '🇦🇳' },
  ig: { name: 'Igbo', localName: 'Igbo', emoji: '🇳🇬' },
  // ii: { name: 'Sichuan Yi', localName: 'ꆈꌠꉙ', emoji: '🇦🇳' },
  // ik: { name: 'Inupiaq', localName: 'Inupiaq', emoji: '🇦🇳' },
  // io: { name: 'Ido', localName: 'Ido', emoji: '🇦🇳' },
  is: { name: 'Icelandic', localName: 'Íslenska', emoji: '🇮🇸' },
  it: { name: 'Italian', localName: 'Italiano', emoji: '🇮🇹' },
  // iu: { name: 'Inuktitut', localName: 'Inuktitut', emoji: '🇦🇳' },
  ja: { name: 'Japanese', localName: '日本語', emoji: '🇯🇵' },
  jv: { name: 'Javanese', localName: 'Jawa', emoji: '🇲🇾' },
  ka: { name: 'Georgian', localName: 'Ქართული', emoji: '🇬🇪' },
  kg: { name: 'Kongo', localName: 'Kongo', emoji: '🇨🇩' },
  ki: { name: 'Kikuyu', localName: 'Gikuyu', emoji: '🇰🇪' },
  kj: { name: 'Kuanyama', localName: 'Kuanyama', emoji: '🇳🇦' },
  kk: { name: 'Kazakh', localName: 'Қазақ тілі', emoji: '🇰🇿' },
  kl: { name: 'Kalaallisut', localName: 'Kalaallisut', emoji: '🇬🇱' },
  km: { name: 'Khmer', localName: 'ខ្មែរ', emoji: '🇰🇭' },
  kn: { name: 'Kannada', localName: 'ಕನ್ನಡ', emoji: '🇮🇳' },
  ko: { name: 'Korean', localName: '한국어', emoji: '🇰🇷' },
  kr: { name: 'Kanuri', localName: 'Kanuri', emoji: '🇹🇩' },
  ks: { name: 'Kashmiri', localName: 'کٲشُر', emoji: '🇮🇳' },
  // ku: { name: 'Kurdish', localName: 'Kurdî', emoji: '🇦🇳' },
  // kv: { name: 'Komi', localName: 'Komi', emoji: '🇦🇳' },
  // kw: { name: 'Cornish', localName: 'Kernewek', emoji: '🇦🇳' },
  ky: { name: 'Kyrgyz', localName: 'Кыргызча', emoji: '🇰🇬' },
  lb: {
    name: 'Luxembourgish',
    localName: 'Lëtzebuergesch',
    emoji: '🇱🇺',
  },
  lg: { name: 'Ganda', localName: 'Luganda', emoji: '🇺🇬' },
  // li: { name: 'Limburgish', localName: 'Limburgish', emoji: '🇦🇳' },
  ln: { name: 'Lingala', localName: 'Lingála', emoji: '🇨🇩' },
  lo: { name: 'Lao', localName: 'ລາວ', emoji: '🇱🇦' },
  lt: { name: 'Lithuanian', localName: 'Lietuvių', emoji: '🇱🇹' },
  lu: { name: 'Luba-Katanga', localName: 'Tshiluba', emoji: '🇨🇩' },
  lv: { name: 'Latvian', localName: 'Latviešu', emoji: '🇱🇻' },
  mg: { name: 'Malagasy', localName: 'Malagasy', emoji: '🇲🇬' },
  mh: { name: 'Marshallese', localName: 'Marshallese', emoji: '🇲🇭' },
  mi: { name: 'Māori', localName: 'Māori', emoji: '🇳🇿' },
  mk: { name: 'Macedonian', localName: 'Македонски', emoji: '🇲🇰' },
  ml: { name: 'Malayalam', localName: 'മലയാളം', emoji: '🇮🇳' },
  mn: { name: 'Mongolian', localName: 'Монгол', emoji: '🇲🇳' },
  mr: { name: 'Marathi', localName: 'मराठी', emoji: '🇮🇳' },
  ms: { name: 'Malay', localName: 'Melayu', emoji: '🇲🇾' },
  mt: { name: 'Maltese', localName: 'Malti', emoji: '🇲🇹' },
  my: { name: 'Burmese', localName: 'မြန်မာ', emoji: '🇲🇲' },
  na: { name: 'Nauru', localName: 'Nauru', emoji: '🇳🇷' },
  nb: {
    name: 'Norwegian Bokmål',
    localName: 'Norsk bokmål',
    emoji: '🇳🇴',
  },
  nd: { name: 'North Ndebele', localName: 'IsiNdebele', emoji: '🇿🇼' },
  ne: { name: 'Nepali', localName: 'नेपाली', emoji: '🇳🇵' },
  ng: { name: 'Ndonga', localName: 'Ndonga', emoji: '🇳🇦' },
  nl: { name: 'Dutch', localName: 'Nederlands', emoji: '🇳🇱' },
  nn: {
    name: 'Norwegian Nynorsk',
    localName: 'Norsk nynorsk',
    emoji: '🇳🇴',
  },
  no: { name: 'Norwegian', localName: 'Norsk', emoji: '🇳🇴' },
  nr: {
    name: 'South Ndebele',
    localName: 'South Ndebele',
    emoji: '🇿🇦',
  },
  // nv: { name: 'Navajo', localName: 'Navajo', emoji: '🇦🇳' },
  ny: { name: 'Nyanja', localName: 'Nyanja', emoji: '🇲🇼' },
  oc: { name: 'Occitan', localName: 'Occitan', emoji: '🇫🇷' },
  oj: { name: 'Ojibwa', localName: 'Ojibwa', emoji: '🇨🇦' },
  om: { name: 'Oromo', localName: 'Oromoo', emoji: '🇪🇹' },
  or: { name: 'Odia', localName: 'ଓଡ଼ିଆ', emoji: '🇮🇳' },
  // os: { name: 'Ossetic', localName: 'Ирон', emoji: '🇦🇳' },
  pa: { name: 'Punjabi', localName: 'ਪੰਜਾਬੀ', emoji: '🇮🇳' },
  // pi: { name: 'Pali', localName: 'Pali', emoji: '🇦🇳' },
  pl: { name: 'Polish', localName: 'Polski', emoji: '🇵🇱' },
  // ps: { name: 'Pashto', localName: 'پښتو', emoji: '🇦🇳' },
  pt: { name: 'Portuguese', localName: 'Português', emoji: '🇵🇹' },
  qu: { name: 'Quechua', localName: 'Runasimi', emoji: '🇵🇪' },
  rm: { name: 'Romansh', localName: 'Rumantsch', emoji: '🇨🇭' },
  rn: { name: 'Rundi', localName: 'Ikirundi', emoji: '🇷🇼' },
  ro: { name: 'Romanian', localName: 'Română', emoji: '🇷🇴' },
  ru: { name: 'Russian', localName: 'Русский', emoji: '🇷🇺' },
  rw: { name: 'Kinyarwanda', localName: 'Kinyarwanda', emoji: '🇷🇼' },
  sa: { name: 'Sanskrit', localName: 'संस्कृत भाषा', emoji: '🇮🇳' },
  // sc: { name: 'Sardinian', localName: 'Sardu', emoji: '🇦🇳' },
  sd: { name: 'Sindhi', localName: 'سنڌي', emoji: '🇵🇰' },
  // se: {
  // name: 'Northern Sami',
  // localName: 'Davvisámegiella',
  // emoji: '🇦🇳',
  // },
  sg: { name: 'Sango', localName: 'Sängö', emoji: '🇨🇫' },
  si: { name: 'Sinhala', localName: 'සිංහල', emoji: '🇱🇰' },
  sk: { name: 'Slovak', localName: 'Slovenčina', emoji: '🇸🇰' },
  sl: { name: 'Slovenian', localName: 'Slovenščina', emoji: '🇸🇮' },
  sm: { name: 'Samoan', localName: 'Samoan', emoji: '🇼🇸' },
  sn: { name: 'Shona', localName: 'ChiShona', emoji: '🇿🇼' },
  so: { name: 'Somali', localName: 'Soomaali', emoji: '🇸🇴' },
  sq: { name: 'Albanian', localName: 'Shqip', emoji: '🇦🇱' },
  sr: { name: 'Serbian', localName: 'Српски', emoji: '🇷🇸' },
  ss: { name: 'Swati', localName: 'Swati', emoji: '🇸🇿' },
  st: {
    name: 'Southern Sotho',
    localName: 'Southern Sotho',
    emoji: '🇱🇸',
  },
  su: { name: 'Sundanese', localName: 'Basa Sunda', emoji: '🇸🇩' },
  sv: { name: 'Swedish', localName: 'Svenska', emoji: '🇸🇪' },
  sw: { name: 'Swahili', localName: 'Kiswahili', emoji: '🇹🇿' },
  ta: { name: 'Tamil', localName: 'தமிழ்', emoji: '🇮🇳' },
  te: { name: 'Telugu', localName: 'తెలుగు', emoji: '🇮🇳' },
  tg: { name: 'Tajik', localName: 'Тоҷикӣ', emoji: '🇺🇿' },
  th: { name: 'Thai', localName: 'ไทย', emoji: '🇹🇭' },
  ti: { name: 'Tigrinya', localName: 'ትግርኛ', emoji: '🇪🇹' },
  tk: { name: 'Turkmen', localName: 'Türkmen dili', emoji: '🇹🇲' },
  tl: { name: 'Filipino', localName: 'Filipino', emoji: '🇵🇭' },
  tn: { name: 'Tswana', localName: 'Tswana', emoji: '🇧🇼' },
  to: { name: 'Tongan', localName: 'Lea fakatonga', emoji: '🇹🇴' },
  tr: { name: 'Turkish', localName: 'Türkçe', emoji: '🇹🇷' },
  ts: { name: 'Tsonga', localName: 'Tsonga', emoji: '🇲🇿' },
  // tt: { name: 'Tatar', localName: 'Татар', emoji: '🇦🇳' },
  tw: { name: 'Akan', localName: 'Akan', emoji: '🇬🇭' },
  ty: { name: 'Tahitian', localName: 'Tahitian', emoji: '🇵🇫' },
  // ug: { name: 'Uyghur', localName: 'ئۇيغۇرچە', emoji: '🇦🇳' },
  uk: { name: 'Ukrainian', localName: 'Українська', emoji: '🇺🇦' },
  ur: { name: 'Urdu', localName: 'اردو', emoji: '🇵🇰' },
  uz: { name: 'Uzbek', localName: 'O‘zbek', emoji: '🇺🇿' },
  ve: { name: 'Venda', localName: 'Venda', emoji: '🇿🇦' },
  vi: { name: 'Vietnamese', localName: 'Tiếng Việt', emoji: '🇻🇳' },
  // vo: { name: 'Volapük', localName: 'Volapük', emoji: '🇦🇳' },
  wa: { name: 'Walloon', localName: 'Walloon', emoji: '🇧🇪' },
  wo: { name: 'Wolof', localName: 'Wolof', emoji: '🇸🇳' },
  xh: { name: 'Xhosa', localName: 'IsiXhosa', emoji: '🇦🇿🇦' },
  // yi: { name: 'Yiddish', localName: 'ייִדיש', emoji: '🇦🇳' },
  yo: { name: 'Yoruba', localName: 'Èdè Yorùbá', emoji: '🇧🇯' },
  za: { name: 'Zhuang', localName: 'Zhuang', emoji: '🇨🇳' },
  zh: { name: 'Chinese', localName: '中文', emoji: '🇨🇳' },
  zu: { name: 'Zulu', localName: 'IsiZulu', emoji: '🇿🇦' },
} as const satisfies Record<
  LanguageEnum,
  {
    name: string;
    localName: string;
    emoji?: string;
  }
>;
