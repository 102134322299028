import { has } from 'lodash';
import { Check } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { GroupBase, OptionProps } from 'react-select';

import { Box, HStack, Icon, Text } from '@chakra-ui/react';

/**
 * This help to keep the GenericSelect smooth when open.
 * The default Option component in GenericSelect rerender the whole list on hover which result in a really laggy input
 */
export const EntityLegalFormOption = <
  Option,
  IsMulti extends boolean,
  GroupOption extends GroupBase<Option>,
>({
  label,
  innerProps: { onClick },
  data,
  isFocused,
  isSelected,
  options,
  ...rest
}: OptionProps<Option, IsMulti, GroupOption>): JSX.Element => {
  const optionRef = useRef<HTMLDivElement>(null);

  const hasGroup = options && options[0] && has(options[0], 'options');

  // Scroll focused option into view
  useEffect(() => {
    if (optionRef.current && optionRef.current.parentElement && isFocused) {
      const { top, bottom } = optionRef.current.getBoundingClientRect() ?? {};

      let parentElement = optionRef.current?.parentElement;
      // @NOTE: With group, the actual list is 3 parents away from option
      if (
        hasGroup &&
        parentElement.parentElement &&
        parentElement.parentElement.parentElement
      ) {
        parentElement = parentElement.parentElement.parentElement;
      }
      const { top: topParent, bottom: bottomParent } =
        parentElement.getBoundingClientRect() ?? {};

      if (bottom > bottomParent || top < topParent) {
        optionRef.current?.scrollIntoView({ block: 'end' });
      }
    }
  }, [isFocused, label, hasGroup]);

  return (
    <HStack
      ref={optionRef}
      cursor="pointer"
      py="1.5"
      px="2.5"
      h="8"
      fontSize="md"
      backgroundColor={isSelected || isFocused ? 'gray.100' : 'initial'}
      _hover={{ backgroundColor: 'gray.100' }}
      onClick={onClick}
      borderRadius="md"
    >
      {isSelected ? (
        <Icon fontSize="inherit" color="black" width="1em" h="1em" as={Check} />
      ) : (
        <Box minWidth="1em" />
      )}
      <Text noOfLines={1} color="gray.600">
        {label}
      </Text>
    </HStack>
  );
};
