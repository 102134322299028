/* eslint-disable */
import { DocumentNode } from 'graphql';

import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  OperationVariables,
  Reference,
  StoreObject,
} from '@apollo/client';

import { logAndAddError } from '../../datadog';
import { getExtras } from './relay-style-pagination';

interface InsertIntoEdgesFunction {
  (existingEdges: any[], newEdge: any): any[];
}

interface AddDataToConnectionOptions<TQuery = any, TData = any> {
  /**
   * name of the connection to update
   */
  query: DocumentNode;
  /**
   * name of the connection field in the query result
   */
  connection: keyof TQuery;
  /**
   * variable used with the query for filtering/ordering
   */
  variables?: OperationVariables;
  /**
   * key in `data` containing the new connection node
   */
  newDataKey: keyof TData;
  /**
   * Customize new item insertion in the collections edges
   */
  // @TODO - E-67 - Automatically insert into edge with reSort/reFilter according to variables
  insertIntoEdges?: InsertIntoEdgesFunction;
}
export const addDataToConnection =
  <
    TQuery = any,
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>,
  >({
    query,
    connection,
    variables,
    newDataKey,
    insertIntoEdges = defaultInsertInEdges,
  }: AddDataToConnectionOptions<TQuery, TData>): MutationUpdaterFunction<
    TData,
    TVariables,
    TContext,
    TCache
  > =>
  (cache, result): void => {
    if (!result || !result.data) {
      logAndAddError(
        new Error(
          `Could not retrieve result and result.data: ensure the connection is configured with relayStylePagination in the cache typePolicies or provide the same query variables`,
        ),
      );
      return;
    }
    const newItem = result?.data[newDataKey];
    const existing: TQuery | null = cache.readQuery({ query, variables });
    if (!existing) {
      logAndAddError(
        new Error(
          `Could not retrieve query data from cache: ensure the connection is configured with relayStylePagination in the cache typePolicies or provide the same query variables`,
        ),
      );
      return;
    }

    const existingConnecion: any = existing?.[connection];
    const edges = existingConnecion.edges ?? [];

    const moreEdges = insertIntoEdges(edges, {
      node: newItem,
      // We must build an edge with a cursor, otherwise Apollo complains
      // about a missing field
      cursor: '_unknown_',
    });

    cache.writeQuery({
      data: {
        [connection]: {
          ...getExtras(existingConnecion),
          edges: moreEdges,
          pageInfo: {
            ...existingConnecion.pageInfo,
          },
        },
      },
      query,
      variables,
    });
  };

const defaultInsertInEdges: InsertIntoEdgesFunction = (
  existingEdges,
  newEdge,
) => [newEdge, ...existingEdges];

interface RemoveDataFromConnectionOptions {
  /**
   * name of the connection to update
   */
  connection: string;
  /**
   * id to remove from the connection
   */
  id: string;
}
export const removeDataFromConnection =
  <
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>,
  >({
    connection,
    id,
  }: RemoveDataFromConnectionOptions): MutationUpdaterFunction<
    TData,
    TVariables,
    TContext,
    TCache
  > =>
  (cache): void => {
    cache.modify({
      fields: {
        [connection](existing: Record<string, unknown> = {}, { readField }) {
          const edges = existing['edges'] as {
            node?: StoreObject | Reference;
          }[];
          const filteredEdges = edges.filter(
            (edge) => id !== readField('id', edge.node),
          );
          return {
            ...getExtras(existing),
            edges: filteredEdges,
            pageInfo: {
              ...(existing['pageInfo'] as object),
            },
          };
        },
      },
    });
  };
