import { ElementOf } from 'ts-essentials';

import { CompanyStatusEnum } from '../../../shared/models';

export const companyStatusDefinition = [
  {
    value: CompanyStatusEnum.live,
    label: 'Live',
    color: 'green',
  },
  {
    value: CompanyStatusEnum.closed,
    label: 'Closed',
    color: 'red',
  },
  {
    value: CompanyStatusEnum.not_reported,
    label: 'Not reported',
    color: 'grey',
  },
];

export function getCompanyStatusDefinition(
  status: CompanyStatusEnum,
): ElementOf<typeof companyStatusDefinition> {
  return companyStatusDefinition.find(
    ({ value }) => value === status,
  ) as ElementOf<typeof companyStatusDefinition>;
}
