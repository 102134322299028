import {
  GroupBase,
  OnChangeValue,
  Props,
  SelectInstance,
} from 'chakra-react-select';
import { Ref } from 'react';

import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { GenericSelect, Option } from './generic-select';

export type SelectOnChangeValue<
  TIsMulti extends boolean = boolean,
  TOption extends Option = Option,
> = OnChangeValue<TOption, TIsMulti>;

export type SelectProps<
  TIsMulti extends boolean,
  TOption extends Option = Option,
> = Props<TOption, TIsMulti, GroupBase<TOption>>;

export const Select = forwardRefWithGeneric(function SelectWithRef<
  TIsMulti extends boolean = false,
  TOption extends Option = Option,
>(
  { ...props }: SelectProps<TIsMulti, TOption>,
  ref: Ref<SelectInstance<TOption, TIsMulti>>,
): JSX.Element {
  return (
    <GenericSelect<TOption, TIsMulti, GroupBase<TOption>>
      {...props}
      ref={ref}
    />
  );
});
