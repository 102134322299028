import { Calendar, LucideIcon } from 'lucide-react';
import { AnySchema } from 'yup';

import { DATE_FORMATS, formatDateTime } from '@dotfile/shared/common';

import { CustomPropertyTypeEnum } from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import {
  CustomPropertyFormatValueOptions,
  CustomPropertyFormatValueParam,
} from './type';

export class DateCustomPropertyDefinition extends BaseCustomPropertyDefinition {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.date;
  }

  get label(): string {
    return 'Date';
  }

  get icon(): LucideIcon {
    return Calendar;
  }

  // Settings
  // --------

  // no settings for now

  // Value
  // -----

  public valueSchema(): AnySchema {
    const schema = yup.string().nullable().absoluteDate().strict();

    return schema;
  }

  public override formatValue(
    { value }: CustomPropertyFormatValueParam,
    options: CustomPropertyFormatValueOptions,
  ): string {
    try {
      return formatDateTime(value, DATE_FORMATS.date, options?.timezone);
    } catch {
      return value;
    }
  }
}

export const dateCustomPropertyDefinition = new DateCustomPropertyDefinition();
