import {
  EntityTypeEnum,
  FileStorageEnum,
  FileTargetEnum,
} from '../../../shared/models';

type IdFileTarget = {
  id: string;
};

type CheckFileTargetData = {
  workspaceId: string;
  caseId: string;
  entityType: EntityTypeEnum;
  entityTypeId: string;
  checkId: string;
  checkDataId: string;
};
function checkFileTargetFn({
  workspaceId,
  caseId,
  entityType,
  entityTypeId,
  checkId,
  checkDataId,
}: CheckFileTargetData) {
  return `${casePathPrefix({
    workspaceId,
    caseId,
  })}/${entityType}__${entityTypeId}/check__${checkId}/data__${checkDataId}`;
}

type DocumentOrderFileTarget = {
  workspaceId: string;
  caseId: string;
  companyId: string;
  documentOrderId: string;
};
function documentOrderFileTargetFn({
  workspaceId,
  caseId,
  companyId,
  documentOrderId,
}: DocumentOrderFileTarget) {
  return `${casePathPrefix({
    caseId,
    workspaceId,
  })}/company__${companyId}/document_order__${documentOrderId}`;
}

type ClientPortalFileTarget = {
  workspaceId: string;
  clientPortalId: string;
  versionId?: string | null; // can be null for Draft objects
};
function clientPortalFileTargetFn({
  workspaceId,
  clientPortalId,
  versionId,
}: ClientPortalFileTarget) {
  const clientPortalPathPrefix = `${workspacePathPrefix(
    workspaceId,
  )}/client_portal__${clientPortalId}`;
  if (versionId) {
    return `${clientPortalPathPrefix}/${versionId}`;
  }
  // For Client Portal draft objects
  return `${clientPortalPathPrefix}/_draft`;
}

export const workspacePathPrefix = (id: string): string => `workspace__${id}`;
export const casePathPrefix = ({
  caseId,
  workspaceId,
}: {
  workspaceId: string;
  caseId: string;
}): string => `${workspacePathPrefix(workspaceId)}/case__${caseId}`;

export const fileTargetDefinition = {
  [FileTargetEnum.user_avatar]: {
    storage: FileStorageEnum.main,
    path: ({ id }: IdFileTarget) => `user__${id}/avatar`,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.workspace_logo]: {
    storage: FileStorageEnum.main,
    path: ({ id }: IdFileTarget) => `${workspacePathPrefix(id)}/logo`,
    isPubliclyAccessible: true,
  },
  [FileTargetEnum.communication_logo]: {
    storage: FileStorageEnum.main,
    path: ({ id }: IdFileTarget) =>
      `${workspacePathPrefix(id)}/communication_logo`,
    isPubliclyAccessible: true,
  },
  [FileTargetEnum.client_portal_logo]: {
    storage: FileStorageEnum.main,
    path: (ids: ClientPortalFileTarget) =>
      `${clientPortalFileTargetFn(ids)}/logo`,
    isPubliclyAccessible: true,
  },
  [FileTargetEnum.client_portal_email_logo]: {
    storage: FileStorageEnum.main,
    path: (ids: ClientPortalFileTarget) =>
      `${clientPortalFileTargetFn(ids)}/email_logo`,
    isPubliclyAccessible: true,
  },
  [FileTargetEnum.client_portal_favicon]: {
    storage: FileStorageEnum.main,
    path: (ids: ClientPortalFileTarget) =>
      `${clientPortalFileTargetFn(ids)}/favicon`,
    isPubliclyAccessible: true,
  },
  [FileTargetEnum.client_portal_block_pdf]: {
    storage: FileStorageEnum.main,
    path: (ids: ClientPortalFileTarget) =>
      `${clientPortalFileTargetFn(ids)}/block/pdf`,
    isPubliclyAccessible: true,
  },
  [FileTargetEnum.client_portal_block_image]: {
    storage: FileStorageEnum.main,
    path: (ids: ClientPortalFileTarget) =>
      `${clientPortalFileTargetFn(ids)}/block/image`,
    isPubliclyAccessible: true,
  },
  [FileTargetEnum.client_portal_translation]: {
    storage: FileStorageEnum.main,
    path: (ids: ClientPortalFileTarget) =>
      `${clientPortalFileTargetFn(ids)}/translations`,
    isPubliclyAccessible: true,
  },
  [FileTargetEnum.id_verification_data_report]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.id_verification_data_front]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.id_verification_data_back]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.id_verification_data_signature]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.id_verification_data_face]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.id_verification_data_video]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.id_document_data_front]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.id_document_data_back]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.document_data_file]: {
    storage: FileStorageEnum.document,
    path: checkFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.document_order_file]: {
    storage: FileStorageEnum.document,
    path: documentOrderFileTargetFn,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.import_file]: {
    storage: FileStorageEnum.document,
    path: (data: { workspaceId: string; importId: string }) =>
      `${workspacePathPrefix(data.workspaceId)}/import__${data.importId}`,
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.case_report_file]: {
    storage: FileStorageEnum.document,
    path: (data: { workspaceId: string; caseId: string }) =>
      casePathPrefix(data),
    isPubliclyAccessible: false,
  },
  [FileTargetEnum.communication_attachment]: {
    storage: FileStorageEnum.document,
    path: (data: {
      workspaceId: string;
      caseId: string;
      communicationMessageId: string;
    }) => {
      return `${casePathPrefix({
        caseId: data.caseId,
        workspaceId: data.workspaceId,
      })}/communication__${data.communicationMessageId}/attachments`;
    },
    isPubliclyAccessible: false,
  },
} as const;
// @TODO - E-2355 - Satisfies in domain break compilation in console-app
// } satisfies Record<
//   FileTargetEnum,
//   {
//     storage: FileStorageEnum;
//     path: ((data: Record<string,string>) => string);
//   }
// >;
