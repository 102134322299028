import { numberInputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle((props) => {
  return {
    field: {
      backgroundColor: 'white',
      _focusVisible: {
        borderColor: mode('gray.800', 'gray.600')(props),
        boxShadow: 'none !important',
      },
      _invalid: {
        bg: 'red.50',
        boxShadow: 'none !important',
      },
    },
  };
});

export const NumberInputTheme = defineMultiStyleConfig({
  variants: {
    outline: baseStyle,
  },
});
