import { ChevronDown, ChevronUp } from 'lucide-react';

import {
  NumberDecrementStepper as ChakraNumberDecrementStepper,
  NumberIncrementStepper as ChakraNumberIncrementStepper,
  NumberInputStepper as ChakraNumberInputStepper,
  NumberInputStepperProps as CharkaNumberInputStepperProps,
  ComponentWithAs,
  Icon,
  NumberDecrementStepperProps,
  NumberIncrementStepperProps,
} from '@chakra-ui/react';

const NumberDecrementStepper: ComponentWithAs<
  'div',
  NumberDecrementStepperProps
> = (props) => (
  <ChakraNumberDecrementStepper {...props}>
    <Icon as={ChevronDown} />
  </ChakraNumberDecrementStepper>
);

const NumberIncrementStepper: ComponentWithAs<
  'div',
  NumberIncrementStepperProps
> = (props) => (
  <ChakraNumberIncrementStepper {...props}>
    <Icon as={ChevronUp} />
  </ChakraNumberIncrementStepper>
);

export const NumberInputStepper = ({
  children,
  ...props
}: CharkaNumberInputStepperProps): JSX.Element => {
  return (
    <ChakraNumberInputStepper {...props}>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </ChakraNumberInputStepper>
  );
};
