import { mergeWith } from 'lodash';

import {
  BaseRepresentativeModel,
  CaseRelationRoleEnum,
  CompanyBeneficialOwnerModel,
  CompanyEntityTypeEnum,
  CompanyRepresentativeModel,
  CompanyShareholderModel,
  IndividualBeneficialOwnerModel,
  IndividualRepresentativeModel,
  IndividualShareholderModel,
  MergedIndividualsModel,
} from '../../../../shared/models';
import { IndividualComparator } from './individual-comparison.helper';

function entityTypeIndividualFilter(representative: BaseRepresentativeModel) {
  return representative.entityType === CompanyEntityTypeEnum.individual;
}

type MergedIndividualsModelWithSourceRole = MergedIndividualsModel & {
  _sourceRole: 'beneficial_owner' | 'shareholder' | 'legal_representative';
};

function orderIndividuals(
  a: MergedIndividualsModelWithSourceRole,
  b: MergedIndividualsModelWithSourceRole,
): [
  MergedIndividualsModelWithSourceRole,
  MergedIndividualsModelWithSourceRole,
] {
  // beneficial_owner priority
  if (a._sourceRole === 'beneficial_owner') {
    return [a, b];
  } else if (b._sourceRole === 'beneficial_owner') {
    return [b, a];
  }

  // // shareholder priority
  if (a._sourceRole === 'shareholder') {
    return [a, b];
  } else if (b._sourceRole === 'shareholder') {
    return [b, a];
  }

  // no priority
  return [a, b];
}

type MergeIndividualsParam = {
  beneficialOwners?:
    | (IndividualBeneficialOwnerModel | CompanyBeneficialOwnerModel)[]
    | null;
  shareholders?:
    | (IndividualShareholderModel | CompanyShareholderModel)[]
    | null;
  legalRepresentatives?:
    | (IndividualRepresentativeModel | CompanyRepresentativeModel)[]
    | null;
};

// @TODO - E-4415 - Refactor merge-companies and merge-individuals helpers
/**
 * Merge a list of individuals
 * Also strip out 'company' entityType
 * @see https://linear.app/dotfile/issue/E-1922/domain-merge-individuals
 *
 * @param param.beneficialOwners - An array of beneficial owners
 * @param param.shareholders - An array of shareholders
 * @param param.legalRepresentatives - An array of legal representatives
 * @returns A merged array of SimpleIndividualModel
 */
export function mergeIndividuals({
  beneficialOwners,
  shareholders,
  legalRepresentatives,
}: MergeIndividualsParam): MergedIndividualsModel[] {
  if (!beneficialOwners) beneficialOwners = [];
  if (!shareholders) shareholders = [];
  if (!legalRepresentatives) legalRepresentatives = [];

  const individuals: MergedIndividualsModelWithSourceRole[] = [
    ...beneficialOwners
      .filter((b0): b0 is IndividualBeneficialOwnerModel =>
        entityTypeIndividualFilter(b0),
      )
      .map(
        (bo): MergedIndividualsModelWithSourceRole => ({
          _sourceRole: 'beneficial_owner',
          birthCountry: bo.birthCountry ?? null,
          birthPlace: bo.birthPlace ?? null,
          birthDate: bo.birthDate ?? null,
          firstName: bo.firstName ?? null,
          middleName: bo.middleName ?? null,
          lastName: bo.lastName ?? null,
          maidenName: bo.maidenName ?? null,
          position: bo.position ?? null,
          address: bo.address,
          ownershipPercentage: bo.ownershipPercentage ?? null,
          votingRightsPercentage: bo.votingRightsPercentage ?? null,
          roles: [],
          isBeneficialOwner: true,
          isBusinessContact: false,
          relations: [
            {
              ownershipPercentage: null,
              votingRightsPercentage: null,
              position: bo.position ?? null,
              roles: [],
            },
          ],
        }),
      ),
    ...shareholders
      .filter((s): s is IndividualShareholderModel =>
        entityTypeIndividualFilter(s),
      )
      .map(
        (sh): MergedIndividualsModelWithSourceRole => ({
          _sourceRole: 'shareholder',
          birthCountry: sh.birthCountry ?? null,
          birthPlace: sh.birthPlace ?? null,
          birthDate: sh.birthDate ?? null,
          firstName: sh.firstName ?? null,
          middleName: sh.middleName ?? null,
          lastName: sh.lastName ?? null,
          maidenName: sh.maidenName ?? null,
          position: sh.position ?? null,
          address: sh.address,
          ownershipPercentage: sh.ownershipPercentage ?? null,
          votingRightsPercentage: sh.votingRightsPercentage ?? null,
          roles: [CaseRelationRoleEnum.shareholder],
          isBeneficialOwner: false,
          isBusinessContact: false,
          relations: [
            {
              ownershipPercentage: null,
              votingRightsPercentage: null,
              position: sh.position ?? null,
              roles: [CaseRelationRoleEnum.shareholder],
            },
          ],
        }),
      ),
    ...legalRepresentatives
      .filter((lr): lr is IndividualRepresentativeModel =>
        entityTypeIndividualFilter(lr),
      )
      .map(
        (lr): MergedIndividualsModelWithSourceRole => ({
          _sourceRole: 'legal_representative',
          birthCountry: lr.birthCountry ?? null,
          birthPlace: lr.birthPlace ?? null,
          birthDate: lr.birthDate ?? null,
          firstName: lr.firstName ?? null,
          middleName: lr.middleName ?? null,
          lastName: lr.lastName ?? null,
          maidenName: lr.maidenName ?? null,
          position: lr.position ?? null,
          address: lr.address,
          ownershipPercentage: null,
          votingRightsPercentage: lr.votingRightsPercentage ?? null,
          roles: [CaseRelationRoleEnum.legal_representative],
          isBeneficialOwner: false,
          isBusinessContact: false,
          relations: [
            {
              ownershipPercentage: null,
              votingRightsPercentage: null,
              position: lr.position ?? null,
              roles: [CaseRelationRoleEnum.legal_representative],
            },
          ],
        }),
      ),
  ];

  const result = individuals.reduce<MergedIndividualsModelWithSourceRole[]>(
    (acc, currentIndividual) => {
      const comparator = new IndividualComparator(currentIndividual);
      const matchingIndex = comparator.isComparable
        ? acc.findIndex((otherIndividual) => comparator.isSame(otherIndividual))
        : -1;

      if (matchingIndex === -1) {
        // current individual is not comparable or did not match with any other
        return [...acc, currentIndividual];
      }

      // map over acc allow to update in place the matchingIndividual
      // with the information of the currentIndividual
      // currentIndividual is not appended to acc because it is merged
      // into matchingIndividual
      return acc.map((otherIndividual, index) => {
        if (index === matchingIndex) {
          const matchingIndividual = otherIndividual;

          // handle priority of information from role:
          // beneficial_owner > stakeholder > legal_representative
          const [moreInfo, lessInfo] = orderIndividuals(
            currentIndividual,
            matchingIndividual,
          );

          return mergeWith(
            {},
            lessInfo,
            moreInfo,
            {
              roles: [...new Set([...lessInfo.roles, ...moreInfo.roles])],
            },
            {
              // merge relation by concatenating roles
              // @WARNING: This merge logic  won't work if there are not exactly one relation relation in each individuals
              relations: [
                {
                  ownershipPercentage:
                    moreInfo.relations[0]?.ownershipPercentage ??
                    lessInfo.relations[0]?.ownershipPercentage ??
                    null,
                  votingRightsPercentage:
                    moreInfo.relations[0]?.votingRightsPercentage ??
                    lessInfo.relations[0]?.votingRightsPercentage ??
                    null,
                  position:
                    moreInfo.relations[0]?.position ??
                    lessInfo.relations[0]?.position ??
                    null,
                  roles: [
                    ...new Set([
                      ...(moreInfo.relations[0]?.roles ?? []),
                      ...(lessInfo.relations[0]?.roles ?? []),
                    ]),
                  ],
                },
              ],
            },
            (objValue, srcValue) =>
              // takes in priority info from the last object or one that it truthy
              srcValue || objValue,
          );
        }

        return otherIndividual;
      });
    },
    [],
  );

  return result.map(
    ({
      _sourceRole, // Remove _sourceRole
      ...mim
    }): MergedIndividualsModel => ({ ...mim }),
  );
}
