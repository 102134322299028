import { PropsWithChildren } from 'react';

import {
  HStack,
  Image,
  Link,
  Spacer,
  useIsSmallScreen,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { LanguageSelector } from '../../components';
import { useLatestClientPortalVersion } from '../../hooks';
import { CONTENT_MAX_WIDTH } from './constants';

export const Footer = ({ children }: PropsWithChildren) => {
  const { data } = useLatestClientPortalVersion();

  const isSmallScreen = useIsSmallScreen();

  return (
    <HStack
      backdropFilter="blur(6px)"
      bottom="-8"
      mb="-8"
      mx={isSmallScreen ? '-4' : '-16'}
      pb={isSmallScreen ? '2' : '3'}
      px={isSmallScreen ? '6' : '12'}
      position="sticky"
      width={`calc(100% + ${isSmallScreen ? '32px' : '128px'})`}
      height="auto"
      zIndex="10"
    >
      {isSmallScreen ? (
        <VStack width="full" align="start">
          <HStack width="full">{children}</HStack>
          <HStack
            marginX="-6"
            marginBottom="-2"
            paddingX="6"
            paddingRight="2"
            paddingY="3"
            height="14"
            width="calc(100% + 48px)"
            bgColor="gray.200"
            justifyContent="space-between"
          >
            {data?.latestClientPortalVersion.setting.styling
              .showDotfileLogo && (
              <Link
                display="contents"
                href="https://dotfile.com"
                target="_blank"
              >
                <Image
                  height="auto"
                  h="16px"
                  src="/assets/logo-original.svg"
                  alt="Dotfile logo"
                />
              </Link>
            )}
            <Spacer />
            <LanguageSelector />
          </HStack>
        </VStack>
      ) : (
        <HStack minHeight="40px" width="full">
          {data?.latestClientPortalVersion.setting.styling.showDotfileLogo && (
            <Link display="contents" href="https://dotfile.com" target="_blank">
              <Image
                position="absolute"
                height="auto"
                h="16px"
                src="/assets/logo-original.svg"
                alt="Dotfile logo"
              />
            </Link>
          )}
          <HStack
            width={CONTENT_MAX_WIDTH}
            margin="auto"
            justifyContent="space-between"
          >
            {children}
          </HStack>
        </HStack>
      )}
    </HStack>
  );
};
