import { match } from 'ts-pattern';

import { PropertyMappingTypeEnum } from '../../../shared/models';
import {
  PropertyTypeEnum,
  QueryBuilderProperty,
} from '../../../shared/properties';
import {
  DEFAULT_QUERY_OPERATOR_LABELS,
  QueryBuilderOperator,
} from '../../../utils';

type GetLogicBuilderOperator = {
  /**
   * Will default to the labels in `DEFAULT_QUERY_OPERATOR_LABELS`
   */
  label?: string;
  value: QueryBuilderOperator;
};

/**
 * Get the available operators for a given property type
 *
 * @param property
 *
 * @see https://react-querybuilder.js.org/docs/components/querybuilder#getoperators
 * @see https://react-querybuilder.js.org/docs/components/querybuilder#operators
 */
export const getLogicBuilderOperators = (
  property: QueryBuilderProperty<PropertyTypeEnum>,
): Required<GetLogicBuilderOperator>[] => {
  const textLikeOperators: GetLogicBuilderOperator[] = [
    { value: '=', label: 'Is' },
    { value: '!=', label: 'Is not' },
    { value: 'contains' },
    { value: 'doesNotContain' },
    { value: 'null' },
    { value: 'notNull' },
    { value: 'matchRegexp', label: 'Matches regular expression' },
    { value: 'doesNotMatchRegexp', label: 'Does not match regular expression' },
  ];

  const choicesArrayValues: GetLogicBuilderOperator[] = [
    { value: 'arrayContains' },
    { value: 'arrayContainsAll' },
    { value: 'arrayContainsExactly' },
    { value: 'doesNotArrayContain' },
    { value: 'doesNotArrayContainAll' },
    { value: 'null' },
    { value: 'notNull' },
  ];
  const choicesSingleValue: GetLogicBuilderOperator[] = [
    { value: '=', label: 'Is' },
    { value: '!=', label: 'Is not' },
    { value: 'in' },
    { value: 'notIn' },
    { value: 'null' },
    { value: 'notNull' },
  ];

  const dateOperators: GetLogicBuilderOperator[] = [
    { value: '=', label: 'On' },
    { value: '!=', label: 'Not on' },
    { value: '<', label: 'Before' },
    { value: '<=', label: 'On or before' },
    { value: '>', label: 'After' },
    { value: '>=', label: 'On or After' },
    { value: 'between' },
    { value: 'notBetween' },
    { value: 'null' },
    { value: 'notNull' },
  ];

  const operators = match(property)
    .when(
      isPropertyType(PropertyTypeEnum.boolean),
      (): GetLogicBuilderOperator[] => [
        { value: '=', label: 'Is' },
        { value: '!=', label: 'Is not' },
        { value: 'null' },
        { value: 'notNull' },
      ],
    )
    .when(
      isPropertyType(PropertyTypeEnum.choices),
      (choiceProperty): GetLogicBuilderOperator[] => {
        const isArrayValue =
          choiceProperty.mapping.type === PropertyMappingTypeEnum.custom || // Custom property value are all array
          choiceProperty.settings?.allowMultiple; // For default property we must look at allowMultiple to check if the value is an array or single value

        return isArrayValue ? choicesArrayValues : choicesSingleValue;
      },
    )
    .when(
      isPropertyType(PropertyTypeEnum.classifications),
      (): GetLogicBuilderOperator[] => choicesArrayValues,
    )
    .when(
      isPropertyType(PropertyTypeEnum.countries),
      (countriesProperty): GetLogicBuilderOperator[] => {
        const isArrayValue =
          countriesProperty.mapping.type === PropertyMappingTypeEnum.custom || // Custom property value are all array
          countriesProperty.settings?.allowMultiple; // For default property we must look at allowMultiple to check if the value is an array or single value

        return isArrayValue ? choicesArrayValues : choicesSingleValue;
      },
    )
    .when(
      isPropertyType(PropertyTypeEnum.entity_legal_form),
      (): GetLogicBuilderOperator[] => choicesSingleValue,
    )
    .when(
      isPropertyType(PropertyTypeEnum.date),
      (): GetLogicBuilderOperator[] => dateOperators,
    )
    .when(
      isPropertyType(PropertyTypeEnum.email),
      (): GetLogicBuilderOperator[] => textLikeOperators,
    )
    .when(
      isPropertyType(PropertyTypeEnum.numeric),
      (): GetLogicBuilderOperator[] => [
        { value: '=' },
        { value: '!=' },
        { value: '<' },
        { value: '<=' },
        { value: '>' },
        { value: '>=' },
        { value: 'between' },
        { value: 'notBetween' },
        { value: 'null' },
        { value: 'notNull' },
      ],
    )
    .when(
      isPropertyType(PropertyTypeEnum.phone_number),
      (): GetLogicBuilderOperator[] => textLikeOperators,
    )
    .when(
      isPropertyType(PropertyTypeEnum.text),
      (): GetLogicBuilderOperator[] => textLikeOperators,
    )
    .when(
      isPropertyType(PropertyTypeEnum.url),
      (): GetLogicBuilderOperator[] => textLikeOperators,
    )

    .otherwise(
      // Other property type are not supported so returns no operators
      () => [],
    );

  const fullOperators: Required<GetLogicBuilderOperator>[] = operators.map(
    (o) => ({
      value: o.value,
      label: o.label ?? DEFAULT_QUERY_OPERATOR_LABELS[o.value],
    }),
  );

  return fullOperators;
};

export const isPropertyType =
  <T extends PropertyTypeEnum>(type: T) =>
  (
    property: QueryBuilderProperty<PropertyTypeEnum>,
  ): property is QueryBuilderProperty<T> =>
    property.type === type;
