import { InviteRoleEnum, MemberRoleEnum } from '../../../shared/models';

export type RoleDefinition = {
  name: string;
  description: string;
};

export const roleDefinition: Record<MemberRoleEnum, RoleDefinition> = {
  [MemberRoleEnum.owner]: {
    name: 'Owner',
    description: 'Can delete workspace',
  },
  [MemberRoleEnum.admin]: {
    name: 'Admin',
    description: 'Can change workspace settings and invite new members',
  },
  [MemberRoleEnum.member]: {
    name: 'Member',
    description: 'Cannot change workspace settings or invite new members',
  },
};

export const getRoleDefinition = (
  role: MemberRoleEnum | InviteRoleEnum,
): RoleDefinition => roleDefinition[role];
