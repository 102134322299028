import { useEffect, useMemo, useRef } from 'react';

import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  CompanySearchInput,
  CompanySearchQuery,
  useCompanySearchQuery,
} from '@dotfile/shared/data-access-client-portal';

export const useCompanySearch = (
  input: CompanySearchInput,
  {
    onNoVendorSupported,
    onCompleted = () => 0,
    timeout = 30_000, // 30 sec
  }: Partial<{
    /**
     * Triggered when data is fetched
     */
    onCompleted: (data: CompanySearchQuery) => void;
    /**
     * Triggered when country has no vendor supported
     */
    onNoVendorSupported: () => void;
    timeout: number;
  }>,
) => {
  const handleError = useHandleError();

  const controller = useMemo(() => new AbortController(), []);
  const companySearch = useCompanySearchQuery({
    variables: { input },

    // used to timeout the call
    context: {
      fetchOptions: {
        signal: controller.signal,
      },
    },
    onCompleted,
    onError: (error) => {
      if (
        // @TODO - E-69 - better handle of error
        /No vendor supported for country/.test(error.message)
      ) {
        onNoVendorSupported?.();
      } else {
        handleError({
          error,
          title: 'Unable to search company',
        });
      }
    },
  });

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (!timer.current && companySearch.loading) {
      timer.current = setTimeout(() => {
        controller.abort(new Error('Timeout'));
      }, timeout);
    } else if (timer.current && !companySearch.loading) {
      clearTimeout(timer.current);
    }
  }, [companySearch.loading, controller, timeout]);

  return companySearch;
};
