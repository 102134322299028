import { useMemo } from 'react';

import { forwardRef, Text } from '@chakra-ui/react';

import { logAndAddError } from '@dotfile/frontend/shared/common';
import { COUNTRIES } from '@dotfile/shared/domain';

import { TextProps } from '../../typography/text';

export type CountryProps = {
  /**
   * ISO 3166-1 alpha-2 country code
   * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  code: string;
  /**
   * Only default country flag instead of flag and name
   */
  flagOnly?: boolean;
} & TextProps;

export const Country = forwardRef<CountryProps, 'span'>(
  ({ code, flagOnly, ...rest }: CountryProps, ref): JSX.Element => {
    const { flag, flagAndLabel } = useMemo(() => {
      const country = COUNTRIES.find(
        (c) =>
          // Values in COUNTRIES are in upper case but some code are stored in lower case
          c.code === code.toUpperCase(),
      );
      if (!country) {
        logAndAddError(
          new Error(`Could not find country from code '${code}'`),
          { code },
        );
        return { flag: 'Unknown', flagAndLabel: 'Unknown' };
      }

      const flag = country.emoji ?? 'Unknown';

      return { flag, flagAndLabel: `${country.emoji} ${country.name}` };
    }, [code]);

    return (
      <Text as="span" color="black" title={flagAndLabel} {...rest} ref={ref}>
        {flagOnly ? flag : flagAndLabel}
      </Text>
    );
  },
);
Country.displayName = 'Country';
