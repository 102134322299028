import { createContext, PropsWithChildren, useContext, useRef } from 'react';
import { useStore } from 'zustand';

import {
  FormDatastoreApi,
  FormDatastoreState,
  initFormDatastore,
} from './datastore';

const FormDatastoreContext = createContext<FormDatastoreApi | null>(null);

export function useMaybeFormDatastoreApi(): FormDatastoreApi | null {
  const context = useContext(FormDatastoreContext);

  return context;
}

export function useFormDatastoreApi(): FormDatastoreApi {
  const context = useMaybeFormDatastoreApi();
  if (!context) {
    throw new Error(
      `value for FormDatastoreContext was not initialized. Make sure the FormDatastoreProvider is set up.`,
    );
  }

  return context;
}

function baseSelector(state: FormDatastoreState) {
  return state;
}

export function useFormDatastore<T = FormDatastoreState>(
  selector: (state: FormDatastoreState) => T = baseSelector as typeof selector,
): T {
  const storeApi = useFormDatastoreApi();

  return useStore(storeApi, selector);
}

type FormDatastoreProviderProps = {
  clientPortalId: string;
} & PropsWithChildren;

export const FormDatastoreProvider = ({
  clientPortalId,
  children,
}: FormDatastoreProviderProps) => {
  const formDatastoreRef = useRef<FormDatastoreApi | null>(null);
  if (!formDatastoreRef.current) {
    formDatastoreRef.current = initFormDatastore(clientPortalId);
  }

  return (
    <FormDatastoreContext.Provider value={formDatastoreRef.current}>
      {children}
    </FormDatastoreContext.Provider>
  );
};
