import { ReactNode, useRef } from 'react';

import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
} from '@chakra-ui/react';

import { forwardRef } from '../../utils/component-factory';
import { Portal } from '../../utils/portal';

export type ConfirmPopoverProps = {
  children: ReactNode | ((state: { isOpen: boolean }) => ReactNode);

  showCloseButton?: boolean;
  header?: string;
  body: ReactNode;

  isOpen: boolean;

  /**
   * @default "Cancel"
   */
  cancelLabel?: string;
  onCancel: () => void;
  /**
   * @default "Confirm"
   */
  confirmLabel?: string;
  onConfirm: () => void;
  isConfirmLoading?: boolean;
} & Omit<
  PopoverProps,
  | 'ref'
  | 'children'
  | 'isOpen'
  | 'defaultIsOpen'
  | 'onOpen'
  | 'onClose'
  | 'trigger'
>;

/**
 * Display a confirmation popover around the children element.
 * The `open` stats of the popover is **controlled** by the prop `isOpen`.
 *
 * @param props
 * @returns
 */
export const ConfirmPopover = forwardRef(
  (
    {
      children,
      showCloseButton,
      header,
      body,
      isOpen,
      cancelLabel = 'Cancel',
      onCancel,
      confirmLabel = 'Confirm',
      onConfirm,
      isConfirmLoading,
      ...popoverProps
    }: ConfirmPopoverProps,
    ref,
  ): JSX.Element => {
    const initialFocusRef = useRef<HTMLButtonElement | null>(null);

    return (
      <Popover
        initialFocusRef={initialFocusRef}
        placement="right"
        isLazy
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onCancel}
        {...popoverProps}
      >
        {(state) => (
          <>
            <PopoverTrigger>
              {typeof children === 'function' ? children(state) : children}
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="min-content" ref={ref}>
                {header && <PopoverHeader>{header}</PopoverHeader>}
                <PopoverArrow />
                {showCloseButton && <PopoverCloseButton />}
                <PopoverBody>{body}</PopoverBody>
                <PopoverFooter>
                  <ButtonGroup>
                    <Button size="md" variant="ghost" onClick={onCancel}>
                      {cancelLabel}
                    </Button>
                    <Button
                      size="md"
                      variant="solid"
                      onClick={onConfirm}
                      ref={initialFocusRef}
                      isLoading={isConfirmLoading}
                    >
                      {confirmLabel}
                    </Button>
                  </ButtonGroup>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    );
  },
);
