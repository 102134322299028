import { CompanyTypeEnum } from '@dotfile/shared/data-access-client-portal';

import { FormDatastoreState } from './datastore';

export const mainCompanySelector = (state: FormDatastoreState) =>
  state.data.companies?.find(
    (company) => company.type === CompanyTypeEnum.main,
  );

export const companySearchRefSelector = (state: FormDatastoreState) =>
  state.companySearch.searchRef;

export const affiliatedCompaniesSelector = (state: FormDatastoreState) =>
  state.data.companies?.filter(
    (company) => company.type === CompanyTypeEnum.affiliated,
  );

export const businessContactSelector = (state: FormDatastoreState) =>
  state.data.individuals?.find((individual) => individual.isBusinessContact);

/**
 * Select the index of the individual for forms in KYC steps
 *
 * Will find the existing business contact if any or returns index 0
 */
export const kycIndividualIndexSelector = (
  state: FormDatastoreState,
): number => {
  if (!state.data.individuals) {
    return 0; // Will insert the individual at index 0
  }

  // First attempt to find existing business contact
  const index = state.data.individuals.findIndex(
    (individual) => individual.isBusinessContact,
  );
  if (index > -1) {
    return index;
  }

  // Use the first individual if there is none marked as business contact yet
  return 0;
};
