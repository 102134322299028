import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@dotfile/frontend/shared/design-system';

type SupportButtonProps = {
  supportEmail: string | null | undefined;
} & ButtonProps;

export const SupportButton = ({
  supportEmail,
  ...props
}: SupportButtonProps) => {
  const { t } = useTranslation();

  return (
    supportEmail && (
      <Button as="a" href={`mailto:${supportEmail}`} {...props}>
        {t('errors.default.contact_support', {
          ns: 'client-portal',
          defaultValue: 'Contact support',
        })}
      </Button>
    )
  );
};
