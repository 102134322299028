import { ErrorInfo } from 'react';

export function createReactRenderingError(error: Error, info: ErrorInfo) {
  // @see https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
  const renderingError = new Error(error.message);
  renderingError.name = `ReactRenderingError`;
  if (info.componentStack) {
    renderingError.stack = info.componentStack;
  }
  renderingError.cause = error;
  return renderingError;
}
