import { ForwardedRef, useEffect, useImperativeHandle } from 'react';

import { useCurrentEditor } from '@tiptap/react';

import { forwardRef } from '../../utils/component-factory';
import { RichTextHandle, RichTextModeEnum } from './types';

type SyncEditorProps = {
  mode: RichTextModeEnum;
};

/**
 * The `mode`/`isEditable` and the `content` props are not reactive on the EditorContext and are only set on initialization/mount
 * This component synchronizes the update to those props into the editor
 * @see https://github.com/ueberdosis/tiptap/issues/3775
 */
export const SyncEditor = forwardRef(
  ({ mode }: SyncEditorProps, ref: ForwardedRef<RichTextHandle>): null => {
    const { editor } = useCurrentEditor();
    useEffect(() => {
      if (editor) {
        editor.setEditable(mode === RichTextModeEnum.edit);
      }
    }, [editor, mode]);

    useImperativeHandle(ref, () => ({
      setContent: (content) => {
        if (
          editor &&
          // Prevent loosing selection when adding bold/italic/...
          editor.getHTML() !== content
        ) {
          editor.chain().clearContent().insertContent(content).run();
        }
      },
      focus: () => {
        if (editor) {
          editor.commands.focus('start');
        }
      },
    }));

    return null;
  },
);
