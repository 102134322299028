import { match, P } from 'ts-pattern';

import { VStack } from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalChecks_CheckInterface,
  ClientPortalChecks_DocumentCheck,
  ClientPortalChecks_IdDocumentCheck,
  ClientPortalChecks_IdVerificationCheck,
} from '@dotfile/shared/data-access-client-portal';
import { CheckTypeEnum } from '@dotfile/shared/domain';

import { DocumentCheck } from '../../document';
import { IdDocumentCheck } from '../../id-document';
import { IdVerificationCheck } from '../../id-verification';

export const ChecksCardList = ({
  checks,
}: {
  checks: ClientPortalChecks_CheckInterface[];
}) => {
  return (
    <VStack spacing="0" align="stretch" width="full">
      {checks.map((check) => (
        <VStack
          key={check.id}
          spacing="0"
          border="1px solid"
          width="full"
          borderColor="gray.200"
          borderTop="0px"
          _first={{
            borderTopRadius: 'lg',
            borderTop: '1px solid',
            borderTopColor: 'gray.200',
          }}
          _last={{
            borderBottomRadius: 'lg',
          }}
          padding="4"
        >
          {match(check)
            .with(
              P.when(
                (check): check is ClientPortalChecks_DocumentCheck =>
                  check.type === CheckTypeEnum.document,
              ),
              (check) => <DocumentCheck check={check} />,
            )
            .with(
              P.when(
                (check): check is ClientPortalChecks_IdVerificationCheck =>
                  check.type === CheckTypeEnum.id_verification,
              ),
              (check) => <IdVerificationCheck check={check} />,
            )
            .with(
              P.when(
                (check): check is ClientPortalChecks_IdDocumentCheck =>
                  check.type === CheckTypeEnum.id_document,
              ),
              (check) => <IdDocumentCheck check={check} />,
            )
            .exhaustive()}
        </VStack>
      ))}
    </VStack>
  );
};
