import { SchemaOf } from 'yup';

import {
  CheckTypeEnum,
  DocumentDataModel,
  DocumentDataSettingsModel,
  EntityTypeEnum,
  PermissionResourceEnum,
  VendorEnum,
} from '../../../shared/models';
import {
  BaseCheckDefinition,
  BaseCheckSettingsDefinition,
} from '../definitions/base-check-definition';
import { FindOptions } from '../definitions/types';
import { DocumentCheckInput } from './document-check-input';
import { documentDataSchema, documentSettingsSchema } from './validation';

const SUBTYPE_DOCUMENT_TYPE_KEY = 'document_type';
const SUBTYPE_CUSTOM_DOCUMENT_TYPE_KEY = 'custom_document_type';

export class DocumentCheckDefinition extends BaseCheckDefinition<
  DocumentDataModel,
  DocumentCheckInput
> {
  // General
  // -------

  get type(): CheckTypeEnum {
    return CheckTypeEnum.document;
  }
  get icon(): string {
    return 'FileSearch';
  }
  get checkRelationKey(): 'documentData' {
    return 'documentData';
  }
  public findOptions(): FindOptions<DocumentDataModel> {
    return {
      relations: ['files', 'files.file', 'settings.customDocumentType'],
      order: { createdAt: 'DESC', files: { position: 'ASC' } },
    };
  }
  get availableTarget(): EntityTypeEnum[] {
    return [EntityTypeEnum.individual, EntityTypeEnum.company];
  }
  public override resolveSubtype(
    settings: Partial<DocumentDataSettingsModel>,
  ): string {
    // TODO - E-4952 - migrate to documentTypeKey, no more customDocumentTypeId in subtype
    //  https://linear.app/dotfile/issue/E-4952/console-migration-to-document-type-key
    if (settings.documentType) {
      return `${SUBTYPE_DOCUMENT_TYPE_KEY}:${settings.documentType}`;
    }
    if (settings.customDocumentTypeId) {
      return `${SUBTYPE_CUSTOM_DOCUMENT_TYPE_KEY}:${settings.customDocumentTypeId}`;
    }

    throw new Error('Cannot resolve subtype of document check');
  }

  get permissionResource(): PermissionResourceEnum {
    return PermissionResourceEnum.check_document;
  }

  // Integrations
  // ------------

  get availableVendors(): VendorEnum[] {
    return [];
  }

  // Settings
  // --------

  get settings(): BaseCheckSettingsDefinition<DocumentDataModel> {
    return { default: {} };
  }

  // Display
  // -------

  get label(): string {
    return 'Document';
  }

  // Validation
  // ------

  override get validationSchemas(): {
    settings?: SchemaOf<DocumentCheckInput['settings']>;
    data?: SchemaOf<DocumentCheckInput['data']>;
  } {
    return {
      settings: documentSettingsSchema(),
      data: documentDataSchema(),
    };
  }
}

export const documentCheckDefinition = new DocumentCheckDefinition();
