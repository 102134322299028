import { RumInitConfiguration } from '@datadog/browser-rum';

export const datadogRUMDefaultConfig: Partial<RumInitConfiguration> = {
  site: 'datadoghq.eu',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
};
