import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ElementOf } from 'ts-essentials';

import { GroupController } from '@dotfile/frontend/shared/components';
import { SelectMenu, Text } from '@dotfile/frontend/shared/design-system';

import { FormDatastoreIndividual, useFormDatastore } from '../shared';
import { IndividualItem } from './individual-item';
import { FormValues } from './use-business-contact-step-form';

const NEW_INDIVIDUAL = 'NEW_INDIVIDUAL';

export const BusinessContactSelectController = ({
  isBusinessContactLocked,
  onAddNew,
}: {
  isBusinessContactLocked?: boolean;
  onAddNew: () => void;
}) => {
  const { t } = useTranslation();
  const { control, setValue, watch, clearErrors } =
    useFormContext<FormValues>();

  const individuals = useFormDatastore((state) => state.data.individuals);
  const individualOptions: {
    value: string;
    individual: Partial<FormDatastoreIndividual> | null;
  }[] = useMemo(
    () => [
      ...(individuals
        ?.filter((i) => !i.isDelegator)
        .map((individual, index) => ({
          value: index.toString(),
          individual,
        })) || []),
      {
        value: NEW_INDIVIDUAL,
        individual: null,
      },
    ],
    [individuals],
  );

  const onSelectBusinessContact = useCallback(
    ({ value }: { value: string }) => {
      if (value === NEW_INDIVIDUAL) {
        // Open drawer to add new Individual as BusinessContact
        onAddNew();
      } else {
        setValue('businessContactIndex', value, { shouldValidate: true });

        // Reset isBusinessContactTheAuthorizedSignatory / signatoryIndex / isSignatoryLegalRepresentative
        // and remove errors when business contact changes
        setValue('isBusinessContactTheAuthorizedSignatory', null);
        setValue('signatoryIndex', '');
        setValue('isSignatoryLegalRepresentative', false);

        clearErrors();
      }
    },
    [onAddNew, setValue, clearErrors],
  );

  // When business contact change, update email accordingly
  const newBusinessContactIndex = watch('businessContactIndex');
  useLayoutEffect(() => {
    if (individuals && newBusinessContactIndex) {
      const { email } = individuals[Number(newBusinessContactIndex)];
      setValue('email', email ?? '', { shouldValidate: true });
    }
  }, [newBusinessContactIndex, setValue, individuals]);

  return (
    <GroupController
      isRequired
      name="businessContactIndex"
      label={t(`forms.business_contact.select.label`, {
        ns: 'client-portal',
        defaultValue: 'Who are you?',
      })}
      control={control}
      render={(field) => (
        <SelectMenu
          {...field}
          buttonProps={{
            w: '100%',
            isDisabled: isBusinessContactLocked,
          }}
          variant="select"
          onChange={onSelectBusinessContact}
          options={individualOptions}
          // @NOTE: option require to explicitly define typing, inferred will resolve to {value: Type} from SelectMenu
          renderOption={(option: ElementOf<typeof individualOptions>) => (
            <IndividualItem
              individual={option.individual}
              addLabel={t(`forms.business_contact.select.add.option`, {
                ns: 'client-portal',
                defaultValue: 'Add new individual',
              })}
            />
          )}
        >
          {field.value && individuals ? (
            <IndividualItem individual={individuals[Number(field.value)]} />
          ) : (
            <Text align="start">
              {t(`forms.business_contact.business_contact.placeholder`, {
                ns: 'client-portal',
                defaultValue: 'Select ...', // Same as other <Select /> but this one is a <SelectMenu /> so the placeholder has to be set here
              })}
            </Text>
          )}
        </SelectMenu>
      )}
    />
  );
};
