import { DeepPartial } from 'ts-essentials';

import {
  IdVerificationDataModeEnum,
  IdVerificationVendorEnum,
  WorkspaceSettingsIdVerificationModel,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';

export const identityVerificationSchema = (): yup.SchemaOf<
  Omit<WorkspaceSettingsIdVerificationModel, 'legacyUbble'>
> =>
  yup.object().shape({
    vendor: yup
      .mixed()
      .oneOf(Object.values(IdVerificationVendorEnum))
      .required('Vendor is required'),
    mode: yup
      .mixed()
      .oneOf(Object.values(IdVerificationDataModeEnum))
      .required('Mode is required'),
    automaticApproval: yup.boolean().required(),
    automaticRejection: yup.boolean().required(),
    automaticExpiration: yup.boolean().required(),
    redirectUrl: yup.string().url().nullable().defined().label('Redirect URL'),
  });

export const validateWorkspaceSettingsIdVerification = (
  communicationSettings: DeepPartial<WorkspaceSettingsIdVerificationModel> = {},
): void => {
  identityVerificationSchema().validateSync(communicationSettings, {
    abortEarly: false,
  });
};
