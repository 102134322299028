export const TemplateRuleTargetEnum = {
  individual_any: 'individual_any',
  individual_shareholder: 'individual_shareholder',
  individual_beneficial_owner: 'individual_beneficial_owner',
  individual_legal_representative: 'individual_legal_representative',
  individual_delegator: 'individual_delegator',
  individual_signatory: 'individual_signatory',
  individual_business_contact: 'individual_business_contact',
  company_main: 'company_main',
  company_affiliated: 'company_affiliated',
} as const;
export type TemplateRuleTargetEnum =
  (typeof TemplateRuleTargetEnum)[keyof typeof TemplateRuleTargetEnum];
