import { FormDatastoreCompany, FormDatastoreIndividual } from '../context';

/**
 * Validate that the data store contains all the required data to allow to save for later and create
 * case, companies and individuals:
 * - all individuals must have a first and last name OR only one individual (for KYC)
 * - all companies must have a name
 * isAuthenticated [optional]: do not display SaveForLater if the contact is authenticated, autoSave will take the lead on the next button
 * @returns
 */
export const canSaveForLater = ({
  individuals,
  companies,
  isAuthenticated,
}: Partial<{
  individuals: FormDatastoreIndividual[];
  companies: FormDatastoreCompany[];
  isAuthenticated?: boolean;
}>): boolean => {
  if (isAuthenticated !== undefined && isAuthenticated) {
    return false;
  }
  if (individuals && individuals.some((i) => !i.firstName || !i.lastName)) {
    return false;
  }

  if (companies && companies.some((c) => !c.name)) {
    return false;
  }

  return true;
};
