import { MenuListProps } from 'chakra-react-select';
import { useCallback, useMemo, useState } from 'react';
import {
  FieldValues,
  UseFieldArrayUpdate,
  useFormContext,
} from 'react-hook-form';

import {
  GenericSelect,
  Input,
  Option,
} from '@dotfile/frontend/shared/design-system';
import {
  companyClassifications,
  CompanyClassificationTypeEnum,
} from '@dotfile/shared/domain';

import { GroupController } from '../../../input';
import { VirtualizedMenuList } from './virtualized-menu-list';

export type CompanyClassificationCodeFieldProps = {
  index: number;
  update: UseFieldArrayUpdate<FieldValues, string>;
  isManual: boolean;
  label: string;
  name?: string;
};
export const CompanyClassificationCodeField = ({
  index,
  update,
  name = '',
  isManual,
  label,
}: CompanyClassificationCodeFieldProps) => {
  const { control, watch, trigger } = useFormContext();
  const prefix = useMemo(() => `${name}.${index}`, [index, name]);
  const classification = watch(prefix);
  const knownType = classification.type in CompanyClassificationTypeEnum;
  const [searchCode, setSearchCode] = useState('');

  const companyClassification = useMemo(
    () =>
      knownType
        ? companyClassifications[
            classification.type as CompanyClassificationTypeEnum
          ]
        : [],
    [classification.type, knownType],
  );

  const options = useMemo(
    () =>
      companyClassification
        .map(({ code, description }) => ({
          value: code,
          label: `${code} - ${description}`,
        }))
        .filter(
          (e) => !searchCode || new RegExp(searchCode, 'i').test(e.label),
        ),
    [companyClassification, searchCode],
  );

  const MenuList = useCallback(
    (props: MenuListProps<Option, false>): JSX.Element => (
      <VirtualizedMenuList
        selected={classification.code}
        onChange={props.selectOption}
        options={options}
      />
    ),
    [options, classification],
  );

  return (
    <GroupController
      name={`${prefix}.code`}
      label={label}
      isRequired
      isDisabled={!classification.type}
      control={control}
      render={(field) =>
        !isManual ? (
          <GenericSelect
            {...field}
            isSearchable
            isClearable
            minMenuHeight={400}
            components={{
              MenuList,
            }}
            options={options}
            onInputChange={(value) => setSearchCode(value)}
            onChange={(value) => {
              update(index, {
                ...classification,
                code: value?.value ?? '',
                description: value?.value
                  ? companyClassifications[
                      classification.type as CompanyClassificationTypeEnum
                    ]?.find(({ code }) => value.value === code)?.description
                  : '',
              });
              // Retrigger full field for uniqueness validation
              trigger(name);
            }}
            value={
              classification.code
                ? options.find(({ value }) => classification.code === value)
                : null
            }
          />
        ) : (
          <Input
            {...field}
            value={field.value}
            onChange={(event) => {
              field.onChange(event);
              // Retrigger full field for uniqueness validation
              trigger(name);
            }}
          />
        )
      }
    />
  );
};
