import { TFunction } from 'i18next';

// Inspired from the yup locale structure but only with the validator used in the client-portal
// @see https://github.com/jquense/yup/blob/v0.32.11/src/locale.ts

// Pass the namespace as prefix to make below function less verbose
const validationPrefix = 'collaboration.invite.validation';
const emailPrefix = 'collaboration.invite.email';
const customMessagePrefix = 'collaboration.invite.custom_message';

export const getLocalizedValidation = (t: TFunction) => ({
  required: t(`${validationPrefix}.required`, {
    defaultValue: 'This field is required',
    ns: 'client-portal',
  }),
  email: {
    invalid: t(`${emailPrefix}.invalid`, {
      defaultValue: 'Must be a valid email address',
      ns: 'client-portal',
    }),
  },
  customMessage: {
    max_length: t(`${customMessagePrefix}.too_long`, {
      // This do not indicate the exact limit since it is computed including the html tag so does not match
      // the character actually typed
      defaultValue: 'This message is too long',
      ns: 'client-portal',
    }),
  },
});

export type LocalizedValidation = ReturnType<typeof getLocalizedValidation>;
