import { useEffect, useState } from 'react';

export const useRedirectAfterDelay = (
  url: string | null | undefined,
  delay = 5000,
): number => {
  const [timeLeft, setTimeLeft] = useState(delay / 1000);

  useEffect(() => {
    if (!url) return; // Don't trigger redirect if URL is null or undefined

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          window.location.href = url;
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [url, delay]);

  return timeLeft;
};
