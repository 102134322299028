import {
  CheckTypeEnum,
  ClientPortalSettingWordingModel,
  DocumentTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { WORDING_LABEL_MAX_LENGTH } from '../../constants';

type CheckWordingOption = {
  labels?: { label?: string; description?: string };
};
const checkWordingSchema = ({ labels }: CheckWordingOption = {}) => {
  let label = yup.string().max(WORDING_LABEL_MAX_LENGTH).required();
  if (labels?.label) {
    label = label.label(labels.label);
  }

  let description = yup.string().optionalString();
  if (labels?.description) {
    description = description.label(labels.description);
  }

  return yup
    .object({
      label,
      description,
    })
    .default(null)
    .nullable()
    .optional();
};

type DocumentWording<
  T extends DocumentTypeEnum | string = DocumentTypeEnum | string,
> = Record<
  `${typeof CheckTypeEnum.document}__${T}`,
  ReturnType<typeof checkWordingSchema>
>;

export const clientPortalWordingSchema = (
  customDocumentTypeKeys: string[],
  options?: CheckWordingOption,
): yup.SchemaOf<ClientPortalSettingWordingModel> => {
  const wordingSchema = checkWordingSchema(options);

  return yup
    .object()
    .shape({
      checks: yup
        .object({
          [CheckTypeEnum.id_document]: wordingSchema,
          [CheckTypeEnum.id_verification]: wordingSchema,
          ...[
            ...Object.values(DocumentTypeEnum),
            ...customDocumentTypeKeys,
          ].reduce<DocumentWording>(
            (acc, documentType) => ({
              ...acc,
              [`${CheckTypeEnum.document}__${documentType}`]: wordingSchema,
            }),
            {} as DocumentWording,
          ),
        })
        .noUnknown()
        .optional(),
    })
    .defined()
    .noUnknown();
};
