import { DocumentAnalysisModelEnum } from '../../../../../../shared/models';
import { DocumentAnalysisIBANParametersModel } from '../../../../../../shared/models/checks/document/document-analysis/iban/document-analysis-iban-parameters.model';
import yup from '../../../../../../utils/yup-extended';

export const documentAnalysisIBANParametersSchema = (options?: {
  forceDefined?: boolean;
}): yup.SchemaOf<DocumentAnalysisIBANParametersModel> => {
  const definedOrOptional = options?.forceDefined ? 'defined' : 'optional';

  return yup
    .object({
      model: yup.mixed().is([DocumentAnalysisModelEnum.iban]).required(),
      ibanMatching: yup.boolean()[definedOrOptional](),
      bicMatching: yup.boolean()[definedOrOptional](),
    })
    .noUnknown()
    .required()
    .strict();
};
