import { subEnum } from './sub-enum.helper';
import { VendorEnum } from './vendor.enum';

export const IdVerificationVendorEnum = subEnum(VendorEnum, [
  VendorEnum.ubble,
  VendorEnum.checkout,
  VendorEnum.veriff,
  VendorEnum.onfido,
]);
export type IdVerificationVendorEnum =
  (typeof IdVerificationVendorEnum)[keyof typeof IdVerificationVendorEnum];
