import { DownloadIcon } from 'lucide-react';

import { useSaveFile } from '@dotfile/frontend/shared/components';
import {
  Button,
  ButtonProps,
  Icon,
  Spinner,
} from '@dotfile/frontend/shared/design-system';
import { FileFragment } from '@dotfile/shared/data-access-client-portal';

type DownloadButtonProps = {
  file: FileFragment;
} & Omit<ButtonProps, 'onClick' | 'rightIcon'>;

export const DownloadButton = ({
  file,
  ...buttonProps
}: DownloadButtonProps) => {
  const saveFile = useSaveFile({ showSuccessToast: true });

  return (
    <Button
      onClick={() => saveFile.mutate(file)}
      rightIcon={
        saveFile.isPending ? <Spinner size="xs" /> : <Icon as={DownloadIcon} />
      }
      {...buttonProps}
      variant="link"
      noOfLines={1}
    >
      {file.name}
    </Button>
  );
};
