import { DeepNullable } from 'ts-essentials';

import { normalize } from '@dotfile/shared/common';

import { IndividualModel } from '../../../../shared/models';

export type MaybeComparableIndividual = DeepNullable<
  Partial<ComparableIndividual>
>;
type ComparableIndividual = Pick<IndividualModel, 'firstName' | 'lastName'>;

type BoundIndividualComparisonFunction = (
  other: MaybeComparableIndividual,
) => boolean;

/**
 * Create a instance to compare `current` individual with multiple other individuals
 */
export class IndividualComparator {
  /**
   * Determine if an individual is comparable, that mean it
   * has a first and last name
   *
   * @param individual
   * @returns
   */
  public static isIndividualComparable(
    individual: MaybeComparableIndividual,
  ): individual is ComparableIndividual {
    return !!individual.firstName && !!individual.lastName;
  }

  private readonly _isComparable: boolean;
  private readonly _isSame: BoundIndividualComparisonFunction;

  constructor(current: MaybeComparableIndividual) {
    if (!IndividualComparator.isIndividualComparable(current)) {
      this._isComparable = false;
      this._isSame = () => false;
    } else {
      this._isComparable = true;

      const sluggedNames = this.normalizeNames(current);
      this._isSame = (other: MaybeComparableIndividual) =>
        IndividualComparator.isIndividualComparable(other) &&
        this.normalizeNames(other) === sluggedNames;
    }
  }

  /**
   * Whether `current` individual is comparable.
   *
   * When it is not, `isSame` will always returns false without checking the `other`
   * individual
   */
  get isComparable(): boolean {
    return this._isComparable;
  }

  /**
   * Determine if `other` individual is the same.
   *
   * Will compare each first and last name, transforming to lowercase, ignoring spaces/dashes
   * and removing combining diacritical marks.
   *
   * Will return false when any individuals is missing first or last name
   *
   * @param other
   * @returns
   */
  public isSame(other: MaybeComparableIndividual): boolean {
    return this._isSame(other);
  }

  private normalizeNames(individual: ComparableIndividual): string {
    return normalize(`${individual.firstName}${individual.lastName}`, {
      removeWhiteSpace: true,
      removeCharacters: '-',
    });
  }
}
