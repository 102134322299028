import { useCallback } from 'react';

import {
  ClientPortalForms_Block,
  ClientPortalForms_BlockField,
  ClientPortalLogicTypeEnum,
} from '@dotfile/shared/data-access-client-portal';
import {
  ClientPortalBlockTypeEnum,
  clientPortalCompanyPropertiesDefinition,
  generateKeyFromMapping,
  jsonLogic,
} from '@dotfile/shared/domain';

import { FieldsValues } from '../utils';
import {
  buildLocalDataContext,
  LogicDataContext,
} from './data-context-builder';
import { useGlobalDataContext } from './use-global-data-context';

type BlockLogicMatrixes = {
  display: Record<string, boolean>;
  required: Record<string, boolean>;
};

export const useBlocksLogic = (blocks: ClientPortalForms_Block[]) => {
  const globalDataContext = useGlobalDataContext();

  const computeBlockLogics = useCallback(
    (formValues: FieldsValues) => {
      const matrix = computeBlocksLogic(
        globalDataContext(),
        blocks,
        formValues,
      );

      const displayedBlocks = blocks
        .filter((block) => matrix.display[block.key])
        .map((field) => ({
          ...field,
          // This will be taken into account to display the required indicator and compute the validation schema
          isRequired: matrix.required[field.key],
        }));

      const displayedFields = displayedBlocks.filter(isBlockField);

      const hiddenFields = blocks
        .filter((block) => !matrix.display[block.key])
        .filter(isBlockField);

      return { displayedBlocks, displayedFields, hiddenFields };
    },
    [blocks, globalDataContext],
  );

  return computeBlockLogics;
};

function computeBlocksLogic(
  globalDataContext: LogicDataContext['global'],
  blocks: ClientPortalForms_Block[],
  formValues: FieldsValues,
): BlockLogicMatrixes {
  const fields = blocks.filter(
    (b): b is ClientPortalForms_BlockField =>
      b.type === ClientPortalBlockTypeEnum.field,
  );
  const localDataContext = buildLocalDataContext({ fields, formValues });

  const dataContext = {
    local: localDataContext,
    global: globalDataContext,
  };

  const matrix: BlockLogicMatrixes = { display: {}, required: {} };

  for (const block of blocks) {
    matrix.display[block.key] = computeMaybeLogic(
      block,
      ClientPortalLogicTypeEnum.display,
      dataContext,
      true,
    );
  }

  for (const field of fields) {
    matrix.required[field.key] = computeMaybeLogic(
      field,
      ClientPortalLogicTypeEnum.required,
      dataContext,
      field.isRequired, // Fallback when
    );
  }

  return matrix;
}

function computeMaybeLogic(
  block: ClientPortalForms_Block,
  logicType: ClientPortalLogicTypeEnum,
  dataContext: LogicDataContext,
  defaultValue: boolean,
) {
  // @TODO - 5149 - Client portal: Properly fix "never not required" Company status field
  // Remove this dirty hack
  if (
    block.key ===
      generateKeyFromMapping(
        clientPortalCompanyPropertiesDefinition.status.mapping,
      ) &&
    logicType === ClientPortalLogicTypeEnum.required
  ) {
    return true;
  }

  const logic = block.logics.find(
    (logic) => logic.type === logicType,
  )?.jsonLogic;

  if (logic) {
    const result = jsonLogic.apply(logic, dataContext);
    return result;
  } else {
    return defaultValue;
  }
}

const isBlockField = (
  block: ClientPortalForms_Block,
): block is ClientPortalForms_BlockField =>
  block.type === ClientPortalBlockTypeEnum.field;
