import { omit } from 'lodash';

import { subEnum } from './sub-enum.helper';
import { VendorEnum } from './vendor.enum';

export const IntegrationVendorEnum = omit(
  {
    ...subEnum(VendorEnum, Object.values(VendorEnum)),
    veriff_idv: 'veriff_idv',
    veriff_idd: 'veriff_idd',
  } as const,
  ['veriff'],
);

export type IntegrationVendorEnum =
  (typeof IntegrationVendorEnum)[keyof typeof IntegrationVendorEnum];
