import { Trans } from 'react-i18next';

import { Text } from '@dotfile/frontend/shared/design-system';

type IsItTheRightDocumentForIndividualProps = {
  individualFullName: string;
  individualBirthDate: string | null;
};

/**
 * Similar to the component with the same name in id-verification/
 */
export const IsItTheRightDocumentForIndividual = ({
  individualFullName,
  individualBirthDate,
}: IsItTheRightDocumentForIndividualProps) => {
  return (
    <Text color="black">
      {individualBirthDate ? (
        <Trans
          i18nKey="checks.id_document.update_reference_data.is_it_the_right_for_individual_document_with_birth_date"
          ns="client-portal"
          defaults="Is this the right document for <b>{{individualFullName}}</b>, born <b>{{individualBirthDate}}</b>?"
          tOptions={{ individualFullName, individualBirthDate }}
        />
      ) : (
        <Trans
          i18nKey="checks.id_document.update_reference_data.is_it_the_right_for_individual_document"
          ns="client-portal"
          defaults="Is this the right document for <b>{{individualFullName}}</b>?"
          tOptions={{ individualFullName }}
        />
      )}
    </Text>
  );
};
