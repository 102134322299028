// @TODO - E-208 - use webpack to package the worker - https://react-pdf-viewer.dev/examples/compile-and-set-the-worker-source-with-webpack/
// BLOCKED for webpack5 -> https://gitlab.com/dotfile/dotfile/-/merge_requests/206#note_723243046
import { Worker } from '@react-pdf-viewer/core';

interface Props {
  children: React.ReactNode;
}

// @doc https://react-pdf-viewer.dev/docs/basic-usage/
export const PdfWorker = ({ children }: Props) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      {children}
    </Worker>
  );
};
