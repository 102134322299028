import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleObject } from '@chakra-ui/react';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
  SystemStyleObject,
} from '@chakra-ui/styled-system';
import { ComponentStyleConfig } from '@chakra-ui/theme';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/modal.ts
// for default ChakraUI theme
// Modal theme is also used for AlertDialog

const baseStyleOverlay: SystemStyleObject = {
  backdropFilter: 'blur(3px)',
};

const baseStyleHeader: SystemStyleObject = {
  color: 'white',
  fontWeight: '500',
  backgroundColor: 'gray.800',
  paddingY: 4,
  paddingX: 8,
  fontSize: 'lg',
};

const baseStyle: PartsStyleObject<typeof parts> = {
  overlay: baseStyleOverlay,
  header: baseStyleHeader,
};

const variantDialog: PartsStyleObject<typeof parts> = {
  closeButton: { top: 3, right: 4, color: 'white' },
  body: {
    p: 'unset',
    marginY: 8,
  },
  footer: {},
};

const variantOverview: PartsStyleObject<typeof parts> = {
  dialog: { borderRadius: 'xl', overflow: 'hidden' },
  header: {
    px: '10',
    py: '4',
    mb: 'unset',
    textAlign: 'initial',
    display: 'flex',
  },
  closeButton: { position: 'initial', color: 'gray.500' },
  body: {
    px: '0',
    py: '0',
  },
  footer: {
    justifyContent: 'end',
  },
};

const xxl = defineStyle({
  maxWidth: 'min(100%, 1440px)',
});
const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);
const sizes = {
  xxl: definePartsStyle({ dialog: xxl }),
};

export const DrawerTheme: ComponentStyleConfig = {
  baseStyle,
  sizes,
  variants: { dialog: variantDialog, overview: variantOverview },
  defaultProps: { variant: 'dialog' },
};
