import { useCallback } from 'react';

import {
  isGraphQLErrorWithCode,
  logAndAddError,
  useAuth,
} from '@dotfile/frontend/shared/common';
import {
  CaseQueryDocument,
  CompanyDataInput,
  IndividualDataInput,
  useCompleteFormsMutation,
  useCompleteFormsPublicMutation,
} from '@dotfile/shared/data-access-client-portal';

import { ContactAuthContext } from '../../../../shared';
import { useFormDatastoreApi } from '../context';

export const useCompleteForms = () => {
  const [completeForms, completeFormsResult] = useCompleteFormsMutation();
  const [completeFormsPublic, completeFormsPublicResult] =
    useCompleteFormsPublicMutation();

  const storeApi = useFormDatastoreApi();
  const { auth, setAuthToken } = useAuth<ContactAuthContext>();

  const run = useCallback(async () => {
    // Must ust getState to get up-to-date data, otherwise the closure contains stale value
    const storeData = storeApi.getState().data;

    try {
      const runMutation = auth.isAuthenticated
        ? completeForms
        : completeFormsPublic;

      // Submit data
      const { data } = await runMutation({
        variables: {
          input: {
            ...storeData.case,
            // Store contains data typed as fully partial but by construction, the client-portal
            // should collect all required fields / attributes
            companies: (storeData.companies ?? []) as CompanyDataInput[],
            individuals: (storeData.individuals ?? []) as IndividualDataInput[],
          },
        },
        // Need to refetch case query when submitting the case,
        // otherwise the ValidateRouteGuard will bring us back to forms as case.status is still draft
        refetchQueries: auth.isAuthenticated ? [CaseQueryDocument] : [],
        awaitRefetchQueries: true,
      });
      if (!data) {
        // data could be missing in result in some case
        // https://www.apollographql.com/docs/react/data/mutations/#mutationhookoptions-interface-ignoreresults
        throw new Error(`No data after form completions`);
      }

      // Reset store
      storeApi.getState().reset();

      // Extract authToken info from mutation
      const { accessToken, contactId, caseId, expiresAt } =
        'completeForms' in data ? data.completeForms : data.completeFormsPublic;

      // Set authenticated
      setAuthToken({
        accessToken,
        contactId,
        caseId,
        expiresAt,
      });
    } catch (error) {
      if (
        isGraphQLErrorWithCode(
          error,
          // BAD_USER_INPUT is an Apollo code when a mutation input variables are invalid
          // (for instance a required ! field missing)
          'BAD_USER_INPUT',
        )
      ) {
        // reset the store because this means that is it probably corrupted with invalid data
        storeApi.getState().reset();
      }

      logAndAddError(error, { store: storeData });
    }
  }, [auth, setAuthToken, storeApi, completeForms, completeFormsPublic]);

  return {
    run,
    // Should always call only one of the 2 mutations
    data: completeFormsResult.data || completeFormsPublicResult.data,
    error: completeFormsResult.error || completeFormsPublicResult.error,
    loading: completeFormsResult.loading || completeFormsPublicResult.loading,
  };
};
