import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationCheck = (): JSX.Element => {
  const { stroke, background, approved, rejected } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="0 0 482 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M407 1C407 41.8691 374.317 75 334 75L334 1L407 1Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M334 75C415.186 75 481 140.814 481 222L334 222L334 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M37 75C37 34.1309 69.6832 1 110 1L110 75L37 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M260 75C260 156.186 192.843 222 110 222L110 75L260 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
        <path
          d="M334 75C334 34.1309 300.869 0.999999 260 0.999997L260 75L334 75Z"
          stroke={stroke}
          strokeWidth="1.10769"
          strokeDasharray="11.08 11.08"
        />
      </g>
      <g filter="url(#filter0_d_1148_44015)">
        <rect
          x="154"
          y="46"
          width="248"
          height="136"
          rx="7.68"
          fill="white"
          shapeRendering="crispEdges"
        />
        <rect x="178" y="70" width="115" height="8" rx="4" fill={background} />
        <rect x="178" y="86" width="162" height="8" rx="4" fill={background} />
        <rect x="178" y="102" width="64" height="8" rx="4" fill={background} />
        <rect
          x="178"
          y="134"
          width="88"
          height="24"
          rx="3.84"
          fill={approved}
        />
        <rect
          x="290"
          y="134"
          width="88"
          height="24"
          rx="3.84"
          fill={rejected}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1148_44015"
          x="122.32"
          y="22.32"
          width="311.36"
          height="199.36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="7.68"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_1148_44015"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.557083 0 0 0 0 0.594813 0 0 0 0 0.7 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1148_44015"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1148_44015"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
