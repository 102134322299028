import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { logAndAddError } from '@dotfile/frontend/shared/common';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  GridItem,
  GridItemProps,
  useIsSmallScreen,
} from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalForms_BlockField,
  PropertyTypeEnum,
} from '@dotfile/shared/data-access-client-portal';

import { useCurrentStep } from '../../context';
import { generateFieldFormName } from '../../utils';
import {
  FieldAddress,
  FieldBankingInformation,
  FieldBoolean,
  FieldChoices,
  FieldClassifications,
  FieldCountries,
  FieldDate,
  FieldEmail,
  FieldEntityLegalForm,
  FieldNumeric,
  FieldPhoneNumber,
  FieldProps,
  FieldText,
  FieldUrl,
} from './field';
import { TransBlockDescription } from './shared';

type BlockFieldProps = {
  field: ClientPortalForms_BlockField;
} & GridItemProps;

export const _BlockField = ({
  field: { property, ...field },
  ...gridItemProps
}: BlockFieldProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();

  const step = useCurrentStep();
  useEffect(() => {
    if (!property) {
      logAndAddError(new Error(`Unresolved property for field ${field.id}`), {
        field,
        step,
      });
    }
  }, [field, property, step]);
  if (!property) {
    // Ignore field where the property was not resolved
    return null;
  }
  const colSpan = isSmallScreen
    ? // Always 2 column on small screen
      2
    : (
          [
            // 2 columns for this types
            PropertyTypeEnum.address,
            PropertyTypeEnum.banking_information,
            PropertyTypeEnum.classifications,
            PropertyTypeEnum.boolean,
            PropertyTypeEnum.choices,
            // @TODO - E-2270 - Add @total-typescript/ts-reset
            // `as` should not be necessary anymore
          ] as PropertyTypeEnum[]
        ).includes(property.type) ||
        // 2 columns for multiline (textarea) text
        (property.type === PropertyTypeEnum.text &&
          property.settings?.isMultiLine) ||
        // 2 columns for long description, containing line break or with list
        (field?.description &&
          (field.description.includes('<br>') ||
            field.description.includes('<li>')))
      ? 2
      : // 1 column otherwise
        1;

  let wrapperProps: FieldProps<PropertyTypeEnum>['wrapperProps'] = {
    as: GridItem,
    gridColumn: `span ${colSpan}/span ${colSpan}`,
    ...gridItemProps,
  };
  if (colSpan === 1) {
    wrapperProps = {
      ...wrapperProps,
      // Use grid + subgrid to align each fields on the input even with long labels
      display: 'grid',
      gridTemplateRows: 'subgrid',

      gridRow: `span 4/span 4`, // Span 4 rows for the label (1), input (2), helper (3) and error (4)
      gap: 0,
    };
  }

  const helperPosition = colSpan === 1 ? 'bottom' : 'top';

  const props: Omit<FieldProps<PropertyTypeEnum>, 'property'> &
    Omit<GridItemProps, 'property'> = {
    name: generateFieldFormName(field),
    label: t(`steps.${step.key}.blocks.${field.key}.label`, {
      ns: 'dynamic',
      defaultValue: field.label,
    }),
    helper: field.description ? (
      <TransBlockDescription block={field} />
    ) : undefined,

    field,
    isRequired: field.isRequired,
    helperNoOfLines: null,
    helperPosition,

    labelProps: {
      display: 'flex',
      alignItems: 'end',
    },
    wrapperProps,
  };

  return match(property)
    .with({ type: PropertyTypeEnum.address }, (property) => {
      return <FieldAddress property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.banking_information }, (property) => {
      return <FieldBankingInformation property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.boolean }, (property) => {
      return <FieldBoolean property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.choices }, (property) => {
      return <FieldChoices property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.classifications }, (property) => {
      return <FieldClassifications property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.countries }, (property) => {
      return <FieldCountries property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.date }, (property) => {
      return <FieldDate property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.email }, (property) => {
      return <FieldEmail property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.entity_legal_form }, (property) => {
      return <FieldEntityLegalForm property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.numeric }, (property) => {
      return <FieldNumeric property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.phone_number }, (property) => {
      return <FieldPhoneNumber property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.text }, (property) => {
      return <FieldText property={property} {...props} />;
    })
    .with({ type: PropertyTypeEnum.url }, (property) => {
      return <FieldUrl property={property} {...props} />;
    })
    .otherwise((property) => (
      <Alert status="warning" {...wrapperProps} gridTemplateRows={undefined}>
        <AlertIcon />
        <AlertTitle mr={2}>
          Property type {`'`}
          {property.type}
          {`'`} not implemented yet for field {`'`}
          {field.id}
          {`'`}
        </AlertTitle>
      </Alert>
    ));
};

export const BlockField = memo(_BlockField);
BlockField.displayName = 'BlockField';
