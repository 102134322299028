import { PropertyType, PropertyTypeEnum } from './types';

export const propertyTypesDefinition = {
  [PropertyTypeEnum.text]: {
    id: PropertyTypeEnum.text,
    name: 'Text',
    icon: 'type',
  },
  [PropertyTypeEnum.numeric]: {
    id: PropertyTypeEnum.numeric,
    name: 'Number',
    icon: 'hash',
  },
  [PropertyTypeEnum.boolean]: {
    id: PropertyTypeEnum.boolean,
    name: 'Boolean',
    icon: 'copy-check',
  },
  [PropertyTypeEnum.date]: {
    id: PropertyTypeEnum.date,
    name: 'Date',
    icon: 'calendar',
  },
  [PropertyTypeEnum.choices]: {
    id: PropertyTypeEnum.choices,
    name: 'Choices',
    icon: 'list',
  },
  [PropertyTypeEnum.countries]: {
    id: PropertyTypeEnum.countries,
    name: 'Countries',
    icon: 'flag',
  },
  [PropertyTypeEnum.email]: {
    id: PropertyTypeEnum.email,
    name: 'Email',
    icon: 'at-sign',
  },
  [PropertyTypeEnum.phone_number]: {
    id: PropertyTypeEnum.phone_number,
    name: 'Phone number',
    icon: 'phone',
  },
  [PropertyTypeEnum.url]: {
    id: PropertyTypeEnum.url,
    name: 'Url',
    icon: 'link',
  },
  [PropertyTypeEnum.address]: {
    id: PropertyTypeEnum.address,
    name: 'Address',
    icon: 'map-pin',
  },
  [PropertyTypeEnum.banking_information]: {
    id: PropertyTypeEnum.banking_information,
    name: 'Banking information',
    icon: 'landmark',
  },
  [PropertyTypeEnum.classifications]: {
    id: PropertyTypeEnum.classifications,
    name: 'Company classifications',
    icon: 'shapes',
  },
  [PropertyTypeEnum.entity_legal_form]: {
    id: PropertyTypeEnum.entity_legal_form,
    name: 'Entity legal form',
    icon: 'scale',
  },
  [PropertyTypeEnum.unknown]: {
    id: PropertyTypeEnum.entity_legal_form,
    name: 'Unknown',
    icon: 'boxes',
  },
} satisfies Record<PropertyTypeEnum, PropertyType>;

export const allPropertyTypesDefinition = Object.values(
  propertyTypesDefinition,
);
