import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import ReactQueryBuilder, {
  DefaultCombinator,
  FullCombinator,
  FullOperator,
  isRuleGroupType,
  Path,
} from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder-layout.css';

import { QueryBuilderChakra } from '@react-querybuilder/chakra';

import { createUseDebounce } from '@dotfile/frontend/shared/common';
import {
  PropertyTypeEnum,
  QueryBuilderOperator,
  QueryBuilderProperty,
  QueryBuilderRuleGroupType,
} from '@dotfile/shared/domain';

import { BoxProps } from '../../layout/box/box';
import { MAX_GROUP_DEPTH } from './constants';
import { controlElements } from './control-elements';
import { StyledWrapper } from './styled-wrapper';
import { TypedQueryBuilderField, TypedQueryBuilderProps } from './type';

export { type QueryBuilderFieldValueSelectorComponent } from './type';
export { ValueSelector as QueryBuilderValueSelector } from './value-selector';

const combinators = [
  { name: 'and', value: 'and', label: 'And' } as const,
  { name: 'or', value: 'or', label: 'Or' } as const,
] satisfies DefaultCombinator[];

const controlClassnames: TypedQueryBuilderProps['controlClassnames'] = {
  // Use a different class as in the doc because the style is completely re-written for inline combinators
  queryBuilder: 'queryBuilder-branchesInline',
};

export type QueryBuilderHandle = {
  setQuery: (query: QueryBuilderRuleGroupType) => void;
};

export type QueryBuilderProps = {
  /**
   * Array of fields or fields group available for logic. Can use the result of `getLogicBuilderFields` in **@dotfile/shared/domain**.
   *
   * @see https://react-querybuilder.js.org/docs/components/querybuilder#fields
   */
  fields: TypedQueryBuilderProps['fields'];

  /**
   * Initial json-logic value.
   *
   * @WARN The `defaultValue` props is not reactive.
   *
   * @see https://jsonlogic.com/
   */
  defaultValue: QueryBuilderRuleGroupType;
  /**
   * Called with the new json-logic after it has been changed in the builder.
   *
   * @see https://jsonlogic.com/
   */
  onChange: (newValue: QueryBuilderRuleGroupType) => void;

  /**
   * Generate the operators for a given `PropertyTypeEnum`. Can use `getLogicBuilderOperators` in **@dotfile/shared/domain**.
   *
   * @see https://react-querybuilder.js.org/docs/components/querybuilder#getoperators
   * @param type PropertyTypeEnum
   */
  getOperators?: (
    property: QueryBuilderProperty<PropertyTypeEnum>,
  ) => ReturnType<Required<TypedQueryBuilderProps>['getOperators']>;

  /**
   * @see https://react-querybuilder.js.org/docs/components/querybuilder#debugmode
   */
  debugMode?: TypedQueryBuilderProps['debugMode'];
} & Omit<BoxProps, 'value' | 'onChange' | 'defaultValue'>;

const useDebounce = createUseDebounce<[QueryBuilderRuleGroupType]>(300);

/**
 * Based on react-querybuilder
 * @see https://react-querybuilder.js.org/docs/
 */
export const QueryBuilder = forwardRef(
  (
    {
      fields,
      /**
       * @WARN The `defaultValue` props is not reactive.
       */
      defaultValue,
      onChange,
      getOperators,
      debugMode,
      ...boxProps
    }: QueryBuilderProps,
    ref: ForwardedRef<QueryBuilderHandle>,
  ): JSX.Element => {
    const [query, setQuery] = useState<QueryBuilderRuleGroupType>(defaultValue);

    useImperativeHandle(
      ref,
      () => ({
        setQuery,
      }),
      [],
    );

    const debouncedOnChange = useDebounce(
      (rqbQuery: QueryBuilderRuleGroupType) => {
        // Debounce the onChange to avoid formatting the query to json logic each time the query changes
        // otherwise key stroke on field value becomes un-responsive
        onChange(rqbQuery);
      },
    );

    const handleQueryChange = useCallback(
      (newQuery: QueryBuilderRuleGroupType) => {
        setQuery(newQuery);

        debouncedOnChange(newQuery);

        if (newQuery.rules.length === 0) {
          // Immediately flush changes when there is no rules anymore
          debouncedOnChange.flush();
        } else if (newQuery.rules.length === 1) {
          const onlyRule = isRuleGroupType(newQuery.rules[0])
            ? newQuery.rules[0].rules[0]
            : newQuery.rules[0];

          if (
            onlyRule &&
            'value' in onlyRule &&
            (!onlyRule.value ||
              (Array.isArray(onlyRule.value) && onlyRule.value.length === 0))
          ) {
            // Immediately flush changes when the only rule has no values,
            // like when adding a new rule or rule group on a previously empty query
            debouncedOnChange.flush();
          }
        }
      },
      [debouncedOnChange],
    );

    const onAddGroup = useCallback(
      (ruleGroup: QueryBuilderRuleGroupType, parentPath: Path) => {
        if (parentPath.length >= MAX_GROUP_DEPTH) {
          // Prevent adding the group
          return false;
        }

        return true;
      },
      [],
    );

    const getRqbOperators: TypedQueryBuilderProps['getOperators'] = useCallback(
      (
        fieldName: string,
        { fieldData }: { fieldData: TypedQueryBuilderField },
      ) => {
        return getOperators?.(fieldData.property) ?? null;
      },

      [getOperators],
    );

    return (
      <StyledWrapper {...boxProps}>
        <QueryBuilderChakra>
          <ReactQueryBuilder<
            QueryBuilderRuleGroupType,
            TypedQueryBuilderField,
            FullOperator<QueryBuilderOperator>,
            FullCombinator
          >
            controlClassnames={controlClassnames}
            controlElements={controlElements}
            query={query}
            onQueryChange={handleQueryChange}
            onAddGroup={onAddGroup}
            addRuleToNewGroups
            combinators={combinators}
            showCombinatorsBetweenRules
            getOperators={getRqbOperators}
            resetOnOperatorChange
            fields={fields}
            resetOnFieldChange
            listsAsArrays
            debugMode={debugMode}
          />
        </QueryBuilderChakra>
      </StyledWrapper>
    );
  },
);
QueryBuilder.displayName = 'QueryBuilder';
