import { get } from 'lodash';
import { useEffect, useMemo } from 'react';

import { useLocation } from '@swan-io/chicane';

import {
  ClientPortalTypeEnum,
  PropertyMappingEntityEnum,
} from '@dotfile/shared/data-access-client-portal';
import {
  ClientPortalFieldMapping,
  ClientPortalFieldMappingTypeEnum,
} from '@dotfile/shared/domain';

import { useLatestClientPortalVersion } from '../../../../shared';
import { useFormDatastore } from '../context';

type ClientPortalQueryParam = {
  key: string;
  type: ClientPortalTypeEnum[];
  mapping: ClientPortalFieldMapping;
  format?: (value: string) => string;
};
const clientPortalQueryParamDefinitions: ClientPortalQueryParam[] = [
  {
    key: 'externalId',
    type: [ClientPortalTypeEnum.KYB, ClientPortalTypeEnum.KYC],
    mapping: {
      entity: PropertyMappingEntityEnum.case,
      type: ClientPortalFieldMappingTypeEnum.default,
      key: 'externalId',
    },
  },
  // KYC
  {
    key: 'firstName',
    type: [ClientPortalTypeEnum.KYC],
    mapping: {
      entity: PropertyMappingEntityEnum.individual,
      type: ClientPortalFieldMappingTypeEnum.default,
      key: 'firstName',
    },
  },
  {
    key: 'lastName',
    type: [ClientPortalTypeEnum.KYC],
    mapping: {
      entity: PropertyMappingEntityEnum.individual,
      type: ClientPortalFieldMappingTypeEnum.default,
      key: 'lastName',
    },
  },
  {
    key: 'email',
    type: [ClientPortalTypeEnum.KYC],
    mapping: {
      entity: PropertyMappingEntityEnum.individual,
      type: ClientPortalFieldMappingTypeEnum.default,
      key: 'email',
    },
  },
  // KYB
  {
    key: 'country',
    type: [ClientPortalTypeEnum.KYB],
    mapping: {
      entity: PropertyMappingEntityEnum.company,
      type: ClientPortalFieldMappingTypeEnum.default,
      key: 'country',
    },
    format: (value) => value.toUpperCase(),
  },
  {
    key: 'companyName',
    type: [ClientPortalTypeEnum.KYB],
    mapping: {
      entity: PropertyMappingEntityEnum.company,
      type: ClientPortalFieldMappingTypeEnum.default,
      key: 'name',
    },
  },
  {
    key: 'registrationNumber',
    type: [ClientPortalTypeEnum.KYB],
    mapping: {
      entity: PropertyMappingEntityEnum.company,
      type: ClientPortalFieldMappingTypeEnum.default,
      key: 'registrationNumber',
    },
  },
];

/**
 * Set initial datastore from defined queryParams depending on the ClientPortal type
 * @returns
 */
export const useQueryParamInit = () => {
  const { data } = useLatestClientPortalVersion();
  const { patchMainCompanyData, patchCaseData, patchIndividualData } =
    useFormDatastore((state) => state);
  const caseId = useFormDatastore((state) => state.data.case?.id);

  const queryParamDefinitions = useMemo(
    () =>
      data?.latestClientPortalVersion.clientPortal.type &&
      clientPortalQueryParamDefinitions.filter((qp) =>
        qp.type.includes(data?.latestClientPortalVersion.clientPortal.type),
      ),
    [data?.latestClientPortalVersion.clientPortal.type],
  );

  const location = useLocation();

  // At init
  useEffect(() => {
    if (caseId) {
      // Ignore query params when there is an existing case
      return;
    }

    if (queryParamDefinitions) {
      // Extract relevant query params values
      for (const queryParam of queryParamDefinitions) {
        const value = get(location.search, queryParam.key, null);
        const valueString: string | null = Array.isArray(value)
          ? value[0]
          : value;

        if (valueString) {
          const prefillProperty = {
            [queryParam.mapping.key]: queryParam.format
              ? queryParam.format(valueString)
              : valueString,
          };

          switch (queryParam.mapping.entity) {
            case PropertyMappingEntityEnum.case:
              patchCaseData(prefillProperty);
              break;
            case PropertyMappingEntityEnum.company:
              patchMainCompanyData(prefillProperty);
              break;
            case PropertyMappingEntityEnum.individual:
              // @NOTE Only for KYC so far so only for the first and unique individual
              patchIndividualData(prefillProperty, 0);
              break;
            default:
              break;
          }
        }
      }
    }
  }, [
    location.search,
    patchCaseData,
    patchIndividualData,
    patchMainCompanyData,
    queryParamDefinitions,
    caseId,
  ]);
};
