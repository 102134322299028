import {
  forwardRef,
  TableCell,
  TableCellProps,
} from '@dotfile/frontend/shared/design-system';

export type CellProps = TableCellProps & { size?: number };

export const Cell = forwardRef<CellProps, 'td'>(
  ({ size, ...props }: CellProps, ref) => {
    return (
      <TableCell
        ref={ref}
        flex={`${size} 0 auto`}
        minWidth="0"
        width={`${size}px`}
        display="flex"
        alignItems="center"
        {...props}
      />
    );
  },
);
