import { lighten } from 'polished';

const DEFAULT_SWATCH = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];
const FACTOR = 0.001; // arbitrary

/**
 * Generate a swatch for a given color
 * @param color
 * @returns
 */
export function generateSwatch(
  color: string,
  swatch = DEFAULT_SWATCH,
  // 500 is the base color for most components when using colorScheme
  baseColorSwatch = 500,
) {
  return swatch.reduce((acc, amount) => {
    return {
      ...acc,
      [amount]: lighten((baseColorSwatch - amount) * FACTOR, color),
    };
  }, {});
}
