import LinkifyReact from 'linkify-react';
import { ComponentProps, useMemo } from 'react';
import { apiObject } from 'rudder-sdk-js';

import { Link as RouterLink } from '@swan-io/chicane';

import {
  EventName,
  useAnalyticsTrack,
  useEnvironmentContext,
} from '@dotfile/frontend/shared/common';
import {
  ExternalLink,
  forwardRef,
  Link,
} from '@dotfile/frontend/shared/design-system';

type LinkifyReactProps = ComponentProps<typeof LinkifyReact>;

/**
 * @see https://linkify.js.org/docs/options.html
 */
type LinkifyOptions = LinkifyReactProps['options'];

export type LinkifyProps = LinkifyReactProps & {
  trackClick?: { event: EventName; properties: apiObject };
};

export const Linkify = forwardRef<LinkifyProps, typeof LinkifyReact>(
  ({ children, as, tagName, options, trackClick }: LinkifyProps, ref) => {
    const track = useAnalyticsTrack();
    const { baseApp } = useEnvironmentContext();

    const _options = useMemo(() => {
      const defaultOptions: LinkifyOptions = {
        render: {
          url: ({ attributes, content, eventListeners }) => {
            const {
              href,
              target, // target is handled by ExternalLink or Link
              ...props
            } = attributes;

            const isExternal = !href.startsWith(baseApp);

            return isExternal ? (
              <ExternalLink href={href} {...props} {...eventListeners}>
                {content}
              </ExternalLink>
            ) : (
              <Link as={RouterLink} to={href} {...props} {...eventListeners}>
                {content}
              </Link>
            );
          },
        },
        events: {
          onClick: (e: MouseEvent) => {
            e.stopPropagation();

            if (trackClick) {
              track(trackClick.event, trackClick.properties);
            }
          },
          onAuxClick: () => {
            if (trackClick) {
              track(trackClick.event, trackClick.properties);
            }
          },
        },
      };
      const _options = { ...defaultOptions, ...options };

      return _options;
    }, [options, trackClick, track, baseApp]);

    return (
      <LinkifyReact as={as} tagName={tagName} options={_options} ref={ref}>
        {children}
      </LinkifyReact>
    );
  },
);
