import { List, LucideIcon } from 'lucide-react';
import { AnySchema, SchemaOf } from 'yup';

import {
  CustomPropertyChoicesSettings,
  CustomPropertyTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BaseCustomPropertyDefinition } from './base-custom-property-definition';
import {
  CustomPropertyFormatValueParam,
  CustomPropertyValueSchemaParam,
} from './type';

export class ChoicesCustomPropertyDefinition extends BaseCustomPropertyDefinition<CustomPropertyChoicesSettings> {
  // General
  // -------

  get type(): CustomPropertyTypeEnum {
    return CustomPropertyTypeEnum.choices;
  }

  get label(): string {
    return 'Choices';
  }

  get icon(): LucideIcon {
    return List;
  }

  override get hasOptions(): boolean {
    return true;
  }

  // Settings
  // --------

  override get defaultSettings(): CustomPropertyChoicesSettings {
    return { allowMultiple: false };
  }

  public override settingsSchema(): SchemaOf<CustomPropertyChoicesSettings> {
    const schema = yup.object().shape({
      allowMultiple: yup.boolean().required(),
    });
    return schema;
  }

  // Value
  // -----

  public valueSchema(
    customProperty: CustomPropertyValueSchemaParam,
  ): AnySchema {
    if (!customProperty.customPropertyOptions?.length) {
      throw new Error(
        `No options available for custom property \`${customProperty.key}\``,
      );
    }

    const { allowMultiple } = this.settingsSchema().validateSync(
      customProperty.settings,
    );
    const options =
      customProperty.customPropertyOptions.map((s) => s.key) ?? [];

    let baseSchema = yup.array().uniqueItems();
    if (!allowMultiple) {
      // Single choice: only one element but still an array
      baseSchema = baseSchema.max(1);
    }

    return baseSchema
      .nullable()
      .of(yup.string().nullable().oneOf(options))
      .strict();
  }

  override get multiValues(): boolean {
    return true;
  }

  public override formatValue({
    value,
    customPropertyOption,
  }: CustomPropertyFormatValueParam): string {
    return customPropertyOption ? customPropertyOption.label : value;
  }
}

export const choicesCustomPropertyDefinition =
  new ChoicesCustomPropertyDefinition();
