import { CaseStatusEnum } from '@dotfile/shared/data-access-client-portal';

import { ROOT_ROUTES } from './root-routes.constant';

export type GetValidRoutesParam = {
  caseStatus: CaseStatusEnum | null;
  caseContactHasAction: boolean;
  isCaseCreationAllowed: boolean;
  isFormInProgress: boolean;
};

type GetValidRoutesResult = Array<(typeof ROOT_ROUTES)[number]>;

/**
 * Compute the valid routes available depending on the authentication, case
 * status / contact has action, case creation allowed on client portal setting
 * and whether there is already a form in progress.
 */
export const getValidRoutes = ({
  caseStatus,
  caseContactHasAction,
  isCaseCreationAllowed,
  isFormInProgress,
}: GetValidRoutesParam): GetValidRoutesResult => {
  if (caseStatus) {
    if (caseStatus === CaseStatusEnum.draft) {
      return ['Forms'];
    } else if (
      caseContactHasAction &&
      (
        [
          CaseStatusEnum.open,
          CaseStatusEnum.approved,
          // @TODO - E-2270 - Add @total-typescript/ts-reset
          // `as` should not be necessary anymore
        ] as CaseStatusEnum[]
      ).includes(caseStatus)
    ) {
      return ['Checks'];
    } else {
      return ['End'];
    }
  } else {
    if (isCaseCreationAllowed) {
      if (isFormInProgress) {
        // @NOTE: Later, we could want to allow the Login route when form is in progress with a prompt to resume it
        // see https://www.notion.so/dotfile/Start-new-onboarding-fully-should-reset-the-session-c41ef00f7b2949328dba311419fbd2ba?pvs=4
        return ['Forms'];
      } else {
        // @NOTE: Only case when two route are valid: when not authenticated, no form in progress yet and
        // case creation is allowed, then both route are valid and can be open directly
        return ['Login', 'Forms'];
      }
    } else {
      return ['Login'];
    }
  }
};
