import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageEnum } from '@dotfile/shared/domain';

export const useLanguageState = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useTranslation();

  const setLanguage = useCallback(
    async (newLanguage: LanguageEnum) => {
      setIsLoading(true);
      try {
        await i18n.changeLanguage(newLanguage);
      } finally {
        setIsLoading(false);
      }
    },

    [i18n],
  );

  return [{ currentLanguage: i18n.language, isLoading }, setLanguage] as const;
};
