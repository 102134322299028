/**
 * A type guard is a kind of conditional check that narrows a type.
 * Type guards allow for run-time type checking by using expressions to see if a value is of a certain type or not.
 * Custom type guards are the most powerful kind of type guard, because we can verify any type,
 * including ones that we defined ourselves, as well as built-in types from JavaScript or the DOM.
 */

/**
 * Check if a `value` is a string
 * @param value
 * @returns
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export const isNotEmptyArray = (value: unknown): value is string[] | number[] =>
  Array.isArray(value) && value.length > 0;

export const isStringArray = (value: unknown): value is string[] =>
  isNotEmptyArray(value) && value.every((val) => typeof val === 'string');

/**
 * Check if a `value` is a not null or undefined
 * @param value
 * @returns
 */
export function isNotNullNotUndefined<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * Check is `key` is a key of `obj`
 * @param key
 * @param obj
 * @returns
 */
export function isKeyof<T extends Record<string, unknown>>(
  key: string | number | symbol,
  obj: T,
): key is keyof T {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
