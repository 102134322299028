import { SendHorizontal } from 'lucide-react';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from '@swan-io/chicane';

import { useAuth } from '@dotfile/frontend/shared/common';
import { GroupController } from '@dotfile/frontend/shared/components';
import {
  AbsoluteCenter,
  Alert,
  AlertDescription,
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@dotfile/frontend/shared/design-system';

import { useRouter } from '../../routes';
import {
  ContactAuthContext,
  FullContainer,
  useLatestClientPortalVersion,
} from '../../shared';
import RetryButton from './components/retry-button';

export const LoginPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const { data } = useLatestClientPortalVersion();

  const { requestMagicLink } = useAuth<ContactAuthContext>();
  const [isRequested, setIsRequested] = useState(false);

  const resolver = useMemo(() => {
    return yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email(
            t('login.email.validation.invalid', {
              ns: 'client-portal',
              defaultValue: 'Must be an email address',
            }),
          )
          .required(
            t('login.email.validation.required', {
              ns: 'client-portal',
              defaultValue: 'Email is required',
            }),
          ),
      }),
    );
  }, [t]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
    setError,
    getValues,
  } = useForm<{ email: string }>({
    mode: 'all',
    criteriaMode: 'all',
    resolver,
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<{ email: string }> = async (formData) => {
    const { email } = formData;
    try {
      await requestMagicLink(email);
      setIsRequested(true);
    } catch (error) {
      setError('email', {
        message: t('login.errors.on_submit', {
          ns: 'client-portal',
          defaultValue: 'An error occurred, please try again',
        }),
      });
    }
  };

  const isCaseCreationAllowed =
    data?.latestClientPortalVersion.setting.isCaseCreationAllowed;

  return (
    <FullContainer>
      <Container height="full" maxWidth="lg">
        <VStack
          align="stretch"
          justifyContent="center"
          height="full"
          spacing="4"
        >
          {isCaseCreationAllowed && (
            <>
              <Heading as="h2" size="md">
                {t('login.heading_start_new', {
                  ns: 'client-portal',
                  defaultValue: 'Begin your onboarding',
                })}
              </Heading>
              <Button
                isDisabled={isSubmitting}
                size="md"
                as={Link}
                to={router.Forms()}
              >
                {t('login.start_new', {
                  ns: 'client-portal',
                  defaultValue: 'Start new onboarding',
                })}
              </Button>
              <Box position="relative" py="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  <Text>
                    {t('login.or', {
                      ns: 'client-portal',
                      defaultValue: 'Or',
                    })}
                  </Text>
                </AbsoluteCenter>
              </Box>
            </>
          )}
          <Heading as="h2" size="md">
            {t('login.heading_resume', {
              ns: 'client-portal',
              defaultValue: 'Resume your onboarding',
            })}
          </Heading>
          {!isRequested ? (
            <VStack
              align="stretch"
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <GroupController
                name="email"
                label={t('login.email.label', {
                  ns: 'client-portal',
                  defaultValue: 'Your email',
                })}
                helper={t('login.email.helper', {
                  ns: 'client-portal',
                  defaultValue: 'Send a resume link to your email address',
                })}
                isRequired
                control={control}
                render={(field) => (
                  <InputGroup>
                    <Input
                      type="email"
                      placeholder={t('login.email.placeholder', {
                        ns: 'client-portal',
                        defaultValue: 'name@email.com',
                      })}
                      {...field}
                    />
                    <InputRightElement>
                      <IconButton
                        isLoading={isSubmitting}
                        isDisabled={!isValid}
                        type="submit"
                        aria-label={t('login.request_link', {
                          ns: 'client-portal',
                          defaultValue: 'Request link',
                        })}
                        title={t('login.request_link', {
                          ns: 'client-portal',
                          defaultValue: 'Request link',
                        })}
                        icon={<Icon as={SendHorizontal} />}
                      />
                    </InputRightElement>
                  </InputGroup>
                )}
              />
            </VStack>
          ) : (
            <Alert>
              <HStack>
                <AlertDescription>
                  {t('login.email_sent', {
                    ns: 'client-portal',
                    defaultValue:
                      'An email has been sent to {{ email }}, check your inbox.',
                    email: getValues('email'),
                  })}
                </AlertDescription>
                <RetryButton
                  variant="ghost"
                  onClick={() => setIsRequested(false)}
                />
              </HStack>
            </Alert>
          )}
        </VStack>
      </Container>
    </FullContainer>
  );
};
