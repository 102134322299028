import { Trans } from 'react-i18next';

import { Text } from '@dotfile/frontend/shared/design-system';

type ProvideValidIdentityDocumentForIndividualProps = {
  individualFullName: string;
  individualBirthDate: string | null;
};

/**
 * Similar to the component with the same name in id-verification/
 */
export const ProvideValidIdentityDocumentForIndividual = ({
  individualFullName,
  individualBirthDate,
}: ProvideValidIdentityDocumentForIndividualProps) => {
  return (
    <Text color="black">
      {individualBirthDate ? (
        <Trans
          i18nKey="checks.id_document.update_reference_data.provide_valid_identity_document_for_individual_birth_date"
          ns="client-portal"
          defaults="Provide a valid identity document for <b>{{individualFullName}}</b>, born <b>{{individualBirthDate}}</b><br> Name and date of birth date must match, document must not be in black and white"
          tOptions={{ individualFullName, individualBirthDate }}
        />
      ) : (
        <Trans
          i18nKey="checks.id_document.update_reference_data.provide_valid_identity_document_for_individual"
          ns="client-portal"
          defaults="Provide a valid identity document for <b>{{individualFullName}}</b><br> Name and date of birth date must match, document must not be in black and white"
          tOptions={{ individualFullName }}
        />
      )}
    </Text>
  );
};
