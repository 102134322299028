import { CountryCoverage } from '../country-coverage.interface';

// France + french oversea countries
export const INPI_COUNTRIES: CountryCoverage[] = [
  {
    // France
    code: 'FR',
  },
  {
    // Réunion
    code: 'RE',
  },
  {
    // Saint Barthélemy
    code: 'BL',
  },
  {
    // Saint Martin
    code: 'MF',
  },
  {
    // Saint Pierre and Miquelon
    code: 'PM',
  },
  {
    // Mayotte
    code: 'YT',
  },
  {
    // Guadeloupe
    code: 'GP',
  },
  {
    // Martinique
    code: 'MQ',
  },
  {
    // Guyane
    code: 'GY',
  },
  {
    // Wallis and Futuna
    code: 'WF',
  },
  {
    // French Polynesia
    code: 'PF',
  },
  {
    // New Caledonia
    code: 'NC',
  },
  {
    // French Southern Territories
    code: 'TF',
  },
];
