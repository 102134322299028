import { ApolloError } from '@apollo/client';

export const isGraphQLErrorWithCode = (
  error: unknown,
  code: string,
): boolean => {
  return (
    !!error &&
    error instanceof ApolloError &&
    !!error.cause?.extensions &&
    'code' in error.cause.extensions &&
    // The backed GraphQL error formatter put the `code` in the `extensions`
    // @see libs/backend/shared/graphql/src/lib/module/graphql.options.ts#32
    error.cause.extensions['code'] === code
  );
};
