
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseBeneficialOwnerInterface": [
      "CompanyBeneficialOwner",
      "IndividualBeneficialOwner"
    ],
    "BaseRepresentativeInterface": [
      "CompanyBeneficialOwner",
      "CompanyRepresentative",
      "CompanyShareholder",
      "IndividualBeneficialOwner",
      "IndividualRepresentative",
      "IndividualShareholder"
    ],
    "BaseShareholderInterface": [
      "CompanyShareholder",
      "IndividualShareholder"
    ],
    "BeneficialOwnerUnion": [
      "CompanyBeneficialOwner",
      "IndividualBeneficialOwner"
    ],
    "CheckInterface": [
      "DocumentCheck",
      "IdDocumentCheck",
      "IdVerificationCheck"
    ],
    "ClientPortalBlockInterface": [
      "ClientPortalBlockField",
      "ClientPortalBlockLayout"
    ],
    "CompanyRepresentativeInterface": [
      "CompanyBeneficialOwner",
      "CompanyRepresentative",
      "CompanyShareholder"
    ],
    "IndividualRepresentativeInterface": [
      "IndividualBeneficialOwner",
      "IndividualRepresentative",
      "IndividualShareholder"
    ],
    "RepresentativeUnion": [
      "CompanyRepresentative",
      "IndividualRepresentative"
    ],
    "ShareholderUnion": [
      "CompanyShareholder",
      "IndividualShareholder"
    ]
  }
};
      export default result;
    