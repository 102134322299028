import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  ClientPortalChecks_IdVerificationCheck,
  ClientPortalChecks_Individual,
} from '@dotfile/shared/data-access-client-portal';
import { getMinBirthDate, yup } from '@dotfile/shared/domain';

import { useUpdateReferenceData } from './use-update-reference-data';

export type UpdateReferenceDataFormValues = {
  firstName: string;
  lastName: string;
  birthDate: string | null;
};

/**
 * Similar to the hook with the same name in id-document/
 */
export const useUpdateReferenceDataForm = (
  check: ClientPortalChecks_IdVerificationCheck,
  individual: ClientPortalChecks_Individual,
) => {
  const { t } = useTranslation();

  const isBirthDateRequired = !!individual.birthDate;
  const resolver = useMemo(() => {
    const minBirthDate = getMinBirthDate();
    let birthDate = yup
      .string()
      .nullable()
      .birthdate({
        formatErrorMessage: t(`forms.field.date.invalid`, {
          ns: 'client-portal',
          defaultValue: 'Must be a date in the format dd/mm/yyyy',
        }),
        min: {
          value: minBirthDate,
          errorMessage: t(`forms.field.date.invalid`, {
            ns: 'client-portal',
            minAbsoluteDate: minBirthDate,
            defaultValue: 'Must be strictly after {{minAbsoluteDate}}',
          }),
        },
        max: {
          value: new Date(),
          errorMessage: t(`forms.field.date.past`, {
            ns: 'client-portal',
            defaultValue: 'Must be in the past',
          }),
        },
      });

    if (isBirthDateRequired) {
      // Make birth date required when already set
      birthDate = birthDate.required(({ label }) =>
        t(`forms.validation.required`, {
          ns: 'client-portal',
          defaultValue: 'This field is required',
        }),
      );
    }

    return yupResolver(
      yup.object().shape({
        firstName: yup.string().required(
          t(`forms.validation.required`, {
            ns: 'client-portal',
            defaultValue: 'This field is required',
          }),
        ),
        lastName: yup.string().required(
          t(`forms.validation.required`, {
            ns: 'client-portal',
            defaultValue: 'This field is required',
          }),
        ),
        birthDate,
      }),
    );
  }, [t, isBirthDateRequired]);

  const defaultValues: UpdateReferenceDataFormValues = {
    firstName: individual.firstName,
    lastName: individual.lastName,
    birthDate: individual.birthDate ?? null,
  };

  const methods = useForm<UpdateReferenceDataFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues,
    resolver,
  });

  const resetForm = () => {
    methods.reset(defaultValues);
  };

  const [isRightDocumentForIndividual, setIsRightDocumentForIndividual] =
    useState<'yes' | 'no' | undefined>(undefined);
  useEffect(() => {
    // Reset when check data changes like after completing a new flow
    setIsRightDocumentForIndividual(undefined);
  }, [check.data.id]);

  const [updateReferenceData] = useUpdateReferenceData();
  const onSubmit: SubmitHandler<UpdateReferenceDataFormValues> = async (
    formData,
  ) => {
    const res = await updateReferenceData({ id: check.id, ...formData });

    if (res?.individual) {
      methods.reset({
        firstName: res.individual.firstName,
        lastName: res.individual.lastName,
        birthDate: res.individual.birthDate ?? null,
      });

      setIsRightDocumentForIndividual(undefined);
    }
  };

  const {
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  return {
    methods: methods,
    submitIsDisabled:
      isRightDocumentForIndividual === 'yes' && (!isValid || !isDirty),
    isSubmitting,
    reset: resetForm,
    handleSubmit: methods.handleSubmit(onSubmit),

    isBirthDateRequired,

    isRightDocumentForIndividual,
    setIsRightDocumentForIndividual,
  };
};
