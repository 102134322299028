import { DeepReadonly } from 'ts-essentials';
import { SchemaOf } from 'yup';

import {
  BaseModel,
  CheckModel,
  CheckTypeEnum,
  EntityTypeEnum,
  ExtractCheckDataSettings,
  PermissionResourceEnum,
  VendorEnum,
} from '../../../shared/models';
import { FindOptions } from './types';

export type BaseCheckSettingsDefinition<TData> = {
  default: Partial<ExtractCheckDataSettings<TData>>;
};

export type BaseCheckInput<TData, TInput = unknown> = Partial<CheckModel> & {
  settings?: Partial<ExtractCheckDataSettings<TData>>;
  data?: TInput;
};

export abstract class BaseCheckDefinition<
  TData extends BaseModel,
  TDataInput extends BaseCheckInput<TData>,
> {
  // General
  // -------

  /**
   * Unique check identifier
   */
  abstract get type(): CheckTypeEnum;

  /**
   * Component name in UpperCamelCase of icon, eg `'FolderClosed'` or `'Building2'`.
   * @see https://lucide.dev/icons/
   */
  abstract get icon(): string;

  /**
   * The relation key in the Check model to get check Data
   */
  abstract get checkRelationKey(): keyof CheckModel;

  /**
   * Find options for check data
   * Common use case are for `relations` (eg AML check hits) and order (column and direction)
   * This is assignable to TypeORM FindOneOptions
   * @see https://github.com/typeorm/typeorm/blob/master/src/find-options/FindOneOptions.ts
   */
  public abstract findOptions(): FindOptions<TData>;

  /**
   * available target to add this Check type on Individual|Company.
   */
  abstract get availableTarget(): EntityTypeEnum[];

  /**
   * Resolve the subtype of a check given its settings.
   * `null` when there is no subtype for this check (default).
   * @param settings
   */
  public resolveSubtype(
    // IdVerificationVendorEnum is clashing with IdDocumentVendorEnum
    // Omit the vendor and add it back as VendorEnum
    settings: Omit<TDataInput['settings'], 'vendor'> & {
      vendor?: VendorEnum;
    },
  ): string | null {
    void settings;
    return null;
  }

  /**
   * Permission resource associated to this check
   */
  abstract get permissionResource(): PermissionResourceEnum;

  /**
   * Will define isInternal
   */
  get isInternal(): boolean {
    return false;
  }

  // Integrations
  // ------------

  /**
   * List of available vendors for this CheckType.
   */
  abstract get availableVendors(): VendorEnum[];

  // Settings
  // --------

  abstract get settings(): DeepReadonly<BaseCheckSettingsDefinition<TData>>;

  // Display
  // -------

  /**
   * Default label (in english) for this Check type
   */
  abstract get label(): string;

  // @TODO - E-1984 - Shared i18n lib for Console and Client-portal
  /**
   * Full translation key with this Check type
   */
  get labelKey(): string {
    return `check.type.${this.type}`;
  }

  // Validation
  // ------

  get validationSchemas(): {
    settings?: SchemaOf<TDataInput['settings']>;
    data?: SchemaOf<TDataInput['data']>;
  } {
    return {};
  }
}
