import { createContext, useContext } from 'react';

import { DotfileStageEnum } from '@dotfile/shared/domain';

// @TODO - E-2494 - App-specific environment file for frontend
export type Environment = {
  production: boolean;
  baseApp: string;
  baseAPI: string;
  baseMainStorage?: string | null;
  stage: DotfileStageEnum;
  ssoGoogleClientId?: string | null;
  analyticsWriteKey?: string | null;
  analyticsDataPlane?: string | null;
  notificationApiKey?: string | null;
  notificationChannelId?: string | null;
  integrationGoogleSheetsClientId?: string | null;
  datadogRUM?: {
    applicationId: string;
    clientToken: string;
  } | null;
};

const EnvironmentContext = createContext<Environment | null>(null);

/**
 * Return the environment from the context
 * @returns Environment
 */
export const useEnvironmentContext = (): Environment => {
  const environment = useContext(EnvironmentContext);

  if (!environment) {
    const error = new Error(
      'useEnvironmentContext: `environment` is falsy. Seems you forgot to wrap component within the EnvironmentContextProvider or initialize the context value',
    );
    error.name = 'EnvironmentContextError';
    Error.captureStackTrace?.(error, useContext);
    throw error;
  }

  return environment;
};

export const EnvironmentContextProvider = EnvironmentContext.Provider;
