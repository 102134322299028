import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(['wrapper', 'option']);

export const RadioButtonTheme = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle((props) => {
    const { colorScheme: c } = props;

    return {
      wrapper: {},

      option: {
        backgroundColor: 'white',
        _readOnly: {
          cursor: 'not-allowed',
        },
        _hover: {
          borderColor: 'gray.300',
        },
        _checked: {
          bg: `${c}.500`,
          borderColor: `${c}.500`,
          color: 'white',
        },
        _focusVisible: { borderColor: `${c}.500` },
      },
    };
  }),

  defaultProps: {
    colorScheme: 'blue',
  },
});
