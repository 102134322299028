import { LucideIcon } from 'lucide-react';
import { ReactNode } from 'react';

import { ChainedCommands, Editor } from '@tiptap/core';
import { useCurrentEditor } from '@tiptap/react';

import { Icon } from '../../../media-icon/icon/icon';
import { forwardRef } from '../../../utils/component-factory';
import { IconButton, IconButtonProps } from '../../icon-button/icon-button';

type RichTextMenuButtonProps = {
  icon: LucideIcon;
  name?: string;
} & Omit<IconButtonProps, 'icon' | 'onClick' | 'isDisabled' | 'isActive'> &
  (
    | {
        command: (chain: ChainedCommands, editor: Editor) => ChainedCommands;
        onClick?: never;
      }
    | { command?: never; onClick?: () => void }
  );

export const RichTextMenuButton = forwardRef(
  (
    { icon, command, onClick, name, ...iconProps }: RichTextMenuButtonProps,
    ref,
  ): ReactNode => {
    const { editor } = useCurrentEditor();
    if (!editor) return null;

    return (
      <IconButton
        onClick={() =>
          command ? command(editor.chain().focus(), editor).run() : onClick?.()
        }
        isDisabled={
          command && !command(editor.can().chain().focus(), editor).run()
        }
        isActive={!!name && editor.isActive(name)}
        variant="ghost"
        colorScheme="gray"
        size="xs"
        icon={<Icon as={icon} />}
        {...iconProps}
        ref={ref}
      />
    );
  },
);
