import { nameFormat } from '@dotfile/shared/common';
import {
  DocumentAnalysisIBANDetailedResultsModel,
  DocumentAnalysisIBANInformationModel,
  DocumentAnalysisModelEnum,
  getDocumentAnalysisDefinition,
} from '@dotfile/shared/domain';

import { DataComparisonLabel } from './data-comparison-label';
import { DataValidationLabel } from './data-validation-label';
import { AnalysisDetailedResult } from './types';

const definition = getDocumentAnalysisDefinition(
  DocumentAnalysisModelEnum.iban,
);

export const analysisIBANDetailedResults: AnalysisDetailedResult<
  DocumentAnalysisIBANDetailedResultsModel,
  DocumentAnalysisIBANInformationModel
> = {
  validIBAN: ({ detailedResult }) => (
    <DataValidationLabel
      informationDefinition={definition.information.iban}
      detailedResult={detailedResult}
    />
  ),
  validBIC: ({ detailedResult }) => (
    <DataValidationLabel
      informationDefinition={definition.information.bic}
      detailedResult={detailedResult}
    />
  ),
  ibanMatching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.iban}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.firstName, entity.individual?.lastName)
      }
    />
  ),
  bicMatching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.bic}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.firstName, entity.individual?.lastName)
      }
    />
  ),
  nameMatching: ({ detailedResult, entity }) => (
    <DataComparisonLabel
      informationDefinition={definition.information.holderName}
      detailedResult={detailedResult}
      entityName={
        entity.company?.name ??
        nameFormat(entity.individual?.firstName, entity.individual?.lastName)
      }
    />
  ),
};
