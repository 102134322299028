import { mergeWith, omit, pick } from 'lodash';
import { useCallback, useMemo } from 'react';

import { ApolloError } from '@apollo/client';

import { removeTypenameFromData } from '@dotfile/frontend/shared/common';
import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  ClientPortalStepTypeEnum,
  CompanyData_CompanyFetchFragment,
  CompanyDataInput,
  CompanySearch_CompanySearchFragment,
  CompanyTypeEnum,
  IndividualDataInput,
  useCompanyFetchQueryLazyQuery,
} from '@dotfile/shared/data-access-client-portal';
import {
  CompanyComparator,
  IndividualComparator,
} from '@dotfile/shared/domain';

import {
  affiliatedCompaniesSelector,
  useFormDatastore,
  useLatestClientPortalVersionForms,
} from '../../shared';

const useOnCompanyFetchSuccess = () => {
  const latestClientPortalVersionFormsQuery =
    useLatestClientPortalVersionForms();
  const hasAffiliatedCompaniesStep =
    !!latestClientPortalVersionFormsQuery.data?.latestClientPortalVersion.steps.find(
      (step) =>
        step.type === ClientPortalStepTypeEnum.affiliated_companies_edit,
    );
  const {
    resetCompaniesAndIndividualsData,
    patchMainCompanyData,
    patchAffiliatedCompanyData,
    patchIndividualData,
  } = useFormDatastore();

  const caseData = useFormDatastore((state) => state.data.case);
  const isResumeFormMode = !!caseData?.id;
  const existingIndividuals = useFormDatastore(
    (state) => state.data.individuals,
  );
  const existingAffiliatedCompanies = useFormDatastore(
    affiliatedCompaniesSelector,
  );

  return useCallback(
    (_companyFetch: CompanyData_CompanyFetchFragment) => {
      // Don't reset if we are in resume form (existing caseId)
      if (!isResumeFormMode) {
        resetCompaniesAndIndividualsData();
      }

      const companyFetch = removeTypenameFromData(_companyFetch);

      patchMainCompanyData(
        omit({ ...companyFetch, type: CompanyTypeEnum.main }, [
          'mergedCompanies',
          'mergedIndividuals',
        ]),
      );

      if (hasAffiliatedCompaniesStep) {
        const affiliatedCompaniesFromCompanyFetch = (
          companyFetch?.mergedCompanies ?? []
        ).map((company) => ({
          ...company,
          type: CompanyTypeEnum.affiliated,
          address: {
            streetAddress: company.address.streetAddress ?? '',
            streetAddress2: company.address.streetAddress2 ?? '',
            city: company.address.city ?? '',
            postalCode: company.address.postalCode ?? '',
            state: company.address.state ?? '',
            country: company.address.country ?? null,
          },
          country: company.country ?? '',
          name: company.name ?? '',
          registrationNumber: company.registrationNumber ?? '',
        })) as Partial<CompanyDataInput>[];

        // Concat with existing and then merge with CompanyComparator
        // Only for ResumeForm
        // @TODO - E-4415 - Refactor merge-companies and merge-individuals helpers
        const mergedCompanies = isResumeFormMode
          ? [
              ...(existingAffiliatedCompanies ?? []),
              ...affiliatedCompaniesFromCompanyFetch,
            ].reduce<Partial<CompanyDataInput>[]>((acc, currentCompany) => {
              // @NOTE some logics that could be put in common with merge-companies.helper.ts
              if (!currentCompany.name) {
                // Ignore company without name
                return acc;
              }

              const comparator = new CompanyComparator(currentCompany);
              const matchingIndex = comparator.isComparable
                ? acc.findIndex((otherCompany) =>
                    comparator.isSame(otherCompany),
                  )
                : -1;

              if (matchingIndex === -1) {
                // current company is not comparable or did not match with any other
                return [...acc, currentCompany];
              }

              return acc.map((otherCompany, index) => {
                if (index === matchingIndex) {
                  const matchingCompany = otherCompany;

                  return mergeWith(
                    {},
                    currentCompany,
                    matchingCompany,
                    (objValue, srcValue) =>
                      // takes in priority info from the last object or one that it truthy
                      srcValue || objValue,
                  );
                }

                return otherCompany;
              });
            }, [])
          : affiliatedCompaniesFromCompanyFetch;

        mergedCompanies.forEach((company, index) => {
          patchAffiliatedCompanyData(company, index);
        });
      }

      const individualsFromCompanyFetch = (
        companyFetch?.mergedIndividuals ?? []
      ).map((individual) => ({
        ...individual,
        firstName: individual.firstName ?? '',
        lastName: individual.lastName ?? '',
      })) as Partial<IndividualDataInput>[];

      // @TODO - E-4415 - Refactor merge-companies and merge-individuals helpers
      const mergedIndividuals = isResumeFormMode
        ? [
            ...(existingIndividuals ?? []),
            ...individualsFromCompanyFetch,
          ].reduce<Partial<IndividualDataInput>[]>((acc, currentIndividual) => {
            const comparator = new IndividualComparator(currentIndividual);
            const matchingIndex = comparator.isComparable
              ? acc.findIndex((otherIndividual) =>
                  comparator.isSame(otherIndividual),
                )
              : -1;

            if (matchingIndex === -1) {
              // current individual is not comparable or did not match with any other
              return [...acc, currentIndividual];
            }

            // map over acc allow to update in place the matchingIndividual
            // with the information of the currentIndividual
            // currentIndividual is not appended to acc because it is merged
            // into matchingIndividual
            return acc.map((otherIndividual, index) => {
              if (index === matchingIndex) {
                const matchingIndividual = otherIndividual;

                // handle priority of information from role:
                // beneficial_owner > stakeholder > legal_representative
                const [moreInfo, lessInfo] = [
                  currentIndividual,
                  matchingIndividual,
                ];

                return mergeWith(
                  {},
                  currentIndividual,
                  matchingIndividual,
                  {
                    roles: [
                      ...new Set([
                        ...(lessInfo.roles ?? []),
                        ...(moreInfo.roles ?? []),
                      ]),
                    ],
                  },
                  {
                    // merge relation by concatenating roles
                    // @WARNING: This merge logic  won't work if there are not exactly one relation relation in each individuals
                    relations: [
                      {
                        ownershipPercentage:
                          moreInfo.relations?.[0]?.ownershipPercentage ??
                          lessInfo.relations?.[0]?.ownershipPercentage ??
                          null,
                        votingRightsPercentage:
                          moreInfo.relations?.[0]?.votingRightsPercentage ??
                          lessInfo.relations?.[0]?.votingRightsPercentage ??
                          null,
                        position:
                          moreInfo.relations?.[0]?.position ??
                          lessInfo.relations?.[0]?.position ??
                          null,
                        roles: [
                          ...new Set([
                            ...(moreInfo.relations?.[0]?.roles ?? []),
                            ...(lessInfo.relations?.[0]?.roles ?? []),
                          ]),
                        ],
                      },
                    ],
                  },
                  (objValue, srcValue) =>
                    // takes in priority info from the last object or one that it truthy
                    srcValue || objValue,
                );
              }

              return otherIndividual;
            });
          }, [])
        : individualsFromCompanyFetch;

      mergedIndividuals.forEach((individual, index) => {
        patchIndividualData(individual, index);
      });
    },
    [
      existingAffiliatedCompanies,
      existingIndividuals,
      hasAffiliatedCompaniesStep,
      isResumeFormMode,
      patchAffiliatedCompanyData,
      patchIndividualData,
      patchMainCompanyData,
      resetCompaniesAndIndividualsData,
    ],
  );
};

/**
 * On company fetch failed, set the main company data with Search values
 * @param companySearch
 */
const useOnCompanyFetchFailed = () => {
  const handleError = useHandleError();
  const { resetCompaniesAndIndividualsData, patchMainCompanyData } =
    useFormDatastore();

  return useCallback(
    (
      companySearch: CompanySearch_CompanySearchFragment,
      error?: ApolloError,
    ) => {
      let title = 'Error',
        isRetryable = false,
        skipRUM = false,
        skipToast = true;
      if (error) {
        if ((error as Error)?.message?.includes(`503`)) {
          title = 'Our data provider is temporally unavailable';
          skipRUM = true;
          isRetryable = true;
          skipToast = false;
        }
      } else {
        title = 'Unexpected error, company fetch seems to be empty';
      }

      handleError({
        title,
        error,
        isRetryable,
        skipRUM,
        skipToast,
      });

      resetCompaniesAndIndividualsData();

      patchMainCompanyData(
        omit(
          {
            ...pick(removeTypenameFromData(companySearch), [
              'country',
              'name',
              'registrationNumber',
              'address',
            ]),
            type: CompanyTypeEnum.main,
          },
          ['mergedCompanies', 'mergedIndividuals'],
        ),
      );
    },
    [patchMainCompanyData, resetCompaniesAndIndividualsData, handleError],
  );
};

export const useCompanyFetch = (
  timeout = 30_000, // 30 sec
) => {
  const onError = useOnCompanyFetchFailed();
  const onSuccess = useOnCompanyFetchSuccess();
  const [runQuery, result] = useCompanyFetchQueryLazyQuery();
  const controller = useMemo(() => new AbortController(), []);

  const fetchCompany = useCallback(
    async (
      companySearch: CompanySearch_CompanySearchFragment,
    ): Promise<CompanyData_CompanyFetchFragment | null> => {
      const timer = setTimeout(() => {
        controller.abort(new Error('Timeout'));
      }, timeout);

      const { data, error } = await runQuery({
        variables: {
          input: {
            searchRef: companySearch.searchRef,
          },
        },

        // used to timeout the call
        context: {
          fetchOptions: {
            signal: controller.signal,
          },
        },
      });

      clearTimeout(timer);

      const companyFetch = data?.companyFetch;
      if (!companyFetch || error) {
        onError(companySearch, error);
        return null;
      }

      onSuccess(companyFetch);
      return companyFetch;
    },
    [runQuery, controller, onSuccess, onError, timeout],
  );

  return [fetchCompany, result] as const;
};
