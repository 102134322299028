import { ReactNode, useEffect, useMemo } from 'react';
import ReactFlow, {
  Background,
  Controls,
  Edge as EdgeType,
  MiniMap,
  Node as NodeType,
  PanOnScrollMode,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { CustomEdge, defaultEdgeOptions } from './edges';
import { BaseData, componentInjector, getAutoLayoutElements } from './helpers';
import { CustomNode } from './nodes';

export type CompanyGraphProps<TNodeData, TEdgeData> = {
  nodes: NodeType[];
  edges: EdgeType[];
  onNodeClick?: (id: string, data: TNodeData) => void;
  onEdgeClick?: (id: string) => void;
  renderNodeContent?: (id: string, data: TNodeData) => ReactNode;
  renderEdgeContent?: (id: string, data: TEdgeData) => ReactNode;
  withMiniMap?: boolean;
  readOnly?: boolean;
  selectedEdge?: string | null;
  selectedNodes?: string[];
};
export const CompanyGraph = <TNodeData, TEdgeData>({
  nodes: initialNodes,
  edges: initialEdges,
  onNodeClick,
  onEdgeClick,
  renderNodeContent,
  renderEdgeContent,
  withMiniMap,
  readOnly = false,
  selectedEdge,
  selectedNodes,
}: CompanyGraphProps<TNodeData, TEdgeData>): JSX.Element => {
  // Register Node & Edge types
  const nodeTypes = useMemo(
    () => ({
      customNode: CustomNode,
    }),
    [],
  );
  const edgeTypes = useMemo(
    () => ({ customEdge: CustomEdge<BaseData<TEdgeData>> }),
    [],
  );

  // Use recommended controlled Graph
  // @see https://reactflow.dev/docs/getting-started/core-concepts/#controlled-or-uncontrolled
  const [nodes, setNodes, onNodesChange] = useNodesState<BaseData<TNodeData>>(
    [],
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState<BaseData<TEdgeData>>(
    [],
  );

  useEffect(() => {
    const withLayout = getAutoLayoutElements(initialNodes, initialEdges);
    const withComponents = componentInjector<TNodeData, TEdgeData>(
      withLayout,
      {
        node: renderNodeContent,
        edge: renderEdgeContent,
      },
      readOnly,
      selectedEdge,
      selectedNodes,
    );
    setNodes(withComponents.nodes);
    setEdges(withComponents.edges);
  }, [
    initialEdges,
    initialNodes,
    renderEdgeContent,
    renderNodeContent,
    setEdges,
    setNodes,
    readOnly,
    selectedEdge,
    selectedNodes,
  ]);

  // This reset view to fit when a node or edge is created or deleted (length changes)
  const reactFlow = useReactFlow();
  useEffect(() => {
    // Set fitView in a timeout to make sure Graph has already re-rendered with the new nodes
    const timeout = setTimeout(() => reactFlow.fitView(), 10);
    return () => {
      clearTimeout(timeout);
    };
  }, [reactFlow, nodes.length, edges.length]);

  return (
    <ReactFlow
      aria-readonly={readOnly}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      defaultEdgeOptions={defaultEdgeOptions}
      fitView
      snapToGrid
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodesConnectable={!readOnly}
      nodesDraggable={false}
      selectNodesOnDrag={!readOnly}
      panOnDrag
      panOnScroll={false}
      panOnScrollMode={PanOnScrollMode.Free}
      zoomOnScroll={false}
      zoomOnDoubleClick={false}
      zoomOnPinch={!readOnly}
      multiSelectionKeyCode="DISABLED" // @see https://github.com/wbkd/react-flow/issues/774
      onNodeClick={(_, node) => {
        if (onNodeClick) onNodeClick(node.id, node.data.props);
      }}
      onEdgeClick={(_, edge) => {
        if (readOnly && onEdgeClick) onEdgeClick(edge.id);
      }}
      proOptions={{
        hideAttribution: true, // @see https://reactflow.dev/learn/troubleshooting/remove-attribution
      }}
    >
      <Controls position="bottom-right" showInteractive={false} />
      {withMiniMap && <MiniMap position="bottom-left" />}
      <Background
        gap={8}
        color="var(--chakra-colors-blue-200)"
        style={{
          backgroundColor: 'var(--chakra-colors-gray-25)',
        }}
      />
    </ReactFlow>
  );
};

export const CompanyGraphWithProvider = <TNodeData, TEdgeData>(
  props: CompanyGraphProps<TNodeData, TEdgeData>,
): JSX.Element => {
  return (
    <ReactFlowProvider>
      <CompanyGraph {...props} />
    </ReactFlowProvider>
  );
};
