import { CircleCheck } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HStack, Icon, Text } from '@dotfile/frontend/shared/design-system';

import { useFormDatastore } from '../context';

export const AutoSaveConfirm = () => {
  const { t } = useTranslation();

  const [autoSavedIsVisible, setAutoSavedIsVisible] = useState(false);
  const lastAutoSavedAt = useFormDatastore((state) => state.lastAutoSavedAt);

  useEffect(() => {
    // Shows message only if save happened within last 300ms
    if (
      !lastAutoSavedAt ||
      // lastAutoSaveAt could be a string when restoring the store from persistence (LocalStorage)
      // Could be fixed at the datastore level see the TODO (but so far only this value is an ISO Date that needs rehydration)
      // @TODO - E-5284 - Client portal: handle date hydration in datastore properly
      (lastAutoSavedAt instanceof Date
        ? Math.abs(Date.now() - lastAutoSavedAt.getTime()) > 300
        : Math.abs(Date.now() - new Date(lastAutoSavedAt).getTime()) > 300)
    ) {
      return;
    }

    setAutoSavedIsVisible(true);
    const timer = setTimeout(() => {
      setAutoSavedIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [lastAutoSavedAt]);

  if (!autoSavedIsVisible) {
    return null;
  }

  return (
    <HStack
      as="p"
      spacing="1"
      color="gray.400"
      fontSize="sm"
      alignItems="center"
    >
      <Icon as={CircleCheck} color="gray.400" fontSize="sm" />
      <Text as="span" color="gray.400" fontSize="sm">
        {t('forms.auto_save_for_later.success', {
          ns: 'client-portal',
          defaultValue: 'Progress saved',
        })}
      </Text>
    </HStack>
  );
};
