import { AddressModel, PropertyMappingEntityEnum } from '../models';
import { DefaultPropertiesBuilder } from './default-properties-builder';
import { Property, PropertyTypeEnum } from './types';

export type AddressPropertyKey = keyof AddressModel;
export const getAddressDefaultPropertiesDefinition = (
  entity: PropertyMappingEntityEnum,
): Record<AddressPropertyKey, Property<PropertyTypeEnum>> =>
  new DefaultPropertiesBuilder<AddressModel>(entity)
    .addProperty('city', PropertyTypeEnum.text, {
      alwaysRequired: false,
    })
    .addProperty('country', PropertyTypeEnum.countries, {
      alwaysRequired: false,
      settings: { allowMultiple: false },
    })
    .addProperty('postalCode', PropertyTypeEnum.text, {
      alwaysRequired: false,
    })
    .addProperty('region', PropertyTypeEnum.text, {
      alwaysRequired: false,
    })
    .addProperty('state', PropertyTypeEnum.text, {
      alwaysRequired: false,
    })
    .addProperty('streetAddress', PropertyTypeEnum.text, {
      alwaysRequired: false,
    })
    .addProperty('streetAddress2', PropertyTypeEnum.text, {
      alwaysRequired: false,
    })
    .getDefinition();
