import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CompanyClassificationsField,
  GroupController,
} from '@dotfile/frontend/shared/components';
import {
  GroupControl,
  Input,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/data-access-client-portal';
import { clientPortalCompanyPropertiesDefinition } from '@dotfile/shared/domain';

import { mainCompanySelector, useFormDatastore } from '../../../context';
import { generateFieldFormName } from '../../../utils';
import { FieldProps } from './types';

const classificationsPrefix = 'forms.field.classifications';

export const FieldClassifications = ({
  field,
  property,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.classifications>) => {
  const { t } = useTranslation();
  const { watch, control } = useFormContext();

  // @NOTE: Not ideal but simplest way to get current form country
  // if not in form, we can assume fairly that it should concern the main company
  const {
    fieldState: { error },
  } = useController({ name: groupControlProps.name, control });

  const formCountryKey = generateFieldFormName(
    clientPortalCompanyPropertiesDefinition.country,
  );
  const mainCompany = useFormDatastore(mainCompanySelector);
  const country = watch(formCountryKey) ?? mainCompany?.country;
  const classifications = watch(groupControlProps.name);

  return (
    <VStack align="stretch" spacing="2" my="2" width="full" {...wrapperProps}>
      {/**
       * @NOTE: Separate GroupControl from GroupController.
       * - GroupControl will handle label, helper and error
       * - GroupController will be rendered only when no classifications in
       * order to focus (scroll to) the classifications field when required and
       * no other input can be focused.
       *   The GroupController should not be rendered with classifications or it
       *   will block the focus
       */}
      <GroupControl
        {...groupControlProps}
        labelProps={{
          fontWeight: 'bold',
          ...groupControlProps.labelProps,
        }}
        error={error?.message as string}
        helperPosition="top"
      />
      {!classifications?.length && groupControlProps.isRequired ? (
        // Allow scroll into view when field is required but have no subfield
        <GroupController
          {...groupControlProps}
          label={undefined}
          helper={null}
          error={null}
          render={(field) => (
            <Input
              {...field}
              isReadOnly
              position="absolute"
              border="none"
              width="0"
              height="0"
              value=""
            />
          )}
        />
      ) : null}

      <CompanyClassificationsField
        {...groupControlProps}
        country={country}
        labels={{
          type: t(`${classificationsPrefix}.type.label`, {
            defaultValue: 'Type',
            ns: 'client-portal',
          }),
          code: t(`${classificationsPrefix}.code.label`, {
            defaultValue: 'Code',
            ns: 'client-portal',
          }),
          description: t(`${classificationsPrefix}.description.label`, {
            defaultValue: 'Description',
            ns: 'client-portal',
          }),
          delete: t(`common.remove`, {
            defaultValue: 'Remove',
            ns: 'client-portal',
          }),
          notUnique: t(`${classificationsPrefix}.not_unique`, {
            defaultValue: 'Not unique',
            ns: 'client-portal',
          }),
        }}
      />
    </VStack>
  );
};
