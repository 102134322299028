import { VStack } from '@dotfile/frontend/shared/design-system';
import { CheckStatusEnum } from '@dotfile/shared/domain';

import { useSortedEntities } from '../hooks/use-sorted-entities';
import { ChecksEntityCard } from './checks-entity-card';

export type ChecksEntityListProps = {
  statuses: CheckStatusEnum[];
};

export const ChecksEntityList = ({ statuses }: ChecksEntityListProps) => {
  const sortedEntities = useSortedEntities(statuses);

  return (
    <VStack spacing="6">
      {sortedEntities.map((entity) => {
        return (
          <ChecksEntityCard
            key={entity.id}
            entity={entity}
            pendingActions={statuses.includes(CheckStatusEnum.rejected)}
          />
        );
      })}
    </VStack>
  );
};
