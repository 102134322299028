import { SchemaOf } from 'yup';

import {
  ClientPortalCompanySearchConfig,
  ClientPortalStepTypeEnum,
  ClientPortalTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { BaseClientPortalStepDefinition } from './base-client-portal-step-definition';

export class CompanySearchStepDefinition extends BaseClientPortalStepDefinition<ClientPortalCompanySearchConfig> {
  // General
  // -------
  get type(): ClientPortalStepTypeEnum {
    return ClientPortalStepTypeEnum.company_search;
  }
  get title(): string {
    return 'Help us identify your company';
  }
  get icon(): string {
    return 'folder-search-2';
  }
  get isUnique(): boolean {
    return true;
  }
  public canHaveLogics(): boolean {
    return false;
  }

  get position(): number {
    return 1;
  }
  get clientPortalType(): ClientPortalTypeEnum[] {
    return [ClientPortalTypeEnum.KYB];
  }

  // Actions
  // -------
  get canBeMoved(): boolean {
    return false;
  }
  get canBeRemoved(): boolean {
    return false;
  }

  // Visibility
  // -------
  get canBeHidden(): boolean {
    return false;
  }

  // Config
  // --------

  override get defaultConfig(): ClientPortalCompanySearchConfig {
    return { authorizedCountries: null };
  }

  public override configSchema(): SchemaOf<ClientPortalCompanySearchConfig> {
    const schema = yup.object().shape({
      authorizedCountries: yup
        .array(
          yup
            .string()
            // @NOTE Need to put ensure here to have the correct InferType and avoid string | undefined
            .ensure()
            .required()
            .countryCode2(),
        )
        .defined()
        .min(1)
        .nullable()
        .default(null),
    });

    return schema;
  }
}

export const companySearchStepDefinition = new CompanySearchStepDefinition();
