import { useIllustrationTokens } from './tokens';

export const EmptyStateIllustrationMissingData = (): JSX.Element => {
  const { stroke, skeleton } = useIllustrationTokens();

  return (
    <svg
      width="100%"
      viewBox="214 0 772 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g opacity="0.3">
          <path
            d="M697 1C697 41.8691 664.317 75 624 75L624 1L697 1Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M624 75C705.186 75 771 140.814 771 222L624 222L624 75Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M327 75C327 34.1309 359.683 1 400 1L400 75L327 75Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M550 75C550 156.186 482.843 222 400 222L400 75L550 75Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
          <path
            d="M624 75C624 34.1309 590.869 0.999999 550 0.999997L550 75L624 75Z"
            stroke={stroke}
            strokeWidth="1.10769"
            strokeDasharray="11.08 11.08"
          />
        </g>
        <g filter="url(#filter0_ddd_2199_1938)">
          <rect x="530" y="38" width="96" height="96" rx="4" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M564 70.0001C562.895 70.0001 562 70.8955 562 72.0001V100C562 101.105 562.895 102 564 102H592C593.105 102 594 101.105 594 100V72.0001C594 70.8955 593.105 70.0001 592 70.0001H564ZM558 72.0001C558 68.6864 560.686 66.0001 564 66.0001H592C595.314 66.0001 598 68.6864 598 72.0001V100C598 103.314 595.314 106 592 106H564C560.686 106 558 103.314 558 100V72.0001Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M571 78.0001C570.448 78.0001 570 78.4478 570 79.0001C570 79.5523 570.448 80.0001 571 80.0001C571.552 80.0001 572 79.5523 572 79.0001C572 78.4478 571.552 78.0001 571 78.0001ZM566 79.0001C566 76.2386 568.238 74.0001 571 74.0001C573.761 74.0001 576 76.2386 576 79.0001C576 81.7615 573.761 84.0001 571 84.0001C568.238 84.0001 566 81.7615 566 79.0001Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.586 80.5858C585.367 79.8047 586.633 79.8047 587.414 80.5858L597.414 90.5858C598.195 91.3668 598.195 92.6332 597.414 93.4142C596.633 94.1953 595.367 94.1953 594.586 93.4142L586 84.8284L565.414 105.414C564.633 106.195 563.367 106.195 562.586 105.414C561.805 104.633 561.805 103.367 562.586 102.586L584.586 80.5858Z"
            fill={skeleton}
          />
        </g>
        <g filter="url(#filter1_ddd_2199_1938)">
          <rect x="587" y="93" width="96" height="96" rx="4" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M624.586 120.586C625.466 119.705 626.749 119 628.2 119H642C642.53 119 643.039 119.211 643.414 119.586L652.414 128.586C652.789 128.961 653 129.47 653 130V149.8C653 151.25 652.295 152.534 651.414 153.414C650.533 154.295 649.25 155 647.8 155H628.2C626.749 155 625.466 154.295 624.586 153.414C623.705 152.534 623 151.25 623 149.8V124.2C623 122.75 623.705 121.466 624.586 120.586ZM628.2 123C628.05 123 627.733 123.095 627.414 123.414C627.095 123.734 627 124.05 627 124.2V149.8C627 149.95 627.095 150.266 627.414 150.586C627.733 150.905 628.05 151 628.2 151H647.8C647.949 151 648.266 150.905 648.586 150.586C648.905 150.266 649 149.95 649 149.8V130.828L641.171 123H628.2Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M617 130.2C618.105 130.2 619 131.095 619 132.2V157.8C619 157.95 619.095 158.266 619.414 158.586C619.734 158.905 620.05 159 620.2 159H639.8C640.905 159 641.8 159.895 641.8 161C641.8 162.105 640.905 163 639.8 163H620.2C618.75 163 617.466 162.295 616.586 161.414C615.705 160.534 615 159.25 615 157.8V132.2C615 131.095 615.895 130.2 617 130.2Z"
            fill={skeleton}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M641 119C642.104 119 643 119.895 643 121V129H651C652.104 129 653 129.895 653 131C653 132.105 652.104 133 651 133H641C639.895 133 639 132.105 639 131V121C639 119.895 639.895 119 641 119Z"
            fill={skeleton}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddd_2199_1938"
          x="342.4"
          y="-103.4"
          width="471.2"
          height="471.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2199_1938"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2199_1938"
            result="effect2_dropShadow_2199_1938"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_2199_1938"
            result="effect3_dropShadow_2199_1938"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_2199_1938"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_2199_1938"
          x="399.4"
          y="-48.4"
          width="471.2"
          height="471.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.07235" />
          <feGaussianBlur stdDeviation="6.2378" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0201946 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2199_1938"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10.3194" />
          <feGaussianBlur stdDeviation="20.9515" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.0298054 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2199_1938"
            result="effect2_dropShadow_2199_1938"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="46.2" />
          <feGaussianBlur stdDeviation="93.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.0823529 0 0 0 0 0.101961 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_2199_1938"
            result="effect3_dropShadow_2199_1938"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_2199_1938"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
