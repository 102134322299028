import { QueryFunction, useQuery } from '@tanstack/react-query';

import {
  convertBlobToBase64,
  useHttpClient,
} from '@dotfile/frontend/shared/common';
import { FileModel, isFilePubliclyAccessible } from '@dotfile/shared/domain';

const getFile: QueryFunction<string, [string, string]> = async ({
  queryKey,
  signal,
  meta,
}) => {
  const [baseUrl, fileId] = queryKey;

  const response = await (
    meta?.['httpClient'] as ReturnType<typeof useHttpClient>
  ).get(`${baseUrl}/${fileId}`, {
    signal,
    responseType: 'blob',
  });
  const base64Data = await convertBlobToBase64(response.data);

  return base64Data;
};

export const useFile = (
  file?: Pick<FileModel, 'id' | 'target'> | null,
): {
  data: string | undefined;
  isLoading: boolean;
} => {
  const baseUrl =
    file && isFilePubliclyAccessible(file) ? '/files/public' : '/files/preview';

  const query = useQuery({
    queryKey: [baseUrl, file?.id as string],
    queryFn: getFile,
    enabled: !!file,

    // 30min before image gets stale and evicted from cache
    staleTime: 30 * 60 * 1000,

    meta: {
      httpClient: useHttpClient(),
    },

    // by default, react-query retry 3 times
    retry: 1,
  });

  return {
    data: query.data,
    // If there aren't file, isLoading remains at true
    isLoading: !file ? false : query.isLoading,
  };
};
