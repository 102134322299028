import { ExternalLink as ExternalLinkIcon } from 'lucide-react';

import { Icon } from '../../media-icon/icon/icon';
import { Text, TextProps } from '../../typography/text';
import { Link, LinkProps } from './link';

export type ExternalLinkProps = LinkProps & TextProps;

export const ExternalLink = ({
  children,
  href,
  noOfLines,
  ...rest
}: ExternalLinkProps): JSX.Element => {
  return (
    <Text
      as={Link}
      color="inherit"
      target="_blank"
      href={href}
      {...rest}
      display="inline-flex"
      alignItems="center"
    >
      {/* Avoid hiding the icon when there is too much lines */}
      <Text
        as="span"
        color="inherit"
        noOfLines={noOfLines}
        display="inline"
        whiteSpace="inherit"
      >
        {children ?? href}
      </Text>
      <Icon as={ExternalLinkIcon} ml="1" />
    </Text>
  );
};
