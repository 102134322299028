import { ChevronDown } from 'lucide-react';
import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Icon } from '../../media-icon/icon/icon';
import { useDisclosure, usePrevious } from '../../utils/hooks/chakra-hooks';
import { Collapse } from '../../utils/transitions';
import { Button } from '../button/button';
import { RICH_TEXT_LINE_HEIGHT, RICH_TEXT_PADDING } from './constants';
import { RichTextModeEnum } from './types';

const SEE_MORE_LIMIT =
  RICH_TEXT_LINE_HEIGHT * 3 + // To display 3 lines
  RICH_TEXT_PADDING * 2; // top + bottom padding

type RichTextSeeMoreProps = PropsWithChildren & {
  mode: RichTextModeEnum;
  alwaysIn?: boolean;
  onToggle?: (isOpen: boolean) => void;
};

export const RichTextSeeMore = ({
  alwaysIn,
  mode,
  onToggle,
  children,
}: RichTextSeeMoreProps): ReactNode => {
  const [hasSeeMore, setHasSeeMore] = useState(false);
  const innerDivRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver;

    const trigger = () => {
      const contentHeight = innerDivRef.current?.clientHeight;
      if (contentHeight) {
        if (contentHeight <= SEE_MORE_LIMIT) {
          setHasSeeMore(false);
        } else {
          setHasSeeMore(true);
        }
      }
    };

    if (innerDivRef.current && !alwaysIn) {
      if ('ResizeObserver' in window) {
        resizeObserver = new ResizeObserver(trigger);
        resizeObserver.observe(innerDivRef.current);
      }

      trigger();
    }

    return () => {
      resizeObserver?.disconnect();
    };
  }, [setHasSeeMore, alwaysIn]);

  const seeMore = useDisclosure({
    defaultIsOpen: false,
  });

  // Auto close the collapse when going back to display mode
  const previousMode = usePrevious(mode);
  const onClose = seeMore.onClose;
  useEffect(() => {
    if (
      previousMode === RichTextModeEnum.edit &&
      mode === RichTextModeEnum.display
    ) {
      onClose();
    }
  }, [previousMode, mode, onClose]);

  return (
    <>
      <Collapse
        in={
          alwaysIn ||
          mode === RichTextModeEnum.edit ||
          seeMore.isOpen ||
          !hasSeeMore
        }
        startingHeight={
          hasSeeMore
            ? `${
                SEE_MORE_LIMIT - RICH_TEXT_PADDING // subtract the bottom padding
              }px`
            : undefined
        }
      >
        <div ref={innerDivRef}>{children}</div>
      </Collapse>

      {mode === RichTextModeEnum.display && hasSeeMore && !alwaysIn && (
        <Button
          mt={seeMore.isOpen ? 0 : '1.5'}
          mb="3"
          ml="2"
          pl="1"
          pr="2"
          py="0"
          variant="ghost"
          onClick={() => {
            onToggle?.(!seeMore.isOpen);
            seeMore.onToggle();
          }}
          flexShrink="0"
          leftIcon={
            <Icon
              __css={{
                // Same as accordion icon
                // @TODO - E-3452 - Design: SeeMore component
                transform: seeMore.isOpen ? 'rotate(-180deg)' : undefined,
                transition: 'transform 0.2s',
                transformOrigin: 'center',
              }}
              as={ChevronDown}
            />
          }
        >
          See {seeMore.isOpen ? 'less' : 'more'}
        </Button>
      )}
    </>
  );
};
