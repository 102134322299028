import { useTranslation } from 'react-i18next';

import { DrawerProps } from '@dotfile/frontend/shared/design-system';
import {
  CaseRelationRoleEnum,
  ClientPortalForms_Step,
  ClientPortalStepTypeEnum,
} from '@dotfile/shared/data-access-client-portal';

import {
  Blocks,
  DrawerFieldsForm,
  DrawerFieldsFormSubmit,
  useAutoSaveForLater,
  useFieldsForm,
  useFormDatastore,
  useLatestClientPortalVersionForms,
} from '../shared';

export type IndividualFormDrawerProps = Pick<
  DrawerProps,
  'isOpen' | 'onClose'
> & {
  onClose: () => void;

  step: ClientPortalForms_Step;
  selectedIndividualIndex: number | null;
};

export const IndividualFormDrawer = ({
  isOpen,
  onClose,
  selectedIndividualIndex,
  step,
}: IndividualFormDrawerProps) => {
  const individual = useFormDatastore((state) =>
    selectedIndividualIndex === null
      ? {}
      : state.data.individuals?.[selectedIndividualIndex],
  );
  const { patchIndividualData, addIndividualData } = useFormDatastore();

  const { methods, displayedBlocks } = useFieldsForm({
    step,
    data: { case: undefined, individual },
    isHidden: !isOpen,
  });
  const { t } = useTranslation();

  const { data: clientPortal } = useLatestClientPortalVersionForms();
  const autoSaveForLater = useAutoSaveForLater();
  const onSubmit: DrawerFieldsFormSubmit = async ({ data }) => {
    if (data.individual) {
      if (selectedIndividualIndex === null) {
        addIndividualData(data.individual);
      } else {
        if (
          individual?.isDelegator &&
          data.individual?.roles &&
          !data.individual.roles.includes(
            CaseRelationRoleEnum.legal_representative,
          ) &&
          clientPortal?.latestClientPortalVersion.steps.find(
            (s) => s.type === ClientPortalStepTypeEnum.business_contact,
          )?.config?.requestDelegator
        ) {
          // When the delegator is requested in business_contact step, remove isDelegator from individual that were
          // delegator but had their legal_representative role removed in the form to force the contact to select
          // a new delegator on the business_contact step that is always after this individual_edits step
          data.individual.isDelegator = false;
        }
        patchIndividualData(data.individual, selectedIndividualIndex);
      }
    }

    await autoSaveForLater.run();

    onClose();
  };

  const header = t('forms.individuals_edit.form_drawer.header', {
    ns: 'client-portal',
    defaultValue: 'Individual information',
  });

  const submitLabel =
    selectedIndividualIndex === null
      ? t('forms.add_individual', {
          ns: 'client-portal',
          defaultValue: 'Add individual',
        })
      : t('common.update', { ns: 'client-portal', defaultValue: 'Update' });

  return (
    <DrawerFieldsForm
      isOpen={isOpen}
      onClose={onClose}
      methods={methods}
      onSubmit={onSubmit}
      header={header}
      submitLabel={submitLabel}
    >
      <Blocks blocks={displayedBlocks} />

      {/* @TODO - E-4123 - Client portal App: Interest in main company section */}
    </DrawerFieldsForm>
  );
};
