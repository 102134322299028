import {
  Table as CharkaTable,
  TableBodyProps as CharkaTableBodyProps,
  TableCaption as CharkaTableCaption,
  TableCaptionProps as CharkaTableCaptionProps,
  TableColumnHeaderProps as CharkaTableColumnHeaderProps,
  TableContainer as CharkaTableContainer,
  TableContainerProps as CharkaTableContainerProps,
  TableFooterProps as CharkaTableFooterProps,
  TableHeadProps as CharkaTableHeadProps,
  TableProps as CharkaTableProps,
  TableRowProps as CharkaTableRowProps,
  Tbody as CharkaTbody,
  Td as CharkaTd,
  TableCellProps as CharkaTdProps,
  Tfoot as CharkaTfoot,
  Th as CharkaTh,
  Thead as CharkaThead,
  Tr as CharkaTr,
} from '@chakra-ui/react';

export const Table = CharkaTable;
export type TableProps = CharkaTableProps;
export const TableHead = CharkaThead;
export type TableHeadProps = CharkaTableHeadProps;
export const TableBody = CharkaTbody;
export type TableBodyProps = CharkaTableBodyProps;
export const TableFooter = CharkaTfoot;
export type TableFooterProps = CharkaTableFooterProps;
export const TableRow = CharkaTr;
export type TableRowProps = CharkaTableRowProps;
export const TableColumnHeader = CharkaTh;
export type TableColumnHeaderProps = CharkaTableColumnHeaderProps;
export const TableCell = CharkaTd;
export type TableCellProps = CharkaTdProps;
export const TableCaption = CharkaTableCaption;
export type TableCaptionProps = CharkaTableCaptionProps;
export const TableContainer = CharkaTableContainer;
export type TableContainerProps = CharkaTableContainerProps;

export { TableTheme } from './table.theme';
export * from './filter-widget';
