import { MarkRequired } from 'ts-essentials';

import {
  CHECK_ANALYSIS_RESULT_DATA_COMPARISON_BIRTH_DATE_KEY,
  CHECK_ANALYSIS_RESULT_DATA_COMPARISON_NAME_KEY,
} from '.';
import {
  AnalysisResultCategoryEnum,
  AnalysisResultModel,
  CheckResultEnum,
} from '../../../shared/models';

// Compute the dataComparison result using analysisResults
// like it was done with legacy detailedResult.document.dataComparison
// backend/modules/checks/shared/identity-data-comparison/identity-data-comparison.service
export const computeIdDataComparisonResultFromAnalysisResults = (
  analysisResults: MarkRequired<
    Partial<AnalysisResultModel>,
    'key' | 'category'
  >[],
): CheckResultEnum | null => {
  const idDataComparisons = analysisResults.filter(
    (analysisResult) =>
      !analysisResult.vendor &&
      analysisResult.category === AnalysisResultCategoryEnum.data_comparison &&
      [
        CHECK_ANALYSIS_RESULT_DATA_COMPARISON_NAME_KEY,
        CHECK_ANALYSIS_RESULT_DATA_COMPARISON_BIRTH_DATE_KEY,
      ].includes(analysisResult?.key),
  );

  if (!idDataComparisons.length) {
    return null;
  }

  if (
    idDataComparisons.some(({ result }) => result === CheckResultEnum.rejected)
  ) {
    return CheckResultEnum.rejected;
  }

  const hasNameDataComparison = idDataComparisons.find(
    ({ key }) => key === CHECK_ANALYSIS_RESULT_DATA_COMPARISON_NAME_KEY,
  );

  if (!hasNameDataComparison) {
    return null;
  }

  return CheckResultEnum.approved;
};
