import { Search } from 'lucide-react';
import { useState } from 'react';

import { createUseDebounce } from '@dotfile/frontend/shared/common';

import {
  Input,
  InputGroup,
  InputRightElement,
} from '../../../form/input/input';
import { Icon } from '../../../media-icon/icon';

const useDebounce = createUseDebounce<[string]>(250);

type MultiSelectSearchProps = {
  onChangeSearch: (searchTerm: string) => void;
};
export const MultiSelectSearch = ({
  onChangeSearch,
}: MultiSelectSearchProps): JSX.Element => {
  const [search, setSearch] = useState('');

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setAppliedSearchDebounced(searchValue);
  };

  const setAppliedSearchDebounced = useDebounce(onChangeSearch);

  return (
    <InputGroup>
      <InputRightElement pointerEvents="none">
        <Icon as={Search} color="gray.200" />
      </InputRightElement>
      <Input
        variant="outline"
        placeholder="Search"
        value={search}
        onChange={handleOnChange}
      />
    </InputGroup>
  );
};
