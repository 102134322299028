import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

export const classificationsValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.classifications
> = ({ isRequired, localizedValidation }) => {
  const schema = yup
    .array()
    .min(isRequired ? 1 : 0, localizedValidation.classifications.min_element)
    .uniqueItems(
      (p) =>
        p.type && p.code ? `${p.type}:${p.code}:${p.description}` : undefined,
      localizedValidation.not_unique,
    )
    .of(
      yup.object({
        type: yup
          .string()
          .required(localizedValidation.required)
          .typeError(localizedValidation.required),
        code: yup
          .string()
          .required(localizedValidation.required)
          .typeError(localizedValidation.required),
        description: yup.string().optionalString(),
      }),
    );

  return schema.strict();
};
