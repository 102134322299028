import { ComponentStyleConfig } from '@chakra-ui/theme';

export const DropzoneTheme: ComponentStyleConfig = {
  baseStyle: () => ({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '4',
    borderWidth: '2px',
    borderRadius: 'base',
    borderStyle: 'dashed',
    borderColor: 'gray.100',
    backgroundColor: 'gray.25',
    color: 'text',
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
    cursor: 'pointer',
    _focus: {
      borderColor: 'gray.300',
      bg: 'gray.25',
    },
    _hover: {
      borderColor: 'gray.300',
      bg: 'gray.25',
    },
  }),
};
