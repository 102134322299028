import { omit } from 'lodash';

import {
  IdVerificationDataModeEnum,
  IdVerificationVendorEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { defaultIdVerificationSettings } from '../default-settings';
import { IdVerificationCheckInput } from '../id-verification-check-input';
import { idVerificationVendorDefinition } from '../id-verification-vendor-definition';

export const idVerificationSettingsSchema = (): yup.SchemaOf<
  IdVerificationCheckInput['settings']
> => {
  return yup
    .object({
      vendor: yup
        .mixed()
        .when('$legacyUbble', {
          is: (value: boolean) => !value,
          then: (schema) =>
            schema.oneOf([
              Object.values(omit(IdVerificationVendorEnum, ['ubble'])),
            ]),
          otherwise: (schema) =>
            schema.oneOf(Object.values(IdVerificationVendorEnum)),
        })
        .default(IdVerificationVendorEnum.checkout),
      mode: yup
        .mixed()
        .when('vendor', (vendor, schema) => {
          return schema.oneOf(
            idVerificationVendorDefinition[vendor as IdVerificationVendorEnum]
              ?.availableModes ?? [],
          );
        })
        .default(IdVerificationDataModeEnum.auto),
      automaticApproval: yup
        .boolean()
        .default(defaultIdVerificationSettings.automaticApproval),
      automaticRejection: yup
        .boolean()
        .default(defaultIdVerificationSettings.automaticRejection),
      redirectUrl: yup
        .string()
        .url()
        .default(defaultIdVerificationSettings.redirectUrl),
    })
    .noUnknown()
    .defined();
};
