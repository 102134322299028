import {
  HStack,
  Spacer,
  Text,
  useIsSmallScreen,
} from '@dotfile/frontend/shared/design-system';

import { LanguageSelector } from '../../components';
import { useLatestClientPortalVersion, useReadableColor } from '../../hooks';
import { Logo } from './logo';
import { LogoutButton } from './logout-button';

export const Header = () => {
  const { data } = useLatestClientPortalVersion();
  const readableColor = useReadableColor();
  const isSmallScreen = useIsSmallScreen();
  const { logo } = data?.latestClientPortalVersion.setting?.styling ?? {};

  if (!data) return null;

  return (
    <HStack
      height="16"
      py="3"
      px={isSmallScreen ? '6' : '12'}
      width="full"
      backgroundColor="custom.500"
    >
      {logo?.publicUrl ? (
        <Logo url={logo.publicUrl} name={logo?.name} />
      ) : (
        <Text color={readableColor}>
          {data.latestClientPortalVersion.clientPortal.name}
        </Text>
      )}

      <Spacer />

      <LogoutButton />

      {!isSmallScreen && <LanguageSelector color={readableColor} />}
    </HStack>
  );
};
