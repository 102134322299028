import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Cursor for paging through collections */
  ConnectionCursor: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type AddBusinessContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  /** Id of an individual to update or `null` to create a new individual as business contact */
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
};

export type AddBusinessContactResponse = {
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** Id of the business contact individual saved */
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type AddFilesDocumentCheckInput = {
  data: DocumentDataInput;
  /** Id of the check */
  id: Scalars['ID']['input'];
};

export type AddFilesIdDocumentCheckInput = {
  data: IdDocumentDataInput;
  /** Id of the check */
  id: Scalars['ID']['input'];
};

export type Address = {
  city?: Maybe<Scalars['String']['output']>;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  streetAddress2?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
};

export type AnalysisResult = {
  category: AnalysisResultCategoryEnum;
  description?: Maybe<Scalars['String']['output']>;
  expected?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  received?: Maybe<Scalars['String']['output']>;
  result: CheckResultEnum;
  translation?: Maybe<Scalars['JSONObject']['output']>;
  vendor?: Maybe<VendorEnum>;
};

/** Analysis result category */
export const AnalysisResultCategoryEnum = {
  data_comparison: 'data_comparison',
  document_acceptability: 'document_acceptability',
  document_expiration: 'document_expiration',
  document_integrity: 'document_integrity',
  fraud: 'fraud',
  image_integrity: 'image_integrity',
  other: 'other'
} as const;

export type AnalysisResultCategoryEnum = typeof AnalysisResultCategoryEnum[keyof typeof AnalysisResultCategoryEnum];
export type BankingInformation = {
  /**
   * [ISO 9362](https://en.wikipedia.org/wiki/ISO_9362) BIC (eg: `AGRIFRPPXXX`).
   * Spaces will be removed and value upper-cased.
   */
  bic?: Maybe<Scalars['String']['output']>;
  /**
   * [ISO 13616](https://en.wikipedia.org/wiki/International_Bank_Account_Number) IBAN (eg: `FR7630006000011234567890189`).
   * Spaces will be removed and value upper-cased.
   */
  iban?: Maybe<Scalars['String']['output']>;
};

export type BankingInformationInput = {
  /**
   * [ISO 9362](https://en.wikipedia.org/wiki/ISO_9362) BIC (eg: `AGRIFRPPXXX`).
   * Spaces will be removed and value upper-cased.
   */
  bic?: InputMaybe<Scalars['String']['input']>;
  /**
   * [ISO 13616](https://en.wikipedia.org/wiki/International_Bank_Account_Number) IBAN (eg: `FR7630006000011234567890189`).
   * Spaces will be removed and value upper-cased.
   */
  iban?: InputMaybe<Scalars['String']['input']>;
};

export type BaseBeneficialOwnerInterface = {
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
};

export type BaseRepresentativeInterface = {
  address: Address;
  entityType: CompanyEntityTypeEnum;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
};

export type BaseShareholderInterface = {
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
};

export type BeneficialOwnerUnion = CompanyBeneficialOwner | IndividualBeneficialOwner;

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Case = {
  companies: Array<Company>;
  contactHasActions: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  customPropertyValues: Array<CustomPropertyValue>;
  externalId?: Maybe<Scalars['String']['output']>;
  flags: Array<CaseFlagEnum>;
  id: Scalars['ID']['output'];
  individuals: Array<Individual>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  relations: Array<CaseRelation>;
  reviewerHasActions: Scalars['Boolean']['output'];
  status: CaseStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type CaseCompaniesArgs = {
  filter?: CompanyFilter;
  sorting?: Array<CompanySort>;
};


export type CaseCustomPropertyValuesArgs = {
  filter?: CustomPropertyValueFilter;
  sorting?: Array<CustomPropertyValueSort>;
};


export type CaseIndividualsArgs = {
  filter?: IndividualFilter;
  sorting?: Array<IndividualSort>;
};


export type CaseRelationsArgs = {
  filter?: CaseRelationFilter;
  sorting?: Array<CaseRelationSort>;
};

export type CaseAggregateGroupBy = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type CaseCountAggregate = {
  id?: Maybe<Scalars['Int']['output']>;
};

export type CaseDataInput = {
  companies?: InputMaybe<Array<CompanyDataInput>>;
  customProperties?: InputMaybe<Scalars['JSONObject']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  individuals?: InputMaybe<Array<IndividualDataInput>>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CaseEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Case */
  node: Case;
};

/** Case flag */
export const CaseFlagEnum = {
  all_checks_approved: 'all_checks_approved',
  first_collect_completed: 'first_collect_completed',
  for_first_collect: 'for_first_collect',
  for_recollection: 'for_recollection',
  for_review: 'for_review'
} as const;

export type CaseFlagEnum = typeof CaseFlagEnum[keyof typeof CaseFlagEnum];
export type CaseMaxAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type CaseMinAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type CaseRelation = {
  case: Case;
  caseId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<CaseRelationRoleEnum>>;
  sourceCompany: Company;
  sourceCompanyId: Scalars['ID']['output'];
  targetCompany?: Maybe<Company>;
  targetCompanyId?: Maybe<Scalars['ID']['output']>;
  targetIndividual?: Maybe<Individual>;
  targetIndividualId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  votingRightsPercentage?: Maybe<Scalars['Float']['output']>;
};

export type CaseRelationAggregateGroupBy = {
  caseId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sourceCompanyId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CaseRelationCountAggregate = {
  caseId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sourceCompanyId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type CaseRelationDataInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ownershipPercentage?: InputMaybe<Scalars['Float']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<CaseRelationRoleEnum>>;
  votingRightsPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type CaseRelationEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the CaseRelation */
  node: CaseRelation;
};

export type CaseRelationFilter = {
  and?: InputMaybe<Array<CaseRelationFilter>>;
  caseId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<CaseRelationFilter>>;
  sourceCompanyId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CaseRelationMaxAggregate = {
  caseId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sourceCompanyId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CaseRelationMinAggregate = {
  caseId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sourceCompanyId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Case Relation role. */
export const CaseRelationRoleEnum = {
  legal_representative: 'legal_representative',
  shareholder: 'shareholder'
} as const;

export type CaseRelationRoleEnum = typeof CaseRelationRoleEnum[keyof typeof CaseRelationRoleEnum];
export type CaseRelationSort = {
  direction: SortDirection;
  field: CaseRelationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const CaseRelationSortFields = {
  caseId: 'caseId',
  createdAt: 'createdAt',
  id: 'id',
  sourceCompanyId: 'sourceCompanyId',
  updatedAt: 'updatedAt'
} as const;

export type CaseRelationSortFields = typeof CaseRelationSortFields[keyof typeof CaseRelationSortFields];
/** Case status. */
export const CaseStatusEnum = {
  approved: 'approved',
  closed: 'closed',
  draft: 'draft',
  open: 'open',
  rejected: 'rejected'
} as const;

export type CaseStatusEnum = typeof CaseStatusEnum[keyof typeof CaseStatusEnum];
export type Check = {
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  individual?: Maybe<Individual>;
  individualId?: Maybe<Scalars['ID']['output']>;
  status: CheckStatusEnum;
  subtype?: Maybe<Scalars['String']['output']>;
  type: CheckTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type CheckFilter = {
  and?: InputMaybe<Array<CheckFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<CheckFilter>>;
};

export type CheckInterface = {
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  individual?: Maybe<Individual>;
  individualId?: Maybe<Scalars['ID']['output']>;
  status: CheckStatusEnum;
  subtype?: Maybe<Scalars['String']['output']>;
  type: CheckTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

/** Check result. */
export const CheckResultEnum = {
  approved: 'approved',
  error: 'error',
  rejected: 'rejected'
} as const;

export type CheckResultEnum = typeof CheckResultEnum[keyof typeof CheckResultEnum];
export type CheckSort = {
  direction: SortDirection;
  field: CheckSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const CheckSortFields = {
  createdAt: 'createdAt',
  id: 'id'
} as const;

export type CheckSortFields = typeof CheckSortFields[keyof typeof CheckSortFields];
/** Check status. */
export const CheckStatusEnum = {
  approved: 'approved',
  expired: 'expired',
  in_progress: 'in_progress',
  need_review: 'need_review',
  processing: 'processing',
  rejected: 'rejected'
} as const;

export type CheckStatusEnum = typeof CheckStatusEnum[keyof typeof CheckStatusEnum];
/** Check type. */
export const CheckTypeEnum = {
  aml: 'aml',
  document: 'document',
  id_document: 'id_document',
  id_verification: 'id_verification'
} as const;

export type CheckTypeEnum = typeof CheckTypeEnum[keyof typeof CheckTypeEnum];
export type ClientPortal = {
  createdAt: Scalars['DateTime']['output'];
  customDomain: ClientPortalCustomDomain;
  defaultLanguage: LanguageEnum;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: ClientPortalStatusEnum;
  type: ClientPortalTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  workspace: Workspace;
  workspaceId: Scalars['ID']['output'];
};

export type ClientPortalAggregateGroupBy = {
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<ClientPortalStatusEnum>;
  type?: Maybe<ClientPortalTypeEnum>;
};

/** Client portal available default language (en/fr/it/de/es/nl/pt/pl) */
export const ClientPortalAvailableDefaultLanguageEnum = {
  de: 'de',
  en: 'en',
  es: 'es',
  fr: 'fr',
  it: 'it',
  nl: 'nl',
  pl: 'pl',
  pt: 'pt'
} as const;

export type ClientPortalAvailableDefaultLanguageEnum = typeof ClientPortalAvailableDefaultLanguageEnum[keyof typeof ClientPortalAvailableDefaultLanguageEnum];
export type ClientPortalBlock = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  logics: Array<ClientPortalLogic>;
  /** Must be a float to support reorder by dichotomy */
  position: Scalars['Float']['output'];
  stepId: Scalars['ID']['output'];
  type: ClientPortalBlockTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type ClientPortalBlockLogicsArgs = {
  filter?: ClientPortalLogicFilter;
  sorting?: Array<ClientPortalLogicSort>;
};

export type ClientPortalBlockAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockAvgAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalBlockCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  stepId?: Maybe<Scalars['Int']['output']>;
};

export type ClientPortalBlockEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ClientPortalBlock */
  node: ClientPortalBlockInterface;
};

export type ClientPortalBlockField = ClientPortalBlockInterface & {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  display?: Maybe<ClientPortalBlockFieldDisplayEnum>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  logics: Array<ClientPortalLogic>;
  mapping: ClientPortalBlockFieldMapping;
  options: Array<ClientPortalFieldOption>;
  /** Must be a float to support reorder by dichotomy */
  position: Scalars['Float']['output'];
  property?: Maybe<ClientPortalBlockFieldProperty>;
  stepId: Scalars['ID']['output'];
  type: ClientPortalBlockTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type ClientPortalBlockFieldLogicsArgs = {
  filter?: ClientPortalLogicFilter;
  sorting?: Array<ClientPortalLogicSort>;
};


export type ClientPortalBlockFieldOptionsArgs = {
  filter?: ClientPortalFieldOptionFilter;
  sorting?: Array<ClientPortalFieldOptionSort>;
};

export type ClientPortalBlockFieldAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockFieldAvgAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalBlockFieldCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  stepId?: Maybe<Scalars['Int']['output']>;
};

/** ClientPortalBlockField display mode */
export const ClientPortalBlockFieldDisplayEnum = {
  checkbox_radio: 'checkbox_radio',
  dropdown: 'dropdown',
  yes_no: 'yes_no'
} as const;

export type ClientPortalBlockFieldDisplayEnum = typeof ClientPortalBlockFieldDisplayEnum[keyof typeof ClientPortalBlockFieldDisplayEnum];
export type ClientPortalBlockFieldEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ClientPortalBlockField */
  node: ClientPortalBlockField;
};

export type ClientPortalBlockFieldMapping = {
  entity: PropertyMappingEntityEnum;
  key: Scalars['String']['output'];
  type: ClientPortalFieldMappingTypeEnum;
};

export type ClientPortalBlockFieldMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockFieldMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockFieldProperty = {
  settings?: Maybe<Scalars['JSONObject']['output']>;
  type: PropertyTypeEnum;
};

export type ClientPortalBlockFieldSumAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalBlockFilter = {
  and?: InputMaybe<Array<ClientPortalBlockFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ClientPortalBlockFilter>>;
  position?: InputMaybe<FloatFieldComparison>;
  stepId?: InputMaybe<IdFilterComparison>;
};

export type ClientPortalBlockInterface = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  /** Must be a float to support reorder by dichotomy */
  position: Scalars['Float']['output'];
  stepId: Scalars['ID']['output'];
  type: ClientPortalBlockTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientPortalBlockLayout = ClientPortalBlockInterface & {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  logics: Array<ClientPortalLogic>;
  /** Must be a float to support reorder by dichotomy */
  position: Scalars['Float']['output'];
  stepId: Scalars['ID']['output'];
  type: ClientPortalBlockTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  widget: ClientPortalBlockLayoutWidgetEnum;
};


export type ClientPortalBlockLayoutLogicsArgs = {
  filter?: ClientPortalLogicFilter;
  sorting?: Array<ClientPortalLogicSort>;
};

export type ClientPortalBlockLayoutAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockLayoutAvgAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalBlockLayoutCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  stepId?: Maybe<Scalars['Int']['output']>;
};

export type ClientPortalBlockLayoutEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ClientPortalBlockLayout */
  node: ClientPortalBlockLayout;
};

export type ClientPortalBlockLayoutMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockLayoutMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockLayoutSumAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

/** ClientPortalBlockLayout widget enum (text/divider/pdf) */
export const ClientPortalBlockLayoutWidgetEnum = {
  divider: 'divider',
  image: 'image',
  pdf: 'pdf',
  text: 'text'
} as const;

export type ClientPortalBlockLayoutWidgetEnum = typeof ClientPortalBlockLayoutWidgetEnum[keyof typeof ClientPortalBlockLayoutWidgetEnum];
export type ClientPortalBlockMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  stepId?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalBlockSort = {
  direction: SortDirection;
  field: ClientPortalBlockSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const ClientPortalBlockSortFields = {
  createdAt: 'createdAt',
  id: 'id',
  position: 'position',
  stepId: 'stepId'
} as const;

export type ClientPortalBlockSortFields = typeof ClientPortalBlockSortFields[keyof typeof ClientPortalBlockSortFields];
export type ClientPortalBlockSumAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

/** ClientPortalBlock type enum (field/layout) */
export const ClientPortalBlockTypeEnum = {
  field: 'field',
  layout: 'layout'
} as const;

export type ClientPortalBlockTypeEnum = typeof ClientPortalBlockTypeEnum[keyof typeof ClientPortalBlockTypeEnum];
/** Client portal collaboration (none/existing_only/existing_and_add_new) */
export const ClientPortalCollaborationEnum = {
  existing_and_add_new: 'existing_and_add_new',
  existing_only: 'existing_only',
  none: 'none'
} as const;

export type ClientPortalCollaborationEnum = typeof ClientPortalCollaborationEnum[keyof typeof ClientPortalCollaborationEnum];
export type ClientPortalContactAccessToken = {
  accessToken: Scalars['String']['output'];
  caseId: Scalars['ID']['output'];
  clientPortalId: Scalars['ID']['output'];
  contactId: Scalars['ID']['output'];
  expiresAt: Scalars['DateTime']['output'];
  refreshExpiresAt: Scalars['DateTime']['output'];
};

export type ClientPortalCountAggregate = {
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type ClientPortalCustomDomain = {
  domain?: Maybe<Scalars['String']['output']>;
  isVerified: Scalars['Boolean']['output'];
};

/** ClientPortalFieldMappingTypeEnum */
export const ClientPortalFieldMappingTypeEnum = {
  custom: 'custom',
  default: 'default'
} as const;

export type ClientPortalFieldMappingTypeEnum = typeof ClientPortalFieldMappingTypeEnum[keyof typeof ClientPortalFieldMappingTypeEnum];
export type ClientPortalFieldOption = {
  context?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fieldId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientPortalFieldOptionAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalFieldOptionCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  fieldId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ClientPortalFieldOptionFilter = {
  and?: InputMaybe<Array<ClientPortalFieldOptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  fieldId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ClientPortalFieldOptionFilter>>;
};

export type ClientPortalFieldOptionMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalFieldOptionMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalFieldOptionSort = {
  direction: SortDirection;
  field: ClientPortalFieldOptionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const ClientPortalFieldOptionSortFields = {
  createdAt: 'createdAt',
  fieldId: 'fieldId',
  id: 'id'
} as const;

export type ClientPortalFieldOptionSortFields = typeof ClientPortalFieldOptionSortFields[keyof typeof ClientPortalFieldOptionSortFields];
export type ClientPortalLogic = {
  blockId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  jsonLogic: Scalars['JSON']['output'];
  stepId?: Maybe<Scalars['ID']['output']>;
  type: ClientPortalLogicTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientPortalLogicFilter = {
  and?: InputMaybe<Array<ClientPortalLogicFilter>>;
  blockId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isEnabled?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ClientPortalLogicFilter>>;
  stepId?: InputMaybe<IdFilterComparison>;
  type?: InputMaybe<ClientPortalLogicTypeEnumFilterComparison>;
};

export type ClientPortalLogicSort = {
  direction: SortDirection;
  field: ClientPortalLogicSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const ClientPortalLogicSortFields = {
  blockId: 'blockId',
  createdAt: 'createdAt',
  id: 'id',
  isEnabled: 'isEnabled',
  stepId: 'stepId',
  type: 'type'
} as const;

export type ClientPortalLogicSortFields = typeof ClientPortalLogicSortFields[keyof typeof ClientPortalLogicSortFields];
/** ClientPortalLogic type enum (display/required) */
export const ClientPortalLogicTypeEnum = {
  display: 'display',
  prevent_submit: 'prevent_submit',
  required: 'required'
} as const;

export type ClientPortalLogicTypeEnum = typeof ClientPortalLogicTypeEnum[keyof typeof ClientPortalLogicTypeEnum];
export type ClientPortalLogicTypeEnumFilterComparison = {
  eq?: InputMaybe<ClientPortalLogicTypeEnum>;
  gt?: InputMaybe<ClientPortalLogicTypeEnum>;
  gte?: InputMaybe<ClientPortalLogicTypeEnum>;
  iLike?: InputMaybe<ClientPortalLogicTypeEnum>;
  in?: InputMaybe<Array<ClientPortalLogicTypeEnum>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ClientPortalLogicTypeEnum>;
  lt?: InputMaybe<ClientPortalLogicTypeEnum>;
  lte?: InputMaybe<ClientPortalLogicTypeEnum>;
  neq?: InputMaybe<ClientPortalLogicTypeEnum>;
  notILike?: InputMaybe<ClientPortalLogicTypeEnum>;
  notIn?: InputMaybe<Array<ClientPortalLogicTypeEnum>>;
  notLike?: InputMaybe<ClientPortalLogicTypeEnum>;
};

export type ClientPortalMaxAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<ClientPortalStatusEnum>;
  type?: Maybe<ClientPortalTypeEnum>;
};

export type ClientPortalMinAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<ClientPortalStatusEnum>;
  type?: Maybe<ClientPortalTypeEnum>;
};

export type ClientPortalSetting = {
  clientPortal: ClientPortal;
  collaboration: ClientPortalCollaborationEnum;
  id: Scalars['ID']['output'];
  isCaseCreationAllowed: Scalars['Boolean']['output'];
  magicLinkTTL: Scalars['Float']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
  styling: ClientPortalSettingStyling;
  supportEmail?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
};

export type ClientPortalSettingAggregateGroupBy = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalSettingCountAggregate = {
  id?: Maybe<Scalars['Int']['output']>;
};

export type ClientPortalSettingEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ClientPortalSetting */
  node: ClientPortalSetting;
};

export type ClientPortalSettingMaxAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalSettingMinAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalSettingStyling = {
  colorsMain: Scalars['String']['output'];
  favicon?: Maybe<File>;
  faviconId?: Maybe<Scalars['ID']['output']>;
  logo?: Maybe<File>;
  logoId?: Maybe<Scalars['ID']['output']>;
  showDotfileLogo: Scalars['Boolean']['output'];
};

/** ClientPortal status enum */
export const ClientPortalStatusEnum = {
  offline: 'offline',
  online: 'online'
} as const;

export type ClientPortalStatusEnum = typeof ClientPortalStatusEnum[keyof typeof ClientPortalStatusEnum];
export type ClientPortalStep = {
  blocks: Array<ClientPortalBlockInterface>;
  clientPortalVersionId: Scalars['ID']['output'];
  config?: Maybe<Scalars['JSONObject']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isVisible: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  logics: Array<ClientPortalLogic>;
  /** Must be a float to support reorder by dichotomy */
  position: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  type: ClientPortalStepTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type ClientPortalStepBlocksArgs = {
  filter?: ClientPortalBlockFilter;
  sorting?: Array<ClientPortalBlockSort>;
};


export type ClientPortalStepLogicsArgs = {
  filter?: ClientPortalLogicFilter;
  sorting?: Array<ClientPortalLogicSort>;
};

export type ClientPortalStepAggregateGroupBy = {
  id?: Maybe<Scalars['ID']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalStepAvgAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalStepCountAggregate = {
  id?: Maybe<Scalars['Int']['output']>;
  isVisible?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

export type ClientPortalStepFilter = {
  and?: InputMaybe<Array<ClientPortalStepFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isVisible?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ClientPortalStepFilter>>;
  position?: InputMaybe<FloatFieldComparison>;
};

export type ClientPortalStepMaxAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalStepMinAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalStepSort = {
  direction: SortDirection;
  field: ClientPortalStepSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const ClientPortalStepSortFields = {
  id: 'id',
  isVisible: 'isVisible',
  position: 'position'
} as const;

export type ClientPortalStepSortFields = typeof ClientPortalStepSortFields[keyof typeof ClientPortalStepSortFields];
export type ClientPortalStepSumAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

/** ClientPortalStep type enum */
export const ClientPortalStepTypeEnum = {
  affiliated_companies_edit: 'affiliated_companies_edit',
  business_contact: 'business_contact',
  company_edit: 'company_edit',
  company_search: 'company_search',
  custom: 'custom',
  individual_edit: 'individual_edit',
  individuals_edit: 'individuals_edit'
} as const;

export type ClientPortalStepTypeEnum = typeof ClientPortalStepTypeEnum[keyof typeof ClientPortalStepTypeEnum];
export type ClientPortalTranslation = {
  clientPortalVersionId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  fileId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** ISO-639-1 alpha-2 string (eg: 'fr', 'en', ...) */
  language: LanguageEnum;
  /** Must be a float to support reorder by dichotomy */
  position: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientPortalTranslationAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalTranslationAvgAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalTranslationCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  enabled?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

export type ClientPortalTranslationFilter = {
  and?: InputMaybe<Array<ClientPortalTranslationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ClientPortalTranslationFilter>>;
  position?: InputMaybe<FloatFieldComparison>;
};

export type ClientPortalTranslationMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalTranslationMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

export type ClientPortalTranslationSort = {
  direction: SortDirection;
  field: ClientPortalTranslationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const ClientPortalTranslationSortFields = {
  createdAt: 'createdAt',
  enabled: 'enabled',
  id: 'id',
  position: 'position'
} as const;

export type ClientPortalTranslationSortFields = typeof ClientPortalTranslationSortFields[keyof typeof ClientPortalTranslationSortFields];
export type ClientPortalTranslationSumAggregate = {
  position?: Maybe<Scalars['Float']['output']>;
};

/** ClientPortal type enum */
export const ClientPortalTypeEnum = {
  KYB: 'KYB',
  KYC: 'KYC'
} as const;

export type ClientPortalTypeEnum = typeof ClientPortalTypeEnum[keyof typeof ClientPortalTypeEnum];
export type ClientPortalVersion = {
  clientPortal: ClientPortal;
  clientPortalId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isLatest: Scalars['Boolean']['output'];
  setting: ClientPortalSetting;
  steps: Array<ClientPortalStep>;
  translations: Array<ClientPortalTranslation>;
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};


export type ClientPortalVersionStepsArgs = {
  filter?: ClientPortalStepFilter;
  sorting?: Array<ClientPortalStepSort>;
};


export type ClientPortalVersionTranslationsArgs = {
  filter?: ClientPortalTranslationFilter;
  sorting?: Array<ClientPortalTranslationSort>;
};

export type ClientPortalVersionAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalVersionCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ClientPortalVersionEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ClientPortalVersion */
  node: ClientPortalVersion;
};

export type ClientPortalVersionMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ClientPortalVersionMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type Company = {
  address: Address;
  bankingInformation: BankingInformation;
  case: Case;
  checks: Array<CheckInterface>;
  classifications?: Maybe<Array<CompanyClassification>>;
  commercialName?: Maybe<Scalars['String']['output']>;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customPropertyValues: Array<CustomPropertyValue>;
  employerIdentificationNumber?: Maybe<Scalars['String']['output']>;
  /** [ISO 20275](https://www.gleif.org/en/about-lei/iso-20275-entity-legal-forms-code-list) code (eg: `6CHY`) */
  entityLegalForm?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRelevant: Scalars['Boolean']['output'];
  legalForm?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** Format `yyyy-MM-dd` */
  registrationDate?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  shareCapital?: Maybe<Scalars['String']['output']>;
  status: CompanyStatusEnum;
  taxIdentificationNumber?: Maybe<Scalars['String']['output']>;
  type: CompanyTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};


export type CompanyChecksArgs = {
  filter?: CheckFilter;
  sorting?: Array<CheckSort>;
};


export type CompanyCustomPropertyValuesArgs = {
  filter?: CustomPropertyValueFilter;
  sorting?: Array<CustomPropertyValueSort>;
};

export type CompanyAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<CompanyTypeEnum>;
};

export type CompanyBeneficialOwner = BaseBeneficialOwnerInterface & BaseRepresentativeInterface & CompanyRepresentativeInterface & {
  address: Address;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: Maybe<Scalars['String']['output']>;
  /** [ISO 20275](https://www.gleif.org/en/about-lei/iso-20275-entity-legal-forms-code-list) code (eg: `6CHY`) */
  entityLegalForm?: Maybe<Scalars['String']['output']>;
  entityType: CompanyEntityTypeEnum;
  legalForm?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanyClassification = {
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type CompanyClassificationInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type CompanyCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isRelevant?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type CompanyCountry = {
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  code: Scalars['String']['output'];
  emoji: Scalars['String']['output'];
  emojiCode: Scalars['String']['output'];
  localName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  vendor: Scalars['String']['output'];
};

export type CompanyData = {
  address: Address;
  beneficialOwners: Array<BeneficialOwnerUnion>;
  classifications: Array<CompanyClassification>;
  commercialName?: Maybe<Scalars['String']['output']>;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country: Scalars['String']['output'];
  /** [ISO 20275](https://www.gleif.org/en/about-lei/iso-20275-entity-legal-forms-code-list) code (eg: `6CHY`) */
  entityLegalForm?: Maybe<Scalars['String']['output']>;
  legalForm?: Maybe<Scalars['String']['output']>;
  legalRepresentatives: Array<RepresentativeUnion>;
  mergedCompanies: Array<MergedCompany>;
  mergedIndividuals: Array<MergedIndividual>;
  name: Scalars['String']['output'];
  registrationDate?: Maybe<Scalars['String']['output']>;
  registrationNumber: Scalars['String']['output'];
  shareCapital?: Maybe<Scalars['String']['output']>;
  shareholders: Array<ShareholderUnion>;
  status: CompanyStatusEnum;
  taxIdentificationNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanyDataInput = {
  address?: InputMaybe<AddressInput>;
  bankingInformation?: InputMaybe<BankingInformationInput>;
  classifications?: InputMaybe<Array<CompanyClassificationInput>>;
  commercialName?: InputMaybe<Scalars['String']['input']>;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: InputMaybe<Scalars['String']['input']>;
  customProperties?: InputMaybe<Scalars['JSONObject']['input']>;
  employerIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
  /** [ISO 20275](https://www.gleif.org/en/about-lei/iso-20275-entity-legal-forms-code-list) code (eg: `6CHY`) */
  entityLegalForm?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  legalForm?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** Format `yyyy-MM-dd` */
  registrationDate?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  /** Relation from the main company (source) to this company (target) */
  relations?: InputMaybe<Array<CaseRelationDataInput>>;
  shareCapital?: InputMaybe<Scalars['String']['input']>;
  /** Will default to `not_reported` */
  status?: InputMaybe<CompanyStatusEnum>;
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
  type: CompanyTypeEnum;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Vendor for company data only */
export const CompanyDataVendorEnum = {
  companies_house: 'companies_house',
  creditsafe: 'creditsafe',
  inpi: 'inpi',
  kompany: 'kompany',
  kyckr: 'kyckr',
  pappers: 'pappers'
} as const;

export type CompanyDataVendorEnum = typeof CompanyDataVendorEnum[keyof typeof CompanyDataVendorEnum];
/** Company entity type. (company/individual) */
export const CompanyEntityTypeEnum = {
  company: 'company',
  individual: 'individual'
} as const;

export type CompanyEntityTypeEnum = typeof CompanyEntityTypeEnum[keyof typeof CompanyEntityTypeEnum];
export type CompanyFetchInput = {
  searchRef: Scalars['String']['input'];
};

export type CompanyFilter = {
  and?: InputMaybe<Array<CompanyFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isRelevant?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<CompanyFilter>>;
  type?: InputMaybe<CompanyTypeEnumFilterComparison>;
};

export type CompanyMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<CompanyTypeEnum>;
};

export type CompanyMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<CompanyTypeEnum>;
};

export type CompanyRepresentative = BaseRepresentativeInterface & CompanyRepresentativeInterface & {
  address: Address;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: Maybe<Scalars['String']['output']>;
  /** [ISO 20275](https://www.gleif.org/en/about-lei/iso-20275-entity-legal-forms-code-list) code (eg: `6CHY`) */
  entityLegalForm?: Maybe<Scalars['String']['output']>;
  entityType: CompanyEntityTypeEnum;
  legalForm?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanyRepresentativeInterface = {
  address: Address;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: Maybe<Scalars['String']['output']>;
  /** [ISO 20275](https://www.gleif.org/en/about-lei/iso-20275-entity-legal-forms-code-list) code (eg: `6CHY`) */
  entityLegalForm?: Maybe<Scalars['String']['output']>;
  entityType: CompanyEntityTypeEnum;
  legalForm?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanySearch = {
  address: Address;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  registrationNumber: Scalars['String']['output'];
  searchRef: Scalars['String']['output'];
  vendor: CompanyDataVendorEnum;
  vendorId: Scalars['String']['output'];
};

export type CompanySearchInput = {
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CompanySearchList = {
  data: Array<CompanySearch>;
};

export type CompanyShareholder = BaseRepresentativeInterface & BaseShareholderInterface & CompanyRepresentativeInterface & {
  address: Address;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: Maybe<Scalars['String']['output']>;
  /** [ISO 20275](https://www.gleif.org/en/about-lei/iso-20275-entity-legal-forms-code-list) code (eg: `6CHY`) */
  entityLegalForm?: Maybe<Scalars['String']['output']>;
  entityType: CompanyEntityTypeEnum;
  legalForm?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanySort = {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const CompanySortFields = {
  createdAt: 'createdAt',
  id: 'id',
  isRelevant: 'isRelevant',
  type: 'type'
} as const;

export type CompanySortFields = typeof CompanySortFields[keyof typeof CompanySortFields];
/** Company status. */
export const CompanyStatusEnum = {
  closed: 'closed',
  live: 'live',
  not_reported: 'not_reported'
} as const;

export type CompanyStatusEnum = typeof CompanyStatusEnum[keyof typeof CompanyStatusEnum];
/** Company type. */
export const CompanyTypeEnum = {
  affiliated: 'affiliated',
  main: 'main'
} as const;

export type CompanyTypeEnum = typeof CompanyTypeEnum[keyof typeof CompanyTypeEnum];
export type CompanyTypeEnumFilterComparison = {
  eq?: InputMaybe<CompanyTypeEnum>;
  gt?: InputMaybe<CompanyTypeEnum>;
  gte?: InputMaybe<CompanyTypeEnum>;
  iLike?: InputMaybe<CompanyTypeEnum>;
  in?: InputMaybe<Array<CompanyTypeEnum>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<CompanyTypeEnum>;
  lt?: InputMaybe<CompanyTypeEnum>;
  lte?: InputMaybe<CompanyTypeEnum>;
  neq?: InputMaybe<CompanyTypeEnum>;
  notILike?: InputMaybe<CompanyTypeEnum>;
  notIn?: InputMaybe<Array<CompanyTypeEnum>>;
  notLike?: InputMaybe<CompanyTypeEnum>;
};

export type Contact = {
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomDocumentType = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  targetEntities: Array<EntityTypeEnum>;
  updatedAt: Scalars['DateTime']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type CustomProperty = {
  createdAt: Scalars['DateTime']['output'];
  customPropertyOptions: Array<CustomPropertyOption>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  settings?: Maybe<Scalars['JSONObject']['output']>;
  targetEntity: CustomPropertyEntityTypeEnum;
  type: CustomPropertyTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  workspaceId: Scalars['ID']['output'];
};


export type CustomPropertyCustomPropertyOptionsArgs = {
  filter?: CustomPropertyOptionFilter;
  sorting?: Array<CustomPropertyOptionSort>;
};

export type CustomPropertyAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  targetEntity?: Maybe<CustomPropertyEntityTypeEnum>;
  type?: Maybe<CustomPropertyTypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomPropertyCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['Int']['output']>;
  targetEntity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

/** Custom property targetEntity type enum */
export const CustomPropertyEntityTypeEnum = {
  case: 'case',
  company: 'company',
  individual: 'individual'
} as const;

export type CustomPropertyEntityTypeEnum = typeof CustomPropertyEntityTypeEnum[keyof typeof CustomPropertyEntityTypeEnum];
export type CustomPropertyMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  targetEntity?: Maybe<CustomPropertyEntityTypeEnum>;
  type?: Maybe<CustomPropertyTypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomPropertyMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  targetEntity?: Maybe<CustomPropertyEntityTypeEnum>;
  type?: Maybe<CustomPropertyTypeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomPropertyOption = {
  createdAt: Scalars['DateTime']['output'];
  customPropertyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  position: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomPropertyOptionFilter = {
  and?: InputMaybe<Array<CustomPropertyOptionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customPropertyId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  key?: InputMaybe<StringFieldComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CustomPropertyOptionFilter>>;
  position?: InputMaybe<NumberFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomPropertyOptionSort = {
  direction: SortDirection;
  field: CustomPropertyOptionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const CustomPropertyOptionSortFields = {
  createdAt: 'createdAt',
  customPropertyId: 'customPropertyId',
  id: 'id',
  key: 'key',
  label: 'label',
  position: 'position',
  updatedAt: 'updatedAt'
} as const;

export type CustomPropertyOptionSortFields = typeof CustomPropertyOptionSortFields[keyof typeof CustomPropertyOptionSortFields];
/** Custom property type enum */
export const CustomPropertyTypeEnum = {
  boolean: 'boolean',
  choices: 'choices',
  countries: 'countries',
  date: 'date',
  email: 'email',
  numeric: 'numeric',
  phone_number: 'phone_number',
  text: 'text',
  url: 'url'
} as const;

export type CustomPropertyTypeEnum = typeof CustomPropertyTypeEnum[keyof typeof CustomPropertyTypeEnum];
export type CustomPropertyValue = {
  caseId?: Maybe<Scalars['ID']['output']>;
  companyId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customProperty: CustomProperty;
  customPropertyId: Scalars['ID']['output'];
  customPropertyOption?: Maybe<CustomPropertyOption>;
  customPropertyOptionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  individualId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type CustomPropertyValueAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomPropertyValueCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type CustomPropertyValueEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the CustomPropertyValue */
  node: CustomPropertyValue;
};

export type CustomPropertyValueFilter = {
  and?: InputMaybe<Array<CustomPropertyValueFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<CustomPropertyValueFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CustomPropertyValueMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomPropertyValueMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomPropertyValueSort = {
  direction: SortDirection;
  field: CustomPropertyValueSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const CustomPropertyValueSortFields = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
} as const;

export type CustomPropertyValueSortFields = typeof CustomPropertyValueSortFields[keyof typeof CustomPropertyValueSortFields];
export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DetailedResultsDocument = {
  compromisedDocument?: Maybe<CheckResultEnum>;
  dataComparison?: Maybe<CheckResultEnum>;
  dataConsistency?: Maybe<CheckResultEnum>;
  dataValidation?: Maybe<CheckResultEnum>;
  imageIntegrity?: Maybe<CheckResultEnum>;
  visualAuthenticity?: Maybe<CheckResultEnum>;
};

export type DocumentAnalysisSettings = {
  parameters: Scalars['JSONObject']['output'];
};

export type DocumentCheck = CheckInterface & {
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data: DocumentData;
  dataHistories: DocumentCheckDataHistoriesConnection;
  id: Scalars['ID']['output'];
  individual?: Maybe<Individual>;
  individualId?: Maybe<Scalars['ID']['output']>;
  status: CheckStatusEnum;
  subtype?: Maybe<Scalars['String']['output']>;
  type: CheckTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type DocumentCheckDataHistoriesArgs = {
  filter?: DocumentDataFilter;
  paging?: CursorPaging;
  sorting?: Array<DocumentDataSort>;
};

export type DocumentCheckAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DocumentCheckCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type DocumentCheckDataHistoriesConnection = {
  /** Array of edges. */
  edges: Array<DocumentDataEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type DocumentCheckMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DocumentCheckMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DocumentData = {
  analysisResults: Array<AnalysisResult>;
  check: DocumentCheck;
  checkId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use analysisResults instead, see E-5020 - Remove legacy detailed results and migrations */
  detailedResults: DocumentDataDetailedResults;
  files: Array<DocumentDataFile>;
  id: Scalars['ID']['output'];
  information: DocumentDataInformation;
  isLatest: Scalars['Boolean']['output'];
  result?: Maybe<CheckResultEnum>;
  review: Review;
  settings: DocumentDataSettings;
  updatedAt: Scalars['DateTime']['output'];
};


export type DocumentDataFilesArgs = {
  filter?: DocumentDataFileFilter;
  sorting?: Array<DocumentDataFileSort>;
};

export type DocumentDataAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DocumentDataCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type DocumentDataDetailedResults = {
  documentAnalysis?: Maybe<Scalars['JSONObject']['output']>;
};

export type DocumentDataEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the DocumentData */
  node: DocumentData;
};

export type DocumentDataFile = {
  fileId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
};

export type DocumentDataFileFilter = {
  and?: InputMaybe<Array<DocumentDataFileFilter>>;
  or?: InputMaybe<Array<DocumentDataFileFilter>>;
  position?: InputMaybe<IntFieldComparison>;
};

export type DocumentDataFileInput = {
  /** The upload ref generated by the POST /uploads/{storage} endpoint */
  uploadRef: Scalars['String']['input'];
};

export type DocumentDataFileSort = {
  direction: SortDirection;
  field: DocumentDataFileSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const DocumentDataFileSortFields = {
  position: 'position'
} as const;

export type DocumentDataFileSortFields = typeof DocumentDataFileSortFields[keyof typeof DocumentDataFileSortFields];
export type DocumentDataFilter = {
  and?: InputMaybe<Array<DocumentDataFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DocumentDataFilter>>;
};

export type DocumentDataInformation = {
  documentAnalysis?: Maybe<Scalars['JSONObject']['output']>;
  /** Date in format `yyyy-MM-dd` without time nor timezone information */
  expirationDate?: Maybe<Scalars['String']['output']>;
};

export type DocumentDataInput = {
  files: Array<DocumentDataFileInput>;
  languages?: InputMaybe<Array<ClientPortalAvailableDefaultLanguageEnum>>;
};

export type DocumentDataMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DocumentDataMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DocumentDataSettings = {
  customDocumentType?: Maybe<CustomDocumentType>;
  customDocumentTypeId?: Maybe<Scalars['String']['output']>;
  documentAnalysis?: Maybe<DocumentAnalysisSettings>;
  documentType?: Maybe<DocumentTypeEnum>;
};

export type DocumentDataSort = {
  direction: SortDirection;
  field: DocumentDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const DocumentDataSortFields = {
  createdAt: 'createdAt',
  id: 'id'
} as const;

export type DocumentDataSortFields = typeof DocumentDataSortFields[keyof typeof DocumentDataSortFields];
/** Document type */
export const DocumentTypeEnum = {
  articles_of_association: 'articles_of_association',
  bank_details: 'bank_details',
  birth_certificate: 'birth_certificate',
  criminal_record_extract: 'criminal_record_extract',
  driving_license: 'driving_license',
  financial_statements: 'financial_statements',
  id_document: 'id_document',
  proof_of_address: 'proof_of_address',
  proof_of_insurance: 'proof_of_insurance',
  registration_certificate: 'registration_certificate',
  social_security_card: 'social_security_card',
  tax_notice: 'tax_notice',
  ubo_registry_extract: 'ubo_registry_extract',
  vehicle_registration_certificate: 'vehicle_registration_certificate',
  visa: 'visa'
} as const;

export type DocumentTypeEnum = typeof DocumentTypeEnum[keyof typeof DocumentTypeEnum];
/** Entity type */
export const EntityTypeEnum = {
  company: 'company',
  individual: 'individual'
} as const;

export type EntityTypeEnum = typeof EntityTypeEnum[keyof typeof EntityTypeEnum];
export type File = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  publicUrl?: Maybe<Scalars['String']['output']>;
  size: Scalars['Float']['output'];
  storage: FileStorageEnum;
  target: FileTargetEnum;
  updatedAt: Scalars['DateTime']['output'];
};

/** File Storage */
export const FileStorageEnum = {
  document: 'document',
  main: 'main'
} as const;

export type FileStorageEnum = typeof FileStorageEnum[keyof typeof FileStorageEnum];
/** File target */
export const FileTargetEnum = {
  case_report_file: 'case_report_file',
  client_portal_block_image: 'client_portal_block_image',
  client_portal_block_pdf: 'client_portal_block_pdf',
  client_portal_email_logo: 'client_portal_email_logo',
  client_portal_favicon: 'client_portal_favicon',
  client_portal_logo: 'client_portal_logo',
  client_portal_translation: 'client_portal_translation',
  communication_attachment: 'communication_attachment',
  communication_logo: 'communication_logo',
  document_data_file: 'document_data_file',
  document_order_file: 'document_order_file',
  id_document_data_back: 'id_document_data_back',
  id_document_data_front: 'id_document_data_front',
  id_verification_data_back: 'id_verification_data_back',
  id_verification_data_face: 'id_verification_data_face',
  id_verification_data_front: 'id_verification_data_front',
  id_verification_data_report: 'id_verification_data_report',
  id_verification_data_signature: 'id_verification_data_signature',
  id_verification_data_video: 'id_verification_data_video',
  import_file: 'import_file',
  user_avatar: 'user_avatar',
  workspace_logo: 'workspace_logo'
} as const;

export type FileTargetEnum = typeof FileTargetEnum[keyof typeof FileTargetEnum];
export type FloatFieldComparison = {
  between?: InputMaybe<FloatFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<FloatFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type ForceReviewDocumentCheckInput = {
  /** Id of the check */
  id: Scalars['ID']['input'];
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
};

export type IdDocumentCheck = CheckInterface & {
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data: IdDocumentData;
  dataHistories: IdDocumentCheckDataHistoriesConnection;
  id: Scalars['ID']['output'];
  individual?: Maybe<Individual>;
  individualId?: Maybe<Scalars['ID']['output']>;
  status: CheckStatusEnum;
  subtype?: Maybe<Scalars['String']['output']>;
  type: CheckTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type IdDocumentCheckDataHistoriesArgs = {
  filter?: IdDocumentDataFilter;
  paging?: CursorPaging;
  sorting?: Array<IdDocumentDataSort>;
};

export type IdDocumentCheckAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdDocumentCheckCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IdDocumentCheckDataHistoriesConnection = {
  /** Array of edges. */
  edges: Array<IdDocumentDataEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type IdDocumentCheckMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdDocumentCheckMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdDocumentData = {
  analysisResults: Array<AnalysisResult>;
  check: IdDocumentCheck;
  checkId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use analysisResults instead, see E-5020 - Remove legacy detailed results and migrations */
  detailedResults: IdDocumentDataDetailedResults;
  hasFiles: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  information: IdDocumentDataInformation;
  isLatest: Scalars['Boolean']['output'];
  result?: Maybe<CheckResultEnum>;
  review: Review;
  updatedAt: Scalars['DateTime']['output'];
  vendor: IdDocumentDataVendor;
};

export type IdDocumentDataAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdDocumentDataCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IdDocumentDataDetailedResults = {
  /** @deprecated Use analysisResults instead, see E-5020 - Remove legacy detailed results and migrations */
  document: DetailedResultsDocument;
};

export type IdDocumentDataEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the IdDocumentData */
  node: IdDocumentData;
};

export type IdDocumentDataFilter = {
  and?: InputMaybe<Array<IdDocumentDataFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<IdDocumentDataFilter>>;
};

export type IdDocumentDataInformation = {
  backFile?: Maybe<File>;
  backFileId?: Maybe<Scalars['String']['output']>;
  frontFile?: Maybe<File>;
  frontFileId?: Maybe<Scalars['String']['output']>;
};

export type IdDocumentDataInput = {
  /** The upload ref generated by the POST /uploads endpoint for the back id document */
  backUploadRef?: InputMaybe<Scalars['String']['input']>;
  /** The upload ref generated by the POST /uploads endpoint for the front id document */
  frontUploadRef: Scalars['String']['input'];
};

export type IdDocumentDataMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdDocumentDataMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdDocumentDataSort = {
  direction: SortDirection;
  field: IdDocumentDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const IdDocumentDataSortFields = {
  createdAt: 'createdAt',
  id: 'id'
} as const;

export type IdDocumentDataSortFields = typeof IdDocumentDataSortFields[keyof typeof IdDocumentDataSortFields];
export type IdDocumentDataVendor = {
  name: VendorEnum;
};

export type IdVerificationCheck = CheckInterface & {
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data: IdVerificationData;
  dataHistories: IdVerificationCheckDataHistoriesConnection;
  id: Scalars['ID']['output'];
  individual?: Maybe<Individual>;
  individualId?: Maybe<Scalars['ID']['output']>;
  status: CheckStatusEnum;
  subtype?: Maybe<Scalars['String']['output']>;
  type: CheckTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type IdVerificationCheckDataHistoriesArgs = {
  filter?: IdVerificationDataFilter;
  paging?: CursorPaging;
  sorting?: Array<IdVerificationDataSort>;
};

export type IdVerificationCheckAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdVerificationCheckCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IdVerificationCheckDataHistoriesConnection = {
  /** Array of edges. */
  edges: Array<IdVerificationDataEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type IdVerificationCheckMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdVerificationCheckMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdVerificationData = {
  analysisResults: Array<AnalysisResult>;
  check: IdVerificationCheck;
  checkId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use analysisResults instead, see E-5020 - Remove legacy detailed results and migrations */
  detailedResults: IdVerificationDataDetailedResults;
  id: Scalars['ID']['output'];
  information: IdVerificationDataInformation;
  isLatest: Scalars['Boolean']['output'];
  result?: Maybe<CheckResultEnum>;
  review: Review;
  settings: IdVerificationDataSettings;
  updatedAt: Scalars['DateTime']['output'];
  vendor: IdVerificationDataVendor;
};

export type IdVerificationDataAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdVerificationDataCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type IdVerificationDataDetailedResults = {
  document: DetailedResultsDocument;
};

export type IdVerificationDataEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the IdVerificationData */
  node: IdVerificationData;
};

export type IdVerificationDataFilter = {
  and?: InputMaybe<Array<IdVerificationDataFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<IdVerificationDataFilter>>;
};

export type IdVerificationDataInformation = {
  backFile?: Maybe<File>;
  backFileId?: Maybe<Scalars['String']['output']>;
  frontFile?: Maybe<File>;
  frontFileId?: Maybe<Scalars['String']['output']>;
};

export type IdVerificationDataMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type IdVerificationDataMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Id Verification Data mode. */
export const IdVerificationDataModeEnum = {
  auto: 'auto',
  certified: 'certified',
  default: 'default',
  expert: 'expert',
  liveness: 'liveness'
} as const;

export type IdVerificationDataModeEnum = typeof IdVerificationDataModeEnum[keyof typeof IdVerificationDataModeEnum];
export type IdVerificationDataSettings = {
  mode: IdVerificationDataModeEnum;
  redirectUrl: Scalars['String']['output'];
  vendor: IdVerificationVendorEnum;
};

export type IdVerificationDataSort = {
  direction: SortDirection;
  field: IdVerificationDataSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const IdVerificationDataSortFields = {
  createdAt: 'createdAt',
  id: 'id'
} as const;

export type IdVerificationDataSortFields = typeof IdVerificationDataSortFields[keyof typeof IdVerificationDataSortFields];
export type IdVerificationDataVendor = {
  comment?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  name: VendorEnum;
  sdkToken?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  verificationCodes?: Maybe<Array<Scalars['Float']['output']>>;
  verificationRef?: Maybe<Scalars['String']['output']>;
  verificationUrl: Scalars['String']['output'];
};

/** Subset of VendorEnum for ID verification */
export const IdVerificationVendorEnum = {
  checkout: 'checkout',
  onfido: 'onfido',
  ubble: 'ubble',
  veriff: 'veriff'
} as const;

export type IdVerificationVendorEnum = typeof IdVerificationVendorEnum[keyof typeof IdVerificationVendorEnum];
export type Individual = {
  address: Address;
  bankingInformation: BankingInformation;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  birthCountry?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthPlace?: Maybe<Scalars['String']['output']>;
  case: Case;
  checks: Array<CheckInterface>;
  createdAt: Scalars['DateTime']['output'];
  customPropertyValues: Array<CustomPropertyValue>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isBeneficialOwner: Scalars['Boolean']['output'];
  isBusinessContact: Scalars['Boolean']['output'];
  isDelegator: Scalars['Boolean']['output'];
  isRelevant: Scalars['Boolean']['output'];
  isSignatory: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  maidenName?: Maybe<Scalars['String']['output']>;
  /** May contain multiple middle names */
  middleName?: Maybe<Scalars['String']['output']>;
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  /** E.164 https://en.wikipedia.org/wiki/E.164 */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  roles: Array<CaseRelationRoleEnum>;
  socialSecurityNumber?: Maybe<Scalars['String']['output']>;
  taxIdentificationNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  votingRightsPercentage?: Maybe<Scalars['Float']['output']>;
};


export type IndividualChecksArgs = {
  filter?: CheckFilter;
  sorting?: Array<CheckSort>;
};


export type IndividualCustomPropertyValuesArgs = {
  filter?: CustomPropertyValueFilter;
  sorting?: Array<CustomPropertyValueSort>;
};

export type IndividualAggregateGroupBy = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isRelevant?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IndividualBeneficialOwner = BaseBeneficialOwnerInterface & BaseRepresentativeInterface & IndividualRepresentativeInterface & {
  address: Address;
  birthCountry?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthPlace?: Maybe<Scalars['String']['output']>;
  entityType: CompanyEntityTypeEnum;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  /** May contain multiple middle names */
  middleName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  votingRightsPercentage?: Maybe<Scalars['Float']['output']>;
};

export type IndividualCountAggregate = {
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isRelevant?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type IndividualDataInput = {
  address?: InputMaybe<AddressInput>;
  bankingInformation?: InputMaybe<BankingInformationInput>;
  birthCountry?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  birthPlace?: InputMaybe<Scalars['String']['input']>;
  customProperties?: InputMaybe<Scalars['JSONObject']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isBeneficialOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isBusinessContact?: InputMaybe<Scalars['Boolean']['input']>;
  isDelegator?: InputMaybe<Scalars['Boolean']['input']>;
  isSignatory?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  maidenName?: InputMaybe<Scalars['String']['input']>;
  /** May contain multiple middle names */
  middleName?: InputMaybe<Scalars['String']['input']>;
  ownershipPercentage?: InputMaybe<Scalars['Float']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  /** Relation from the main company (source) to this individual (target) */
  relations?: InputMaybe<Array<CaseRelationDataInput>>;
  roles?: InputMaybe<Array<CaseRelationRoleEnum>>;
  socialSecurityNumber?: InputMaybe<Scalars['String']['input']>;
  taxIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
  votingRightsPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type IndividualFilter = {
  and?: InputMaybe<Array<IndividualFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isRelevant?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<IndividualFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type IndividualMaxAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IndividualMinAggregate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type IndividualRepresentative = BaseRepresentativeInterface & IndividualRepresentativeInterface & {
  address: Address;
  birthCountry?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthPlace?: Maybe<Scalars['String']['output']>;
  entityType: CompanyEntityTypeEnum;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  /** May contain multiple middle names */
  middleName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  votingRightsPercentage?: Maybe<Scalars['Float']['output']>;
};

export type IndividualRepresentativeInterface = {
  address: Address;
  birthCountry?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthPlace?: Maybe<Scalars['String']['output']>;
  entityType: CompanyEntityTypeEnum;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  /** May contain multiple middle names */
  middleName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['String']['output']>;
  votingRightsPercentage?: Maybe<Scalars['Float']['output']>;
};

export type IndividualShareholder = BaseRepresentativeInterface & BaseShareholderInterface & IndividualRepresentativeInterface & {
  address: Address;
  birthCountry?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthPlace?: Maybe<Scalars['String']['output']>;
  entityType: CompanyEntityTypeEnum;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  /** May contain multiple middle names */
  middleName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  votingRightsPercentage?: Maybe<Scalars['Float']['output']>;
};

export type IndividualSort = {
  direction: SortDirection;
  field: IndividualSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export const IndividualSortFields = {
  createdAt: 'createdAt',
  id: 'id',
  isRelevant: 'isRelevant',
  updatedAt: 'updatedAt'
} as const;

export type IndividualSortFields = typeof IndividualSortFields[keyof typeof IndividualSortFields];
export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

/** Language ISO-639-1 alpha-2. */
export const LanguageEnum = {
  aa: 'aa',
  af: 'af',
  ak: 'ak',
  am: 'am',
  ar: 'ar',
  as: 'as',
  az: 'az',
  ba: 'ba',
  be: 'be',
  bg: 'bg',
  bh: 'bh',
  bi: 'bi',
  bm: 'bm',
  bn: 'bn',
  bo: 'bo',
  bs: 'bs',
  ca: 'ca',
  ch: 'ch',
  cs: 'cs',
  cy: 'cy',
  da: 'da',
  de: 'de',
  dv: 'dv',
  dz: 'dz',
  ee: 'ee',
  el: 'el',
  en: 'en',
  es: 'es',
  et: 'et',
  fa: 'fa',
  fi: 'fi',
  fj: 'fj',
  fo: 'fo',
  fr: 'fr',
  fy: 'fy',
  ga: 'ga',
  gd: 'gd',
  gl: 'gl',
  gn: 'gn',
  gu: 'gu',
  he: 'he',
  hi: 'hi',
  ho: 'ho',
  hr: 'hr',
  ht: 'ht',
  hu: 'hu',
  hy: 'hy',
  hz: 'hz',
  id: 'id',
  ig: 'ig',
  is: 'is',
  it: 'it',
  ja: 'ja',
  jv: 'jv',
  ka: 'ka',
  kg: 'kg',
  ki: 'ki',
  kj: 'kj',
  kk: 'kk',
  kl: 'kl',
  km: 'km',
  kn: 'kn',
  ko: 'ko',
  kr: 'kr',
  ks: 'ks',
  ky: 'ky',
  lb: 'lb',
  lg: 'lg',
  ln: 'ln',
  lo: 'lo',
  lt: 'lt',
  lu: 'lu',
  lv: 'lv',
  mg: 'mg',
  mh: 'mh',
  mi: 'mi',
  mk: 'mk',
  ml: 'ml',
  mn: 'mn',
  mr: 'mr',
  ms: 'ms',
  mt: 'mt',
  my: 'my',
  na: 'na',
  nb: 'nb',
  nd: 'nd',
  ne: 'ne',
  ng: 'ng',
  nl: 'nl',
  nn: 'nn',
  no: 'no',
  nr: 'nr',
  ny: 'ny',
  oc: 'oc',
  oj: 'oj',
  om: 'om',
  or: 'or',
  pa: 'pa',
  pl: 'pl',
  pt: 'pt',
  qu: 'qu',
  rm: 'rm',
  rn: 'rn',
  ro: 'ro',
  ru: 'ru',
  rw: 'rw',
  sa: 'sa',
  sd: 'sd',
  sg: 'sg',
  si: 'si',
  sk: 'sk',
  sl: 'sl',
  sm: 'sm',
  sn: 'sn',
  so: 'so',
  sq: 'sq',
  sr: 'sr',
  ss: 'ss',
  st: 'st',
  su: 'su',
  sv: 'sv',
  sw: 'sw',
  ta: 'ta',
  te: 'te',
  tg: 'tg',
  th: 'th',
  ti: 'ti',
  tk: 'tk',
  tl: 'tl',
  tn: 'tn',
  to: 'to',
  tr: 'tr',
  ts: 'ts',
  tw: 'tw',
  ty: 'ty',
  uk: 'uk',
  ur: 'ur',
  uz: 'uz',
  ve: 've',
  vi: 'vi',
  wa: 'wa',
  wo: 'wo',
  xh: 'xh',
  yo: 'yo',
  za: 'za',
  zh: 'zh',
  zu: 'zu'
} as const;

export type LanguageEnum = typeof LanguageEnum[keyof typeof LanguageEnum];
export type MergedCompany = {
  address: Address;
  /** [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country code (eg `FR`) */
  country?: Maybe<Scalars['String']['output']>;
  /** [ISO 20275](https://www.gleif.org/en/about-lei/iso-20275-entity-legal-forms-code-list) code (eg: `6CHY`) */
  entityLegalForm?: Maybe<Scalars['String']['output']>;
  legalForm?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  registrationNumber?: Maybe<Scalars['String']['output']>;
  relations: Array<MergedCompanyRelation>;
  type: CompanyTypeEnum;
};

export type MergedCompanyRelation = {
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<CaseRelationRoleEnum>>;
};

export type MergedIndividual = {
  address: Address;
  birthCountry?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthPlace?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  isBeneficialOwner: Scalars['Boolean']['output'];
  isBusinessContact: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  /** May contain multiple middle names */
  middleName?: Maybe<Scalars['String']['output']>;
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  relations: Array<MergedIndividualRelation>;
  roles: Array<CaseRelationRoleEnum>;
  votingRightsPercentage?: Maybe<Scalars['Float']['output']>;
};

export type MergedIndividualRelation = {
  ownershipPercentage?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<CaseRelationRoleEnum>>;
  votingRightsPercentage?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
  addFilesDocumentCheck: DocumentCheck;
  addFilesIdDocumentCheck: IdDocumentCheck;
  autoSaveForLater: SaveForLaterResult;
  completeForms: ClientPortalContactAccessToken;
  completeFormsPublic: ClientPortalContactAccessToken;
  forceReviewDocumentCheck: DocumentCheck;
  refreshUrlIdentityVerificationCheck: IdVerificationCheck;
  restartIdentityVerificationCheck: IdVerificationCheck;
  /** Save a business contact (existing or new individual) */
  saveBusinessContact: AddBusinessContactResponse;
  saveForLater: SaveForLaterResult;
  saveForLaterPublic: SaveForLaterResult;
  /** Send an email to invite to collaborate with an authenticated link to the current client portal to an individual that is a relevant business contact of the current case */
  sendCollaborationEmail: SendCollaborationEmailResponse;
  /** Generate an authenticated link to the current client portal for the specified individual that is a relevant business contact of the current case */
  shareClientPortalLink: ShareClientPortalLinkResponse;
  /** Try to complete the checks of the case if the contact doesn't have any actions remaining */
  tryCompleteChecks: Case;
  updateIdDocumentReferenceData: IdDocumentCheck;
  updateIdVerificationReferenceData: IdVerificationCheck;
};


export type MutationAddFilesDocumentCheckArgs = {
  input: AddFilesDocumentCheckInput;
};


export type MutationAddFilesIdDocumentCheckArgs = {
  input: AddFilesIdDocumentCheckInput;
};


export type MutationAutoSaveForLaterArgs = {
  input: CaseDataInput;
};


export type MutationCompleteFormsArgs = {
  input: CaseDataInput;
};


export type MutationCompleteFormsPublicArgs = {
  input: CaseDataInput;
};


export type MutationForceReviewDocumentCheckArgs = {
  input: ForceReviewDocumentCheckInput;
};


export type MutationRefreshUrlIdentityVerificationCheckArgs = {
  input: RefreshUrlIdentityVerificationCheckInput;
};


export type MutationRestartIdentityVerificationCheckArgs = {
  input: RestartIdentityVerificationCheckInput;
};


export type MutationSaveBusinessContactArgs = {
  input: AddBusinessContactInput;
};


export type MutationSaveForLaterArgs = {
  input: CaseDataInput;
};


export type MutationSaveForLaterPublicArgs = {
  input: CaseDataInput;
};


export type MutationSendCollaborationEmailArgs = {
  input: SendCollaborationEmailInput;
};


export type MutationShareClientPortalLinkArgs = {
  input: ShareClientPortalLinkInput;
};


export type MutationUpdateIdDocumentReferenceDataArgs = {
  input: UpdateIdDocumentReferenceDataInput;
};


export type MutationUpdateIdVerificationReferenceDataArgs = {
  input: UpdateIdVerificationReferenceDataInput;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type OffsetPageInfo = {
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type PageInfo = {
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

/** PropertyMapping entity enum (case/individual/company) */
export const PropertyMappingEntityEnum = {
  case: 'case',
  company: 'company',
  individual: 'individual'
} as const;

export type PropertyMappingEntityEnum = typeof PropertyMappingEntityEnum[keyof typeof PropertyMappingEntityEnum];
/** Property type enum */
export const PropertyTypeEnum = {
  address: 'address',
  banking_information: 'banking_information',
  boolean: 'boolean',
  choices: 'choices',
  classifications: 'classifications',
  countries: 'countries',
  date: 'date',
  email: 'email',
  entity_legal_form: 'entity_legal_form',
  numeric: 'numeric',
  phone_number: 'phone_number',
  text: 'text',
  unknown: 'unknown',
  url: 'url'
} as const;

export type PropertyTypeEnum = typeof PropertyTypeEnum[keyof typeof PropertyTypeEnum];
export type Query = {
  case?: Maybe<Case>;
  companyCountries: Array<CompanyCountry>;
  companyFetch: CompanyData;
  companySearch: CompanySearchList;
  idDocumentCheck?: Maybe<IdDocumentCheck>;
  idVerificationCheck?: Maybe<IdVerificationCheck>;
  latestClientPortalVersion: ClientPortalVersion;
  me: Contact;
};


export type QueryCaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyFetchArgs = {
  input: CompanyFetchInput;
};


export type QueryCompanySearchArgs = {
  input: CompanySearchInput;
};


export type QueryIdDocumentCheckArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIdVerificationCheckArgs = {
  id: Scalars['ID']['input'];
};

export type RefreshUrlIdentityVerificationCheckInput = {
  /** Id of the check */
  checkId: Scalars['ID']['input'];
};

export type RepresentativeUnion = CompanyRepresentative | IndividualRepresentative;

export type RestartIdentityVerificationCheckInput = {
  /** Id of the check */
  checkId: Scalars['ID']['input'];
};

export type Review = {
  comment?: Maybe<Scalars['String']['output']>;
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SaveForLaterResult = {
  auth: ClientPortalContactAccessToken;
  case: Case;
};

export type SendCollaborationEmailInput = {
  /** Optional additional custom message to add in the email */
  customMessage?: InputMaybe<Scalars['String']['input']>;
  individualId: Scalars['String']['input'];
};

export type SendCollaborationEmailResponse = {
  sent: Scalars['Boolean']['output'];
};

export type ShareClientPortalLinkInput = {
  individualId: Scalars['String']['input'];
};

export type ShareClientPortalLinkResponse = {
  expiresAt: Scalars['DateTime']['output'];
  link: Scalars['String']['output'];
};

export type ShareholderUnion = CompanyShareholder | IndividualShareholder;

/** Sort Directions */
export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
/** Sort Nulls Options */
export const SortNulls = {
  NULLS_FIRST: 'NULLS_FIRST',
  NULLS_LAST: 'NULLS_LAST'
} as const;

export type SortNulls = typeof SortNulls[keyof typeof SortNulls];
export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIdDocumentReferenceDataInput = {
  /** Optional birth date of the individual related to this check */
  birthDate?: InputMaybe<Scalars['String']['input']>;
  /** First name of the individual related to this check */
  firstName: Scalars['String']['input'];
  /** Id of the check */
  id: Scalars['ID']['input'];
  /** Last name of the individual related to this check */
  lastName: Scalars['String']['input'];
};

export type UpdateIdVerificationReferenceDataInput = {
  /** Optional birth date of the individual related to this check */
  birthDate?: InputMaybe<Scalars['String']['input']>;
  /** First name of the individual related to this check */
  firstName: Scalars['String']['input'];
  /** Id of the check */
  id: Scalars['ID']['input'];
  /** Last name of the individual related to this check */
  lastName: Scalars['String']['input'];
};

/** Vendor. */
export const VendorEnum = {
  checkout: 'checkout',
  companies_house: 'companies_house',
  comply_advantage: 'comply_advantage',
  creditsafe: 'creditsafe',
  idnow: 'idnow',
  inpi: 'inpi',
  kompany: 'kompany',
  kyckr: 'kyckr',
  mistral_ai: 'mistral_ai',
  onfido: 'onfido',
  open_ai: 'open_ai',
  pappers: 'pappers',
  postmark: 'postmark',
  ubble: 'ubble',
  veriff: 'veriff'
} as const;

export type VendorEnum = typeof VendorEnum[keyof typeof VendorEnum];
export type Workspace = {
  id: Scalars['ID']['output'];
  logo?: Maybe<File>;
  logoId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
};

export type WorkspaceAggregateGroupBy = {
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkspaceCountAggregate = {
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
};

export type WorkspaceEdge = {
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Workspace */
  node: Workspace;
};

export type WorkspaceMaxAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkspaceMinAggregate = {
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SaveBusinessContactMutationVariables = Exact<{
  input: AddBusinessContactInput;
}>;


export type SaveBusinessContactMutation = { saveBusinessContact: Pick<AddBusinessContactResponse, 'id'> };

export type SendCollaborationEmailMutationVariables = Exact<{
  input: SendCollaborationEmailInput;
}>;


export type SendCollaborationEmailMutation = { sendCollaborationEmail: Pick<SendCollaborationEmailResponse, 'sent'> };

export type ShareClientPortalLinkMutationVariables = Exact<{
  input: ShareClientPortalLinkInput;
}>;


export type ShareClientPortalLinkMutation = { shareClientPortalLink: Pick<ShareClientPortalLinkResponse, 'link' | 'expiresAt'> };

export type AddFilesDocumentCheckMutationVariables = Exact<{
  input: AddFilesDocumentCheckInput;
}>;


export type AddFilesDocumentCheckMutation = { addFilesDocumentCheck: (
    Pick<DocumentCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
      & { settings: (
        Pick<DocumentDataSettings, 'documentType'>
        & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
      ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
    ), individual?: Maybe<(
      Pick<Individual, 'id'>
      & { case: Pick<Case, 'id' | 'contactHasActions'> }
    )>, company?: Maybe<(
      Pick<Company, 'id'>
      & { case: Pick<Case, 'id' | 'contactHasActions'> }
    )> }
  ) };

export type ForceReviewDocumentCheckMutationVariables = Exact<{
  input: ForceReviewDocumentCheckInput;
}>;


export type ForceReviewDocumentCheckMutation = { forceReviewDocumentCheck: (
    Pick<DocumentCheck, 'id' | 'status'>
    & { individual?: Maybe<(
      Pick<Individual, 'id'>
      & { case: Pick<Case, 'id' | 'contactHasActions'> }
    )>, company?: Maybe<(
      Pick<Company, 'id'>
      & { case: Pick<Case, 'id' | 'contactHasActions'> }
    )> }
  ) };

export type ClientPortalChecks_DocumentData = (
  Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
  & { settings: (
    Pick<DocumentDataSettings, 'documentType'>
    & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
  ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
);

export type ClientPortalChecks_DocumentCheck = (
  Pick<DocumentCheck, 'id' | 'type' | 'status'>
  & { data: (
    Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
    & { settings: (
      Pick<DocumentDataSettings, 'documentType'>
      & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
    ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
  ) }
);

export type AddFilesIdDocumentCheckMutationVariables = Exact<{
  input: AddFilesIdDocumentCheckInput;
}>;


export type AddFilesIdDocumentCheckMutation = { addFilesIdDocumentCheck: (
    Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
      & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
    ) }
  ) };

export type ClientPortalChecks_IdDocumentData = (
  Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
  & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
);

export type ClientPortalChecks_IdDocumentCheck = (
  Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
  & { data: (
    Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
    & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
  ) }
);

export type IdDocumentCheckQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IdDocumentCheckQuery = { idDocumentCheck?: Maybe<(
    Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
      & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
    ) }
  )> };

export type UpdateIdDocumentReferenceDataMutationVariables = Exact<{
  input: UpdateIdDocumentReferenceDataInput;
}>;


export type UpdateIdDocumentReferenceDataMutation = { updateIdDocumentReferenceData: (
    Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
    & { individual?: Maybe<Pick<Individual, 'id' | 'firstName' | 'lastName' | 'birthDate'>>, data: (
      Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
      & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
    ) }
  ) };

export type ClientPortalChecks_IdVerificationData = (
  Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
  & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
);

export type ClientPortalChecks_IdVerificationCheck = (
  Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
  & { data: (
    Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
    & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
  ) }
);

export type IdVerificationCheckQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IdVerificationCheckQuery = { idVerificationCheck?: Maybe<(
    Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
      & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
    ), individual?: Maybe<(
      Pick<Individual, 'id'>
      & { case: Pick<Case, 'id' | 'contactHasActions'> }
    )>, company?: Maybe<(
      Pick<Company, 'id'>
      & { case: Pick<Case, 'id' | 'contactHasActions'> }
    )> }
  )> };

export type RefreshUrlIdentityVerificationCheckMutationVariables = Exact<{
  input: RefreshUrlIdentityVerificationCheckInput;
}>;


export type RefreshUrlIdentityVerificationCheckMutation = { refreshUrlIdentityVerificationCheck: (
    Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
      & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
    ) }
  ) };

export type RestartIdentityVerificationCheckMutationVariables = Exact<{
  input: RestartIdentityVerificationCheckInput;
}>;


export type RestartIdentityVerificationCheckMutation = { restartIdentityVerificationCheck: (
    Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
      & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
    ) }
  ) };

export type UpdateIdVerificationReferenceDataMutationVariables = Exact<{
  input: UpdateIdVerificationReferenceDataInput;
}>;


export type UpdateIdVerificationReferenceDataMutation = { updateIdVerificationReferenceData: (
    Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
    & { individual?: Maybe<Pick<Individual, 'id' | 'firstName' | 'lastName' | 'birthDate'>>, data: (
      Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
      & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
    ) }
  ) };

export type CaseChecksQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CaseChecksQuery = { case?: Maybe<(
    Pick<Case, 'id' | 'contactHasActions'>
    & { individuals: Array<(
      Pick<Individual, 'id' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'birthDate' | 'roles' | 'isBeneficialOwner' | 'isBusinessContact' | 'isDelegator' | 'email'>
      & { checks: Array<(
        Pick<DocumentCheck, 'id' | 'type' | 'status'>
        & { data: (
          Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
          & { settings: (
            Pick<DocumentDataSettings, 'documentType'>
            & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
          ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
        ) }
      ) | (
        Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
        & { data: (
          Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
          & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
        ) }
      ) | (
        Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
        & { data: (
          Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
          & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
        ) }
      )> }
    )>, companies: Array<(
      Pick<Company, 'id' | 'name' | 'type'>
      & { checks: Array<(
        Pick<DocumentCheck, 'id' | 'type' | 'status'>
        & { data: (
          Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
          & { settings: (
            Pick<DocumentDataSettings, 'documentType'>
            & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
          ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
        ) }
      ) | (
        Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
        & { data: (
          Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
          & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
        ) }
      ) | (
        Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
        & { data: (
          Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
          & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
        ) }
      )> }
    )> }
  )> };

export type ClientPortalChecks_Case = (
  Pick<Case, 'id' | 'contactHasActions'>
  & { individuals: Array<(
    Pick<Individual, 'id' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'birthDate' | 'roles' | 'isBeneficialOwner' | 'isBusinessContact' | 'isDelegator' | 'email'>
    & { checks: Array<(
      Pick<DocumentCheck, 'id' | 'type' | 'status'>
      & { data: (
        Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
        & { settings: (
          Pick<DocumentDataSettings, 'documentType'>
          & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
        ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
      ) }
    ) | (
      Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
      & { data: (
        Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
        & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
      ) }
    ) | (
      Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
      & { data: (
        Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
        & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
      ) }
    )> }
  )>, companies: Array<(
    Pick<Company, 'id' | 'name' | 'type'>
    & { checks: Array<(
      Pick<DocumentCheck, 'id' | 'type' | 'status'>
      & { data: (
        Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
        & { settings: (
          Pick<DocumentDataSettings, 'documentType'>
          & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
        ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
      ) }
    ) | (
      Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
      & { data: (
        Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
        & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
      ) }
    ) | (
      Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
      & { data: (
        Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
        & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
      ) }
    )> }
  )> }
);

export type ClientPortalChecks_Individual = (
  Pick<Individual, 'id' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'birthDate' | 'roles' | 'isBeneficialOwner' | 'isBusinessContact' | 'isDelegator' | 'email'>
  & { checks: Array<(
    Pick<DocumentCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
      & { settings: (
        Pick<DocumentDataSettings, 'documentType'>
        & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
      ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
    ) }
  ) | (
    Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
      & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
    ) }
  ) | (
    Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
      & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
    ) }
  )> }
);

export type ClientPortalChecks_Company = (
  Pick<Company, 'id' | 'name' | 'type'>
  & { checks: Array<(
    Pick<DocumentCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
      & { settings: (
        Pick<DocumentDataSettings, 'documentType'>
        & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
      ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
    ) }
  ) | (
    Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
      & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
    ) }
  ) | (
    Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
    & { data: (
      Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
      & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
    ) }
  )> }
);

type ClientPortalChecks_CheckInterface_DocumentCheck = (
  Pick<DocumentCheck, 'id' | 'type' | 'status'>
  & { data: (
    Pick<DocumentData, 'id' | 'isLatest' | 'result' | 'updatedAt'>
    & { settings: (
      Pick<DocumentDataSettings, 'documentType'>
      & { customDocumentType?: Maybe<Pick<CustomDocumentType, 'id' | 'label' | 'key'>>, documentAnalysis?: Maybe<Pick<DocumentAnalysisSettings, 'parameters'>> }
    ), information: Pick<DocumentDataInformation, 'documentAnalysis'>, detailedResults: Pick<DocumentDataDetailedResults, 'documentAnalysis'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>>, review: Pick<Review, 'comment' | 'reviewedAt'> }
  ) }
);

type ClientPortalChecks_CheckInterface_IdDocumentCheck = (
  Pick<IdDocumentCheck, 'id' | 'type' | 'status'>
  & { data: (
    Pick<IdDocumentData, 'id' | 'isLatest' | 'result'>
    & { review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> }, vendor: Pick<IdDocumentDataVendor, 'name'> }
  ) }
);

type ClientPortalChecks_CheckInterface_IdVerificationCheck = (
  Pick<IdVerificationCheck, 'id' | 'type' | 'status'>
  & { data: (
    Pick<IdVerificationData, 'id' | 'isLatest' | 'result'>
    & { settings: Pick<IdVerificationDataSettings, 'mode' | 'redirectUrl'>, vendor: Pick<IdVerificationDataVendor, 'name' | 'verificationRef' | 'verificationUrl' | 'sdkToken' | 'verificationCodes' | 'startedAt' | 'endedAt'>, review: Pick<Review, 'comment' | 'reviewedAt'>, analysisResults: Array<Pick<AnalysisResult, 'key' | 'description' | 'category' | 'result' | 'expected' | 'received' | 'vendor'>>, detailedResults: { document: Pick<DetailedResultsDocument, 'dataComparison'> }, information: { frontFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>>, backFile?: Maybe<Pick<File, 'id' | 'name' | 'mimeType' | 'target'>> } }
  ) }
);

export type ClientPortalChecks_CheckInterface = ClientPortalChecks_CheckInterface_DocumentCheck | ClientPortalChecks_CheckInterface_IdDocumentCheck | ClientPortalChecks_CheckInterface_IdVerificationCheck;

export type ClientPortalChecks_CheckDataReview = Pick<Review, 'comment' | 'reviewedAt'>;

type ClientPortalChecks_Check_DocumentCheck = Pick<DocumentCheck, 'id' | 'type' | 'status'>;

type ClientPortalChecks_Check_IdDocumentCheck = Pick<IdDocumentCheck, 'id' | 'type' | 'status'>;

type ClientPortalChecks_Check_IdVerificationCheck = Pick<IdVerificationCheck, 'id' | 'type' | 'status'>;

export type ClientPortalChecks_Check = ClientPortalChecks_Check_DocumentCheck | ClientPortalChecks_Check_IdDocumentCheck | ClientPortalChecks_Check_IdVerificationCheck;

type ClientPortalChecks_ContactHasActionsRefresh_DocumentCheck = (
  Pick<DocumentCheck, 'id'>
  & { individual?: Maybe<(
    Pick<Individual, 'id'>
    & { case: Pick<Case, 'id' | 'contactHasActions'> }
  )>, company?: Maybe<(
    Pick<Company, 'id'>
    & { case: Pick<Case, 'id' | 'contactHasActions'> }
  )> }
);

type ClientPortalChecks_ContactHasActionsRefresh_IdDocumentCheck = (
  Pick<IdDocumentCheck, 'id'>
  & { individual?: Maybe<(
    Pick<Individual, 'id'>
    & { case: Pick<Case, 'id' | 'contactHasActions'> }
  )>, company?: Maybe<(
    Pick<Company, 'id'>
    & { case: Pick<Case, 'id' | 'contactHasActions'> }
  )> }
);

type ClientPortalChecks_ContactHasActionsRefresh_IdVerificationCheck = (
  Pick<IdVerificationCheck, 'id'>
  & { individual?: Maybe<(
    Pick<Individual, 'id'>
    & { case: Pick<Case, 'id' | 'contactHasActions'> }
  )>, company?: Maybe<(
    Pick<Company, 'id'>
    & { case: Pick<Case, 'id' | 'contactHasActions'> }
  )> }
);

export type ClientPortalChecks_ContactHasActionsRefresh = ClientPortalChecks_ContactHasActionsRefresh_DocumentCheck | ClientPortalChecks_ContactHasActionsRefresh_IdDocumentCheck | ClientPortalChecks_ContactHasActionsRefresh_IdVerificationCheck;

export type ClientPortalChecks_AnalysisResult = Pick<AnalysisResult, 'key' | 'description' | 'category' | 'vendor' | 'result' | 'expected' | 'received' | 'translation'>;

export type TryCompleteChecksMutationVariables = Exact<{ [key: string]: never; }>;


export type TryCompleteChecksMutation = { tryCompleteChecks: Pick<Case, 'id'> };

export type CompanyFetchQueryVariables = Exact<{
  input: CompanyFetchInput;
}>;


export type CompanyFetchQuery = { companyFetch: (
    Pick<CompanyData, 'name' | 'commercialName' | 'registrationNumber' | 'registrationDate' | 'legalForm' | 'entityLegalForm' | 'status' | 'country' | 'shareCapital' | 'taxIdentificationNumber'>
    & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, classifications: Array<Pick<CompanyClassification, 'code' | 'description' | 'type'>>, mergedIndividuals: Array<(
      Pick<MergedIndividual, 'firstName' | 'lastName' | 'middleName' | 'maidenName' | 'birthDate' | 'birthPlace' | 'birthCountry' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage' | 'roles' | 'isBeneficialOwner' | 'isBusinessContact'>
      & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, relations: Array<Pick<MergedIndividualRelation, 'position' | 'roles' | 'ownershipPercentage' | 'votingRightsPercentage'>> }
    )>, mergedCompanies: Array<(
      Pick<MergedCompany, 'name' | 'registrationNumber' | 'legalForm' | 'entityLegalForm' | 'country'>
      & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, relations: Array<Pick<MergedCompanyRelation, 'position' | 'roles' | 'ownershipPercentage'>> }
    )> }
  ) };

export type CompanySearchQueryVariables = Exact<{
  input: CompanySearchInput;
}>;


export type CompanySearchQuery = { companySearch: { data: Array<(
      Pick<CompanySearch, 'searchRef' | 'name' | 'country' | 'registrationNumber' | 'vendor' | 'vendorId'>
      & { address: Pick<Address, 'postalCode' | 'city'> }
    )> } };

export type CompanySearch_CompanySearchFragment = (
  Pick<CompanySearch, 'searchRef' | 'name' | 'country' | 'registrationNumber' | 'vendor' | 'vendorId'>
  & { address: Pick<Address, 'postalCode' | 'city'> }
);

export type CompanyData_CompanyFetchFragment = (
  Pick<CompanyData, 'name' | 'commercialName' | 'registrationNumber' | 'registrationDate' | 'legalForm' | 'entityLegalForm' | 'status' | 'country' | 'shareCapital' | 'taxIdentificationNumber'>
  & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, classifications: Array<Pick<CompanyClassification, 'code' | 'description' | 'type'>>, mergedIndividuals: Array<(
    Pick<MergedIndividual, 'firstName' | 'lastName' | 'middleName' | 'maidenName' | 'birthDate' | 'birthPlace' | 'birthCountry' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage' | 'roles' | 'isBeneficialOwner' | 'isBusinessContact'>
    & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, relations: Array<Pick<MergedIndividualRelation, 'position' | 'roles' | 'ownershipPercentage' | 'votingRightsPercentage'>> }
  )>, mergedCompanies: Array<(
    Pick<MergedCompany, 'name' | 'registrationNumber' | 'legalForm' | 'entityLegalForm' | 'country'>
    & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, relations: Array<Pick<MergedCompanyRelation, 'position' | 'roles' | 'ownershipPercentage'>> }
  )> }
);

export type CompanyData_CompanyFetchMergedIndividualFragment = (
  Pick<MergedIndividual, 'firstName' | 'lastName' | 'middleName' | 'maidenName' | 'birthDate' | 'birthPlace' | 'birthCountry' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage' | 'roles' | 'isBeneficialOwner' | 'isBusinessContact'>
  & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, relations: Array<Pick<MergedIndividualRelation, 'position' | 'roles' | 'ownershipPercentage' | 'votingRightsPercentage'>> }
);

export type CompanyData_CompanyFetchMergedCompanyFragment = (
  Pick<MergedCompany, 'name' | 'registrationNumber' | 'legalForm' | 'entityLegalForm' | 'country'>
  & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, relations: Array<Pick<MergedCompanyRelation, 'position' | 'roles' | 'ownershipPercentage'>> }
);

export type SaveForLaterPublicMutationVariables = Exact<{
  input: CaseDataInput;
}>;


export type SaveForLaterPublicMutation = { saveForLaterPublic: { auth: Pick<ClientPortalContactAccessToken, 'caseId' | 'clientPortalId' | 'contactId' | 'accessToken' | 'expiresAt' | 'refreshExpiresAt'>, case: (
      Pick<Case, 'id' | 'externalId' | 'metadata'>
      & { customPropertyValues: Array<(
        Pick<CustomPropertyValue, 'id' | 'value'>
        & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
      )>, individuals: Array<(
        Pick<Individual, 'id' | 'roles' | 'isBusinessContact' | 'isBeneficialOwner' | 'isDelegator' | 'isSignatory' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'email' | 'birthDate' | 'birthCountry' | 'birthPlace' | 'taxIdentificationNumber' | 'phoneNumber' | 'socialSecurityNumber' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage'>
        & { bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, customPropertyValues: Array<(
          Pick<CustomPropertyValue, 'id' | 'value'>
          & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
        )> }
      )>, companies: Array<(
        Pick<Company, 'id' | 'type' | 'name' | 'commercialName' | 'registrationNumber' | 'registrationDate' | 'status' | 'legalForm' | 'entityLegalForm' | 'country' | 'shareCapital' | 'taxIdentificationNumber' | 'websiteUrl' | 'employerIdentificationNumber'>
        & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, classifications?: Maybe<Array<Pick<CompanyClassification, 'code' | 'description' | 'type'>>>, bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, customPropertyValues: Array<(
          Pick<CustomPropertyValue, 'id' | 'value'>
          & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
        )> }
      )> }
    ) } };

export type SaveForLaterMutationVariables = Exact<{
  input: CaseDataInput;
}>;


export type SaveForLaterMutation = { saveForLater: { auth: Pick<ClientPortalContactAccessToken, 'caseId' | 'clientPortalId' | 'contactId' | 'accessToken' | 'expiresAt' | 'refreshExpiresAt'>, case: (
      Pick<Case, 'id' | 'externalId' | 'metadata'>
      & { customPropertyValues: Array<(
        Pick<CustomPropertyValue, 'id' | 'value'>
        & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
      )>, individuals: Array<(
        Pick<Individual, 'id' | 'roles' | 'isBusinessContact' | 'isBeneficialOwner' | 'isDelegator' | 'isSignatory' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'email' | 'birthDate' | 'birthCountry' | 'birthPlace' | 'taxIdentificationNumber' | 'phoneNumber' | 'socialSecurityNumber' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage'>
        & { bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, customPropertyValues: Array<(
          Pick<CustomPropertyValue, 'id' | 'value'>
          & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
        )> }
      )>, companies: Array<(
        Pick<Company, 'id' | 'type' | 'name' | 'commercialName' | 'registrationNumber' | 'registrationDate' | 'status' | 'legalForm' | 'entityLegalForm' | 'country' | 'shareCapital' | 'taxIdentificationNumber' | 'websiteUrl' | 'employerIdentificationNumber'>
        & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, classifications?: Maybe<Array<Pick<CompanyClassification, 'code' | 'description' | 'type'>>>, bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, customPropertyValues: Array<(
          Pick<CustomPropertyValue, 'id' | 'value'>
          & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
        )> }
      )> }
    ) } };

export type AutoSaveForLaterMutationVariables = Exact<{
  input: CaseDataInput;
}>;


export type AutoSaveForLaterMutation = { autoSaveForLater: { auth: Pick<ClientPortalContactAccessToken, 'caseId' | 'clientPortalId' | 'contactId' | 'accessToken' | 'expiresAt' | 'refreshExpiresAt'>, case: (
      Pick<Case, 'id' | 'externalId' | 'metadata'>
      & { customPropertyValues: Array<(
        Pick<CustomPropertyValue, 'id' | 'value'>
        & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
      )>, individuals: Array<(
        Pick<Individual, 'id' | 'roles' | 'isBusinessContact' | 'isBeneficialOwner' | 'isDelegator' | 'isSignatory' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'email' | 'birthDate' | 'birthCountry' | 'birthPlace' | 'taxIdentificationNumber' | 'phoneNumber' | 'socialSecurityNumber' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage'>
        & { bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, customPropertyValues: Array<(
          Pick<CustomPropertyValue, 'id' | 'value'>
          & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
        )> }
      )>, companies: Array<(
        Pick<Company, 'id' | 'type' | 'name' | 'commercialName' | 'registrationNumber' | 'registrationDate' | 'status' | 'legalForm' | 'entityLegalForm' | 'country' | 'shareCapital' | 'taxIdentificationNumber' | 'websiteUrl' | 'employerIdentificationNumber'>
        & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, classifications?: Maybe<Array<Pick<CompanyClassification, 'code' | 'description' | 'type'>>>, bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, customPropertyValues: Array<(
          Pick<CustomPropertyValue, 'id' | 'value'>
          & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
        )> }
      )> }
    ) } };

export type CaseFormsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CaseFormsQuery = { case?: Maybe<(
    Pick<Case, 'id' | 'externalId' | 'metadata'>
    & { customPropertyValues: Array<(
      Pick<CustomPropertyValue, 'id' | 'value'>
      & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
    )>, individuals: Array<(
      Pick<Individual, 'id' | 'roles' | 'isBusinessContact' | 'isBeneficialOwner' | 'isDelegator' | 'isSignatory' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'email' | 'birthDate' | 'birthCountry' | 'birthPlace' | 'taxIdentificationNumber' | 'phoneNumber' | 'socialSecurityNumber' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage'>
      & { bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, customPropertyValues: Array<(
        Pick<CustomPropertyValue, 'id' | 'value'>
        & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
      )> }
    )>, companies: Array<(
      Pick<Company, 'id' | 'type' | 'name' | 'commercialName' | 'registrationNumber' | 'registrationDate' | 'status' | 'legalForm' | 'entityLegalForm' | 'country' | 'shareCapital' | 'taxIdentificationNumber' | 'websiteUrl' | 'employerIdentificationNumber'>
      & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, classifications?: Maybe<Array<Pick<CompanyClassification, 'code' | 'description' | 'type'>>>, bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, customPropertyValues: Array<(
        Pick<CustomPropertyValue, 'id' | 'value'>
        & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
      )> }
    )> }
  )> };

export type ClientPortalForms_Case = (
  Pick<Case, 'id' | 'externalId' | 'metadata'>
  & { customPropertyValues: Array<(
    Pick<CustomPropertyValue, 'id' | 'value'>
    & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
  )>, individuals: Array<(
    Pick<Individual, 'id' | 'roles' | 'isBusinessContact' | 'isBeneficialOwner' | 'isDelegator' | 'isSignatory' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'email' | 'birthDate' | 'birthCountry' | 'birthPlace' | 'taxIdentificationNumber' | 'phoneNumber' | 'socialSecurityNumber' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage'>
    & { bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, customPropertyValues: Array<(
      Pick<CustomPropertyValue, 'id' | 'value'>
      & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
    )> }
  )>, companies: Array<(
    Pick<Company, 'id' | 'type' | 'name' | 'commercialName' | 'registrationNumber' | 'registrationDate' | 'status' | 'legalForm' | 'entityLegalForm' | 'country' | 'shareCapital' | 'taxIdentificationNumber' | 'websiteUrl' | 'employerIdentificationNumber'>
    & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, classifications?: Maybe<Array<Pick<CompanyClassification, 'code' | 'description' | 'type'>>>, bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, customPropertyValues: Array<(
      Pick<CustomPropertyValue, 'id' | 'value'>
      & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
    )> }
  )> }
);

export type ClientPortalForms_Individual = (
  Pick<Individual, 'id' | 'roles' | 'isBusinessContact' | 'isBeneficialOwner' | 'isDelegator' | 'isSignatory' | 'firstName' | 'middleName' | 'maidenName' | 'lastName' | 'email' | 'birthDate' | 'birthCountry' | 'birthPlace' | 'taxIdentificationNumber' | 'phoneNumber' | 'socialSecurityNumber' | 'position' | 'ownershipPercentage' | 'votingRightsPercentage'>
  & { bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, customPropertyValues: Array<(
    Pick<CustomPropertyValue, 'id' | 'value'>
    & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
  )> }
);

export type ClientPortalForms_Company = (
  Pick<Company, 'id' | 'type' | 'name' | 'commercialName' | 'registrationNumber' | 'registrationDate' | 'status' | 'legalForm' | 'entityLegalForm' | 'country' | 'shareCapital' | 'taxIdentificationNumber' | 'websiteUrl' | 'employerIdentificationNumber'>
  & { address: Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>, classifications?: Maybe<Array<Pick<CompanyClassification, 'code' | 'description' | 'type'>>>, bankingInformation: Pick<BankingInformation, 'iban' | 'bic'>, customPropertyValues: Array<(
    Pick<CustomPropertyValue, 'id' | 'value'>
    & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
  )> }
);

export type ClientPortalForms_CustomPropertyValue = (
  Pick<CustomPropertyValue, 'id' | 'value'>
  & { customProperty: Pick<CustomProperty, 'id' | 'type' | 'key'> }
);

export type CompleteFormsPublicMutationVariables = Exact<{
  input: CaseDataInput;
}>;


export type CompleteFormsPublicMutation = { completeFormsPublic: Pick<ClientPortalContactAccessToken, 'caseId' | 'clientPortalId' | 'contactId' | 'accessToken' | 'expiresAt' | 'refreshExpiresAt'> };

export type CompleteFormsMutationVariables = Exact<{
  input: CaseDataInput;
}>;


export type CompleteFormsMutation = { completeForms: Pick<ClientPortalContactAccessToken, 'caseId' | 'clientPortalId' | 'contactId' | 'accessToken' | 'expiresAt' | 'refreshExpiresAt'> };

export type LatestClientPortalVersionFormsQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestClientPortalVersionFormsQuery = { latestClientPortalVersion: (
    Pick<ClientPortalVersion, 'id'>
    & { steps: Array<(
      Pick<ClientPortalStep, 'id' | 'key' | 'type' | 'title' | 'description' | 'config'>
      & { logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>>, blocks: Array<(
        Pick<ClientPortalBlockField, 'id' | 'type' | 'key' | 'label' | 'description' | 'display' | 'isRequired'>
        & { mapping: Pick<ClientPortalBlockFieldMapping, 'key' | 'type' | 'entity'>, property?: Maybe<Pick<ClientPortalBlockFieldProperty, 'type' | 'settings'>>, options: Array<Pick<ClientPortalFieldOption, 'key' | 'label' | 'context'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
      ) | (
        Pick<ClientPortalBlockLayout, 'id' | 'type' | 'key' | 'label' | 'description' | 'widget'>
        & { file?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
      )> }
    )> }
  ) };

export type ClientPortalForms_Version = (
  Pick<ClientPortalVersion, 'id'>
  & { steps: Array<(
    Pick<ClientPortalStep, 'id' | 'key' | 'type' | 'title' | 'description' | 'config'>
    & { logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>>, blocks: Array<(
      Pick<ClientPortalBlockField, 'id' | 'type' | 'key' | 'label' | 'description' | 'display' | 'isRequired'>
      & { mapping: Pick<ClientPortalBlockFieldMapping, 'key' | 'type' | 'entity'>, property?: Maybe<Pick<ClientPortalBlockFieldProperty, 'type' | 'settings'>>, options: Array<Pick<ClientPortalFieldOption, 'key' | 'label' | 'context'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
    ) | (
      Pick<ClientPortalBlockLayout, 'id' | 'type' | 'key' | 'label' | 'description' | 'widget'>
      & { file?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
    )> }
  )> }
);

export type ClientPortalForms_Step = (
  Pick<ClientPortalStep, 'id' | 'key' | 'type' | 'title' | 'description' | 'config'>
  & { logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>>, blocks: Array<(
    Pick<ClientPortalBlockField, 'id' | 'type' | 'key' | 'label' | 'description' | 'display' | 'isRequired'>
    & { mapping: Pick<ClientPortalBlockFieldMapping, 'key' | 'type' | 'entity'>, property?: Maybe<Pick<ClientPortalBlockFieldProperty, 'type' | 'settings'>>, options: Array<Pick<ClientPortalFieldOption, 'key' | 'label' | 'context'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
  ) | (
    Pick<ClientPortalBlockLayout, 'id' | 'type' | 'key' | 'label' | 'description' | 'widget'>
    & { file?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
  )> }
);

type ClientPortalForms_Block_ClientPortalBlockField = (
  Pick<ClientPortalBlockField, 'id' | 'type' | 'key' | 'label' | 'description' | 'display' | 'isRequired'>
  & { mapping: Pick<ClientPortalBlockFieldMapping, 'key' | 'type' | 'entity'>, property?: Maybe<Pick<ClientPortalBlockFieldProperty, 'type' | 'settings'>>, options: Array<Pick<ClientPortalFieldOption, 'key' | 'label' | 'context'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
);

type ClientPortalForms_Block_ClientPortalBlockLayout = (
  Pick<ClientPortalBlockLayout, 'id' | 'type' | 'key' | 'label' | 'description' | 'widget'>
  & { file?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
);

export type ClientPortalForms_Block = ClientPortalForms_Block_ClientPortalBlockField | ClientPortalForms_Block_ClientPortalBlockLayout;

export type ClientPortalForms_BlockField = (
  Pick<ClientPortalBlockField, 'id' | 'key' | 'type' | 'label' | 'description' | 'display' | 'isRequired'>
  & { mapping: Pick<ClientPortalBlockFieldMapping, 'key' | 'type' | 'entity'>, property?: Maybe<Pick<ClientPortalBlockFieldProperty, 'type' | 'settings'>>, options: Array<Pick<ClientPortalFieldOption, 'key' | 'label' | 'context'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
);

export type ClientPortalForms_BlockLayout = (
  Pick<ClientPortalBlockLayout, 'id' | 'key' | 'type' | 'label' | 'description' | 'widget'>
  & { file?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logics: Array<Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>> }
);

export type ClientPortalForms_LogicFragment = Pick<ClientPortalLogic, 'id' | 'type' | 'jsonLogic'>;

export type AddressFragment = Pick<Address, 'city' | 'country' | 'postalCode' | 'state' | 'streetAddress' | 'streetAddress2'>;

export type ClientPortalContactAccessTokenFragment = Pick<ClientPortalContactAccessToken, 'caseId' | 'clientPortalId' | 'contactId' | 'accessToken' | 'expiresAt' | 'refreshExpiresAt'>;

export type CaseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CaseQuery = { case?: Maybe<Pick<Case, 'id' | 'contactHasActions' | 'status' | 'externalId'>> };

export type ClientPortal_Case = Pick<Case, 'id' | 'contactHasActions' | 'status' | 'externalId'>;

export type LatestClientPortalVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestClientPortalVersionQuery = { latestClientPortalVersion: (
    Pick<ClientPortalVersion, 'id'>
    & { clientPortal: (
      Pick<ClientPortal, 'id' | 'workspaceId' | 'name' | 'type' | 'defaultLanguage'>
      & { workspace: (
        Pick<Workspace, 'name'>
        & { logo?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>> }
      ), customDomain: Pick<ClientPortalCustomDomain, 'domain' | 'isVerified'> }
    ), setting: (
      Pick<ClientPortalSetting, 'id' | 'isCaseCreationAllowed' | 'collaboration' | 'supportEmail' | 'redirectUrl'>
      & { styling: (
        Pick<ClientPortalSettingStyling, 'colorsMain' | 'showDotfileLogo'>
        & { favicon?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logo?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>> }
      ) }
    ), translations: Array<Pick<ClientPortalTranslation, 'id' | 'language'>> }
  ) };

export type ClientPortal_File = Pick<File, 'id' | 'name' | 'publicUrl'>;

export type ClientPortal_Version = (
  Pick<ClientPortalVersion, 'id'>
  & { clientPortal: (
    Pick<ClientPortal, 'id' | 'workspaceId' | 'name' | 'type' | 'defaultLanguage'>
    & { workspace: (
      Pick<Workspace, 'name'>
      & { logo?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>> }
    ), customDomain: Pick<ClientPortalCustomDomain, 'domain' | 'isVerified'> }
  ), setting: (
    Pick<ClientPortalSetting, 'id' | 'isCaseCreationAllowed' | 'collaboration' | 'supportEmail' | 'redirectUrl'>
    & { styling: (
      Pick<ClientPortalSettingStyling, 'colorsMain' | 'showDotfileLogo'>
      & { favicon?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logo?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>> }
    ) }
  ), translations: Array<Pick<ClientPortalTranslation, 'id' | 'language'>> }
);

export type ClientPortal_Workspace = (
  Pick<Workspace, 'name'>
  & { logo?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>> }
);

export type ClientPortal_ClientPortal = (
  Pick<ClientPortal, 'id' | 'workspaceId' | 'name' | 'type' | 'defaultLanguage'>
  & { workspace: (
    Pick<Workspace, 'name'>
    & { logo?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>> }
  ), customDomain: Pick<ClientPortalCustomDomain, 'domain' | 'isVerified'> }
);

export type ClientPortal_Setting = (
  Pick<ClientPortalSetting, 'id' | 'isCaseCreationAllowed' | 'collaboration' | 'supportEmail' | 'redirectUrl'>
  & { styling: (
    Pick<ClientPortalSettingStyling, 'colorsMain' | 'showDotfileLogo'>
    & { favicon?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logo?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>> }
  ) }
);

export type ClientPortal_SettingStyling = (
  Pick<ClientPortalSettingStyling, 'colorsMain' | 'showDotfileLogo'>
  & { favicon?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>>, logo?: Maybe<Pick<File, 'id' | 'name' | 'publicUrl'>> }
);

export type ClientPortal_Translation = Pick<ClientPortalTranslation, 'id' | 'language'>;

export type FileFragment = Pick<File, 'id' | 'name' | 'mimeType' | 'target'>;

export const ClientPortalChecks_Check = gql`
    fragment ClientPortalChecks_Check on CheckInterface {
  id
  type
  status
}
    `;
export const ClientPortalChecks_AnalysisResult = gql`
    fragment ClientPortalChecks_AnalysisResult on AnalysisResult {
  key
  description
  category
  vendor
  result
  expected
  received
  translation
}
    `;
export const ClientPortalChecks_CheckDataReview = gql`
    fragment ClientPortalChecks_CheckDataReview on Review {
  comment
  reviewedAt
}
    `;
export const ClientPortalChecks_DocumentData = gql`
    fragment ClientPortalChecks_DocumentData on DocumentData {
  id
  isLatest
  result
  settings {
    documentType
    customDocumentType {
      id
      label
      key
    }
    documentAnalysis {
      parameters
    }
  }
  information {
    documentAnalysis
  }
  detailedResults {
    documentAnalysis
  }
  analysisResults {
    ...ClientPortalChecks_AnalysisResult
  }
  updatedAt
  review {
    ...ClientPortalChecks_CheckDataReview
  }
}
    ${ClientPortalChecks_AnalysisResult}
${ClientPortalChecks_CheckDataReview}`;
export const ClientPortalChecks_DocumentCheck = gql`
    fragment ClientPortalChecks_DocumentCheck on DocumentCheck {
  ...ClientPortalChecks_Check
  data {
    ...ClientPortalChecks_DocumentData
  }
}
    ${ClientPortalChecks_Check}
${ClientPortalChecks_DocumentData}`;
export const FileFragment = gql`
    fragment FileFragment on File {
  id
  name
  mimeType
  target
}
    `;
export const ClientPortalChecks_IdDocumentData = gql`
    fragment ClientPortalChecks_IdDocumentData on IdDocumentData {
  id
  isLatest
  result
  review {
    ...ClientPortalChecks_CheckDataReview
  }
  analysisResults {
    key
    description
    category
    result
    expected
    received
    vendor
  }
  detailedResults {
    document {
      dataComparison
    }
  }
  information {
    frontFile {
      ...FileFragment
    }
    backFile {
      ...FileFragment
    }
  }
  vendor {
    name
  }
}
    ${ClientPortalChecks_CheckDataReview}
${FileFragment}`;
export const ClientPortalChecks_IdDocumentCheck = gql`
    fragment ClientPortalChecks_IdDocumentCheck on IdDocumentCheck {
  ...ClientPortalChecks_Check
  data {
    ...ClientPortalChecks_IdDocumentData
  }
}
    ${ClientPortalChecks_Check}
${ClientPortalChecks_IdDocumentData}`;
export const ClientPortalChecks_IdVerificationData = gql`
    fragment ClientPortalChecks_IdVerificationData on IdVerificationData {
  id
  isLatest
  result
  settings {
    mode
    redirectUrl
  }
  vendor {
    name
    verificationRef
    verificationUrl
    sdkToken
    verificationCodes
    startedAt
    endedAt
  }
  review {
    ...ClientPortalChecks_CheckDataReview
  }
  analysisResults {
    key
    description
    category
    result
    expected
    received
    vendor
  }
  detailedResults {
    document {
      dataComparison
    }
  }
  information {
    frontFile {
      ...FileFragment
    }
    backFile {
      ...FileFragment
    }
  }
}
    ${ClientPortalChecks_CheckDataReview}
${FileFragment}`;
export const ClientPortalChecks_IdVerificationCheck = gql`
    fragment ClientPortalChecks_IdVerificationCheck on IdVerificationCheck {
  ...ClientPortalChecks_Check
  data {
    ...ClientPortalChecks_IdVerificationData
  }
}
    ${ClientPortalChecks_Check}
${ClientPortalChecks_IdVerificationData}`;
export const ClientPortalChecks_CheckInterface = gql`
    fragment ClientPortalChecks_CheckInterface on CheckInterface {
  ...ClientPortalChecks_DocumentCheck
  ...ClientPortalChecks_IdDocumentCheck
  ...ClientPortalChecks_IdVerificationCheck
}
    ${ClientPortalChecks_DocumentCheck}
${ClientPortalChecks_IdDocumentCheck}
${ClientPortalChecks_IdVerificationCheck}`;
export const ClientPortalChecks_Individual = gql`
    fragment ClientPortalChecks_Individual on Individual {
  id
  firstName
  middleName
  maidenName
  lastName
  birthDate
  roles
  isBeneficialOwner
  isBusinessContact
  isDelegator
  email
  checks {
    ...ClientPortalChecks_CheckInterface
  }
}
    ${ClientPortalChecks_CheckInterface}`;
export const ClientPortalChecks_Company = gql`
    fragment ClientPortalChecks_Company on Company {
  id
  name
  type
  checks {
    ...ClientPortalChecks_CheckInterface
  }
}
    ${ClientPortalChecks_CheckInterface}`;
export const ClientPortalChecks_Case = gql`
    fragment ClientPortalChecks_Case on Case {
  id
  contactHasActions
  individuals {
    ...ClientPortalChecks_Individual
  }
  companies {
    ...ClientPortalChecks_Company
  }
}
    ${ClientPortalChecks_Individual}
${ClientPortalChecks_Company}`;
export const ClientPortalChecks_ContactHasActionsRefresh = gql`
    fragment ClientPortalChecks_ContactHasActionsRefresh on CheckInterface {
  id
  individual {
    id
    case {
      id
      contactHasActions
    }
  }
  company {
    id
    case {
      id
      contactHasActions
    }
  }
}
    `;
export const CompanySearch_CompanySearchFragment = gql`
    fragment CompanySearch_CompanySearchFragment on CompanySearch {
  searchRef
  name
  country
  registrationNumber
  address {
    postalCode
    city
  }
  vendor
  vendorId
}
    `;
export const AddressFragment = gql`
    fragment AddressFragment on Address {
  city
  country
  postalCode
  state
  streetAddress
  streetAddress2
}
    `;
export const CompanyData_CompanyFetchMergedIndividualFragment = gql`
    fragment CompanyData_CompanyFetchMergedIndividualFragment on MergedIndividual {
  firstName
  lastName
  middleName
  maidenName
  birthDate
  birthPlace
  birthCountry
  address {
    ...AddressFragment
  }
  position
  ownershipPercentage
  votingRightsPercentage
  roles
  isBeneficialOwner
  isBusinessContact
  relations {
    position
    roles
    ownershipPercentage
    votingRightsPercentage
  }
}
    ${AddressFragment}`;
export const CompanyData_CompanyFetchMergedCompanyFragment = gql`
    fragment CompanyData_CompanyFetchMergedCompanyFragment on MergedCompany {
  name
  registrationNumber
  legalForm
  entityLegalForm
  country
  address {
    ...AddressFragment
  }
  relations {
    position
    roles
    ownershipPercentage
  }
}
    ${AddressFragment}`;
export const CompanyData_CompanyFetchFragment = gql`
    fragment CompanyData_CompanyFetchFragment on CompanyData {
  name
  commercialName
  registrationNumber
  registrationDate
  legalForm
  entityLegalForm
  status
  country
  address {
    ...AddressFragment
  }
  classifications {
    code
    description
    type
  }
  shareCapital
  taxIdentificationNumber
  mergedIndividuals {
    ...CompanyData_CompanyFetchMergedIndividualFragment
  }
  mergedCompanies {
    ...CompanyData_CompanyFetchMergedCompanyFragment
  }
}
    ${AddressFragment}
${CompanyData_CompanyFetchMergedIndividualFragment}
${CompanyData_CompanyFetchMergedCompanyFragment}`;
export const ClientPortalForms_CustomPropertyValue = gql`
    fragment ClientPortalForms_CustomPropertyValue on CustomPropertyValue {
  id
  value
  customProperty {
    id
    type
    key
  }
}
    `;
export const ClientPortalForms_Individual = gql`
    fragment ClientPortalForms_Individual on Individual {
  id
  roles
  isBusinessContact
  isBeneficialOwner
  isDelegator
  isSignatory
  firstName
  middleName
  maidenName
  lastName
  email
  birthDate
  birthCountry
  birthPlace
  bankingInformation {
    iban
    bic
  }
  address {
    ...AddressFragment
  }
  taxIdentificationNumber
  phoneNumber
  socialSecurityNumber
  position
  ownershipPercentage
  votingRightsPercentage
  customPropertyValues {
    ...ClientPortalForms_CustomPropertyValue
  }
}
    ${AddressFragment}
${ClientPortalForms_CustomPropertyValue}`;
export const ClientPortalForms_Company = gql`
    fragment ClientPortalForms_Company on Company {
  id
  type
  name
  commercialName
  registrationNumber
  registrationDate
  status
  legalForm
  entityLegalForm
  country
  address {
    ...AddressFragment
  }
  classifications {
    code
    description
    type
  }
  bankingInformation {
    iban
    bic
  }
  shareCapital
  taxIdentificationNumber
  websiteUrl
  employerIdentificationNumber
  customPropertyValues {
    ...ClientPortalForms_CustomPropertyValue
  }
}
    ${AddressFragment}
${ClientPortalForms_CustomPropertyValue}`;
export const ClientPortalForms_Case = gql`
    fragment ClientPortalForms_Case on Case {
  id
  externalId
  metadata
  customPropertyValues {
    ...ClientPortalForms_CustomPropertyValue
  }
  individuals {
    ...ClientPortalForms_Individual
  }
  companies {
    ...ClientPortalForms_Company
  }
}
    ${ClientPortalForms_CustomPropertyValue}
${ClientPortalForms_Individual}
${ClientPortalForms_Company}`;
export const ClientPortalForms_LogicFragment = gql`
    fragment ClientPortalForms_LogicFragment on ClientPortalLogic {
  id
  type
  jsonLogic
}
    `;
export const ClientPortalForms_BlockField = gql`
    fragment ClientPortalForms_BlockField on ClientPortalBlockField {
  id
  key
  type
  label
  description
  display
  mapping {
    key
    type
    entity
  }
  property {
    type
    settings
  }
  options {
    key
    label
    context
  }
  isRequired
  logics(filter: {isEnabled: {is: true}}) {
    ...ClientPortalForms_LogicFragment
  }
}
    ${ClientPortalForms_LogicFragment}`;
export const ClientPortal_File = gql`
    fragment ClientPortal_File on File {
  id
  name
  publicUrl
}
    `;
export const ClientPortalForms_BlockLayout = gql`
    fragment ClientPortalForms_BlockLayout on ClientPortalBlockLayout {
  id
  key
  type
  label
  description
  widget
  file {
    ...ClientPortal_File
  }
  logics {
    ...ClientPortalForms_LogicFragment
  }
}
    ${ClientPortal_File}
${ClientPortalForms_LogicFragment}`;
export const ClientPortalForms_Block = gql`
    fragment ClientPortalForms_Block on ClientPortalBlockInterface {
  id
  type
  ... on ClientPortalBlockField {
    ...ClientPortalForms_BlockField
  }
  ... on ClientPortalBlockLayout {
    ...ClientPortalForms_BlockLayout
  }
}
    ${ClientPortalForms_BlockField}
${ClientPortalForms_BlockLayout}`;
export const ClientPortalForms_Step = gql`
    fragment ClientPortalForms_Step on ClientPortalStep {
  id
  key
  type
  title
  description
  config
  logics(filter: {isEnabled: {is: true}}) {
    ...ClientPortalForms_LogicFragment
  }
  blocks(sorting: {field: position, direction: ASC}) {
    ...ClientPortalForms_Block
  }
}
    ${ClientPortalForms_LogicFragment}
${ClientPortalForms_Block}`;
export const ClientPortalForms_Version = gql`
    fragment ClientPortalForms_Version on ClientPortalVersion {
  id
  steps(
    filter: {isVisible: {is: true}}
    sorting: {field: position, direction: ASC}
  ) {
    ...ClientPortalForms_Step
  }
}
    ${ClientPortalForms_Step}`;
export const ClientPortalContactAccessTokenFragment = gql`
    fragment ClientPortalContactAccessTokenFragment on ClientPortalContactAccessToken {
  caseId
  clientPortalId
  contactId
  accessToken
  expiresAt
  refreshExpiresAt
}
    `;
export const ClientPortal_Case = gql`
    fragment ClientPortal_Case on Case {
  id
  contactHasActions
  status
  externalId
}
    `;
export const ClientPortal_Workspace = gql`
    fragment ClientPortal_Workspace on Workspace {
  name
  logo {
    ...ClientPortal_File
  }
}
    ${ClientPortal_File}`;
export const ClientPortal_ClientPortal = gql`
    fragment ClientPortal_ClientPortal on ClientPortal {
  id
  workspaceId
  workspace {
    ...ClientPortal_Workspace
  }
  name
  type
  defaultLanguage
  customDomain {
    domain
    isVerified
  }
}
    ${ClientPortal_Workspace}`;
export const ClientPortal_SettingStyling = gql`
    fragment ClientPortal_SettingStyling on ClientPortalSettingStyling {
  colorsMain
  showDotfileLogo
  favicon {
    ...ClientPortal_File
  }
  logo {
    ...ClientPortal_File
  }
}
    ${ClientPortal_File}`;
export const ClientPortal_Setting = gql`
    fragment ClientPortal_Setting on ClientPortalSetting {
  id
  isCaseCreationAllowed
  collaboration
  supportEmail
  redirectUrl
  styling {
    ...ClientPortal_SettingStyling
  }
}
    ${ClientPortal_SettingStyling}`;
export const ClientPortal_Translation = gql`
    fragment ClientPortal_Translation on ClientPortalTranslation {
  id
  language
}
    `;
export const ClientPortal_Version = gql`
    fragment ClientPortal_Version on ClientPortalVersion {
  id
  clientPortal {
    ...ClientPortal_ClientPortal
  }
  setting {
    ...ClientPortal_Setting
  }
  translations(
    sorting: {field: position, direction: ASC}
    filter: {enabled: {is: true}}
  ) {
    ...ClientPortal_Translation
  }
}
    ${ClientPortal_ClientPortal}
${ClientPortal_Setting}
${ClientPortal_Translation}`;
export const SaveBusinessContactMutationDocument = gql`
    mutation saveBusinessContactMutation($input: AddBusinessContactInput!) {
  saveBusinessContact(input: $input) {
    id
  }
}
    `;
export type SaveBusinessContactMutationMutationFn = Apollo.MutationFunction<SaveBusinessContactMutation, SaveBusinessContactMutationVariables>;

/**
 * __useSaveBusinessContactMutation__
 *
 * To run a mutation, you first call `useSaveBusinessContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBusinessContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBusinessContactMutation, { data, loading, error }] = useSaveBusinessContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBusinessContactMutation(baseOptions?: Apollo.MutationHookOptions<SaveBusinessContactMutation, SaveBusinessContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBusinessContactMutation, SaveBusinessContactMutationVariables>(SaveBusinessContactMutationDocument, options);
      }
export type SaveBusinessContactMutationHookResult = ReturnType<typeof useSaveBusinessContactMutation>;
export type SaveBusinessContactMutationMutationResult = Apollo.MutationResult<SaveBusinessContactMutation>;
export type SaveBusinessContactMutationMutationOptions = Apollo.BaseMutationOptions<SaveBusinessContactMutation, SaveBusinessContactMutationVariables>;
export const SendCollaborationEmailMutationDocument = gql`
    mutation sendCollaborationEmailMutation($input: SendCollaborationEmailInput!) {
  sendCollaborationEmail(input: $input) {
    sent
  }
}
    `;
export type SendCollaborationEmailMutationMutationFn = Apollo.MutationFunction<SendCollaborationEmailMutation, SendCollaborationEmailMutationVariables>;

/**
 * __useSendCollaborationEmailMutation__
 *
 * To run a mutation, you first call `useSendCollaborationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCollaborationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCollaborationEmailMutation, { data, loading, error }] = useSendCollaborationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCollaborationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendCollaborationEmailMutation, SendCollaborationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCollaborationEmailMutation, SendCollaborationEmailMutationVariables>(SendCollaborationEmailMutationDocument, options);
      }
export type SendCollaborationEmailMutationHookResult = ReturnType<typeof useSendCollaborationEmailMutation>;
export type SendCollaborationEmailMutationMutationResult = Apollo.MutationResult<SendCollaborationEmailMutation>;
export type SendCollaborationEmailMutationMutationOptions = Apollo.BaseMutationOptions<SendCollaborationEmailMutation, SendCollaborationEmailMutationVariables>;
export const ShareClientPortalLinkMutationDocument = gql`
    mutation shareClientPortalLinkMutation($input: ShareClientPortalLinkInput!) {
  shareClientPortalLink(input: $input) {
    link
    expiresAt
  }
}
    `;
export type ShareClientPortalLinkMutationMutationFn = Apollo.MutationFunction<ShareClientPortalLinkMutation, ShareClientPortalLinkMutationVariables>;

/**
 * __useShareClientPortalLinkMutation__
 *
 * To run a mutation, you first call `useShareClientPortalLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareClientPortalLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareClientPortalLinkMutation, { data, loading, error }] = useShareClientPortalLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareClientPortalLinkMutation(baseOptions?: Apollo.MutationHookOptions<ShareClientPortalLinkMutation, ShareClientPortalLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareClientPortalLinkMutation, ShareClientPortalLinkMutationVariables>(ShareClientPortalLinkMutationDocument, options);
      }
export type ShareClientPortalLinkMutationHookResult = ReturnType<typeof useShareClientPortalLinkMutation>;
export type ShareClientPortalLinkMutationMutationResult = Apollo.MutationResult<ShareClientPortalLinkMutation>;
export type ShareClientPortalLinkMutationMutationOptions = Apollo.BaseMutationOptions<ShareClientPortalLinkMutation, ShareClientPortalLinkMutationVariables>;
export const AddFilesDocumentCheckMutationDocument = gql`
    mutation addFilesDocumentCheckMutation($input: AddFilesDocumentCheckInput!) {
  addFilesDocumentCheck(input: $input) {
    ...ClientPortalChecks_DocumentCheck
    ...ClientPortalChecks_ContactHasActionsRefresh
  }
}
    ${ClientPortalChecks_DocumentCheck}
${ClientPortalChecks_ContactHasActionsRefresh}`;
export type AddFilesDocumentCheckMutationMutationFn = Apollo.MutationFunction<AddFilesDocumentCheckMutation, AddFilesDocumentCheckMutationVariables>;

/**
 * __useAddFilesDocumentCheckMutation__
 *
 * To run a mutation, you first call `useAddFilesDocumentCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFilesDocumentCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFilesDocumentCheckMutation, { data, loading, error }] = useAddFilesDocumentCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFilesDocumentCheckMutation(baseOptions?: Apollo.MutationHookOptions<AddFilesDocumentCheckMutation, AddFilesDocumentCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFilesDocumentCheckMutation, AddFilesDocumentCheckMutationVariables>(AddFilesDocumentCheckMutationDocument, options);
      }
export type AddFilesDocumentCheckMutationHookResult = ReturnType<typeof useAddFilesDocumentCheckMutation>;
export type AddFilesDocumentCheckMutationMutationResult = Apollo.MutationResult<AddFilesDocumentCheckMutation>;
export type AddFilesDocumentCheckMutationMutationOptions = Apollo.BaseMutationOptions<AddFilesDocumentCheckMutation, AddFilesDocumentCheckMutationVariables>;
export const ForceReviewDocumentCheckMutationDocument = gql`
    mutation forceReviewDocumentCheckMutation($input: ForceReviewDocumentCheckInput!) {
  forceReviewDocumentCheck(input: $input) {
    id
    status
    ...ClientPortalChecks_ContactHasActionsRefresh
  }
}
    ${ClientPortalChecks_ContactHasActionsRefresh}`;
export type ForceReviewDocumentCheckMutationMutationFn = Apollo.MutationFunction<ForceReviewDocumentCheckMutation, ForceReviewDocumentCheckMutationVariables>;

/**
 * __useForceReviewDocumentCheckMutation__
 *
 * To run a mutation, you first call `useForceReviewDocumentCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceReviewDocumentCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceReviewDocumentCheckMutation, { data, loading, error }] = useForceReviewDocumentCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceReviewDocumentCheckMutation(baseOptions?: Apollo.MutationHookOptions<ForceReviewDocumentCheckMutation, ForceReviewDocumentCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForceReviewDocumentCheckMutation, ForceReviewDocumentCheckMutationVariables>(ForceReviewDocumentCheckMutationDocument, options);
      }
export type ForceReviewDocumentCheckMutationHookResult = ReturnType<typeof useForceReviewDocumentCheckMutation>;
export type ForceReviewDocumentCheckMutationMutationResult = Apollo.MutationResult<ForceReviewDocumentCheckMutation>;
export type ForceReviewDocumentCheckMutationMutationOptions = Apollo.BaseMutationOptions<ForceReviewDocumentCheckMutation, ForceReviewDocumentCheckMutationVariables>;
export const AddFilesIdDocumentCheckMutationDocument = gql`
    mutation addFilesIdDocumentCheckMutation($input: AddFilesIdDocumentCheckInput!) {
  addFilesIdDocumentCheck(input: $input) {
    ...ClientPortalChecks_IdDocumentCheck
  }
}
    ${ClientPortalChecks_IdDocumentCheck}`;
export type AddFilesIdDocumentCheckMutationMutationFn = Apollo.MutationFunction<AddFilesIdDocumentCheckMutation, AddFilesIdDocumentCheckMutationVariables>;

/**
 * __useAddFilesIdDocumentCheckMutation__
 *
 * To run a mutation, you first call `useAddFilesIdDocumentCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFilesIdDocumentCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFilesIdDocumentCheckMutation, { data, loading, error }] = useAddFilesIdDocumentCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFilesIdDocumentCheckMutation(baseOptions?: Apollo.MutationHookOptions<AddFilesIdDocumentCheckMutation, AddFilesIdDocumentCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFilesIdDocumentCheckMutation, AddFilesIdDocumentCheckMutationVariables>(AddFilesIdDocumentCheckMutationDocument, options);
      }
export type AddFilesIdDocumentCheckMutationHookResult = ReturnType<typeof useAddFilesIdDocumentCheckMutation>;
export type AddFilesIdDocumentCheckMutationMutationResult = Apollo.MutationResult<AddFilesIdDocumentCheckMutation>;
export type AddFilesIdDocumentCheckMutationMutationOptions = Apollo.BaseMutationOptions<AddFilesIdDocumentCheckMutation, AddFilesIdDocumentCheckMutationVariables>;
export const IdDocumentCheckQueryDocument = gql`
    query idDocumentCheckQuery($id: ID!) {
  idDocumentCheck(id: $id) {
    ...ClientPortalChecks_IdDocumentCheck
  }
}
    ${ClientPortalChecks_IdDocumentCheck}`;

/**
 * __useIdDocumentCheckQuery__
 *
 * To run a query within a React component, call `useIdDocumentCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdDocumentCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdDocumentCheckQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIdDocumentCheckQuery(baseOptions: Apollo.QueryHookOptions<IdDocumentCheckQuery, IdDocumentCheckQueryVariables> & ({ variables: IdDocumentCheckQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IdDocumentCheckQuery, IdDocumentCheckQueryVariables>(IdDocumentCheckQueryDocument, options);
      }
export function useIdDocumentCheckQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IdDocumentCheckQuery, IdDocumentCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IdDocumentCheckQuery, IdDocumentCheckQueryVariables>(IdDocumentCheckQueryDocument, options);
        }
export function useIdDocumentCheckQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IdDocumentCheckQuery, IdDocumentCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IdDocumentCheckQuery, IdDocumentCheckQueryVariables>(IdDocumentCheckQueryDocument, options);
        }
export type IdDocumentCheckQueryHookResult = ReturnType<typeof useIdDocumentCheckQuery>;
export type IdDocumentCheckQueryLazyQueryHookResult = ReturnType<typeof useIdDocumentCheckQueryLazyQuery>;
export type IdDocumentCheckQuerySuspenseQueryHookResult = ReturnType<typeof useIdDocumentCheckQuerySuspenseQuery>;
export type IdDocumentCheckQueryQueryResult = Apollo.QueryResult<IdDocumentCheckQuery, IdDocumentCheckQueryVariables>;
export const UpdateIdDocumentReferenceDataMutationDocument = gql`
    mutation updateIdDocumentReferenceDataMutation($input: UpdateIdDocumentReferenceDataInput!) {
  updateIdDocumentReferenceData(input: $input) {
    ...ClientPortalChecks_IdDocumentCheck
    individual {
      id
      firstName
      lastName
      birthDate
    }
  }
}
    ${ClientPortalChecks_IdDocumentCheck}`;
export type UpdateIdDocumentReferenceDataMutationMutationFn = Apollo.MutationFunction<UpdateIdDocumentReferenceDataMutation, UpdateIdDocumentReferenceDataMutationVariables>;

/**
 * __useUpdateIdDocumentReferenceDataMutation__
 *
 * To run a mutation, you first call `useUpdateIdDocumentReferenceDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdDocumentReferenceDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdDocumentReferenceDataMutation, { data, loading, error }] = useUpdateIdDocumentReferenceDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIdDocumentReferenceDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIdDocumentReferenceDataMutation, UpdateIdDocumentReferenceDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIdDocumentReferenceDataMutation, UpdateIdDocumentReferenceDataMutationVariables>(UpdateIdDocumentReferenceDataMutationDocument, options);
      }
export type UpdateIdDocumentReferenceDataMutationHookResult = ReturnType<typeof useUpdateIdDocumentReferenceDataMutation>;
export type UpdateIdDocumentReferenceDataMutationMutationResult = Apollo.MutationResult<UpdateIdDocumentReferenceDataMutation>;
export type UpdateIdDocumentReferenceDataMutationMutationOptions = Apollo.BaseMutationOptions<UpdateIdDocumentReferenceDataMutation, UpdateIdDocumentReferenceDataMutationVariables>;
export const IdVerificationCheckQueryDocument = gql`
    query idVerificationCheckQuery($id: ID!) {
  idVerificationCheck(id: $id) {
    ...ClientPortalChecks_IdVerificationCheck
    ...ClientPortalChecks_ContactHasActionsRefresh
  }
}
    ${ClientPortalChecks_IdVerificationCheck}
${ClientPortalChecks_ContactHasActionsRefresh}`;

/**
 * __useIdVerificationCheckQuery__
 *
 * To run a query within a React component, call `useIdVerificationCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdVerificationCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdVerificationCheckQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIdVerificationCheckQuery(baseOptions: Apollo.QueryHookOptions<IdVerificationCheckQuery, IdVerificationCheckQueryVariables> & ({ variables: IdVerificationCheckQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IdVerificationCheckQuery, IdVerificationCheckQueryVariables>(IdVerificationCheckQueryDocument, options);
      }
export function useIdVerificationCheckQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IdVerificationCheckQuery, IdVerificationCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IdVerificationCheckQuery, IdVerificationCheckQueryVariables>(IdVerificationCheckQueryDocument, options);
        }
export function useIdVerificationCheckQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IdVerificationCheckQuery, IdVerificationCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IdVerificationCheckQuery, IdVerificationCheckQueryVariables>(IdVerificationCheckQueryDocument, options);
        }
export type IdVerificationCheckQueryHookResult = ReturnType<typeof useIdVerificationCheckQuery>;
export type IdVerificationCheckQueryLazyQueryHookResult = ReturnType<typeof useIdVerificationCheckQueryLazyQuery>;
export type IdVerificationCheckQuerySuspenseQueryHookResult = ReturnType<typeof useIdVerificationCheckQuerySuspenseQuery>;
export type IdVerificationCheckQueryQueryResult = Apollo.QueryResult<IdVerificationCheckQuery, IdVerificationCheckQueryVariables>;
export const RefreshUrlIdentityVerificationCheckMutationDocument = gql`
    mutation refreshUrlIdentityVerificationCheckMutation($input: RefreshUrlIdentityVerificationCheckInput!) {
  refreshUrlIdentityVerificationCheck(input: $input) {
    ...ClientPortalChecks_IdVerificationCheck
  }
}
    ${ClientPortalChecks_IdVerificationCheck}`;
export type RefreshUrlIdentityVerificationCheckMutationMutationFn = Apollo.MutationFunction<RefreshUrlIdentityVerificationCheckMutation, RefreshUrlIdentityVerificationCheckMutationVariables>;

/**
 * __useRefreshUrlIdentityVerificationCheckMutation__
 *
 * To run a mutation, you first call `useRefreshUrlIdentityVerificationCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUrlIdentityVerificationCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUrlIdentityVerificationCheckMutation, { data, loading, error }] = useRefreshUrlIdentityVerificationCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshUrlIdentityVerificationCheckMutation(baseOptions?: Apollo.MutationHookOptions<RefreshUrlIdentityVerificationCheckMutation, RefreshUrlIdentityVerificationCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshUrlIdentityVerificationCheckMutation, RefreshUrlIdentityVerificationCheckMutationVariables>(RefreshUrlIdentityVerificationCheckMutationDocument, options);
      }
export type RefreshUrlIdentityVerificationCheckMutationHookResult = ReturnType<typeof useRefreshUrlIdentityVerificationCheckMutation>;
export type RefreshUrlIdentityVerificationCheckMutationMutationResult = Apollo.MutationResult<RefreshUrlIdentityVerificationCheckMutation>;
export type RefreshUrlIdentityVerificationCheckMutationMutationOptions = Apollo.BaseMutationOptions<RefreshUrlIdentityVerificationCheckMutation, RefreshUrlIdentityVerificationCheckMutationVariables>;
export const RestartIdentityVerificationCheckMutationDocument = gql`
    mutation restartIdentityVerificationCheckMutation($input: RestartIdentityVerificationCheckInput!) {
  restartIdentityVerificationCheck(input: $input) {
    ...ClientPortalChecks_IdVerificationCheck
  }
}
    ${ClientPortalChecks_IdVerificationCheck}`;
export type RestartIdentityVerificationCheckMutationMutationFn = Apollo.MutationFunction<RestartIdentityVerificationCheckMutation, RestartIdentityVerificationCheckMutationVariables>;

/**
 * __useRestartIdentityVerificationCheckMutation__
 *
 * To run a mutation, you first call `useRestartIdentityVerificationCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartIdentityVerificationCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartIdentityVerificationCheckMutation, { data, loading, error }] = useRestartIdentityVerificationCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestartIdentityVerificationCheckMutation(baseOptions?: Apollo.MutationHookOptions<RestartIdentityVerificationCheckMutation, RestartIdentityVerificationCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartIdentityVerificationCheckMutation, RestartIdentityVerificationCheckMutationVariables>(RestartIdentityVerificationCheckMutationDocument, options);
      }
export type RestartIdentityVerificationCheckMutationHookResult = ReturnType<typeof useRestartIdentityVerificationCheckMutation>;
export type RestartIdentityVerificationCheckMutationMutationResult = Apollo.MutationResult<RestartIdentityVerificationCheckMutation>;
export type RestartIdentityVerificationCheckMutationMutationOptions = Apollo.BaseMutationOptions<RestartIdentityVerificationCheckMutation, RestartIdentityVerificationCheckMutationVariables>;
export const UpdateIdVerificationReferenceDataMutationDocument = gql`
    mutation updateIdVerificationReferenceDataMutation($input: UpdateIdVerificationReferenceDataInput!) {
  updateIdVerificationReferenceData(input: $input) {
    ...ClientPortalChecks_IdVerificationCheck
    individual {
      id
      firstName
      lastName
      birthDate
    }
  }
}
    ${ClientPortalChecks_IdVerificationCheck}`;
export type UpdateIdVerificationReferenceDataMutationMutationFn = Apollo.MutationFunction<UpdateIdVerificationReferenceDataMutation, UpdateIdVerificationReferenceDataMutationVariables>;

/**
 * __useUpdateIdVerificationReferenceDataMutation__
 *
 * To run a mutation, you first call `useUpdateIdVerificationReferenceDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdVerificationReferenceDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdVerificationReferenceDataMutation, { data, loading, error }] = useUpdateIdVerificationReferenceDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIdVerificationReferenceDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIdVerificationReferenceDataMutation, UpdateIdVerificationReferenceDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIdVerificationReferenceDataMutation, UpdateIdVerificationReferenceDataMutationVariables>(UpdateIdVerificationReferenceDataMutationDocument, options);
      }
export type UpdateIdVerificationReferenceDataMutationHookResult = ReturnType<typeof useUpdateIdVerificationReferenceDataMutation>;
export type UpdateIdVerificationReferenceDataMutationMutationResult = Apollo.MutationResult<UpdateIdVerificationReferenceDataMutation>;
export type UpdateIdVerificationReferenceDataMutationMutationOptions = Apollo.BaseMutationOptions<UpdateIdVerificationReferenceDataMutation, UpdateIdVerificationReferenceDataMutationVariables>;
export const CaseChecksQueryDocument = gql`
    query caseChecksQuery($id: ID!) {
  case(id: $id) {
    ...ClientPortalChecks_Case
  }
}
    ${ClientPortalChecks_Case}`;

/**
 * __useCaseChecksQuery__
 *
 * To run a query within a React component, call `useCaseChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseChecksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseChecksQuery(baseOptions: Apollo.QueryHookOptions<CaseChecksQuery, CaseChecksQueryVariables> & ({ variables: CaseChecksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseChecksQuery, CaseChecksQueryVariables>(CaseChecksQueryDocument, options);
      }
export function useCaseChecksQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseChecksQuery, CaseChecksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseChecksQuery, CaseChecksQueryVariables>(CaseChecksQueryDocument, options);
        }
export function useCaseChecksQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CaseChecksQuery, CaseChecksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CaseChecksQuery, CaseChecksQueryVariables>(CaseChecksQueryDocument, options);
        }
export type CaseChecksQueryHookResult = ReturnType<typeof useCaseChecksQuery>;
export type CaseChecksQueryLazyQueryHookResult = ReturnType<typeof useCaseChecksQueryLazyQuery>;
export type CaseChecksQuerySuspenseQueryHookResult = ReturnType<typeof useCaseChecksQuerySuspenseQuery>;
export type CaseChecksQueryQueryResult = Apollo.QueryResult<CaseChecksQuery, CaseChecksQueryVariables>;
export const TryCompleteChecksMutationDocument = gql`
    mutation tryCompleteChecksMutation {
  tryCompleteChecks {
    id
  }
}
    `;
export type TryCompleteChecksMutationMutationFn = Apollo.MutationFunction<TryCompleteChecksMutation, TryCompleteChecksMutationVariables>;

/**
 * __useTryCompleteChecksMutation__
 *
 * To run a mutation, you first call `useTryCompleteChecksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTryCompleteChecksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tryCompleteChecksMutation, { data, loading, error }] = useTryCompleteChecksMutation({
 *   variables: {
 *   },
 * });
 */
export function useTryCompleteChecksMutation(baseOptions?: Apollo.MutationHookOptions<TryCompleteChecksMutation, TryCompleteChecksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TryCompleteChecksMutation, TryCompleteChecksMutationVariables>(TryCompleteChecksMutationDocument, options);
      }
export type TryCompleteChecksMutationHookResult = ReturnType<typeof useTryCompleteChecksMutation>;
export type TryCompleteChecksMutationMutationResult = Apollo.MutationResult<TryCompleteChecksMutation>;
export type TryCompleteChecksMutationMutationOptions = Apollo.BaseMutationOptions<TryCompleteChecksMutation, TryCompleteChecksMutationVariables>;
export const CompanyFetchQueryDocument = gql`
    query companyFetchQuery($input: CompanyFetchInput!) {
  companyFetch(input: $input) {
    ...CompanyData_CompanyFetchFragment
  }
}
    ${CompanyData_CompanyFetchFragment}`;

/**
 * __useCompanyFetchQuery__
 *
 * To run a query within a React component, call `useCompanyFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFetchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyFetchQuery(baseOptions: Apollo.QueryHookOptions<CompanyFetchQuery, CompanyFetchQueryVariables> & ({ variables: CompanyFetchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyFetchQuery, CompanyFetchQueryVariables>(CompanyFetchQueryDocument, options);
      }
export function useCompanyFetchQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyFetchQuery, CompanyFetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyFetchQuery, CompanyFetchQueryVariables>(CompanyFetchQueryDocument, options);
        }
export function useCompanyFetchQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyFetchQuery, CompanyFetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyFetchQuery, CompanyFetchQueryVariables>(CompanyFetchQueryDocument, options);
        }
export type CompanyFetchQueryHookResult = ReturnType<typeof useCompanyFetchQuery>;
export type CompanyFetchQueryLazyQueryHookResult = ReturnType<typeof useCompanyFetchQueryLazyQuery>;
export type CompanyFetchQuerySuspenseQueryHookResult = ReturnType<typeof useCompanyFetchQuerySuspenseQuery>;
export type CompanyFetchQueryQueryResult = Apollo.QueryResult<CompanyFetchQuery, CompanyFetchQueryVariables>;
export const CompanySearchQueryDocument = gql`
    query companySearchQuery($input: CompanySearchInput!) {
  companySearch(input: $input) {
    data {
      ...CompanySearch_CompanySearchFragment
    }
  }
}
    ${CompanySearch_CompanySearchFragment}`;

/**
 * __useCompanySearchQuery__
 *
 * To run a query within a React component, call `useCompanySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanySearchQuery(baseOptions: Apollo.QueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables> & ({ variables: CompanySearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchQueryDocument, options);
      }
export function useCompanySearchQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchQueryDocument, options);
        }
export function useCompanySearchQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanySearchQuery, CompanySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanySearchQuery, CompanySearchQueryVariables>(CompanySearchQueryDocument, options);
        }
export type CompanySearchQueryHookResult = ReturnType<typeof useCompanySearchQuery>;
export type CompanySearchQueryLazyQueryHookResult = ReturnType<typeof useCompanySearchQueryLazyQuery>;
export type CompanySearchQuerySuspenseQueryHookResult = ReturnType<typeof useCompanySearchQuerySuspenseQuery>;
export type CompanySearchQueryQueryResult = Apollo.QueryResult<CompanySearchQuery, CompanySearchQueryVariables>;
export const SaveForLaterPublicMutationDocument = gql`
    mutation saveForLaterPublicMutation($input: CaseDataInput!) {
  saveForLaterPublic(input: $input) {
    auth {
      ...ClientPortalContactAccessTokenFragment
    }
    case {
      ...ClientPortalForms_Case
    }
  }
}
    ${ClientPortalContactAccessTokenFragment}
${ClientPortalForms_Case}`;
export type SaveForLaterPublicMutationMutationFn = Apollo.MutationFunction<SaveForLaterPublicMutation, SaveForLaterPublicMutationVariables>;

/**
 * __useSaveForLaterPublicMutation__
 *
 * To run a mutation, you first call `useSaveForLaterPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveForLaterPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveForLaterPublicMutation, { data, loading, error }] = useSaveForLaterPublicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveForLaterPublicMutation(baseOptions?: Apollo.MutationHookOptions<SaveForLaterPublicMutation, SaveForLaterPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveForLaterPublicMutation, SaveForLaterPublicMutationVariables>(SaveForLaterPublicMutationDocument, options);
      }
export type SaveForLaterPublicMutationHookResult = ReturnType<typeof useSaveForLaterPublicMutation>;
export type SaveForLaterPublicMutationMutationResult = Apollo.MutationResult<SaveForLaterPublicMutation>;
export type SaveForLaterPublicMutationMutationOptions = Apollo.BaseMutationOptions<SaveForLaterPublicMutation, SaveForLaterPublicMutationVariables>;
export const SaveForLaterMutationDocument = gql`
    mutation saveForLaterMutation($input: CaseDataInput!) {
  saveForLater(input: $input) {
    auth {
      ...ClientPortalContactAccessTokenFragment
    }
    case {
      ...ClientPortalForms_Case
    }
  }
}
    ${ClientPortalContactAccessTokenFragment}
${ClientPortalForms_Case}`;
export type SaveForLaterMutationMutationFn = Apollo.MutationFunction<SaveForLaterMutation, SaveForLaterMutationVariables>;

/**
 * __useSaveForLaterMutation__
 *
 * To run a mutation, you first call `useSaveForLaterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveForLaterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveForLaterMutation, { data, loading, error }] = useSaveForLaterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveForLaterMutation(baseOptions?: Apollo.MutationHookOptions<SaveForLaterMutation, SaveForLaterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveForLaterMutation, SaveForLaterMutationVariables>(SaveForLaterMutationDocument, options);
      }
export type SaveForLaterMutationHookResult = ReturnType<typeof useSaveForLaterMutation>;
export type SaveForLaterMutationMutationResult = Apollo.MutationResult<SaveForLaterMutation>;
export type SaveForLaterMutationMutationOptions = Apollo.BaseMutationOptions<SaveForLaterMutation, SaveForLaterMutationVariables>;
export const AutoSaveForLaterMutationDocument = gql`
    mutation autoSaveForLaterMutation($input: CaseDataInput!) {
  autoSaveForLater(input: $input) {
    auth {
      ...ClientPortalContactAccessTokenFragment
    }
    case {
      ...ClientPortalForms_Case
    }
  }
}
    ${ClientPortalContactAccessTokenFragment}
${ClientPortalForms_Case}`;
export type AutoSaveForLaterMutationMutationFn = Apollo.MutationFunction<AutoSaveForLaterMutation, AutoSaveForLaterMutationVariables>;

/**
 * __useAutoSaveForLaterMutation__
 *
 * To run a mutation, you first call `useAutoSaveForLaterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoSaveForLaterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoSaveForLaterMutation, { data, loading, error }] = useAutoSaveForLaterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAutoSaveForLaterMutation(baseOptions?: Apollo.MutationHookOptions<AutoSaveForLaterMutation, AutoSaveForLaterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AutoSaveForLaterMutation, AutoSaveForLaterMutationVariables>(AutoSaveForLaterMutationDocument, options);
      }
export type AutoSaveForLaterMutationHookResult = ReturnType<typeof useAutoSaveForLaterMutation>;
export type AutoSaveForLaterMutationMutationResult = Apollo.MutationResult<AutoSaveForLaterMutation>;
export type AutoSaveForLaterMutationMutationOptions = Apollo.BaseMutationOptions<AutoSaveForLaterMutation, AutoSaveForLaterMutationVariables>;
export const CaseFormsQueryDocument = gql`
    query caseFormsQuery($id: ID!) {
  case(id: $id) {
    ...ClientPortalForms_Case
  }
}
    ${ClientPortalForms_Case}`;

/**
 * __useCaseFormsQuery__
 *
 * To run a query within a React component, call `useCaseFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseFormsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseFormsQuery(baseOptions: Apollo.QueryHookOptions<CaseFormsQuery, CaseFormsQueryVariables> & ({ variables: CaseFormsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseFormsQuery, CaseFormsQueryVariables>(CaseFormsQueryDocument, options);
      }
export function useCaseFormsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseFormsQuery, CaseFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseFormsQuery, CaseFormsQueryVariables>(CaseFormsQueryDocument, options);
        }
export function useCaseFormsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CaseFormsQuery, CaseFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CaseFormsQuery, CaseFormsQueryVariables>(CaseFormsQueryDocument, options);
        }
export type CaseFormsQueryHookResult = ReturnType<typeof useCaseFormsQuery>;
export type CaseFormsQueryLazyQueryHookResult = ReturnType<typeof useCaseFormsQueryLazyQuery>;
export type CaseFormsQuerySuspenseQueryHookResult = ReturnType<typeof useCaseFormsQuerySuspenseQuery>;
export type CaseFormsQueryQueryResult = Apollo.QueryResult<CaseFormsQuery, CaseFormsQueryVariables>;
export const CompleteFormsPublicMutationDocument = gql`
    mutation completeFormsPublicMutation($input: CaseDataInput!) {
  completeFormsPublic(input: $input) {
    ...ClientPortalContactAccessTokenFragment
  }
}
    ${ClientPortalContactAccessTokenFragment}`;
export type CompleteFormsPublicMutationMutationFn = Apollo.MutationFunction<CompleteFormsPublicMutation, CompleteFormsPublicMutationVariables>;

/**
 * __useCompleteFormsPublicMutation__
 *
 * To run a mutation, you first call `useCompleteFormsPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFormsPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFormsPublicMutation, { data, loading, error }] = useCompleteFormsPublicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteFormsPublicMutation(baseOptions?: Apollo.MutationHookOptions<CompleteFormsPublicMutation, CompleteFormsPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteFormsPublicMutation, CompleteFormsPublicMutationVariables>(CompleteFormsPublicMutationDocument, options);
      }
export type CompleteFormsPublicMutationHookResult = ReturnType<typeof useCompleteFormsPublicMutation>;
export type CompleteFormsPublicMutationMutationResult = Apollo.MutationResult<CompleteFormsPublicMutation>;
export type CompleteFormsPublicMutationMutationOptions = Apollo.BaseMutationOptions<CompleteFormsPublicMutation, CompleteFormsPublicMutationVariables>;
export const CompleteFormsMutationDocument = gql`
    mutation completeFormsMutation($input: CaseDataInput!) {
  completeForms(input: $input) {
    ...ClientPortalContactAccessTokenFragment
  }
}
    ${ClientPortalContactAccessTokenFragment}`;
export type CompleteFormsMutationMutationFn = Apollo.MutationFunction<CompleteFormsMutation, CompleteFormsMutationVariables>;

/**
 * __useCompleteFormsMutation__
 *
 * To run a mutation, you first call `useCompleteFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFormsMutation, { data, loading, error }] = useCompleteFormsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteFormsMutation(baseOptions?: Apollo.MutationHookOptions<CompleteFormsMutation, CompleteFormsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteFormsMutation, CompleteFormsMutationVariables>(CompleteFormsMutationDocument, options);
      }
export type CompleteFormsMutationHookResult = ReturnType<typeof useCompleteFormsMutation>;
export type CompleteFormsMutationMutationResult = Apollo.MutationResult<CompleteFormsMutation>;
export type CompleteFormsMutationMutationOptions = Apollo.BaseMutationOptions<CompleteFormsMutation, CompleteFormsMutationVariables>;
export const LatestClientPortalVersionFormsQueryDocument = gql`
    query latestClientPortalVersionFormsQuery {
  latestClientPortalVersion {
    ...ClientPortalForms_Version
  }
}
    ${ClientPortalForms_Version}`;

/**
 * __useLatestClientPortalVersionFormsQuery__
 *
 * To run a query within a React component, call `useLatestClientPortalVersionFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestClientPortalVersionFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestClientPortalVersionFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestClientPortalVersionFormsQuery(baseOptions?: Apollo.QueryHookOptions<LatestClientPortalVersionFormsQuery, LatestClientPortalVersionFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestClientPortalVersionFormsQuery, LatestClientPortalVersionFormsQueryVariables>(LatestClientPortalVersionFormsQueryDocument, options);
      }
export function useLatestClientPortalVersionFormsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestClientPortalVersionFormsQuery, LatestClientPortalVersionFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestClientPortalVersionFormsQuery, LatestClientPortalVersionFormsQueryVariables>(LatestClientPortalVersionFormsQueryDocument, options);
        }
export function useLatestClientPortalVersionFormsQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LatestClientPortalVersionFormsQuery, LatestClientPortalVersionFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LatestClientPortalVersionFormsQuery, LatestClientPortalVersionFormsQueryVariables>(LatestClientPortalVersionFormsQueryDocument, options);
        }
export type LatestClientPortalVersionFormsQueryHookResult = ReturnType<typeof useLatestClientPortalVersionFormsQuery>;
export type LatestClientPortalVersionFormsQueryLazyQueryHookResult = ReturnType<typeof useLatestClientPortalVersionFormsQueryLazyQuery>;
export type LatestClientPortalVersionFormsQuerySuspenseQueryHookResult = ReturnType<typeof useLatestClientPortalVersionFormsQuerySuspenseQuery>;
export type LatestClientPortalVersionFormsQueryQueryResult = Apollo.QueryResult<LatestClientPortalVersionFormsQuery, LatestClientPortalVersionFormsQueryVariables>;
export const CaseQueryDocument = gql`
    query caseQuery($id: ID!) {
  case(id: $id) {
    ...ClientPortal_Case
  }
}
    ${ClientPortal_Case}`;

/**
 * __useCaseQuery__
 *
 * To run a query within a React component, call `useCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseQuery(baseOptions: Apollo.QueryHookOptions<CaseQuery, CaseQueryVariables> & ({ variables: CaseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseQuery, CaseQueryVariables>(CaseQueryDocument, options);
      }
export function useCaseQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseQuery, CaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseQuery, CaseQueryVariables>(CaseQueryDocument, options);
        }
export function useCaseQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CaseQuery, CaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CaseQuery, CaseQueryVariables>(CaseQueryDocument, options);
        }
export type CaseQueryHookResult = ReturnType<typeof useCaseQuery>;
export type CaseQueryLazyQueryHookResult = ReturnType<typeof useCaseQueryLazyQuery>;
export type CaseQuerySuspenseQueryHookResult = ReturnType<typeof useCaseQuerySuspenseQuery>;
export type CaseQueryQueryResult = Apollo.QueryResult<CaseQuery, CaseQueryVariables>;
export const LatestClientPortalVersionQueryDocument = gql`
    query latestClientPortalVersionQuery {
  latestClientPortalVersion {
    ...ClientPortal_Version
  }
}
    ${ClientPortal_Version}`;

/**
 * __useLatestClientPortalVersionQuery__
 *
 * To run a query within a React component, call `useLatestClientPortalVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestClientPortalVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestClientPortalVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestClientPortalVersionQuery(baseOptions?: Apollo.QueryHookOptions<LatestClientPortalVersionQuery, LatestClientPortalVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestClientPortalVersionQuery, LatestClientPortalVersionQueryVariables>(LatestClientPortalVersionQueryDocument, options);
      }
export function useLatestClientPortalVersionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestClientPortalVersionQuery, LatestClientPortalVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestClientPortalVersionQuery, LatestClientPortalVersionQueryVariables>(LatestClientPortalVersionQueryDocument, options);
        }
export function useLatestClientPortalVersionQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LatestClientPortalVersionQuery, LatestClientPortalVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LatestClientPortalVersionQuery, LatestClientPortalVersionQueryVariables>(LatestClientPortalVersionQueryDocument, options);
        }
export type LatestClientPortalVersionQueryHookResult = ReturnType<typeof useLatestClientPortalVersionQuery>;
export type LatestClientPortalVersionQueryLazyQueryHookResult = ReturnType<typeof useLatestClientPortalVersionQueryLazyQuery>;
export type LatestClientPortalVersionQuerySuspenseQueryHookResult = ReturnType<typeof useLatestClientPortalVersionQuerySuspenseQuery>;
export type LatestClientPortalVersionQueryQueryResult = Apollo.QueryResult<LatestClientPortalVersionQuery, LatestClientPortalVersionQueryVariables>;