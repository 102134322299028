import { AmlHitAiAnalysisStatusEnum } from '../../../shared/models';

export const amlHitAiAnalysisDefinition = {
  [AmlHitAiAnalysisStatusEnum.true_positive]: {
    label: 'True hit',
    color: 'red.700',
    bg: 'red.50',
  },
  [AmlHitAiAnalysisStatusEnum.false_positive]: {
    label: 'False positive',
    color: 'gray.500',
    bg: 'gray.50',
  },
  [AmlHitAiAnalysisStatusEnum.manual_review]: {
    label: 'Manual review',
    color: 'orange.600',
    bg: 'orange.50',
  },
};
