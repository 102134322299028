import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import {
  AuthContext,
  useEnvironmentContext,
} from '@dotfile/frontend/shared/common';

import { useClientPortalId } from '../client-portal-id.context';
import { ContactAuthService } from './auth.service';
import { ContactAccessToken, ContactAuth, ContactAuthContext } from './type';

// Contact Auth
const useContactAuthProvider = (): ContactAuthContext => {
  const clientPortalId = useClientPortalId();
  const environment = useEnvironmentContext();

  const contactAuthService = useMemo(() => {
    // Handle custom domain for baseAPI
    // @NOTE we need auth to be on the same origin to let first-party cookies pass (refresh token)
    const isDefaultDomain = environment.baseApp === window.location.origin;
    let baseAPI = environment.baseAPI;
    if (!isDefaultDomain) {
      baseAPI = window.location.origin;
    }

    return new ContactAuthService(baseAPI, clientPortalId);
  }, [clientPortalId, environment]);

  const [auth, setAuth] = useState<ContactAuth>({
    isAuthenticated: null,
    logout: false,
    contactId: null,
    caseId: null,
  });

  // Request Magic Link
  const requestMagicLink = useCallback(
    (email: string) => contactAuthService.requestMagicLink(email),
    [contactAuthService],
  );

  const requestMagicLinkFromToken = useCallback(
    (token: string) => contactAuthService.requestMagicLinkFromToken(token),
    [contactAuthService],
  );

  // Redeem
  const redeemMagicToken = useCallback(
    async (token: string) => {
      const { contactId, caseId } =
        await contactAuthService.redeemMagicToken(token);
      setAuth({
        isAuthenticated: true,
        contactId,
        caseId,
        logout: false,
      });
      return contactId;
    },
    [contactAuthService],
  );

  // Token
  const getToken = useCallback(
    () => contactAuthService.getToken(),
    [contactAuthService],
  );
  const setAuthToken = useCallback(
    (authToken: ContactAccessToken) => {
      contactAuthService.setAuthToken(authToken);
      setAuth({
        isAuthenticated: true,
        contactId: authToken.contactId,
        caseId: authToken.caseId,
        logout: false,
      });
    },
    [contactAuthService],
  );

  // Logout
  const logout = useCallback(async () => {
    await contactAuthService.logout();
    setAuth({
      isAuthenticated: false,
      contactId: null,
      caseId: null,
      notificationToken: undefined,
      logout: true,
    });
    return true;
  }, [contactAuthService]);

  // Sync
  const sync = useCallback(async () => {
    if (!contactAuthService.checkAuth() && !auth.logout) {
      try {
        const { contactId, caseId } = await contactAuthService.silentRefresh();
        setAuth({
          isAuthenticated: true,
          contactId,
          caseId,
          logout: false,
        });
        console.debug(`🔓 Logged in as contact ${contactId}`);
      } catch (e) {
        // If errors, clear Contact and refresh without setting logout flag
        await contactAuthService.logout();
        setAuth({
          isAuthenticated: false,
          contactId: null,
          caseId: null,
          notificationToken: undefined,
          logout: false,
        });
      }
    }
  }, [contactAuthService, auth]);

  // Unlike console-app, auth is not automatically sync

  useEffect(() => {
    // Set case-id/contact-id in Datadog context when available
    // @see https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#global-context
    if (auth.caseId) {
      datadogRum.setGlobalContextProperty('case-id', auth.caseId);
    }
    if (auth.contactId) {
      datadogRum.setGlobalContextProperty('contact-id', auth.contactId);

      // Also identify the user by id only
      // @see https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#identify-user-session
      datadogRum.setUser({
        id: auth.contactId,
        // name and email omitted for privacy
      });
    }
  }, [auth.caseId, auth.contactId]);

  return {
    auth,
    sync,
    logout,
    getToken,
    setAuthToken,
    requestMagicLink,
    requestMagicLinkFromToken,
    redeemMagicToken,
  };
};

export const ContactAuthProvider: FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const auth = useContactAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
