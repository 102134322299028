import { get } from 'lodash';
import { UseFormHandleSubmit } from 'react-hook-form';

import { SUBMIT_BUTTON_DATA_ATTRIBUTE, useFormDatastore } from '../shared';
import { FormValues } from './use-business-contact-step-form';

export const useBusinessContactHandleSubmit = (
  handleSubmit: UseFormHandleSubmit<FormValues>,
  requestDelegator: boolean,
  {
    handleSaveForLater,
    onSubmitStep,
  }: {
    handleSaveForLater: () => Promise<void>;
    onSubmitStep: () => Promise<void>;
  },
) => {
  const {
    setSignatory,
    setDelegator,
    setBusinessContact,
    patchIndividualData,
  } = useFormDatastore();

  return handleSubmit(async (formValues, event) => {
    const businessContactIndex = Number(formValues.businessContactIndex);
    setBusinessContact(businessContactIndex);
    patchIndividualData({ email: formValues.email }, businessContactIndex);

    if (requestDelegator) {
      setSignatory(Number(formValues.signatoryIndex));

      // Set delegator individual
      if (
        formValues.isSignatoryLegalRepresentative === false &&
        formValues.delegatorIndex
      ) {
        setDelegator(Number(formValues.delegatorIndex));
      }
    }

    // If Next button, also call onSubmitStep for navigation, otherwise Save for later
    if (
      // Submit on ENTER key
      get(event?.nativeEvent, 'submitter', null) === null ||
      // Submit on click on Next button
      get(
        event?.nativeEvent,
        'submitter',
        document.createElement('button'),
      ).getAttribute('data-submit') === SUBMIT_BUTTON_DATA_ATTRIBUTE.next
    ) {
      await onSubmitStep();
    } else {
      handleSaveForLater();
    }
  });
};
