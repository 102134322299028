import { GridItem, Text } from '@dotfile/frontend/shared/design-system';

type LabelValueProps = {
  label: string;
  value: unknown;
};
export const LabelValue = ({ label, value }: LabelValueProps) => {
  return (
    <>
      <GridItem>
        <Text>{label}</Text>
      </GridItem>
      <GridItem>
        <Text as="pre" fontSize="sm" whiteSpace="pre-wrap">
          {value && typeof value === 'object'
            ? JSON.stringify(value)
            : `${value}`}
        </Text>
      </GridItem>
    </>
  );
};
