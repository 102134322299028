import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  ForceReviewDocumentCheckInput,
  useForceReviewDocumentCheckMutation,
} from '@dotfile/shared/data-access-client-portal';

import { useTryCompleteChecks } from '../../shared';

export const useForceReviewDocumentCheck = () => {
  const handleError = useHandleError();
  const { t } = useTranslation();
  const [runMutation, result] = useForceReviewDocumentCheckMutation();
  const [tryCompleteChecks] = useTryCompleteChecks();

  const forceReview = useCallback(
    async (input: ForceReviewDocumentCheckInput): Promise<boolean> => {
      try {
        const { data } = await runMutation({
          variables: {
            input,
          },
          // no need to refetch queries since response contains updated data
        });

        await tryCompleteChecks(data?.forceReviewDocumentCheck);

        return true;
      } catch (error) {
        handleError({
          title: t('checks.document.force_review.error', {
            defaultValue: 'Unable to force review',
            ns: 'client-portal',
          }),
          error,
        });
        return false;
      }
    },
    [runMutation, handleError, t, tryCompleteChecks],
  );

  return [forceReview, result] as const;
};
