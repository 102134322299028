export const WorkflowActionTypeEnum = {
  add_tag: 'add_tag',
  create_aml_check: 'create_aml_check',
  create_document_check: 'create_document_check',
  create_id_document_check: 'create_id_document_check',
  create_id_verification_check: 'create_id_verification_check',
  log: 'log',
  set_template: 'set_template',
  lookup: 'lookup',
  set_risk: 'set_risk',

  // Add the end of the array to be tested last when doing Object.values(WorkflowActionTypeEnum)
  exit: 'exit',
} as const;
export type WorkflowActionTypeEnum =
  (typeof WorkflowActionTypeEnum)[keyof typeof WorkflowActionTypeEnum];
