import { TFunction } from 'i18next';

import { ClientPortalChecks_CheckInterface } from '@dotfile/shared/data-access-client-portal';
import {
  CheckDataType,
  formatCheckTitle as domainFormatCheckTitle,
} from '@dotfile/shared/domain';

export const formatCheckTitle = (
  check: ClientPortalChecks_CheckInterface,
  t: TFunction,
) => {
  return domainFormatCheckTitle(
    check.type,
    check.data as CheckDataType<typeof check.type>,
    {
      t,
      ignorePrefixForDocument: true,
    },
  );
};
