import { useMemo } from 'react';

import { Extensions, mergeAttributes } from '@tiptap/core';
import { Bold } from '@tiptap/extension-bold';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Document } from '@tiptap/extension-document';
import { HardBreak } from '@tiptap/extension-hard-break';
import { Italic } from '@tiptap/extension-italic';
import { Link } from '@tiptap/extension-link';
import { ListItem } from '@tiptap/extension-list-item';
import { Mention } from '@tiptap/extension-mention';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Text } from '@tiptap/extension-text';

import { createSuggestion } from './suggestion';
import { RichTextGetSuggestionItemsFn } from './types';

type UseExtensionsParam = {
  getSuggestionItems?: RichTextGetSuggestionItemsFn;
  placeholder?: string;
  tagMap?: Partial<{
    Bold: 'strong' | 'b';
    Italic: 'em' | 'i';
  }>;
};

export const useExtensions = ({
  getSuggestionItems = () => [],
  placeholder,
  tagMap: tagMapOverride,
}: UseExtensionsParam): Extensions =>
  useMemo(() => {
    const tagMap = {
      // default
      Bold: 'b',
      Italic: 'i',
      // props
      ...tagMapOverride,
    };

    const extensions = [
      // Basic
      Document, // @see https://tiptap.dev/docs/editor/api/nodes/document
      Text, // @see https://tiptap.dev/docs/editor/api/nodes/text
      Paragraph, // @see hhttps://tiptap.dev/docs/editor/api/nodes/paragraph
      HardBreak.configure({
        // @see https://tiptap.dev/docs/editor/api/nodes/hard-break

        // Reset mark on hard break trigger by "Shift Enter" or "Control/Cmd Enter"
        // Regular break via "Enter" still keeps marks
        keepMarks: false,
      }),

      // Styling
      Bold.extend({
        renderHTML: function renderHTML({ HTMLAttributes }) {
          return [
            tagMap.Bold,
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
          ];
        },
      }), // @see https://tiptap.dev/docs/editor/api/marks/bold
      Italic.extend({
        renderHTML: function renderHTML({ HTMLAttributes }) {
          return [
            tagMap.Italic,
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
          ];
        },
      }), // @see https://tiptap.dev/docs/editor/api/marks/italic
      // @see https://tiptap.dev/docs/editor/api/nodes/bullet-list
      ListItem, // @see https://tiptap.dev/docs/editor/api/nodes/list-item
      BulletList.configure({
        // @see https://tiptap.dev/docs/editor/api/nodes/bullet-list
        keepMarks: true,
        keepAttributes: false,
      }),

      getSuggestionItems &&
        // Actions
        Mention.extend({
          // Extends the extension to override the HTML attributes logic for the user id
          addAttributes() {
            return {
              id: {
                default: null,
                parseHTML: (element) =>
                  element.getAttribute('data-id')?.replace('user:', ''),
                renderHTML: (attributes) => {
                  if (!('id' in attributes)) {
                    return {};
                  }

                  return {
                    'data-id': `user:${attributes['id']}`,
                  };
                },
              },

              label: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-label'),
                renderHTML: (attributes) => {
                  if (!attributes['label']) {
                    return {};
                  }

                  return {
                    'data-label': attributes['label'],
                  };
                },
              },
            };
          },
        }).configure({
          // @see https://tiptap.dev/docs/editor/api/nodes/mention

          // keep in sync with backend mention formatting in
          // libs/backend/modules/case-management/src/lib/note/note-validation-helper.service.ts

          renderHTML({ options, node }) {
            return `${options.suggestion.char}${node.attrs['label']}`;
          },
          suggestion: createSuggestion(getSuggestionItems),
        }),
      Link.configure({
        // @see https://tiptap.dev/docs/editor/api/marks/link
        autolink: true,
        linkOnPaste: true,

        // After some test, looks like links open only when not editable with openOnClick=false
        // When editable, opening on click is weird
        // @see https://github.com/ueberdosis/tiptap/pull/3312 (not merged)
        openOnClick: false,

        // @TODO - E-3446 - RichText improvements
        // Format links with an icon for external links like in the ExternalLink component
      }),
    ].filter(Boolean) as Extensions;

    if (placeholder) {
      extensions.push(
        Placeholder.configure({
          // @see https://tiptap.dev/docs/editor/api/extensions/placeholder
          placeholder,
          showOnlyWhenEditable: true,
        }),
      );
    }

    return extensions;
  }, [getSuggestionItems, placeholder, tagMapOverride]);
