import { AmlListTypeEnum } from '../../../shared/models';

export const AML_DEFAULT_IS_MONITORED = true;

export const AML_DEFAULT_SETTINGS_LIST_TYPES = [
  AmlListTypeEnum.sanction,
  AmlListTypeEnum.warning,
  AmlListTypeEnum.fitness_probity,
  AmlListTypeEnum.pep,
];

export const AML_DEFAULT_SETTINGS_FUZZINESS = 0.6;

export const AML_DEFAULT_SETTINGS_ENABLE_ENTITY_FILTER = true;

/**
 * Maximum hits that can be review in one batch
 */
export const AML_REVIEW_MAX_HITS = 100;
