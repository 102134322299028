import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import { NumberInput } from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/data-access-client-portal';
import {
  numericCustomPropertyDefinition,
  PropertyNumericFormatEnum,
} from '@dotfile/shared/domain';

import { FieldProps } from './types';

export const FieldNumeric = ({
  field,
  property,

  helper,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.numeric>) => {
  const { control } = useFormContext();

  const format = property.settings?.format ?? PropertyNumericFormatEnum.number;
  const currencyCode = property.settings?.currencyCode ?? null;
  const min = property.settings?.min ?? null;
  const max = property.settings?.max ?? null;

  const { t } = useTranslation();
  const minMaxHelper =
    typeof min === 'number' && typeof max === 'number'
      ? t('forms.field.numeric.helper.between', {
          min,
          max,
          defaultValue: `Must be between ${min} and ${max}`,
          ns: 'client-portal',
        })
      : typeof min === 'number'
        ? t('forms.field.numeric.helper.min', {
            min,
            defaultValue: `Must be greater than ${min}`,
            ns: 'client-portal',
          })
        : typeof max === 'number'
          ? t('forms.field.numeric.helper.max', {
              max,
              defaultValue: `Must be lower than ${max}`,
              ns: 'client-portal',
            })
          : null;

  return (
    <GroupController
      control={control}
      render={(f) => (
        <NumberInput
          format={format}
          currencyCode={currencyCode}
          min={min ?? numericCustomPropertyDefinition.minMin}
          max={max ?? numericCustomPropertyDefinition.maxMax}
          {...f}
        />
      )}
      helper={
        helper || minMaxHelper ? (
          <>
            {helper}
            {minMaxHelper && <p>{minMaxHelper}</p>}
          </>
        ) : (
          minMaxHelper
        )
      }
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
