import { Plus } from 'lucide-react';

import {
  Avatar,
  forwardRef,
  HStack,
  Icon,
  StackProps,
  Text,
} from '@dotfile/frontend/shared/design-system';
import { fullNameHelper } from '@dotfile/shared/domain';

import { FormDatastoreIndividual } from '../shared';

export type IndividualItemProps = {
  individual: Partial<FormDatastoreIndividual> | null | undefined;
  addLabel?: string;
} & StackProps;

export const IndividualItem = forwardRef(
  ({ individual, addLabel, ...props }: IndividualItemProps, ref) => {
    return (
      <HStack py="1" {...props} ref={ref}>
        {individual ? (
          <>
            <Avatar name={fullNameHelper(individual)} />
            <Text color="black" maxWidth="200px" noOfLines={1}>
              {fullNameHelper(individual)}
            </Text>
          </>
        ) : addLabel ? (
          <>
            <Icon as={Plus} />
            <Text color="black" maxWidth="200px" noOfLines={1}>
              {addLabel}
            </Text>
          </>
        ) : null}
      </HStack>
    );
  },
);
