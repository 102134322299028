import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from '@dotfile/shared/domain';

import { getLocalizedValidation } from '../shared/utils/validation/localize-validation';

export type FormValues = {
  businessContactIndex: string;
  email: string;

  isBusinessContactTheAuthorizedSignatory: boolean | null;
  signatoryIndex: string;
  isSignatoryLegalRepresentative: boolean;

  delegatorIndex: string;
};

export const useBusinessContactStepForm = (
  requestDelegator: boolean,
  defaultValues: FormValues,
) => {
  const { t } = useTranslation();

  const resolver = useMemo(() => {
    const localizedValidation = getLocalizedValidation(t);

    return yupResolver(
      yup
        .object()
        .shape({
          businessContactIndex: yup
            .string()
            .required(localizedValidation.required),
          email: yup
            .string()
            .email(localizedValidation.email.invalid)
            .required(localizedValidation.required),

          signatoryIndex: requestDelegator
            ? yup.string().required(localizedValidation.required)
            : yup.string(),
          isBusinessContactTheAuthorizedSignatory: requestDelegator
            ? yup
                .boolean()
                .typeError(localizedValidation.required)
                .required(localizedValidation.required)
            : yup.boolean().nullable(),
          isSignatoryLegalRepresentative: yup.boolean(),
          delegatorIndex: requestDelegator
            ? yup.string().when('isSignatoryLegalRepresentative', {
                is: false,
                then: (schema) => schema.required(localizedValidation.required),
              })
            : yup.string(),
        })
        .required(),
    );
  }, [requestDelegator, t]);

  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
    resolver,
  });

  return methods;
};
