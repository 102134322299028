import { CompanyDataVendorEnum } from '../enum/company-data-vendor.enum';

export const COMPANY_COUNTRY_VENDOR_NONE = 'none' as const;

export interface CompanyCountryModel {
  code: string;

  name: string;
  localName: string;
  emoji: string;
  emojiCode: string;

  vendor: CompanyDataVendorEnum | typeof COMPANY_COUNTRY_VENDOR_NONE;
}
