import { ComponentStyleConfig } from '@chakra-ui/theme';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/accordion.ts
// for default ChakraUI theme
const variantUnstyled = {
  container: {
    borderTopWidth: '0',
    _last: {
      borderBottomWidth: '0',
    },
  },
};

export const AccordionTheme: ComponentStyleConfig = {
  parts: [],
  variants: {
    unstyled: variantUnstyled,
  },
};
