import {
  CompanyClassificationModel,
  CompanyClassificationTypeEnum,
} from '../../models';

const naceClassifications: CompanyClassificationModel[] = [
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'A',
    description: 'AGRICULTURE, FORESTRY AND FISHING',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01',
    description:
      'Crop and animal production, hunting and related service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.1',
    description: 'Growing of non-perennial crops',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.11',
    description:
      'Growing of cereals (except rice), leguminous crops and oil seeds',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.12',
    description: 'Growing of rice',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.13',
    description: 'Growing of vegetables and melons, roots and tubers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.14',
    description: 'Growing of sugar cane',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.15',
    description: 'Growing of tobacco',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.16',
    description: 'Growing of fibre crops',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.19',
    description: 'Growing of other non-perennial crops',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.2',
    description: 'Growing of perennial crops',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.21',
    description: 'Growing of grapes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.22',
    description: 'Growing of tropical and subtropical fruits',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.23',
    description: 'Growing of citrus fruits',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.24',
    description: 'Growing of pome fruits and stone fruits',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.25',
    description: 'Growing of other tree and bush fruits and nuts',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.26',
    description: 'Growing of oleaginous fruits',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.27',
    description: 'Growing of beverage crops',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.28',
    description: 'Growing of spices, aromatic, drug and pharmaceutical crops',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.29',
    description: 'Growing of other perennial crops',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.3',
    description: 'Plant propagation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.30',
    description: 'Plant propagation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.4',
    description: 'Animal production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.41',
    description: 'Raising of dairy cattle',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.42',
    description: 'Raising of other cattle and buffaloes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.43',
    description: 'Raising of horses and other equines',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.44',
    description: 'Raising of camels and camelids',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.45',
    description: 'Raising of sheep and goats',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.46',
    description: 'Raising of swine/pigs',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.47',
    description: 'Raising of poultry',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.49',
    description: 'Raising of other animals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.5',
    description: 'Mixed farming',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.50',
    description: 'Mixed farming',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.6',
    description:
      'Support activities to agriculture and post-harvest crop activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.61',
    description: 'Support activities for crop production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.62',
    description: 'Support activities for animal production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.63',
    description: 'Post-harvest crop activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.64',
    description: 'Seed processing for propagation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.7',
    description: 'Hunting, trapping and related service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '01.70',
    description: 'Hunting, trapping and related service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02',
    description: 'Forestry and logging',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02.1',
    description: 'Silviculture and other forestry activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02.10',
    description: 'Silviculture and other forestry activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02.2',
    description: 'Logging',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02.20',
    description: 'Logging',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02.3',
    description: 'Gathering of wild growing non-wood products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02.30',
    description: 'Gathering of wild growing non-wood products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02.4',
    description: 'Support services to forestry',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '02.40',
    description: 'Support services to forestry',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '03',
    description: 'Fishing and aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '03.1',
    description: 'Fishing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '03.11',
    description: 'Marine fishing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '03.12',
    description: 'Freshwater fishing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '03.2',
    description: 'Aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '03.21',
    description: 'Marine aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '03.22',
    description: 'Freshwater aquaculture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'B',
    description: 'MINING AND QUARRYING',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '05',
    description: 'Mining of coal and lignite',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '05.1',
    description: 'Mining of hard coal',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '05.10',
    description: 'Mining of hard coal',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '05.2',
    description: 'Mining of lignite',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '05.20',
    description: 'Mining of lignite',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '06',
    description: 'Extraction of crude petroleum and natural gas',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '06.1',
    description: 'Extraction of crude petroleum',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '06.10',
    description: 'Extraction of crude petroleum',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '06.2',
    description: 'Extraction of natural gas',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '06.20',
    description: 'Extraction of natural gas',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '07',
    description: 'Mining of metal ores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '07.1',
    description: 'Mining of iron ores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '07.10',
    description: 'Mining of iron ores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '07.2',
    description: 'Mining of non-ferrous metal ores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '07.21',
    description: 'Mining of uranium and thorium ores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '07.29',
    description: 'Mining of other non-ferrous metal ores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08',
    description: 'Other mining and quarrying',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08.1',
    description: 'Quarrying of stone, sand and clay',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08.11',
    description:
      'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08.12',
    description:
      'Operation of gravel and sand pits; mining of clays and kaolin',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08.9',
    description: 'Mining and quarrying n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08.91',
    description: 'Mining of chemical and fertiliser minerals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08.92',
    description: 'Extraction of peat',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08.93',
    description: 'Extraction of salt',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '08.99',
    description: 'Other mining and quarrying n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '09',
    description: 'Mining support service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '09.1',
    description: 'Support activities for petroleum and natural gas extraction',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '09.10',
    description: 'Support activities for petroleum and natural gas extraction',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '09.9',
    description: 'Support activities for other mining and quarrying',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '09.90',
    description: 'Support activities for other mining and quarrying',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'C',
    description: 'MANUFACTURING',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10',
    description: 'Manufacture of food products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.1',
    description:
      'Processing and preserving of meat and production of meat products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.11',
    description: 'Processing and preserving of meat',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.12',
    description: 'Processing and preserving of poultry meat',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.13',
    description: 'Production of meat and poultry meat products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.2',
    description: 'Processing and preserving of fish, crustaceans and molluscs',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.20',
    description: 'Processing and preserving of fish, crustaceans and molluscs',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.3',
    description: 'Processing and preserving of fruit and vegetables',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.31',
    description: 'Processing and preserving of potatoes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.32',
    description: 'Manufacture of fruit and vegetable juice',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.39',
    description: 'Other processing and preserving of fruit and vegetables',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.4',
    description: 'Manufacture of vegetable and animal oils and fats',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.41',
    description: 'Manufacture of oils and fats',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.42',
    description: 'Manufacture of margarine and similar edible fats',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.5',
    description: 'Manufacture of dairy products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.51',
    description: 'Operation of dairies and cheese making',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.52',
    description: 'Manufacture of ice cream',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.6',
    description:
      'Manufacture of grain mill products, starches and starch products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.61',
    description: 'Manufacture of grain mill products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.62',
    description: 'Manufacture of starches and starch products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.7',
    description: 'Manufacture of bakery and farinaceous products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.71',
    description:
      'Manufacture of bread; manufacture of fresh pastry goods and cakes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.72',
    description:
      'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.73',
    description:
      'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.8',
    description: 'Manufacture of other food products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.81',
    description: 'Manufacture of sugar',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.82',
    description: 'Manufacture of cocoa, chocolate and sugar confectionery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.83',
    description: 'Processing of tea and coffee',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.84',
    description: 'Manufacture of condiments and seasonings',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.85',
    description: 'Manufacture of prepared meals and dishes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.86',
    description:
      'Manufacture of homogenised food preparations and dietetic food',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.89',
    description: 'Manufacture of other food products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.9',
    description: 'Manufacture of prepared animal feeds',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.91',
    description: 'Manufacture of prepared feeds for farm animals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '10.92',
    description: 'Manufacture of prepared pet foods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11',
    description: 'Manufacture of beverages',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11.0',
    description: 'Manufacture of beverages',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11.01',
    description: 'Distilling, rectifying and blending of spirits',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11.02',
    description: 'Manufacture of wine from grape',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11.03',
    description: 'Manufacture of cider and other fruit wines',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11.04',
    description: 'Manufacture of other non-distilled fermented beverages',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11.05',
    description: 'Manufacture of beer',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11.06',
    description: 'Manufacture of malt',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '11.07',
    description:
      'Manufacture of soft drinks; production of mineral waters and other bottled waters',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '12',
    description: 'Manufacture of tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '12.0',
    description: 'Manufacture of tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '12.00',
    description: 'Manufacture of tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13',
    description: 'Manufacture of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.1',
    description: 'Preparation and spinning of textile fibres',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.10',
    description: 'Preparation and spinning of textile fibres',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.2',
    description: 'Weaving of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.20',
    description: 'Weaving of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.3',
    description: 'Finishing of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.30',
    description: 'Finishing of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.9',
    description: 'Manufacture of other textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.91',
    description: 'Manufacture of knitted and crocheted fabrics',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.92',
    description: 'Manufacture of made-up textile articles, except apparel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.93',
    description: 'Manufacture of carpets and rugs',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.94',
    description: 'Manufacture of cordage, rope, twine and netting',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.95',
    description:
      'Manufacture of non-wovens and articles made from non-wovens, except apparel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.96',
    description: 'Manufacture of other technical and industrial textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '13.99',
    description: 'Manufacture of other textiles n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14',
    description: 'Manufacture of wearing apparel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.1',
    description: 'Manufacture of wearing apparel, except fur apparel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.11',
    description: 'Manufacture of leather clothes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.12',
    description: 'Manufacture of workwear',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.13',
    description: 'Manufacture of other outerwear',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.14',
    description: 'Manufacture of underwear',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.19',
    description: 'Manufacture of other wearing apparel and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.2',
    description: 'Manufacture of articles of fur',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.20',
    description: 'Manufacture of articles of fur',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.3',
    description: 'Manufacture of knitted and crocheted apparel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.31',
    description: 'Manufacture of knitted and crocheted hosiery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '14.39',
    description: 'Manufacture of other knitted and crocheted apparel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '15',
    description: 'Manufacture of leather and related products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '15.1',
    description:
      'Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '15.11',
    description: 'Tanning and dressing of leather; dressing and dyeing of fur',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '15.12',
    description:
      'Manufacture of luggage, handbags and the like, saddlery and harness',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '15.2',
    description: 'Manufacture of footwear',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '15.20',
    description: 'Manufacture of footwear',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16',
    description:
      'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16.1',
    description: 'Sawmilling and planing of wood',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16.10',
    description: 'Sawmilling and planing of wood',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16.2',
    description:
      'Manufacture of products of wood, cork, straw and plaiting materials',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16.21',
    description: 'Manufacture of veneer sheets and wood-based panels',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16.22',
    description: 'Manufacture of assembled parquet floors',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16.23',
    description: "Manufacture of other builders' carpentry and joinery",
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16.24',
    description: 'Manufacture of wooden containers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '16.29',
    description:
      'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17',
    description: 'Manufacture of paper and paper products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.1',
    description: 'Manufacture of pulp, paper and paperboard',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.11',
    description: 'Manufacture of pulp',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.12',
    description: 'Manufacture of paper and paperboard',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.2',
    description: 'Manufacture of articles of paper and paperboard',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.21',
    description:
      'Manufacture of corrugated paper and paperboard and of containers of paper and paperboard',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.22',
    description:
      'Manufacture of household and sanitary goods and of toilet requisites',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.23',
    description: 'Manufacture of paper stationery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.24',
    description: 'Manufacture of wallpaper',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '17.29',
    description: 'Manufacture of other articles of paper and paperboard',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '18',
    description: 'Printing and reproduction of recorded media',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '18.1',
    description: 'Printing and service activities related to printing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '18.11',
    description: 'Printing of newspapers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '18.12',
    description: 'Other printing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '18.13',
    description: 'Pre-press and pre-media services',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '18.14',
    description: 'Binding and related services',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '18.2',
    description: 'Reproduction of recorded media',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '18.20',
    description: 'Reproduction of recorded media',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '19',
    description: 'Manufacture of coke and refined petroleum products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '19.1',
    description: 'Manufacture of coke oven products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '19.10',
    description: 'Manufacture of coke oven products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '19.2',
    description: 'Manufacture of refined petroleum products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '19.20',
    description: 'Manufacture of refined petroleum products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20',
    description: 'Manufacture of chemicals and chemical products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.1',
    description:
      'Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.11',
    description: 'Manufacture of industrial gases',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.12',
    description: 'Manufacture of dyes and pigments',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.13',
    description: 'Manufacture of other inorganic basic chemicals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.14',
    description: 'Manufacture of other organic basic chemicals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.15',
    description: 'Manufacture of fertilisers and nitrogen compounds',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.16',
    description: 'Manufacture of plastics in primary forms',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.17',
    description: 'Manufacture of synthetic rubber in primary forms',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.2',
    description: 'Manufacture of pesticides and other agrochemical products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.20',
    description: 'Manufacture of pesticides and other agrochemical products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.3',
    description:
      'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.30',
    description:
      'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.4',
    description:
      'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.41',
    description:
      'Manufacture of soap and detergents, cleaning and polishing preparations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.42',
    description: 'Manufacture of perfumes and toilet preparations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.5',
    description: 'Manufacture of other chemical products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.51',
    description: 'Manufacture of explosives',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.52',
    description: 'Manufacture of glues',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.53',
    description: 'Manufacture of essential oils',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.59',
    description: 'Manufacture of other chemical products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.6',
    description: 'Manufacture of man-made fibres',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '20.60',
    description: 'Manufacture of man-made fibres',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '21',
    description:
      'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '21.1',
    description: 'Manufacture of basic pharmaceutical products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '21.10',
    description: 'Manufacture of basic pharmaceutical products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '21.2',
    description: 'Manufacture of pharmaceutical preparations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '21.20',
    description: 'Manufacture of pharmaceutical preparations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22',
    description: 'Manufacture of rubber and plastic products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22.1',
    description: 'Manufacture of rubber products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22.11',
    description:
      'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22.19',
    description: 'Manufacture of other rubber products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22.2',
    description: 'Manufacture of plastic products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22.21',
    description: 'Manufacture of plastic plates, sheets, tubes and profiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22.22',
    description: 'Manufacture of plastic packing goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22.23',
    description: 'Manufacture of builders’ ware of plastic',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '22.29',
    description: 'Manufacture of other plastic products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23',
    description: 'Manufacture of other non-metallic mineral products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.1',
    description: 'Manufacture of glass and glass products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.11',
    description: 'Manufacture of flat glass',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.12',
    description: 'Shaping and processing of flat glass',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.13',
    description: 'Manufacture of hollow glass',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.14',
    description: 'Manufacture of glass fibres',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.19',
    description:
      'Manufacture and processing of other glass, including technical glassware',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.2',
    description: 'Manufacture of refractory products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.20',
    description: 'Manufacture of refractory products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.3',
    description: 'Manufacture of clay building materials',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.31',
    description: 'Manufacture of ceramic tiles and flags',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.32',
    description:
      'Manufacture of bricks, tiles and construction products, in baked clay',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.4',
    description: 'Manufacture of other porcelain and ceramic products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.41',
    description: 'Manufacture of ceramic household and ornamental articles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.42',
    description: 'Manufacture of ceramic sanitary fixtures',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.43',
    description: 'Manufacture of ceramic insulators and insulating fittings',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.44',
    description: 'Manufacture of other technical ceramic products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.49',
    description: 'Manufacture of other ceramic products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.5',
    description: 'Manufacture of cement, lime and plaster',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.51',
    description: 'Manufacture of cement',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.52',
    description: 'Manufacture of lime and plaster',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.6',
    description: 'Manufacture of articles of concrete, cement and plaster',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.61',
    description: 'Manufacture of concrete products for construction purposes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.62',
    description: 'Manufacture of plaster products for construction purposes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.63',
    description: 'Manufacture of ready-mixed concrete',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.64',
    description: 'Manufacture of mortars',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.65',
    description: 'Manufacture of fibre cement',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.69',
    description:
      'Manufacture of other articles of concrete, plaster and cement',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.7',
    description: 'Cutting, shaping and finishing of stone',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.70',
    description: 'Cutting, shaping and finishing of stone',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.9',
    description:
      'Manufacture of abrasive products and non-metallic mineral products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.91',
    description: 'Production of abrasive products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '23.99',
    description: 'Manufacture of other non-metallic mineral products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24',
    description: 'Manufacture of basic metals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.1',
    description: 'Manufacture of basic iron and steel and of ferro-alloys',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.10',
    description: 'Manufacture of basic iron and steel and of ferro-alloys',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.2',
    description:
      'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.20',
    description:
      'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.3',
    description: 'Manufacture of other products of first processing of steel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.31',
    description: 'Cold drawing of bars',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.32',
    description: 'Cold rolling of narrow strip',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.33',
    description: 'Cold forming or folding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.34',
    description: 'Cold drawing of wire',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.4',
    description: 'Manufacture of basic precious and other non-ferrous metals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.41',
    description: 'Precious metals production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.42',
    description: 'Aluminium production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.43',
    description: 'Lead, zinc and tin production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.44',
    description: 'Copper production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.45',
    description: 'Other non-ferrous metal production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.46',
    description: 'Processing of nuclear fuel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.5',
    description: 'Casting of metals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.51',
    description: 'Casting of iron',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.52',
    description: 'Casting of steel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.53',
    description: 'Casting of light metals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '24.54',
    description: 'Casting of other non-ferrous metals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25',
    description:
      'Manufacture of fabricated metal products, except machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.1',
    description: 'Manufacture of structural metal products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.11',
    description: 'Manufacture of metal structures and parts of structures',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.12',
    description: 'Manufacture of doors and windows of metal',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.2',
    description: 'Manufacture of tanks, reservoirs and containers of metal',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.21',
    description: 'Manufacture of central heating radiators and boilers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.29',
    description:
      'Manufacture of other tanks, reservoirs and containers of metal',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.3',
    description:
      'Manufacture of steam generators, except central heating hot water boilers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.30',
    description:
      'Manufacture of steam generators, except central heating hot water boilers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.4',
    description: 'Manufacture of weapons and ammunition',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.40',
    description: 'Manufacture of weapons and ammunition',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.5',
    description:
      'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.50',
    description:
      'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.6',
    description: 'Treatment and coating of metals; machining',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.61',
    description: 'Treatment and coating of metals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.62',
    description: 'Machining',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.7',
    description: 'Manufacture of cutlery, tools and general hardware',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.71',
    description: 'Manufacture of cutlery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.72',
    description: 'Manufacture of locks and hinges',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.73',
    description: 'Manufacture of tools',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.9',
    description: 'Manufacture of other fabricated metal products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.91',
    description: 'Manufacture of steel drums and similar containers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.92',
    description: 'Manufacture of light metal packaging',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.93',
    description: 'Manufacture of wire products, chain and springs',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.94',
    description: 'Manufacture of fasteners and screw machine products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '25.99',
    description: 'Manufacture of other fabricated metal products n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26',
    description: 'Manufacture of computer, electronic and optical products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.1',
    description: 'Manufacture of electronic components and boards',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.11',
    description: 'Manufacture of electronic components',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.12',
    description: 'Manufacture of loaded electronic boards',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.2',
    description: 'Manufacture of computers and peripheral equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.20',
    description: 'Manufacture of computers and peripheral equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.3',
    description: 'Manufacture of communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.30',
    description: 'Manufacture of communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.4',
    description: 'Manufacture of consumer electronics',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.40',
    description: 'Manufacture of consumer electronics',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.5',
    description:
      'Manufacture of instruments and appliances for measuring, testing and navigation; watches and clocks',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.51',
    description:
      'Manufacture of instruments and appliances for measuring, testing and navigation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.52',
    description: 'Manufacture of watches and clocks',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.6',
    description:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.60',
    description:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.7',
    description:
      'Manufacture of optical instruments and photographic equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.70',
    description:
      'Manufacture of optical instruments and photographic equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.8',
    description: 'Manufacture of magnetic and optical media',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '26.80',
    description: 'Manufacture of magnetic and optical media',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27',
    description: 'Manufacture of electrical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.1',
    description:
      'Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.11',
    description: 'Manufacture of electric motors, generators and transformers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.12',
    description:
      'Manufacture of electricity distribution and control apparatus',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.2',
    description: 'Manufacture of batteries and accumulators',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.20',
    description: 'Manufacture of batteries and accumulators',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.3',
    description: 'Manufacture of wiring and wiring devices',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.31',
    description: 'Manufacture of fibre optic cables',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.32',
    description:
      'Manufacture of other electronic and electric wires and cables',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.33',
    description: 'Manufacture of wiring devices',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.4',
    description: 'Manufacture of electric lighting equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.40',
    description: 'Manufacture of electric lighting equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.5',
    description: 'Manufacture of domestic appliances',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.51',
    description: 'Manufacture of electric domestic appliances',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.52',
    description: 'Manufacture of non-electric domestic appliances',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.9',
    description: 'Manufacture of other electrical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '27.90',
    description: 'Manufacture of other electrical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28',
    description: 'Manufacture of machinery and equipment n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.1',
    description: 'Manufacture of general-purpose machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.11',
    description:
      'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.12',
    description: 'Manufacture of fluid power equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.13',
    description: 'Manufacture of other pumps and compressors',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.14',
    description: 'Manufacture of other taps and valves',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.15',
    description: 'Manufacture of bearings, gears, gearing and driving elements',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.2',
    description: 'Manufacture of other general-purpose machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.21',
    description: 'Manufacture of ovens, furnaces and furnace burners',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.22',
    description: 'Manufacture of lifting and handling equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.23',
    description:
      'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.24',
    description: 'Manufacture of power-driven hand tools',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.25',
    description:
      'Manufacture of non-domestic cooling and ventilation equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.29',
    description: 'Manufacture of other general-purpose machinery n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.3',
    description: 'Manufacture of agricultural and forestry machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.30',
    description: 'Manufacture of agricultural and forestry machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.4',
    description: 'Manufacture of metal forming machinery and machine tools',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.41',
    description: 'Manufacture of metal forming machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.49',
    description: 'Manufacture of other machine tools',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.9',
    description: 'Manufacture of other special-purpose machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.91',
    description: 'Manufacture of machinery for metallurgy',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.92',
    description:
      'Manufacture of machinery for mining, quarrying and construction',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.93',
    description:
      'Manufacture of machinery for food, beverage and tobacco processing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.94',
    description:
      'Manufacture of machinery for textile, apparel and leather production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.95',
    description: 'Manufacture of machinery for paper and paperboard production',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.96',
    description: 'Manufacture of plastics and rubber machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '28.99',
    description: 'Manufacture of other special-purpose machinery n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '29',
    description: 'Manufacture of motor vehicles, trailers and semi-trailers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '29.1',
    description: 'Manufacture of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '29.10',
    description: 'Manufacture of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '29.2',
    description:
      'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '29.20',
    description:
      'Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '29.3',
    description: 'Manufacture of parts and accessories for motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '29.31',
    description:
      'Manufacture of electrical and electronic equipment for motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '29.32',
    description:
      'Manufacture of other parts and accessories for motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30',
    description: 'Manufacture of other transport equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.1',
    description: 'Building of ships and boats',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.11',
    description: 'Building of ships and floating structures',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.12',
    description: 'Building of pleasure and sporting boats',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.2',
    description: 'Manufacture of railway locomotives and rolling stock',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.20',
    description: 'Manufacture of railway locomotives and rolling stock',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.3',
    description: 'Manufacture of air and spacecraft and related machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.30',
    description: 'Manufacture of air and spacecraft and related machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.4',
    description: 'Manufacture of military fighting vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.40',
    description: 'Manufacture of military fighting vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.9',
    description: 'Manufacture of transport equipment n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.91',
    description: 'Manufacture of motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.92',
    description: 'Manufacture of bicycles and invalid carriages',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '30.99',
    description: 'Manufacture of other transport equipment n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '31',
    description: 'Manufacture of furniture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '31.0',
    description: 'Manufacture of furniture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '31.01',
    description: 'Manufacture of office and shop furniture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '31.02',
    description: 'Manufacture of kitchen furniture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '31.03',
    description: 'Manufacture of mattresses',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '31.09',
    description: 'Manufacture of other furniture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32',
    description: 'Other manufacturing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.1',
    description: 'Manufacture of jewellery, bijouterie and related articles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.11',
    description: 'Striking of coins',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.12',
    description: 'Manufacture of jewellery and related articles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.13',
    description: 'Manufacture of imitation jewellery and related articles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.2',
    description: 'Manufacture of musical instruments',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.20',
    description: 'Manufacture of musical instruments',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.3',
    description: 'Manufacture of sports goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.30',
    description: 'Manufacture of sports goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.4',
    description: 'Manufacture of games and toys',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.40',
    description: 'Manufacture of games and toys',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.5',
    description: 'Manufacture of medical and dental instruments and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.50',
    description: 'Manufacture of medical and dental instruments and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.9',
    description: 'Manufacturing n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.91',
    description: 'Manufacture of brooms and brushes',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '32.99',
    description: 'Other manufacturing n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33',
    description: 'Repair and installation of machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.1',
    description: 'Repair of fabricated metal products, machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.11',
    description: 'Repair of fabricated metal products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.12',
    description: 'Repair of machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.13',
    description: 'Repair of electronic and optical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.14',
    description: 'Repair of electrical equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.15',
    description: 'Repair and maintenance of ships and boats',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.16',
    description: 'Repair and maintenance of aircraft and spacecraft',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.17',
    description: 'Repair and maintenance of other transport equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.19',
    description: 'Repair of other equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.2',
    description: 'Installation of industrial machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '33.20',
    description: 'Installation of industrial machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'D',
    description: 'ELECTRICITY, GAS, STEAM AND AIR CONDITIONING SUPPLY',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35',
    description: 'Electricity, gas, steam and air conditioning supply',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.1',
    description: 'Electric power generation, transmission and distribution',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.11',
    description: 'Production of electricity',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.12',
    description: 'Transmission of electricity',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.13',
    description: 'Distribution of electricity',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.14',
    description: 'Trade of electricity',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.2',
    description:
      'Manufacture of gas; distribution of gaseous fuels through mains',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.21',
    description: 'Manufacture of gas',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.22',
    description: 'Distribution of gaseous fuels through mains',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.23',
    description: 'Trade of gas through mains',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.3',
    description: 'Steam and air conditioning supply',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '35.30',
    description: 'Steam and air conditioning supply',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'E',
    description:
      'WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '36',
    description: 'Water collection, treatment and supply',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '36.0',
    description: 'Water collection, treatment and supply',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '36.00',
    description: 'Water collection, treatment and supply',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '37',
    description: 'Sewerage',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '37.0',
    description: 'Sewerage',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '37.00',
    description: 'Sewerage',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38',
    description:
      'Waste collection, treatment and disposal activities; materials recovery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.1',
    description: 'Waste collection',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.11',
    description: 'Collection of non-hazardous waste',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.12',
    description: 'Collection of hazardous waste',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.2',
    description: 'Waste treatment and disposal',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.21',
    description: 'Treatment and disposal of non-hazardous waste',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.22',
    description: 'Treatment and disposal of hazardous waste',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.3',
    description: 'Materials recovery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.31',
    description: 'Dismantling of wrecks',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '38.32',
    description: 'Recovery of sorted materials',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '39',
    description: 'Remediation activities and other waste management services',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '39.0',
    description: 'Remediation activities and other waste management services',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '39.00',
    description: 'Remediation activities and other waste management services',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'F',
    description: 'CONSTRUCTION',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '41',
    description: 'Construction of buildings',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '41.1',
    description: 'Development of building projects',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '41.10',
    description: 'Development of building projects',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '41.2',
    description: 'Construction of residential and non-residential buildings',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '41.20',
    description: 'Construction of residential and non-residential buildings',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42',
    description: 'Civil engineering',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.1',
    description: 'Construction of roads and railways',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.11',
    description: 'Construction of roads and motorways',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.12',
    description: 'Construction of railways and underground railways',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.13',
    description: 'Construction of bridges and tunnels',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.2',
    description: 'Construction of utility projects',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.21',
    description: 'Construction of utility projects for fluids',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.22',
    description:
      'Construction of utility projects for electricity and telecommunications',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.9',
    description: 'Construction of other civil engineering projects',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.91',
    description: 'Construction of water projects',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '42.99',
    description: 'Construction of other civil engineering projects n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43',
    description: 'Specialised construction activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.1',
    description: 'Demolition and site preparation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.11',
    description: 'Demolition',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.12',
    description: 'Site preparation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.13',
    description: 'Test drilling and boring',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.2',
    description:
      'Electrical, plumbing and other construction installation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.21',
    description: 'Electrical installation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.22',
    description: 'Plumbing, heat and air-conditioning installation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.29',
    description: 'Other construction installation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.3',
    description: 'Building completion and finishing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.31',
    description: 'Plastering',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.32',
    description: 'Joinery installation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.33',
    description: 'Floor and wall covering',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.34',
    description: 'Painting and glazing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.39',
    description: 'Other building completion and finishing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.9',
    description: 'Other specialised construction activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.91',
    description: 'Roofing activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '43.99',
    description: 'Other specialised construction activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'G',
    description:
      'WHOLESALE AND RETAIL TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45',
    description:
      'Wholesale and retail trade and repair of motor vehicles and motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.1',
    description: 'Sale of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.11',
    description: 'Sale of cars and light motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.19',
    description: 'Sale of other motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.2',
    description: 'Maintenance and repair of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.20',
    description: 'Maintenance and repair of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.3',
    description: 'Sale of motor vehicle parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.31',
    description: 'Wholesale trade of motor vehicle parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.32',
    description: 'Retail trade of motor vehicle parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.4',
    description:
      'Sale, maintenance and repair of motorcycles and related parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '45.40',
    description:
      'Sale, maintenance and repair of motorcycles and related parts and accessories',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46',
    description: 'Wholesale trade, except of motor vehicles and motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.1',
    description: 'Wholesale on a fee or contract basis',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.11',
    description:
      'Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.12',
    description:
      'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.13',
    description: 'Agents involved in the sale of timber and building materials',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.14',
    description:
      'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.15',
    description:
      'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.16',
    description:
      'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.17',
    description: 'Agents involved in the sale of food, beverages and tobacco',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.18',
    description: 'Agents specialised in the sale of other particular products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.19',
    description: 'Agents involved in the sale of a variety of goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.2',
    description: 'Wholesale of agricultural raw materials and live animals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.21',
    description:
      'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.22',
    description: 'Wholesale of flowers and plants',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.23',
    description: 'Wholesale of live animals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.24',
    description: 'Wholesale of hides, skins and leather',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.3',
    description: 'Wholesale of food, beverages and tobacco',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.31',
    description: 'Wholesale of fruit and vegetables',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.32',
    description: 'Wholesale of meat and meat products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.33',
    description: 'Wholesale of dairy products, eggs and edible oils and fats',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.34',
    description: 'Wholesale of beverages',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.35',
    description: 'Wholesale of tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.36',
    description: 'Wholesale of sugar and chocolate and sugar confectionery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.37',
    description: 'Wholesale of coffee, tea, cocoa and spices',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.38',
    description:
      'Wholesale of other food, including fish, crustaceans and molluscs',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.39',
    description: 'Non-specialised wholesale of food, beverages and tobacco',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.4',
    description: 'Wholesale of household goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.41',
    description: 'Wholesale of textiles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.42',
    description: 'Wholesale of clothing and footwear',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.43',
    description: 'Wholesale of electrical household appliances',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.44',
    description: 'Wholesale of china and glassware and cleaning materials',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.45',
    description: 'Wholesale of perfume and cosmetics',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.46',
    description: 'Wholesale of pharmaceutical goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.47',
    description: 'Wholesale of furniture, carpets and lighting equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.48',
    description: 'Wholesale of watches and jewellery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.49',
    description: 'Wholesale of other household goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.5',
    description: 'Wholesale of information and communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.51',
    description:
      'Wholesale of computers, computer peripheral equipment and software',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.52',
    description:
      'Wholesale of electronic and telecommunications equipment and parts',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.6',
    description: 'Wholesale of other machinery, equipment and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.61',
    description: 'Wholesale of agricultural machinery, equipment and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.62',
    description: 'Wholesale of machine tools',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.63',
    description:
      'Wholesale of mining, construction and civil engineering machinery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.64',
    description:
      'Wholesale of machinery for the textile industry and of sewing and knitting machines',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.65',
    description: 'Wholesale of office furniture',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.66',
    description: 'Wholesale of other office machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.69',
    description: 'Wholesale of other machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.7',
    description: 'Other specialised wholesale',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.71',
    description:
      'Wholesale of solid, liquid and gaseous fuels and related products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.72',
    description: 'Wholesale of metals and metal ores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.73',
    description:
      'Wholesale of wood, construction materials and sanitary equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.74',
    description:
      'Wholesale of hardware, plumbing and heating equipment and supplies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.75',
    description: 'Wholesale of chemical products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.76',
    description: 'Wholesale of other intermediate products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.77',
    description: 'Wholesale of waste and scrap',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.9',
    description: 'Non-specialised wholesale trade',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '46.90',
    description: 'Non-specialised wholesale trade',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47',
    description: 'Retail trade, except of motor vehicles and motorcycles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.1',
    description: 'Retail sale in non-specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.11',
    description:
      'Retail sale in non-specialised stores with food, beverages or tobacco predominating',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.19',
    description: 'Other retail sale in non-specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.2',
    description:
      'Retail sale of food, beverages and tobacco in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.21',
    description: 'Retail sale of fruit and vegetables in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.22',
    description: 'Retail sale of meat and meat products in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.23',
    description:
      'Retail sale of fish, crustaceans and molluscs in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.24',
    description:
      'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.25',
    description: 'Retail sale of beverages in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.26',
    description: 'Retail sale of tobacco products in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.29',
    description: 'Other retail sale of food in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.3',
    description: 'Retail sale of automotive fuel in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.30',
    description: 'Retail sale of automotive fuel in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.4',
    description:
      'Retail sale of information and communication equipment in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.41',
    description:
      'Retail sale of computers, peripheral units and software in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.42',
    description:
      'Retail sale of telecommunications equipment in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.43',
    description:
      'Retail sale of audio and video equipment in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.5',
    description:
      'Retail sale of other household equipment in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.51',
    description: 'Retail sale of textiles in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.52',
    description:
      'Retail sale of hardware, paints and glass in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.53',
    description:
      'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.54',
    description:
      'Retail sale of electrical household appliances in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.59',
    description:
      'Retail sale of furniture, lighting equipment and other household articles in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.6',
    description:
      'Retail sale of cultural and recreation goods in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.61',
    description: 'Retail sale of books in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.62',
    description:
      'Retail sale of newspapers and stationery in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.63',
    description:
      'Retail sale of music and video recordings in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.64',
    description: 'Retail sale of sporting equipment in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.65',
    description: 'Retail sale of games and toys in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.7',
    description: 'Retail sale of other goods in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.71',
    description: 'Retail sale of clothing in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.72',
    description:
      'Retail sale of footwear and leather goods in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.73',
    description: 'Dispensing chemist in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.74',
    description:
      'Retail sale of medical and orthopaedic goods in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.75',
    description:
      'Retail sale of cosmetic and toilet articles in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.76',
    description:
      'Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.77',
    description: 'Retail sale of watches and jewellery in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.78',
    description: 'Other retail sale of new goods in specialised stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.79',
    description: 'Retail sale of second-hand goods in stores',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.8',
    description: 'Retail sale via stalls and markets',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.81',
    description:
      'Retail sale via stalls and markets of food, beverages and tobacco products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.82',
    description:
      'Retail sale via stalls and markets of textiles, clothing and footwear',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.89',
    description: 'Retail sale via stalls and markets of other goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.9',
    description: 'Retail trade not in stores, stalls or markets',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.91',
    description: 'Retail sale via mail order houses or via Internet',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '47.99',
    description: 'Other retail sale not in stores, stalls or markets',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'H',
    description: 'TRANSPORTATION AND STORAGE',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49',
    description: 'Land transport and transport via pipelines',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.1',
    description: 'Passenger rail transport, interurban',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.10',
    description: 'Passenger rail transport, interurban',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.2',
    description: 'Freight rail transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.20',
    description: 'Freight rail transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.3',
    description: 'Other passenger land transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.31',
    description: 'Urban and suburban passenger land transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.32',
    description: 'Taxi operation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.39',
    description: 'Other passenger land transport n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.4',
    description: 'Freight transport by road and removal services',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.41',
    description: 'Freight transport by road',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.42',
    description: 'Removal services',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.5',
    description: 'Transport via pipeline',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '49.50',
    description: 'Transport via pipeline',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50',
    description: 'Water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50.1',
    description: 'Sea and coastal passenger water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50.10',
    description: 'Sea and coastal passenger water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50.2',
    description: 'Sea and coastal freight water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50.20',
    description: 'Sea and coastal freight water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50.3',
    description: 'Inland passenger water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50.30',
    description: 'Inland passenger water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50.4',
    description: 'Inland freight water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '50.40',
    description: 'Inland freight water transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '51',
    description: 'Air transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '51.1',
    description: 'Passenger air transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '51.10',
    description: 'Passenger air transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '51.2',
    description: 'Freight air transport and space transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '51.21',
    description: 'Freight air transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '51.22',
    description: 'Space transport',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52',
    description: 'Warehousing and support activities for transportation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52.1',
    description: 'Warehousing and storage',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52.10',
    description: 'Warehousing and storage',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52.2',
    description: 'Support activities for transportation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52.21',
    description: 'Service activities incidental to land transportation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52.22',
    description: 'Service activities incidental to water transportation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52.23',
    description: 'Service activities incidental to air transportation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52.24',
    description: 'Cargo handling',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '52.29',
    description: 'Other transportation support activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '53',
    description: 'Postal and courier activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '53.1',
    description: 'Postal activities under universal service obligation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '53.10',
    description: 'Postal activities under universal service obligation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '53.2',
    description: 'Other postal and courier activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '53.20',
    description: 'Other postal and courier activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'I',
    description: 'ACCOMMODATION AND FOOD SERVICE ACTIVITIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55',
    description: 'Accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55.1',
    description: 'Hotels and similar accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55.10',
    description: 'Hotels and similar accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55.2',
    description: 'Holiday and other short-stay accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55.20',
    description: 'Holiday and other short-stay accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55.3',
    description:
      'Camping grounds, recreational vehicle parks and trailer parks',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55.30',
    description:
      'Camping grounds, recreational vehicle parks and trailer parks',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55.9',
    description: 'Other accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '55.90',
    description: 'Other accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '56',
    description: 'Food and beverage service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '56.1',
    description: 'Restaurants and mobile food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '56.10',
    description: 'Restaurants and mobile food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '56.2',
    description: 'Event catering and other food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '56.21',
    description: 'Event catering activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '56.29',
    description: 'Other food service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '56.3',
    description: 'Beverage serving activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '56.30',
    description: 'Beverage serving activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'J',
    description: 'INFORMATION AND COMMUNICATION',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58',
    description: 'Publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.1',
    description:
      'Publishing of books, periodicals and other publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.11',
    description: 'Book publishing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.12',
    description: 'Publishing of directories and mailing lists',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.13',
    description: 'Publishing of newspapers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.14',
    description: 'Publishing of journals and periodicals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.19',
    description: 'Other publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.2',
    description: 'Software publishing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.21',
    description: 'Publishing of computer games',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '58.29',
    description: 'Other software publishing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '59',
    description:
      'Motion picture, video and television programme production, sound recording and music publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '59.1',
    description: 'Motion picture, video and television programme activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '59.11',
    description:
      'Motion picture, video and television programme production activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '59.12',
    description:
      'Motion picture, video and television programme post-production activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '59.13',
    description:
      'Motion picture, video and television programme distribution activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '59.14',
    description: 'Motion picture projection activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '59.2',
    description: 'Sound recording and music publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '59.20',
    description: 'Sound recording and music publishing activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '60',
    description: 'Programming and broadcasting activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '60.1',
    description: 'Radio broadcasting',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '60.10',
    description: 'Radio broadcasting',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '60.2',
    description: 'Television programming and broadcasting activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '60.20',
    description: 'Television programming and broadcasting activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61',
    description: 'Telecommunications',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61.1',
    description: 'Wired telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61.10',
    description: 'Wired telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61.2',
    description: 'Wireless telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61.20',
    description: 'Wireless telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61.3',
    description: 'Satellite telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61.30',
    description: 'Satellite telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61.9',
    description: 'Other telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '61.90',
    description: 'Other telecommunications activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '62',
    description: 'Computer programming, consultancy and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '62.0',
    description: 'Computer programming, consultancy and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '62.01',
    description: 'Computer programming activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '62.02',
    description: 'Computer consultancy activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '62.03',
    description: 'Computer facilities management activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '62.09',
    description: 'Other information technology and computer service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '63',
    description: 'Information service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '63.1',
    description: 'Data processing, hosting and related activities; web portals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '63.11',
    description: 'Data processing, hosting and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '63.12',
    description: 'Web portals',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '63.9',
    description: 'Other information service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '63.91',
    description: 'News agency activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '63.99',
    description: 'Other information service activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'K',
    description: 'FINANCIAL AND INSURANCE ACTIVITIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64',
    description:
      'Financial service activities, except insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.1',
    description: 'Monetary intermediation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.11',
    description: 'Central banking',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.19',
    description: 'Other monetary intermediation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.2',
    description: 'Activities of holding companies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.20',
    description: 'Activities of holding companies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.3',
    description: 'Trusts, funds and similar financial entities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.30',
    description: 'Trusts, funds and similar financial entities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.9',
    description:
      'Other financial service activities, except insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.91',
    description: 'Financial leasing',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.92',
    description: 'Other credit granting',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '64.99',
    description:
      'Other financial service activities, except insurance and pension funding n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '65',
    description:
      'Insurance, reinsurance and pension funding, except compulsory social security',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '65.1',
    description: 'Insurance',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '65.11',
    description: 'Life insurance',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '65.12',
    description: 'Non-life insurance',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '65.2',
    description: 'Reinsurance',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '65.20',
    description: 'Reinsurance',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '65.3',
    description: 'Pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '65.30',
    description: 'Pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66',
    description:
      'Activities auxiliary to financial services and insurance activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.1',
    description:
      'Activities auxiliary to financial services, except insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.11',
    description: 'Administration of financial markets',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.12',
    description: 'Security and commodity contracts brokerage',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.19',
    description:
      'Other activities auxiliary to financial services, except insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.2',
    description: 'Activities auxiliary to insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.21',
    description: 'Risk and damage evaluation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.22',
    description: 'Activities of insurance agents and brokers',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.29',
    description: 'Other activities auxiliary to insurance and pension funding',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.3',
    description: 'Fund management activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '66.30',
    description: 'Fund management activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'L',
    description: 'REAL ESTATE ACTIVITIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '68',
    description: 'Real estate activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '68.1',
    description: 'Buying and selling of own real estate',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '68.10',
    description: 'Buying and selling of own real estate',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '68.2',
    description: 'Renting and operating of own or leased real estate',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '68.20',
    description: 'Renting and operating of own or leased real estate',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '68.3',
    description: 'Real estate activities on a fee or contract basis',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '68.31',
    description: 'Real estate agencies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '68.32',
    description: 'Management of real estate on a fee or contract basis',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'M',
    description: 'PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '69',
    description: 'Legal and accounting activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '69.1',
    description: 'Legal activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '69.10',
    description: 'Legal activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '69.2',
    description:
      'Accounting, bookkeeping and auditing activities; tax consultancy',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '69.20',
    description:
      'Accounting, bookkeeping and auditing activities; tax consultancy',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '70',
    description:
      'Activities of head offices; management consultancy activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '70.1',
    description: 'Activities of head offices',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '70.10',
    description: 'Activities of head offices',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '70.2',
    description: 'Management consultancy activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '70.21',
    description: 'Public relations and communication activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '70.22',
    description: 'Business and other management consultancy activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '71',
    description:
      'Architectural and engineering activities; technical testing and analysis',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '71.1',
    description:
      'Architectural and engineering activities and related technical consultancy',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '71.11',
    description: 'Architectural activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '71.12',
    description: 'Engineering activities and related technical consultancy',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '71.2',
    description: 'Technical testing and analysis',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '71.20',
    description: 'Technical testing and analysis',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '72',
    description: 'Scientific research and development',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '72.1',
    description:
      'Research and experimental development on natural sciences and engineering',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '72.11',
    description: 'Research and experimental development on biotechnology',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '72.19',
    description:
      'Other research and experimental development on natural sciences and engineering',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '72.2',
    description:
      'Research and experimental development on social sciences and humanities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '72.20',
    description:
      'Research and experimental development on social sciences and humanities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '73',
    description: 'Advertising and market research',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '73.1',
    description: 'Advertising',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '73.11',
    description: 'Advertising agencies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '73.12',
    description: 'Media representation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '73.2',
    description: 'Market research and public opinion polling',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '73.20',
    description: 'Market research and public opinion polling',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74',
    description: 'Other professional, scientific and technical activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74.1',
    description: 'Specialised design activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74.10',
    description: 'Specialised design activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74.2',
    description: 'Photographic activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74.20',
    description: 'Photographic activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74.3',
    description: 'Translation and interpretation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74.30',
    description: 'Translation and interpretation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74.9',
    description:
      'Other professional, scientific and technical activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '74.90',
    description:
      'Other professional, scientific and technical activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '75',
    description: 'Veterinary activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '75.0',
    description: 'Veterinary activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '75.00',
    description: 'Veterinary activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'N',
    description: 'ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77',
    description: 'Rental and leasing activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.1',
    description: 'Renting and leasing of motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.11',
    description: 'Renting and leasing of cars and light motor vehicles',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.12',
    description: 'Renting and leasing of trucks',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.2',
    description: 'Renting and leasing of personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.21',
    description: 'Renting and leasing of recreational and sports goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.22',
    description: 'Renting of video tapes and disks',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.29',
    description: 'Renting and leasing of other personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.3',
    description:
      'Renting and leasing of other machinery, equipment and tangible goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.31',
    description: 'Renting and leasing of agricultural machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.32',
    description:
      'Renting and leasing of construction and civil engineering machinery and equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.33',
    description:
      'Renting and leasing of office machinery and equipment (including computers)',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.34',
    description: 'Renting and leasing of water transport equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.35',
    description: 'Renting and leasing of air transport equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.39',
    description:
      'Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.4',
    description:
      'Leasing of intellectual property and similar products, except copyrighted works',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '77.40',
    description:
      'Leasing of intellectual property and similar products, except copyrighted works',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '78',
    description: 'Employment activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '78.1',
    description: 'Activities of employment placement agencies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '78.10',
    description: 'Activities of employment placement agencies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '78.2',
    description: 'Temporary employment agency activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '78.20',
    description: 'Temporary employment agency activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '78.3',
    description: 'Other human resources provision',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '78.30',
    description: 'Other human resources provision',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '79',
    description:
      'Travel agency, tour operator and other reservation service and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '79.1',
    description: 'Travel agency and tour operator activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '79.11',
    description: 'Travel agency activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '79.12',
    description: 'Tour operator activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '79.9',
    description: 'Other reservation service and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '79.90',
    description: 'Other reservation service and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '80',
    description: 'Security and investigation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '80.1',
    description: 'Private security activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '80.10',
    description: 'Private security activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '80.2',
    description: 'Security systems service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '80.20',
    description: 'Security systems service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '80.3',
    description: 'Investigation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '80.30',
    description: 'Investigation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81',
    description: 'Services to buildings and landscape activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81.1',
    description: 'Combined facilities support activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81.10',
    description: 'Combined facilities support activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81.2',
    description: 'Cleaning activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81.21',
    description: 'General cleaning of buildings',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81.22',
    description: 'Other building and industrial cleaning activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81.29',
    description: 'Other cleaning activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81.3',
    description: 'Landscape service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '81.30',
    description: 'Landscape service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82',
    description:
      'Office administrative, office support and other business support activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.1',
    description: 'Office administrative and support activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.11',
    description: 'Combined office administrative service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.19',
    description:
      'Photocopying, document preparation and other specialised office support activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.2',
    description: 'Activities of call centres',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.20',
    description: 'Activities of call centres',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.3',
    description: 'Organisation of conventions and trade shows',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.30',
    description: 'Organisation of conventions and trade shows',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.9',
    description: 'Business support service activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.91',
    description: 'Activities of collection agencies and credit bureaus',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.92',
    description: 'Packaging activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '82.99',
    description: 'Other business support service activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'O',
    description:
      'PUBLIC ADMINISTRATION AND DEFENCE; COMPULSORY SOCIAL SECURITY',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84',
    description:
      'Public administration and defence; compulsory social security',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.1',
    description:
      'Administration of the State and the economic and social policy of the community',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.11',
    description: 'General public administration activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.12',
    description:
      'Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.13',
    description:
      'Regulation of and contribution to more efficient operation of businesses',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.2',
    description: 'Provision of services to the community as a whole',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.21',
    description: 'Foreign affairs',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.22',
    description: 'Defence activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.23',
    description: 'Justice and judicial activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.24',
    description: 'Public order and safety activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.25',
    description: 'Fire service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.3',
    description: 'Compulsory social security activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '84.30',
    description: 'Compulsory social security activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'P',
    description: 'EDUCATION',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85',
    description: 'Education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.1',
    description: 'Pre-primary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.10',
    description: 'Pre-primary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.2',
    description: 'Primary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.20',
    description: 'Primary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.3',
    description: 'Secondary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.31',
    description: 'General secondary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.32',
    description: 'Technical and vocational secondary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.4',
    description: 'Higher education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.41',
    description: 'Post-secondary non-tertiary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.42',
    description: 'Tertiary education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.5',
    description: 'Other education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.51',
    description: 'Sports and recreation education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.52',
    description: 'Cultural education',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.53',
    description: 'Driving school activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.59',
    description: 'Other education n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.6',
    description: 'Educational support activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '85.60',
    description: 'Educational support activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'Q',
    description: 'HUMAN HEALTH AND SOCIAL WORK ACTIVITIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86',
    description: 'Human health activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86.1',
    description: 'Hospital activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86.10',
    description: 'Hospital activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86.2',
    description: 'Medical and dental practice activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86.21',
    description: 'General medical practice activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86.22',
    description: 'Specialist medical practice activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86.23',
    description: 'Dental practice activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86.9',
    description: 'Other human health activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '86.90',
    description: 'Other human health activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87',
    description: 'Residential care activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87.1',
    description: 'Residential nursing care activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87.10',
    description: 'Residential nursing care activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87.2',
    description:
      'Residential care activities for mental retardation, mental health and substance abuse',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87.20',
    description:
      'Residential care activities for mental retardation, mental health and substance abuse',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87.3',
    description: 'Residential care activities for the elderly and disabled',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87.30',
    description: 'Residential care activities for the elderly and disabled',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87.9',
    description: 'Other residential care activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '87.90',
    description: 'Other residential care activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '88',
    description: 'Social work activities without accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '88.1',
    description:
      'Social work activities without accommodation for the elderly and disabled',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '88.10',
    description:
      'Social work activities without accommodation for the elderly and disabled',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '88.9',
    description: 'Other social work activities without accommodation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '88.91',
    description: 'Child day-care activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '88.99',
    description: 'Other social work activities without accommodation n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'R',
    description: 'ARTS, ENTERTAINMENT AND RECREATION',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '90',
    description: 'Creative, arts and entertainment activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '90.0',
    description: 'Creative, arts and entertainment activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '90.01',
    description: 'Performing arts',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '90.02',
    description: 'Support activities to performing arts',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '90.03',
    description: 'Artistic creation',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '90.04',
    description: 'Operation of arts facilities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '91',
    description: 'Libraries, archives, museums and other cultural activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '91.0',
    description: 'Libraries, archives, museums and other cultural activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '91.01',
    description: 'Library and archives activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '91.02',
    description: 'Museums activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '91.03',
    description:
      'Operation of historical sites and buildings and similar visitor attractions',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '91.04',
    description:
      'Botanical and zoological gardens and nature reserves activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '92',
    description: 'Gambling and betting activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '92.0',
    description: 'Gambling and betting activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '92.00',
    description: 'Gambling and betting activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93',
    description: 'Sports activities and amusement and recreation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93.1',
    description: 'Sports activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93.11',
    description: 'Operation of sports facilities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93.12',
    description: 'Activities of sports clubs',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93.13',
    description: 'Fitness facilities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93.19',
    description: 'Other sports activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93.2',
    description: 'Amusement and recreation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93.21',
    description: 'Activities of amusement parks and theme parks',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '93.29',
    description: 'Other amusement and recreation activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'S',
    description: 'OTHER SERVICE ACTIVITIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94',
    description: 'Activities of membership organisations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.1',
    description:
      'Activities of business, employers and professional membership organisations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.11',
    description:
      'Activities of business and employers membership organisations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.12',
    description: 'Activities of professional membership organisations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.2',
    description: 'Activities of trade unions',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.20',
    description: 'Activities of trade unions',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.9',
    description: 'Activities of other membership organisations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.91',
    description: 'Activities of religious organisations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.92',
    description: 'Activities of political organisations',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '94.99',
    description: 'Activities of other membership organisations n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95',
    description: 'Repair of computers and personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.1',
    description: 'Repair of computers and communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.11',
    description: 'Repair of computers and peripheral equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.12',
    description: 'Repair of communication equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.2',
    description: 'Repair of personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.21',
    description: 'Repair of consumer electronics',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.22',
    description: 'Repair of household appliances and home and garden equipment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.23',
    description: 'Repair of footwear and leather goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.24',
    description: 'Repair of furniture and home furnishings',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.25',
    description: 'Repair of watches, clocks and jewellery',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '95.29',
    description: 'Repair of other personal and household goods',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '96',
    description: 'Other personal service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '96.0',
    description: 'Other personal service activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '96.01',
    description: 'Washing and (dry-)cleaning of textile and fur products',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '96.02',
    description: 'Hairdressing and other beauty treatment',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '96.03',
    description: 'Funeral and related activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '96.04',
    description: 'Physical well-being activities',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '96.09',
    description: 'Other personal service activities n.e.c.',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'T',
    description:
      'ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS; U0NDIFFERENTIATED GOODS- AND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '97',
    description: 'Activities of households as employers of domestic personnel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '97.0',
    description: 'Activities of households as employers of domestic personnel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '97.00',
    description: 'Activities of households as employers of domestic personnel',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '98',
    description:
      'Undifferentiated goods- and services-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '98.1',
    description:
      'Undifferentiated goods-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '98.10',
    description:
      'Undifferentiated goods-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '98.2',
    description:
      'Undifferentiated service-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '98.20',
    description:
      'Undifferentiated service-producing activities of private households for own use',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: 'U',
    description: 'ACTIVITIES OF EXTRATERRITORIAL ORGANISATIONS AND BODIES',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '99',
    description: 'Activities of extraterritorial organisations and bodies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '99.0',
    description: 'Activities of extraterritorial organisations and bodies',
  },
  {
    type: CompanyClassificationTypeEnum.NACE,
    code: '99.00',
    description: 'Activities of extraterritorial organisations and bodies',
  },
];

export default naceClassifications;
