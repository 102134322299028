const wordTokenizer = /['\s,-]+/;

/**
 * Tokenize `source` from `tokenizer`
 * @param source - The string to tokenize
 * @param tokenizer - default to `/['\s,-]+/`
 * @returns string array of tokens
 */
export function tokenize(
  source: string,
  tokenizer: string | RegExp = wordTokenizer,
): string[] {
  return source.split(tokenizer).filter(Boolean);
}
