import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@dotfile/frontend/shared/common';

import { useRouter } from '../../../../routes';
import { useFormDatastore } from '../context';
import { useStepProgress } from './use-step-progress';

/**
 * Custom hook to handle back navigation logic
 */
export function useBackProgressNavigation() {
  const { t } = useTranslation();

  const progress = useFormDatastore((state) => state.progress);
  const setProgress = useFormDatastore((state) => state.setProgress);
  const resetStore = useFormDatastore((state) => state.reset);

  const {
    auth: { isAuthenticated },
  } = useAuth();

  const { getPrevious } = useStepProgress();
  const router = useRouter();

  const handleBack = useCallback(() => {
    const previous = getPrevious();
    if (!previous) {
      if (isAuthenticated) {
        // Cannot go back to Login using the navigation button when authenticated
        return;
      }

      // Reset the store otherwise the login page would redirect to the in progress form
      // @NOTE Later, in progress form data could be stored elsewhere
      // @NOTE Later, we could want to have a confirmation modal before clearing all the data,
      // see https://www.notion.so/dotfile/Start-new-onboarding-fully-should-reset-the-session-c41ef00f7b2949328dba311419fbd2ba?pvs=4
      resetStore();

      router.push('Login');
    } else {
      setProgress({
        currentStepKey: previous,
        completedStepKeys: progress.completedStepKeys,
      });
    }
  }, [getPrevious, router, setProgress, progress, resetStore, isAuthenticated]);

  const label = useMemo(
    () =>
      getPrevious()
        ? t('common.back', { ns: 'client-portal', defaultValue: 'Back' })
        : isAuthenticated
          ? null // Cannot go back to Login using the navigation button when authenticated
          : t('common.cancel', { ns: 'client-portal', defaultValue: 'Cancel' }),
    [getPrevious, t, isAuthenticated],
  );

  return {
    handleBack,
    label,
  };
}
