import { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 1,
  textTransform: 'none',
  fontSize: 'sm',
  borderRadius: 'base',
  fontWeight: 500,
  lineHeight: '4',
  padding: '4px 8px',
};

const variantDefault: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    bg: mode(`${c}.100`, `${c}.700`)(props),
    color: mode(`${c}.700`, `${c}.100`)(props),
  };
};

const variants = {
  default: variantDefault,
};

const defaultProps = {
  variant: 'default',
  colorScheme: 'gray',
};

export const BadgeTheme: ComponentStyleConfig = {
  baseStyle,
  variants,
  defaultProps,
};
