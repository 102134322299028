import { TemplateRuleTargetEnum } from '../../../shared/models';

export const templateRuleTargetDefinition: Record<
  TemplateRuleTargetEnum,
  { label: string }
> = {
  [TemplateRuleTargetEnum.company_main]: {
    label: 'Main company',
  },
  [TemplateRuleTargetEnum.company_affiliated]: {
    label: 'Affiliated',
  },
  [TemplateRuleTargetEnum.individual_any]: {
    label: 'Any individual',
  },
  [TemplateRuleTargetEnum.individual_beneficial_owner]: {
    label: 'Ultimate beneficial owner',
  },
  [TemplateRuleTargetEnum.individual_shareholder]: {
    label: 'Shareholder',
  },
  [TemplateRuleTargetEnum.individual_legal_representative]: {
    label: 'Legal representative',
  },
  [TemplateRuleTargetEnum.individual_delegator]: {
    label: 'Delegator',
  },
  [TemplateRuleTargetEnum.individual_signatory]: {
    label: 'Signatory',
  },
  [TemplateRuleTargetEnum.individual_business_contact]: {
    label: 'Business contact',
  },
};
