import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Container = styled(Box)`
  &.readOnly {
    user-select: none;
    pointer-events: none;
  }
  .react-tel-input {
    width: ${(props) => props.width};
  }

  *:focus:not([data-focus-visible-added]) {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  }
  .input,
  .button {
    font-size: var(--chakra-fontSizes-md);
    border-radius: var(--chakra-radii-md);
  }
  .input {
    width: 100%;
    border-color: var(--chakra-colors-gray-200);
    box-sizing: border-box !important;
    height: var(--chakra-sizes-10);
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    &:hover {
      border-color: var(--chakra-colors-gray-300);
    }
    &:focus,
    &:active {
      border: solid 1px var(--chakra-colors-gray-900);
      border-radius: var(--chakra-radii-md);
    }
    &.invalid {
      border: solid 1px var(--chakra-colors-red-500);
      background-color: var(--chakra-colors-red-50);
      &:focus {
        border: solid 1px var(--chakra-colors-gray-900);
      }
    }
  }
  .button {
    border-radius: var(--chakra-radii-md) 0 0 var(--chakra-radii-md);
    top: 1px;
    bottom: 1px;
    left: 1px;
    border: 0px;
    .selected-flag {
      border-radius: var(--chakra-radii-md) 0 0 var(--chakra-radii-md);
      background-color: var(--chakra-colors-gray-200);
    }
    .dropDown.country-list {
      position: relative;
      border-radius: var(--chakra-radii-md);
      border-color: var(--chakra-colors-gray-300);
      padding: var(--chakra-space-1);
      margin-bottom: var(--chakra-space-4);
    }
    .country:hover,
    .highlight {
      border-radius: var(--chakra-radii-md);
    }

    &.open,
    &:hover {
      border-radius: var(--chakra-radii-md) 0 0 var(--chakra-radii-md);
      .selected-flag {
        background-color: var(--chakra-colors-gray-200);
        border-radius: var(--chakra-radii-md) 0 0 var(--chakra-radii-md);
      }
    }
  }
`;
