import { useCallback, useEffect, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { usePageParams } from '@dotfile/frontend/shared/common';
import {
  CheckStatusEnum,
  ClientPortalChecks_CheckInterface,
  ClientPortalChecks_DocumentCheck,
  ClientPortalChecks_IdDocumentCheck,
  ClientPortalChecks_IdVerificationCheck,
} from '@dotfile/shared/data-access-client-portal';
import { CheckTypeEnum } from '@dotfile/shared/domain';

import { useRouter } from '../../../../routes';
import { DocumentModal } from '../../document';
import { IdDocumentModal } from '../../id-document';
import { IdVerificationModal } from '../../id-verification';
import { PendingStatuses, useCaseChecks } from '../../shared';
import { Entity } from '../../types';

export const ChecksModal = () => {
  const { data } = useCaseChecks();

  // One the URL state look at the
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ checkModal: openedModalCheckId }, _, removeParams] = usePageParams(
    router.Checks,
  );

  const onClose = useCallback(() => {
    removeParams(['checkModal']);
  }, [removeParams]);

  const { check, entity, individual } = useMemo(() => {
    const checks = [
      ...(data?.case?.companies.flatMap((company) => company.checks) ?? []),
      ...(data?.case?.individuals.flatMap((individual) => individual.checks) ??
        []),
    ];

    const check: ClientPortalChecks_CheckInterface | null =
      checks.find((c) => c.id === openedModalCheckId) ?? null;

    const company =
      data?.case?.companies.find((c) =>
        c.checks.some((c) => c.id === openedModalCheckId),
      ) ?? null;
    const individual =
      data?.case?.individuals.find((c) =>
        c.checks.some((c) => c.id === openedModalCheckId),
      ) ?? null;
    const entity: Entity | null = company ?? individual;

    return {
      check:
        check &&
        // Only show modal for check in pending statuses or processing for polling
        [...PendingStatuses, CheckStatusEnum.processing].includes(check.status)
          ? check
          : null,
      entity,
      individual,
    };
  }, [data?.case, openedModalCheckId]);

  // Remove param if check not found
  useEffect(() => {
    if (data && openedModalCheckId && (!check || !entity)) {
      onClose();
    }
  }, [check, data, entity, onClose, openedModalCheckId]);

  if (!check || !entity) return null;

  return match(check)
    .with(
      P.when(
        (check): check is ClientPortalChecks_DocumentCheck =>
          check.type === CheckTypeEnum.document,
      ),
      (check) => (
        <DocumentModal entity={entity} check={check} isOpen onClose={onClose} />
      ),
    )
    .with(
      P.when(
        (check): check is ClientPortalChecks_IdVerificationCheck =>
          check.type === CheckTypeEnum.id_verification,
      ),
      (check) => {
        if (!individual) {
          // ID Verification check should always be related to an individual
          return null;
        }

        return (
          <IdVerificationModal
            check={check}
            individual={individual}
            isOpen
            onClose={onClose}
          />
        );
      },
    )
    .with(
      P.when(
        (check): check is ClientPortalChecks_IdDocumentCheck =>
          check.type === CheckTypeEnum.id_document,
      ),
      (check) => {
        if (!individual) {
          // ID Document check should always be related to an individual
          return null;
        }

        return (
          <IdDocumentModal
            check={check}
            individual={individual}
            isOpen
            onClose={onClose}
          />
        );
      },
    )
    .exhaustive();
};
