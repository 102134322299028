// Use yup-extended to have countryCode2
import { AddressModel } from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';

export const addressSchema = (): yup.SchemaOf<AddressModel> => {
  const baseStringRule = yup.string().optionalString().default(null);

  return yup.object({
    streetAddress: baseStringRule,
    streetAddress2: baseStringRule,
    postalCode: baseStringRule,
    city: baseStringRule,
    state: baseStringRule,
    region: baseStringRule,
    country: baseStringRule.countryCode2(),
  });
};
