import { AmlHitMonitoringStatusEnum } from '../../../shared/models';

type AmlHitMonitoringStatusDefinition = {
  action: string;
};

export const amlHitMonitoringStatusDefinition: Record<
  AmlHitMonitoringStatusEnum,
  AmlHitMonitoringStatusDefinition
> = {
  [AmlHitMonitoringStatusEnum.new]: {
    action: 'added',
  },
  [AmlHitMonitoringStatusEnum.updated]: {
    action: 'updated',
  },
  [AmlHitMonitoringStatusEnum.removed]: {
    action: 'removed',
  },
} as const;
