import { useCallback, useState } from 'react';

import { useIdDocumentCheckQueryLazyQuery } from '@dotfile/shared/data-access-client-portal';
import { CheckStatusEnum } from '@dotfile/shared/domain';

import { useTryCompleteChecks } from '../../shared';

const INTERVAL_TIME = 2000; // 2 seconds
const MAX_ATTEMPTS = 8; // 8 times * 2 seconds = 16 seconds max

export const usePollIdDocumentCheck = (
  checkId: string,
  onClose: () => void,
) => {
  const [, setAttempts] = useState(0);
  const [loading, setLoading] = useState(false);

  const [tryCompleteChecks] = useTryCompleteChecks();
  const [runQuery] = useIdDocumentCheckQueryLazyQuery({
    variables: { id: checkId as string },
    fetchPolicy: 'network-only',
  });

  const start = useCallback(async () => {
    setAttempts(0); // Reset attempts if the start function is called again
    setLoading(true); // start loading

    const processIdDocumentResult = async (interval: NodeJS.Timeout) => {
      const result = await runQuery();
      const idDocumentCheck = result.data?.idDocumentCheck;

      if (!idDocumentCheck) return;
      const status = idDocumentCheck.status;

      if (status === CheckStatusEnum.rejected) {
        // Keep modal open, refresh state to display the rejection reason
        // Stop the polling
        clearInterval(interval);
        setLoading(false);
        return;
      }

      if (
        (
          [
            CheckStatusEnum.approved,
            CheckStatusEnum.need_review,
            // @TODO - E-2270 - Add @total-typescript/ts-reset
            // `as` should not be necessary anymore
          ] as CheckStatusEnum[]
        ).includes(status)
      ) {
        // if status need_review or approved -> call tryCompleteChecks
        clearInterval(interval);
        await tryCompleteChecks(
          // ClientPortalChecks_ContactHasActionsRefresh is not refetch so always attempt to complete check
          // here and backend will get the up-to-date contactHasAction to decide to complete checks
          false,
        );
        onClose();
      }
    };

    const interval = setInterval(() => {
      setAttempts((prev) => {
        if (prev >= MAX_ATTEMPTS) {
          // Stop the polling after X attempts, try to complete checks anyway and close
          clearInterval(interval);
          // This will also refresh the CaseQueryDocument to enforce the flow of
          // ValidateRouteGuard in order to redirect to End page if this check
          // was the last one to be filled
          tryCompleteChecks(false);
          onClose();
        } else {
          processIdDocumentResult(interval); // Poll
        }
        return prev + 1;
      });
    }, INTERVAL_TIME);

    return () => clearInterval(interval); // Clear interval when the component is unmounted
  }, [onClose, runQuery, tryCompleteChecks]);

  return { start, loading };
};
