/**
 * Callback for .filter to have the right typing when excluding empty values
 * @see https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
 * @param value
 * @returns
 */
export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}
