import { useCallback } from 'react';

import { ClientPortalLogicTypeEnum } from '@dotfile/shared/data-access-client-portal';
import { jsonLogic } from '@dotfile/shared/domain';

import { useLatestClientPortalVersionForms } from '../hooks';
import { useGlobalDataContext } from './use-global-data-context';

export function useStepPreventSubmitLogic(): (stepId: string) => boolean {
  const { data: dataForm } = useLatestClientPortalVersionForms();
  // @NOTE only need to global context since it is computed before the step is displayed (so no local context)
  const globalDataContext = useGlobalDataContext();

  const computePreventSubmitStep = useCallback(
    (stepId: string) => {
      if (dataForm) {
        const step = dataForm.latestClientPortalVersion.steps.find(
          (step) => step.key === stepId,
        );
        const preventSubmitLogic = step?.logics.find(
          (logic) => logic.type === ClientPortalLogicTypeEnum.prevent_submit,
        )?.jsonLogic;
        if (preventSubmitLogic) {
          const global = globalDataContext();
          return jsonLogic.apply(preventSubmitLogic, {
            local: {},
            global,
          });
        }
      } else {
        return false;
      }
    },
    [dataForm, globalDataContext],
  );

  return computePreventSubmitStep;
}
