import { LatestClientPortalVersionQuery } from '@dotfile/shared/data-access-client-portal';

/**
 * Redirects to a custom domain if
 * 1. it is verified & valid
 * 2. the current domain is not the custom domain.
 */
export const redirectToCustomDomain = (
  customDomain:
    | LatestClientPortalVersionQuery['latestClientPortalVersion']['clientPortal']['customDomain']
    | null,
) => {
  if (!customDomain || !customDomain.domain || !customDomain.isVerified) {
    return;
  }

  const currentDomain = window.location.hostname;

  if (currentDomain !== customDomain.domain) {
    const newUrl = new URL(window.location.href);
    newUrl.host = customDomain.domain;

    // Ensure the protocol is http or https
    if (newUrl.protocol !== 'http:' && newUrl.protocol !== 'https:') {
      throw new Error('Invalid protocol');
    }

    // file deepcode ignore OR: This redirect to a custom domain that is verified before
    window.location.replace(newUrl.href);
  }
};
