import { TFunction } from 'i18next';
import { SchemaOf } from 'yup';

import {
  DocumentAnalysisIBANDetailedResultsModel,
  DocumentAnalysisIBANInformationModel,
  DocumentAnalysisIBANParametersModel,
  DocumentAnalysisModelEnum,
} from '../../../../../shared/models';
import {
  DocumentAnalysisDefinition,
  DocumentAnalysisDefinitionDetailedResults,
  DocumentAnalysisDefinitionInformation,
  DocumentAnalysisDefinitionParameters,
} from '../document-analysis-definition';
import { documentAnalysisIBANParametersSchema } from './validation';

export class DocumentAnalysisIBANDefinition extends DocumentAnalysisDefinition<
  DocumentAnalysisIBANParametersModel,
  DocumentAnalysisIBANDetailedResultsModel,
  DocumentAnalysisIBANInformationModel
> {
  override get model(): DocumentAnalysisModelEnum {
    return DocumentAnalysisModelEnum.iban;
  }

  override get label(): string {
    return 'IBAN';
  }

  override get parameters(): DocumentAnalysisDefinitionParameters<DocumentAnalysisIBANParametersModel> {
    return {
      model: {
        label: 'Model',
      },
      ibanMatching: {
        label: 'IBAN matching',
      },
      bicMatching: {
        label: 'BIC matching',
      },
    };
  }

  override get detailedResults(): DocumentAnalysisDefinitionDetailedResults<DocumentAnalysisIBANDetailedResultsModel> {
    return {
      nameMatching: {
        label: 'Name match',
      },
      ibanMatching: {
        label: 'IBAN matching',
      },
      bicMatching: {
        label: 'BIC matching',
      },
      validIBAN: {
        label: 'Valid IBAN',
      },
      validBIC: {
        label: 'Valid BIC',
      },
    };
  }

  override get information(): DocumentAnalysisDefinitionInformation<DocumentAnalysisIBANInformationModel> {
    return {
      holderName: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.holder_name', {
            defaultValue: 'Holder name',
            ns: 'domain',
          }),
      },
      bankName: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.bank_name', {
            defaultValue: 'Bank name',
            ns: 'domain',
          }),
      },
      bic: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.bic', {
            defaultValue: 'BIC',
            ns: 'domain',
          }),
      },
      iban: {
        label: (t: TFunction) =>
          t('check.document.document_analysis.information.iban', {
            defaultValue: 'IBAN',
            ns: 'domain',
          }),
      },
    };
  }

  override get defaultParameters(): Omit<
    DocumentAnalysisIBANParametersModel,
    'model'
  > {
    return {
      ibanMatching: false,
      bicMatching: false,
    };
  }

  override get validationSchemas(): {
    parameters?: SchemaOf<DocumentAnalysisIBANParametersModel>;
  } {
    return { parameters: documentAnalysisIBANParametersSchema() };
  }
}

export const documentAnalysisIBANDefinition =
  new DocumentAnalysisIBANDefinition();
