import { get, includes, isEmpty, isObjectLike, pick } from 'lodash';

export const getValue = (object: object, field: string): unknown => {
  const value = get(object, field);

  if (value === '' || value === undefined) {
    // Coalesce property with empty string or no value to null in changes
    return null;
  } else {
    return value;
  }
};

const getPaths = (obj: object, parentKey = ''): string[] => {
  return Object.keys(obj).reduce<string[]>((paths, key) => {
    const fullPath = parentKey ? `${parentKey}.${key}` : key;
    const value = get(obj, key);
    if (
      value !== null &&
      typeof get(obj, key) === 'object' &&
      !Array.isArray(get(obj, key))
    ) {
      const nestedPaths = getPaths(get(obj, key), fullPath);
      return [...paths, ...nestedPaths];
    } else {
      return [...paths, fullPath];
    }
  }, []);
};

export const isSubObjectWithSameValues = (
  previous: object | null,
  next: object | null,
): boolean => {
  if (!isEmpty(next) && !isEmpty(previous)) {
    const paths = getPaths(next);
    if (paths.length === 0) {
      return true;
    }
    return paths
      .reduce<boolean[]>((acc, currentKey) => {
        const nextValue = getValue(next, currentKey);
        const previousValue = getValue(previous, currentKey);

        if (Array.isArray(nextValue) && Array.isArray(previousValue)) {
          if (nextValue.length !== previousValue.length) return [...acc, false];

          const bool = nextValue.every((v): unknown => {
            // if it's an object but not null, func nor an array
            if (isObjectLike(v) && !Array.isArray(v)) {
              const vPaths = getPaths(v);
              return Boolean(
                previousValue.find((pv) => {
                  // recurse on sub object
                  return isSubObjectWithSameValues(
                    pick(pv, vPaths),
                    pick(v, vPaths),
                  );
                }),
              );
            } else {
              return includes(previousValue, v);
            }
          });
          return [...acc, bool];
        }

        return [...acc, nextValue === previousValue];
      }, [])
      .every(Boolean);
  }
  return true;
};
