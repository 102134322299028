import {
  ClientPortalStepModel,
  ClientPortalStepTypeEnum,
} from '../../../../shared/models';
import yup from '../../../../utils/yup-extended';
import { STEP_KEY_MAX_LENGTH, STEP_TITLE_MAX_LENGTH } from '../../constants';
import {
  clientPortalStepDefinitionsRecord,
  getClientPortalStepDefinition,
} from '../definitions';

type ClientPortalStepSchemaOption = {
  /**
   * Async function to determine if a key is unique
   * @param key
   * @returns
   */
  isKeyUnique: (key: string) => Promise<boolean>;

  /**
   * Async function to determine if a position is unique
   * @param position
   * @returns
   */
  isPositionUnique: (position: number) => Promise<boolean>;
};

const canBeHiddenStepTypes = Object.values(
  clientPortalStepDefinitionsRecord,
).reduce(
  (acc, stepDefinition) =>
    stepDefinition.canBeHidden ? [...acc, stepDefinition.type] : acc,
  [] as ClientPortalStepTypeEnum[],
);

const canBeMovedStepTypes = Object.values(
  clientPortalStepDefinitionsRecord,
).reduce(
  (acc, stepDefinition) =>
    stepDefinition.canBeMoved ? [...acc, stepDefinition.type] : acc,
  [] as ClientPortalStepTypeEnum[],
);

export const clientPortalStepSchema = ({
  isKeyUnique,
  isPositionUnique,
}: ClientPortalStepSchemaOption): yup.SchemaOf<
  Pick<
    ClientPortalStepModel,
    | 'title'
    | 'key'
    | 'type'
    | 'description'
    | 'position'
    | 'isVisible'
    | 'config'
    | 'clientPortalId'
  >
> => {
  return yup
    .object()
    .shape({
      title: yup.string().max(STEP_TITLE_MAX_LENGTH).required(),
      key: yup
        .string()
        .max(STEP_KEY_MAX_LENGTH)
        .matches(
          /^[a-z][a-z_0-9]*$/,
          'key must start with a lower case letter and only contain lower case letters, digit and underscore',
        )
        .when('type', (type: ClientPortalStepTypeEnum, schema) => {
          const definition = getClientPortalStepDefinition(type);

          return type === ClientPortalStepTypeEnum.custom
            ? schema
                .required()
                .isUnique(
                  isKeyUnique,
                  "Another step with the same key '${value}' already exists",
                )
            : schema
                .nullable()
                .default(definition?.type)
                .is(
                  [definition?.type],
                  '${path} cannot be set for type: '.concat(type),
                );
        }),
      description: yup.string().optionalString(),
      type: yup
        .mixed()
        .oneOf(Object.values(ClientPortalStepTypeEnum))
        .required(),
      isVisible: yup
        .boolean()
        .when('type', (type: ClientPortalStepTypeEnum, schema) => {
          const definition = getClientPortalStepDefinition(type);

          return canBeHiddenStepTypes.includes(type)
            ? schema.optional()
            : schema
                .nullable()
                .default(!definition?.canBeHidden)
                .is(
                  [!definition?.canBeHidden],
                  '${path} cannot be set for type: '.concat(type),
                );
        }),
      position: yup
        .number()
        .when('type', (type: ClientPortalStepTypeEnum, schema) => {
          const definition = getClientPortalStepDefinition(type);

          return canBeMovedStepTypes.includes(type)
            ? schema
                .required()
                .isUnique(
                  isPositionUnique,
                  "Another step with the same position '${value}' already exists",
                )
            : schema
                .nullable()
                .default(definition?.position)
                .is(
                  [definition?.position],
                  '${path} cannot be set for type: '.concat(type),
                );
        }),
      config: yup.mixed().when('type', (type, schema) => {
        return (
          getClientPortalStepDefinition(type)?.configSchema?.() ??
          schema.nullable().default(null)
        );
      }),
      clientPortalId: yup.string().uuid().required(),
    })
    .noUnknown()
    .defined();
};
