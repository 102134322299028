import { SchemaOf } from 'yup';

import {
  DocumentAnalysisModelEnum,
  DocumentAnalysisPromptDetailedResultsModel,
  DocumentAnalysisPromptInformationModel,
  DocumentAnalysisPromptParametersModel,
} from '../../../../../shared/models';
import {
  DocumentAnalysisDefinition,
  DocumentAnalysisDefinitionDetailedResults,
  DocumentAnalysisDefinitionInformation,
  DocumentAnalysisDefinitionParameters,
} from '../document-analysis-definition';
import { documentAnalysisPromptParametersSchema } from './validation';

export class DocumentAnalysisPromptDefinition extends DocumentAnalysisDefinition<
  DocumentAnalysisPromptParametersModel,
  DocumentAnalysisPromptDetailedResultsModel,
  DocumentAnalysisPromptInformationModel
> {
  override get model(): DocumentAnalysisModelEnum {
    return DocumentAnalysisModelEnum.prompt;
  }

  override get label(): string {
    return 'Prompt';
  }

  override get parameters(): DocumentAnalysisDefinitionParameters<DocumentAnalysisPromptParametersModel> {
    return {
      model: {
        label: 'Model',
      },
      prompt: {
        label: 'Prompt',
      },
      languages: {
        label: 'Languages',
      },
    };
  }

  override get detailedResults(): DocumentAnalysisDefinitionDetailedResults<DocumentAnalysisPromptDetailedResultsModel> {
    return {
      results: {
        label: 'Results',
      },
    };
  }

  override get information(): DocumentAnalysisDefinitionInformation<DocumentAnalysisPromptInformationModel> {
    return {};
  }

  override get defaultParameters(): Omit<
    DocumentAnalysisPromptParametersModel,
    'model'
  > {
    return {
      prompt: '',
      languages: null,
    };
  }

  override get validationSchemas(): {
    parameters?: SchemaOf<DocumentAnalysisPromptParametersModel>;
  } {
    return { parameters: documentAnalysisPromptParametersSchema() };
  }
}

export const documentAnalysisPromptDefinition =
  new DocumentAnalysisPromptDefinition();
