import { Building, CircleCheckBig, Folder, Users } from 'lucide-react';

import { CustomViewTypeEnum } from '../../../../shared/models';

export const customViewTypeDefinition = {
  [CustomViewTypeEnum.cases]: {
    label: 'Cases',
    icon: Folder,
  },
  [CustomViewTypeEnum.checks]: {
    label: 'Checks',
    icon: CircleCheckBig,
  },
  [CustomViewTypeEnum.companies]: {
    label: 'Companies',
    icon: Building,
  },
  [CustomViewTypeEnum.individuals]: {
    label: 'Individuals',
    icon: Users,
  },
} as const;

export const getCustomViewDefinition = (
  type: CustomViewTypeEnum,
): (typeof customViewTypeDefinition)[keyof typeof customViewTypeDefinition] => {
  return customViewTypeDefinition[type];
};
