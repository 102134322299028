import { AppEvent, GetEventName } from '../../../shared/event';

// For now this is how this definition is used :
// - key : the app events that is listened to
// - value : true if the event is dispatches, or an array when it is mapped (translated) to other events.
//      Translated have a "subEvent" property set to the original event name (their key)
// EG:
// - Case.Created is dispatched as-is
// - Case.StatusUpdated produces 2 messages : Case.StatusUpdated and Case.Updated
//      With a subEvent of Case.StatusUpdated
export const integrationsPlatformEventDefinition = {
  'Case.Created': true,
  'Case.InfoUpdated': ['Case.Updated'],
  'Case.StatusUpdated': ['Case.StatusUpdated', 'Case.Updated'],
  'Case.FlagsUpdated': ['Case.Updated'],
  'Case.RiskUpdated': ['Case.Updated'],
  'Case.TemplateUpdated': ['Case.Updated'],
  'Case.AssigneeUpdated': ['Case.Updated'],
  'Case.MetadataUpdated': ['Case.Updated'],
  'Case.ContactHasActionsUpdated': ['Case.Updated'],
  'Case.ReviewerHasActionsUpdated': ['Case.Updated'],
  'Case.TagsAdded': ['Case.Updated'],
  'Case.TagsRemoved': ['Case.Updated'],
  'Case.Deleted': true,
  'Individual.Created': true,
  'Individual.InfoUpdated': ['Individual.Updated'],
  'Individual.MarkedAsRelevant': ['Individual.Updated'],
  'Individual.MarkedAsNotRelevant': ['Individual.Updated'],
  'Individual.Deleted': true,
  'Company.Created': true,
  'Company.InfoUpdated': ['Company.Updated'],
  'Company.MarkedAsRelevant': ['Company.Updated'],
  'Company.MarkedAsNotRelevant': ['Company.Updated'],
  'Company.Deleted': true,
  'Check.Started': true,
  'Check.ReviewNeeded': true,
  'Check.Approved': true,
  'Check.Rejected': true,
  'Check.Expired': true,
  'Check.Deleted': true,
} as const satisfies Record<string, true | (GetEventName<AppEvent> | string)[]>;
