import { AbilityBuilder, RawRuleOf } from '@casl/ability';

import { PermissionAbilityEnum } from '../../../shared/models';
import { PermissionAbility } from './type';
import type { PermissionAbilityType, ScopedPermission } from './type';

export const buildAbilityFromPermissions = (
  permissions: ScopedPermission[],
): PermissionAbilityType => {
  return new PermissionAbility(permissions.flatMap(buildAbilityFromPermission));
};

const buildAbilityFromPermission = (
  permission: ScopedPermission,
): RawRuleOf<PermissionAbilityType>[] => {
  const { can, cannot, rules } = new AbilityBuilder(PermissionAbility);

  if (permission.ability === PermissionAbilityEnum.can) {
    can(permission.action, permission.resource);
  } else {
    cannot(permission.action, permission.resource);
  }

  return rules;
};
