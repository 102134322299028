import {
  PropertyNumericFormatEnum,
  PropertyNumericSettings,
  PropertyTypeEnum,
} from '@dotfile/shared/domain';

import { HStack } from '../../layout/stack/stack';
import { Tooltip } from '../../overlay/tooltip/tooltip';
import { NumberInput } from '../number-input/number-input';
import { CustomValueEditorProps } from './type';

const NumericInput = ({
  value,
  settings,
  onChange,
}: {
  value: number | null;
  settings: PropertyNumericSettings | undefined;
  onChange: (newValue: number | null | string) => void;
}) => {
  const format = settings?.format ?? PropertyNumericFormatEnum.number;
  const currencyCode = settings?.currencyCode ?? null;
  const min = settings?.min ?? undefined;
  const max = settings?.max ?? undefined;

  return (
    <Tooltip
      isDisabled={min === undefined && max === undefined}
      label={
        typeof min === 'number' && typeof max === 'number'
          ? `Must be between ${min} and ${max}`
          : typeof min === 'number'
            ? `Must be greater than ${min}`
            : typeof max === 'number'
              ? `Must be lower than ${max}`
              : null
      }
    >
      <NumberInput
        format={format}
        currencyCode={currencyCode}
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        clampValueOnBlur // There is no validation so we need to clamp the value on blur
      />
    </Tooltip>
  );
};

export const ValueEditorNumeric = ({
  operator,
  value,
  handleOnChange,
  fieldData,
}: CustomValueEditorProps<typeof PropertyTypeEnum.numeric>): JSX.Element => {
  const selectsRange = operator === 'between' || operator === 'notBetween';

  if (selectsRange) {
    return (
      <HStack>
        <NumericInput
          value={value?.[0] ?? null}
          settings={fieldData.property.settings}
          onChange={(v) => handleOnChange([v, value?.[1] ?? null])}
        />
        <NumericInput
          value={value?.[1] ?? null}
          settings={fieldData.property.settings}
          onChange={(v) => handleOnChange([value?.[0] ?? null, v])}
        />
      </HStack>
    );
  }

  return (
    <NumericInput
      value={value}
      settings={fieldData.property.settings}
      onChange={handleOnChange}
    />
  );
};
