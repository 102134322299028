import { useTranslation } from 'react-i18next';

import { Pill } from '@dotfile/frontend/shared/design-system';

export type BeneficialOwnerPillProps = {
  shortened?: boolean;
};

export const BeneficialOwnerPill = ({
  shortened = true,
}: BeneficialOwnerPillProps) => {
  const { t } = useTranslation();

  const title = shortened
    ? t('common.beneficial_owner', {
        defaultValue: 'Beneficial Owner',
        ns: 'client-portal',
      })
    : undefined;

  const children = shortened
    ? t('common.ubo', {
        defaultValue: 'UBO',
        ns: 'client-portal',
      })
    : t('common.beneficial_owner', {
        defaultValue: 'Beneficial Owner',
        ns: 'client-portal',
      });

  return (
    <Pill colorScheme="blue" title={title}>
      {children}
    </Pill>
  );
};
