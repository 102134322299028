import { ClientPortalBlockLayoutWidgetEnum } from '../../../../shared/models';

export type ClientPortalBlockLayoutDynamicFieldRequirement =
  | 'label'
  | 'fileId'
  | 'description';

export const CLIENT_PORTAL_WIDGET_DYNAMIC_FIELD_REQUIREMENT_MAPPING: Record<
  ClientPortalBlockLayoutDynamicFieldRequirement,
  {
    required: ClientPortalBlockLayoutWidgetEnum[];
    optional: ClientPortalBlockLayoutWidgetEnum[];
  }
> = {
  label: {
    required: [ClientPortalBlockLayoutWidgetEnum.pdf],
    optional: [ClientPortalBlockLayoutWidgetEnum.image],
  },
  description: {
    required: [ClientPortalBlockLayoutWidgetEnum.text],
    optional: [
      ClientPortalBlockLayoutWidgetEnum.image,
      ClientPortalBlockLayoutWidgetEnum.pdf,
    ],
  },
  fileId: {
    required: [
      ClientPortalBlockLayoutWidgetEnum.image,
      ClientPortalBlockLayoutWidgetEnum.pdf,
    ],
    optional: [],
  },
};
