import { useCallback } from 'react';

import { useHandleError } from '@dotfile/frontend/shared/components';
import {
  AddBusinessContactInput,
  useSaveBusinessContactMutation,
} from '@dotfile/shared/data-access-client-portal';

export const useSaveBusinessContact = () => {
  const [runMutation, result] = useSaveBusinessContactMutation();
  const handleError = useHandleError();

  const saveBusinessContact = useCallback(
    async (input: AddBusinessContactInput) => {
      try {
        const { data } = await runMutation({
          variables: {
            input,
          },
          // Case data will be refetch in the next mutation
        });
        return data?.saveBusinessContact.id;
      } catch (error) {
        handleError({
          error: error,
          title: (error as Error).message,
        });
      }
      return null;
    },
    [runMutation, handleError],
  );

  return [saveBusinessContact, result] as const;
};
