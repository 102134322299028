import { get, omit, snakeCase } from 'lodash';

import {
  ClientPortalBlockFieldModel,
  ClientPortalTypeEnum,
  CompanyClassificationModel,
  CompanyTypeEnum,
  companyViewColumnDefinition,
} from '@dotfile/shared/domain';

import {
  FormDatastoreCase,
  FormDatastoreCompany,
  FormDatastoreIndividual,
  FormDatastoreState,
} from '../context/datastore/datastore';
import { FieldsValues } from '../utils';

export type LogicDataContext = {
  local: FieldsValues;
  global: {
    case?: FieldsValues;
    main_company?: FieldsValues;
    current_individual?: FieldsValues;
  };
};

export function buildLocalDataContext({
  fields,
  formValues,
}: {
  fields: Pick<ClientPortalBlockFieldModel, 'key'>[] | null;
  formValues: FieldsValues;
}): LogicDataContext['local'] {
  // Build local context form step field blocks
  if (!fields) {
    return {};
  }

  // Map form values
  return fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.key]: sanitizePropertyValue(get(formValues, field.key, null)),
    };
  }, {});
}

export function buildGlobalDataContext({
  datastoreData,
  clientPortalType,
}: {
  datastoreData: FormDatastoreState['data'];
  clientPortalType: ClientPortalTypeEnum;
}): LogicDataContext['global'] {
  // Get Case data and transform
  let caseData;
  if (datastoreData.case) {
    caseData = normalizeGlobalData('case', datastoreData.case);
  }

  let mainCompanyData, currentIndividualData;
  if (clientPortalType === ClientPortalTypeEnum.KYB) {
    // Get main company data and transform
    const mainCompany = datastoreData.companies?.find(
      (c) => c.type === CompanyTypeEnum.main,
    );
    if (mainCompany) {
      mainCompanyData = normalizeGlobalData('company', mainCompany);
      // Special normalization for array object: company classifications
      mainCompanyData =
        normalizeCompanyDefaultClassificationsData(mainCompanyData);
    }
  } else if (clientPortalType === ClientPortalTypeEnum.KYC) {
    // Get current individual data and transform
    const currentIndividual = datastoreData.individuals?.at(0);
    if (currentIndividual && currentIndividual.isBusinessContact) {
      currentIndividualData = normalizeGlobalData(
        'individual',
        currentIndividual,
      );
    }
  }

  return {
    case: caseData,
    main_company: mainCompanyData,
    current_individual: currentIndividualData,
  };
}

function normalizeGlobalData(
  entityType: 'case' | 'company' | 'individual',
  input: FormDatastoreCase | FormDatastoreCompany | FormDatastoreIndividual,
): FieldsValues {
  if (!input) return {};

  let resultRecord =
    Object.entries(omit(input, 'customProperties')).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [`${entityType}__default__${snakeCase(key)}`]:
            emptyStringValueToNull(value),
        };
      },
      {},
    ) || {};

  // custom properties
  if (input.customProperties) {
    resultRecord = {
      ...resultRecord,
      ...Object.entries(input.customProperties).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [`${entityType}__custom__${key}`]: emptyStringValueToNull(value),
        };
      }, {}),
    };
  }

  return resultRecord;
}

function sanitizePropertyValue(propertyValue: unknown) {
  if (!propertyValue) {
    return emptyStringValueToNull(propertyValue);
  }

  if (typeof propertyValue === 'object' && !Array.isArray(propertyValue)) {
    return Object.entries(propertyValue).reduce(
      (acc, [nestedKey, nestedValue]) => ({
        ...acc,
        [nestedKey]: emptyStringValueToNull(nestedValue),
      }),
      {},
    );
  }

  return propertyValue;
}

function emptyStringValueToNull(value: unknown): unknown | null {
  return typeof value === 'string' && value.trim() === '' ? null : value;
}

function normalizeCompanyDefaultClassificationsData(fieldValues: FieldsValues) {
  const companyClassificationsNormalizedKey =
    companyViewColumnDefinition.classifications.key;

  if (
    !fieldValues[companyClassificationsNormalizedKey] ||
    !Array.isArray(fieldValues[companyClassificationsNormalizedKey]) ||
    !fieldValues[companyClassificationsNormalizedKey].length
  )
    return fieldValues;

  if (
    !isCompanyClassificationModels(
      fieldValues[companyClassificationsNormalizedKey],
    )
  ) {
    return fieldValues;
  }

  return {
    ...fieldValues,
    company__default__classifications: fieldValues[
      companyClassificationsNormalizedKey
    ].map(({ type, code }) => `${type}__${code}`),
  };
}

export const isCompanyClassificationModel = (
  input: unknown,
): input is CompanyClassificationModel => {
  if (!input || typeof input !== 'object' || Array.isArray(input)) {
    return false;
  }

  return 'type' in input && 'code' in input;
};

export const isCompanyClassificationModels = (
  input: unknown,
): input is CompanyClassificationModel[] => {
  if (!Array.isArray(input) || !input.length) {
    return false;
  }

  return isCompanyClassificationModel(input[0]);
};
