import { useFormContext } from 'react-hook-form';

import { GroupController } from '@dotfile/frontend/shared/components';
import { Input } from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/data-access-client-portal';

import { FieldProps } from './types';

export const FieldUrl = ({
  field,
  property,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.url>) => {
  const { control } = useFormContext();

  return (
    <GroupController
      control={control}
      render={(f) => <Input {...f} value={f.value ?? ''} />}
      {...groupControlProps}
      {...wrapperProps}
    />
  );
};
