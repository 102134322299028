import * as linkify from 'linkifyjs';

import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

// @NOTE: Similar to custom property url validation but
// with isRequired

export const urlValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.url
> = ({ isRequired, property, localizedValidation }) => {
  let schema = yup
    .string()
    .transform((value) => {
      if (!value || typeof value !== 'string') {
        // not a string -> make it fails in the yup url() validation
        return value;
      }

      const foundURL = linkify.find(value, 'url', {
        defaultProtocol: 'https',
      });

      if (foundURL.length !== 1) {
        // not exactly one url -> make it fails in the yup url() validation
        return value;
      }

      if (foundURL[0].value !== value) {
        // not only the url -> make it fails in the yup url() validation
        return value;
      }

      return foundURL[0].href;
    })
    .url(localizedValidation.url.invalid)
    .nullable();

  if (property.settings?.maxLength) {
    schema = schema.max(
      property.settings.maxLength,
      localizedValidation.max_length,
    );
  }

  if (isRequired) {
    schema = schema
      .required(localizedValidation.required)
      .typeError(localizedValidation.required);
  }

  return (
    schema
      // @NOTE cannot do transform in strict mode, no strong type check but all will fallback on the URL validation
      .strict(false)
  );
};
