import { breadcrumbAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleLink = defineStyle({
  fontSize: 'md',
  color: 'gray.500',
});

const baseSeparatorStyle = defineStyle({
  color: 'gray.500',
  lineHeight: '0', // Reset line-height to align the separator with the item text
});

const baseStyle = definePartsStyle({
  link: baseStyleLink,
  separator: baseSeparatorStyle,
});

export const BreadcrumbTheme = defineMultiStyleConfig({
  baseStyle,
});
