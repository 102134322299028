import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  HStack,
  Icon,
  Spacer,
} from '@dotfile/frontend/shared/design-system';

import {
  SUBMIT_BUTTON_DATA_ATTRIBUTE,
  useBackProgressNavigation,
} from '../hooks';
import { AutoSaveConfirm } from './auto-save-confirm';
import { SaveForLaterModals, SaveForLaterState } from './save-for-later';

export type StepFooterProps = {
  /**
   * Override default behaviors of "Back" button which is to navigate to the previous step or the login page if on the first step
   */
  onPrevious?: () => void;

  /**
   * By default, the "Next" button submits the parent <form /> (via type="submit") but this allow to have a specific on-click handler
   */
  onNext?: () => void;

  /**
   * Override "Next" button label (default is "Next" or "Save and continue" on last step)
   */
  nextLabel?: string;

  isNextLoading?: boolean;

  saveForLaterState?: SaveForLaterState;
  /**
   * By default, the "Save for later" button submits the parent <form /> (via type="submit") but this allow to have a specific on-click handler (Lists steps that doesn't have a form)
   */
  onSaveForLater?: () => void;
};

export const StepFooter = ({
  onPrevious,
  onNext,
  nextLabel,
  isNextLoading,
  saveForLaterState,
  onSaveForLater,
}: StepFooterProps) => {
  const { t } = useTranslation();

  const resolvedNextLabel =
    nextLabel ??
    t('common.next', { ns: 'client-portal', defaultValue: 'Next' });

  const backNavigation = useBackProgressNavigation();
  const resolvedPreviousLabel = onPrevious
    ? // Keep default label if onPrevious was set
      t('common.back', { ns: 'client-portal', defaultValue: 'Back' })
    : backNavigation.label;

  return (
    <>
      {resolvedPreviousLabel && (
        <Button
          leftIcon={<Icon as={ArrowLeft} />}
          variant="ghost"
          onClick={onPrevious ?? backNavigation.handleBack}
        >
          {resolvedPreviousLabel}
        </Button>
      )}

      <Spacer />

      {/** @NOTE need to row-reverse to put the Next button first in the DOM for the submit on ENTER
       *   that will also trigger the onClick of the first submit button
       *   @see https://www.w3.org/TR/2011/WD-html5-20110525/the-button-element.html#the-button-element
       */}
      <HStack flexDirection="row-reverse">
        <Button
          type="submit"
          data-submit={SUBMIT_BUTTON_DATA_ATTRIBUTE.next}
          rightIcon={<Icon as={ArrowRight} />}
          onClick={onNext}
          isLoading={isNextLoading}
        >
          {resolvedNextLabel}
        </Button>

        <AutoSaveConfirm />

        {saveForLaterState?.can && (
          <>
            <Button
              variant="ghost"
              type="submit"
              data-submit={SUBMIT_BUTTON_DATA_ATTRIBUTE.saveForLater}
              onClick={onSaveForLater}
              isLoading={saveForLaterState.isLoading}
            >
              {t('forms.save_for_later.label', {
                ns: 'client-portal',
                defaultValue: 'Save for later',
              })}
            </Button>

            <SaveForLaterModals {...saveForLaterState.modals} />
          </>
        )}
      </HStack>
    </>
  );
};
