import { TFunction } from 'i18next';

import { EntityLegalForm } from './types';

export function entityLegalFormFormat(
  { active, code, languages }: EntityLegalForm,
  { noCode, t }: { noCode?: boolean; t?: TFunction } = {},
): string {
  const deprecationIndicator = active
    ? ''
    : `[${
        t
          ? t('entity_legal_form.deprecated', {
              defaultValue: 'Deprecated',
              ns: 'domain',
            })
          : 'Deprecated'
      }] `;
  const labelsAndAbbreviations = Object.values(languages)
    .map((language) => {
      if (language.abbreviations.length > 0) {
        return `${language.label} (${language.abbreviations.join(', ')})`;
      }

      // Translate common code if t is available
      if (t && code === '8888')
        return t('entity_legal_form.other', {
          ns: 'domain',
          defaultValue: 'Other',
        });
      if (t && code === '9999')
        return t('entity_legal_form.not_applicable', {
          ns: 'domain',
          defaultValue: 'not-applicable',
        });

      return language.label;
    })
    .join(', ');

  return `${deprecationIndicator}${noCode ? '' : code}${
    !noCode ? ' - ' : ''
  }${labelsAndAbbreviations}`;
}
