import { createRef, memo, useEffect } from 'react';

import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';

import { Box, useIsMobile } from '@dotfile/frontend/shared/design-system';

import {
  ID_VERIFICATION_IFRAME_HEIGHT,
  ID_VERIFICATION_IFRAME_HEIGHT_MOBILE,
} from '../constants';
import { VendorIframeProps } from '../types';

type VeriffIframeProps = Omit<VendorIframeProps, 'onExpired'>;

// Pure component to not rerender if url don't change
// Make sure you wrap handlers in useCallback to not trigger rerender
export const VeriffIframe = memo(function VeriffIframe({
  verificationUrl,
  onComplete,
  onAbort,
}: VeriffIframeProps) {
  const isMobile = useIsMobile();
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    const veriff = createVeriffFrame({
      url: verificationUrl,
      onReload: () => {
        // URL will reopen the modal on reload
      },
      onEvent: (msg) => {
        switch (msg) {
          case MESSAGES.CANCELED: {
            onAbort();
            break;
          }
          case MESSAGES.SUBMITTED: {
            onComplete();
            break;
          }
        }
      },
    });

    return () => {
      veriff.close();
    };
  }, [verificationUrl, ref, onComplete, onAbort]);

  return (
    <Box
      minHeight={
        isMobile
          ? ID_VERIFICATION_IFRAME_HEIGHT_MOBILE
          : ID_VERIFICATION_IFRAME_HEIGHT
      }
      ref={ref}
      className="iframe-ref"
    />
  );
});
