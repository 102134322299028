import { Check, ChevronDown } from 'lucide-react';
import { PropsWithChildren, ReactNode, Ref, useState } from 'react';

import { Box } from '@chakra-ui/react';

import { Spinner } from '../../feedback/spinner/spinner';
import { Icon } from '../../media-icon/icon/icon';
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '../../overlay/menu/menu';
import { forwardRefWithGeneric } from '../../utils/forward-ref-with-generic';
import { useDisclosure } from '../../utils/hooks/chakra-hooks';
import { Portal } from '../../utils/portal';
import { Button, ButtonProps } from '../button/button';

export type SelectMenuProps<
  Type extends string = string,
  Option extends { readonly value: Type } = { value: Type },
> = PropsWithChildren<{
  value?: Type;
  defaultValue?: Type;
  options: Option[];
  renderOption?: (option: Option) => ReactNode;
  onChange?: (value: Option) => void | Promise<boolean | void>;
  buttonProps?: ButtonProps;
  variant?: 'rounded' | 'select';
  isLazy?: boolean;
  isInvalid?: boolean;
}>;

export const SelectMenu = forwardRefWithGeneric(function SelectMenuWithRef<
  Type extends string = string,
  Option extends { value: Type } = { value: Type },
>(
  {
    children,
    value,
    defaultValue,
    options = [],
    renderOption = (option) => option.value,
    onChange,
    buttonProps,
    variant = 'rounded',
    isLazy = false,
    isInvalid = false,
  }: SelectMenuProps<Type, Option>,
  ref: Ref<HTMLInputElement>,
): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [targetValue, setTargetValue] = useState<Type | undefined>();
  const handleClick = async (option: Option) => {
    try {
      setTargetValue(option.value ?? undefined);
      const res = await onChange?.(option);
      if (typeof res !== 'boolean' || res) {
        onClose();
      }
    } finally {
      setTargetValue(undefined);
    }
  };

  return (
    <Menu
      strategy="fixed"
      closeOnSelect={false}
      isOpen={isOpen}
      onClose={onClose}
      isLazy={isLazy}
    >
      <MenuButton
        as={Button}
        ref={ref}
        aria-label="actions"
        onClick={(event) => {
          event.preventDefault();
          onOpen();
        }}
        paddingRight={variant === 'rounded' ? '1' : 0}
        rightIcon={
          variant === 'rounded' ? (
            <Icon color="black" as={ChevronDown} />
          ) : (
            <Box
              borderLeft="1px solid"
              borderColor={isInvalid ? 'red.500' : 'gray.200'}
              w={variant === 'select' ? '10' : '8'}
              h={variant === 'select' ? '10' : '8'}
              p={variant === 'select' ? '2.5' : '2'}
            >
              <Icon
                color="black"
                w={variant === 'select' ? '5' : 'initial'}
                h={variant === 'select' ? '5' : 'initial'}
                as={ChevronDown}
              />
            </Box>
          )
        }
        variant="outline"
        borderRadius={variant === 'rounded' ? 'full' : '4px'}
        size="sm"
        height={variant === 'select' ? '10' : 'initial'}
        {...(variant === 'select'
          ? {
              borderColor: 'gray.200',
              _hover: {
                borderColor: 'gray.300',
                bg: 'white',
              },
              _focus: {
                borderColor: 'black',
                bg: 'white',
              },
              _active: {
                borderColor: 'black',
                bg: 'white',
              },
            }
          : {})}
        {...(isInvalid
          ? {
              borderColor: 'red.500',
              bg: 'red.50',
              _hover: { borderColor: 'red.500', bg: 'red.50' },
              _focus: { borderColor: 'black', bg: 'red.50' },
              _active: { borderColor: 'black', bg: 'red.50' },
            }
          : {})}
        {...buttonProps}
      >
        {children}
      </MenuButton>
      <Portal>
        <MenuList maxH={500} minW={300} overflow="auto">
          <MenuOptionGroup
            type="radio"
            defaultValue={defaultValue}
            value={targetValue ?? value}
          >
            {options.map((option) => (
              <MenuItemOption
                key={option.value}
                alignItems="center"
                h="2.5em"
                value={option.value ?? undefined}
                isChecked={option.value === value}
                isDisabled={targetValue !== undefined}
                sx={{
                  _disabled: {
                    color: 'inherit',
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  },
                }}
                icon={
                  targetValue === option.value ? (
                    <Spinner size="xs" />
                  ) : (
                    <Icon as={Check} />
                  )
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(option);
                }}
              >
                {renderOption(option)}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
});
